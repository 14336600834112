import React, { useContext, useEffect, useRef } from 'react';
import config from '../constants/config';
import Swiper from 'react-id-swiper';
import Slider from "react-slick";
import { keyMappings } from '../helperfunctions/ctvHelper';
import { checkCorrectPlatform } from '../helperfunctions/common';
import { PLATFORM_XBOX } from '../constants/common';
import TvodSlickContext from './contexts/TvodSlickContext';

const CustomSlider = (props) => {
	const slider_ref = useRef(null);;
	const extraIndex = useContext(TvodSlickContext);

	const changeSlides = (e) => {
		const dir = keyMappings(e.keyCode);
		if(dir === 'LEFT') {
			slider_ref.current && slider_ref.current.slickPrev();
		} else if(dir === 'RIGHT') {
			slider_ref.current && slider_ref.current.slickNext();
		}
	}

	useEffect(() => {
		// didmount
		const elms = document.querySelector(`.content-${props.content_identifier}`);

		if(checkCorrectPlatform([PLATFORM_XBOX]) && elms && props.content_identifier && !props.params.disable_keyboard_events) {
			elms.addEventListener('keydown', changeSlides);
		}

		return () => {
			// unmount
			if(checkCorrectPlatform([PLATFORM_XBOX]) && elms && !props.params.disable_keyboard_events) {
				elms.removeEventListener('keydown', changeSlides);
			}
		}
	}, [])

	useEffect(() => {
		if(extraIndex > 0 && slider_ref.current)  {
			slider_ref.current.slickGoTo(extraIndex)
		}
	}, [extraIndex])

	if (config.use_light_sliders) {
		if(checkCorrectPlatform([PLATFORM_XBOX])) {
			props.params.accessibility = false;
		}

		return (
			<Slider 
				{...props.params}
				ref={slider_ref}
			>
				{ props.children }
			</Slider>
    );
	} else {
		return (
			<Swiper {...props.params}>
				{ props.children }
			</Swiper>
		);
	}
}

export default CustomSlider;