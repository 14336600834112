import {Config} from "@cleeng/mediastore-sdk"
import { getLocalStorage } from "./storage";

export function setCleengConfiguration(feed) {
    const integrations = feed && feed.tvod && feed.tvod.integrations
    const cleengIntegration = integrations && integrations.find(i => i.provider === 'cleeng')
    const isConfigAlreadySet = Boolean(getLocalStorage("CLEENG_THEME", true));
    
    const logo = feed && feed.logo && feed.logo.url;
    Config.setTheme({
        fontColor: "#ffffff",
        backgroundColor: "#292525",
        cardColor: "#675d5d",
        successColor: "#435dc5",
        primaryColor: "#435dc5",
        loaderColor: "#cccccc",
        errorColor: "red",
        logoUrl: logo,
    });
    if (cleengIntegration && cleengIntegration.data) {
        Config.setPublisher(cleengIntegration.data.publisherId);
        Config.setEnvironment(cleengIntegration.data.sandbox ? "sandbox": "production");
        Config.setOffer(cleengIntegration.data.offerId);
        if (!isConfigAlreadySet) window.location.reload(false);
    }
    if(logo) {
        const root = document.querySelector('body');
        if(root) {
            root.style.setProperty('--cleeng_brand_logo', `url('${logo}')`);
        }
    }
    //Need to reload the page, if config is set for the first time, such that cleeng component pick the updated config.
}
