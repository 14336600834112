import { PLATFORM_SAMSUNG, PLATFORM_XBOX, PLATFORM_LG } from "../constants/common";
import { checkCorrectPlatform } from "./common";
import Cookies from 'universal-cookie';


export function setLocalStorage(key, value) {
    const stringVal = JSON.stringify(value);
    try {
        if(checkCorrectPlatform([PLATFORM_SAMSUNG]) && window.tizen) {
            window.tizen.keymanager.saveData(key, stringVal, null, (err) => {err && console.log(err)});
        } else if(checkCorrectPlatform([PLATFORM_XBOX]) && window.Windows != undefined) {
            let localSettings = window.Windows.Storage.ApplicationData.current.localSettings;
            localSettings.values[key] = stringVal;
        } else {
            localStorage.setItem(key, stringVal);
        }
    } catch (e) {
        console.log('unable to save data in local.')
    }
}

export function getLocalStorage(key, withoutParse=false) {
    let val;
    try {
        if(checkCorrectPlatform([PLATFORM_SAMSUNG]) && window.tizen) {
            val = window.tizen.keymanager.getData({ 'name': key });
        } else if(checkCorrectPlatform([PLATFORM_XBOX]) && window.Windows != undefined) {
            let localSettings = window.Windows.Storage.ApplicationData.current.localSettings;
            val = localSettings.values[key];
        } else {
            val = localStorage.getItem(key);
        }
    } catch (e) {
        console.log('unable to get data from local.')
    }
    //val will undefined in case of tizen not found the key
    if (val === undefined) val = null;
    if (withoutParse) return val;
    return val && JSON.parse(val);
}

export function removeItemLocalStorage(key) {
    try {
        if(checkCorrectPlatform([PLATFORM_SAMSUNG]) && window.tizen) {
            window.tizen.keymanager.removeData({ 'name': key });
        } else if(checkCorrectPlatform([PLATFORM_XBOX]) && window.Windows != undefined) {
            let localSettings = window.Windows.Storage.ApplicationData.current.localSettings;
            localSettings.values.remove(key);
        } else {
            localStorage.removeItem(key);
        }
    } catch (e) {
        console.log('unable to remove data from local.')
    }
}

export function addUserCookie(value) {
    try {
      if(checkCorrectPlatform([PLATFORM_LG])) {
        localStorage.setItem('AppUser', JSON.stringify(value));
      } else if(checkCorrectPlatform([PLATFORM_SAMSUNG]) && window.tizen) {
        window.tizen.keymanager.saveData('AppUser', JSON.stringify(value), null, () => {});
      } else if(checkCorrectPlatform([PLATFORM_XBOX]) && window.Windows != undefined) {
        let localSettings = window.Windows.Storage.ApplicationData.current.localSettings;
        localSettings.values['AppUser'] = JSON.stringify(value);
      } else {
        localStorage.setItem('AppUser', JSON.stringify(value));
      }
    } catch(err) {
        // could not add
    }
}

export function getUserCookie() {
    let user_cookie;
    try {
        if(checkCorrectPlatform([PLATFORM_LG])) {
            user_cookie = localStorage.getItem('AppUser');
        } else if(checkCorrectPlatform([PLATFORM_SAMSUNG]) && window.tizen) {
            user_cookie = window.tizen.keymanager.getData({ 'name': 'AppUser' });
        } else if(checkCorrectPlatform([PLATFORM_XBOX]) && window.Windows != undefined) {
            let localSettings = window.Windows.Storage.ApplicationData.current.localSettings;
            user_cookie = localSettings.values['AppUser'];
        } else {
            user_cookie = localStorage.getItem('AppUser');
            //fallback to check user login
            if(!user_cookie) {
                const cookies = new Cookies();
                user_cookie = cookies.get('AppUser');
                if(user_cookie) {
                    // got data in cookie, setting it in localstorage & removing from cookie
                    localStorage.setItem('AppUser', JSON.stringify(user_cookie));
                    cookies.remove('AppUser', { path: '/' });
                }
            }
        }
        if(typeof user_cookie === 'string') user_cookie = JSON.parse(user_cookie);
    } catch(err) {
        user_cookie = null;
    }
    return user_cookie;
}

export function removeUserCookie() {
    try {
        if(checkCorrectPlatform([PLATFORM_LG])) {
          localStorage.removeItem('AppUser');
        } else if(checkCorrectPlatform([PLATFORM_SAMSUNG]) && window.tizen) {
          window.tizen.keymanager.removeData({'name': 'AppUser'});
        } else if(checkCorrectPlatform([PLATFORM_XBOX]) && window.Windows != undefined) {
          let localSettings = window.Windows.Storage.ApplicationData.current.localSettings;
          localSettings.values.remove('AppUser');
        } else {
            localStorage.removeItem('AppUser');
        }
      } catch(err) {
        console.log("Logout issues");
      }
}

export function setSessionStorage(key, value) {
    const stringVal = JSON.stringify(value);
    try {
        if(checkCorrectPlatform([PLATFORM_SAMSUNG]) && window.tizen) {
            window.tizen.keymanager.saveData(key, stringVal, null, (err) => {err && console.log(err)});
        } else if(checkCorrectPlatform([PLATFORM_XBOX]) && window.Windows != undefined) {
            let localSettings = window.Windows.Storage.ApplicationData.current.localSettings;
            localSettings.values[key] = stringVal;
        } else {
            sessionStorage.setItem(key, stringVal);
        }
    } catch (e) {
        console.log('unable to save data in local.')
    }
}

export function getSessionStorage(key, withoutParse=false) {
    let val;
    try {
        if(checkCorrectPlatform([PLATFORM_SAMSUNG]) && window.tizen) {
            val = window.tizen.keymanager.getData({ 'name': key });
        } else if(checkCorrectPlatform([PLATFORM_XBOX]) && window.Windows != undefined) {
            let localSettings = window.Windows.Storage.ApplicationData.current.localSettings;
            val = localSettings.values[key];
        } else {
            val = sessionStorage.getItem(key);
        }
    } catch (e) {
        console.log('unable to get data from local.')
    }
    //val will undefined in case of tizen not found the key
    if (val === undefined) val = null;
    if (withoutParse) return val;
    return val && JSON.parse(val);
}