import React from 'react';

import { GetImageUrl, getImageUrlBaseOnSize } from '../helperfunctions/common';

export default (props) => {
  const { style = {}, classNames, cover, customSize, id } = props;
  const [ fallbackUsed, setFallbackUsed] = React.useState(false);
  let url;
  if(cover && cover.url) {
    url = customSize ? getImageUrlBaseOnSize(cover.url, customSize) : GetImageUrl(cover.url, cover.sizes);
  }
  return (
    <img 
      id={id || ""}
      loading="lazy"
      aria-hidden="true"
      style={{...style, visibility:"hidden"}}
      className={classNames || ""} 
      src={url}
      onLoad={(e) => {e.target.style.visibility='visible'}}
      onError={(e) => {
        if(!fallbackUsed && e && e.target && cover) {
          e.target.src = cover.url
          setFallbackUsed(true)
        }
      }}
    />
  )
}
