import React from 'react';
import {Puff} from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Logo from './Logo';
import QRCode from "qrcode.react";
import ReactFocusLock from 'react-focus-lock';
import configuration from '../constants/config';
import { isSmartTv } from '../helperfunctions/common';
import { getAllConstants } from '../helperfunctions/regionalization';

const FbIconImage = () => (
    <img alt="svgImg" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iODAiIGhlaWdodD0iODAiCnZpZXdCb3g9IjAgMCA0OCA0OCIKc3R5bGU9IiBmaWxsOiMwMDAwMDA7Ij48cGF0aCBmaWxsPSIjM0Y1MUI1IiBkPSJNNDIsMzdjMCwyLjc2Mi0yLjIzOCw1LTUsNUgxMWMtMi43NjEsMC01LTIuMjM4LTUtNVYxMWMwLTIuNzYyLDIuMjM5LTUsNS01aDI2YzIuNzYyLDAsNSwyLjIzOCw1LDVWMzd6Ij48L3BhdGg+PHBhdGggZmlsbD0iI0ZGRiIgZD0iTTM0LjM2OCwyNUgzMXYxM2gtNVYyNWgtM3YtNGgzdi0yLjQxYzAuMDAyLTMuNTA4LDEuNDU5LTUuNTksNS41OTItNS41OUgzNXY0aC0yLjI4N0MzMS4xMDQsMTcsMzEsMTcuNiwzMSwxOC43MjNWMjFoNEwzNC4zNjgsMjV6Ij48L3BhdGg+PC9zdmc+"/>
)

function websiteLoginLoader(props) {
    const ALL_C = getAllConstants();
    const logoUrl = props.feeds.logo && props.feeds.logo.url
    if (configuration.isAppTvodType) {
        let jointWith = props.loaderText.includes("?") ? "&" : "?";
        return (<div className="loader-scrn">
        {logoUrl && <Logo logo={logoUrl}/>}
        <ReactFocusLock>
            <div className="loader-details" role="dialog" tabIndex="-1" aria-labelledby='login-text1 login-url login-text2'>
                <div id="login-text1">{ALL_C.kCtvWebLoginGoToMsg}:</div>
                <b id="login-url">{props.loaderText}</b>
                <div id="login-text2">{ALL_C.kCtvWebEnterDeviceCode}</div>
                {props.code && <b>{props.code}</b>}
                <div className="text-center">
                    <QRCode value={`${props.loaderText}${jointWith}code=${props.code}`} size={isSmartTv() ? 270 : 128} />
                </div>
            </div>
        </ReactFocusLock>
    </div>)
    } else {
        return (<div className="loader-scrn">
        {logoUrl && <Logo logo={logoUrl}/>}
        <ReactFocusLock>
            <div className="loader-details" role="dialog" tabIndex="-1" aria-labelledby='login-text1 login-url login-text2'>
                <div id="login-text1">{ALL_C.kCtvWebLoginGoToMsg}:</div>
                <b id="login-url">{props.loaderText}</b>
                <div id="login-text2">{ALL_C.kCtvWebRegisterMsg}</div>
                <div className="text-center">
                    <QRCode value={props.loaderText} size={isSmartTv() ? 270 : 128} />
                </div>
            </div>
        </ReactFocusLock>
    </div>)
    }
    
}

function subscriptioPaymentLoader(props) {
    const ALL_C = getAllConstants();
    const logoUrl = props.feeds.logo && props.feeds.logo.url
    return (
    <div className="payment-details-ctv loader-scrn">
        {logoUrl && <Logo logo={logoUrl}/>}
        <ReactFocusLock>
            <div className="loader-details" role="dialog" aria-labelledby='pay-url pay-text' tabIndex="-1">
                <b id="pay-url" className="text-center">{props.loaderText}</b>
                <div className="text-center">OR</div>
                <div className="text-center">
                    <QRCode value={props.loaderText} size={isSmartTv() ? 270 : 128}/>
                </div>
                <div id="pay-text">
                    <div>{ALL_C.kCtvPaymentTextMakePayment}</div>
                    <div>{ALL_C.kCtvPaymentTextMakePayment}</div>
                    <div><b>{ALL_C.kCtvPaymentTextDoNotBackPress}</b></div>
                </div>
            </div>
        </ReactFocusLock>
    </div>
    )
}

function fbLoadinScreen(props) {
    return (
        <ReactFocusLock>
            <div className="fb-wait-loader loader-scrn" tabIndex="-1" role="dialog" aria-labelledby='fblogin-code fblogin-desc'>
                <div className="fb-loader-details">
                    <span><FbIconImage /></span>
                    <div id="fbllogin-code" className="fb-code">{props.loaderText}</div>
                    <div id="fblogin-desc" className="fb-desc">Visit <b>facebook.com/device</b> on your smartphone or computer and enter the above code</div>
                </div>
            </div>
        </ReactFocusLock>
    )
}

function Loader(props) {
    if (props.loadingType == "websiteScreen") {
        return (websiteLoginLoader(props));
    }
    if (props.loadingType == "paymentLoader") {
        return (subscriptioPaymentLoader(props))
    }
    if (props.loadingType == "fbLoader") {
        return (fbLoadinScreen(props))
    }
    if (props.loadingType == "videoLoader") {
        return (<div className="video-loader">
            <Puff
                color="#C8C8C8"
                height={60}
                width={60}
                ariaLabel='loading'
            />
        </div>
        )
    }
    return (
        <div className="payment-loader">
            <Puff
                color="#00BFFF"
                height={100}
                width={100}
                ariaLabel='loading'
            />
            <br />
            <div className="loader-text">{props.loaderText}</div>
        </div>
    )
}

export default Loader;