import React from "react"

const PolicyError = () => {
    return (
        <div className={`feed-error web-feed-err`}>
            <h1>Content is not available for your region.</h1>
        </div>
    )
}

export default PolicyError