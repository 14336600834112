import * as actionTypes from '../constants/actionTypes'
import configuration from '../constants/config';

export default function reducer(
    state={
        items: {
            content: [],
        }, user_id: 0, success: false, loader: true,
        history: {contents: []},
        progress: {contents: []},
        haveSavedMazIDFeed: false,
        dynamicLists: {},
        refreshFavDynamicList: false
    }, action) {

    switch (action.type) {
        case "RECEIVE_SAVED_ITEMS": {
            const items = (action.page === 1) ? action.payload : [...state.items.content].concat(action.payload)
            return {
                ...state, items: {content: items}, user_id: action.user_id, success: action.success, loader: action.loader,
            }
        }
        case "RECEIVE_DELETE_ITEMS": {
            var index = 0;
            for(var i=0; i < state.items.content.length; i++){
                if((configuration.isAppTvodType && state.items.content[i].cid === action.payload.cid) || (!configuration.isAppTvodType && state.items.content[i].identifier === action.payload.identifier)){
                    index = i;
                    break;
                }
            }
            return {
                ...state, items: {content: state.items.content.slice(0, index).concat(state.items.content.slice(index+1))}, user_id: action.user_id, success: action.success,
            }
        }
        case "REPLACE_SAVED_ITEMS": {
            var index = null;
            let content = [...state.items.content];
            for(var i=0; i < content.length; i++){
                if(content[i].cid === action.payload.cid){
                    index = i;
                    break;
                }
            }
            if(index != null) {
                let newContent = {...content[index], ...action.payload}
                content[index] = newContent;
            }
            return {
                ...state, items: {content: content},
            }
        }
        case "RECEIVE_All_SAVED_ITEMS": {
            return {
                ...state, items: action.payload, user_id: action.user_id, success: action.success, loader: action.loader,
            }
        }
        case "CLEAR_SAVED_ITEMS": {
            return {
                ...state, items: action.payload, user_id: action.user_id, success: action.success, loader: action.loader,
                history: {contents: []},
                progress: {contents: []}
            }
        }
        case actionTypes.GET_USER_HISTORY_PROGRESS_RESOLVE: {
            return {
                ...state,
                progress: {
                    contents: action.payload.content
                }
            }
        }
        case actionTypes.GET_USER_HISTORY_RESOLVE: {
            return {
                ...state,
                history: {
                    contents: action.payload.content
                }
            }
        }
        case actionTypes.GET_DYNAMIC_LIST_PENDING: {
            const uniqueKey = action.payload;
            let dynamicList = {...state.dynamicLists[uniqueKey]};
            return {
                ...state,
                dynamicLists : {
                    ...state.dynamicLists,
                    [uniqueKey] : {
                        ...dynamicList,
                        apiCallMade: true,
                        pending: true
                    }
                }
            }
        }
        case actionTypes.GET_DYNAMIC_LIST_RESOLVE: {
            const uniqueKey = action.uniqueKey;
            let dynamicList = {...state.dynamicLists[uniqueKey]};
            let content = [];
            if(action.page === 1) {
                // first page. Concat with local list
                if(["watching", "history"].includes(uniqueKey) && dynamicList.content) {
                    // Remove duplicate
                    const allKeys = {};
                    content = [...dynamicList.content];
                    dynamicList.content.forEach(item => {
                        allKeys[item.cid] = true
                    });
                    action.payload.forEach(itemP => {
                        if(!allKeys[itemP.cid]) {
                            content.push(itemP)
                        }
                    })
                } else {
                    content = action.payload;
                }
            } else {
                content = [...dynamicList.content].concat(action.payload);
            }
            return {
                ...state,
                dynamicLists : {
                    ...state.dynamicLists,
                    [uniqueKey] : {
                        ...dynamicList,
                        content: content,
                        pending: !action.lastPage
                    }
                }
            }
        }
        case actionTypes.GET_DYNAMIC_LIST_REJECT: {
            const uniqueKey = action.payload;
            let dynamicList = {...state.dynamicLists[uniqueKey]};
            return {
                ...state,
                dynamicLists : {
                    ...state.dynamicLists,
                    [uniqueKey] : {
                        ...dynamicList,
                        pending: false
                    }
                }
            }
        }
        case actionTypes.CLEAR_DYNAMIC_LIST: {
            return {
                ...state,
                dynamicLists : {}
            }
        }
        case actionTypes.REFRESH_FAV_DYNAMIC_LIST: {
            return {
                ...state,
                refreshFavDynamicList : action.payload
            }
        }
        case actionTypes.UPDATE_WACHING_HISTORY_DYNAMIC_LIST: {
            const watchObj = state.dynamicLists["watching"] || {};
            const historyObj = state.dynamicLists["history"] || {};
            const watching = watchObj.content ? [...watchObj.content] : [];
            const history = historyObj.content ? [...historyObj.content] : [];
            const { progress, content } = action.payload;

            const watchIndex = watching.findIndex((item) => item.cid === content.cid);
            const historyIndex = history.findIndex((item) => item.cid === content.cid);
            if(progress >= 95) {
                // Add in history & remove from watching
                if(historyIndex === -1) {
                    history.push(content);
                }
                if(watchIndex >= 0) {
                    watching.splice(watchIndex, 1);
                }
            } else {
                // Add in watching & remove from history
                if(watchIndex === -1) {
                    watching.push(content);
                }
                if(historyIndex >= 0) {
                    history.splice(historyIndex, 1);
                }
            }

            return {
                ...state,
                dynamicLists: {
                    ...state.dynamicLists,
                    "watching": {
                        ...watchObj,
                        content: watching,
                    },
                    "history": {
                        ...historyObj,
                        content: history,
                    }
                }
            }
        }
        
        case "SAVED_ITEMS_FAILED": {
            return {...state, loader: action.loader, success: false}
        }
        case actionTypes.HAVE_MAZ_ID_SAVE_FEED: {
            return {
                ...state, haveSavedMazIDFeed: true
            }
        }
    }

    return state
}