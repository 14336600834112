import * as ACTION_TYPES from '../constants/actionTypes';

const defaultState = {
  isFetching: false,
  codeConfigured: false,
  codeValidated: false,
  codeValidationFailed: false,
  validationError: null
}

export default function reducer(state = {...defaultState} , action) {

  switch(action.type) {
    case ACTION_TYPES.PARENTAL_LOCK_DATA_FTECHING:
      return {
        ...state,
        isFetching: true
      }
    case ACTION_TYPES.PARENTAL_LOCK_DATA_FTECH_FAIL:
      return {
        ...state,
        isFetching: false,
        codeConfigured: false
      }
    case ACTION_TYPES.PARENTAL_LOCK_DATA_FTECH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        codeConfigured: action.payload
      }
    case ACTION_TYPES.PARENTAL_LOCK_CODE_SETUP:
      return {
        ...state,
        isFetching: true
      }
    case ACTION_TYPES.PARENTAL_LOCK_CODE_SETUP_FAIL:
      return {
        ...state,
        isFetching: false
      }
    case ACTION_TYPES.PARENTAL_LOCK_CODE_SETUP_SUCCESS:
      return {
        ...state,
        isFetching: false,
        codeConfigured: true,
        codeValidated: new Date()
      }
    case ACTION_TYPES.PARENTAL_LOCK_CODE_VALIDATE:
      return {
        ...state,
        isFetching: true,
        codeValidationFailed: false,
        validationError: null
      }
    case ACTION_TYPES.PARENTAL_LOCK_CODE_VALIDATE_FAIL:
      return {
        ...state,
        isFetching: false,
        codeValidated: false,
        codeValidationFailed: true,
        validationError: action.payload
      }
    case ACTION_TYPES.PARENTAL_LOCK_CODE_VALIDATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        codeValidated: new Date(),
        codeValidationFailed: false,
        validationError: null
      }
    case ACTION_TYPES.PARENTAL_LOCK_CODE_RESET:
      return {
        ...state,
        codeValidated: false
      }
    case ACTION_TYPES.PARENTAL_LOCK_CODE_REMOVED:
      return {
        ...state,
        codeValidated: false,
        codeConfigured: false
      }
    case ACTION_TYPES.PARENTAL_LOCK_CODE_VALID_FAIL_RESET:
      return {
        ...state,
        codeValidationFailed: false,
        validationError: null
      }
  }

  return state;
}