import React from 'react';
import { connect, Provider } from "react-redux"
import { withRouter } from 'react-router-dom'
import OverlayVideo from "../components/OverlayVideo"
import Subscribe from '../containers/Subscribe'

import OverlayImage from "../components/OverlayImage"
import { closeModal, openModal } from "../actions/modalActions"
import { saveItem, saveTvodItem } from "../actions/itemActions"
import ErrorDisplay from "../components/ErrorDisplay"
import FakeLive from "../components/FakeLive"
import LiveSetup from "../components/LiveSetup"
import ContentSectionVideo from "../components/ContentSectionVideo"
import ConfirmPayment from "../components/ConfirmPayment";
import { clearPendingPurchase } from "../actions/subscriptionAction";
import Loader from "../components/Loader";
import { backButtonObj, checkIfSectionLevelContent, checkIfShowingContentOnSectionLevel, findCurrentContentType, getLogoFromLoginOrFeed } from "../helperfunctions"

import configuration from '../constants/config';
import isAvailable from '../helperfunctions/is_available';
import { DEEPLINK, PLATFORM_LG, PLATFORM_SAMSUNG, PLATFORM_VIZIO, PLATFORM_XBOX, PLATFORM_ZEASN, throttle } from '../constants/common';
import LoginFlow from '../components/LoginFlow';
import { checkLoginUIMetaData, isSmartTv, checkCorrectPlatform, getTabIndex, toggleNavbarOnModalChanges, getPaymentFailureUrl } from '../helperfunctions/common';
import backMobile from "../images/icons/back-mobile.png";
import { parse } from 'query-string';
import JsSpatialNavigation from '../ctvnavigation/js-spatial-navigation';
import { addRemoveAllNavigation, callExitCtvMethod, checkIfFocusInsideBanner, getBackBtnKeyCodes, retainFocusOnBackPress, speakText } from '../helperfunctions/ctvHelper';
import InterstitialScreen from "../components/InterstitialScreen";
import { elementScrollIntoView } from "seamless-scroll-polyfill";
import TvodWatchman from './TvodWatchman';
import FocusLock from 'react-focus-lock';
import { addUserCookie } from '../helperfunctions/storage';
import { MyAccount, Subscriptions, store } from '@cleeng/mediastore-sdk';
import ReactFocusLock from 'react-focus-lock';
import { getAllConstants } from '../helperfunctions/regionalization';
import LinkSection from './LinkSection';

let ALL_C = {};

function FindSection(props, section_id) {
  var feed = props;
  var currentLocation = section_id.split("/")
  var contents = props.sections
  for (var i = 0; i < currentLocation.length; i++) {
    for (var j = 0; j < contents.length; j++) {
      if (contents[j].identifier == currentLocation[i]) {
        feed = contents[j]
        contents = contents[j].contents
        if (!Array.isArray(contents)) {
          break;
        }
      }
    }
  }
  if (feed.contents && (feed.contents[0].type == "fake" || feed.contents[0].type == "live")) {
    feed = feed.contents[0]
  }
  return feed;
}

const exitIfNotInput = () => {
  const activeElement = document.activeElement;
  if(activeElement && activeElement.id === 'voucher-title-input') return false
  if(!checkCorrectPlatform([PLATFORM_ZEASN])) return true;
  return !(activeElement.tagName.toLowerCase() === "input" && ["text", "password", "email", "search"].includes(activeElement.type));
}

const retainFocus = (_this) => {
  retainFocusOnBackPress(_this.focus_store, _this.props.modal.type, window.location.pathname);
}

const clickBackButton = (_self) => {
  if(_self.props.modal && (_self.props.modal.type == 'exit_app')) {
    _self.exitCtvApp(false);
    retainFocus(_self);
  } else if(document.getElementById("more-back-button")) {
    document.getElementById("more-back-button").click();
  } else if (document.getElementById("gdpr_back_button")) {
    document.getElementById("gdpr_back_button").click();
  } else if (document.getElementById("fb_back_button_ctv")) {
    document.getElementById("fb_back_button_ctv").click();
  } else if (document.getElementById("website_back_button_ctv")) {
    document.getElementById("website_back_button_ctv").click();
  } else if (document.getElementById("iframe1_back_button_ctv")) {
    document.getElementById("iframe1_back_button_ctv").click();
  } else if(document.getElementById("link-modal-close-button")){
    document.getElementById("link-modal-close-button").click()
  } else if (document.getElementById('virtual_keyboard') &&  document.getElementById("virtual_keyboard").style.display != 'none') {
    const search_elm = document.querySelector('.search-box input');
    if((search_elm && search_elm.value.length == 0)) {
      document.getElementById('virtual_keyboard').style.display = "none";
      document.getElementById('virtual_keyboard').setAttribute("data-last_focus_osk", 0);
      JsSpatialNavigation.focus();
    }
  } else if(_self.props.isPlayerSettingsModalOpen) return ////Do not Close Player Setting modal is Open
  else if (document.getElementById("overlay_video_back_btn")) {
    if(configuration.isAppTvodType && document.getElementById("continue-watching")) return;
    const inline_elm = document.getElementById('video-inlineMenu');
    const tvod_overlay = document.getElementById("tvod-overlay");
    if(inline_elm && !inline_elm.classList.contains('closedMenu')) {
      // if opened, don't go back
      return;
    } else if (checkIfSectionLevelContent() && !(tvod_overlay && tvod_overlay.classList.contains("playing"))) {
      _self.openExitAppModal();
    } else {
      document.getElementById("overlay_video_back_btn").click();
      retainFocus(_self);
    }
  } else if(document.getElementById('error_display_btn')) {
    document.getElementById("error_display_btn").click();
  } else if (document.getElementById('vizio-account-container-loader')){
    // If Vizio Payment Actions are triggered, do not go back with remote button
    return
  } else if (document.getElementById('modal_back_btn')) {
    document.getElementById("modal_back_btn").click();
    retainFocus(_self);
  } else if(document.getElementById('error_display_btn')) {
    // console.log("error button clicked")
    document.getElementById("error_display_btn").click();
  } else if (document.getElementById("main-tv-back-button")) {
    document.getElementById("main-tv-back-button").click();
  } else if (document.getElementById("main_back_id")) {
    // console.log("main_back_id clicked");
    document.getElementById("main_back_id").click();
    retainFocus(_self);
  } else if(checkIfSectionLevelContent()) {
    if (_self.state.emailSelected || window.emailSelected) {
      _self.setState({ emailSelected: false });
      window.emailSelected = false;
      return;
    }
    if (window.dontOpenExit) {
      window.dontOpenExit=false;
      return; 
    }
    if(document.getElementById("more-back-button")) return;
    _self.openExitAppModal()
  }
}

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.selectEmail = this.selectEmail.bind(this);
    this.selectBack = this.selectBack.bind(this);
    this.dismissAction = this.dismissAction.bind(this);
    this.closeLogin = this.closeLogin.bind(this);
    this.saveLastFocus = this.saveLastFocus.bind(this);
    this.updateEmailSelected = this.updateEmailSelected.bind(this);
    this.state = { 
      emailSelected: false,
      login_data: null
    };
    this.focus_store = {
      paths: {},
      modals: {}
    };
    ALL_C = getAllConstants();
  }

  saveLastFocus(data) {
    try {
      if(data.srcElement) {
        if(this.props.modal.type) {
          this.focus_store['modals'][this.props.modal.type] = data.srcElement;
        } else {
          this.focus_store['paths'][window.location.pathname] = data.srcElement;
        }
      }
      window.last_focus = this.focus_store;
    } catch(err) {
      console.log("not able to save focus!",err);
    }
  }

  handleRemoteClick = (e) => {
    if(parseInt(e.keyCode) >= 37 && parseInt(e.keyCode) <= 40) {
      if (!(window.location.pathname.includes('settings') || checkIfFocusInsideBanner())) {
        if('scrollIntoViewIfNeeded' in document.activeElement) {
          document.activeElement.scrollIntoViewIfNeeded(false);
        }
      }
    }

    const back_key_codes = getBackBtnKeyCodes();
    if (back_key_codes[e.keyCode] && exitIfNotInput()) {
      // only one click per 500ms
      throttle(() => clickBackButton(this));
      e.preventDefault();
    }
  }

  openExitAppModal = () => {
    if(this.props.modal.type === 'exit_app') return;
    if (Object.keys(this.props.modal)) {
      this.last_modal_data = this.props.modal;
    } else {
      this.last_modal_data = null;
    }
    this.props.openModal({ type: 'exit_app' });
  }

  exitCtvApp = (_bool) => {
    if (_bool) {
      callExitCtvMethod();
    } else {
      if (this.last_modal_data) {
        this.props.openModal(this.last_modal_data);
      } else {
        this.props.closeModal();
      }
    }
  }

  componentDidMount() {
    if (isSmartTv()) {
      document.addEventListener('keydown', this.handleRemoteClick);
      document.addEventListener('sn:focused', this.saveLastFocus);
    }
    window.addEventListener('popstate', () => this.backBottonClick());
  }

  componentWillUnmount() {
    if (isSmartTv()) {
      document.removeEventListener('keydown', this.handleRemoteClick);
      document.removeEventListener('sn:focused', this.saveLastFocus);
    }
  }

  dismissAction(ev, intersitialProps) {
    this.showBodyScroll();
    if (intersitialProps) {
      this.props.openModal(intersitialProps)
    } else {
      this.props.closeModal();
    }
    this.goBackToPrevious();
    if (checkIfSectionLevelContent()) {
      // if section level live/simlive
      this.continuePlayingAfterLogin()
    }   
    if (ev)
      ev.preventDefault()
  }

  selectEmail(ev) {
    this.setState({ emailSelected: true })
    if (ev)
      ev.preventDefault()
  }

  goBackToPrevious(continuePlaying, closeVizioModal) {
    if (this.props.modal.goBack === "search") {
      // for search section, need to go back to search instead of feed
      this.props.history.replace(`/apps/${configuration.app_id}/search`);
      document.body.classList.remove("noNav")
    } 
    
    if (this.props.modal.goBack === "login" || (this.props.modal.message && this.props.modal.message.goBack === "login")) {
      this.props.openModal({type: 'login'});
      if(isSmartTv()) {
        this.setState({ emailSelected: this.state.login_data ? true : false });
      }
    } else if(this.props.modal.goBack === "tvodInterstitial") {
        if (closeVizioModal) {
          window.history.back()
        } else if(this.props.isVizioPaymentModalOpen){}
        else {
          const failureUrl = getPaymentFailureUrl(window.location.pathname, this.props.modal)
          this.props.history.replace(failureUrl);
        }
    } else if (this.props.modal.goBack) {
      let url = window.location.pathname;
      const backUrlObj = backButtonObj(this.props.feeds, url.split('/').slice(3).join('/'));
      // Go back to parent feed
      this.props.history.replace(backUrlObj.backPath);
      if (continuePlaying && !isSmartTv()) {
        // continue playing video and image
        this.props.history.replace(url);
      }
    }

    if(isSmartTv()) {
      retainFocusOnBackPress(window.last_focus, null, window.location.pathname);
    }
  }

  selectBack(ev) {
    this.showBodyScroll();
    this.setState({ emailSelected: false })
    this.goBackToPrevious()
    if (ev)
      ev.preventDefault()
  }

  closeLogin(ev, intersitialProps) {
    const currEmail = this.state.emailSelected;
    this.showBodyScroll();
    this.setState({ emailSelected: false })
    this.goBackToPrevious()
    if (intersitialProps) {
      this.props.openModal(intersitialProps)
    } else if (!isSmartTv() || !currEmail) {
      this.props.closeModal()
    }
    if (ev)
      ev.preventDefault()
  }

  componentDidUpdate(prevProps) {
    this.checkOverlayAndHideShowBodyScroll();
    if(!this.props.modal) return;
    // waiting for all 3 api completion- login, subscription, meter
    if (this.props.modal.type === "login" && this.props.logged_in && !this.props.isUserLoading && !this.props.isMeteringLoading) {
      // this.showBodyScroll()
      addUserCookie(this.props.user);
      if (this.props.modal.saved_item) {
        const { saved_item, viewMetering, locked } = this.props.modal;
        const contentData = configuration.isAppTvodType ? saved_item : null;
        const isLocked = locked && !isAvailable(saved_item.parent_id, saved_item.special, saved_item.registerWall, contentData);
        if(configuration.isAppTvodType) {
          if(!isLocked) this.props.saveTvodItem(saved_item, {saveAfterLogin: true});
        } else if (!isLocked || (isLocked && viewMetering && this.canSaveLockedItem(saved_item.identifier))) {
          this.props.saveItem(this.props.user.auth_token, saved_item.identifier, this.props.user.user_id, saved_item)
        }
      }
      this.setState({ emailSelected: false })
      this.props.closeModal()
      this.continuePlayingAfterLogin()
    }

    // When user is purchasing subscription and loading its subscription details.
    if (prevProps.isUserLoading && !this.props.isUserLoading && this.props.isPurchasePending) {
      if (!this.props.userError) {
        const watchModal = (this.props.modal && this.props.modal.tvodWatchmanModal) ? {tvodWatchmanModal: {...this.props.modal.tvodWatchmanModal}}: {}
        this.props.openModal({ type: "payment", ...watchModal })
      }
      else {
        this.props.clearPendingPurchase();
        this.props.openModal({ type: "subscribe", message: "Unable to load current subscriptions." })
      }
    }
    if (isSmartTv()) {
      this.changeStyleIfModalDataChanges();
      this.handleCTVNavigation();
      if(this.props.modal.type) {
        if(this.props.modal.type == "terms_privacy_screen") {
          if(document.activeElement) {
            document.activeElement.blur();
          }
          speakText(`${this.props.modal.message.type}, To read, please visit: ${this.props.modal.message.url}`);
        }
        let type = this.props.modal.type; 
        if(type == 'login' && document.getElementById('email_id')) {
          return;
        } else if(type == 'live' || type == 'fake' || type == 'video') {
          return;
        } else if(document.getElementById('nav-bar') && document.getElementById('nav-bar').contains(document.activeElement)) {
          return;
        } else if(type == 'terms_privacy_screen') {
          if(document.getElementById("terms-privacy-back-btn")) {
            document.getElementById('terms-privacy-back-btn').focus();
          }
          // speakText(`${this.props.modal.message.type}, To read, please visit: ${this.props.modal.message.url}`);
          // if(document.activeElement) {
          //   document.activeElement.blur();
          //   return;
          // }
        }
        JsSpatialNavigation.focus();
      }
    }
  }

  handleCTVNavigation() {
    if(!configuration.use_light_sliders) return;
    let type = this.props.modal.type;
    if(type && type != 'login') {
      // if(type == 'error' && !window.location.href.includes('settings')) JsSpatialNavigation.clear();
      this.id = addRemoveAllNavigation({
        selector: '#overlay .focusable'
      });
      JsSpatialNavigation.focus(this.id);
    } else if(!type && this.id) {
      addRemoveAllNavigation({ id: this.id });
      this.id = null;
    }
  }

  canSaveLockedItem(identifier) {
    let freeViews = (this.props.feeds.ctaMetadata && this.props.feeds.ctaMetadata.metering) ? this.props.feeds.ctaMetadata.metering.freeViews : 0;
    if (freeViews <= 0) return false;
    let rest = freeViews - this.props.metering_views_seen.length;
    if (!this.props.metering_views_seen.includes(identifier) && rest <= 0) {
      return false;
    }
    return true;
  }

  continuePlayingAfterLogin() {
    // Continue fake and live video after login
    let section_id = window.location.pathname.replace("/apps/" + this.props.app_id, "");
    if (section_id !== "") {
      let section = FindSection(this.props.feeds, section_id);
      if (section.type === "fake") {
        this.props.openModal({ type: "fake", feeds: this.props.feeds, section: section })
      } else if (section.type === "live") {
        this.props.openModal({ type: "live", feeds: this.props.feeds, section: section })
      } else if ((section.type === "vid") || (section.type === "image")) {
        // send back 
        if (!checkIfSectionLevelContent()) {
          this.goBackToPrevious(true)
        }
      } else if (section_id.includes("/search")) {
        this.goBackToPrevious()
      }
    }
  }

  checkOverlayAndHideShowBodyScroll = () => {
    if (document.querySelector('body #overlay')) {
      this.hideBodyScroll();
    } else {
      this.showBodyScroll();
    }
  }

  backBottonClick() {
    if (this.props.history.action === "POP" && this.props.modal && 
      (this.props.modal.type === "video" || this.isOpenedLiveModalDirectly())&& 
      this.props.history.location && this.props.history.location.pathname
    ) {
      if (this.props.history.location.pathname.includes("search/")) {
        return;
      }
      //On browser back button close modal
      const type = findCurrentContentType(this.props.feeds, this.props.history.location.pathname)

      if (type !== "vid" && !this.props.oneFeedLoading) {
        if(configuration.isAppTvodType && window.location.search === "?mode=watch") {
          window.history.replaceState("view", {}, `${window.location.pathname}`)
        }
        this.props.closeModal();
      }

      // In case of autoplay next video, window.location.pathname happen to be wrong. Which result in app crash
      // Fix for https://github.com/MagAppZine/MagAppZine-MagControl/issues/7539
      const loc = this.props.history.location;
      if(loc && loc.pathname && window.location.pathname !== loc.pathname) {
        this.props.history.replace(loc.pathname);
      }
    }
  }

  clickEnter = (e) => {
    // console.log('Click enter', e);
    // document.activeElement.click();
    e.srcElement.childNodes[0].click();
  }

  /**
   * Function for back button
   * @param {*} onClickAction Which function to call when clicked on back button
   */
  backButtonHtml = (onClickAction) => {
    let style = {}
    if(isSmartTv()) {
      style.display = 'none';
    }

    return (
    <span className={"icon-hollow_arrow backBtnSpan " + (this.props.runningOnMobile ? "backBtnSpan-mobile" : "")}>
      <a id="modal_back_btn" href="" onClick={(e) => {
          e.preventDefault();
          onClickAction();
        }} 
        className={(this.props.runningOnMobile ? "backBtn-mobile" : "backBtn")}
        style={{zIndex: 100, ...style}}>
          {this.props.runningOnMobile && <img src={backMobile} alt="Back"/>}
      </a>
    </span>)
  }

  hideBodyScroll = () => {
    document.body.classList.add("overflow-hidden");
  }

  showBodyScroll = () => {
    document.body.classList.remove("overflow-hidden");
  }

  changeStyleIfModalDataChanges = () => {
    toggleNavbarOnModalChanges(this.props.modal);
  }

  updateEmailSelected = (emailSelected) => {
    this.setState({ emailSelected });
  }

  clearLoginData = () => {
    this.setState({ login_data: null })
  }

  canPlayDirectly() {
    if(!configuration.isAppTvodType) return true;
    return false;
  }

  isOpenedLiveModalDirectly() {
    if(!this.props.modal) return false;
    return ((this.props.modal.type === "live" || this.props.modal.type === "fake") && this.props.modal.openLiveModalDirectly)
  }
  
  closeTermsPrivacyScreen = () => {
    document.getElementById("modal_back_btn").click();
    retainFocusOnBackPress(this.focus_store, this.props.modal.type, window.location.pathname);
  }

  tvodWatchman = (modal, openedWithPayment) => {
    let classNames = "mediaOverlay showFeVideo";
    const feeds = modal.type === "video" ? this.props.feeds : modal.feeds;
    const section = modal.type === "video" ? modal.content : modal.section;
    if(checkIfShowingContentOnSectionLevel(section, feeds)) {
      classNames = "mediaOverlay showFeVideo section-level-content"
    }
    return (
      <div id="tvod-overlay" className={classNames} key={openedWithPayment ? "forPayment" : "standard"}>
        <TvodWatchman
          modal={modal}
          feeds={feeds}
          runningOnMobile={this.props.runningOnMobile}
          section={section}
          type={modal.type}
          openedWithPayment={openedWithPayment}
        />
      </div>
    )
  }

  render() {
    if (isSmartTv()) {
      this.changeStyleIfModalDataChanges();
    }
    const canPlayDirectly = this.canPlayDirectly();

    if (this.props.isUserLoading && !(configuration.isAppTvodType && document.getElementsByClassName("tvod-interstitial").length > 0))
      return <Loader />
    if (this.props.isCounterPolling)
      return <Loader />

    if (!this.props.modal || Object.keys(this.props.modal).length === 0) {
      return (
        <div className="mediaOverlay" />
      )
    } else if (this.props.modal.type === "video" && canPlayDirectly) {
      return (
        <div className="mediaOverlay showVideo">
          <OverlayVideo modal={this.props.modal} section={this.props.modal.content} app_id={this.props.app_id}
              all_contents={this.props.modal.all_contents} feeds={this.props.feeds} metering_details={this.props.metering_details} parent_id= {this.props.modal.head} runningOnMobile={this.props.runningOnMobile}/>
        </div>
      )
    } else if (this.props.modal.type === "image") {
      return (
        <div className="mediaOverlay showGallery">
          <OverlayImage modal={this.props.modal} section={this.props.modal.content} app_id={this.props.app_id}
                        all_contents={this.props.modal.all_contents} feeds={this.props.feeds} runningOnMobile={this.props.runningOnMobile}/>
        </div>
      )
    } else if (this.props.modal.type === "fake" && canPlayDirectly) {
      return (
        <div className={"mediaOverlay showFeVideo " + (checkIfSectionLevelContent() ? "section-level-content" : "")}>
          <FakeLive modal={this.props.modal} key={this.props.modal.section.identifier} feeds={this.props.modal.feeds} section={this.props.modal.section} app_id={this.props.app_id} runningOnMobile={this.props.runningOnMobile}/>
        </div>
      )
    } else if (this.props.modal.type === "live" && canPlayDirectly) {
      return (
        <div className={"mediaOverlay showFeVideo " + (checkIfSectionLevelContent() ? "section-level-content" : "")}>
          <LiveSetup modal={this.props.modal} key={this.props.modal.section.identifier} feeds={this.props.modal.feeds} section={this.props.modal.section} runningOnMobile={this.props.runningOnMobile}/>
        </div>
      )
    } else if (this.props.modal.type === "svideo" && canPlayDirectly) {
      return (
        <div className={"mediaOverlay showFeVideo " + (checkIfSectionLevelContent() ? "section-level-content" : "")}>
          <ContentSectionVideo feeds={this.props.modal.feeds} section={this.props.modal.section} runningOnMobile={this.props.runningOnMobile}/>
        </div>
      )
    } else if(configuration.isAppTvodType && (this.props.modal.type === "svideo" || this.props.modal.type === "live" || this.props.modal.type === "fake" || this.props.modal.type === "video")) {
      return this.tvodWatchman(this.props.modal);
    } else if (this.props.modal.type === "login" && !this.state.emailSelected) {
      const headerLogo = getLogoFromLoginOrFeed(this.props.feeds, "registrationWall");
      const interstitialProps = this.props.modal.interstitialModalDetails;
      const allProps = {
        feeds: checkLoginUIMetaData(this.props.feeds),
        app_id: this.props.app_id,
        background: (this.props.feeds.loginUIMetadata.colors && this.props.feeds.loginUIMetadata.colors.background) || null,
        screen: this.props.modal.screen,
        titleMsg: this.props.modal.text || null,
        directLogin: this.props.modal.directLogin
      }
      return (
        <div id="overlay" className={"user-access overflow-scroll-auto " + (this.props.modal.hideCloseButton ? "no-close-btn" : "")}>
          {/* Have to add extra div for scrolling in firefox browser */}
          <div className="scroll-auto-h-100">
            {!this.props.modal.hideCloseButton && this.backButtonHtml(() => {
                this.clearLoginData();
                this.props.clearPendingPurchase();
                this.closeLogin(null, interstitialProps);
                if(this.props.modal.parentCallback) {
                  this.props.modal.parentCallback();
                }
            })}
            {/* Lot of things to do on back button, that's why calling LoginFlow independently */}
            <LoginFlow
              emailSelected={false}
              login_data={this.state.login_data}
              updateEmailSelected={this.updateEmailSelected}
              headerLogo={headerLogo}
              selectEmail={this.selectEmail}
              allProps={allProps}
              modalLogin={true}
              runningOnMobile={this.props.runningOnMobile}
            />
          </div>
        </div>
      )
    } else if ((this.props.modal.type === "login" && (this.state.emailSelected || !this.props.feeds.loginUIMetadata.fb)) ||
      (this.props.modal.type === "userAccessPage")
    ) {
      const interstitialProps = this.props.modal.interstitialModalDetails;
      const headerLogo = getLogoFromLoginOrFeed(this.props.feeds, "registrationWall");
      const allProps = {
        feeds: checkLoginUIMetaData(this.props.feeds),
        app_id: this.props.app_id,
        background: (this.props.feeds.loginUIMetadata.colors && this.props.feeds.loginUIMetadata.colors.background) || null,
        screen: this.props.modal.screen,
        titleMsg: this.props.modal.text || null,
        directLogin: this.props.modal.directLogin
      }
      return (
        <div id="overlay" className={"user-access overflow-scroll-auto " + (this.props.modal.hideCloseButton ? "no-close-btn" : "")}>
          {/* Have to add extra div for scrolling in firefox browser */}
          <div className="scroll-auto-h-100">
            {!this.props.modal.hideCloseButton && this.backButtonHtml(() => {
              this.clearLoginData();
              this.props.clearPendingPurchase();
              this.closeLogin(null, interstitialProps);
              if(this.props.modal.parentCallback) {
                this.props.modal.parentCallback();
              }
            })}
            {/* Lot of things to do on back button, that's why calling LoginFlow independently */}
            <LoginFlow
              emailSelected={this.state.emailSelected}
              login_data={this.state.login_data}
              updateEmailSelected={this.updateEmailSelected}
              headerLogo={headerLogo}
              selectBack={this.selectBack}
              allProps={allProps}
              modalLogin={true}
              runningOnMobile={this.props.runningOnMobile}
            />
          </div>
        </div>
      )
    } else if (this.props.modal.type === "subscribe") {
      return (
        <div id="overlay" className="user-access">
          <ErrorDisplay dismiss={() => {
            this.dismissAction();
            this.props.clearPendingPurchase();
          }} errorMsg={this.props.modal.message} errorHeading={this.props.modal.heading} />
        </div>
      )
    } else if (this.props.modal.type === "subscriptions") {
      const interstitialProps = this.props.modal.interstitialModalDetails;
      return (
        <div id="overlay" className={"user-access subscribe-page " + (this.props.modal.hideCloseButton ? "no-close-btn" : "")}>
          {/* Have to add extra div for scrolling in firefox browser */}
          <div className="scroll-auto-h-100">
            {!this.props.modal.hideCloseButton && this.backButtonHtml(() => {
              this.dismissAction(null, interstitialProps)
            })}
            <Subscribe
              feeds={this.props.modal.feeds}
              subscriptions={this.props.modal.feeds.subscriptions}
              background={"#808080"}
              item={this.props.modal.content}
              parent_id={this.props.modal.parent_id}
              isOpenedFromModal={true}
            />
          </div>
        </div>
      )
    } else if (this.props.modal.type === "purchase") {
      return null
    } else if (this.props.modal.type === "payment") {
      const cleengClass = configuration.payment_provider === 'cleeng' ? "cleeng-payment" : "";
      return (
        <>
          {this.props.modal.tvodWatchmanModal && this.tvodWatchman(this.props.modal.tvodWatchmanModal, true)}
          <div id="overlay" className={`user-access payment font-black ${cleengClass}`}>
            <div className="">
              {this.backButtonHtml(() => {
                this.props.clearPendingPurchase()
                this.closeLogin(null, this.props.modal.tvodWatchmanModal);
              })}
              <ConfirmPayment
                feeds={this.props.modal.feeds}
                onCancel={(e) => {
                  this.props.clearPendingPurchase()
                  this.closeLogin(e, this.props.modal.tvodWatchmanModal);
                }}
                modalData={this.props.modal}
                vizioModalClose={(e) => this.goBackToPrevious(null, true)}
              />
            </div>
          </div>
        </>
      )
    } else if (this.props.modal.type === "linkModal") {
      return (
        <div id="overlay" className="user-access">
          <LinkSection item={this.props.modal.content} onClose={this.dismissAction}/>
        </div>
      )
    } else if (this.props.modal.type === "error") {
      return (
        <div id="overlay" className="user-access">
          <ErrorDisplay dismiss={this.dismissAction} errorMsg={this.props.modal.message} errorHeading={this.props.modal.heading} />
        </div>
      )
    } else if (this.props.modal.type === 'exit_app') {
      return (
        <FocusLock>
          <div id="overlay" className="user-access exit-popup" style={{ background: 'black',zIndex: 250 }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 'inherit', flexDirection: 'column' }}>
              <div role="heading" id="exit_app_text">
                {ALL_C.kCtvExitMsg}
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '13em', marginTop: '4em' }}>
                <button aria-label={`${ALL_C.kCtvExitMsg}, ${ALL_C.kNo}`} onClick={e => this.exitCtvApp(false)} className="focusable" tabIndex={getTabIndex()} style={{ background: '#99bdff', padding: '1em 4em', borderRadius: '1em', fontSize: '1em' }}>{ALL_C.kNo}</button>
                <button aria-label={`${ALL_C.kCtvExitMsg}, ${ALL_C.kYes}`} onClick={e => this.exitCtvApp(true)} className="focusable" tabIndex={getTabIndex()} style={{ background: '#f5f5f5', padding: '1em 4em', borderRadius: '1em', marginTop: '2em', fontSize: '1em' }}>{ALL_C.kYes}</button>
              </div>
            </div>
          </div>
        </FocusLock>
      );
    } else if (this.props.modal.type === "interstitialScreen") {
        return (
          <div id="overlay" className="interstitial">
            {!this.props.modal.hideCloseButton && this.backButtonHtml(() => {
                this.props.closeModal();
                this.goBackToPrevious()
              })}
            <InterstitialScreen
              {...this.props.modal}
              runningOnMobile={this.props.runningOnMobile}
              feeds={this.props.feeds}
            />
          </div>
        )
    } else if(this.props.modal.type === "terms_privacy_screen") {
      return(
        <div id="overlay" className="user-access" style={{ background: 'black', paddingTop: '2em', paddingLeft: '1em' }} role="dialog" aria-labelledby='terms-heading' aria-describedby='terms-desc'>
          {/* Added back button in terms_privacy modal */}
          {!this.props.modal.hideCloseButton && this.backButtonHtml(() => {
            if(isSmartTv()) {
              this.setState({ login_data: this.props.modal.message.login_data },() => {
                if (this.props.modal.last_modal_data) {
                  this.props.openModal(this.props.modal.last_modal_data);
                } else {
                  this.props.closeModal();
                }
                // this.props.closeModal();
                this.goBackToPrevious();
              })
            } else {
              this.props.closeModal();
              this.goBackToPrevious();
            }
          })}
          <h3 id="terms-heading">{ this.props.modal.message.type }</h3>
          <p id="terms-desc">
            &nbsp; {ALL_C.kISReadVisit} &nbsp; {this.props.modal.message.url }
          </p>
          <ReactFocusLock>
            <div className='center-align' style={{marginTop: '5em'}}>
              <div id="terms-privacy-back-btn" className="focusable" tabIndex="-1" onClick={() => this.closeTermsPrivacyScreen()} aria-label={ALL_C.kGoBack} aria-describedby="terms-heading terms-desc">
                {ALL_C.kGoBack}
              </div>
            </div>
          </ReactFocusLock>
        </div>
      );
    } else if(this.props.modal.type === "cleengAccount") {
      return (
      <div id="overlay" className="user-access cleeng">
        {!this.props.modal.hideCloseButton && this.backButtonHtml(() => {
            this.props.closeModal();
            this.goBackToPrevious()
          })}
          <Provider store={store}>
            <div id="cleeng-container">
              {this.props.modal.cleengComponent === "account" && <MyAccount /> }
              {this.props.modal.cleengComponent === "subscriptions" && <Subscriptions /> }
            </div>
          </Provider>
      </div>
    )} else {
      return (null)
    }
  }
}

const mapStateToProps = (state) => ({
  modal: state.modal.modal,
  logged_in: state.user.logged_in,
  user: state.user.user,
  isUserLoading: state.user.isLoading,
  isMeteringLoading: state.user.meteringLoading,
  metering_views_seen: state.user.metering_views_seen,
  userError: state.user.error,
  isCounterPolling: state.user.entitlementCounterPolling,
  isPurchasePending: state.subscription.purchase.isPending,
  isVizioPaymentModalOpen: state.vizioAccount.modalOpen,
  isPlayerSettingsModalOpen: state.user.playerSettingsModalOpen,
})

const mapDispatchToProps = {
  closeModal: closeModal,
  openModal: openModal,
  saveItem: saveItem,
  saveTvodItem: saveTvodItem,
  clearPendingPurchase,
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Modal));
