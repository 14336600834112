import React from 'react';
import { getTabIndex } from '../helperfunctions/common';
import { getRole } from '../helperfunctions/ctvHelper';
import { getAllConstants } from '../helperfunctions/regionalization';

class ErrorDisplay extends React.Component {
    constructor(props){
        super(props);
    }

    componentDidMount() {
        const error_btn = document.getElementById("error_display_btn");
        if(error_btn) error_btn.focus();
    }

    render(){
        const { kDismiss } = getAllConstants();
        if(document.getElementsByClassName("user-access")[0] && document.getElementsByClassName("user-access")[0].getElementsByClassName('logoHolder')[0])
            document.getElementsByClassName("user-access")[0].getElementsByClassName('logoHolder')[0].style.display = "none";
        return (
            <div className="invalidScreen">
                <div className="invalidContent">
                    <h3>{this.props.errorHeading}</h3>
                    <p id="error_msg">{this.props.errorMsg}</p>
                    <a { ...getRole('button') } id="error_display_btn" aria-describedby="error_msg" tabIndex={getTabIndex()} href="#" onClick={(e) => {e.preventDefault(); this.props.dismiss();}} className="btn-dismiss focusable">{this.props.dismissButtonText || kDismiss}</a>
                </div>
            </div>
        )
    }
}

export default ErrorDisplay;