
import * as ACTIONS from "../constants/actionTypes";

const DEFAULT_STATE = {
  streamingAllowed: true,
  maxStreamCount: 0,
  fetchingStreams: false,
  allStreamObject: {} // cid as key
};

export default function reducer(state=DEFAULT_STATE, action) {
  switch (action.type) {
    case ACTIONS.TVOD_STREAMING_ALLOWED: {
      return {
        ...state, 
        streamingAllowed: true, 
        maxStreamCount: 0,
        allStreamObject: {...state.allStreamObject, ...action.payload}
      }
    }
    case ACTIONS.TVOD_STREAMING_MAX_OUT: {
      return {
        ...state, 
        streamingAllowed: false,
        maxStreamCount: action.payload
      }
    }
    case ACTIONS.GET_TVOD_STREAMING_PENDING: {
      return {
        ...state, 
        fetchingStreams: true, 
      }
    }
    case ACTIONS.GET_TVOD_STREAMING_RESOLVED: {
      return {
        ...state, 
        fetchingStreams: action.payload.stillFetching,
        allStreamObject: {...state.allStreamObject, ...action.payload.obj}
      }
    }
    case ACTIONS.GET_TVOD_STREAMING_REJECTED: {
      return {
        ...state, 
        fetchingStreams: false,
      }
    }
    case ACTIONS.CLEAR_TVOD_STREAMING_DATA: {
      return {
        ...DEFAULT_STATE
      }
    }
  }

  return state
}