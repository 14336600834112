import React from 'react';
import { connect } from "react-redux"

import { openModal } from "../actions/modalActions"
import { EPG_THEME } from '../constants/common';
import configuration from '../constants/config';
import { getSecondsFromDuration, getTabIndex, isMenuBlockGeofence, isSmartTv } from '../helperfunctions/common'
import { focusEpg, addRemoveAllNavigation } from '../helperfunctions/ctvHelper';
import { calculateEpgHeaderWidth, calculateEpgProgrammesWidth, calculateTimeBarWidth, calculateWidthFromSec, checkFutureItemAndGenerateArr, checkIfTodayOrFutureTime, compareAndReturnBigDate, formatEpgTime, getAllTimesForTimeline, getDateWithoutTimeZoneIfAny, getEpgTimeFromSeconds, getPlayingObject, getSecondsFromEpgTime, getStartingMinuts, makeGroupsFromChannelItems, supportTimezoneFormatting } from '../helperfunctions/epgHelper';
import playButton from "../images/icons/play-button.png";
import { elementScrollIntoView } from "seamless-scroll-polyfill";
import { getFontStyleFromFeed } from '../helperfunctions';

function checkValidChannelContent(content){
  if (content.regularLayout !== EPG_THEME) return false;
  const contents = content.contents;
  var isValidContent = true
  for(var i = 0; i < contents.length; i++) {
    let isFeed = false;
    let isVideo = false;
    if(contents[i].type === "menu") {
      isFeed = true;
      if(contents[i].contents) {
        for(var itemIndex = 0; itemIndex < contents[i].contents.length; itemIndex++ ) {
          const item = contents[i].contents[itemIndex];
          if(item.type === "menu" || item.type === "fake" || item.type === "live") {
            isValidContent = false;
            break;
          }
        }
      } else {
        isValidContent = false;
      }
    } else if(contents[i].type === "fake" || contents[i].type === "live") {
      isValidContent = false;
    } else {
      isVideo = true;
    }
    if(isFeed && isVideo) {//No mixed content
      isValidContent = false;
    }
    if(isValidContent) break;
  }
  return isValidContent;
}

function getFirstChannel(section) {
  let allContent = section && section.contents;
  let firstContent;
  for (let i=0; i<allContent.length; i++) {
    let content = allContent[i];
    if (content.type == "menu") {
      firstContent = content;
      break
    }
  }
  return firstContent;
}

function checkIfEpgLayout(content) {
  // if (content && content.contents && content.contents[0].type!=="menu" && content.regularLayout === EPG_THEME)
  if (content && content.contents && content.contents[0].type!=="menu" && content.type === "menu")
    return true;
  return false;
}

function checkIfFlatten(section) {
  let allContent = section && section.contents;
  let isFlatten = true;
  for (let i=0; i<allContent.length; i++) {
    let content = allContent[i];
    if (content.type == "menu") {
      isFlatten = false;
      break
    }
  }
  if (isFlatten && section && section.contents && section.contents[0].type!=="menu")
    return true;
  return false;
}
class EPGSection extends React.Component {
  constructor(props) {
    super(props);
    this.durationInterval = parseInt(configuration.epg_header_time)|| 60;
    let maxDateTime = this.getMaxDateTimeForTimeline();
    let epgChannels = this.getAllChannels();
    this.state = {
      desIndex: null,
      desContent: null,
      desId: null,
      desChannelIdentifier: null,
      desChannelDetail: null,
      currentDate: this.getCurrentDate(),
      epgChannels,
      maxDateTime,
      currDateTime: new Date(),
      programmesCount: 10,
      channelsCount: 5
    }
    this.timeUpdate = null;
    this.allColors = this.getEPGColors();
    this.all_contents = {};
    this.minDurationToShowChannelItemsInSec = 100
    this.handleIntersect = this.handleIntersect.bind(this)
    this.observers = [];
    this.channelObserver = [];
  }

  componentDidMount() {
    this.setInitialPreviewContent()
    this.timeUpdate = setInterval(() => {this.updateCurrentTime()}, 1000)
    if (isSmartTv()) { 
      if(configuration.use_light_sliders) {
        this.id = addRemoveAllNavigation({ 
          selector: '#EPG-layout .focusable',
          config: {
            enterTo: 'default-element',
            defaultElement: document.querySelector('#EPG-layout .focusable')  
          }
        });
        document.querySelector('#EPG-layout').addEventListener('sn:focused', this.handleCtvScrollView);        
      } else {
        focusEpg();
      }
    }
    /* Lazy Load */
    let elements = document.querySelectorAll('.intersection-observer')
    var elements_array = [...elements]; 
    elements_array.forEach((el) => {
      this.createObserver(el);
    })

    let channelElement = document.querySelector(".channel-intersection-observer")
    this.createObserver(channelElement);
  }

  componentDidUpdate(prevProps, prevState) {
    this.updateEpgChaneelsIfFeedChanged(prevProps);
    this.updateEpgChaneelsNextDay(prevState);
    
    if(prevState.channelsCount !== this.state.channelsCount) {
      this.afterSetStateFinished()
    }
  }


  createObserver(boxElement) {
    let observer;
  
    let options = {
      root: null,
      rootMargin: '-100px -100px 0px 0px',
      threshold: 0,
    };
  
    observer = new IntersectionObserver(this.handleIntersect, options);
    observer.observe(boxElement);
    if(boxElement.classList.contains("intersection-observer")) {
      this.observers = [...this.observers, { observer, boxElement }]
    }
    if(boxElement.classList.contains("channel-intersection-observer")) {
      this.channelObserver = [{observer, boxElement}]
    }
  }

  handleIntersect(entries, observer) {
    entries.map((entry) => {
      if (entry.isIntersecting) {
        if(entry.target.classList.contains("intersection-observer")){ 
          this.setState((prevState) => ({ programmesCount: prevState.programmesCount + 10 })) 
        }
        if(entry.target.classList.contains("channel-intersection-observer")) { 
          this.setState((prevState) => ({ channelsCount: prevState.channelsCount + 5 }))
        }
      }
    });
  }

  afterSetStateFinished() {
    let elements = document.querySelectorAll('.intersection-observer');
    var elements_array = [...elements];

    let currentObservers = this.observers;

    currentObservers.map((item) => {
      const {boxElement, observer} = item;
      observer.unobserve(boxElement);
    })

    this.observers = [];

    elements_array.forEach((el) => {
      this.createObserver(el);
    })
  }


  handleCtvScrollView(data) {
    if(!data || !data.detail) return;
    if(data.detail.direction == 'left' || data.detail.direction == 'right') {
      elementScrollIntoView(data.srcElement, { behavior: 'auto', block: 'center', inline: 'center'});
    }
  }

  updateEpgChaneelsIfFeedChanged(prevProps) {
    if ((prevProps.feeds.modified !== this.props.feeds.modified) || 
      ( prevProps.sectionDataChangedAt !== this.props.sectionDataChangedAt)) {
      this.updateChannels();
    }
  }

  updateEpgChaneelsNextDay(prevState) {
    let prevDate = new Date(prevState.currDateTime).getDate();
    let currDate = new Date(this.state.currDateTime).getDate();
    if (prevDate !== currDate) {
      this.updateChannels();
    }
  }

  updateChannels = () => {
    let epgChannels = this.getAllChannels();
    let maxDateTime = this.getMaxDateTimeForTimeline();
    this.setState({
      epgChannels,
      maxDateTime
    })
  }

  componentWillUnmount() {
    let currentObservers = this.observers;

    if(this.timeUpdate) {
      clearInterval(this.timeUpdate);
    }
    if(isSmartTv() && configuration.use_light_sliders) {
      addRemoveAllNavigation({ id: this.id });
      document.querySelector('#EPG-layout').removeEventListener('sn:focused', this.handleCtvScrollView)      
    }

    currentObservers.map((item) => {
      const { boxElement, observer } = item;
      observer.unobserve(boxElement);
    })

    const [{ boxElement, observer }] = this.channelObserver;
    observer.unobserve(boxElement);
  }

  getEPGColors() {
    const epg = (this.props.feeds && this.props.feeds.epg) || {};
    return {
      activeProgramColor: epg.activeProgramColor || "rgb(55, 109, 111)",
      borderColor: epg.borderColor || "rgb(47, 56, 60)",
      channelColor: epg.channelColor || "rgb(54, 68, 75)",
      channelLabelColor: epg.channelLabelColor || "white",
      inactiveProgramColor: epg.inactiveProgramColor || "rgb(40, 49, 53)",
      programLabelColor: epg.programLabelColor || "white",
      timeDisplayColor: epg.timeDisplayColor || "rgb(159, 172, 181)",
      timeMarkerColor: epg.timeMarkerColor || "rgb(118, 239, 239)"
    }
  }

  getMaxDateTimeForTimeline() {
    let maxDate = new Date();
    if(checkIfFlatten(this.props.content)) {
      const content = {...this.props.content};
      const epgEndTime = supportTimezoneFormatting(content.supportTimezone, content.epgEndTime)
      let timeWithoutTimeZone = getDateWithoutTimeZoneIfAny(epgEndTime)
      maxDate = compareAndReturnBigDate(maxDate, timeWithoutTimeZone)
    } else {
      let contentArr = this.props.content.contents;
      for(let i=0; i<contentArr.length; i++) {
        let channelDetails = {...contentArr[i]};
        if (checkIfEpgLayout(channelDetails)) {
          const epgEndTime = supportTimezoneFormatting(channelDetails.supportTimezone, channelDetails.epgEndTime)
          let timeWithoutTimeZone = getDateWithoutTimeZoneIfAny(epgEndTime);
          maxDate = compareAndReturnBigDate(maxDate, timeWithoutTimeZone)
        }
      } 
    }
    return maxDate;
  }

  setInitialPreviewContent = () => {
    let firstChannel;
    if (checkIfFlatten(this.props.content)) {
      firstChannel = this.props.content.identifier;
    } else {
      firstChannel = getFirstChannel(this.props.content).identifier;
    }
    const channel = this.state.epgChannels[firstChannel];
    const currentPlaying = channel && channel.channelItems && channel.channelItems.length ? getPlayingObject(channel).currPlayingItem : {};
    this.setState({
      desContent: currentPlaying,
      desId: currentPlaying.identifier,
      desChannelIdentifier: firstChannel,
      desChannelDetail: channel
    })
  }

  getAllChannels = () => {
    let sectionContent = this.props.content;
    let channels = {};
    const currentSeconds = (new Date().getHours() * 3600) + (new Date().getMinutes() * 60) + new Date().getSeconds();
    if(checkIfFlatten(this.props.content)) {
      channels[sectionContent.identifier] = this.getChannelDetails(sectionContent)
      channels[sectionContent.identifier].title = sectionContent.feedTitle || sectionContent.title;
    } else {
      let contentArr = sectionContent.contents;
      for(let i=0; i<contentArr.length; i++) {
        let channelDetails = contentArr[i];
        if (checkIfEpgLayout(channelDetails)) {
          channels[channelDetails.identifier] = this.getChannelDetails(channelDetails)
        }
      } 
    }
    return channels;
  }

  getChannelDetails = (channelDetails) => {
    let currChannel = {...channelDetails};
    let channelItems = currChannel.contents;
    let supportTimezone = currChannel.supportTimezone || false;
    delete currChannel.contents;
    currChannel.channelItems = channelItems && channelItems.length ? this.getAllChannelItems(channelItems, supportTimezone): [];
    return currChannel;
  }

  getAllChannelItems = (channelItems, supportTimezone) => {
    let maxEndTime = this.getMaxDateTimeForTimeline()
    let filteredChannelItems = channelItems.filter((videoItem, i) => {
      // if (i%4 ==0) return false
      const epgEndTime = supportTimezoneFormatting(supportTimezone, videoItem.epgEndTime)
      if (videoItem.epgStartTime && videoItem.epgEndTime && checkIfTodayOrFutureTime(epgEndTime, maxEndTime)) {
        return true;
      }
      return false;
    })
    let futureItems = [];
    let updatedChannelItems = filteredChannelItems.map(videoItem => {
      videoItem.epgStartTime = supportTimezoneFormatting(supportTimezone, videoItem.epgStartTime);
      videoItem.epgEndTime = supportTimezoneFormatting(supportTimezone, videoItem.epgEndTime);
      videoItem.epgStartTimeSec = getSecondsFromEpgTime(videoItem.epgStartTime);
      if (videoItem.epgEndTime) {
        videoItem.epgEndTimeSec = getSecondsFromEpgTime(videoItem.epgEndTime);
      }
      // if (!videoItem.epgEndTime && videoItem.duration) {
        //   let endTimeInSeconds = videoItem.epgStartTimeSec + getSecondsFromDuration(videoItem.duration);
        //   videoItem.epgEndTime = getEpgTimeFromSeconds(endTimeInSeconds);
        //   videoItem.epgEndTimeSec = endTimeInSeconds;
        // }
      videoItem.itemDurationInSec = videoItem.epgEndTimeSec - videoItem.epgStartTimeSec;
      const futureItem = checkFutureItemAndGenerateArr(videoItem, maxEndTime);
      futureItems = futureItems.concat(futureItem);
      return videoItem;
    })
    updatedChannelItems = updatedChannelItems.concat(futureItems);
    let sortedChannelItems = updatedChannelItems.sort((item1, item2) => {
      return item1.epgStartTimeSec - item2.epgStartTimeSec
    })
    return makeGroupsFromChannelItems(sortedChannelItems, maxEndTime);
  }

  updateCurrentTime() {
    const d = this.getCurrentDate();
    const currDate = new Date();
    if(d !== this.state.currentDate) {
      this.setState({currentDate: d, currDateTime: currDate})
    }
  }

  getCurrentDate() {
    const d = new Date().toDateString().substring(4,10);
    let h = new Date().getHours();
    let m = new Date().getMinutes();
    let format = "AM";
    if(h >= 12) {
      h = h -12;
      format = "PM";
    }
    if(h === 0) {
      h = 12
    }
    return `${d}, ${h}:${(m < 10 ? "0" + m : m )} ${format}`;
  }

  getTimeLineLocation() {
    // let m = (new Date().getMinutes()) // 60 minutes frame
    let startMin = getStartingMinuts(this.durationInterval);
    const h = new Date().getHours();
    const currMin = new Date().getMinutes();
    const currTotalMin = h*60 + currMin;
    const px = (currTotalMin - startMin)*360/this.durationInterval;
    // change will be triggered by update of state(currentDate)
    // For width change, also change value here
    // width of a show = 360px, time = 60 mins ie. 1 min = 6px (if width > 1024)
    // start location, width of channel Name - width of fixed time - 1/2 width of timeline. ie. (300 - 260 - 6)
    const width = window.screen.width;
    let loc = 0;
    if(width <= 580) {
      loc = (100 - 70 - 4) + (px);
    } else if(width <= 1024) {
      loc = (150 - 120 - 4) + (px);
    } else {
      loc = (300 - 260 - 6) + (px);
    }
    return `${loc}px`;
  }

  calculateHoursFromMin(min){
    let hours = Math.floor(min/60);
    let minutes = min%60;
    if (minutes < 10)
      minutes = `0${minutes}`
    
    return {
      hours,
      minutes
    }
  }

  getAllTimes() {
    const maxEndDate = this.state.maxDateTime;
    return getAllTimesForTimeline(this.durationInterval, maxEndDate);
  }

  handleMouseHover(content, channelDetails) {
    if(this.props.runningOnMobile) return;
    let contentIdentifier = content.identifier;
    if(this.state.desId !== contentIdentifier) {
      this.setState({
        desContent: content,
        desId: contentIdentifier,
        desChannelIdentifier: channelDetails.identifier
      })
    }
  }

  handleShowDescription(content, channelDetail) {
    if(this.state.desId === content.identifier) {
      if(this.props.runningOnMobile) {
        this.setState({
          desContent: null,
          desId: null,
          desChannelIdentifier: null,
          desChannelDetail: null
        })
      } else {
        this.playChannelVideo(channelDetail, content.videos);
      }
    } else {
      this.setState({
        desContent: content,
        desId: content.identifier,
        desChannelIdentifier: channelDetail.identifier,
        desChannelDetail: channelDetail
      })
    }
  }

  renderPreview() {
    const contents = this.state.desContent;
    const channelDetails = this.state.desChannelIdentifier && this.state.epgChannels[this.state.desChannelIdentifier]
    const cont = contents && contents.videos && contents.videos[0] || {};
    const {feeds} = this.props;
    const fontStyle = getFontStyleFromFeed(feeds);
    // const imageView = channelDetails && channelDetails.thumbOrientation || 'landscape';
    return (
      <div className="preview" style={fontStyle.secondaryFont}>
        {/* {cont && cont.cover && cont.cover.url && <img className="background" src={cont.cover.url}/>} */}
        <div className="preview-description">
          <div className="ch-show-name">
            <span style={fontStyle.primaryFont} className="title">{cont.title}</span>
            {/* <span className="sub-title">{cont.subtitle || ""}</span> */}
            <span className="sub-title">{cont.epgStartTime && `${formatEpgTime(cont.epgStartTime)} - ${formatEpgTime(contents.epgEndTime)}` || ""}</span>
            <span className="sub-title item-description">{cont.summary || ""}</span>
          </div>
          <div className={`ch-title`}>
            {cont.cover && cont.cover.url && <img src={cont.cover.url}/>}
            {/* <span>{cont.title}</span> */}
          </div>
        </div>
      </div>
    )
  }

  isPlayingClickedProgram(clickedProgramVideos, identifier) {
    if(!clickedProgramVideos || !identifier) return false;
    let flag = false;
    for(let i = 0; i < clickedProgramVideos.length; i++) {
      if(clickedProgramVideos[i].identifier === identifier) {
        flag = true;
        break;
      }
    }
    return flag;
  }

  playChannelVideo = (channel, clickedProgramVideos) => {
    const channelData = channel || {};
    const playingObj = getPlayingObject(channelData);
    const showAd = playingObj.item.showVideoAd;
    const channelTitle = channel.title;
    if ((playingObj.currPlayingItem && playingObj.currPlayingItem.haveNoData) || !playingObj.identifier) {
      return null;
    }
    if(clickedProgramVideos && !this.isPlayingClickedProgram(clickedProgramVideos, playingObj.identifier)) {
      // If clicked on future programs
      return null;
    }
    const firstpath = `${window.location.pathname}/${playingObj.identifier}`
    const epgPayload = {
      identifier: playingObj.identifier,
      time: new Date().getTime(),
      forwardDuration: playingObj.forwardDuration
    }

    this.props.openModal({head: EPG_THEME, type: "video", identifier: playingObj.identifier,
      content: {...playingObj.item, channelTitle: channelTitle}, show_ad: showAd, all_contents: playingObj.allContents, epgPayload: epgPayload,
      pathname: window.location.pathname, firstpath: firstpath
    })
  }

  renderChanelName(channel) {
    const {channelLabelColor, channelColor} = this.allColors;
    const {feeds} = this.props;
    const fontStyle = getFontStyleFromFeed(feeds);

    return (
      <div className="channel-link">
        <div style={{backgroundColor: channelColor}} className="channel-name focusable" tabIndex={getTabIndex()} onClick={() => this.playChannelVideo(channel)}>
          <span className="channel-info" style={{color: channelLabelColor, ...fontStyle.primaryFont}}>{channel.title}</span>
        </div>
      </div>
    )
  }

  renderDescription() {
    const contents = this.state.desContent;
    const cont = contents && contents.videos && contents.videos[0] || {};
    var secondary = this.props.feeds.secondaryColor || "#FFFFFF"
    // const cont = this.state.desContent || {};
    const divSecodaryBack = {backgroundColor: this.state.desContent.cover ? 'transparent' :secondary.replace('#FF', '#')}
    const imgSecodaryBack = {backgroundColor: secondary.replace('#FF', '#')}
    const { desChannelDetail, desContent } = this.state;
    const playingObj = getPlayingObject(desChannelDetail);
    const isPlaying = this.isPlayingClickedProgram(desContent.videos, playingObj.identifier)
    const showPlayButtonOverlay = this.props.feeds?.extras?.showPlayIcon ?? true;

    return (
      <div className="show-description" onClick={() => this.playChannelVideo(desChannelDetail, desContent.videos)}>
        <div className="wrapper">
          <div style={divSecodaryBack} className="img-video"
            data-title={cont.title} data-subtitle={cont.subtitle} data-summary={cont.summary} >
            {cont.cover && cont.cover.url && <img style={imgSecodaryBack} className="img-thumb" src={cont.cover.url}/>}
            {showPlayButtonOverlay && isPlaying && <img alt="Play" src={playButton} className="play-icon"/>}
          </div>
          <div>
            <div className="show-title">{cont.title}</div>
            <div className="show-time">{cont.epgStartTime && `${formatEpgTime(cont.epgStartTime)} - ${formatEpgTime(contents.epgEndTime)}` || ""}</div>
            {/* {cont.subtitle && cont.subtitle !== "" && <div className="show-subtitle">{cont.subtitle}</div>} */}
            {cont.summary && cont.summary !== "" && <div className="show-subtitle show-summary">{cont.summary}</div>}
          </div>
        </div>
      </div>
    )
  }

  calculateLeft = (startTime, currentSeconds) => {
    const pxPerSec = 360/this.durationInterval/60;
    return (startTime - currentSeconds)*pxPerSec;
  }


  renderEpgProgrammes = (content) => {
    const _self = this;
    const identifier = content.identifier;
    const channelDetails = this.state.epgChannels[identifier];
    const items = channelDetails.channelItems;
    const currentSeconds = getStartingMinuts(this.durationInterval)*60;
    const {feeds} = this.props;
    const fontStyle = getFontStyleFromFeed(feeds);
    const limitedItems = items.filter((_, idx) => idx < this.state.programmesCount);
    const lastItem = limitedItems.slice(-1)[0];
    const leftForIntersectionObs = _self.calculateLeft(lastItem.epgStartTimeSec, currentSeconds);

    return (<div className="channel-programes" 
      >
        {console.log("PROGRAMMES COUNT:", this.state.programmesCount)}
        {console.log("CHANNELS COUNT:", this.state.channelsCount)}
        {this.renderChanelName(channelDetails)}
      {
        limitedItems.map((item, i) => {
          const isSame = this.state.desContent && this.state.desContent.identifier === item.identifier;
          const classNames = isSame ? "show same-show focusable" : "show focusable";
          let width = calculateWidthFromSec(item.itemDurationInSec, this.durationInterval) - 1;
          let left = _self.calculateLeft(item.epgStartTimeSec, currentSeconds);
          let lastFocusRight = item.isLastItem ? {'data-sn-right': ""}: {};
          if (item.epgEndTimeSec <= currentSeconds) {
            return null;
          }
          if ((item.epgStartTimeSec < currentSeconds) && ((item.epgEndTimeSec - currentSeconds) > 100)) {
            left = 0;
            let duration = item.epgEndTimeSec - currentSeconds;
            width = calculateWidthFromSec(duration, this.durationInterval) - 1;
          }

          // needed 2px of space for focusable border to show
          if(isSmartTv() && left == 0) left = 2;

          const {activeProgramColor, inactiveProgramColor, borderColor, programLabelColor} = this.allColors;
          const backgroundColor = isSame ? activeProgramColor : inactiveProgramColor ;
          if (item.haveNoData) {
            return (
              <div className={'show'} style={{left: `${left}px`, backgroundColor: inactiveProgramColor, color: programLabelColor, borderColor: borderColor}} key={`nodata+${i}`}>
                <div className="show-title-wrapper" style={{width: `${width}px`, padding: `${15}px`, ...fontStyle.primaryFont}}>
                  <span className="show-title" style={{width: `100%`}}>{item.epgName}</span>
                </div>
              </div>
            )
          }
          return (
            <div className={classNames} 
              style={{left: `${left}px`, backgroundColor: backgroundColor, color: programLabelColor, borderColor: borderColor}}
              onClick={() => this.handleShowDescription(item, channelDetails)} // For Mobile
              onFocus={() => this.handleMouseHover(item, channelDetails)} // For CTV
              onMouseEnter={() => this.handleMouseHover(item, channelDetails)} // For web
              tabIndex={getTabIndex()}
              key={item.identifier}
              {...lastFocusRight}
            >
              <div className="show-title-wrapper" style={{width: `${width}px`, padding: `${15}px`}}>
                <span className="show-title" style={{width: `100%`, ...fontStyle.primaryFont}}>{item.epgName}</span>
                {item.subtitle && item.subtitle !== "" && <span className="show-subtitle" style={fontStyle.secondaryFont}>{item.subtitle}</span>}
              </div>
            </div>
            )
          })
        }
        <div className="show intersection-observer" style={{left: `${leftForIntersectionObs}px`, width:"50px", height:"50px", background: "#fff"}}></div>
    </div>)

  }

  isBlockedGeofence = (content) => {
    return isMenuBlockGeofence(this.props.isGeofencingOn, content, this.props.userCountry);
  }
  
  render() {
    if(!this.props.content || !this.props.content.contents ) {
      return null;
    }
    // const contents = this.props.content.feedTitle ? [this.props.content] :this.props.content.contents;
    const contents = checkIfFlatten(this.props.content) ? [this.props.content] :this.props.content.contents;
    const times = this.getAllTimes();
    const timeBarWidth = calculateEpgHeaderWidth(this.durationInterval, this.state.maxDateTime);
    const channelWrapperWidth = calculateEpgProgrammesWidth(this.durationInterval, this.state.maxDateTime);
    const style = {
      left: this.getTimeLineLocation()
    }
    const {timeDisplayColor, timeMarkerColor} = this.allColors;
    const {feeds} = this.props;
    const fontStyle = getFontStyleFromFeed(feeds);
    
    return (
      <div id="EPG-layout" className="content-box EPG-layout">
        {!this.props.runningOnMobile && this.renderPreview()}
        <div className={!this.props.runningOnMobile ? "main-content" : "main-content mobile"} style={fontStyle.secondaryFont}>
          <div className="epg-header" style={{width: `${timeBarWidth}px`}}>
            <div className="current-time" style={{color: timeMarkerColor}}>{this.state.currentDate}</div>
            <div className="time-head">
              {times.map((time, i) => {
                return <div key={time+i} className="time" style={{color: timeDisplayColor}}>{time}</div>
              })}
              <div className="time-line" style={style}>
                <span className="time-line-h" style={{background: timeMarkerColor}}></span>
                <span className="time-line-t" style={{borderColor: timeMarkerColor}}></span>
              </div>
            </div>
          </div>
          <div className="channel-wrapper" style={{width: `${channelWrapperWidth}px`}}>
            {contents.filter((_, idx) => idx < this.state.channelsCount).map((content, i) => {
              if(!content.contents) return null;
              if(!checkIfEpgLayout(content)) return null;
              if(!this.state.epgChannels[content.identifier]) return null;
              if(this.isBlockedGeofence(content)) return null;

              const desOpen = this.props.runningOnMobile && this.state.desContent && !this.state.desContent.haveNoData && this.state.desChannelIdentifier === content.identifier;
              return (
                <div key={content.identifier} className={"channel " + (desOpen ? "des-open" : "")}>
                  {this.renderEpgProgrammes(content)}
                  { desOpen && this.renderDescription()}
                </div>
              )
            })}
            <div className='channel channel-intersection-observer' style={{ opacity: "0", marginTop:"-50px" }}></div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isGeofencingOn: state.user.isGeofencingOn,
    userCountry: state.user.userCountry
  }
}

const mapDispatchToProps = {
  openModal: openModal
}

export default connect(mapStateToProps, mapDispatchToProps)(EPGSection);