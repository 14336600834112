import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { getTabIndex, isSmartTv, removeTransition } from '../../helperfunctions/common';
import { addRemoveAllNavigation } from '../../helperfunctions/ctvHelper';
import { resetParentalLock } from "../../actions/parentalLockAction"; 
import Background from '../Background';
import Loader from '../Loader';
import { getCustomUIColors } from '../../helperfunctions';
import JsSpatialNavigation from '../../ctvnavigation/js-spatial-navigation';
import { getAllConstants } from '../../helperfunctions/regionalization';
import backMobile from "../../images/icons/back-mobile.png";

function ResetParentalLock(props) {
  const { logged_in, user_profile, goBack, isFetching, feeds, runningOnMobile } = props;
  const [emailSent, setEmailSent] = useState(false);
  const STRINGS = getAllConstants();

  useEffect(() => {
    const focusId = addRemoveAllNavigation({ selector: '.lock-overlay .focusable' });
    return () => {
      addRemoveAllNavigation({ id: focusId });
    }
  }, [])

  useEffect(() => {
    const el = document.getElementById("primaryButton");
    if(el) {
      JsSpatialNavigation.focus(el);
    }
  }, [emailSent])

  const exitPrantalCode = () => {
    goBack();
  }

  const handleButtonClick = (btn) => {
    switch(btn) {
      case "sCancel":
      case "sOk":
        exitPrantalCode();
        break;
      case "sConfirm":
        props.resetParentalLock();
        setEmailSent(true);
        break;
    }
  }


  // ============================

  const renderBackButton = () => {
    let style = {}
    if(isSmartTv()) {
      style.display = 'none';
    }
    return (
      <span className={"icon-hollow_arrow backBtnSpan "+ (runningOnMobile ? "backBtnSpan-mobile" : "")}>
        <a id="more-back-button" href="" onClick={(e) => {
            e.preventDefault();
            exitPrantalCode();
          }} 
          className={(runningOnMobile ? "backBtn-mobile" : "backBtn")}
          style={{zIndex: 100, ...style}}>
            {runningOnMobile && <img src={backMobile} alt="Back"/>}
        </a>
      </span>
    )
  }

  if(!logged_in) return null;

  const buttons = emailSent ? [ "sOk" ] : [ "sCancel", "sConfirm" ];
  const { priButtonStyle, secButtonStyle } = getCustomUIColors(feeds);
  return (
    <div className='parental-lock'>
      <Background/>
      {renderBackButton()}
      {isFetching && <Loader />}
      <div className='lock-overlay'>
        <div className='title' aria-label={STRINGS.sResetAccessCode.toLowerCase()}>{STRINGS.sResetAccessCode}</div>
        {emailSent
          ? <div className='des' aria-label={STRINGS.sResetInstructionMsg.toLowerCase()}>{STRINGS.sResetInstructionMsg}</div>
          : (
            <>
              <div className='des' aria-label={STRINGS.sResetAccessCodeMsg.toLowerCase()}>{STRINGS.sResetAccessCodeMsg}</div>
              <div className='des' style={{fontWeight: "bold"}}>{user_profile.email || "Your Email Address"}</div>
              <div className='des' aria-label={STRINGS.sAreYouSure.toLowerCase()}>{STRINGS.sAreYouSure}</div>
            </>
          )
        }
        <div className='buttons'>
          {buttons.map(btn => {
            return (
              <span
                key={btn}
                role="button"
                aria-label={STRINGS[btn].toLowerCase()}
                className={`bttn focusable ${removeTransition()}`}
                tabIndex={getTabIndex()}
                onClick={() => handleButtonClick(btn)}
                style={ btn === "sCancel" ? secButtonStyle : priButtonStyle}
                id={btn === "sCancel" ? "" : "primaryButton"}
              >
                {STRINGS[btn]}
              </span>
            )
          })}
        </div>
      </div>
    </div>
  )
  
}

const mapStateToProps = (state) => ({
  isFetching: state.parentalLock.isFetching,
  logged_in: state.user.logged_in,
  feeds: state.feeds.feeds,
  user_profile: state.user.user_profile
})

const mapDispatchToProps = {
  resetParentalLock
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetParentalLock);
