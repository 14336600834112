import React, { Component } from "react";
//Do not remove the cleeng import, it's for importing their css first before ours.
import "./css/tvx-fonts.css";
import "./css/tvx-default.css";
import "./css/swiper.min.css";
import "./css/dev.css";
import "./css/tvx-large.css";
import "./css/desktop.css";
import "./css/style.css";
import "./css/responsive.css";
import "./css/webStyle.css";
import "./css/webResponsiveStyle.css";
import "./css/ctv.css";
import "./css/slick_slider_ctv.css";
import "./css/imaControlBar.css";
import "./css/spotlight.css";
import "./css/fullscreen.css";
import "./css/rtl.css";
import Layout from "./containers/Layout";
import { Provider } from "react-redux";
import store from "./store";
import config from "./constants/config";
import JsSpatialNavigation from "./ctvnavigation/js-spatial-navigation";
import { checkCorrectPlatform, isSmartTv } from "./helperfunctions/common";
import configuration from "./constants/config";
import { BrowserRouter as Router } from "react-router-dom";
import { getCtvKeyMappings, speakText } from "./helperfunctions/ctvHelper";
import { PLATFORM_SAMSUNG } from "./constants/common";

class App extends Component {
  componentDidMount() {
    this.initializeCTVNavigation();

    var domain = window && window.location.origin
    var isTvodAndNotCustomDomain = configuration.isAppTvodType && domain && (domain.includes('maz.tv') || domain.includes('zype.com'))

    if (config.web_custom_script_header && !isTvodAndNotCustomDomain) {
      document.head.insertAdjacentHTML(
        "beforeend",
        config.web_custom_script_header
      );
    }
    if (config.web_custom_script_footer && !isTvodAndNotCustomDomain) {
      document.body.insertAdjacentHTML(
        "beforeend",
        config.web_custom_script_footer
      );
    }
    if (
      configuration.kFirebaseAnalyticsEnabled &&
      configuration.kGA4ApiKey &&
      configuration.kFirebaseMeasurementID &&
      window &&
      window.gtag
    ) {
      window.gtag("config", configuration.kFirebaseMeasurementID);
    }

    if (checkCorrectPlatform([PLATFORM_SAMSUNG])) {
      document.addEventListener("sn:navigatefailed", this.navigationFailed);
    }

    if (isSmartTv()) {
      const root = document.getElementById("root");
      root && root.classList.add("hide-scrollbar");
    }
  }

  navigationFailed(data) {
    try {
      speakText(`Navigation failed, unable to move ${data.detail.direction}`);
    } catch (err) {
      console.log("Unable to speak", err);
    }
  }

  componentWillUnmount() {
    if (checkCorrectPlatform([PLATFORM_SAMSUNG])) {
      document.removeEventListener("sn:navigatefailed", this.navigationFailed);
    }
  }

  initializeCTVNavigation() {
    // if running on smart TV this method will initialize spatial navigation
    try {
      if (isSmartTv()) {
        JsSpatialNavigation.setKeyMap(getCtvKeyMappings());
        JsSpatialNavigation.init();

        if (configuration.use_light_sliders) {
          if (document.getElementById("root")) {
            document.getElementById("root").style.overflowX = "hidden";
          }

          JsSpatialNavigation.add(`navbar`, {
            selector: `#nav-bar .focusable`,
            enterTo: "default-element",
            defaultElement: "#nav-bar .active.focusable:first-child",
            straightOnly: false,
            leaveFor: {
              "down": ".content-box .focusable"
            }
          });
        } else {
          JsSpatialNavigation.add({
            selector: ".focusable"
          });
        }
      }
    } catch (err) {
      console.log("unable to add spatial naviogation", err);
    }
  }

  render() {
    if (!config.is_simulator) {
      if (config.title) {
        document.title = config.title;
      }
      let favicon = document.getElementById("favicon");
      if (config.favicon) {
        favicon.href = config.favicon;
      } else {
        favicon.href = "favicon.ico";
      }
    }
    return (
      <Provider store={store}>
        <Router>
          <Layout />
        </Router>
      </Provider>
    );
  }
}

export default App;
