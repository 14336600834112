import React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Thumbnail from "./Thumbnail";
import StandardTitle from "./StandardTitle";
import GridContent from "../components/GridContent";
import JumboContent from "../components/JumboContent";
import DetailContent from "../components/DetailContent";
import SpotLightContent from "../components/SpotLightContent";
import VideoGridContent from "./VideoGridContent";
import Loader from "./Loader";
import configuration from "../constants/config";
import SeriesContent from "./SeriesContent";
import ItemLockOverlay from "./ItemLockOverlay";
import Image from "./Image";
import ContentBadges from "./ContentBadges";

import {
  checkIfMenuOrItemDeeplink,
  checkIfToDoNothing,
  formatItemDuration,
  getTabIndex,
  isMenuBlockGeofence,
  isSmartTv,
  isFeedAvailable
} from "../helperfunctions/common";
import {
  deepLinkUrl,
  SERIES_THEME,
  VIDEO_GRID_THEME_THEME,
  HERO_SLIDER_THEME
} from "../constants/common";
import { openModal, setPreviewVideo } from "../actions/modalActions";
import { getFontStyleFromFeed } from "../helperfunctions";
import {
  clickParentDivWhenFocusedSpotlightContent,
  clickParentIfFullscreen,
  getSpatialDataAttribute
} from "../helperfunctions/ctvHelper";

function ChangeRoute(section, head) {
  if (
    head === undefined ||
    window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 1
    ] === head.identifier
  ) {
    var currentLocation = window.location.pathname + "/" + section.identifier;
  } else {
    var currentLocation =
      window.location.pathname +
      "/" +
      head.identifier +
      "/" +
      section.identifier;
  }
  return currentLocation;
}

function checkSingleMenu(contents) {
  var singleMenu = true;
  for (var i = 0; i < contents.length; i++) {
    if (
      contents[i].type === "menu" ||
      contents[i].type === "fake" ||
      contents[i].type === "live"
    ) {
      singleMenu = false;
      break;
    }
  }
  return singleMenu;
}

function checkMediaPresent(contents) {
  var mediaPresent = false;
  for (var i = 0; i < contents.length; i++) {
    if (contents[i].type === "vid" || contents[i].type === "image") {
      mediaPresent = true;
      break;
    }
  }
  return mediaPresent;
}

class Content extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentView: "",
      hover: false,
      sourceClass: "",
      showLoader: false,
      deeplinkActionUrl: null,
      identifier: null
    };
    this.getAriaAttributes = this.getAriaAttributes.bind(this);
  }

  onMouseEventHandler(hover, sourceClass, content = null, style) {
    if (hover) {
      this.setState({
        hover: hover,
        sourceClass: sourceClass
      });

      this.props.setPreviewVideo(content);
      if (isSmartTv()) {
        clickParentDivWhenFocusedSpotlightContent();
        clickParentIfFullscreen();
      }
    } else {
      this.setState({
        hover: hover,
        sourceClass: sourceClass
      });
    }

    if (
      !isSmartTv() &&
      sourceClass &&
      document.getElementById(sourceClass) &&
      style
    ) {
      // This is a workaround, On web state update is not updating background color https://github.com/MagAppZine/MAZ-Video-Ui/issues/1149
      document.getElementById(sourceClass).style.backgroundColor =
        style.backgroundColor;
    }
  }

  onLinkCLick(route, overlay, actionUrl, e, deepLinkPath, {content} = {}) {
    if (actionUrl && checkIfToDoNothing(actionUrl, this.props.feeds)) {
      e && e.preventDefault();
      return null;
    }
    if (actionUrl && actionUrl.includes("/registerwall/")) {
      e && e.preventDefault();
      this.props.openModal({ type: "login", screen: "registerWall" });
    } else if (actionUrl && actionUrl.includes("/register/")) {
      e && e.preventDefault();
      this.props.openModal({ type: "login", screen: "firstSave" });
    } else if (deepLinkPath) {
      this.props.history.push(deepLinkPath);
    } else if(content?.type === 'link'){
      this.props.openModal({
        type: "linkModal",
        content: content,
      });
    } else {
      if (overlay) {
        this.props.openModal({
          type: "error",
          message: "You must unlock this content in order to access it."
        });
      }
      this.props.setPreviewVideo({});// reset preview after click
      this.setState({ currentView: route });
      this.props.callbackParent(route);
    }
  }

  componentDidMount() {
    document.body.classList.remove("noNav");
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.oneFeedLoading &&
      !this.props.oneFeedLoading &&
      this.state.deeplinkActionUrl
    ) {
      this.onDeepLinkClick(
        null,
        this.state.identifier,
        this.state.deeplinkActionUrl
      );
    }
  }

  onDeepLinkClick = (e, identifier, deeplinkUrl) => {
    e && e.preventDefault();
    if (this.props.oneFeedLoading && checkIfMenuOrItemDeeplink(deeplinkUrl)) {
      this.setState({
        showLoader: true,
        deeplinkActionUrl: deeplinkUrl,
        identifier
      });
    } else {
      let deeplink = deepLinkUrl(
        deeplinkUrl,
        this.props.app_id,
        this.props.feeds
      );
      this.onLinkCLick(identifier, deeplink[1], deeplinkUrl, e, deeplink[0]);
    }
  };

  isBlockedGeofence(content) {
    return isMenuBlockGeofence(
      this.props.isGeofencingOn,
      content,
      this.props.userCountry
    );
  }

  getAriaPreviewSubtileText(content) {
    try {
      if (!content) return "";
      let text = "";
      if (content.duration) {
        text = formatItemDuration(content.duration);
      }

      text += content.copyright || "";
      text += content.rating || "";

      return text;
    } catch (err) {
      console.log("unable to get aria text");
    }
  }

  getAriaAttributes(content, parent_id) {
    try {
      if (!content) return {};

      if (this.props.layout == "spotlight") {
        const aria_attrs = {
          "aria-label": `${content.title || ""} ${
            content.summary || ""
          } ${this.getAriaPreviewSubtileText(content)}`,
          "aria-describedby": `content-head-${parent_id}`
        };
        return aria_attrs;
      } else {
        return {
          "aria-label": content.title,
          "aria-describedby": `content-head-${parent_id}`
        };
      }
    } catch (err) {
      console.log("unable to fetch aria");
    }
  }

  getContentsAvaialableInCountry(contents) {
    if (!configuration.isAppTvodType) return contents;

    if (!contents) return [];
    return contents.filter((item) =>
      isFeedAvailable(
        this.props.policyData.country,
        this.props.feeds.countryGroups,
        item.countryGroupId
      )
    );
  }

  onMenuClicked(e, content) {
    if (content.deeplinkUrl) {
      this.onDeepLinkClick(e, content.identifier, content.deeplinkUrl);
    } else {
      this.onLinkCLick(content.identifier, false, null, e, null, {content: content}) ;
    }
  }

  renderImage(colorStyle, cover) {
    return (
      <Image
        style={colorStyle}
        classNames={"img-thumb swiper-lazy"}
        cover={cover}
      />
    );
  }

  itemLockIconOverlay = (content) => {
    return <ItemLockOverlay feeds={this.props.feeds} content={content} />;
  };

  renderItemTitle(content, secondary, index, total) {
    return (
      <StandardTitle
        no_color={this.props.layout !== "jumbo"}
        locked={content.locked}
        access={content.access}
        content={content}
        type={"content"}
        secondary={secondary}
        feeds={this.props.feeds}
        runningOnMobile={this.props.runningOnMobile}
        layout={this.props.layout}
        heroIndex={this.props.layout === HERO_SLIDER_THEME && this.props.index}
        index={index}
        total={total}
      />
    );
  }

  renderMenuTitle(content, fontStyle) {
    if (this.props.layout === "spotlight") return null;
    if (this.props.layout === HERO_SLIDER_THEME && this.props.index === 0)
      return null;
    return (
      <div className="titleHolder">
        <p style={fontStyle.primaryFont}>{content.title}</p>
      </div>
    );
  }

  itemRenderer(content, index, primary, secondary) {
    const mainContent = this.props.content;
    content.showInterstitial =
      mainContent.showInterstitial || content.showInterstitial;
    content.locked = mainContent.locked;
    content.special = mainContent.special;
    content.displayTitle = mainContent.displayTitle;
    content.registerWall = mainContent.registerWall;
    content.parent_id = mainContent.identifier;
    content.inlineMenu = mainContent.inlineMenu || "";
    content.thumbOrientation = mainContent.thumbOrientation;
    if (!configuration.isAppTvodType) {
      content.access = mainContent.access;
    }
    return (
      <div
        key={content.identifier}
        className={"detail-swiper item-" + content.identifier}
      >
        <Thumbnail
          locked={mainContent.locked}
          show_ad={mainContent.showVideoAd}
          access={content.access}
          all_contents={this.getContentsAvaialableInCountry(
            mainContent.contents
          )}
          primary={primary}
          secondary={secondary}
          content={content}
          head={mainContent.identifier}
          detail={this.props.layout === "detail"}
          layout={this.props.layout}
          feeds={this.props.feeds}
          background={this.props.background}
          index={index}
          total={mainContent.contents.length}
          col={this.props.col}
        />
        {this.renderItemTitle(
          content,
          secondary,
          index,
          mainContent.contents.length
        )}
      </div>
    );
  }

  menuRenderer(content, colorData, index) {
    const {
      divPrimaryBack,
      imgPrimaryBack,
      divSecodaryBack,
      imgSecodaryBack,
      fontStyle
    } = colorData;
    const isHovered =
      this.state.hover &&
      this.state.sourceClass === "image-video-" + content.identifier;
    const mainContent = this.props.content;
    const toLink = content.deeplinkUrl || content.type === "link"
      ? "#"
      : ChangeRoute(content, mainContent);
    return (
      <div key={content.identifier} className="detail-swiper">
        <Link
          to={toLink}
          onClick={(e) => this.onMenuClicked(e, content)}
          className="thumbLink focusable"
          tabIndex={getTabIndex()}
          data-toggle="tab"
          onFocus={() =>
            this.onMouseEventHandler(
              true,
              "image-video-" + content.identifier,
              content
            )
          }
          onBlur={() =>
            this.onMouseEventHandler(
              false,
              "image-video-" + content.identifier,
              content
            )
          }
          {...getSpatialDataAttribute(index, mainContent.contents.length)}
          {...this.getAriaAttributes(
            content,
            mainContent.parent_id || mainContent.identifier
          )}
        >
          <div
            aria-hidden="true"
            style={isHovered ? divSecodaryBack : divPrimaryBack}
            className="img-video"
            id={"image-video-" + content.identifier}
            data-title={content.title}
            data-subtitle={content.subtitle}
            data-summary={content.summary}
            onMouseEnter={(hover, sourceClass) =>
              this.onMouseEventHandler(
                true,
                "image-video-" + content.identifier,
                content,
                divSecodaryBack
              )
            }
            onMouseLeave={(hover, sourceClass) =>
              this.onMouseEventHandler(
                false,
                "image-video-" + content.identifier,
                content,
                divPrimaryBack
              )
            }
          >
            {content.cover &&
              content.cover.url &&
              this.renderImage(
                isHovered ? imgSecodaryBack : imgPrimaryBack,
                content.cover
              )}
            <ContentBadges content={content} />
            {this.itemLockIconOverlay(content)}
          </div>
        </Link>
        {this.renderMenuTitle(content, fontStyle)}
      </div>
    );
  }

  flattenMenuRenderer(primary, secondary, fontStyle) {
    const mainContent = this.props.content;
    let imgBackColor, divBackgroundStyle;
    const divPrimaryBack = {
      backgroundColor: mainContent.cover
        ? "transparent"
        : primary.replace("#FF", "#")
    };
    const divSecodaryBack = {
      backgroundColor: mainContent.cover
        ? "transparent"
        : secondary.replace("#FF", "#")
    };
    if (
      this.state.hover &&
      this.state.sourceClass === "image-video-" + mainContent.identifier
    ) {
      //If thumbnail image is present then on hover, background must be transparent (BUG - https://github.com/MagAppZine/MagAppZine-MagControl/issues/7190#issuecomment-699616347)
      divBackgroundStyle = divSecodaryBack;
      imgBackColor = secondary.replace("#FF", "#");
    } else {
      divBackgroundStyle = divPrimaryBack;
      imgBackColor = primary.replace("#FF", "#");
    }
    const imgBackStyle = { backgroundColor: imgBackColor };

    const toLink = this.props.content.type === "link"
      ? "#"
      : ChangeRoute(mainContent, undefined);
    return (
      <div key={mainContent.identifier} className="detail-swiper">
        <Link
          to={toLink}
          onClick={(route, overlay) =>
            this.onLinkCLick(mainContent.identifier, false, null, null, null, {content: this.props.content})
          }
          className="thumbLink focusable"
          tabIndex={getTabIndex()}
          data-toggle="tab"
          onFocus={() =>
            this.onMouseEventHandler(
              true,
              "image-video-" + mainContent.identifier,
              mainContent
            )
          }
          onBlur={() =>
            this.onMouseEventHandler(
              false,
              "image-video-" + mainContent.identifier,
              mainContent
            )
          }
          {...getSpatialDataAttribute(0, 1, this.props.layout)}
          {...this.getAriaAttributes(
            mainContent,
            mainContent.parent_id || mainContent.identifier
          )}
        >
          <div
            aria-hidden="true"
            style={divBackgroundStyle}
            className="img-video"
            id={"image-video-" + mainContent.identifier}
            data-title={mainContent.title}
            data-subtitle={mainContent.subtitle}
            data-summary={mainContent.summary}
            onMouseEnter={(hover, sourceClass) =>
              this.onMouseEventHandler(
                true,
                "image-video-" + mainContent.identifier,
                mainContent,
                divSecodaryBack
              )
            }
            onMouseLeave={(hover, sourceClass) =>
              this.onMouseEventHandler(
                false,
                "image-video-" + mainContent.identifier,
                mainContent,
                divPrimaryBack
              )
            }
          >
            {mainContent.cover &&
              this.renderImage(imgBackStyle, mainContent.cover)}
            <ContentBadges content={mainContent} />
            {this.itemLockIconOverlay(mainContent)}
          </div>
        </Link>
        {mainContent.type === "menu" || mainContent.type === "live"
          ? this.renderMenuTitle(mainContent, fontStyle)
          : this.renderItemTitle(mainContent, secondary, 0, 1)}
      </div>
    );
  }

  render() {
    const contents = [];
    var primary = this.props.feeds.primaryColor || "#FFFFFF";
    var secondary = this.props.feeds.secondaryColor || "#FFFFFF";
    var nosaveicon = false;
    const fontStyle = getFontStyleFromFeed(this.props.feeds);
    if (this.isBlockedGeofence(this.props.content)) {
      return null;
    }

    if (
      (this.props.content.mazContentType === "saved" || this.props.content.catalog === "favorite") &&
      this.props.layout === VIDEO_GRID_THEME_THEME
    ) {
      //No saved section from MAZ ID feed for mobile
      return null;
    }

    if (this.state.showLoader) {
      return <Loader />;
    }

    if (
      this.props.content.contents &&
      checkSingleMenu(this.props.content.contents)
    ) {
      this.props.content.contents.map(function (content, i) {
        if (
          !isFeedAvailable(
            this.props.policyData.country,
            this.props.feeds.countryGroups,
            content.countryGroupId
          )
        )
          return null;
        const itemContent = this.itemRenderer(content, i, primary, secondary);
        if (itemContent) {
          contents.push(itemContent);
        }
      }, this);
    } else if (
      this.props.content.contents === undefined &&
      (this.props.content.type === "vid" ||
        this.props.content.type === "image" ||
        this.props.content.type === "menu" ||
        this.props.content.type === "link" ||
        this.props.content.type === "live") &&
      isFeedAvailable(
        this.props.policyData.country,
        this.props.feeds.countryGroups,
        this.props.content.countryGroupId
      )
    ) {
      const flattenContent = this.flattenMenuRenderer(
        primary,
        secondary,
        fontStyle
      );
      if (flattenContent) {
        contents.push(flattenContent);
      }
    } else if (this.props.content.contents !== undefined) {
      var mediaPresent = checkMediaPresent(this.props.content.contents);
      this.props.content.contents.map((content, i) => {
        const divSecodaryBack = {
          backgroundColor: content.cover
            ? "transparent"
            : secondary.replace("#FF", "#")
        };
        const divPrimaryBack = {
          backgroundColor: content.cover
            ? "transparent"
            : primary.replace("#FF", "#")
        };
        const imgPrimaryBack = { backgroundColor: primary.replace("#FF", "#") };
        const imgSecodaryBack = {
          backgroundColor: secondary.replace("#FF", "#")
        };

        if (
          this.isBlockedGeofence(content) ||
          !isFeedAvailable(
            this.props.policyData.country,
            this.props.feeds.countryGroups,
            content.countryGroupId
          )
        ) {
          return null;
        } else if (
          (content.mazContentType === "saved" || content.catalog === "favorite") &&
          this.props.layout === VIDEO_GRID_THEME_THEME
        ) {
          return null;
          //No saved section from MAZ ID feed for mobile
        } else if (content.type === "vid" || content.type === "image" || (content.type === "live" && this.props.isDynamicList)) {
          // case where video and images are with menu inside a feed
          const itemContent = this.itemRenderer(content, i, primary, secondary);
          if (itemContent) {
            contents.push(itemContent);
          }
        } else {
          const colorData = {
            divPrimaryBack,
            imgPrimaryBack,
            divSecodaryBack,
            imgSecodaryBack,
            fontStyle
          };
          const menuContent = this.menuRenderer(content, colorData, i);
          contents.push(menuContent);
          if (!mediaPresent) {
            nosaveicon = true;
          }
        }
      }, this);
    }

    if (!contents.length) {
      return null;
    }

    const propData = {
      key: contents.length,
      content: this.props.content,
      title: this.props.content.title,
      contents: contents,
      feeds: this.props.feeds,
      layout: this.props.layout
    };

    if (this.props.layout === "detail") {
      return (
        <DetailContent
          {...propData}
          secondary={secondary}
          detail_content={this.props.content.contents}
        />
      );
    } else if (this.props.layout === "jumbo") {
      return <JumboContent currentSection={this.props.currentSection} {...propData} />;
    } else if (
      this.props.layout === "spotlight" ||
      this.props.layout === "fullscreen"
    ) {
      return (
        <SpotLightContent
          index={this.props.index}
          section={this.props.section}
          {...propData}
        />
      );
    } else if (this.props.layout === VIDEO_GRID_THEME_THEME) {
      return <VideoGridContent nosaveicon={nosaveicon} {...propData} />;
    } else if (this.props.layout === HERO_SLIDER_THEME) {
      return this.props.runningOnMobile ? (
        <VideoGridContent
          index={this.props.index}
          isHero={true}
          nosaveicon={nosaveicon}
          currentSection={this.props.currentSection}
          {...propData}
        />
      ) : (
        <GridContent
          index={this.props.index}
          nosaveicon={nosaveicon}
          currentSection={this.props.currentSection}
          {...propData}
        />
      );
    } else if (this.props.layout === SERIES_THEME) {
      return (
        <SeriesContent
          nosaveicon={nosaveicon}
          runningOnMobile={this.props.runningOnMobile}
          {...propData}
        />
      );
    } else {
      return <GridContent nosaveicon={nosaveicon} currentSection={this.props.currentSection} {...propData} />;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    oneFeedLoading: state.feeds.oneFeedLoading,
    isGeofencingOn: state.user.isGeofencingOn,
    userCountry: state.user.userCountry,
    policyData: state.feeds.policyData
  };
};

const mapDispatchToProps = {
  openModal: openModal,
  setPreviewVideo
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Content)
);
