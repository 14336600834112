import axios from "axios";
import { PLATFORM_LG, PLATFORM_SAMSUNG } from "../constants/common";
import configuration from "../constants/config";
import { checkCorrectPlatform, getRandomString, isSmartTv } from "./common";
import { FetchCurrentDeviceID } from '../helperfunctions/ctvHelper';
import { getTitleArrayForMenus } from "../constants/analytics";
import { getMazAnalyticsApi, getMazAnalyticsApiToken } from "../constants/initializer";

let current_device_uuid = null;
let subscriberEventSent = false;

function isSendingMazEvent() {
  if(configuration.kEnableMazReporting == undefined ) {
    //don't exist
    if(configuration.env === "production") {
      return true;
    }
    return false;
  }
  return configuration.kEnableMazReporting;
}

function formatToTwoDigit(num) {
  if(num > 9) {
    return num.toString();
  } else {
    return `0${num}`;
  }
}

function getCreatedAt() {
  const d = new Date();
  const month = formatToTwoDigit(d.getMonth() + 1);
  const day = formatToTwoDigit(d.getDate());
  return `${d.getFullYear()}-${month}-${day}`
}

function getUserSession() {
  let sessionId;
  if(checkCorrectPlatform([PLATFORM_SAMSUNG]) && window.tizen) {
    sessionId = `${current_device_uuid}_${window.tizen.package.getPackageInfo().appIds}`;
  } else if(checkCorrectPlatform([PLATFORM_LG]) && window.webOS) {
    sessionId = `${current_device_uuid}_${window.webOS.fetchAppId()}`;
  } else {
    sessionId = localStorage.getItem(`user_session_${configuration.app_id}`)
    if(!sessionId) {
      sessionId = getRandomString();
      localStorage.setItem(`user_session_${configuration.app_id}`, sessionId)
    }
  }
  return sessionId;  
}

async function sendMazEvent(data) {
  try {
    const isSending = isSendingMazEvent()
    if(!isSending) return;

    if(isSmartTv() && !current_device_uuid) {
      await getCurrentDeviceUUID();
    }

    let appSessionId = getUserSession();
    if(!appSessionId) return;
  
    const eventData = {
      ...data,
      appId: configuration.app_id.toString(),
      appName: configuration.title,
      platform: configuration.platform,
      appSessionId: appSessionId,
      createdAt: getCreatedAt()
    }
    axios.post(getMazAnalyticsApi(), eventData, {headers: {"X-API-Key": getMazAnalyticsApiToken()}});
  } catch(err) {
    console.log('err',err)
  }
}

export function sendActiveUserEvent() {
  const data = {
    eventCategory: "user",
    eventName: "Active User",
    eventLabel: "App Launched",
    eventValue: "0",
    eventStartTime: new Date().toISOString(),
    eventEndTime: new Date().toISOString()
  }
  sendMazEvent(data);
}

export function sendSubscribeEvent() {
  if(subscriberEventSent) return;
  subscriberEventSent = true;
  const data = {
    eventCategory: "subscriber",
    eventName: "Active Subscriber",
    eventLabel: "Subscription Used",
    eventValue: "0",
    eventStartTime: new Date().toISOString(),
    eventEndTime: new Date().toISOString()
  }
  sendMazEvent(data);
}

export function sendAppUsageMinutes(count, start) {
  const data = {
    eventCategory: "app_session",
    eventName: "App Usage",
    eventLabel: "Beacon | App Usage Time (seconds)",
    eventValue: count.toString(),
    eventStartTime: start,
    eventEndTime: new Date().toISOString()
  }
  sendMazEvent(data);
}

export function sendVideoMinutes(event, feeds, video_path, video_title, videoPlayCount=null) {
  let title = "";
  if(video_path.includes("search")) {
    title = "search | " + video_title;
  } else if (video_path.includes("saved_items")) {
    title = "saved | " + video_title;
  } else {
    let responseArray = getTitleArrayForMenus(feeds, video_path);
    let screenName = responseArray[0].join(' | ');
    title = screenName;
    if(videoPlayCount !== null) {
      title = videoPlayCount + ' | ' + screenName + ' | ' + video_title;
    } else if(responseArray[2]) {
      title = screenName + ' | ' + video_title;
    }
  }
  const data = {
    ...event,
    eventCategory: "video",
    eventName: "Video Streamed",
    eventLabel: title,
    eventEndTime: new Date().toISOString()
  }
  sendMazEvent(data);
}

export async function getCurrentDeviceUUID() {
  try {
    if(!current_device_uuid) {
      current_device_uuid = await FetchCurrentDeviceID();
    }
  
    return current_device_uuid;
  } catch(err) {
    return null;
  }
}

export function resetSubscriberEvent() {
  subscriberEventSent = false;
}