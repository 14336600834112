import React from 'react';
import { connect } from 'react-redux';
import { getFbLoginCode, fbLoginPolling } from '../actions/userActions';
import Loader from './Loader';
import { openModal, closeModal } from '../actions/modalActions';
import ReactFocusLock from 'react-focus-lock';
import { getTabIndex, isSmartTv } from '../helperfunctions/common';

const FbIconImage = () => <img src={require('../images/icons/fb-white.png')} className="fb-icon"/>
class FbDeviceLogin extends React.Component {
    constructor(props) {
        super(props);
        this.stopTimer = null;
        this.intervalTimer = null;
        this.state = {
            showDeviceCode: false,
            textCode: null
        }
    }

    componentDidMount() {
        if (this.props.isGdpr && (this.props.isUserConcent && this.props.openFbTab)) {
            this.getLoginCode();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevProps.fbCodeData && this.props.fbCodeData) {
            this.startFbPolling(this.props.fbCodeData);
        }
        if (prevProps.isFbPolling && !this.props.isFbPolling) {
            this.checkPollingEnd();
        }
        if (prevProps.isFbUserDataLoading && !this.props.isFbUserDataLoading) {
            this.doLoginAction();
        }
    }

    doLoginAction = () => {
        if (this.props.fbUserData) {
            const email = this.props.fbUserData.email;
            const name = this.props.fbUserData.name;
            const accessToken = this.props.fbUserData.token;
            this.props.callback({
                email,
                name,
                accessToken
            })
        }
    }

    componentWillUnmount() {
        this.removeInterval();
    }

    checkPollingEnd = () => {
        this.removeInterval();
    }

    getLoginCode = () => {
        this.setState({
            showDeviceCode: true
        })
        this.props.getFbLoginCode();
    }

    startFbPolling = (fbData) => {
        const _self = this;
        const timeoutTime = 1000 * 60 * 5; //5 minutes
        this.stopTimer = setTimeout(() => {
            _self.onTimerEnd();
        }, timeoutTime);
        this.setState({
            textCode: `${fbData.user_code}`
        })
        this.intervalTimer = setInterval(() => {
            _self.props.fbLoginPolling(fbData.code)
        }, 6000)
    }

    onTimerEnd = () => {
        this.removeInterval();
        this.props.closeModal();
    }

    removeInterval = () => {
        if (this.intervalTimer) {
            clearInterval(this.intervalTimer);
        }
        if (this.stopTimer) {
            clearTimeout(this.stopTimer);
        }
    }

    onFbClick = (e) => {
        if (this.props.isGdpr && !this.props.isUserConcent) {
            this.props.onGdprClick(e);
        } else {
            this.getLoginCode();
        }
    }

    onBack = () => {
        if (isSmartTv) {
            this.setState({showDeviceCode: false, textCode: null});
            this.props.cancelFbLogin();
            this.removeInterval();
        }
    }

    render() {
        const fbButton = (<button className="btn-fbIcon focusable" tabIndex={getTabIndex()} onClick={(e) => this.onFbClick(e)}><FbIconImage/> Log In with Facebook</button>);
        if (this.state.showDeviceCode) {
            return (
                <>
                    {isSmartTv() && <button id="fb_back_button_ctv" onClick={this.onBack} style={{display: "none"}}>back</button>}
                    {this.state.textCode
                        ? (<Loader loadingType={"fbLoader"} loaderText={this.state.textCode}/>)
                        : (<Loader />)
                    }
                    {fbButton}
                </>
            )
        }
        return (
            fbButton
        )
    }
}

const mapStateToProps = (state) => ({
    fbPollData: state.user.fbPollData,
    fbCodeData: state.user.fbCodeData,
    isFbPolling: state.user.isFbPolling,
    isFbUserDataLoading: state.user.isFbUserDataLoading,
    fbUserData: state.user.fbUserData

})

const mapDispatchToProps = {
    getFbLoginCode,
    fbLoginPolling,
    closeModal
}

export default connect(mapStateToProps, mapDispatchToProps)(FbDeviceLogin);