import React, {Component} from "react";
import { formatItemDuration, isSmartTv } from '../helperfunctions/common';
import { truncateString } from './../helperfunctions';
import { handleTextTruncate } from '../helperfunctions/ctvHelper';
import { checkIfSectionLevelContent } from '../helperfunctions';

class PreviewDescription extends Component {
  componentDidMount() {
    this.elm = document.getElementById('previewSummary');    
    handleTextTruncate(this.elm);
  }

  componentDidUpdate() {
    handleTextTruncate(this.elm);
  }

  render(){
    const {duration, rating, copyright} = this.props.item;
    let payload = {
      duration: formatItemDuration(duration),
      year: copyright,
      rating: rating
    };
    if(!duration && !copyright && !rating) payload = null;

    const fontStyle = this.props.fontStyle;
    const description  = this.props.description;
    let descriptionLines = 4;
    const screenWidth = window.innerWidth;
    const addExtraLine = isSmartTv() || checkIfSectionLevelContent();
    if(screenWidth >= 1366 && addExtraLine) descriptionLines = 5;

    if(!description.title) {
      descriptionLines += 2;
    } else if(!description.subtitle && !payload) {
      descriptionLines++;
    }

    return(
      <div
        id = "previewDescription"
        // aria-live="polite"
        // aria-atomic="true"
      >
        <div
          className="previewTitle"
          style={fontStyle.primaryFont}
          id="previewMainTitle"
          // aria-live="polite"
          // aria-atomic="true"  
        >
          {description.title}
        </div>
        {payload && <div className="previewSubtitle" style={{ ...fontStyle.secondaryFont, display: 'flex' }}>
            {Object.keys(payload).map((k, i) => { 
                if(!payload[k]) return null;
                return <span key={i} className={k === 'rating' ? 'upperCase' : ''}>{payload[k]}</span>;
              }
            )}
          </div>
        }

        {!payload && description.subtitle && <div className= "previewSubtitle"  style={fontStyle.secondaryFont}>
            {description.subtitle}
          </div>
        }
        <div
          id ="previewSummary"
          style={{ ...fontStyle.secondaryFont, 'WebkitLineClamp': descriptionLines }}
          // aria-live="polite"
          // aria-atomic="true"  
        >
          {description.summary}
        </div>
      </div>
    )
  }
}

export default PreviewDescription;