import React from 'react';
import { connect } from "react-redux";
import { managePlayAfterPurchase } from "../actions/subscriptionAction";
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import crossIcon from '../images/failure.png';
import checkIcon from '../images/success.png';
import * as analytics  from '../constants/analytics';
import { isSmartTv } from '../helperfunctions/common';
import configuration from '../constants/config';
import JsSpatialNavigation from '../ctvnavigation/js-spatial-navigation';
import { addRemoveAllNavigation } from '../helperfunctions/ctvHelper';
import ReactFocusLock from 'react-focus-lock';
import { getAllConstants } from '../helperfunctions/regionalization';

let ALL_C = {};

function freeTrailLabel() {
    return {
        7: "with 7 days Free Trial",
        31: "with 1 month Free Trial",
        62: "with 2 months Free Trial",
        93: "with 3 months Free Trial"
    }
}

function SubDurationLabel(){
    return {
        31: `Monthly Subscription`,
        93: `3 Months Subscription`,
        186: `6 Months Subscription`, 
        366: `Annual Subscription`
    };
}

class PaymentMsg extends React.Component {
    constructor(props) {
        super(props);
        this.timeout=null;
        this.state = {
            showBacklink: false
        }
        ALL_C = getAllConstants();
    }

    componentDidMount() {
        // focus on first focusable element
        if(isSmartTv() && configuration.use_light_sliders) {
            this.sn_id = addRemoveAllNavigation({ selector: '#payment-page .focusable' });
            JsSpatialNavigation.focus(this.sn_id);
        }
        const self = this;
        this.hideBackIcon();
        const details = this.getPaymentDetails();
        this.timeout = setTimeout(function() {
            if(details.success) {
                self.props.managePlayAfterPurchase(true);
            }
            self.props.history.push(details.redirectSection);
            self.setState({showBacklink: true});
        }, 3000)
        this.sendPurchaseAnalytics();
    }

    showBackIcon = () => {
        if (!isSmartTv() && document.querySelector('.icon-hollow_arrow.backBtnSpan'))
            document.querySelector('.icon-hollow_arrow.backBtnSpan').style.display="block";
    }

    hideBackIcon = () => {
        if (document.querySelector('.icon-hollow_arrow.backBtnSpan'))
            document.querySelector('.icon-hollow_arrow.backBtnSpan').style.display="none";
    }

    componentWillUnmount() {
        this.showBackIcon();
        if (this.timeout) clearTimeout(this.timeout);
        // remove navigation
        if(isSmartTv() && configuration.use_light_sliders) {
            addRemoveAllNavigation({ id: this.sn_id });
        }
    }

    redirectBack = () => {
        const details = this.getPaymentDetails();
        this.props.history.push(details.redirectSection);
    }


    sendPurchaseAnalytics = () => {
        var paymentData = this.getPaymentDetails();
        if(paymentData.success === true){
            if(paymentData.purchaseType === 'sub'){
                var subscriptionType = SubDurationLabel()[paymentData.subDuration]
                analytics.setEvent('Subscriptions', 'Sub Purchase', subscriptionType);
            }else{
                analytics.setEvent('Purchases', 'Single Purchase', paymentData.title);
            }
        }
    }

    getPaymentDetails = () => {
        const query = queryString.parse(this.props.location.search);
        let details = {}
        details.success = query.type === 'success' ? true : false;
        details.price = query.price;
        let section = query.section;
        if(query.prev_purchase) {
            details.redirectSection = `${section}?prev_purchase=${query.prev_purchase}&sub_id=${query.sub_id}&justpurchased=true`;
        } else {
            details.redirectSection = `${section}?justpurchased=true`;
        }
        details.purchaseType = query.purchaseType;
        details.title = query.title === 'undefined' ? null : query.title;
        details.subDuration = query.duration === 'undefined' ? null: query.duration;
        details.freeTrial = query.ft === 'undefined' ? null : query.ft;
        return details;
    }

    renderPaymentFailure = (backgndColor, fontColor, tv_style, _this) => {
        return (
            <div id="payment-page" className="font-black payment-page focusable" tabIndex="-1" style={{backgroundColor: backgndColor, color: fontColor, ...tv_style}} aria-labelledby="payment-failed">
                {!isSmartTv() && <img className="failureImgIcon cnf-icon" src={crossIcon} alt="payment failure"/>}
                {isSmartTv() && <div className="failureImgIcon cnf-icon"></div>}
                <div className="pay-cnf-msg" id="payment-failed">{ALL_C.kPaymentFailed}</div>
                <div className='redirect-text'>{ALL_C.kPaymentRedirect}</div>
                {_this.state.showBacklink && (<div className='redirect-bck' onClick={_this.redirectBack}>{ALL_C.kPaymentClick}</div>)}
            </div>
        )
    }

    renderPaymentSuccess = (backgndColor, fontColor, tv_style, purTypeMsg, purchseDetails, _this) => {
        return (
            <div id="payment-page" className="font-black payment-page focusable" tabIndex="-1" style={{backgroundColor: backgndColor, color: fontColor, ...tv_style}} aria-labelledby="payment-success">
                {!isSmartTv() && <img className="successImgIcon cnf-icon" src={checkIcon} alt="payment success"/>}
                {isSmartTv() && <div className="successImgIcon cnf-icon"></div>}
                <div className="pay-cnf-msg" id="payment-success">{ALL_C.kPaymentSuccess}</div>
                {!configuration.isAppTvodType && <div className="pay-title">You have successfully purchased {purTypeMsg}.</div>}
                {
                    purchseDetails.purchaseType === 'sub' ?
                    (<div className="dur-price">
                        <div><b>{ALL_C.kPaymentDuration}: </b>{SubDurationLabel()[purchseDetails.subDuration]}</div>
                        <div><b>{ALL_C.kPaymentPrice}: </b>${purchseDetails.price}</div>
                        {purchseDetails.freeTrial &&
                            <div>{freeTrailLabel()[purchseDetails.freeTrial]}</div>
                        }
                    </div>)
                    :
                    (<div className="dur-price">
                        <div><b>{ALL_C.kPaymentTitle}: </b>{purchseDetails.title}</div>
                        <div><b>{ALL_C.kPaymentPrice}: </b>${purchseDetails.price}</div>
                    </div>)
                }
                <div className='redirect-text'>{ALL_C.kPaymentRedirect}</div>
                {_this.state.showBacklink && (<div className='redirect-bck' onClick={_this.redirectBack}>{ALL_C.kPaymentClick}</div>)}
            </div>
        )
    }


    render() {
        const subUIData = this.props.feeds.subscriptionUIMetadata;
        const tv_style = {};
        const backupColor = "#ddd6d6";
        if(isSmartTv()) {
            tv_style.display = "flex"
        }
        const backgndColor = (subUIData && subUIData.background.color && subUIData.background.color.replace("#FF","#")) || backupColor;
        const fontColor = this.props.feeds.loginUIMetadata.newSub && this.props.feeds.loginUIMetadata.newSub.colors.headerText;
        const purchseDetails = this.getPaymentDetails();
        const purTypeMsg = purchseDetails.purchaseType === 'sub' ? 'a subscription' : 'an item';
        if (!purchseDetails.success) {
            if (isSmartTv()) {
                return <ReactFocusLock>{this.renderPaymentFailure(backgndColor, fontColor, tv_style, this)}</ReactFocusLock>
            } else {
                return this.renderPaymentFailure(backgndColor, fontColor, tv_style, this);
            }
        }
        if (isSmartTv()) {
            return <ReactFocusLock>{this.renderPaymentSuccess(backgndColor, fontColor, tv_style, purTypeMsg, purchseDetails, this)}</ReactFocusLock>
        } else {
            return this.renderPaymentSuccess(backgndColor, fontColor, tv_style, purTypeMsg, purchseDetails, this);
        }
    }
}

const mapDispatchToProps = {
    managePlayAfterPurchase
}
  
export default withRouter(connect(null, mapDispatchToProps)(PaymentMsg))