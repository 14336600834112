import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getTabIndex, isSmartTv, removeTransition } from '../../helperfunctions/common';
import { addRemoveAllNavigation, getHref } from '../../helperfunctions/ctvHelper';
import Background from '../Background';
import SetParentalLock from './SetParentalLock';
import backMobile from "../../images/icons/back-mobile.png";
import ResetParentalLock from './ResetParentalLock';
import { getAllConstants } from '../../helperfunctions/regionalization';
import { elemOrientation } from '../../helperfunctions';

import './parentalLock.css';


function ParentalLock(props) {
  const { feeds, goBack, logged_in, codeConfigured, runningOnMobile } = props;
  const [newCodeFlow, setNewCodeFlow] = useState(false);
  const [resetCodeFlow, setResetCodeFlow] = useState(false);

  useEffect(() => {
    const focusId = addRemoveAllNavigation({ selector: '.parental-lock-container .focusable' });
    return () => {
      addRemoveAllNavigation({ id: focusId });
    }
  }, [])

  const renderLogo = () => {
    if(runningOnMobile) return null;

    return (
      <div className="left-wrapper">
        {feeds.logo && <img src={feeds.logo.url}/>}
      </div>
    )
  }

  const renderBackButton = () => {
    let style = {}
    if(isSmartTv()) {
      style.display = 'none';
    }
    return (
      <span className={"icon-hollow_arrow backBtnSpan "+ (runningOnMobile ? "backBtnSpan-mobile" : "")}>
        <a id="more-back-button" href="" onClick={(e) => {
            e.preventDefault();
            goBack();
          }} 
          className={(runningOnMobile ? "backBtn-mobile" : "backBtn")}
          style={{zIndex: 100, ...style}}>
            {runningOnMobile && <img src={backMobile} alt="Back"/>}
        </a>
      </span>
    )
  }

  if(newCodeFlow) {
    return (
      <SetParentalLock
        goBack={() => setNewCodeFlow(false)}
        runningOnMobile={runningOnMobile}
      />
    )
  }
  if(resetCodeFlow) {
    return (
      <ResetParentalLock
        goBack={() => setResetCodeFlow(false)}
        runningOnMobile={runningOnMobile}
      />
    )
  }

  const resetEnabled = logged_in && codeConfigured;
  const { sSetNewCode, sResetCode } = getAllConstants();
  return (
    <div className="parental-lock-container">
      <Background/>
      {renderBackButton()}
      <div className='parental-lock-wrapper'>
        {renderLogo()}
        <div className="right-wrapper" dir={elemOrientation(feeds)}>
            <div 
              className={`item focusable ${removeTransition()}`}
              tabIndex={getTabIndex()} 
              { ...getHref('#') } 
              onClick={() => setNewCodeFlow(true)}
              aria-label={sSetNewCode.toLowerCase()}
            >
              {sSetNewCode}
            </div>
            <div 
              className={`item focusable ${removeTransition()}`}
              tabIndex={getTabIndex()} { ...getHref('#') } 
              onClick={() => {
                if(resetEnabled) {
                  setResetCodeFlow(true)
                }
              }} 
              disabled={!resetEnabled}
              aria-label={sResetCode.toLowerCase()}
            >
              {sResetCode}
            </div>
        </div>
      </div>
    </div>
  )

}

const mapStateToProps = (state) => ({
  logged_in: state.user.logged_in,
  feeds: state.feeds.feeds,
  codeConfigured: state.parentalLock.codeConfigured
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(ParentalLock);