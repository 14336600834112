import { elementScrollIntoView } from "seamless-scroll-polyfill";
import JsSpatialNavigation from '../ctvnavigation/js-spatial-navigation';
import configuration from "../constants/config";
import { PLATFORM_LG, PLATFORM_SAMSUNG, PLATFORM_VIZIO, PLATFORM_WEB, PLATFORM_XBOX, PLATFORM_ZEASN } from "../constants/common";
import { checkCorrectPlatform, detectPlatform, isSmartTv } from "./common";
import { updateTextTrack } from "./videojsHelper";

/**
 * This function will check if focus in one of the fullscreen or spotlight theme. If yes then we are clicking parent div, which are calling the action to 'setLockedContent' in spotlightContent.js file.
 */
export function clickParentDivWhenFocusedSpotlightContent() {
    const spotFullSlider = document.querySelector('#fullscreen-grid,#spotlight-grid');
    if (spotFullSlider && spotFullSlider.contains(document.activeElement)) {
        const nearestContainer = document.activeElement.closest(".spotlight.slider-box");
        nearestContainer && nearestContainer.click();
    }
}

/**
 * Function will check if focus in fullscreen section. If yes then click the parent div, which call the action to mute, unmute video for background videos.
 */
export function clickParentIfFullscreen() {
    const spotFullSlider = document.querySelector('#fullscreen-grid');
    if (spotFullSlider && spotFullSlider.contains(document.activeElement)) {
        const nearestContainer = document.activeElement.closest(".fullscreen-parent-row");
        nearestContainer && nearestContainer.click();
    }
}

export function checkIfFocusInsideBanner() {
    const bannerParent = document.querySelector('.banner');
    if (bannerParent && bannerParent.contains(document.activeElement))
        return true;
    return false
}

export function focusEpg() {
    let parentEpg = document.getElementById('EPG-layout');
    let tot_els = parentEpg.querySelectorAll('.focusable');
    if (!tot_els.length) return;
    tot_els.forEach((el, j) => {
        if (el.getAttribute('data-row')) return;

        el.setAttribute('data-row', j);

        el.addEventListener('sn:focused', function (data) {
            elementScrollIntoView(this, { behavior: 'smooth', block: 'center', inline: 'center' });
        });
    });
}

export function convertSwiperParamsToSlick(params, _self) {
    // default slick params
    let slick_params = {
        dots: false,
        infinite: false,
        autoplay: false,
        cssEase: "linear",
        arrows: false,
        speed: 250,
        focusOnSelect: false,
        lazyLoad: 'ondemand',
        useCSS: false,
    };

    slick_params['slidesToShow'] = params['slidesPerView'];
    if(params['direction'] && params['direction'] == 'vertical') {
        slick_params['vertical'] = true;
        slick_params['slidesToShow'] = 1;
    }

    if(params['breakpoints']) {
        slick_params['responsive'] = [];
        for(let key in params['breakpoints']) {
            slick_params['responsive'].push({
                breakpoint: key,
                settings: {
                    slidesToShow: params['breakpoints'][key]['slidesPerView'] || slick_params['slidesToShow']
                }
            });
        }
    }

    return slick_params;
}

export function makeContentFocusableSection({ disableDefaultSection, content_identifier, config, time=500}) {
    // try {
    //     JsSpatialNavigation.add(`navbar`, {
    //         selector: `#nav-bar .focusable`,
    //         enterTo: 'default-element',
    //         defaultElement: '#nav-bar .swiper-slide .active.focusable:first-child',
    //         straightOnly: false,
    //         leaveFor: {
    //             'down': '.slider-box .focusable'
    //         }
    //     });
    // } catch(err) {
    //     console.log("navbar",err);
    // }

    return new Promise((res, rej) => {
        try {
            const selector = `.content-${content_identifier} .focusable`;
            let default_config = {
                selector,
                enterTo: 'last-focused',
                defaultElement:  `.content-${content_identifier} .focusable`,//document.querySelector(selector),
                ...config // overwrite default configuration
            };

            let id = `section_${content_identifier}`;
            if(!JsSpatialNavigation.sectionExist(`section_${content_identifier}`)) {
                id = JsSpatialNavigation.add(id,default_config);
            } else {
                JsSpatialNavigation.enable(id);
            }
    
            document.querySelector(`.content-${content_identifier}`) && document.querySelector(`.content-${content_identifier}`).addEventListener('sn:focused', snFocusEventHandler);
             
            res(id);
        } catch(err) {
            let emsg = err.message;
            if(emsg.includes('already existed')) {
                // console.log("err",err.message);
                JsSpatialNavigation.enable(`section_${content_identifier}`);

                document.querySelector(`.content-${content_identifier}`) && document.querySelector(`.content-${content_identifier}`).addEventListener('sn:focused', snFocusEventHandler);
            }
            rej(err);
        }   
    })
}

export function removeContentFromFocusableSection({ enableDefaultSection, content_identifier, id}) {
    return new Promise((res, rej) => {
        try {
            if(enableDefaultSection) {
                // enable default section
                // JsSpatialNavigation.enable('section-1');
            }
    
            // remove created section
            // JsSpatialNavigation.remove(id);
            // JsSpatialNavigation.remove(`section_${content_identifier}`);
            JsSpatialNavigation.disable(`section_${content_identifier}`);
    
            document.querySelector(`.content-${content_identifier}`) && document.querySelector(`.content-${content_identifier}`).removeEventListener('sn:focused', snFocusEventHandler);    
    
            // JsSpatialNavigation.remove(`navbar`);
            res(true)
        } catch(err) {
            console.log("err",err);
            rej(false)
        }     
    });
}

export function enableDisableNavbarFocusable(enable = true) {
    try {
        // JsSpatialNavigation.clear();
        if(enable) {
            JsSpatialNavigation.enable('navbar');
        } else {
            JsSpatialNavigation.disable('navbar');
        }
    } catch(err) {
        console.log("err",err);
    }
}

export function addRemoveAllNavigation({ id, selector, config = {}}) {
    try {
        if(!id) {
            return JsSpatialNavigation.add({
                selector,
                ...config
            });
        } else {
            JsSpatialNavigation.disable(id);
        }
    } catch(err) {
        console.log("error",err);
    }
}

export function getSpatialDataAttribute(_index, _total, _layout = null) {
    if(!isSmartTv() && !configuration.use_light_sliders) return;

    if(!_index == null || _total == null) return;
    if(_layout && _layout == 'detail') {
        if(_index == _total - 1) {
            return { 'data-sn-down': '' }
        }
        return {
            // 'data-sn-right': ''
        };
    }

    _index = parseInt(_index);
    _total = parseInt(_total);

    if(_index == 0) {
        if(_total == 1) {
            return {
                'data-sn-left': '',
                'data-sn-right': ''
            }
        } else {
            return {
                'data-sn-left': ''
            }
        }
    } else if(_index == _total - 1) {
        return {
            'data-sn-right': ''
        }
    }
    return;
}

export function snFocusEventHandler(data) {
    if(!data || !data.detail) return;
    if(data.srcElement && data.srcElement.classList.contains('hg-black')) {
        return;
    }

    if(data.detail.direction == 'up' || data.detail.direction == 'down') {
        elementScrollIntoView(data.srcElement, { behavior: 'auto', block: 'center' });
    } else if(checkCorrectPlatform([PLATFORM_XBOX])) {
        elementScrollIntoView(data.srcElement, { behavior: 'auto', block: 'center' });
    }
}

export function FetchCurrentDeviceID() {
    return new Promise((resolve, reject) => {
        if (checkCorrectPlatform([PLATFORM_LG])) {
            if (!window.webOSDev) {
                reject(null);
                return;
            }
            window.webOSDev.LGUDID({
                onSuccess: function (res) {
                    // user device from server
                    if (res && res.id) {
                        resolve(res.id);
                    } else {
                        resolve(null);
                    }
                },
                onFailure: function (res) {
                    reject(null);
                }
            });
        } else if (checkCorrectPlatform([PLATFORM_SAMSUNG])) {
            if(!window.tizen) {
                reject(null);
                return;
            }
            let did = window.tizen.systeminfo.getCapability("http://tizen.org/system/tizenid") || window.webapis.productinfo.getDuid();
            resolve(did);
        } else if(checkCorrectPlatform([PLATFORM_VIZIO])) {
            if(!window.VIZIO) {
                reject(null);
                return;
            }
            window.VIZIO.getDeviceId(function(deviceId) {
                if(!deviceId) {
                    reject(null);
                } else {
                    resolve(deviceId);
                }
            });
        } else if(checkCorrectPlatform([PLATFORM_ZEASN])) {
            if(!window.onDeviceInfoReady) {
                reject(null);
                return;
            }

            window.onDeviceInfoReady((device_info) => {
                try {
                    resolve(device_info.Product.deviceID);
                } catch(err) {
                    reject(null);
                }
            });
        }
    })
}

export function retainFocusOnBackPress(last_focus, modal, url) {
    if(last_focus) {
      JsSpatialNavigation.pause();
      try {   
        setTimeout(() => {
            if(last_focus) {
                let elm
                if(modal && last_focus['modals'][modal]) {
                    elm = last_focus['modals'][modal];
                } else if(url && last_focus['paths'][url]) {
                    elm = last_focus['paths'][url];
                }
                const tvodInter = document.getElementsByClassName('tvod-interstitial') && document.getElementsByClassName('tvod-interstitial')[0];
                if(elm && !tvodInter) {
                    elm.focus();
                    JsSpatialNavigation.focus(elm);
                    elementScrollIntoView(elm, { behavior: 'auto', block: 'center' });
                }
            }
        },250);
      } catch(err) {
        console.log('error on retain focus',err)
      }
      JsSpatialNavigation.resume();
    };
}

export function alignToTop(e) {
    if(!isSmartTv()) return;
    elementScrollIntoView(e.currentTarget, { behavior: 'auto', block: 'center' });
}

export function scrollToTop(e) {
    if (!isSmartTv()) return;

    try {
        const insideInterstitial = e.currentTarget.closest('.item-play');
        if (insideInterstitial) {
            elementScrollIntoView(e.currentTarget, { behavior: 'auto', block: 'end' });
        }
    } catch (e) {
        return;
    }

} 

export function isTextEllipsis(elm) {
    return elm.scrollHeight > elm.clientHeight;
}

export function handleTextTruncate(elm) {
    /**
     * This method truncates the text
     * on last full stop which can be
     * displayed on the screen, and it
     * removes all the web links to avoid
     * complexity
     */
    if(!checkCorrectPlatform([PLATFORM_SAMSUNG])) return; // Only for samsung
    try {
        if(elm && isTextEllipsis(elm)) {
            let text = elm.innerHTML;
            // removed the links from the text
            text = text.replace(/(?:https?|ftp):\/\/[\n\S]+/g, '');
            text = text.split('.');

            //checking one by one if the text can be shown
            if(text.length > 1) {
                let show_text = "";
                for(let i=0; i < text.length-1;i++) {
                    elm.innerHTML = `${show_text} ${text[i]}.`;
                    if(isTextEllipsis(elm)) {
                        elm.innerHTML = `${show_text}`;
                        break;
                    } else {
                        show_text += `${text[i]}.`;
                    }
                }
            } else {
                elm.innerHTML = "";
            }
        }
    } catch(err) {
        console.log("Error occured while text truncate",err);
    }
}

export function getHref(href = "") {
    if(!isSmartTv()) {
        return {
            href
        };
    }
    return {};
}

export function getRole(role) {
    if(!isSmartTv()) return {};
    return {
        role
    };
}

export function getParameterByName(name, url) {
    if(!name || !url) return;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function toggleSubtitlesVisibility(player, show_subtitle = true) {
    try {
        let mode;
        if (!configuration.kUseTheoPlayer) {
            mode = show_subtitle ? "showing" : "hidden"
            updateTextTrack(player, mode); //For video js
        } else {
            mode = show_subtitle ? "showing" : "disabled"
            if(!player) return;
            const tracks = player.textTracks;
            for (let i = 0; i < tracks.length; i++) {
              let track = tracks[i];
              track.mode = mode;
            }
        }
    } catch(err) {
        console.log("unable to toggle visibility!");
    }
}

export function getCtvKeyMappings() {
    // key mappings for Samsung, LG, VIZIO and XBOX
    const key_mappings = {
        '37': 'left',
        '214': 'left',
        '205': 'left',
        '140': 'left',
        'VK_LEFT': 'left',
        '39': 'right',
        '213': 'right',
        '206': 'right',
        '141': 'right',
        'VK_RIGHT': 'right',
        '38': 'up',
        '211': 'up',
        '203': 'up',
        '138': 'up',
        'VK_UP': 'up',
        '40': 'down',
        '212': 'down',
        '204': 'down',
        '139': 'down',
        'VK_DOWN': 'down'
    }

    const zeasn_constants = getZeasnKeyConstants();

    if(Object.keys(zeasn_constants).length) {
        key_mappings[zeasn_constants['VK_LEFT']] = 'left';
        key_mappings[zeasn_constants['VK_RIGHT']] = 'right';
        key_mappings[zeasn_constants['VK_UP']] = 'up';
        key_mappings[zeasn_constants['VK_DOWN']] = 'down';
    }

    return key_mappings;
}

export function keyMappings(key_code) {
    //detect platform and return key maps accortding to that
    let parsed_key_code = isNaN(parseInt(key_code)) ? key_code : parseInt(key_code);
    const zeasn_constants = getZeasnKeyConstants();

    switch(parsed_key_code) {
        case 37:
        case 214:
        case 205:
        case 140:
        case zeasn_constants['VK_LEFT']:
            return 'LEFT';
        
        case 39:
        case 213:
        case 206:
        case 141:
        case zeasn_constants['VK_RIGHT']:
            return 'RIGHT';

        case 38:
        case 211:
        case 203:
        case 138:
        case zeasn_constants['VK_UP']:
            return 'UP';
                
        case 40:
        case 212:
        case 204:
        case 139:
        case zeasn_constants['VK_DOWN']:
            return 'DOWN';
        
        case 13:
        case 142:
        case 195:
        case zeasn_constants['VK_ENTER']:
            return 'OK';
        
        case 415:
        case zeasn_constants['VK_PLAY']:
            return 'PLAY';
        
        case 19:
        case zeasn_constants['VK_PAUSE']:
            return 'PAUSE';
        
        case 10252:
        case zeasn_constants['VK_PLAY_PAUSE']:
            return 'PLAY-PAUSE';

        case 413:
        case zeasn_constants['VK_STOP']:
            return 'STOP';
        
        case 417:
        case zeasn_constants['VK_FAST_FWD']:
            return 'FORWARD';
        
        case 412:
        case zeasn_constants['VK_REWIND']:
            return 'REWIND';

        default:
            return parsed_key_code;
    }
}

export function getBackBtnKeyCodes() {
    const back_key_codes = {
        '10009': true, // lg
        '461': true    // samsung
    };

    if(checkCorrectPlatform([PLATFORM_VIZIO])) {
        back_key_codes['8'] = true;
    } else if(checkCorrectPlatform([PLATFORM_XBOX])) {
        back_key_codes['198'] = true;
        back_key_codes['196'] = true;
    } else if(checkCorrectPlatform([PLATFORM_ZEASN])) {
        let VK_BACK = window.VK_BACK || 8;
        back_key_codes[VK_BACK] = true;
    }

    return back_key_codes;
}

export function checkIfHideNavbarOnLive(keyCode) {
    if(checkCorrectPlatform([PLATFORM_SAMSUNG])) {
        let keys = [10252, 412, 417, 415, 19, 413, 10232, 10233];
        if(keys.includes(keyCode)) {
          return false;
        }
    }

    return true;
}

export function getZeasnKeyConstants() {
    if(!checkCorrectPlatform([PLATFORM_ZEASN])) return {};

    return {
        VK_0: window.VK_0 || 48,
        VK_1: window.VK_1 || 49,
        VK_2: window.VK_2 || 50,
        VK_3: window.VK_3 || 51,
        VK_4: window.VK_4 || 52,
        VK_5: window.VK_5 || 53,
        VK_6: window.VK_6 || 54,
        VK_7: window.VK_7 || 55,
        VK_8: window.VK_8 || 56,
        VK_9: window.VK_9 || 57,
        VK_ENTER: window.VK_ENTER || 13,
        VK_LEFT: window.VK_LEFT || 37,
        VK_RIGHT: window.VK_RIGHT || 39,
        VK_UP: window.VK_UP || 38,
        VK_DOWN: window.VK_DOWN || 40,
        VK_BACK: window.VK_BACK || 8,
        VK_BACK_SPACE: window.VK_BACK_SPACE || 8,
        VK_PLAY: window.VK_PLAY || 415,
        VK_PAUSE: window.VK_PAUSE || 19,
        VK_STOP: window.VK_STOP || 413,
        VK_REWIND: window.VK_REWIND || 412,
        VK_FAST_FWD: window.VK_FAST_FWD || 417,
        VK_RED: window.VK_RED || 403,
        VK_BLUE: window.VK_BLUE || 66,
        VK_GREEN: window.VK_GREEN || 404,
        VK_YELLOW: window.VK_YELLOW || 89,
        VK_PAGE_UP: window.VK_PAGE_UP || 33,
        VK_PAGE_DOWN: window.VK_PAGE_DOWN || 34,
        VK_INFO: window.VK_INFO || 457,
        VK_PLAY_PAUSE: window.VK_PLAY_PAUSE || 10252
    };
}

export function handleNavItemClick(feeds, elementId) {
    if(!configuration.is_simulator) return;

    const navbar = document.getElementsByClassName("nav-view")[0];
    navbar && navbar.parentNode.classList.remove('fix-nav');
    var navbarLinks = document.getElementsByClassName("headerLinks")
    const {ottTabBarInactiveColor, ottTabBarActiveColor} = feeds;
    for(var i=0; i<navbarLinks.length; i++){
        navbarLinks[i].classList.remove("active")
        if(ottTabBarInactiveColor) {
        navbarLinks[i].style.color = ottTabBarInactiveColor.replace("#FF", "#")
        }
    }
    if(document.getElementsByClassName(elementId)[0]) {
        document.getElementsByClassName(elementId)[0].classList.add("active")
        if(ottTabBarActiveColor) {
            document.getElementsByClassName(elementId)[0].style.color = ottTabBarActiveColor.replace("#FF", "#")
        }
    }
}

/**
 * @param {string} text_to_speak // Text that needs to be spoken
 * 
 * This method check if accessibility is on in the TV settings
 * and if it is swithched on it speaks the desierd text.
 */
export function speakText(text_to_speak) {
    if(!window.speechSynthesis || !text_to_speak || (checkCorrectPlatform([PLATFORM_SAMSUNG]) && window.tizen)) return;

    try {
        if(checkCorrectPlatform([PLATFORM_VIZIO]) && window.VIZIO) {
            if(!window.VIZIO._ttsEnabled) return; // tts is disabled from the settings
            window.VIZIO.Chromevox.play(text_to_speak);
            return window.VIZIO.Chromevox;
        } 
/** Switching off TTS for Samsung TV Devices 
        else if(checkCorrectPlatform([PLATFORM_SAMSUNG]) && window.tizen) {
            if(!window.webapis.tvinfo.getMenuValue(window.webapis.tvinfo.TvInfoMenuKey.VOICE_GUIDE_KEY)) return;
            const synth = window.speechSynthesis;
            if(!synth) return;

            const text = text_to_speak;
            const ttsObject = new SpeechSynthesisUtterance(text);    
            synth.speak(ttsObject);
            return synth;
        }
*/
    } catch(err) {
        console.log("Unable to speak text",err)
    }
}

export function cancelSpeakingText(speak_obj) {
    try {
        if(speak_obj && speak_obj.cancel) {
            speak_obj.cancel();
        }
    } catch(err) {
        console.log("Unable to cancel speech",err);
    }
}

export function loadJsScript({url, location, type_to_set ,onLoadMethod}) {
    try {
        const scriptTag = document.createElement('script');
        scriptTag.src = url;
    
        if(onLoadMethod) {
            scriptTag.onload = onLoadMethod;
            scriptTag.onreadystatechange = onLoadMethod;        
        }
    
        if(type_to_set) {
            scriptTag[type_to_set] = true;
        }

        location.appendChild(scriptTag);
    } catch(err) {
        console.log("Unable to add script tag!!");
    }
}

export function addScriptAccordingToPlatform(platform) {
    switch(platform) {
        case 'vizio_tv':
            loadJsScript({
                url: "http://localhost:12345/scfs/cl/js/vizio-companion-lib.js",
                location: document.body,
                type_to_set: 'defer',
                onLoadMethod: () => {}
            });            
        break;

        case 'samsung_tv':
            loadJsScript({
                url: "$WEBAPIS/webapis/webapis.js",
                location: document.body,
                type_to_set: 'async',
                onLoadMethod: () => {}
            });
        break;

        case 'lg_tv':
            loadJsScript({
                url: "webOSTVjs-1.2.0/webOSTV.js",
                location: document.body,
                type_to_set: 'async',
                onLoadMethod: () => {}
            });
            loadJsScript({
                url: "webOSTVjs-1.2.0/webOSTV-dev.js",
                location: document.body,
                type_to_set: 'async',
                onLoadMethod: () => {}
            });
        break;
    }
}

export function setPlatformConfigs(configuration) {
    if(detectPlatform() == 'Vizio') {
        configuration.platform = 'vizio_tv';
        configuration.use_light_sliders = true;
        configuration.is_simulator = true;      
    } else if(detectPlatform() == 'Xbox') {
        configuration.platform = 'xbox';
        configuration.use_light_sliders = true;
        configuration.is_simulator = true;
    } else if(detectPlatform() == 'Zeasn') {
        configuration.platform = 'zeasn';
        configuration.use_light_sliders = true;
        configuration.is_simulator = true;        
    }
}

export function ctvEnterKeyDownHandler(event, callback) {
    return;
    if(!checkCorrectPlatform([PLATFORM_ZEASN]) || !event || !callback) return;

    if(keyMappings(event.keyCode) == 'OK') {
        callback(event);
    }
}

export function checkIfSpecialCharacter(keyCode) {
    if(!keyCode) return false;

    // ranges of ascii codes for special characters
    const ranges = [
        { s: 32, e: 48 },
        { s: 57, e: 65 },
        { s: 90, e: 97 },
        { s: 122, e: 127 }
    ];

    let found = false;

    for(let i=0;i<ranges.length;i++) {
        if(keyCode > ranges[i].s && keyCode < ranges[i].e) {
            found = true;
            break;
        }
    }

    return found;
}

export function getVerticalSliderSettings() {
    return {
        dots: false,
        infinite: false,
        autoplay: false,
        cssEase: "linear",
        arrows: false,
        speed: 250,
        focusOnSelect: false,
        lazyLoad: 'progressive',
        useCSS: false,
        vertical: true,
        verticalSwiping: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        accessibility: false,
        disable_keyboard_events: true        
    }
}

export function toggleNavbarVisibility(display) {
    const nav_bar = document.getElementById('nav-bar');
    if(nav_bar) nav_bar.style.display = display;
}

export function attachObserver(callback, element, options) {
    if(!element) return;

    if(!options) {
        options = {
            root: null,
            rootMargin: '0px',
            threshold: 0
        };
    }

    let observer;
    if(!window.IntersectionObserver) {
        loadJsScript({
            url: 'https://polyfill.io/v3/polyfill.min.js?features=IntersectionObserver',
            type_to_set: 'async',
            location: document.body,
            onLoadMethod: () => {
                observer = new IntersectionObserver(callback, options);
                observer.observe(element);
                return observer;
            }
        })
    } else {
        observer = new IntersectionObserver(callback, options);
        observer.observe(element);
        return observer;    
    }
}

export function callExitCtvMethod() {
    if (checkCorrectPlatform([PLATFORM_SAMSUNG]) && window.tizen) {
        window.tizen.application.getCurrentApplication().exit();
    } else if (checkCorrectPlatform([PLATFORM_LG])) {
        window.close();
    } else if(checkCorrectPlatform([PLATFORM_VIZIO]) && window.VIZIO) {
        window.VIZIO.exitApplication();
    } else if(checkCorrectPlatform([PLATFORM_XBOX])) {
        window.close();
    } else if(checkCorrectPlatform([PLATFORM_ZEASN])){
        window.history.go(-1 * (window.history.length-1));
    }    
}


export function getPlatformLanguage() {
    let language_promise;

    if(checkCorrectPlatform([PLATFORM_LG]) && window.webOS) {
        language_promise = new Promise((res, rej) => {
            window.webOS.service.request("luna://com.webos.settingsservice", {
                method: "getSystemSettings",
                parameters: {
                    "keys": ["localeInfo"],
                    "subscribe": false
                },
                onSuccess: function (inResponse) {
                    if(inResponse.settings && inResponse.settings.localeInfo && inResponse.settings.localeInfo.locales && inResponse.settings.localeInfo.locales.TV) {
                        res(inResponse.settings.localeInfo.locales.TV.substring(0,2));                     
                    } else {
                        rej({ err: 'No code found!'});
                    }
                    return;
                },
                onFailure: function (inError) {
                    rej(inError);
                    return;
                }
            });
        })
    } else if(checkCorrectPlatform([PLATFORM_SAMSUNG]) && window.tizen) {
        language_promise = new Promise((res, rej) => {
            window.tizen.systeminfo.getPropertyValue('LOCALE',
                systemInfoLocale => {
                    res(systemInfoLocale.language.substring(0,2));
                },
                err => {
                    rej(err);
                }
            );
        });
    } else if(checkCorrectPlatform([PLATFORM_VIZIO]) && window.VIZIO) {
        language_promise = new Promise((res, rej) => {
            window.VIZIO.setDeviceLanguageHandler((device_lang) => {
                if(device_lang.code) {
                    res(device_lang.code.substring(0,2));
                } else {
                    rej({ err: 'no code found!' });
                }
            });
        })
    } /* else if(checkCorrectPlatform([PLATFORM_XBOX]) && window.Windows) {
        language_promise = new Promise((res, rej) => {
            try {
                res(window.Windows.System.UserProfile.GlobalizationPreferences.Languages[0])
            } catch(err) {
                rej(null);
            }
        })
    } */ else {
        language_promise = new Promise((res, rej) => {
            if(window.navigator && window.navigator.language) {
                res(window.navigator.language.substring(0, 2));
            } else {
                rej({ err: 'no code found' })
            }
        })
    }

    return language_promise;
}

export function isCtvOrWebPlatform(platform) {
    const platforms = [PLATFORM_LG, PLATFORM_SAMSUNG, PLATFORM_VIZIO, PLATFORM_XBOX, PLATFORM_ZEASN, PLATFORM_WEB];
    if (platforms.includes(platform)) return true;
    return false;
}