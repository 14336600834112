import React from 'react';
import configuration from '../constants/config'
import { environment } from '../constants/initializer';


class PoweredByScreen extends React.Component {
  getLaunchUrl() {
    if(!configuration.launch_screen) return null;

    if(configuration.is_simulator || configuration.launch_screen.startsWith("https://")) {
      return configuration.launch_screen;
    }
    const domain = window.location.origin;
    return `${domain}/${configuration.launch_screen}`
  }

  render(){
      const launchUrl = this.getLaunchUrl();
      const style = launchUrl ? {} : {backgroundColor: "black"}
      return (
        <div className={"poweredby-screen " + (this.props.runningOnMobile ? "poweredby-screen-mobile" : "")} style={style}>
          {launchUrl
            ? <img src={launchUrl} />
            : <div>
                <img src={"https://s3.amazonaws.com/resources.magappzine.com/assets/core/" + environment() + "/loader.gif"}/>
              </div>
          }
          
        </div>
      )
  }
}

export default PoweredByScreen;
