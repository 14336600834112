import { GET_VIZIO_TV_PLANS_REJECTED, GET_VIZIO_TV_PLANS_PENDING, VIZIO_TV_PURCHASE_PENDING, VIZIO_TV_PURCHASE_RESOLVED, VIZIO_TV_PURCHASE_REJECTED, VIZIO_TV_LINK_RESOLVED, VIZIO_TV_LINK_PENDING, VIZIO_TV_LINK_REJECTED, VIZIO_TV_REGISTER_PENDING, VIZIO_TV_REGISTER_RESOLVED, VIZIO_TV_REGISTER_REJECTED, GET_VIZIO_TV_PLANS_RESOLVED, VIZIO_TV_ACCOUNT_SUPPORTED_REJECTED, VIZIO_TV_ACCOUNT_SUPPORTED_RESOLVED, VIZIO_TV_LINKED_RESOLVED, VIZIO_TV_LINKED_REJECTED, CLEAR_VIZIO_PURCHASE_ERROR, CLEAR_VIZIO_TV_PURCHASE, VIZIO_REQUEST_PENDING, VIZIO_PAYMENT_MODAL_OPEN, VIZIO_PAYMENT_MODAL_CLOSE } from "../constants/actionTypes";

const defaultState = {
    isVizioTvAccountSupported: false,
    isVizioTvLinked: false,
    error: null,
    plans: null,
    isPurchasePending: false,
    isVizioPurchaseSuccess: false,
    isVizioTvParterRegistered: false,
    isLoading: false,
    hasError: false,
    purchasePlan: null,
    modalOpen: false,
};
export default function reducer(state = { ...defaultState }, { type, payload }) {
    switch (type) {
        case VIZIO_PAYMENT_MODAL_OPEN: {
            return {
                ...state,
                modalOpen: true,
            }
        }
        case VIZIO_PAYMENT_MODAL_CLOSE: {
            return {
                ...state,
                modalOpen: false,
            }
        }
        case VIZIO_TV_ACCOUNT_SUPPORTED_RESOLVED: {
            return {
                ...state,
                isLoading: false,
                isVizioTvAccountSupported: true
            }
        }
        case VIZIO_TV_ACCOUNT_SUPPORTED_REJECTED: {
            return {
                ...state,
                isLoading: false,
                ...payload
            }
        }
        case VIZIO_TV_LINKED_RESOLVED: {
            return {
                ...state,
                isLoading: false,
                isVizioTvLinked: true
            }
        }
        case VIZIO_TV_LINKED_REJECTED: {
            return {
                ...state,
                isLoading: false,
                ...payload
            }
        }
        case VIZIO_TV_REGISTER_PENDING: {
            return {
                ...state,
                isLoading: true
            }
        }
        case VIZIO_TV_REGISTER_RESOLVED: {
            return {
                ...state,
                isLoading: false,
                isVizioTvParterRegistered: true
            }
        }
        case VIZIO_TV_REGISTER_REJECTED: {
            return {
                ...state,
                hasError: true,
                isLoading: false,
                isVizioTvParterRegistered: false,
                error: payload,
            }
        }
        case VIZIO_TV_LINK_PENDING: {
            return {
                ...state,
                hasError: false,
                isLoading: true
            }
        }
        case VIZIO_TV_LINK_RESOLVED: {
            return {
                ...state,
                isLoading: false,
                isVizioTvLinked: true,
            }
        }
        case VIZIO_TV_LINK_REJECTED: {
            return {
                ...state,
                isLoading: false,
                hasError: true,
                isVizioTvLinked: false,
                ...payload,
            }
        }
        case GET_VIZIO_TV_PLANS_PENDING: {
            return {
                ...state,
                hasError: false,
                isLoading: true,
            }
        }
        case GET_VIZIO_TV_PLANS_RESOLVED: {
            return {
                ...state,
                plans: payload,
                isLoading: false,
            }
        }
        case GET_VIZIO_TV_PLANS_REJECTED: {
            return {
                ...state,
                isLoading: false,
                hasError: true,
                error: payload,
            }
        }
        case VIZIO_TV_PURCHASE_PENDING: {
            return {
                ...state,
                isLoading: true,
                hasError: false,
                isVizioPurchaseSuccess: false,
                isPurchasePending: true,
            }
        }
        case VIZIO_TV_PURCHASE_RESOLVED: {
            return {
                ...state,
                isLoading: false,
                isVizioPurchaseSuccess: true,
                isPurchasePending: false,
                purchasePlan: payload
            }
        }
        case VIZIO_TV_PURCHASE_REJECTED: {
            return {
                ...state,
                isLoading: false,
                hasError: true,
                isPurchasePending: false,
                isVizioPurchaseSuccess: false,
                error: payload
            }
        }
        case CLEAR_VIZIO_TV_PURCHASE: {
            return {
                ...state,
                isVizioPurchaseSuccess: false,
                purchasePlan: null
            }
        }
        case CLEAR_VIZIO_PURCHASE_ERROR: {
            return {
                ...state,
                error: null,
                hasError: false
            }
        }
        case VIZIO_REQUEST_PENDING: {
            return {
                ...state,
                isLoading: true
            }
        }
    }
    return state;
}