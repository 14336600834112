import * as actionTypes from '../constants/actionTypes';
export default function reducer(
    state={search: {
        content: [],
        search_string: ""
    }, app_id: 0, isSearchCleared: false, isSearchPending: false, searchRouteEntry: []}, action) {

    switch (action.type) {
        case actionTypes.RECEIVE_SEARCH_ITEMS: {
            return {
                ...state, 
                search: {
                    ...state.search,
                    content: action.payload.content
                }, 
                app_id: action.app_id, 
                isSearchPending: false
            }
        }
        case actionTypes.CLEAR_SEARCH: {
            return {
                ...state,
                search: {
                    content: [],
                    search_string: "",
                },
                isSearchCleared: true
            }
        }
        case actionTypes.TEXT_SEARCH: {
            return {
                ...state,
                search: {
                    ...state.search,
                    search_string: action.payload.string,
                },
                isSearchCleared: false,
                isSearchPending: true
            }
        }
        case actionTypes.RECEIVE_SEARCH_ITEMS_FAILED: {
            return {
                ...state,
                search: {
                    content: [],
                    search_string: "",
                },
                isSearchPending: false
            }
        }
        case actionTypes.UPDATE_SEARCH_ROUTE_ENTRY: {
            return {
                ...state,
                searchRouteEntry: action.payload
            }
        }
    }

    return state
}