import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { openModal, closeModal } from "../actions/modalActions";
import { getUserSubscriptionDetails } from "../actions/userActions";
import { getPurchaseSessionId, clearPendingPurchase, clearErrorMessage, startSubPolling } from "../actions/subscriptionAction";
import config from "../constants/config";
import Loader from '../components/Loader';
import { getPaymentFailureUrl, isSmartTv } from '../helperfunctions/common';
import { getMazCoreEnvUrl } from '../constants/initializer';
import QRCode from "qrcode.react";
import configuration from '../constants/config';
import { Config, Checkout } from '@cleeng/mediastore-sdk'
import { getAllConstants } from '../helperfunctions/regionalization';
import { speakText } from '../helperfunctions/ctvHelper';
import VizioAccountContainer from './VizioAccountContainer';
import { PLATFORM_VIZIO } from '../constants/common';

let ALL_C = {};
class ConfirmPayment extends React.Component {
    constructor(props) {
        super(props);
        this.pollInterval = null;
        this.stopTimeout = null;
        this.vizioPlanId = config.payment_provider === 'vizio' ? this.getVizioPlanId() : null,
        this.state = {
            errorMsg: "",
            paymentUrl: null,
            cleengOfferId: config.payment_provider === 'cleeng' ? this.getOfferId() : null,
            useVizioPaymentProvider : this.canUseVizioPaymentProvider(),
        }
        this.openProperPage();
        this.errTimer = null;
        this.stripeObj = this.getStripeObject();
        ALL_C = getAllConstants();
    }

    canUseVizioPaymentProvider = () => {
        if(config.platform !== PLATFORM_VIZIO) return false
        if(config.payment_provider !== "vizio") return false
        if(!this.props.isVizioTvAccountSupported) return false
        if(!config.vizio_app_id) return false
        if(!this.vizioPlanId) return false
        return true
    }

    getStripeObject() {
        const Stripe = window.Stripe;
        let stripeObj = null;
        if (Stripe && config.stripe_public_key && config.stripe_connect_id) {
            stripeObj = Stripe(config.stripe_public_key,
                {stripeAccount: config.stripe_connect_id});
        }
        return stripeObj;        
    }

    componentDidMount() {
        // When this page unloads do not cache the event, so replacing url. When user hits browser back It will go back to previous page, without using cached events
        window.onbeforeunload = function() {
            window.location.replace(`${document.location.href}#nocache`);
        }.bind(this);
        if(this.state.paymentUrl && isSmartTv()) {
            const elem = document.getElementById("loader-scrn-id");
            elem && elem.focus();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        //When api return session id, stripe redirect to checkout Fn will get called.
        if (!prevProps.subscription.sessionId && this.props.subscription.sessionId) {
            this.stripeRedirectToCheckout()
            speakText(`Go to url: ${this.state.paymentUrl}, On your phone or computer, open the link or Scan the QR code To make the payment on that device.`);
        }

        if (prevProps.subscription.isLoading && !this.props.subscription.isLoading && this.props.subscription.errorMsg) {
            this.props.openModal({type: "subscribe", message: ALL_C.kUnableToPurchase, goBack: true})
        }
        if (isSmartTv() && prevProps.subscription.isSubPolling && !this.props.subscription.isSubPolling) {
            this.afterPollingCompleteAction()
        }

        if (config.payment_provider === 'cleeng' && prevProps.subLoading && !this.props.subLoading) {
            //This get called, after cleeng purchase and user payment details get loaded.
            this.props.onCancel();
            // console.log("data loaded....")

        }
    }

    componentWillUnmount() {
        this.stopPolling();
    }

    getSubscriptionDetails() {
        const params = this.getApiParams();
        this.props.getUserSubscriptionDetails(params)
    }

    getApiParams = () => {
        return {
          auth_token: this.props.user.user.auth_token,
          user_id: this.props.user.user.user_id,
          combo_app_id: config.app_id
        }
      }

    closePaymentPageIfUserAlreadyBoughtOrOpenPayment() {
        if(!configuration.isAppTvodType) {
            //If user already is subscribed, do not open payment page
            if (this.props.user.subscription && (!this.props.subscription.purchase.details.itemDetails.special || this.props.subscription.purchase.details.subscription)) {
                this.props.closeModal();
                return;
            }
            //If user already bought iap item, do not open payment paage
            if (this.props.user.items_purchased.includes(this.props.subscription.purchase.details.item)) {
                this.props.closeModal();
                return;
            }
        }
        this.createPurchaseSessionId();
        
    }

    isLogin = () => {
        if (this.props.user.logged_in)
            return true;
        return false;
    }

    stripeRedirectToCheckout = () => {
        const sessionId = this.props.subscription.sessionId;
        const self = this;
        if (isSmartTv()) {
            const paymentUrl = this.props.subscription.paymentUrl;
            this.setState({
                paymentUrl
            });
            this.startSubscriptionPolling();
        } else {
            this.stripeObj.redirectToCheckout({
                sessionId: sessionId
            }).then(function (result) {
                if (result.error.message) {
                    self.setState({
                        errorMsg: result.error.message
                    })
                } else {
                    this.props.clearPendingPurchase();
                }
                // If `redirectToCheckout` fails due to a browser or network
                // error, display the localized error message to your customer
                // using `result.error.message`.
            })
        }
    }

    startSubscriptionPolling = () => {
        const _self = this;
        const timeoutTime = 1000 * 60 * 5 // 5minutes.
        this.stopTimeout = setTimeout(() => {
            _self.onTimeoutEnds();
        }, timeoutTime)
        const pollUrl = this.props.subscription.subPollingUrl;
        this.pollInterval = setInterval(() => {
            this.props.startSubPolling(pollUrl);
        }, 5000)
    }

    onTimeoutEnds = () => {
        this.stopPolling();
        this.props.closeModal();
        this.props.clearPendingPurchase();
    }

    stopPolling = () => {
        if (this.pollInterval) {
            clearInterval(this.pollInterval);
        }
        if (this.stopTimeout) {
            clearTimeout(this.stopTimeout)
        }
    }

    afterPollingCompleteAction = () => {
        this.onTimeoutEnds();
        const successUrlPollData = this.props.subscription.subPollData;
        if (successUrlPollData && successUrlPollData.details && successUrlPollData.details.success_response) {
            setTimeout(() => {
                // waiting so that entitlement can be created
                this.getSubscriptionDetails();
                const redirectUrl = successUrlPollData.details.success_response;
                this.props.history.replace(redirectUrl);
            }, 1000)
        } else {
            this.props.history.replace(`/apps/${config.app_id}/subscription`);
        }
        //Redirect the user to paymemt confirmation page.
    }
    /**
     * Function will check is user is logged in or not. If not open login page
     */
    openProperPage = () => {
        if (!this.props.subscription.purchase.isPending ) {
            this.props.closeModal();
            return;
        }
        if (!this.isLogin()) {
            this.props.openModal({type: "login", text: ALL_C.kInorderToPurchase})
            return;
        }
        if(this.state.useVizioPaymentProvider){
            return
        }
        if (config.payment_provider === 'cleeng') {
            const cleengOfferId = this.getOfferId();
            if (!cleengOfferId) {
                this.props.openModal({type: "subscribe", message: ALL_C.kPurchaseNotPossible, goBack: true})
            }
            return;
        }
        if (!config.stripe_connect_id || !config.stripe_public_key) {
            this.props.openModal({type: "subscribe", message: ALL_C.kPurchaseNotPossible})
        }
        // this.createPurchaseSessionId();
        this.closePaymentPageIfUserAlreadyBoughtOrOpenPayment();
    }  

    getDomain = () => {
        if (isSmartTv()) {
            return getMazCoreEnvUrl();
        }
        return window.location.origin;
    }

    createPurchaseSessionId = () => {
        let apiParamsObj = {}
        const details = this.props.subscription.purchase.details;
        const price = details.price;
        const title = details.title;
        const purchaseType = (config.isAppTvodType ? details.type : (details.subscription ? 'sub' : 'iap'));
        const duration = details.itemDetails.duration;
        const freeTrial = !details.isFreeTrialCancelled && details.itemDetails.freeTrial
        const pathname = window.location.pathname;
        const failureUrl = getPaymentFailureUrl(pathname, this.props.modalData)
        if (isSmartTv()) {
            apiParamsObj.need_polling = true;
            apiParamsObj.success_response = encodeURI(`/payment/confirmation?type=success&section=${pathname}&purchaseType=${purchaseType}&price=${price}&title=${title}&duration=${duration}&ft=${freeTrial}`);
            apiParamsObj.failure_response = encodeURI(failureUrl);
        } else {
            apiParamsObj.success_url = encodeURI(`${this.getDomain()}/payment/confirmation?type=success&section=${pathname}&purchaseType=${purchaseType}&price=${price}&title=${title}&duration=${duration}&ft=${freeTrial}&hidesponsor=true`);
            apiParamsObj.cancel_url = encodeURI(`${this.getDomain()}${failureUrl}`);
        }
        let apiParams;
        if(config.isAppTvodType) {
            apiParams = {
                platform: details.platform,
                product_id: details.product_id,
                tier_id: details.tier_id,
                bundle_token: details.bundleToken,
                cid: details.cid,
                ...apiParamsObj
            }
        } else {
            if (details.subscription) {
                apiParamsObj.subscription_id = details.item
            }
            else {
                apiParamsObj.item_id = details.item
            }
            apiParams = {
                combo_app_id: config.app_id,
                user_id: this.props.user.user.user_id,
                auth_token: this.props.user.user.auth_token,
                platform: config.platform,
                ...apiParamsObj
            }
        }
        this.props.getPurchaseSessionId(apiParams);
    }

    showSubscriptionPurchaseDetails = () => {
        const pendingPurchaseDetails = this.props.subscription.purchase.details;
        return (
            <div className="subs-purchase">
                {
                    <div className="">
                        <h3>Subscription Purchase</h3>
                        {pendingPurchaseDetails.isFreeTrial && (
                            <h4>Trial duration:
                                <span>
                                    {` `}{pendingPurchaseDetails.itemDetails.freeTrial}{` Days`}
                                </span>
                            </h4>
                        )}
                        <h4>Duration:
                            <span>
                                {` `}{pendingPurchaseDetails.duration}
                            </span>
                        </h4>
                        <h4>Amount: 
                            <span>
                                {` `}$ {pendingPurchaseDetails.price}
                            </span>
                        </h4>
                    </div>
                }
            </div>
        )
    }

    showIapPurchaseDetails = () => {
        return (
            <div className="iap-purchase">
                <h3>You are purchasing an item.</h3>
                <h4>Title: 
                    <span>
                        {` `}{this.props.subscription.purchase.details.title}
                    </span>
                </h4>
                <h4>Amount: 
                    <span>
                        {` `}{this.props.subscription.purchase.details.price}
                    </span>
                </h4>
            </div>
        )
    }

    showPurchaseDetails = () => {
        if (this.props.subscription.purchase.details.subscription) {
            return this.showSubscriptionPurchaseDetails();
        }

        if (this.props.subscription.purchase.details.iap) {
            return this.showIapPurchaseDetails();
        }
    }

    clearErrorMsg = () => {
        this.setState({
            errorMsg: ""
        });
        this.props.clearErrorMessage();
        clearTimeout(this.errTimer);
    }

    errorDiv = () => {
        const self = this;
        if (this.state.errorMsg || this.props.subscription.errorMsg) {
            this.errTimer = setTimeout(() => {
                self.clearErrorMsg();
            }, 5000)
            return (
                <div className="error-div">
                    <a className="crossBtnIcn" href="#" onClick={(e) => {
                        e.preventDefault();
                        this.clearErrorMsg()
                    }}></a>
                    {this.state.errorMsg || this.props.subscription.errorMsg}
                </div>
            )
        }
    }

    getOfferId = () => {
        const details = this.props.subscription.purchase.details
        const platform = details.platform
        const offerId = details.itemDetails.externalId || details.itemDetails.iap[platform].externalId;
        return offerId;
    }

    getVizioPlanId = () => {
        const details = this.props.subscription.purchase.details
        const isSubcriptionPurchaseType = (config.isAppTvodType ? details.type === "subscriptions" : details.subscription === 'sub');
        if(!isSubcriptionPurchaseType) return null
        const planId = details.itemDetails.externalId || details.itemDetails.iap.vizio.externalId;
        return planId
    }

    onCleengPaymentSuccess = () => {
        this.getSubscriptionDetails();
        this.props.clearPendingPurchase();
    }

    handleVizioNotNow = () =>{
        this.setState({useVizioPaymentProvider : false} , () => this.openProperPage())
    }

    render() {
        if(this.state.useVizioPaymentProvider){
            return <>
                    <VizioAccountContainer onVizioSkip={this.handleVizioNotNow} onClose={this.props.vizioModalClose} onCancel={this.props.onCancel} planCode={this.vizioPlanId} /> 
                </>
        }
        if (config.payment_provider === 'cleeng') {
            return (
                <>
                    {this.props.subLoading && <Loader />}
                    <div id="cleeng-container" className='cleeng-login'>
                        <Checkout
                            onSuccess={this.onCleengPaymentSuccess}
                            offerId={this.state.cleengOfferId}
                        />
                    </div>
                </>
            )
        }
        if (this.state.paymentUrl && isSmartTv()) {
            return (
                <Loader loaderText={this.state.paymentUrl} loadingType="paymentLoader" feeds={this.props.feeds}/>
            )
        } else
        return (<Loader />)
    }
}

const mapStateToProps = (state) => ({
    feeds: state.feeds.feeds,
    app_id: state.feeds.app_id,
    background: state.feeds.background,
    user: state.user,
    subscription: state.subscription,
    subLoading: state.user.isLoading,
    isVizioTvAccountSupported: state.vizioAccount.isVizioTvAccountSupported,
  })
  
  const mapDispatchToProps = {
    openModal: openModal,
    getPurchaseSessionId: getPurchaseSessionId,
    clearPendingPurchase,
    clearErrorMessage,
    closeModal,
    startSubPolling,
    getUserSubscriptionDetails
  }
  
  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConfirmPayment))