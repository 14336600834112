import Background from "./Background";
import { QRCodeSVG } from "qrcode.react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import ReactFocusLock from "react-focus-lock";
import { getAllConstants } from "../helperfunctions/regionalization";
import backMobile from "../images/icons/back-mobile.png";
import { isMobile } from "mobile-device-detect";
import { isSmartTv } from "../helperfunctions/common";
import { useState } from "react";

const LinkSection = ({ item, hideBackButton = false, onClose = () => { }, fullScreen = false}) => {
  const { userEngaged } = useSelector((state) => state.user);
  const [ linkOpened, setLinkOpened ] = useState(false);
  const ALL_C = getAllConstants();
  const { contentUrl, openInNewTabEnabled } = item;
  const smartTv = isSmartTv();
  const openLink = () => {
    const openLinkTab = openInNewTabEnabled ? "_blank" : "_self";
    const url = contentUrl.startsWith("https://") || contentUrl.startsWith("http://") ? contentUrl : `https://${contentUrl}`;
    window.open(url, openLinkTab);
    if(openLinkTab === "_blank") {
      setLinkOpened(true);
    }
  };

  useEffect(() => {
    if (!smartTv && userEngaged) {
      openLink();
    }
  }, []);

  const QRCodeWithText = () => (
    <>
      <span className="link-scan-text">{ALL_C.kLinkScanQRText}</span>
      <div className="link-qr text-center">
          <QRCodeSVG
            style={{ border: "2px white solid" }}
            bgColor="#FFFFFF"
            fgColor="#000000"
            value={contentUrl}
            size={smartTv ? 270 : 128}
          />
      </div>
    </>
  );

  if(!smartTv && userEngaged && !linkOpened) {
    return null;
  }

  return (
    <>
      <div className={`link-modal-container ${fullScreen ? "ctv-link" : ""}`}>
        <div className="link-modal-details">
          <Background />
          <span id="link-visit-text"> {smartTv ? ALL_C.kVisitCtvLinkHeader : ALL_C.kVisitWebLinkHeader}</span>
          <span onClick={openLink}><b className="link-url">{contentUrl}</b></span>
          {smartTv ? QRCodeWithText() : <></>}
          <BackButton id={"link-modal-close-button"} onClick={onClose} hideBackButton={hideBackButton} />
        </div>
      </div>
    </>
  );
};

export default withFocusLock(LinkSection);

function withFocusLock(Component) {
  return function WrappedComponent({ withFocusLock = true, ...props }) {
    if (withFocusLock) {
      return (
        <ReactFocusLock returnFocus={true}>
          <Component {...props} />
        </ReactFocusLock>
      );
    } else {
      return <Component {...props} />;
    }
  };
}

function BackButton ({ id, onClick, hideBackButton }) {
  let style = {};
  if (isSmartTv()) {
    style.display = "none";
  }

  if (hideBackButton) return <></>;

  return (
    <span className={"icon-hollow_arrow backBtnSpan " + (isMobile ? "backBtnSpan-mobile" : "")}>
      <a
        id={id}
        href=""
        onClick={(e) => {
          e.preventDefault();
          onClick();
        }}
        className={isMobile ? "backBtn-mobile" : "backBtn"}
        style={{ zIndex: 100, ...style }}
      >
        {isMobile && <img src={backMobile} alt="Back" />}
      </a>
    </span>
  );
};
