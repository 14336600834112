import React, { createRef, useEffect, useState } from 'react';
import { connect } from "react-redux";

import { getTabIndex, isFeedAvailable, isMenuBlockGeofence, isSmartTv } from '../helperfunctions/common';
import Content from './Content';
import { SERIES_THEME } from '../constants/common';
import { addRemoveAllNavigation } from '../helperfunctions/ctvHelper';
import { openModal } from "../actions/modalActions";
import configuration from '../constants/config';
import { getCustomUIColors, getThumbnailType, getFontStyleFromFeed } from '../helperfunctions';
import isAvailable from '../helperfunctions/is_available';
import Trailer from './icons/Trailer';
import { setPreviewVideo } from "../actions/modalActions";
import Preview from './Preview';
import { getAllConstants } from '../helperfunctions/regionalization';
import { DynamicList } from './DynamicList';

import '../css/series.css';

const SeriesSection = (props) => {
  const [seasonIndex, setSeasonIndex] = useState(0);
  const fontStyle = getFontStyleFromFeed(props.feeds);
  const watchRef = createRef();
  const { kISPlay, kTrailer } = getAllConstants();
  
  const { app_id, feeds, layout, section, callbackParent, pathLocation, runningOnMobile, background} = props;

  useEffect(() => {
    getPreviewData();
  }, []);

  useEffect(() => {
    getPreviewData();
  }, [seasonIndex]);

  useEffect(() => {
    const focusId = addRemoveAllNavigation({ selector: '.series .focusable' });

    if(isSmartTv() && watchRef && watchRef.current) {
      watchRef.current.focus();
    }
    return () => {
      addRemoveAllNavigation({ id: focusId });
    }
    // make focus on watch button to stop previous TTS playback
  }, [props.modal])

  const getPreviewData = () => {
		if(!section.contents) return;

		var _content = {...section.contents[0]};
		if(_content.contents && _content.type === "menu" && _content.contents[0]){
			_content = {..._content.contents[0]};
		}
    if(section.catalog === "Series" && section.contents[seasonIndex]) {props.setPreviewVideo(section.contents[seasonIndex])}
		else props.setPreviewVideo(_content)
	}

  const manageClick = () => {
    if(!section || !section.contents) return;
    const { firstSeason, firstEpisode } = getFirstSeason_EpisodeAvaialableInCountry(section);
    if(!firstSeason || !firstEpisode) return;
    const firstEpisodePlay = isAvailable(null,null,null,firstEpisode);

    const allContents = firstSeason.contents.map(content => {
      const newContent = {
        ...content,
        locked: firstSeason.locked,
        special: firstSeason.special,
        displayTitle: firstSeason.displayTitle,
        registerWall: firstSeason.registerWall,
        parent_id: firstSeason.identifier,
        inlineMenu: firstSeason.inlineMenu || "",
      }
      if(!configuration.isAppTvodType) {
        newContent["access"]= firstSeason.access
      }
      return newContent;
    })

    const allContents_AvaialableInCountry = allContents.filter(content => {
      return isFeedAvailable(props.policyData.country, props.feeds.countryGroups, content.countryGroupId);
    });

    const propsObj = {
      head: firstSeason.identifier, type: "video", identifier: firstEpisode.identifier,
      content: firstEpisode, show_ad: firstSeason.showVideoAd, all_contents: allContents_AvaialableInCountry,
      pathname: window.location.pathname, firstpath: getThumbnailType(firstEpisode, firstSeason.identifier),
      firstEpisodePlay: firstEpisodePlay
    }

    if (configuration.isAppTvodType || !firstSeason.showInterstitial) {
      props.openModal(propsObj)
    } else {
      props.openModal({
        type: 'interstitialScreen',
        modalProps: propsObj,
        thumbnailProps: {
          background: background
        }
      });
    }
  }

  const getFirstSeason_EpisodeAvaialableInCountry = (section) => {
    // This method returns first episode of first possible possible season
    // available in user's country.
    let first_feeds = {
      firstSeason: null,
      firstEpisode: null
    }

    try {
      let found = false;
      for(let i=0;i<section.contents.length;i++) {
        for(let j=0;j<section.contents[i].contents.length;j++) {
          if(isFeedAvailable(props.policyData.country, props.feeds.countryGroups, section.contents[i].contents[j].countryGroupId)) {
            found = true;
            first_feeds['firstEpisode'] = section.contents[i].contents[j];
            break;
          }
        }
        if(found) {
          first_feeds['firstSeason'] = section.contents[i];
          break;
        }
      }

      if(first_feeds['firstSeason'] && isContentBlocked(first_feeds['firstSeason'])) {
        return {
          firstSeason: null,
          firstEpisode: null
        }
      }
      return first_feeds;
    } catch(err) {
      console.log("unable to fetch first season and episde",err);
      return first_feeds;
    }
  }

  const isContentBlocked = (content) => {
    if (content && content.blockCountries && content.blockCountries.includes(props.policyData.country))
      return true;
    return false;
  }

  const playTrailer = () => {
    const trailer = section.trailer;
    if(!trailer) return;

    const propsObj = {
      type: "video", identifier: trailer.identifier,
      content: trailer, show_ad: false, all_contents: [trailer], videoTrailer: true, head: "trailer",
      pathname: window.location.pathname, firstpath: getThumbnailType(trailer, section.identifier)
    }
    props.openModal(propsObj)
  }

  const renderButtons = (title = "", subtitle = "", summary = "") => {
    const trailer = section.trailer;

    if(!runningOnMobile) {
      const { priButtonStyle, secButtonStyle, priTextcolor } = getCustomUIColors(feeds, "red", "red");
      return (
        <div className="item-play">
          <span className="focusable" tabIndex={getTabIndex()} onClick={() => manageClick()} style={priButtonStyle} ref={watchRef} aria-label={`watch season 1 episode 1 | ${title && title.toLowerCase()} | ${subtitle && subtitle.toLowerCase()} | ${summary && summary.toLowerCase()}`}>
            <span>{kISPlay}</span>
            <span className="arrow-right" style={{borderLeftColor: priTextcolor}}></span>
          </span>
          {trailer &&<span className="focusable" tabIndex={getTabIndex()} onClick={() => playTrailer()} style={secButtonStyle} aria-label={`watch trailer | ${title && title.toLowerCase()} | ${subtitle && subtitle.toLowerCase()} | ${summary && summary.toLowerCase()}`}>
            <span>{kTrailer}</span>
          </span>}
        </div>
      )
    }
    return (
      <div className="item-play">
        <div onClick={() => manageClick()}>
          <span className="arrow-right"></span>
          <span className="mt-10">{kISPlay}</span>
        </div>
        {trailer && <div onClick={() => playTrailer()}>
          <Trailer />
          <span className="mt-10">{kTrailer}</span>
        </div>}
      </div>
    )
  }

  const renderDescription = () => {
    const {summary, title, background, subtitle, cover, previewType, previewImage, previewInfo, previewAudio} = section;
    // const imageUrl = (previewType === "image" && previewImage && previewImage.url) ? previewImage.url : (cover && cover.url);

    return (
      <div className="description">
        <div className="image-wrapper">
          {/* {video_content
            ? <PreviewVideo video_content={video_content}/>
            : imageUrl && <img src={imageUrl}/>
          } */}
          <Preview muted = {!previewAudio}
            previewType = {previewType}
            section = {section}
            previewInfo={previewInfo}
            nonDescription={true}
            isSeriesPreview={true}
          />
          {!runningOnMobile && <div className="shodow"></div>}
        </div>
        <div className="details">
          <p className="title" style={fontStyle.primaryFont}>{title}</p>
          {subtitle && <p className="meta-data">{subtitle}</p>}
          {summary && <p className="summary">{summary}</p>}
          {renderButtons(title, subtitle, summary)}
        </div>
      </div>
    )
  }

  const renderSeasonList = () => {
    const seasonList = section.contents.filter(content => {
      return !isContentBlocked(content);
    });

    const getStyle = (borderStyle, index) => {
      if(index === seasonIndex) return {...borderStyle, ...fontStyle.primaryFont}
      return {...borderStyle, ...fontStyle.secondaryFont}
    }

    return (
      <div className="season-list">
        {seasonList.map((season, index) => {
          if(season.type !== "menu" || season.mazIDSectionType || !season.contents || season.contents.length === 0) return null; // saved, progress, history, item at season level
          const borderStyle = (index === seasonIndex && feeds.primaryColor) ? {borderColor: feeds.primaryColor.replace('#FF','#')} : {}
          return (
            <div key={season.identifier} onClick={() => setSeasonIndex(index)} className="season-wrapper">
              <span className={`season focusable ${index === seasonIndex ? "active" : ""}`} style={getStyle(borderStyle, index)} tabIndex={getTabIndex()} aria-label={season.title ? season.title.toLowerCase() : ""}>{season.title}</span>
            </div>
          )
        })}
      </div>
    )
  }

  const renderEpisodes = () => {
    const seasonData = (section.contents && section.contents[seasonIndex]) || null;
    if(!seasonData || seasonData.type !== "menu" || isContentBlocked(seasonData)) return null;

    const propData = {
      background: background,
      app_id: app_id,
      feeds: feeds,
      content: seasonData,
      callbackParent: (route) => callbackParent(route),
      layout: SERIES_THEME,
      pathLocation: pathLocation,
      runningOnMobile: runningOnMobile
    }
    if(seasonData.isDynamicList) {
      return <DynamicList propData={propData} content={seasonData}/>
    }
    return <Content {...propData}/>
  }

  return (
    <div className="content-box series" id="content-box-1" style={fontStyle.secondaryFont}>
      {renderDescription()}
      <div className="below-section">
        {renderSeasonList()}
        {renderEpisodes()}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    modal: state.modal.modal,
    policyData: state.feeds.policyData,
    isGeofencingOn: state.user.isGeofencingOn    
  }
}

const mapDispatchToProps = {
  openModal,
  setPreviewVideo
}

export default connect(mapStateToProps, mapDispatchToProps)(SeriesSection);
