import React from 'react';
import { hexToRgba } from '../constants/common'
import { isSmartTv, isAppRunningOnLowEndPlatform } from '../helperfunctions/common';
import videojs from 'video.js';

function BackgroundOption(props) {
  if (props.background === undefined) {
    return (
      <div className="colorBg"></div>
    )
  } else if(props.background.color) {
    return (
      <div className="colorBg" style={{backgroundColor: props.background.color.replace("#FF","#")}}></div>
    )
  } else if(props.background.imageUrl){
    return (
      <div className="imageBg" style={{"backgroundImage": "url('" + props.background.imageUrl + "')", "backgroundRepeat": "no-repeat", "backgroundSize": "cover", "backgroundPosition": "center center"}}></div>
    )
  } else if(props.background.videoUrl) {
    if(isAppRunningOnLowEndPlatform()) return null;
    return (
      <div className="videoBg">
        {
          isSmartTv() ? (<video id="bck_video_ele" ></video>)
          : (<video src={props.background.videoUrl} autoPlay muted loop></video>)
        }
      </div>
    )
  } else if(props.background.gradient){
    var tempGradTop = hexToRgba("#"+props.background.gradient.topColor.substring(3), props.background.gradient.topColor.substring(1,3));
    var tempGradBot = hexToRgba("#"+props.background.gradient.bottomColor.substring(3), props.background.gradient.bottomColor.substring(1,3));
    const style = {
      "background": "-webkit-gradient(left top, right bottom,color-stop(0, " + tempGradTop + "),color-stop(1, " + tempGradBot + "))",
      "background": "-o-linear-gradient(-25deg, " + tempGradTop + " 0%, " + tempGradBot + " 100%)",
      "background": "-moz-linear-gradient(-25deg, " + tempGradBot + " 0%, " + tempGradBot + " 100%)",
      "background": "-webkit-linear-gradient(-25deg, " + tempGradTop + " 0%, " + tempGradBot + " 100%)",
      "background":"-ms-linear-gradient(-25deg, " + tempGradTop + " 0%, " + tempGradBot + " 100%)",
      "background": "linear-gradient(135deg, " + tempGradTop + " 0%, " + tempGradBot + " 100%)"
    }
    return (
      <div className="gradientBg" style={style}></div>
    )
  }
}

function VideoImageBackgroundOption(props){
  var tempGradTop = hexToRgba("#"+props.background.gradient.topColor.substring(3), props.background.gradient.topColor.substring(1,3));
  var tempGradBot = hexToRgba("#"+props.background.gradient.bottomColor.substring(3), props.background.gradient.bottomColor.substring(1,3));
  const style = {
    "background": "-webkit-gradient(left top, right bottom,color-stop(0, " + tempGradTop + "),color-stop(1, " + tempGradBot + "))",
    "background": "-o-linear-gradient(-25deg, " + tempGradTop + " 0%, " + tempGradBot + " 100%)",
    "background": "-moz-linear-gradient(-25deg, " + tempGradBot + " 0%, " + tempGradBot + " 100%)",
    "background": "-webkit-linear-gradient(-25deg, " + tempGradTop + " 0%, " + tempGradBot + " 100%)",
    "background":"-ms-linear-gradient(-25deg, " + tempGradTop + " 0%, " + tempGradBot + " 100%)",
    "background": "linear-gradient(135deg, " + tempGradTop + " 0%, " + tempGradBot + " 100%)"
  }
  return (
    <div className="gradientBg" style={style}></div>
  )
}

class Background extends React.Component {
  componentDidMount() {
    if (isSmartTv() && document.getElementById('bck_video_ele')) {
      this.player = videojs("bck_video_ele",{
        errorDisplay: false, 
        loadingSpinner: false, 
        preload: 'auto',
        muted: true,
        loop: true,
        controls: false,
      })
      const player = this.player;
      player.src({src: this.props.background && this.props.background.videoUrl});
      player.ready(() => {
        player.play();
        player.muted(true);
      })
    }
  }

  componentWillUnmount() {
    if (this.player) {
      try {
        this.player.dispose()
      }
      catch(err) {
      }
    }
  }
  render() {
    return (
      <div className="bckgrnds">
        <BackgroundOption background={this.props.background} />
        {(this.props.background && (this.props.background.videoUrl || this.props.background.imageUrl) && this.props.background.gradient) && <VideoImageBackgroundOption background={this.props.background} />}
      </div>
    )
  }
}


export default Background;
