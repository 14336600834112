import React from 'react'
import ErrorDisplay from "./ErrorDisplay"
import { connect } from "react-redux"
import { userActions } from "../actions/userActions"
import ForgotPassword from "../components/ForgotPassword"
import { openModal } from '../actions/modalActions';
import Loader from '../components/Loader';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import JsSpatialNavigation from '../ctvnavigation/js-spatial-navigation';
import { checkCorrectPlatform, getTabIndex, isSmartTv, showVirtualKeyboard } from '../helperfunctions/common';
import { getGdprParamsForApi, elemOrientation } from '../helperfunctions/feedHelper'
import configuration from '../constants/config'
import { PLATFORM_LG, PLATFORM_VIZIO, PLATFORM_WEB, PLATFORM_XBOX, PLATFORM_ZEASN, PLATFORM_WEB_PREVIEW } from '../constants/common'
import { getAllConstants } from '../helperfunctions/regionalization'

let ALL_C = {};

function valid_email(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email.trim() === "" || re.test(email) === false) {
        return false
    } else {
        return true
    }
}

function valid_password(password) {
    if (password.trim() === "") {
        return false
    } else {
        return true
    }
}

class UserAccess extends React.Component {
    constructor(props) {
        super(props);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.formSubmit = this.formSubmit.bind(this);
        this.dismissAction = this.dismissAction.bind(this);
        this.forgotPassword = this.forgotPassword.bind(this);
        this.handleInputEventOnKeyboard = this.handleInputEventOnKeyboard.bind(this);
        this.state = { error: false, email: props.email || '', password: props.password || '', errorHeading: '', errorMsg: '', forgotPassword: false, input_focus: 'email', layoutName: "default", show_keyboard: false };
        this.last_focus_osk = false;
        ALL_C = getAllConstants();
    }

    componentDidMount() {
        if (document.getElementsByTagName('body')[0].classList.contains('detail')) {
            document.getElementsByTagName('body')[0].classList.add('overflow-scroll-auto');
        }

        // document.getElementsByTagName('input').forEach(elm => {
        //     elm.addEventListener('sn:willunfocus', function(data) {
        //         if(data.detail.direction == 'left' || data.detail.direction == 'right') {
        //             data.preventDefault();
        //         }
        //     });
        // })
        if(isSmartTv() && checkCorrectPlatform([PLATFORM_LG])) {
            document.addEventListener('keyboardStateChange', this.keyboardStateChange, false);
        }

        if(checkCorrectPlatform([PLATFORM_ZEASN]) && document.getElementById("email_id")) {
            setTimeout(() => {
                document.getElementById("email_id").focus();
            }, 0);
        }
    }

    componentWillUnmount() {
        if (document.getElementsByTagName('body')[0].classList.contains('detail')) {
            document.getElementsByTagName('body')[0].classList.remove('overflow-scroll-auto');
        }
        if(isSmartTv()) {
            if(checkCorrectPlatform([PLATFORM_LG])) {
                document.removeEventListener('keyboardStateChange', this.keyboardStateChange, false);
            }
            JsSpatialNavigation.resume();
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps === this.props) return;
        if ((this.props.user_error || (this.props.user.error && !this.props.logged_in)) && this.state.email.trim() !== "" && this.state.password.trim() !== "") {
            let errorMsg = ""
            if (this.props.user == "Your account is locked.") {
                errorMsg = ALL_C.kAccountLocked
            } else {
                errorMsg = this.props.user.error
            }
            this.setState({ error: true, errorHeading: ALL_C.kInvalidDetails, errorMsg: errorMsg, email: "", password: "" })
        }
    }

    keyboardStateChange(event) {
        let visibility = event.detail.visibility;
        if(!visibility) {
            JsSpatialNavigation.resume();
        }
    }
    
    formSubmit(e) {
        e.preventDefault();
        const email = this.state.email;
        const password = this.state.password;
        const button_clicked = e.target.value;
        if (valid_email(email) && valid_password(password)) {
            const data = {
                email: email,
                password: password,
                app_id: this.props.app_id,
                button_clicked: button_clicked
            }
            if (this.props.userConcent) {
                // User have given concent, don't open data-privacy page
                this.props.userActions(email, password, this.props.app_id, button_clicked, getGdprParamsForApi(this.props.feeds));
            } else {
                this.props.onAPIDataReady(data)
            }
        } else {
            if (!valid_email(email)) {
                this.setState({ error: true, errorHeading: ALL_C.kInvalidEmail, errorMsg: ALL_C.kEnterValidEmail })
            } else if (!valid_password(password)) {
                this.setState({ error: true, errorHeading: ALL_C.kInvalidPassword, errorMsg: ALL_C.kInvalidPasswordText })
            }
        }
    }
    handleEmailChange(e) {
        this.setState({ email: e.target.value })
    }
    handlePasswordChange(e) {
        this.setState({ password: e.target.value })
    }
    dismissAction(e) {
        if (e) {
            e.preventDefault();
        }
        this.setState({ error: false, forgotPassword: false })
    }
    forgotPassword(e) {
        e.preventDefault();
        const email = this.state.email;
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (email.trim() === "" || re.test(email) === false) {
            this.setState({ error: true, errorHeading: ALL_C.kInvalidEmail, errorMsg: ALL_C.kEnterValidEmail })
        }
        else {
            this.setState({ forgotPassword: true })
        }
    }

    setInputFocus = (type) => {
        // console.log('Set input focus', this.keyboard, this.keyboard.utilities.getCaretPosition());
        this.keyboard.clearInput();
        this.setState({ input_focus: type });
        if (type === 'email') {
            // this.keyboard.setInput(this.state.email);
            this.keyboard.replaceInput({ default: this.state.email, input2: this.state.email });
        } else {
            // this.keyboard.setInput(this.state.password);
            this.keyboard.replaceInput({ default: this.state.password, input2: this.state.password });
        }
        this.keyboard.setCaretPosition(null);
    }

    keyboardKeyPressed = (button) => {
        if (button === "{shift}" || button === "{lock}") {
            const layoutName = this.state.layoutName;

            this.setState({
                layoutName: layoutName === "default" ? "shift" : "default"
            },() => {
                setTimeout(() => {
                    const first_btn = document.querySelector('.hg-row .focusable');
                    first_btn && first_btn.focus();
                }, 0);
            });
        }
    }

    onKeyboardChange = (input) => {
        // console.log("INPUT", input, this.keyboard.utilities.getCaretPosition());
        if (this.state.input_focus === 'email') {
            this.setState({ email: input });
        } else {
            this.setState({ password: input });
        }
        // this.keyboard.replaceInput(e.target.value);
    }

    handleInputSelect = (e, type) => {
        if(isSmartTv()) {
            switch (e.keyCode) {
                case 13:
                case 65376: // Done pressed
                    e.preventDefault(); // stop event propogation
                    if(type == 'email') {
                        document.getElementById('email_id').blur();
                        document.getElementById('pswd').focus();
                    } else {
                        JsSpatialNavigation.resume();
                        document.getElementsByClassName('login-btn')[0].click();
                        if(document.getElementsByClassName("hg-button")[0]) {
                            document.getElementsByClassName("hg-button")[0].focus();
                        }
                    }
                break;
    
                case 65385: // Cancel pressed
                    e.preventDefault();
                    if(type == 'email') {
                        document.getElementById('email_id').blur();
                    } else {
                        document.getElementById('pswd').blur();
                    }
                break;

                case 10009: // samsung back press
                case 461: // lg back press
                    e.preventDefault();
                    JsSpatialNavigation.resume();
            }
        } else {
            if (e.keyCode != '13') return;
            document.getElementsByClassName('login-btn')[0].click();
            if(document.getElementsByClassName("hg-button")[0]) {
                document.getElementsByClassName("hg-button")[0].focus();
            }
            if(showVirtualKeyboard()) {
                this.setInputFocus(type);
            }
        }
    }

    virtualKeyboardHanlder(e, _type) {
        let inp_type = e.currentTarget.id == 'email_id' ? 'email' : 'pswd';
        try {
            if(_type == 'pause' && !this.last_focus_osk) {
                // onfocus, If focus not coming from virtual keyboard
                setTimeout(() => {
                    this.last_focus = inp_type; 
                    this.setState({ show_keyboard: true },() => {
                        this.setInputFocus(inp_type);
                        // take focus to keyboard
                        document.querySelector('.hg-row .focusable') && document.querySelector('.hg-row .focusable').focus();
                    });
                    // document.getElementsByClassName('btnBox')[0].style.visibility = 'hidden';
                    if(document.getElementsByClassName('continue-btn')[0]) document.getElementsByClassName('continue-btn')[0].classList.remove('focusable');
                    if(document.getElementsByClassName('login-btn')[0]) document.getElementsByClassName('login-btn')[0].classList.remove('focusable');
                    if(inp_type == 'email') {
                       if(document.getElementById('email_id')) document.getElementById('email_id').style.background = 'white';
                        if(document.getElementById('pswd')) document.getElementById('pswd').style.background = '';
                    } else {
                        if(document.getElementById('email_id')) document.getElementById('email_id').style.background = '';
                        if(document.getElementById('pswd')) document.getElementById('pswd').style.background = 'white';
                    }
                    this.last_focus_osk = true;
                }, 500);
            } else if(_type == 'pause' && this.last_focus_osk) {
                // if focus coming from virtual keyboard
                document.getElementById('email_id').style.background = '';                        
                document.getElementById('pswd').style.background = '';                        
    
                if(this.last_focus == 'email') {
                    document.getElementById('email_id').focus();
                } else {
                    document.getElementById('pswd').focus();
                }
                this.setState({ show_keyboard: false });
                document.getElementsByClassName('continue-btn')[0].classList.add('focusable');
                document.getElementsByClassName('login-btn')[0].classList.add('focusable');
                this.last_focus_osk = false;
            }
        } catch(err) {
            console.log(err)
        }
    }

    controlNavigation = (e, _type) => {
        if(!isSmartTv()) return;
        if(showVirtualKeyboard()) {
            this.virtualKeyboardHanlder(e, _type);
            return;
        }

        if(_type == 'pause' && !checkCorrectPlatform([PLATFORM_XBOX, PLATFORM_ZEASN])) {
            JsSpatialNavigation.pause();
        } else {
            JsSpatialNavigation.resume();
        }
    }

    handleInputEventOnKeyboard(e) {
        this.handleMobileInputForVizio(e);
        // if(e.keyCode != 8) {
		// 	this.handleMobileInputForVizio(e);
		// 	return;
		// }
        // e.preventDefault();
        // this.closeVirtualKeyboard();
    }

    closeVirtualKeyboard() {
        setTimeout(() => {
            document.getElementById('email_id').style.background = '';
            document.getElementById('pswd').style.background = '';
    
            if(this.last_focus == 'email') {
                document.getElementById('email_id').focus();
            } else {
                document.getElementById('pswd').focus();
            }
            this.setState({ show_keyboard: false });
            document.getElementsByClassName('continue-btn')[0].classList.add('focusable');
            document.getElementsByClassName('login-btn')[0].classList.add('focusable');
            this.last_focus_osk = false;
        }, 501);
    }

    handleMobileInputForVizio(e) {
        // debugger
        if(!checkCorrectPlatform([PLATFORM_VIZIO])) return;
    
        let input = e.key;

        if(e.key == 'Backspace') {
          input = '{bksp}';
        } else if(e.key == ' ') {
          input = '{space}';
        }
            
        const focus_element = this.last_focus == 'email' ? document.getElementById('email_id') : document.getElementById('pswd');
        if(input == '{bksp}' && (focus_element && focus_element.value.length == 0)) {
            this.closeVirtualKeyboard();
            return;
        }
          
        let _layout_name;
    
        for(let key in this.layout) {
            if(this.layout[key].join(' ').includes(input)) {
                _layout_name = key;
                break;
            }
        }
    
        if(_layout_name) {
          if(_layout_name != this.state.layoutName) {
            this.setState({ layoutName: _layout_name },() => {
              	this.manualClickKeyboard(input);
            });
          } else {
            this.manualClickKeyboard(input);
          }
        }
    }
    
    manualClickKeyboard(key) {
        setTimeout(() => {
            const btn_elm = this.keyboard.getButtonElement(key);
            if(!btn_elm) return;
            btn_elm.focus();
            btn_elm.click();
        }, 0);
    }
    
    render() {
        this.layout = {
            'default': [
                '1 2 3 4 5 6 7 8 9 0',
                'q w e r t y u i o p',
                'a s d f g h j k l -',
                '{shift} z x c v b n m _',
                '.com @ {bksp} {space} .'
            ],
            'shift': [
                '~ ! @ # $ % ^ & * ( ) _ +',
                'Q W E R T Y U I O P { } |',
                `A S D F G H J K L : " '`,
                '{shift} Z X C V B N M < > ?',
                '.com @ {bksp} {space}'
            ]
        };

        let screen = "firstSave"
        if (this.props.screen === "registerWall") {
            screen = "registrationWall"
        }
        if (configuration.isAppTvodType) {
            screen = "tvodLogin"
        }
        let mainData = this.props.feeds.loginUIMetadata[screen];
        const createText = mainData && mainData.strings && mainData.strings.register;
        const loginText = mainData && mainData.strings && mainData.strings.login;
        if ((document.getElementsByClassName("user-access")[0] !== undefined) && (document.getElementsByClassName("user-access")[0].getElementsByClassName('logoHolder')[0] !== undefined))
            document.getElementsByClassName("user-access")[0].getElementsByClassName('logoHolder')[0].style.display = "block";
        if (this.props.isUserLoading || this.props.emailLoginLoading)
            return (<Loader />)
        if (this.state.error) {
            return <ErrorDisplay dismiss={this.dismissAction} errorMsg={this.state.errorMsg} errorHeading={this.state.errorHeading} />
        } else if (this.state.forgotPassword) {
            return <ForgotPassword forgotPassword={this.forgotPassword} dismiss={this.dismissAction} email={this.state.email} app_id={this.props.app_id} />
        } else {
            return (
                <div className="logInScreen">
                    {/* <p style={{color: this.props.feeds.loginUIMetadata.headerTextColor}}>{this.props.feeds.loginUIMetadata.txtFirstSave}</p> */}
                    <p className="user-access-heading" style={{ color: this.props.feeds.loginUIMetadata.colors.headerText || "#FFD626FF" }}>
                        {this.props.titleMsg || this.props.feeds.loginUIMetadata[screen].strings.header}</p>

                    <form id="account-form">
                        <input onFocus={e => this.controlNavigation(e, 'pause')} onBlur={e => this.controlNavigation(e, 'resume')} id="email_id" onKeyDown={e => this.handleInputSelect(e, 'email')} className="focusable" tabIndex={getTabIndex()} type="email" placeholder={ALL_C.kEmailPlaceholder} onChange={this.handleEmailChange} value={this.state.email} dir={elemOrientation(this.props.feeds)} /><br />
                        <span className="btnForgot">
                            <input onFocus={e => this.controlNavigation(e, 'pause')} onBlur={e => this.controlNavigation(e, 'resume')} id="pswd" onKeyDown={e => this.handleInputSelect(e, 'pswd')} className="focusable" tabIndex={getTabIndex()} type="password" placeholder={ALL_C.kPasswordPlaceholder} onChange={this.handlePasswordChange} value={this.state.password} dir={elemOrientation(this.props.feeds)} />
                            {configuration.is_simulator && (<a className="focusable" tabIndex={getTabIndex()} href="#" onClick={this.forgotPassword}>{ALL_C.kForgotPassword}</a>)}
                        </span><br />
                        <span className="btnBox">
                            {(this.props.feeds.loginUIMetadata.fb && !this.props.modalLogin) && <a href="#" onClick={this.props.selectBack} className="bck-btn">Back</a>}
                            <button className="continue-btn focusable" tabIndex={getTabIndex()} onClick={this.formSubmit} type="submit" value="Create Account" style={{ color: this.props.feeds.loginUIMetadata.buttonTextColor, backgroundColor: this.props.feeds.loginUIMetadata.buttonColor }}>{createText || "Create Account"}</button>
                            <button className="login-btn focusable" tabIndex={getTabIndex()} onClick={this.formSubmit} type="submit" value="Login">{loginText || "Login"}</button>
                            {checkCorrectPlatform([PLATFORM_WEB, PLATFORM_WEB_PREVIEW]) && (<button className="focusable" tabIndex={getTabIndex()} onClick={this.forgotPassword}>{ALL_C.kForgotPassword}</button>)}
                        </span>

                        {
                                <div onKeyUp={e => this.handleInputEventOnKeyboard(e)} id="virtual_keyboard" style={{ width: '100%',left: 0, height: 'auto', margin: '0px auto', marginTop: '1em', position:'absolute', bottom: '0', display: this.state.show_keyboard ? 'block' : 'none' }}>
                                    <Keyboard
                                        keyboardRef={r => (this.keyboard = r)}
                                        layout={this.layout}
                                        layoutName={this.state.layoutName}
                                        enableKeyNavigation={true}
                                        useMouseEvents={true}
                                        buttonTheme={[
                                            {
                                                class: 'hg-black focusable',
                                                buttons: `${this.layout.default.join(' ')} ${this.layout.shift.join(' ')}`
                                            }
                                        ]}
                                        buttonAttributes={[
                                            {
                                                attribute: 'tabindex',
                                                value: '-1',
                                                buttons: `${this.layout.default.join(' ')} ${this.layout.shift.join(' ')}`
                                            },
                                            {
                                                attribute: 'aria-label',
                                                value: 'space',
                                                buttons: '{space}'
                                            }
                                        ]}
                                        onKeyPress={btn => this.keyboardKeyPressed(btn)}
                                        onChange={this.onKeyboardChange}
                                    />
                                </div>
                        }
                    </form>
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    user_error: state.user.error,
    subscription: state.subscription,
    isUserLoading: state.user.isLoading,
    emailLoginLoading: state.user.emailLoginLoading
})

const mapDispatchToProps = {
    userActions: userActions,
    openModal: openModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(UserAccess)
