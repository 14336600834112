import React from 'react';
import { connect } from "react-redux";

import { meteringDetails, getMeteringDisplay, saveUserMeteringDetails } from "../actions/userActions";
import { getMeteringTypes, PLATFORM_HTML_SIMULATOR, PLATFORM_SAMSUNG, PLATFORM_LG, PLATFORM_XBOX } from '../constants/common';
import * as analytics  from '../constants/analytics';
import { openModal } from '../actions/modalActions'
import isAvailable from '../helperfunctions/is_available'
import configuration from '../constants/config';
import { checkIfSectionLevelContent } from '../helperfunctions';
import { checkCorrectPlatform,isSmartTv } from '../helperfunctions/common';
import { getUserCookie } from '../helperfunctions/storage';


class Metering extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      metering_type: null,
    }
    this.meteringCounterId = null
    this.METERING_TYPE = getMeteringTypes();
    this.saveMeteringLocally = this.saveMeteringLocally.bind(this);
  }

  componentDidMount() {
    if(this.props.logged_in || (configuration.is_simulator && checkCorrectPlatform([PLATFORM_HTML_SIMULATOR]))) {
      this.meteringInit();
      window.addEventListener("unload", this.saveMeteringLocally, true);
    } else {
      this.onFreeContentOver(true, true)
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.content_id !== this.props.content_id && this.state.metering_type === this.METERING_TYPE.VIEWS) {
      this.updateViewCounter()
    }
  }

  componentWillUnmount() {
    if (this.meteringCounterId) {
      clearInterval(this.meteringCounterId)
      this.meteringCounterId = null
    }
    // save data before unmounting
    this.saveMetring();
    window.removeEventListener("unload", this.saveMeteringLocally, true);
  }

  saveMeteringLocally() {
    //for refresh and tab close save time metering in localstorage
    if(this.state.metering_type === this.METERING_TYPE.TIME) {
      try {
        if(checkCorrectPlatform([PLATFORM_SAMSUNG]) && window.tizen) {
          window.tizen.keymanager.saveData('metering_details', JSON.stringify(this.props.metering_details), null, (err) => {console.log(err)});
        } else if(checkCorrectPlatform([PLATFORM_XBOX]) && window.Windows != undefined) {
          let localSettings = window.Windows.Storage.ApplicationData.current.localSettings;
          localSettings.values['metering_details'] = JSON.stringify(this.props.metering_details);
        } else {
          localStorage.setItem('metering_details', JSON.stringify(this.props.metering_details))
        }
      } catch(err) {
        console.log("unable to store metering data locally!");
      }
    }
  }

  saveMetring() {
    let user_cookie = getUserCookie();
    if(this.state.metering_type && user_cookie && !(configuration.is_simulator && checkCorrectPlatform([PLATFORM_HTML_SIMULATOR]))) {
      let meters = {
        type: this.state.metering_type
      }
      if(this.state.metering_type === this.METERING_TYPE.TIME) {
        meters.time = {}
        for(let key in this.props.metering_details) {
          //convert as api required
          meters.time[key] = {[key]: this.props.metering_details[key]}
        }
      } else {
        meters.views = this.props.metering_views_seen
      }
      const params = {
        auth_token: user_cookie.auth_token,
        user_id: user_cookie.user_id,
        meters: meters
      }

      this.props.saveUserMeteringDetails(params);
    }
  }

  meteringInit() {
    const { feeds, contentAccess } = this.props;
    if (feeds.ctaMetadata && feeds.ctaMetadata.metering) {
      // View metering
      this.setState({metering_type: this.METERING_TYPE.VIEWS})
      this.updateViewCounter()
    } else if (contentAccess && contentAccess.timed !== undefined) {
      //time metering
      this.setState({
        metering_type: this.METERING_TYPE.TIME
      })
      this.meteringCounter();
      this.meteringCounterId = setInterval(() => this.meteringCounter(), 1000)
    }
  }

  updateViewCounter() {

    const { content_id, feeds, metering_views_seen, isLive } = this.props;
    if (isLive) {//view metering won't work in case of live & simlive
      this.onFreeContentOver(null, true)
      return;
    }
    let viewRemaining = feeds.ctaMetadata.metering.freeViews - metering_views_seen.length
    if(metering_views_seen.length === 0)
      analytics.setEvent('Metering', 'Meter Start', this.props.title)
    //if(viewRemaining === 0)
      
    if (!metering_views_seen.includes(content_id) && viewRemaining <= 0) {
      // Free content over
      
      this.onFreeContentOver(null, true)
    } else if (!metering_views_seen.includes(content_id)) {
      this.props.meteringDetails({
        item_id: content_id,
        mtype: this.METERING_TYPE.VIEWS
      })
      viewRemaining = viewRemaining - 1;
      if(viewRemaining==0)
        analytics.setEvent('Metering', 'Meter Expired', this.props.title);
      setTimeout(() => {
        this.saveMetring();
      },1000)
    }
    if(viewRemaining === undefined || viewRemaining < 0) viewRemaining = 0
    this.setState({viewRemaining: viewRemaining})
  }

  meteringCounter() {
    const { contentAccess, metering_details, parent_id, playing_Ad } = this.props;
    let current_time = (metering_details[parent_id] !== undefined) ? metering_details[parent_id] : contentAccess.timed;
    if(current_time==contentAccess.timed){
      analytics.setEvent('Metering', 'Meter Start', this.props.title);
    }else if(current_time == 0){
      analytics.setEvent('Metering', 'Meter Expired', this.props.title)
    }

    current_time =  current_time - 1;
    if(!playing_Ad && (!isSmartTv() || (isSmartTv() && this.props.video_start))) {
      // if playing ad pause metering update
      this.props.meteringDetails({
        section_id: parent_id,
        seconds: parseInt(current_time),
        mtype: this.METERING_TYPE.TIME
      })
    }
    if (current_time < 0) {
      clearInterval(this.meteringCounterId)
      this.meteringCounterId = null
      const isMeteringAlreadyEnded = current_time < -1;
      this.onFreeContentOver(null, isMeteringAlreadyEnded)
    }
  }

  isLiveFakeOnSectionLevel = () => {
    if ((this.props.content.type === 'live' || this.props.content.type === 'fake') && checkIfSectionLevelContent()) {
      return true;
    }
    return false;
  }

  onFreeContentOver(forLogin, showInterstitial) {
    this.props.onFreeContentOver();
    const loginTitle = this.props.content.registerWall ? null : 'IN ORDER TO ACCESS LOCKED CONTENT YOU NEED TO REGISTER';
    const screen = this.props.content.registerWall ? 'registerWall': 'firstSave';
    if (!forLogin && !isAvailable(this.props.parent_id, this.props.content.special, false, this.props.content)) {
      this.props.openModal(
        {
          type: "subscriptions",
          feeds: this.props.feeds,
          content: this.props.content,
          parent_id: this.props.parent_id,
          goBack: this.props.modalHead || true,
          hideCloseButton: this.isLiveFakeOnSectionLevel(),
          interstitialModalDetails: showInterstitial ? this.props.interstitialModalDetails : null
        }
      )
    } else {
      this.props.openModal(
        {
          type: "login",
          screen: screen,
          goBack: this.props.modalHead || true,
          hideCloseButton: this.isLiveFakeOnSectionLevel(),
          text: loginTitle,
          interstitialModalDetails: showInterstitial ? this.props.interstitialModalDetails : null
        }
      )
    }
  }

  render() {
    const { contentAccess, metering_details, parent_id, playing_Ad, showMetering } = this.props;
    if (!this.state.metering_type || playing_Ad || !showMetering) return null;
    return (
      <div className="prevThumbnail metering-counter" id="MeteringCounter">
        <span className="VideoSecondsMetering">
          {this.state.metering_type === this.METERING_TYPE.VIEWS 
            ? this.state.viewRemaining + " views remaining"
            : getMeteringDisplay((metering_details[parent_id] !== undefined) ? metering_details[parent_id] : contentAccess.timed) + " remaining"
          }
        </span>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  metering_details: state.user.metering_details,
  metering_views_seen: state.user.metering_views_seen,
  logged_in: state.user.logged_in,
})
  
const mapDispatchToProps = {
  meteringDetails: meteringDetails,
  saveUserMeteringDetails: saveUserMeteringDetails,
  openModal: openModal,
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Metering);
  