import React from 'react';
import { connect } from "react-redux";
import { Redirect, withRouter } from 'react-router-dom'
import { getSearchResult, clearSearch } from '../actions/searchActions'
import Background from '../components/Background'
import ThumbnailSearch from '../components/ThumbnailSearch'
import { environment } from "../constants/initializer"
import ReactGA from 'react-ga';
import configuration from '../constants/config';
import * as analytics from '../constants/analytics';
import Keyboard from 'react-simple-keyboard';
import { checkCorrectPlatform, getTabIndex, isFeedAvailable, isSmartTv, showVirtualKeyboard } from '../helperfunctions/common';
import { addRemoveAllNavigation, checkIfSpecialCharacter, snFocusEventHandler, speakText } from '../helperfunctions/ctvHelper';
import JsSpatialNavigation from '../ctvnavigation/js-spatial-navigation';
import { PLATFORM_LG, PLATFORM_VIZIO, PLATFORM_XBOX, PLATFORM_ZEASN } from '../constants/common';
import axios from 'axios';
import Toast from '../components/toast';
import { getAllConstants } from '../helperfunctions/regionalization';
import VirtualGrid from '../components/VirtualGrid';
import { elemOrientation } from '../helperfunctions/feedHelper';

// let last_focus_osk = false;

const handlekeyDown = (e) => {
  if(!isSmartTv()) return;

  switch(e.keyCode) {
    case 13: // enter pressed
      document.querySelector('.search-box input').blur();
    break;
    case 65376: // done pressed
      speakText(document.getElementById("search").value);
    case 65385: // cancel pressed
    case 10009: // samsung back press
    case 461: // lg back press
    case 198: // xbox back press
    case 196: // xbox back press
      JsSpatialNavigation.resume();
    break;
  }
}

const controlNavigation = (e, _type) => {
  if(!isSmartTv()) return;

  if(showVirtualKeyboard()) {
    try {
      let last_focus_osk = parseInt(document.getElementById('virtual_keyboard').getAttribute('data-last_focus_osk'));
      if(_type == 'pause' && !last_focus_osk) {
        setTimeout(() => {
          document.getElementById('virtual_keyboard').style.display = 'block';
          document.querySelector('.hg-row .focusable').focus();
          document.getElementById('virtual_keyboard').setAttribute('data-last_focus_osk', 1);
          // last_focus_osk = true;
        }, 500);
      } else if(_type == 'pause' && last_focus_osk) {
        document.getElementById('virtual_keyboard').style.display = 'none';
        document.getElementById('virtual_keyboard').setAttribute('data-last_focus_osk', 0);
        // last_focus_osk = false;
      }
    } catch(err) {
      console.log(err)
    }
    return;
  }

  if(_type == 'pause' && !checkCorrectPlatform([PLATFORM_XBOX, PLATFORM_ZEASN])) {
    JsSpatialNavigation.pause();
  } else {
    JsSpatialNavigation.resume();
  }
}

const SearchScreen = (props) =>
  <div className="search-box">
    <input aria-describedby='search-msg' onKeyDown={handlekeyDown} onFocus={e => controlNavigation(e, 'pause')} onBlur={e => controlNavigation(e, 'resume')} className="focusable" tabIndex={getTabIndex()} type="text" onChange={props.update} id="search" value={props.search_value} placeholder={props.kSearchPlaceholder} dir={elemOrientation(props.feeds)}/>
  </div>

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.timer = null
    this.state = { displayUserLogin: false, search_value: props.search_string || "", input_focus: '', layoutName: "default", showMenu: null, show_toast: false, hasSearchInitiated: false }
    this.update = this.update.bind(this)
    this.renderVirtualGrid = this.renderVirtualGrid.bind(this)
    this.renderThumbnailSearch = this.renderThumbnailSearch.bind(this)
    if (this.props.isSearchCleared) {
      this.clearSearch();
    }
    this.toast_timeout = null;
  }

  componentDidMount() {
    if(this.props.runningOnMobile) {
      document.body.className = "video-grid"
    } else {
      document.body.className = "grid"
    }
    if (window.location.pathname.includes('search/')) {
      this.props.history.replace(`/apps/${configuration.app_id}/search`);
    }
    const navbar = document.getElementsByClassName("nav-view")[0];
    navbar && navbar.parentNode.classList.remove('fix-nav');
    analytics.setPageViewFixedSections('Search')
    var navbarLinks = document.getElementsByClassName("headerLinks")
    for (var i = 0; i < navbarLinks.length; i++) {
      navbarLinks[i].classList.remove("active")
    }
    // document.getElementsByClassName("navbar-search")[0] && document.getElementsByClassName("navbar-search")[0].classList.add("active")

    if(configuration.is_simulator) {
      const navbar = document.getElementsByClassName("nav-view")[0];
      navbar && navbar.parentNode.classList.remove('fix-nav');
      var navbarLinks = document.getElementsByClassName("headerLinks")
      const {ottTabBarInactiveColor, ottTabBarActiveColor} = this.props.feeds;
      for(var i=0; i<navbarLinks.length; i++){
        navbarLinks[i].classList.remove("active")
        if(ottTabBarInactiveColor) {
          navbarLinks[i].style.color = ottTabBarInactiveColor.replace("#FF", "#")
        }
      }
      if (document.getElementsByClassName("navbar-search")[0]) {
        document.getElementsByClassName("navbar-search")[0].classList.add("active")
        if(ottTabBarActiveColor) {
          document.getElementsByClassName("navbar-search")[0].style.color = ottTabBarActiveColor.replace("#FF", "#")
        }
      }
    }
    if(isSmartTv()) {
      this.search_dom_elem = document.querySelector('.search-box input');
      if(configuration.use_light_sliders) {
        this.search_box_id = addRemoveAllNavigation({ selector: '.search-box .focusable'});
        this.search_result_id = addRemoveAllNavigation({ selector: '.search-row .focusable' });
        this.virtual_keyboard_id = addRemoveAllNavigation({ 
          selector: '#virtual_keyboard .focusable', 
          config: {
            leaveFor: { 
              'up': `@${this.search_box_id}`
            }
          }
        });

        JsSpatialNavigation.set('navbar', {
          leaveFor: {
            'down': `@${this.search_box_id}`
          }
        });        
      }

      if(checkCorrectPlatform([PLATFORM_LG])) {
        document.addEventListener('keyboardStateChange', this.keyboardStateChange, false);
      }

      document.querySelector('.search-section').addEventListener('sn:focused', snFocusEventHandler);
    }
    if(showVirtualKeyboard()) {
      document.getElementById('virtual_keyboard').style.display = 'none';
    }
  }

  componentWillUnmount() {
    if(isSmartTv()) {
      if(!this.props.searchRouteEntry || this.props.searchRouteEntry.length === 0) {
        this.clearSearch();
      }
      if(configuration.use_light_sliders) {
        // addRemoveAllNavigation({ id: this.id });
        addRemoveAllNavigation({ id: this.search_box_id });
        addRemoveAllNavigation({ id: this.search_result_id });
        addRemoveAllNavigation({ id: this.virtual_keyboard_id });

        JsSpatialNavigation.set('navbar', {
          leaveFor: {
            'down': '.content-box .focusable'
          }
        });        
      }

      if(checkCorrectPlatform([PLATFORM_LG])) {
        document.addEventListener('keyboardStateChange', this.keyboardStateChange, false);
      }
      JsSpatialNavigation.resume();

      document.querySelector('.search-section') && document.querySelector('.search-section').removeEventListener('sn:focused', snFocusEventHandler);
    }
  }

  keyboardStateChange(event) {
    let visibility = event.detail.visibility;
    if(!visibility) {
      JsSpatialNavigation.resume();
    }
  }
  
  componentDidUpdate() {
    if (Object.keys(this.props.modal_progress).length > 0) {
      if (document.getElementsByClassName("image-content-" + this.props.modal_progress.item)[0]) {
        window.scrollTo(0, document.getElementsByClassName("image-content-" + this.props.modal_progress.item)[0].getBoundingClientRect().top + document.body.scrollTop - window.innerHeight / 2)
      }
      var elem = document.getElementsByClassName("searchContent hovered")[0]
      if (elem) {
        elem.classList.remove("hovered")
      }
      analytics.setPageViewFixedSections('Search')
      if(configuration.is_simulator) {
        const navbar = document.getElementsByClassName("nav-view")[0];
        navbar && navbar.parentNode.classList.remove('fix-nav');
        var navbarLinks = document.getElementsByClassName("headerLinks")
        const {ottTabBarInactiveColor, ottTabBarActiveColor} = this.props.feeds;
        for(var i=0; i<navbarLinks.length; i++){
          navbarLinks[i].classList.remove("active")
          if(ottTabBarInactiveColor) {
            navbarLinks[i].style.color = ottTabBarInactiveColor.replace("#FF", "#")
          }
        }
        // if (document.getElementsByClassName("navbar-search")[0]) {
        //   document.getElementsByClassName("navbar-search")[0].classList.add("active")
        //   if(ottTabBarActiveColor) {
        //     document.getElementsByClassName("navbar-search")[0].style.color = ottTabBarActiveColor.replace("#FF", "#")
        //   }
        // }
      }
    }
  }

  showToastForZeasnWhenSpecialCharInput(event_target) {
    if(event_target[event_target.length-1] && checkIfSpecialCharacter(event_target[event_target.length-1].charCodeAt(0))) {
      this.setState({ show_toast: true })

      if(this.toast_timeout) {
        clearTimeout(this.toast_timeout)
      }

      this.toast_timeout = setTimeout(() => {
        this.setState({ show_toast: false })
      }, 4000);
    }
  }

  update(event) {
    var event_target = event.target.value;

    checkCorrectPlatform([PLATFORM_ZEASN]) && this.showToastForZeasnWhenSpecialCharInput(event_target)

    this.setAxiosCancelRequest();

    if (!event_target) {
      this.clearSearch()
    }
    else {
      this.setState({hasSearchInitiated: true})
      this.timer = setTimeout(function () {
        analytics.setEvent('Search', 'Search Query', this.state.search_value)
        this.props.getSearchResult(this.props.app_id, this.state.search_value)
        this.setState({hasSearchInitiated: false})
      }.bind(this), 700)
    }
    this.setState({ search_value: event_target })
  }

  clearSearch = () => {
    this.props.clearSearch()
  }

  keyboardKeyPressed = (button) => {
    if (button === "{shift}" || button === "{lock}") {
      const layoutName = this.state.layoutName;

      this.setState({
        layoutName: layoutName === "default" ? "shift" : "default"
      });
    }
  }

  onKeyboardChange = (input) => {
    const event_target = input;

    this.setAxiosCancelRequest();

    if (!event_target) {
      this.clearSearch();
    } else {
      this.setState({hasSearchInitiated: true})
      this.timer = setTimeout(function () {
        this.props.getSearchResult(this.props.app_id, this.state.search_value, { cancelToken: this.axios_source.token })
      this.setState({hasSearchInitiated: false})
      }.bind(this), 1000);
    }

    this.setState({ search_value: input });
  }

  setAxiosCancelRequest() {
    if(this.timer) {
      this.axios_source && this.axios_source.cancel("Request cancelled by the user!");
      clearTimeout(this.timer);
      this.timer = null;
    }

    const CancelToken = axios.CancelToken;
    this.axios_source = CancelToken.source();
  }

  addDataSnAttribute(type) {
    let last_btn_elms = document.getElementsByClassName(`${type}-buttons`);
    for(let i=0;i<last_btn_elms.length;i++) {
      last_btn_elms[i].setAttribute(`data-sn-${type}`,'');
    }
  }

  handleMobileInputForVizio(e) {
    if(!checkCorrectPlatform([PLATFORM_VIZIO])) return;
    let input = e.key;
 
    if(e.key == 'Backspace') {
      input = '{bksp}';
    } else if(e.key == ' ') {
      input = '{space}';
    }

    if(input == '{bksp}' && (this.search_dom_elem && this.search_dom_elem.value.length == 0)) {
      return;
    }

    let _layout_name;

    for(let key in this.layout) {
      if(this.layout[key].join(' ').includes(input)) {
        _layout_name = key;
        break;
      }
    }

    if(!_layout_name) return;
  
    if(_layout_name != this.state.layoutName) {
      this.setState({ layoutName: _layout_name },() => {
        this.manualClickKeyboard(input);
      });
    } else {
      this.manualClickKeyboard(input);
    }
  }

  manualClickKeyboard(key) {
    setTimeout(() => {
      try {
        const btn_elm = this.keyboard.getButtonElement(key);
        if(!btn_elm) return;
        btn_elm.focus();
        btn_elm.click();
      } catch(err) {
        console.log("Unable to click manually",err);
      }
    }, 0);
  }

  renderVirtualKeyboard() {
    if(!showVirtualKeyboard()) return null;

    this.layout = {
      'default': [
        '1 2 3 4 5 6 7 8 9 0',
        'q w e r t y u i o p',
        'a s d f g h j k l -',
        '{shift} z x c v b n m _',
        '.com @ {bksp} {space} .'
      ],
      'shift': [
        '~ ! @ # $ % ^ & * ( ) _ +',
        'Q W E R T Y U I O P { } |',
        `A S D F G H J K L : " '`,
        '{shift} Z X C V B N M < > ?',
        '.com @ {bksp} {space}'
      ]
    };
    
    return (
      <div onKeyUp={e => this.handleMobileInputForVizio(e)} id="virtual_keyboard" data-last_focus_osk="0" style={{ width: '100%', margin: '0px auto', position: 'absolute', bottom: '0', height: 'auto', zIndex: 100 }}>
        <Keyboard
          keyboardRef={r => (this.keyboard = r)}
          layout={this.layout}
          layoutName={this.state.layoutName}
          enableKeyNavigation={true}
          useMouseEvents={true}
          buttonTheme={[
            {
              class: 'hg-black focusable',
              buttons: `${this.layout.default.join(' ')} ${this.layout.shift.join(' ')}`
            },
            {
              class: 'down-buttons',
              buttons: `.com @ {bksp} {space}`
            },
            {
              class: 'left-buttons',
              buttons: `1 q a {shift} .com ~ Q A`
            },
            {
              class: 'right-buttons',
              buttons: `0 p - _ . + | " ?`
            }
          ]}
          buttonAttributes={[
            {
              attribute: 'tabindex',
              value: '-1',
              buttons: `${this.layout.default.join(' ')} ${this.layout.shift.join(' ')}`
            },
            {
              attribute: 'aria-label',
              value: 'space',
              buttons: `{space}`
            }
          ]}
          onKeyPress={btn => this.keyboardKeyPressed(btn)}
          onChange={this.onKeyboardChange}
          onRender={() => {
            this.addDataSnAttribute('down');
            this.addDataSnAttribute('left');
            this.addDataSnAttribute('right');
            document.querySelector('.hg-row .focusable').focus();
          }}
        />
      </div>
    );
  }

  appendParentMenuContent = (content) => {
    const {locked, access, registerWall ,special,identifier,showInterstitial} = content.parentMenu;
    if(configuration.isAppTvodType) return {...content, showInterstitial: showInterstitial };
    const parentMenuAppendedContent = {...content,locked : locked,access: access,special:special, registerWall: registerWall, parent_id: identifier,showInterstitial:showInterstitial}
    return parentMenuAppendedContent
  }

  onMenuClick(menu) {
    this.setState({showMenu: menu})
  }
  
  renderToast() {
    return (
      <Toast type={"error"} msg={"Special Charatcters not allowed!"} />
    )
  }

  renderThumbnailSearch = ({policyData, thumbnailSearchData}) => {
    if(!isFeedAvailable(...policyData)) return;

    return <ThumbnailSearch {...thumbnailSearchData} />
  }

  renderVirtualGrid({contents}, rtl){
    const contentPropsData = {
      items: this.props.itemsReducer,
      head: 1,
      primary: this.props.feeds.primaryColor,
      secondary: this.props.feeds.secondaryColor,
      all_contents: this.props.items.content,
      onMenuClick: (menu) => this.onMenuClick(menu),
      feeds: this.props.feeds,
    }
    return <VirtualGrid rtl={rtl} contents={contents} render={({content}) => this.renderThumbnailSearch({
      policyData: [this.props.policyData.country, this.props.feeds.countryGroups, content.countryGroupId],
      thumbnailSearchData: {...contentPropsData, locked: content.locked, key: content.identifier, content: this.appendParentMenuContent(content), onMenuClick: (menu) => this.onMenuClick(menu)}
    })}/>
  }

  render() {
    let contents = this.props.items.content || [];
    const { kSearchMsg, kSearchEmptyText, kSearchPlaceholder } = getAllConstants();
    let search_res_string = kSearchMsg;
    if(isSmartTv()) {
      if(!this.props.isSearchPending && this.state.search_value && !contents.length && !this.state.hasSearchInitiated) {
        search_res_string = kSearchEmptyText;
      }
    } else if( this.props.search_string.length > 0 && !contents.length ){
      search_res_string = kSearchEmptyText;
    }

    if(this.state.showMenu) {
      return <Redirect to={this.state.showMenu}/>
    }

    const rtl = elemOrientation(this.props.feeds)

    return (
      <div className="search-section">
          { (checkCorrectPlatform([PLATFORM_ZEASN]) && this.state.show_toast) ? this.renderToast() : null }
          <Background background={this.props.background} />
          {configuration.is_simulator && <SearchScreen update={this.update} search_value={this.state.search_value} kSearchPlaceholder={kSearchPlaceholder}/>}
          { this.props.isSearchPending &&
            <div id="searchLoader" style={{display: 'block', backgroundColor: 'transparent', zIndex: 100}} className="loader"><img src={"https://s3.amazonaws.com/resources.magappzine.com/assets/core/" + environment() + "/loader.gif"}/></div>
            }
          { this.renderVirtualKeyboard() }
          { (contents.length > 0 && this.props.search_string.length > 0 )
            ? <div className="search-row" dir={rtl}>
              {isSmartTv() ? this.renderVirtualGrid({contents}, rtl) :
              <>{contents.map(function(content, i) {
                const policyData =  [this.props.policyData.country, this.props.feeds.countryGroups, content.countryGroupId]
                      const thumbnailSearchData = {
                          items: this.props.itemsReducer,
                          locked: content.parentMenu.locked,
                          feeds: this.props.feeds,
                          key: content.identifier,
                          content: this.appendParentMenuContent(content),
                          head: 1,
                          primary: this.props.feeds.primaryColor,
                          secondary: this.props.feeds.secondaryColor,
                          all_contents: this.props.items.content,
                          onMenuClick: (menu) => this.onMenuClick(menu)
                      }
                return (
                  this.renderThumbnailSearch({policyData, thumbnailSearchData}))
              }, this)}</>}
            </div>
            : (!this.props.isSearchPending 
            ? <div id="search-msg" className="no-search-items" aria-live="polite" aria-atomic="true"><span>{ search_res_string }</span></div>
              : null
            )
          }
      </div>
    )
  }
}


const mapStateToProps = (state) => ({
  items: state.search.search,
  searchRouteEntry: state.search.searchRouteEntry,
  search_string: state.search.search.search_string,
  modal_progress: state.modal.progress,
  isSearchCleared: state.search.isSearchCleared,
  isSearchPending: state.search.isSearchPending,
  policyData: state.feeds.policyData
})

const mapDispatchToProps = {
  getSearchResult: getSearchResult,
  clearSearch
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Search))
