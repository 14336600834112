import { AUTH_ON_SCREEN,AUTH_WEBSITE, DEFAULT_LOGIN_UI_METADATA, getSaveFeedSectionIdentifier, PLATFORM_LG, PLATFORM_SAMSUNG, VIDEO_ERROR, PLATFORM_WEB, deepLinkUrl, PLATFORM_VIZIO, PLATFORM_XBOX, PLATFORM_ZEASN, PLATFORM_WEB_PREVIEW } from '../constants/common';
import store from './../store.js';
import config from '../constants/config';
import { backButtonObj, checkIfSectionLevelContent, findDefaultSection } from './feedHelper';
import { elementScrollIntoView } from "seamless-scroll-polyfill";
import JsSpatialNavigation from '../ctvnavigation/js-spatial-navigation';
import { checkIfFocusInsideBanner, retainFocusOnBackPress } from '../helperfunctions/ctvHelper';
import { getLocalStorage, getUserCookie, setLocalStorage } from './storage';
import FullscreenExpand from "../images/icons/fullscreen-expand.png";
import FullscreenContract from "../images/icons/fullscreen-contract.png";
import { getAllConstants } from './regionalization';
import { getUniqueSessionId, ifaMacrosAddition, ifaMacrosFun } from './adMacros';

/**
 * Function to get new url of image, in which you can pass size and that size url been generated
 * @param {*} url 
 * @param {*} size - It can be any one of these {300, 384, 600, 768, 900, 1152, 1536, 2048, 2250}
 */
export function getImageUrlBaseOnSize(url, size) {
    var newUrl = url.split(".")
    newUrl[newUrl.length - 2] = newUrl[newUrl.length - 2] + `-${size}`
    newUrl = newUrl.join(".")
    return newUrl
}

export function checkIsLoginFromCookies() {
    let user_cookie = getUserCookie();
    if (user_cookie)
        return true;
    return false;
}

/**
 * Function to check when click on deepLink, do we need to, do some action or not.
 * @param {*} actionUrl deeplink url
 * @param {*} feeds 
 */
export function checkIfToDoNothing(actionUrl, feeds) {
    const isLogin = checkIsLoginFromCookies();
    if (actionUrl.includes('/existingsub/'))
        return true
    //In case of save, do nothing, if sections doesn't have save section
    if (actionUrl.includes("save") && !getSaveFeedSectionIdentifier(feeds))
        return true;
    //If user is logged In, do nothing on login deeplink click
    if (isLogin && actionUrl.includes("/register/"))
        return true;
    return false;
}

export function detectPlatform() {
    let agent = window.navigator.userAgent;
    let platform = '';

    agent = agent.toLowerCase();

    if (agent.includes('tizen') && agent.includes('smart-tv')) {
        platform = 'Samsung';
    } else if (agent.includes('web0s')) {
        platform = 'LG';
    } 
    // else if (agent.includes('philips')) {
    //     platform = 'Philips';
    // } 
    else if (agent.includes('macintosh')) {
        platform = 'Macintosh';
    } else if(agent.includes('vizio')) {
        platform = 'Vizio';
    } else if(agent.includes('xbox')) {
        platform = 'Xbox';
    } else if(agent.includes('nettv') || agent.includes('whaletv') || agent.includes('philips')) {
        platform = 'Zeasn';
    } else {
        platform = 'unknown';
    }

    // console.log(`You are on: ${platform} platform`);
    return platform;
}

export function isSmartTv() {
    // const agent = window.navigator.userAgent;
    // return agent.includes('SmartTV');
    if (checkCorrectPlatform([PLATFORM_SAMSUNG, PLATFORM_LG, PLATFORM_VIZIO, PLATFORM_XBOX, PLATFORM_ZEASN]))
        return true;
    return false;
}

/**
 * Function get tabIndex value for elements. For CTV we need to assign that. For CTV its value is "-1".
 * @param {string} value It can be "-1", "0" or +ve values. By default it's value is "0"
 */
export function getTabIndex(value = "-1") {
    if (isSmartTv())
        return value;
    return "default"; //Default value for other platforms.
}

export function checkIfSafari() {
    const isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
        navigator.userAgent &&
        navigator.userAgent.indexOf('CriOS') == -1 &&
        navigator.userAgent.indexOf('FxiOS') == -1;
    return isSafari;
}

export function showVirtualKeyboard() {
    if (checkCorrectPlatform([PLATFORM_VIZIO]))
        return true;
    return false;
}

export function checkIfOverlayVideoIsPresent() {
    if (document.getElementById('thumb-video') || document.getElementById('thumb-video_html5_api') ||
        document.getElementById('fake-video_html5_api') || document.getElementById('fake-video')) {
        return true;
    }
    return false;
}

export function checkIfEmptyMazIdFeedsInContents(contents) {
    //We have only three maz id feeds types.
    if (!contents) return false;
    const newState = store.getState();
    const isLoggedIn = newState.user.logged_in;
    const savedItemsCount = newState.items.items.content.length;
    const historyItemsCount = newState.items.history.contents.length;
    const progressItemsCount = newState.items.progress.contents.length;
    const totalCount = savedItemsCount + historyItemsCount + progressItemsCount;
    if (contents.length > 3) return false;
    const allContents = contents.filter(content => {
        if (!isLoggedIn && content.mazIDSectionType && !totalCount) {
            return false;
        }
        return true;
    })
    if (!allContents.length) return true;
    return false;
}

/**
 * This function check if feed contains proper login-ui-metadata, If not then add hardcoded metadata. It happens when MAZ ID is off from admin.
 * @param {*} feeds 
 */
export function checkLoginUIMetaData(feeds) {
    const newFeed = { ...feeds };
    const oldLoginMetadata = {
        ...newFeed.loginUIMetadata
    }
    const firstSave = 'firstSave';
    const registerScreen = "registrationWall";
    if (config.isAppTvodType && newFeed.loginUIMetadata["tvodLogin"]) {
        return newFeed
    }

    if (!newFeed.loginUIMetadata[firstSave] || !newFeed.loginUIMetadata[registerScreen]) {
        newFeed.loginUIMetadata = {
            ...DEFAULT_LOGIN_UI_METADATA,
            ...oldLoginMetadata
        }
    }
    return newFeed;
}

/**
     * Function to check if the login page contains only single login type (OAuth only), If yes return true
     */
export function checkIfSingleOption(screen, feeds) {
    // const screen = this.getScreenType();
    if (config.isAppTvodType) {
        screen = "tvodLogin"
    }
    const loginUI = feeds.loginUIMetadata[screen];
    const isLoginTypeWebsite = loginUI.authType === AUTH_WEBSITE;
    if (!(config.fb_app_id && loginUI.fb) && isLoginTypeWebsite) {
        return true;
    }
    return false;
}

export const checkIfOnlyOneLoginIsPresent = (screen, feeds, tveLoginMode, showWebsiteForTvodCtv, showFbLogin, showSubscriberHeader) => {
    let count = 0;
    let trueKey = null;
    let loginMap = {};

    const genOneLoginMap = {
        authOnScreen : feeds.loginUIMetadata[screen].authType === AUTH_ON_SCREEN,
        authWebsite: feeds.loginUIMetadata[screen].authType === AUTH_WEBSITE,
        fbLogin: showFbLogin,
        showSubscriberHeader: showSubscriberHeader
    }
    const genTwoLoginMap = {
        tveLogin: tveLoginMode,
        websiteForTvodCtv: showWebsiteForTvodCtv,
    }

    if(config.isAppTvodType) loginMap = {...genOneLoginMap, ...genTwoLoginMap}
    else loginMap = {...genOneLoginMap}

    for (const key in loginMap) {
        if (loginMap.hasOwnProperty(key) && loginMap[key] === true) {
            count++;
            trueKey = key;
            if (count > 1) {
                return { oneLoginPresent: false, screenToBeRender: null };
            }
        }
    }

    if (count === 1) {
        return { oneLoginPresent: true, screenToBeRender: trueKey };
    }

    return { oneLoginPresent: false, screenToBeRender: null };
}

export function onVideoError(videoType, player) {
    if(!player) return;
    const error = config.kUseTheoPlayer ? player.error : player.error();
    let msg = VIDEO_ERROR.MSG;
    if(error && error.code == 4) {
        msg = "Item not available";
    } else if (!checkIfSafari() && videoType === "application/x-mpegURL") {
        msg = VIDEO_ERROR.NON_SAFARI_M3U8_MSG;
    } 
    if(document.querySelector('.vjs-modal-dialog-content')) {
        document.querySelector('.vjs-modal-dialog-content').innerHTML = msg;
    }
}

/**
 *  // Videojs error codes:
 *
    MEDIA_ERR_CUSTOM: 0
    MEDIA_ERR_ABORTED: 1
    MEDIA_ERR_NETWORK: 2
    MEDIA_ERR_DECODE: 3
    MEDIA_ERR_SRC_NOT_SUPPORTED: 4
    MEDIA_ERR_ENCRYPTED: 5
 */
export function onCTVVideoError(e, player, type = '') {
    if(!e || !player) return;
    if(e.stopImmediatePropagation) e.stopImmediatePropagation();
    const error = config.kUseTheoPlayer ? player.error : player.error();
    let msg = 'This video has ended! Please come back later';
    switch(error.code) {
        case 2:
            // MEDIA_ERR_NETWORK
            msg = 'Network error occured! Please try again later';
        break;
        case 4:
            msg = "Item not available";
        break;
        default:
            if(type == 'live') {
                msg = 'This Live Stream has ended. Stay tuned for more content';
            }
        break;
    }
    if(document.querySelector('.vjs-modal-dialog-content')) {
        document.querySelector('.vjs-modal-dialog-content').innerHTML = msg;
    }

    if(config.platform == 'vizio_tv') {
        // if error occured on vizio tv, go back after 3 seconds
        setTimeout(() => {
            if(document.getElementById("overlay_video_back_btn")) {
                document.getElementById("overlay_video_back_btn").click();
                retainFocusOnBackPress(window.last_focus, null, window.location.pathname);
            }
        }, 5000);
    }
}

/**
 * 
 * @param {*} supportedPlatformList List for supported platforms
 */
export function checkCorrectPlatform(supportedPlatformList = []) {
    const currentPlatform = config.platform;
    if (supportedPlatformList.includes(currentPlatform))
        return true;
    return false;
}

export function toggleNavbarOnModalChanges(modal) {
    if (isSmartTv() && document.getElementById('nav-with-modal-id')) {
        if (Object.keys(modal).length) {
            document.querySelectorAll('#nav-with-modal-id > div').forEach(elm => {
                elm.style.display = 'none';
            })
        } else {
            document.querySelectorAll('#nav-with-modal-id > div').forEach(elm => {
                //If payment page do not change the display property
                if(elm.classList.contains("nav-view") || elm.classList.contains("display-flex")) {
                    elm.style.display = 'flex';
                } else if (!elm.classList.contains("payment-page")) {
                    elm.style.display = 'block';
                }
            })
        }
        if (checkIfSectionLevelContent() && !checkIfSettingPage()) {
            if (document.getElementById('nav-bar')) document.getElementById('nav-bar').style.display = 'flex';
        }
    }
}

function checkIfSettingPage() {
    const urlArr = window.location.pathname.split('/').slice(3);
    if (urlArr.includes('settings'))
        return true;
    return false;
}

/*
 *  Function returns the current platform string
 */
export function getCurrentPlatform() {
    if (checkCorrectPlatform([PLATFORM_WEB, PLATFORM_WEB_PREVIEW])) {
        return 'web_as_output';
    } else if (checkCorrectPlatform(PLATFORM_SAMSUNG)) {
        return 'samsung';
    } else if (checkCorrectPlatform(PLATFORM_LG)) {
        return 'lg';
    } else if(checkCorrectPlatform(PLATFORM_VIZIO)) {
        return 'vizio';
    } else if(checkCorrectPlatform([PLATFORM_XBOX])) {
        return 'xbox';
    } else if(checkCorrectPlatform([PLATFORM_ZEASN])) {
        return 'zeasn';
    }
    return null;
}

export function getCurrentPlatformTvod() {
    let platform = getCurrentPlatform();
    if(!config.isAppTvodType) return platform;
    if(platform === "web_as_output") {
        platform = "web"
    }
    return platform
}

export function addTvodAPIRegionalisation(data, getState, policy=null) {
    if(!config.isAppTvodType) return data;

    const policyData = policy || getState().feeds.policyData;
    const modData = {
        ...data,
        language: policyData.language,
        locale_id: policyData.locale_id
    }
    return modData;
}


export function getMacros(props, videoItem, player) {
    const playerDimension = getPlayerDimension(player);
    let macros = {
        cb: new Date().getTime(), // random number to prevent caching
        ua: window.navigator.userAgent || null, // user agent
        content_title: videoItem.title || '',
        coppa: 0,
        w: playerDimension.width || null, // player width
        h: playerDimension.height || null, // player height
        dur: videoItem.duration ? getSecondsFromDuration(videoItem.duration) : null, // video duration
        ap: 1, // player set to autoplay or not
        placement: 1, // placement of video
        mid: videoItem.identifier || null, // media id,
        cid: videoItem.identifier || null, // content id,
        vid: videoItem.identifier || null, // video id from item feed
        content_id: videoItem.identifier || null,
        v_url: videoItem.contentUrl || null, // encoded video url
        vt: videoItem.title || null, // encoded video title
        content_title: videoItem.title || null,// encoded content item title
        gdpr: (props.user_concent) ? 1 : 0,
        // lat: (props.user_coords) ? props.user_coords.latitude : null,
        // lon: (props.user_coords) ? props.user_coords.longitude : null,
        app_name: config.title || null,
        desc: videoItem.summary || null, // video description
        app_store_url: null, // app store url in ctv case
        brand_name: null, // samsung or lg
        device_make: null, // samsung or lg
        device_model: null,
        ip: null,
        did: null,
        app_bundle: null,
        us_privacy: null
    };

    macros.ip = props.user_ip || null;

    if(props.feeds.ccpa) {
        macros.us_privacy = '1N--';
    } else {
        macros.us_privacy = '1---';
    }

    if(checkCorrectPlatform([PLATFORM_WEB, PLATFORM_WEB_PREVIEW])) {
        macros.did = null; // since web has not device id, due to security reasons
        macros.device_make = "Web";
        macros.app_bundle = config.app_id || null;
        macros.app_store_url = `${window.location.origin}/apps/${config.app_id}`;
    } else if(checkCorrectPlatform([PLATFORM_SAMSUNG]) && window.tizen) {
        macros.brand_name = "Samsung";
        macros.device_make = "Samsung";
        try {
            macros.device_model = props.device_model;
            macros.did = props.device_uuid;
            macros.app_bundle = window.tizen.application.getCurrentApplication().appInfo.id;
        } catch(err) {
            macros.did = null;
            macros.app_bundle = null;
        }
    } else if(checkCorrectPlatform([PLATFORM_LG]) && window.webOS) {
        macros.brand_name = "LG";
        macros.device_make = "LG";
        try {
            macros.device_model = props.device_model;
            macros.did = props.device_uuid;
            macros.app_bundle = window.webOS.fetchAppId();
        } catch(err) {
            macros.did = null;
            macros.app_bundle = null;
        }
    } else if(checkCorrectPlatform([PLATFORM_VIZIO]) && window.VIZIO) {
        macros.brand_name = "VIZIO";
        macros.device_make = "VIZIO";
        try {
            macros.lmt = window.VIZIO.ad_id.LMT ? 1 : 0;
            macros.ifa_type = window.VIZIO.ad_id.IFA_TYPE;
            macros.did = window.VIZIO.ad_id.IFA;
            macros.device_model = props.device_model;
            macros.app_bundle = config.app_id || null;
            macros.app_store_url = `${window.location.origin}/apps/${config.app_id}`;
        } catch(err) {
            macros.did = null;
            macros.app_bundle = null;
        }
    } else if(checkCorrectPlatform([PLATFORM_ZEASN] && window.onDeviceInfoReady)) {
        macros.brand_name = "ZEASN";
        macros.device_make = "ZEASN";
        try {
            macros.device_model = props.device_model || null;
            macros.did = props.device_uuid || null;
            macros.app_bundle = config.app_id || null;
        } catch(err) {
            macros.did = null;
            macros.app_bundle = null;
        }
    }

    return macros;
}

const getPlayerDimension = (player) => {
    const dimensions = {};
    if (!player) return dimensions;

    if (player.currentWidth && player.currentWidth()) dimensions.width = player.currentWidth();
    if (player.currentHeight && player.currentHeight()) dimensions.height = player.currentHeight();
    if (dimensions.width && dimensions.height) return dimensions;

    // In case of Theo player height & width has null value, hence getting player element height and width
    if (player.element) {
        if (!dimensions.width && player.element?.offsetWidth) dimensions.width = player.element?.offsetWidth;
        if (!dimensions.height && player.element?.offsetHeight) dimensions.height = player.element?.offsetHeight;
    }

    return dimensions
}

export function getMacrosVal(props, videoItem, player) {
    const playerDimension = getPlayerDimension(player);
    let macros_val = {
        CB: new Date().getTime(), // random number to prevent caching
        UA: window.navigator.userAgent || null, // user agent
        CTITLE: videoItem.title || '',
        COPPAFLAG: 0,
        W: playerDimension.width || null, // player width
        H: playerDimension.height || null, // player height
        DUR: videoItem.duration ? getSecondsFromDuration(videoItem.duration) : null, // video duration
        ATPLY: 1, // player set to autoplay or not
        PLCMNT: 1, // placement of video
        MID: videoItem.identifier || null, // media id,
        CID: videoItem.identifier || null, // content id,
        VID: videoItem.identifier || null, // video id from item feed
        CID2: videoItem.identifier || null,
        VURL: videoItem.contentUrl || null, // encoded video url
        VT: videoItem.title || null, // encoded video title
        CTITLE: videoItem.title || null,// encoded content item title
        GDPRFLAG: (props.user_concent) ? 1 : 0,
        // LATITUDE: (props.user_coords) ? props.user_coords.latitude : null,
        // LONGITUDE: (props.user_coords) ? props.user_coords.longitude : null,
        AN: config.title || null,
        DESC: videoItem.summary || null, // video description
        ASU: null, // app store url in ctv case
        BN: null, // samsung or lg
        DMAKE: null, // samsung or lg
        DMODEL: null,
        IPADDRESS: props.user_ip || null,
        DID: null,
        AB: null,
        USPRIVACY: null,
        AD: (window.location && window.location.host) || null,
        SN: config.title || null,
        PURL: (window.location && window.location.href) || null,
        CONTENT_GENRE: videoItem.genre ? videoItem.genre.split(',')[0] : null,
        CONTENT_CHANNEL: videoItem.channelTitle || config.title || null,
        CONTENT_RATING: videoItem.rating || null
    };

    if(props.feeds.ccpa) {
        macros_val.USPRIVACY = '1N--';
    } else {
        macros_val.USPRIVACY = '1---';
    }

    if(checkCorrectPlatform([PLATFORM_WEB, PLATFORM_WEB_PREVIEW])) {
        macros_val.DID = null; // since web has not device id, due to security reasons
        macros_val.DMAKE = "Web";
        macros_val.AB = config.app_id || null;
        macros_val.ASU = `${window.location.origin}/apps/${config.app_id}`;
    } else if(checkCorrectPlatform(PLATFORM_SAMSUNG) && window.tizen) {
        macros_val.BN = "Samsung";
        macros_val.DMAKE = "Samsung";
        try {
            macros_val.DMODEL = props.device_model;
            macros_val.DID = props.device_uuid;
            macros_val.AB = window.tizen.application.getCurrentApplication().appInfo.id;
        } catch(err) {
            macros_val.DID = null;
            macros_val.AB = null;
        }
    } else if(checkCorrectPlatform(PLATFORM_LG) && window.webOS) {
        macros_val.BN = "LG";
        macros_val.DMAKE = "LG";
        try {
            macros_val.DMODEL = props.device_model;
            macros_val.DID = props.device_uuid;
            macros_val.AB = window.webOS.fetchAppId();
        } catch(err) {
            macros_val.DID = null;
            macros_val.AB = null;
        }
    } else if(checkCorrectPlatform([PLATFORM_VIZIO]) && window.VIZIO) {
        macros_val.BN = "VIZIO";
        macros_val.DMAKE = "VIZIO";
        try {
            macros_val.DID = window.VIZIO.ad_id.IFA;
            macros_val.DMODEL = props.device_model;
            macros_val.AB = config.app_id || null;
            macros_val.ASU = `${window.location.origin}/apps/${config.app_id}`;    
        } catch(err) {
            macros_val.DID = null;
            macros_val.app_bundle = null;
        }
    } else if(checkCorrectPlatform([PLATFORM_ZEASN] && window.onDeviceInfoReady)) {
        macros_val.BN = "ZEASN";
        macros_val.DMAKE = "ZEASN";
        try {
            macros_val.DMODEL = props.device_model || null;
            macros_val.DID = props.device_uuid || null;
            macros_val.AB = config.app_id || null;
        } catch(err) {
            macros_val.DID = null;
            macros_val.AB = null;
        }
    }

    if(macros_val.DESC && props.feeds && props.feeds.videoAds && props.feeds.videoAds.advanced && props.feeds.videoAds.advanced.descriptionLength > 0){
        macros_val.DESC = macros_val.DESC.substring(0, props.feeds.videoAds.advanced.descriptionLength)
    }
    return macros_val;
}

export function focusVideoItemWhichWerePlayed(contentIdentifier, progressItem) {
    if(isSmartTv()) return;
    let contentDiv = document.getElementsByClassName("content-" + contentIdentifier)[0];
    let focusItem = document.getElementsByClassName("item-" + progressItem)[0]
    if (contentDiv.getElementsByClassName("swiper-slide slideHover")[0]) {
        contentDiv.getElementsByClassName("swiper-slide slideHover")[0].classList.remove("slideHover")
    }
    focusItem && focusItem.classList.add("slideHover");
}

/*
    this function takes row_element as input, 
    if no row_element is passed it will apply focus on all the swiper
    if passed , then it will be applied only on the specified row_element
*/
export function tvFocus(regularLayout, content_identifier = null) {
    // if row element is passed for swiper-wrapper
    // will not be running this function for light sliders
    if (!isSmartTv() || config.use_light_sliders) return;

    let row_element = null;
    if(content_identifier) {
        row_element = document.querySelector(`.content-${content_identifier} .swiper-wrapper`);
    }

    const wrapper_array = document.querySelectorAll(".swiper-wrapper");
    // const regularLayout = this.props.layout;
    wrapper_array.forEach((el, i) => {
        if (!el) return;
        if (row_element && row_element !== el) return;

        if ((regularLayout === 'spotlight' || regularLayout === 'fullscreen') && (i == 1)) return;

        let tot_els = el.querySelectorAll('.focusable');
        if (!tot_els.length) return;
        if (regularLayout === 'detail' && i != 0) {
        } else {
            // if(tot_els[tot_els.length - 1].tagName === 'IMG') {
            //   tot_els[tot_els.length - 2].setAttribute('data-sn-right','');
            // } else {
            //   tot_els[tot_els.length - 1].setAttribute('data-sn-right','');
            // }
            // if(tot_els[0]) tot_els[0].setAttribute('data-sn-left','')
        }

        if(content_identifier) {
            let prev = document.querySelector(`.t${content_identifier}-prevEl`);
            let next = document.querySelector(`.t${content_identifier}-nextEl`);

            if(!prev.getAttribute('data-row')) {
                prev.setAttribute('data-row', i);

                prev.addEventListener('sn:focused', function(data) {
                    onElementFocussed(data, i, 0, regularLayout, this);
                });    
            }

            if(!next.getAttribute('data-row')) {
                next.setAttribute('data-row', i);

                next.addEventListener('sn:focused', function(data) {
                    onElementFocussed(data, i, 0, regularLayout, this);
                });
            }
        }

        tot_els.forEach((el, j) => {
            if (el.getAttribute('data-row')) return;

            el.setAttribute('data-row', i);
            el.setAttribute('data-col', j);

            el.addEventListener('sn:focused', function(data) {
                onElementFocussed(data, i, j, regularLayout, this);
            });
        });
    });
}

/*
    this function handles the functionality when an
    element is been focussed by JSSpatialNavigation
*/
function onElementFocussed(data, i, j, regularLayout, _self) {
    const direction = data.detail.direction;
    let last_row = data.detail.previousElement && data.detail.previousElement.getAttribute('data-row');
    let row = data.srcElement && data.srcElement.getAttribute('data-row');
    let next_row = direction === 'up' ? parseInt(last_row) - 1 : parseInt(last_row) + 1;

    if (data.srcElement.id.includes('banr_') || Math.abs(last_row - row) == 0) {
        // do nothing
    } else if ((Math.abs(last_row - row) > 1 && parseInt(last_row) > 2) || (i != 0 && (direction === 'up' || direction === 'down'))) {
        data.preventDefault();

        if ((regularLayout === 'spotlight' || regularLayout === 'fullscreen') && next_row == 1) {
            next_row = direction === 'up' ? next_row - 1 : next_row + 1;
        }

        if (document.querySelectorAll('.swiper-wrapper')[next_row]) {
            document.querySelectorAll('.swiper-wrapper')[next_row].querySelector('.focusable').parentElement.parentElement.parentElement.swiper.slideTo(0);
            JsSpatialNavigation.focus(document.querySelectorAll('.swiper-wrapper')[next_row].querySelector('.focusable'), false);

            //If focus on banner do not scrollinto view.
            if (!checkIfFocusInsideBanner()) {
                elementScrollIntoView(document.querySelectorAll('.swiper-wrapper')[next_row].querySelector('.focusable'), { behavior: 'smooth', block: 'center', inline: 'center' });
            }
            return;
        }
    } else if (i == 0 && document.getElementById('nav-bar') && !document.getElementById('nav-bar').contains(data.detail.previousElement)) {
        // on navbar and focus coming from outside navbar
        data.preventDefault(); // cancel current event
        if (document.querySelector('#nav-bar .active')) {
            document.querySelector('#nav-bar .active').parentElement.focus();
        }
        window.scrollTo(0, 0);
        return;
    }
    let inline = 'center';
    if (regularLayout === 'fullscreen' || regularLayout === 'spotlight' || window.location.pathname.includes('settings')) {
        inline = 'end';
    }
    //If focus on banner do not scrollinto view.
    if (!checkIfFocusInsideBanner())
        elementScrollIntoView(_self, { behavior: 'smooth', block: 'center', inline: inline });
}

export function checkIfMenuOrItemDeeplink(actionUrl) {
    if (actionUrl.includes('item') || actionUrl.includes('menu')){
        return true
    }
    return false
}
export function formatItemDuration(duration) {
    if(duration === null || duration === undefined) return '';
    let hr = 0;
    let min = 0;
    let sec = 0;
    if(typeof duration === "string" && duration.includes(":")) {
        const splitedDuration = duration.split(":");
        hr = isNaN(splitedDuration[0]) ? 0 : parseInt(splitedDuration[0]);
        min = isNaN(splitedDuration[1]) ? 0 : parseInt(splitedDuration[1]);
        sec = isNaN(splitedDuration[2]) ? 0 : parseInt(splitedDuration[2]);
    } else if(!isNaN(duration)) {
        let intDuration = parseInt(duration);
        hr = Math.floor(intDuration / 3600);
        intDuration = intDuration % 3600;

        min = Math.floor(intDuration / 60);
        sec = intDuration % 60;
    }
    const { kISHr, kISMin, kISSec} = getAllConstants();

    if(hr > 0) {
        return `${hr} ${kISHr} ${min} ${kISMin}`;
    }
    if(min > 0) {
        return `${min} ${kISMin}`;
    }
    return `${sec} ${kISSec}`;
}

export function getSecondsFromDuration(duration, normalise) {
    if(duration === null || duration === undefined) return '';
    let hr = 0;
    let min = 0;
    let sec = 0;
    if(typeof duration === "string" && duration.includes(":")) {
        const splitedDuration = duration.split(":");
        hr = isNaN(splitedDuration[0]) ? 0 : parseInt(splitedDuration[0]);
        min = isNaN(splitedDuration[1]) ? 0 : parseInt(splitedDuration[1]);
        sec = isNaN(splitedDuration[2]) ? 0 : parseInt(splitedDuration[2]);

        if(normalise) {//normalise if got second arg
            hr = hr >= 24 ? hr%24 : hr;
        }
        return hr*3600 + min*60 + sec;
    } else {
        let intDuration = parseInt(duration);
        return intDuration;
    }
}

export function getItemCuePoints(points) {
    if(!points) return [];
    let cuePoints = [];
    let arr = points.split(",");
    arr.forEach(point => {
        const dur = getSecondsFromDuration(point);
        if(!isNaN(dur)) {
            cuePoints.push(dur);
        }
    })
    return cuePoints;
}

export function setGeoFenceInLocal(allowGeofence, country) {
    const dataObj = {
        geoFenceCountry: country,
        isGeoFenceOn: allowGeofence,
        isGeoPermissionSet: true
    }
    if(checkCorrectPlatform([PLATFORM_SAMSUNG]) && window.tizen) {
        window.tizen.keymanager.saveData('geoDetails', JSON.stringify(dataObj), null, (err) => {console.log(err)});
    } else if(checkCorrectPlatform([PLATFORM_XBOX]) && window.Windows != undefined) {
        let localSettings = window.Windows.Storage.ApplicationData.current.localSettings;
        localSettings.values['geoDetails'] = JSON.stringify(dataObj);
    } else {
        localStorage.setItem('geoDetails', JSON.stringify(dataObj));
    }
}

export function getGeoFromLocal() {
    let geoObj;
    if (checkCorrectPlatform([PLATFORM_SAMSUNG]) && window.tizen) {
        try {
            geoObj = window.tizen.keymanager.getData({ 'name': 'geoDetails' });
        } catch (error) {
            console.log(error);   
        }
        if(typeof geoObj === 'string') geoObj = JSON.parse(geoObj);
    } else if(checkCorrectPlatform([PLATFORM_XBOX]) && window.Windows != undefined) {
        try {
            let localSettings = window.Windows.Storage.ApplicationData.current.localSettings;
            geoObj = localSettings.values['geoDetails'];
            if(typeof geoObj === 'string') geoObj = JSON.parse(geoObj);
        } catch (err) {
            console.log('Error saving to storage in windows')
        }
    } else {
        geoObj = localStorage.getItem('geoDetails');
        if(typeof geoObj === 'string') geoObj = JSON.parse(geoObj);
    }
    return geoObj;
}

export function isMenuBlockGeofence(isGeofencingOn, content, userCountry) {
    const enableGeofencing = config.isAppTvodType ? true : isGeofencingOn;
    if (enableGeofencing && content && content.blockCountries && content.blockCountries.length && content.blockCountries.includes(userCountry))
      return true;
    return false;
}

export function getRedirectUrlAfterGeofence(feeds, checkIfGeoFence) {
    if (checkIfSectionLevelContent()) {
        return getDefaultSectionPath(feeds, checkIfGeoFence)
    } else {
        const url = window.location.pathname.split('/').slice(3).join('/');
        let obj = backButtonObj(feeds, url);
        return obj.backPath;
    }
}

export function isAppRunningOnLowEndPlatform() {
    switch(getCurrentPlatform()) {
        case 'vizio':
        case 'zeasn':
            return true;
        default:
            return false;
    }
}

export function removeTransition(){
    if(isAppRunningOnLowEndPlatform()) {
        return 'remove-transition';
    }
    return '';
}

function getDefaultSectionPath(feeds, checkIfGeoFence) {
    const sectionDetails = findDefaultSection(feeds);
    let pathLocation = sectionDetails[1] && sectionDetails[1][0];
    if (checkIfGeoFence && checkIfGeoFence(sectionDetails[0])) {
        pathLocation = findUnblockGeoSection(feeds, checkIfGeoFence);
    }
    return pathLocation;
}

function findUnblockGeoSection(feeds, checkIfGeoFence) {
    let sections = feeds.sections;
    for(let i=0; i<sections.length; i++) {
        let section = sections[i];
        if (checkIfGeoFence && !checkIfGeoFence(section)) {
            return `apps/${config.app_id}/${section.identifier}`;
        }
    }
    return `apps/${config.app_id}/settings`;
}

export function getRandomString(length_) {
    const length = length_ || 20;
    var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var result = '';
    for ( var i = 0; i < length; i++ ) {
        result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
}

export function getVttCaption(item) {
    const closedCaptions = item && findVttFromArray(item.closedCaptions);
    const fallbackCCs = item && findVttFromArray(item.closedCaptionsFallback);
    return closedCaptions || fallbackCCs;
}


export function GetImageUrl(url, sizes = []) {
    try {
        if(!url || sizes.length === 0) return url
        let dimen = "";
        dimen = `-${Math.min(...sizes)}`
        if(!dimen) return url;
        
        var newUrl = url.split(".")
        newUrl[newUrl.length - 2] = newUrl[newUrl.length - 2] + dimen;
        newUrl = newUrl.join(".")
        return newUrl
    } catch {
        return url;
    }
}

function findVttFromArray(arr) {
    if (!arr || !arr.length) return null;
    const caption = arr.find(caption => {
        if(!caption.url) return false
        let url = caption.url.split(".");
        let extension = url[url.length - 1].split('?')[0];
        if (extension.toLowerCase() === 'vtt') return true;
        return false;
    })
    return (caption && caption.url);
}

export function getDateWithoutHyphen(date) {
    if(!date) return null;
    const nDate = date.replace(/-/g, "/");
    return new Date(nDate);
}

export function getDateTimeForTvodInterstitial(dateS) {
    if(!dateS) return '';
    let date = new Date(dateS).getDate();
    if(date <= 9) date = `0${date}`;
    let month = new Date(dateS).getMonth() + 1;
    if(month <= 9) month = `0${month}`;
    let year = new Date(dateS).getFullYear();
    const time = new Date(dateS).toTimeString().substr(0, 8)
    return `${year}-${month}-${date} ${time}`
}

export function getPaymentFailureUrl(pathName, modal) {
    if(config.isAppTvodType) {
        const {prev_purchase, sub_id} = modal;
        return `/payment/confirmation?type=failure&section=${pathName}&prev_purchase=${prev_purchase}&sub_id=${sub_id}&hidesponsor=true`
    } else {
        return `/payment/confirmation?type=failure&section=${pathName}`
    }
}
export function toHHMMSS(sec_num) {
    // let sec_num = parseInt(sec_num, 10);
    let hours = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - hours * 3600) / 60);
    let seconds = sec_num - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }

    return hours + ":" + minutes + ":" + seconds;
};

/**
 * 
 * @param {string} current_country  // user's current country
 * @param {Object} country_groups   // conntry groups id
 * @param {string} item_country_group_id  // country group id, where feed is available
 * 
 * @return {boolean}
 * isFeedAvailable method returns true
 * if feed is available in the country
 * otherwise it returns false 
 */
export function isFeedAvailable(current_country, country_groups, item_country_group_id) {
    try {
        if(!current_country || !country_groups || !item_country_group_id) return true;

        if(country_groups[item_country_group_id] && country_groups[item_country_group_id].includes(current_country)) {
            return true;
        }
    
        return false;
    } catch(err) {
        console.log("unable to check feed availability", err);
    }
}

export function videoType(url){
    if(!url) return "";

    url = url.split('.')
    switch(url[url.length - 1].split('?')[0]) {
        case "m3u8":
            return "application/x-mpegURL";
        case "mp4":
            return "video/mp4";
        case "webm":
            return "video/webm";
        default:
            return "";
    }
}

export function readableTimeFormat(sec_num) {
    if(!sec_num) return '0 seconds';

    let hours = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - hours * 3600) / 60);
    let seconds = sec_num - hours * 3600 - minutes * 60;

    let time_to_read = "";

    if(hours) {
        time_to_read += `${hours} hours `;
    }
    time_to_read += `${minutes} minutes `;
    time_to_read += `${seconds} seconds`;

    return time_to_read;
}

// fullscreen button for player
export function fullscreen() {
  let button = document.getElementsByClassName('vjs-fullscreen-control-button')[0];
  let image = button && button.querySelector('img');

  if (!button || !image) {
    button = document.createElement("button");
  
    button.classList.add("vjs-control");
    button.classList.add('vjs-button');
    button.classList.add('vjs-fullscreen-control-button');
    button.style.cursor = 'pointer';
    
    image = document.createElement("img");
    image.src = FullscreenExpand;
    button.appendChild(image);
  }

  const doc = window.document;
  const docEl = doc.body;

  const requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
  const cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;

  const isNormalScreen = () => {
      return !doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement;
  }
    
  const expand = () => {
    requestFullScreen.call(docEl);
    image.src = FullscreenContract;
  }
  const contract = () => {
    cancelFullScreen.call(doc);
    image.src = FullscreenExpand;
  }

  button.onclick = () => {
    isNormalScreen() ? expand() : contract()
  }

  return { button, expand, contract, isNormalScreen };
}

export function checkIfStringExistInUrl(text) {
    return window.location.pathname.split("/").includes(text);
}

/************* PLAYBACK URL SUPPORT **************/

export function appendAdditionalTokens(modifiedTokens, ifaTokens, videoItem) {
    return {
        ...modifiedTokens,
        ...ifaTokens,
        get ["player_params.app_bundle"]() { return this.AB; }, //app_bundle
        get ["player_params.app_name"]() { return this.AN; }, //app_name
        get ["player_params.app_domain"]() { return this.AD; }, //app_domain
        get ["player_params.app_store_url"]() { return this.ASU; }, //app_store_url
        get ["player_params.did_type"]() { return this.IFA_TYPE; }, //did_type
        get ["avail.random"]() { return this.CB; }, //Random number to prevent caching
        get ["player_params.ua"]() { return this.UA; }, //User-agent string
        get ["player_params.player_height"]() { return this.H; }, //player_height
        get ["player_params.player_width"]() { return this.W; }, //player_width
        get ["player_params.is_lat"]() { return this.LMT; }, //is_lat
        get ["player_params.site_id"]() { return config.kSiteId; }, //-site_id
        get ["player_params.site_name"]() { return config.kSiteName; }, //-site_name
        get ["player_params.page_url"]() { return this.PURL; }, //-site_page
        get ["player_params.us_privacy"]() { return this.USPRIVACY; }, //us_privacy
        get ["player_params.genre"]() { return this.CONTENT_GENRE; }, //content_genre
        get ["player_params.channel"]() { return this.CONTENT_CHANNEL; }, //content_channel
        get ["player_params.rating"]() { return this.CONTENT_RATING; }, //content_rating
        get ["player_params.title"]() { return this.CTITLE; }, //content_title
        get ["player_params.content_id"]() { return videoItem.cmsData?.video_id || "" }, //content_id
        get ["player_params.did"]() { return getUniqueSessionId(`player-did-${config.app_id}`) }, //content_id
    }
}
export function modifiedPlaybackUrl(props, contentUrl, videoItem, player) {
    let tokens = props && props.feeds.playbackMacros && props.feeds.playbackMacros.token || "";
    let ssaiTokens = props.feeds?.ssaiVideoAds?.playbackMacros || "";
    const isSSaiMediaTailor = props.feeds?.ssaiVideoAds?.playbackMacroType === "mediatailor"
    if(!tokens && !ssaiTokens) return contentUrl;
    let videoUrl = contentUrl;
    try {
        let modifiedTokens = getMacrosVal(props, videoItem, player)
        
        for(let key in modifiedTokens) tokens = tokens.replace(`[[${key}]]`, `${encodeURIComponent(modifiedTokens[key] || '')}`);
        if(isSSaiMediaTailor){
            const ifaTokens = ifaMacrosFun()
            const additionalTokens = appendAdditionalTokens(modifiedTokens, ifaTokens, videoItem)
            for(let key in additionalTokens) {
                ssaiTokens = ssaiTokens.replace(`[${key}]`, `${encodeURIComponent(additionalTokens[key])}`)
            };
        }
        tokens = ifaMacrosAddition(tokens);
        
        if(tokens){
            if(videoUrl.includes("?")) videoUrl = videoUrl + `${tokens}`;
            else videoUrl = videoUrl + `?${tokens}`;
        }
        if(ssaiTokens){
            if(videoUrl.includes("?")) videoUrl = videoUrl + `${ssaiTokens}`;
            else videoUrl = videoUrl + `?${ssaiTokens}`;
        }
        const pattern = /\[\[.*?\]\]|\[.*?\]|\bnull\b/g;
        videoUrl = videoUrl.replace(pattern, '')
        return videoUrl;
    }
    catch(err) {
        return contentUrl;
    }
}

export function constructDateFormat() {
    let d = new Date().getDate();
    if(d < 10) d = `0${d}`;
    let m = new Date().getMonth();
    if(m < 10) m = `0${m}`;
    const y = new Date().getFullYear();
    return `${d}/${m}/${y}`;
}

export function setPlaylistVideoCounter(type, sectionId, itemIndex) {
    if (!config.isAppTvodType) return;
    if(!type || !sectionId) return;

    const key = `${config.app_id}-stream-counter-${type}`;
    let storedData = getLocalStorage(key);
    if(!storedData) {
        storedData = {};
    }
    storedData[sectionId] = itemIndex;
    setLocalStorage(key, storedData);
}

export function getPlaylistVideoCounter(type, sectionId) {
    if (!config.isAppTvodType) return 0;
    if(!type || !sectionId) return 0;

    try {
        const key = `${config.app_id}-stream-counter-${type}`;
        let storedData = getLocalStorage(key);
        if(!storedData || !storedData[sectionId]) return 0;
        return storedData[sectionId];
    } catch {
        return 0;
    }
}

export function handleUnmutedPlayback(promise, player, elId, userEngaged, showPlayButton) {
    promise.then(
        //video Autoplayed With Audio, Success
    ).catch(error => {
        if (!userEngaged) {
            console.log('Error Occured in AutoPlaying, Retrying Muted Playback...', error)
            var newVideo = document.getElementById(elId)
            if (!player || !newVideo) return;
            newVideo.muted = true;
            //Video AutoPlay Failed, Retrying Muted Video Play.
            player.play().catch(e => {
                //Error Occured in trying Muted Playback, Going back to interstital Screen.
                console.log("Error Occured in Muted Autoplay, Cancelling the Autoplay")
                document.getElementById("overlay_video_back_btn") && document.getElementById("overlay_video_back_btn").click();
            })
        } else if(error.name === 'NotAllowedError'){
            //If Playback failed previously due to browser policy then showing play button instead of Interstital screen
            showPlayButton()
        }
    })
}
