import React from 'react';
import { connect } from "react-redux";
import Cta from "../components/Cta";
import { GRID_THEME, HERO_SLIDER_THEME, SERIES_THEME } from '../constants/common';
import config from '../constants/config';
import { elemOrientation, getFontStyleFromFeed, getSwiperParamsByTheme, getThumnailViewClass, isContentPortrait } from "../helperfunctions";
import { checkIfEmptyMazIdFeedsInContents, getTabIndex, focusVideoItemWhichWerePlayed, isSmartTv } from '../helperfunctions/common';
import { convertSwiperParamsToSlick, makeContentFocusableSection, removeContentFromFocusableSection } from '../helperfunctions/ctvHelper';
import CustomSlider from './CustomSlider';

class GridContent extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      currentView: ''
    }
    this.swiper = null;
    this.swiperParams = null;
    this.slick_params = null;
    this.is_in_focus = false;
    this.generateSwiperParam();
  }

  componentDidMount() {
    const swiperCont = document.querySelector(`#standard-grid.content-${this.props.content.identifier} .swiper-container`);
    this.swiper = swiperCont && swiperCont.swiper;

    if(isSmartTv() && config.use_light_sliders) {
      makeContentFocusableSection({
        disableDefaultSection: true,
        content_identifier: this.props.content.identifier
      }).then(val => {
        this.sn_id = val;
      }).catch(err => console.log("error",err))
    }
  }

  componentWillUnmount() {
    if(isSmartTv() && config.use_light_sliders) {
      removeContentFromFocusableSection({
        enableDefaultSection: true,
        content_identifier: this.props.content.identifier,
        id: this.sn_id
      });
    }
  }

  componentDidUpdate(){
    if(Object.keys(this.props.modal_progress).length > 0 && this.props.modal_progress.head === this.props.content.identifier){
      // if (this.swiper) {this.swiper.slideTo(this.props.modal_progress.index)};
      focusVideoItemWhichWerePlayed(this.props.content.identifier, this.props.modal_progress.item);
    }
  }

  generateSwiperParam = () => {
    const _self = this;
    const content = (this.props.layout === HERO_SLIDER_THEME) ? {...this.props.content, index: this.props.index} : this.props.content;
    const theme = [HERO_SLIDER_THEME, SERIES_THEME].includes(this.props.layout) ? this.props.layout : GRID_THEME;
    _self.isPortrait = isContentPortrait(this.props.content, this.props?.currentSection);
    this.swiperParams = getSwiperParamsByTheme(_self, content, theme);
  }

  nextSlide = () => {
    const _self = this;
    _self.swiper && _self.swiper.slideNext();
  }

  prevSlide = () => {
    const _self = this;
    _self.swiper && _self.swiper.slidePrev();
  }

  isInViewport(el) {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }


  render() {
    const _self = this;
    const { layout, index, isTvodExtras, content, currentSection, nosaveicon } = this.props;
    const heroClass = (layout === HERO_SLIDER_THEME) ? "mt-50 " : "";
    const fontStyle = getFontStyleFromFeed(this.props.feeds);
    let wrapSavedSectionItems = this.props.feeds.extras && this.props.feeds.extras.wrapSavedSectionItems;
    let smallMarginLeft = "";
    // let params = getSwiperParamsByTheme(_self, this.props.content, GRID_THEME);
    // _self.generateSwiperParam()
    const thumbClass = isTvodExtras ? "" : getThumnailViewClass(content, currentSection);
    const noSaveClass = nosaveicon ? "nothumbBox ": "";
    const gridClassName = `${heroClass}${noSaveClass}slider-box grid-view ${thumbClass} content-${content.identifier}`;

    if(isSmartTv() && config.use_light_sliders) {
      this.slick_params = convertSwiperParamsToSlick(this.swiperParams, this);
      if(wrapSavedSectionItems) {
        this.slick_params.lazyLoad = "progressive";
        if(this.slick_params && this.slick_params.slidesToShow >= 4) smallMarginLeft = "ml";
      }
    }

    if(checkIfEmptyMazIdFeedsInContents(this.props.content.contents))
      return null;

      return (
        <div id="standard-grid" className={gridClassName + " " + smallMarginLeft}>
        {(!isTvodExtras && !(layout === HERO_SLIDER_THEME && index === 0) && layout !== SERIES_THEME && !this.props.showingSavedSection && this.props.title !== "saved_items" && !this.props.content.locked) && <h2 id={`content-head-${this.props.content.identifier}`} style={fontStyle.primaryFont} dir={elemOrientation(this.props.feeds)}>{this.props.content.feedTitle || this.props.title}</h2>}
        {!(layout === HERO_SLIDER_THEME && index === 0) && layout !== SERIES_THEME && this.props.content.locked && <Cta layout={"standard"}time={Date.now()} content={this.props.content} feeds={this.props.feeds} />}
        {
          isSmartTv() && config.enableCtvVirtualSlides && (
          <>
            <div data-sn-right="" className={`swiper-button-next t${this.props.content.identifier}-nextEl focusable`} tabIndex={getTabIndex()} onClick={this.nextSlide}></div>
            <div data-sn-left="" className={`swiper-button-prev t${this.props.content.identifier}-prevEl focusable`} tabIndex={getTabIndex()} onClick={this.prevSlide}></div>
          </>
          )
        }
        <CustomSlider
          params = {this.slick_params || this.swiperParams}
          content_identifier = {this.props.content.identifier}
        >
          {this.props.contents}
        </CustomSlider>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  modal_progress: state.modal.progress,
})

export default connect(mapStateToProps, null)(GridContent);
