import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import { GET_USER_SUBSCRIPTION_RESOLVE, GET_USER_SUBSCRIPTION_REJECT, GET_USER_SUBSCRIPTION_PENDING,
  USER_METERING_RESOLVE, USER_METERING_REJECT, USER_METERING_PENDING, USER_ENGAGED, USER_CONCENT,
  GET_WEBSITE_LOGIN_PENDING, GET_WEBSITE_LOGIN_REJCTED, GET_WEBSITE_LOGIN_RESOLVED, POLLING_START, POLLING_STOP,
  GET_FB_LOGIN_CODE_PENDING, GET_FB_LOGIN_CODE_REJCTED, GET_FB_LOGIN_CODE_RESOLVED, FB_POLLING_START, FB_POLLING_STOP,
  GET_FB_USER_DATA_PENDING, GET_FB_USER_DATA_REJECTED, GET_FB_USER_DATA_RESOLVED, GET_USER_IP_PENDING, GET_USER_IP_RESOLVED, GET_USER_IP_REJECTED,
  GET_UUID_PENDING, GET_UUID_RESOLVED, GET_UUID_REJECTED, GET_USER_LOCATION_PENDING, GET_USER_LOCATION_RESOLVED, GET_USER_LOCATION_REJECTED,
  GET_DEVICE_MODEL_PENDING, GET_DEVICE_MODEL_RESOLVED, GET_DEVICE_MODEL_REJECTED, LOGIN_MAZ_TV, LOGOUT_MAZ_TV, LOCATION_GEO_PENDING, LOCATION_GEO_RESOLVED, LOCATION_GEO_REJECTED, GET_VALID_VOUCHER_CODE, CHECKING_VALID_VOUCHER_CODE, CLEAR_TVOD_STREAMING_DATA,
  GET_USER_ONLINE_RESOLVED, GET_USER_ONLINE_REJECTED, GET_SIMULATED_SUBSCRIPTION_TVOD, CLEAR_TVOD_SUBSCRIPTION_DATA, RESET_PASSWORD_PENDING, RESET_PASSWORD_REJECTED, RESET_PASSWORD_RESOLVED, SET_ONBOARDING_COMPLETED, VALIDATE_DEVICE_OTP_PENDING, VALIDATE_DEVICE_OTP_RESOLVE, VALIDATE_DEVICE_OTP_REJECT, DEVICE_LOGIN_PENDING, DEVICE_LOGIN_RESOLVE, DEVICE_LOGIN_REJECT, CLEAR_DEVICE_LOGIN, SET_AUTO_PLAY, START_ENTITLEMENT_POLLING, STOP_ENTITLEMENT_POLLING, GET_USER_PROFILE_DATA
} from "../constants/actionTypes";
import { getMazCoreEnvUrl, getTvodApiHeaders, getTvodAPIKey } from '../constants/initializer';
import { isUserSubscribed } from '../helperfunctions/is_available';
import * as APIS from "../constants/api";
import config from "../constants/config";
import { openModal } from "../actions/modalActions"
import * as analytics  from '../constants/analytics';
import { getMeteringTypes,PLATFORM_SAMSUNG,PLATFORM_LG, PLATFORM_WEB, PLATFORM_VIZIO, PLATFORM_WEB_PREVIEW, API_STATE, FEED_POLL_INTERVAL, PLATFORM_XBOX, getSpotXId, PLATFORM_ZEASN } from '../constants/common';
import { checkAllRememberSpot } from "./rememberSpotActions";
import { clearDynamicList, getSavedItems, getTvodSavedItems, getUSerFeedHistoryOrProgress } from "./itemActions";
import { isSmartTv, checkCorrectPlatform, setGeoFenceInLocal, getDateWithoutHyphen, getCurrentPlatformTvod, addTvodAPIRegionalisation } from "../helperfunctions/common";
import { findDefaultSection } from "../helperfunctions";
import { resetSubscriberEvent, sendSubscribeEvent } from "../helperfunctions/mazAnalytics";
import configuration from "../constants/config";
import { getTvodStreams } from "./streamActions";
import fakeApi from "../helperfunctions/fakeApi";
import { addUserCookie, removeUserCookie, setLocalStorage } from "../helperfunctions/storage";
import { getTvodSubscriptionDetails } from "./subscriptionAction";
import { getParentalLockStatus, removeParentalLockFromLocal } from "./parentalLockAction";

let ENTITLEMENT_POLL = null;

function onSuccessfulLogin(dispatch, response, app_id, user_action) {
  const apiParams = {
    auth_token: response.data.auth_token,
    user_id: response.data.user_id,
    combo_app_id: config.app_id
  }
  if (response.data.success) {
    addUserCookie(response.data);
    dispatch(getUserSubscriptionDetails(apiParams))
    dispatch(getUserMeteringDetails(apiParams))
    dispatch({type: "RECEIVE_USER", payload: response.data, app_id: app_id, logged_in: response.data.success, error: false})
    dispatch(checkAllRememberSpot(apiParams))
    dispatch(getSavedItems(apiParams.auth_token, "100", "0", apiParams.user_id))
    dispatch(getUSerFeedHistoryOrProgress({...apiParams, filterType: 'history'}));
    dispatch(getUSerFeedHistoryOrProgress({...apiParams, filterType: 'progress'}));

  } else {
    throw {response};
  }
}

function  onSuccessfulTvodLogin(dispatch, response, app_id) {
  if (response.data.token) {
    addUserCookie(response.data);
    dispatch(getTvodStreams());
    dispatch({type: "RECEIVE_USER", payload: response.data, app_id: app_id, logged_in: true, error: false})
    dispatch(getTvodSavedItems());
    dispatch(getUserSubscriptionDetails())
    dispatch(getParentalLockStatus());
    dispatch(clearDynamicList());
    dispatch(getUserProfile(response.data))
  } else {
    throw {response};
  }
}

function setRegistrationAnalytics(type, user_action){
  var eventLabel = 'Email';
  if(type === 'facebook'){
    eventLabel = 'Facebook';
  }
  if(user_action && user_action === "Login"){
    analytics.setEvent('Registration', 'Log In', eventLabel)
  }else{
    analytics.setEvent('Registration', 'Register', eventLabel)
  }
}

export function userActions(email, password, app_id, user_action, gdprParams={}) {
  let reqParams = {
    app_user: {
      email: email,
      password: password,
      app_id: app_id
    },
  }
  const isAppTvodType = configuration.isAppTvodType;
  if(!isAppTvodType) {
    reqParams.platform = config.platform;
    reqParams.app_user = {
      ...reqParams.app_user,
      ...gdprParams,
        app_type: 'ComboApp'
    }
  }

  let url = (user_action === "Login") ? APIS.USER_SIGN_API : APIS.USER_SIGNUP_API
  return function(dispatch, getState) {
    if(isAppTvodType) {
      reqParams.platform = getCurrentPlatformTvod();
      reqParams.key = getTvodAPIKey();
      reqParams = addTvodAPIRegionalisation(reqParams, getState);
    }
    dispatch({type: 'RECEIVE_USER_EMAIL_PENDING'})
    axios.post(url, reqParams, getTvodApiHeaders(true))
      .then((response) => {
        isAppTvodType ? onSuccessfulTvodLogin(dispatch, response, app_id) : onSuccessfulLogin(dispatch, response, app_id, user_action);
        setRegistrationAnalytics('email', user_action)
      })
      .catch((error) => {
        if(isAppTvodType && error.response && error.response.data && error.response.data.errors && Object.keys(error.response.data.errors)) {
          let key = Object.keys(error.response.data.errors)[0]
          let msg = `${key} ${error.response.data.errors[key]}`
          error.response.data.error = msg;
        }
        dispatch({type: "RECEIVE_USER_ERROR", payload: (error.response && error.response.data), app_id: app_id, logged_in: false, error: true})
      })
  }
}

export function fbUserActions(email, fb_oauth_token, fb_display_name, app_id, gdprParams={}) {
    const apiParams = {
      app_user: {
        ...gdprParams,
        email,
        fb_oauth_token,
        app_id,
        display_name: fb_display_name,
        app_type: 'ComboApp'
      },
      platform: config.platform
    }

    const url = APIS.FB_LOGIN_SIGNUP;
    return function(dispatch) {
        dispatch({type: 'RECEIVE_USER_PENDING'})
        axios.post(url, apiParams)
            .then((response) => {
                onSuccessfulLogin(dispatch, response, app_id);
                setRegistrationAnalytics('facebook')
            })
            .catch(error => {
              if (!error.status) {
                const err = {
                  error: 'There was an issue with your request. Please try again.',
                  success: false
                }
                dispatch({type: "RECEIVE_USER_ERROR", payload: err, app_id: app_id, logged_in: false, error: true})
              }
              else {
                dispatch({type: "RECEIVE_USER_ERROR", payload: (error.response && error.response.data), app_id: app_id, logged_in: false, error: true})
              }
            })
    }
}

export function forgotPassword(email, app_id) {
    let fp_req_data = {app_user:{email:email,app_id:app_id,app_type:"ComboApp"}}
    const url = APIS.USER_FORGOT_PASSWORD_API;
    return function(dispatch, getState) {
        if(configuration.isAppTvodType) {
          fp_req_data.platform = getCurrentPlatformTvod();
          fp_req_data.key = getTvodAPIKey();
          fp_req_data = addTvodAPIRegionalisation(fp_req_data, getState);
        }
        axios.post(url, fp_req_data, getTvodApiHeaders(true))
            .then((response) => {
                let success = response.data.success;
                if (response.status >= 201 && response.status <= 299) success=true;
                dispatch({type: "FORGOT_PASSWORD", payload: response.data, app_id: app_id, logged_in: false, error: !success})
            })
            .catch(err => {
              dispatch({type: "FORGOT_PASSWORD", payload: {success: false}, app_id: app_id, logged_in: false, error: true})
            })
    }
}

export function logoutUserActions(app_id) {
    analytics.setEvent('Registration', 'Log Out', '')
    return function(dispatch, getState) {
      dispatch({type: "LOGOUT_USER", app_id: app_id, logged_in: false, error: false})
      dispatch({type: "UPDATE_REMEMBER_SPOT_FLAG"});
      try {
        if(checkCorrectPlatform([PLATFORM_SAMSUNG]) && window.tizen) {
          window.tizen.keymanager.removeData({'name': 'metering_details'});
        } else if(checkCorrectPlatform([PLATFORM_XBOX]) && window.Windows != undefined) {
          let localSettings = window.Windows.Storage.ApplicationData.current.localSettings;
          localSettings.values.remove('metering_details');
        } else {
          let localData = JSON.parse(localStorage.getItem('metering_details'))
          if(localData) {
            localStorage.removeItem("metering_details");
          }
        }
      } catch(err) {
        console.log("unable to delete data!!");
      }
      if(configuration.isAppTvodType) {
        axios.post(APIS.USER_LOGOUT_API, addTvodAPIRegionalisation({}, getState), getTvodApiHeaders())
        dispatch({type: CLEAR_TVOD_STREAMING_DATA})
        dispatch({type: CLEAR_TVOD_SUBSCRIPTION_DATA})
        dispatch(clearDynamicList());
        dispatch(removeParentalLockFromLocal())
      }
      // remove cookie
      removeUserCookie();
      resetSubscriberEvent();
    }
}

export function setUserCookie(user, app_id){
    return function(dispatch) {
        dispatch({type: "COOKIE_USER", payload: user, app_id: app_id, logged_in: true, error: false})
    }
}

export function simulatedSubPurchase(){
  return function(dispatch) {
    dispatch({type: "SUBSCRIPTION_PURCHASE"})
  }
}

export function simulatedItemPurchase(item_id){
  return function(dispatch) {
    dispatch({type: "ITEM_PURCHASE", item_id: item_id})
  }
}


export function removeSimulatedSubPurchase(){
  return function(dispatch) {
    dispatch({type: "REMOVE_SUBSCRIPTION_PURCHASE"})
  }
}

export function meteringDetails(options){
  return function(dispatch) {
    dispatch({type: "METERING_CONSUMED", section_id: options.section_id, seconds: options.seconds,item_id: options.item_id, mtype:options.mtype})
  }
}

export function getMeteringDisplay(total_seconds,type="non_cta"){
  if (total_seconds < 0) return `00:00`;
  let seconds = total_seconds % 60
  let minutes = (total_seconds-seconds)/60
  seconds = seconds > 9 ? "" + seconds: "0" + seconds
  if (type == "cta" && seconds==0){
    return `${minutes}`
  }
  return `${minutes}:${seconds}`
}

function startGetEntitlementsPolling(dispatch) {
  let intervalTime = FEED_POLL_INTERVAL.staging;
  if (config.env === 'production') {
    intervalTime = FEED_POLL_INTERVAL.production;
  }
  ENTITLEMENT_POLL = setInterval(() => {
    dispatch(getUserSubscriptionDetails(null, true))
  }, intervalTime)
}

export function getUserSubscriptionDetails(apiParams, isPolling, pollingCounter) {
  return function(dispatch) {
    if(!isPolling) {
      dispatch({type: GET_USER_SUBSCRIPTION_PENDING})
    }
    if (configuration.isAppTvodType) {
      //This api used to show subscriptions in subscription page for TVOD.
      dispatch(getTvodSubscriptionDetails())
    }
    axios.get(APIS.GET_USER_SUBSCRIPTION_API, configuration.isAppTvodType ? getTvodApiHeaders() : {
      params: apiParams,
      headers: {
        'content-type': 'application/json'
      }
    })
    .then(data => {
      if(configuration.isAppTvodType) {
        const resp = (data.data && data.data.data) || [];
        let allData = {};
        let isSubscribed = false;
        let sub_platform = null;
        resp.forEach(purchase => {
          if(purchase.isActive) {
            // added check here beacuse getting duplicate entry with same bundle token, resulting in overriding hash value
            // https://github.com/MagAppZine/MAZ-Video-Ui/issues/1013
            if(purchase.type === "subscriptions" || purchase.type === "rentals") {
              if(getDateWithoutHyphen(purchase.endsAt) > new Date()) {
                allData[purchase.bundleToken] = purchase;
                if(purchase.type === "subscriptions") {
                  isSubscribed = true;
                  sub_platform = purchase.platform
                }
              }
            } else {
              allData[purchase.bundleToken] = purchase;
            }
          }
        })
        dispatch({
          type: GET_USER_SUBSCRIPTION_RESOLVE,
          payload: {
            tvod_purchased: allData,
            subscription: isSubscribed,
            sub_platform: sub_platform
          }
        })
        if(isSubscribed && sub_platform) {
          analytics.setEvent('Subscriptions', 'Authenticated Access', sub_platform)
          sendSubscribeEvent();
        }
        if(Boolean(pollingCounter)){
          getEntitlementCounterPolling({dispatch, isSubscribed, pollingCounter})
        }
        if(!ENTITLEMENT_POLL) {
          startGetEntitlementsPolling(dispatch)
        }
      } else {
        const allSubscription = data.data.purchases;
        const subs = allSubscription.map(sub => sub.item_id.toString());
        const [isSubscribed, sub_platform] = isUserSubscribed(allSubscription);

        //Send analytics event for authenticated access
        if(isSubscribed) {
          analytics.setEvent('Subscriptions', 'Authenticated Access', sub_platform)
          sendSubscribeEvent();
        }
        dispatch({
          type: GET_USER_SUBSCRIPTION_RESOLVE,
          payload: {
            items_purchased: subs,
            subscription: isSubscribed,
            all_subscriptions: allSubscription,
            sub_platform: sub_platform,

          }
        })
      }
    })
    .catch(data => {
      analytics.setEvent('Subscriptions', 'Authenticated Access Failure', '');
      dispatch({
        type: GET_USER_SUBSCRIPTION_REJECT,
        payload: {
          error: {
            message: "Some error occur. Failed to get subscription."
          }
        }
      })
    })
  }
}

const getEntitlementCounterPolling = async ({dispatch, isSubscribed = false, pollingCounter = 0}) => {
  const isEntitlementPollingRequired = () => {
    if (pollingCounter < 2) return false
    if(isSubscribed) return false
    return true
  }

  const isEntitleMentRequired = isEntitlementPollingRequired()

  if(isEntitleMentRequired){
    await new Promise(resolve => setTimeout(resolve, 5000)); // 5 Seconds Wait Tine Before Calling Entitlement API again
    dispatch(getUserSubscriptionDetails(null,null,pollingCounter -= 1))
  } else {
    dispatch({type: STOP_ENTITLEMENT_POLLING})

  }
}

export function createUserSubscription(data) {
  return async (dispatch) => {
    try {
      const post = await axios.post(APIS.GET_USER_SUBSCRIPTION_API, data, getTvodApiHeaders())
      dispatch({type: START_ENTITLEMENT_POLLING})
      getEntitlementCounterPolling({dispatch, isSubscribed: false, pollingCounter : 12})
    } catch (error) {
      console.log('Something Went Wrong in Subscription! Please Contact Customer Support')
    }
  }
}

export function simulatedTvodPurchase(item) {
  return function(dispatch) {
    dispatch({type: GET_SIMULATED_SUBSCRIPTION_TVOD, payload: item})
  }
}

export function getUserMeteringDetails(param) {
  return function(dispatch, getState) {
    const state = getState();
    dispatch({type: USER_METERING_PENDING})
    axios.get(`${APIS.USER_INFO_API}${param.user_id}/meters`, {
      params: {
        auth_token: param.auth_token,
        platform: config.platform,
        n: 100
      },
      headers: {
        'content-type': 'application/json'
      }
    })
    .then(data => {
      let payload = {};
      const METERING_TYPE = getMeteringTypes();
      const {type, time, views, meterStartDate} = data.data.meters;
      let localData = JSON.parse(localStorage.getItem('metering_details'))
      if (type == METERING_TYPE.TIME) {
        // local time metering saved in case of page refresh or tab close

        let details = {}
        // change array of objects to an object
        time.forEach(element => {
          let key = Object.keys(element)[0];
          let value = element[key];
          if(localData && localData[key] !== undefined && localData[key] < value) {
            value = localData[key];
          }
          details[key] = value
        });
        payload.metering_details = details;
      } else if (type == METERING_TYPE.VIEWS) {
        payload = getViewMeteringData(state.feeds, meterStartDate, views)
      } else if (!type && localData) {
        payload.metering_details = localData;
      }

      if(localData) {
        localStorage.removeItem("metering_details");
      }
      dispatch({
        type: USER_METERING_RESOLVE,
        payload: payload
      })
    })
    .catch(data => {
      dispatch({
        type: USER_METERING_REJECT,
        payload: {
          error: {
            message: "Some error occur. Failed to get metering details."
          }
        }
      })
    })
  }
}

export function saveUserMeteringDetails(param) {
  return function(dispatch) {

    dispatch({type: USER_METERING_PENDING})
    axios.post(`${APIS.USER_INFO_API}${param.user_id}/meters`, {
      auth_token: param.auth_token,
      platform: config.platform,
      meters: param.meters
    })
    .then(data => {
      dispatch({
        type: USER_METERING_RESOLVE,
        payload: {}
      })
    })
    .catch(data => {
      dispatch({
        type: USER_METERING_REJECT,
        payload: {
          error: {
            message: "Some error occur. Failed to save metering details."
          }
        }
      })
    })
  }
}

export function getViewMeteringData(feeds, meterStartDate, views) {
  // Check whether views has been reset or not, in case of view metering
  let payload = {};
  if(meterStartDate) payload.meterStartDate = meterStartDate;
  if(views) payload.metering_views_seen = views;
  if(feeds && feeds.feeds && feeds.feeds.ctaMetadata && feeds.feeds.ctaMetadata.metering &&
    meterStartDate && views) {
    const resetAfter = feeds.feeds.ctaMetadata.metering.resetAfter;
    if (resetAfter > 0) {
      // change date format from dd/mm/yy to mm/dd/yy
      let formattedDate = meterStartDate.split("/");
      formattedDate = `${formattedDate[1]}/${formattedDate[0]}/${formattedDate[2]}`;
      const diffDays = (new Date() - new Date(formattedDate)) / (86400 * 1000);
      if (diffDays > resetAfter) {
        payload["metering_views_seen"] = [];
      }
    }
  }
  return payload;
}

export function updateMeteringDetailsForFeed() {
  return function(dispatch, getState){
    let currentState = getState();
    const { feeds, user: {metering_views_seen, meterStartDate}} = currentState;
    let payload = getViewMeteringData(feeds, meterStartDate, metering_views_seen)
    dispatch({type: USER_METERING_RESOLVE, payload: payload})
  }
}

export function userEngaged() {
  return function(dispatch) {
    dispatch({type: USER_ENGAGED})
  }
}

export function getUserConcent() {
  return function(dispatch) {
    dispatch({type: USER_CONCENT})
  }
}

export function generateWebsiteLoginToken(app_id, gdprParams={}, tveParams = {}) {
  let keyObj = {};
  if(configuration.isAppTvodType) {
    keyObj = { key: getTvodAPIKey() };
  }

  return function(dispatch, getState) {
    dispatch({type: GET_WEBSITE_LOGIN_PENDING})
    axios.post(`${APIS.WEBSITE_LOGIN}`, {
      app_user: {
        ...gdprParams,
        app_id
      },
      ...keyObj,
      ...tveParams,
      platform: configuration.isAppTvodType ? getCurrentPlatformTvod() : config.platform,
      ...addTvodAPIRegionalisation({}, getState)
    })
    .then(data => {
      dispatch({
        type: GET_WEBSITE_LOGIN_RESOLVED,
        payload: data.data
      })
    })
    .catch(data => {
      dispatch({
        type: GET_WEBSITE_LOGIN_REJCTED,
        payload: data.data
      })
    })
  }
}

export function generateTVELoginToken({software_statement, requestor, tve_provider, generateTokenData}) {
  const deviceId = uuidv4();
  return async function(dispatch) {
    dispatch({type: GET_WEBSITE_LOGIN_PENDING})
    try {
      const { data: { client_secret, client_id } } = await axios.post(APIS.ADOBE_TVE_REGISTRATION_API, { software_statement });
      const { data: { access_token } } = await axios.post(APIS.ADOBE_TVE_ACCESS_TOKEN_API, null, {
        params: {
          client_secret,
          client_id,
          grant_type: "client_credentials"
        }
      });
      const { data } = await axios.post(`${APIS.ADOBE_TVE_API}/reggie/v1/${requestor}/regcode`, null, {
        params: {
          deviceId
        },
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + access_token,
        }
      });
      const tveParams = {
        tve_provider,
        adobe_device_id: deviceId,
        adobe_device_code: data.code,
        adobe_device_info: data.info.deviceInfo,
      }
      dispatch(generateWebsiteLoginToken(...generateTokenData, tveParams))
    } catch (error) {
      dispatch({
        type: GET_WEBSITE_LOGIN_REJCTED,
        payload: error
      })
    }
  }
}

function setRegistrationAnalyticsForWebsiteLogin(isLogin) {
  if(isLogin){
    analytics.setEvent('Registration', 'Log In', 'Email')
  }else{
    analytics.setEvent('Registration', 'Register', 'Email')
  }
}

export function pollingSession(sessionUrl) {
  let api = APIS.FB_LOGIN_SIGNUP+sessionUrl;
  const isAppTvodType = configuration.isAppTvodType;
  if (sessionUrl.toLowerCase().startsWith("http")) {
    api = sessionUrl
  }
  return function(dispatch) {
    dispatch({type: POLLING_START});
    axios.get(api)
    .then(data => {
      if (data.data.polling_success) {
        dispatch({type: POLLING_STOP});
        isAppTvodType ? onSuccessfulTvodLogin(dispatch, data, config.app_id) : onSuccessfulLogin(dispatch, data, config.app_id, null);
        setRegistrationAnalyticsForWebsiteLogin(data.data.already_exists);

      }
    })
    .catch(err => {
      if ((err.response && err.response.status === 401)) {
        dispatch({type: POLLING_STOP, payload: {isPollingError: true}});
      }
    })
  }
}

export function getFbLoginCode() {
  return function(dispatch) {
    dispatch({type: GET_FB_LOGIN_CODE_PENDING})
    axios.post(`${APIS.FB_GET_CODE}`, {
      access_token: `${config.fb_app_id}|${config.fb_client_token}`,
      scope: 'public_profile,email',
    })
    .then(data => {
      dispatch({
        type: GET_FB_LOGIN_CODE_RESOLVED,
        payload: data.data
      })
    })
    .catch(data => {
      dispatch({
        type: GET_FB_LOGIN_CODE_REJCTED,
        payload: data.data
      })
    })
  }
}

export function fbLoginPolling(fbLoginCode) {
  return function(dispatch) {
    dispatch({type: FB_POLLING_START});
    axios.post(`${APIS.FB_POLLING_URL}`, {
      access_token: `${config.fb_app_id}|${config.fb_client_token}`,
      code: fbLoginCode,
    })
    .then(data => {
      if (data.data.access_token) {
        dispatch({type: FB_POLLING_STOP});
        dispatch(getFBUserNameEmail(data.data.access_token));
        // onSuccessfulLogin(dispatch, data, config.app_id, null)
        // setRegistrationAnalyticsForWebsiteLogin(data.data.already_exists);
      }
    })
    .catch(err => {
      if (err.response.status === 401) {
        dispatch({type: FB_POLLING_STOP, payload: {isPollingError: true}});
      }
    })
  }
}

export function getFBUserNameEmail(accessCode) {
  return function(dispatch) {
    dispatch({type: GET_FB_USER_DATA_PENDING});
    axios.get(`${APIS.FB_GET_USER_DATA}?fields=name,email&access_token=${accessCode}`)
    .then(data => {
      dispatch({type: GET_FB_USER_DATA_RESOLVED, payload: { ...data.data, token: accessCode}});
    })
    .catch(err => {
      dispatch({type: GET_FB_USER_DATA_REJECTED, payload: err});
    })
  }
}

export function getUserIp() {
  return function(dispatch) {
    dispatch({ type: GET_USER_IP_PENDING })
    getIp()
    .then(data => {
      dispatch({type: GET_USER_IP_RESOLVED, payload: data.data.ip })
    }).catch(err => {
      dispatch({ type: GET_USER_IP_REJECTED, payload: null })
    })
  }
}

function getIp() {
  return axios.get(APIS.USER_IP, { mode: 'cors' })
    .then(data => {
      return data;
    })
}

export function getUserDeviceID() {
  return function(dispatch) {
    dispatch({ type: GET_UUID_PENDING });

    if(checkCorrectPlatform([PLATFORM_WEB, PLATFORM_WEB_PREVIEW])) {
      dispatch({ type: GET_UUID_RESOLVED, payload: null });
    } else if(checkCorrectPlatform([PLATFORM_LG])) {
      let proms = new Promise((resolve, reject) => {
        if(!window.webOSDev) {
          reject(null);
          return;
        }
        window.webOSDev.LGUDID({
          onSuccess: function (res) {
            // user device from server
            if(res && res.id) {
              resolve(res.id);
            } else {
              resolve(null);
            }
          },
          onFailure: function (res) {
            reject(null);
          }
        });
      });

      proms.then(data => {
        dispatch({ type: GET_UUID_RESOLVED, payload: data })
      }).catch(err => {
        dispatch({ type: GET_UUID_REJECTED, payload: err })
      })
    } else if(checkCorrectPlatform([PLATFORM_SAMSUNG])) {
      if(!window.tizen) {
        dispatch({ type: GET_UUID_REJECTED, payload: null })
        return;
      }
      let did = window.tizen.systeminfo.getCapability("http://tizen.org/system/tizenid") || window.webapis.productinfo.getDuid();
      dispatch({ type: GET_UUID_RESOLVED, payload: did });
    } else if(checkCorrectPlatform([PLATFORM_VIZIO]) && window.VIZIO) {
      window.VIZIO.getDeviceId(function(deviceId) {
        if(!deviceId) {
          dispatch({ type: GET_UUID_REJECTED, payload: null });
        } else {
          dispatch({ type: GET_UUID_RESOLVED, payload: deviceId });
        }
      });
    } else if(checkCorrectPlatform([PLATFORM_ZEASN]) && window.onDeviceInfoReady) {
      window.onDeviceInfoReady((device_info) => {
        try {
          if(device_info.Product.deviceID) {
            dispatch({ type: GET_UUID_RESOLVED, payload: device_info.Product.deviceID });
          }
        } catch(err) {
          dispatch({ type: GET_UUID_REJECTED, payload: null });
        }
      });
    }
  }
}

export function getuserLocation() {
  return function(dispatch) {
    dispatch({ type: GET_USER_LOCATION_PENDING });

    axios.get(APIS.USER_GEO, { mode: 'cors' })
    .then(data => {
      console.log("location",data);
      const coords = data.geo || null;
      dispatch({ type: GET_USER_LOCATION_RESOLVED, payload: coords })
    }).catch(err => {
      dispatch({ type: GET_USER_LOCATION_REJECTED, payload: null })
    });
    // let gps_prom = new Promise((resolve, reject) => {
    //   if(navigator.geolocation) {
    //     navigator.geolocation.getCurrentPosition((pos) => {
    //       resolve(pos);
    //     },(err) => {
    //       reject(err);
    //     })
    //   } else {
    //     reject(null);
    //   }
    // });

    // gps_prom.then(data => {
    //   console.log("Location",data);
    //   const coords = data.coords;
    //   dispatch({ type: GET_USER_LOCATION_RESOLVED, payload: coords });
    // }).catch(err => {
    //   console.log("Location erro ",err);
    //   dispatch({ type: GET_USER_LOCATION_REJECTED, payload: null });
    // })
  }
}

export function getDeviceModel() {
  return function(dispatch) {
    dispatch({ type: GET_DEVICE_MODEL_PENDING });

    if(checkCorrectPlatform([PLATFORM_WEB, PLATFORM_WEB_PREVIEW])) {
      dispatch({ type: GET_DEVICE_MODEL_RESOLVED, payload: null });
    } else if(checkCorrectPlatform([PLATFORM_SAMSUNG])) {
      if(!window.tizen) {
        dispatch({ type: GET_DEVICE_MODEL_REJECTED, payload: null })
        return;
      }

      let proms = new Promise((resolve, reject) => {
        window.tizen.systeminfo.getPropertyValue('BUILD',(res) => {
          resolve(res);
        },err => {
          reject(err);
        })
      });

      proms.then(data => {
        let model = `${data.manufacturer} ${data.model} ${data.buildVersion}`;
        dispatch({ type: GET_DEVICE_MODEL_RESOLVED, payload: model });
      }).catch(err => {
        dispatch({ type: GET_DEVICE_MODEL_REJECTED, payload: null });
      });
    } else if(checkCorrectPlatform([PLATFORM_LG])) {
      if(!window.webOS) {
        dispatch({ type: GET_DEVICE_MODEL_REJECTED, payload: null })
        return;
      }

      let proms = new Promise((resolve, reject) => {
        window.webOS.service.request("luna://com.webos.service.tv.systemproperty", {
          method: "getSystemInfo",
          parameters: {
            "keys": ["modelName", "firmwareVersion", "UHD", "sdkVersion"]
          },
          onComplete: function (inResponse) {
            let isSucceeded = inResponse.returnValue;
            if(isSucceeded) {
              resolve(inResponse);
            } else {
              reject(null);
            }
          }
        })
      });

      proms.then(data => {
        let model = `LG ${data.modelName} ${data.firmwareVersion}`;
        dispatch({ type: GET_DEVICE_MODEL_RESOLVED, payload: model });
      }).catch(err => {
        dispatch({ type: GET_DEVICE_MODEL_REJECTED, payload: null });
      });
    } else if(checkCorrectPlatform([PLATFORM_VIZIO])) {
      if(!window.VIZIO) {
        dispatch({ type: GET_DEVICE_MODEL_REJECTED, payload: null })
        return;
      }
      window.VIZIO.getFirmwareVersion(function(firmwareVersion){
        if(!firmwareVersion) {
          dispatch({ type: GET_DEVICE_MODEL_REJECTED, payload: null });
        } else {
          let model = `vizio ${window.VIZIO.deviceModel} ${firmwareVersion}`;
          dispatch({ type: GET_DEVICE_MODEL_RESOLVED, payload: model });
        }
      });
    } else if(checkCorrectPlatform([PLATFORM_ZEASN])) {
      if(!window.onDeviceInfoReady) {
        dispatch({ type: GET_DEVICE_MODEL_REJECTED, payload: null });
        return;
      }

      window.onDeviceInfoReady(device_info => {
        try {
          if(device_info.Product) {
            const model = `zeasn ${device_info.Channel.brand} ${device_info.Product.platform} ${device_info.Product.firmwareVersion}`
            console.log("model", model);
            dispatch({ type: GET_UUID_RESOLVED, payload: model });
          }
        } catch(err) {
          dispatch({ type: GET_UUID_REJECTED, payload: null });
        }
      });
    }
  }
}

export function loginThroughMazTv(authToken, userId) {
  return function(dispatch) {
    const app_id = config.app_id;
    let responseObj = {
      data: {
        auth_token: authToken,
        user_id: userId,
        success: true
      }
    }
    dispatch({type: LOGIN_MAZ_TV});
    onSuccessfulLogin(dispatch, responseObj, app_id, null);
  }
}

export function logoutMazTv() {
  return function(dispatch) {
    dispatch({type: LOGOUT_MAZ_TV});
    try {
      window.parent.postMessage({
        action: 'logout',
        request_from: 'web-tv'
      }, "*");
    } catch (e) {
      console.log("failed to send logout message.")
    }
  }
}

export function getLocationFromIP(defaultCountry) {
  return function(dispatch) {
    dispatch({
      type: LOCATION_GEO_PENDING
    })
    getIp()
    .then(data => {
      const ip = data.data.ip;
      return axios.get(`${APIS.LOCATION_FROM_IP}?ip_address=${ip}`)
    })
    .then(data => {
      const country = data.data && data.data.alpha2 ? data.data.alpha2.toUpperCase() : defaultCountry
      setGeoFenceInLocal(true, country)
      dispatch(setGeolocation(country))
    })
    .catch(err => {
      setGeoFenceInLocal(true, defaultCountry)
      dispatch({
        type: LOCATION_GEO_REJECTED,
        payload: defaultCountry
      })
      console.log(err)
    })
  }
}

export function setGeolocation(country) {
  return function(dispatch) {
    dispatch({
      type: LOCATION_GEO_RESOLVED,
      payload: country
    })
  }
}

export function checkVoucherCodeValidity(code, app_id) {
  return function(dispatch) {
    if(!code) {
      // On app launch or reload get from localstorage
      try {
        if(checkCorrectPlatform(PLATFORM_XBOX) && window.Windows != undefined) {
          let localSettings = window.Windows.Storage.ApplicationData.current.localSettings;
          code = localSettings.values[`valid_voucher_code_${app_id}`];
        } else {
          code = localStorage.getItem(`valid_voucher_code_${app_id}`);
        }
      } catch(err) {
        console.log("unable to store voucher code!");
      }
    }
    if(!code) {
      return;
    }

    dispatch({type: CHECKING_VALID_VOUCHER_CODE})
    axios.post(`${getMazCoreEnvUrl()}/secret_unlock`, {
      "code": code,
      "app_id": app_id,
      "app_type": "ComboApp"
    }).then(result => {
      const success = result.data.success;
      if(checkCorrectPlatform([PLATFORM_XBOX]) && window.Windows != undefined) {
        let localSettings = window.Windows.Storage.ApplicationData.current.localSettings;
        if(success) {
          localSettings.values[`valid_voucher_code_${app_id}`] = code;
        } else {
          localSettings.values.remove(`valid_voucher_code_${app_id}`);
        }
      } else {
        if(success) {
          localStorage.setItem(`valid_voucher_code_${app_id}`, code);
        } else {
          localStorage.removeItem(`valid_voucher_code_${app_id}`);
        }
      }
      dispatch({type: GET_VALID_VOUCHER_CODE, payload: success})
    }).catch(err => {
      console.log("erroe", err)
      dispatch({type: GET_VALID_VOUCHER_CODE, payload: false})
    })
  }
}

export function checkIfUserOnline() {
  const ping_url = 'https://google.com';

  return function(dispatch) {
    fetch(ping_url, {
      method: 'GET',
      mode: 'no-cors',
      timeout: 4000
    }).then((result) => {
      dispatch({
        type: GET_USER_ONLINE_RESOLVED
      });
    }).catch(e => {
      dispatch({
        type: GET_USER_ONLINE_REJECTED
      });
    });
  }
}

export function resetPassword(body) {
  return function(dispatch) {
    dispatch({type: RESET_PASSWORD_PENDING, payload: { state: API_STATE.loading, success: null }});
    axios.put(APIS.USER_FORGOT_PASSWORD_API, body, getTvodApiHeaders(true))
    .then(data => {
      dispatch({type: RESET_PASSWORD_RESOLVED, payload: {state: API_STATE.resolved, success: true }});
    })
    .catch(err => {
      dispatch({type: RESET_PASSWORD_REJECTED, payload: {state: API_STATE.rejected, success: false, ...err.response.data}});
    })
  }
}

export function setOnboardingCompleted(value) {
  return function(dispatch) {
    dispatch({
      type: SET_ONBOARDING_COMPLETED,
      payload: value
    })
  }
}
export function validateDeviceOTP(params) {
  const url = APIS.VALIDATE_TOKEN_API
  return async function(dispatch, getState) {
    try {
      dispatch({ type: VALIDATE_DEVICE_OTP_PENDING });
      const response = await axios.post(url, addTvodAPIRegionalisation(params, getState), getTvodApiHeaders(true));
      dispatch({ type: VALIDATE_DEVICE_OTP_RESOLVE });
    } catch (e) {
      dispatch({ type: VALIDATE_DEVICE_OTP_REJECT });
      console.error(e);
    }
  }
}

export function deviceLogin(params) {
  const url = APIS.AUTHENTICATE_DEVICE_API
  return async function(dispatch, getState) {
    try {
      dispatch({ type: DEVICE_LOGIN_PENDING });
      const response = await axios.post(url, addTvodAPIRegionalisation(params, getState), getTvodApiHeaders());
      dispatch({ type: DEVICE_LOGIN_RESOLVE });
    } catch (e) {
      dispatch({ type: DEVICE_LOGIN_REJECT });
      console.error(e);
    }
  }
}

export function clearDeviceLogin() {
  return function(dispatch) {
    dispatch({type: CLEAR_DEVICE_LOGIN})
  }
}

export function cleengLogin({refreshToken, jwtToken, app_id}) {
  let reqParams = {
    app_user: { app_id: app_id },
    access_token: jwtToken,
    refresh_token: refreshToken,
    key: getTvodAPIKey()
  }
  const isAppTvodType = configuration.isAppTvodType;
  const user_action = "Login";

  let url = APIS.CLEENG_SIGN_IN;
  return function(dispatch, getState) {
    if(isAppTvodType) {
      reqParams.platform = getCurrentPlatformTvod();
      reqParams = addTvodAPIRegionalisation(reqParams, getState);
    }
    dispatch({type: 'RECEIVE_USER_EMAIL_PENDING'})
    axios.post(url, reqParams, getTvodApiHeaders(true))
      .then((response) => {
        onSuccessfulTvodLogin(dispatch, response, app_id);
        // setRegistrationAnalytics('cleeng', user_action)
      })
      .catch((error) => {
        if(isAppTvodType && error.response && error.response.data && error.response.data.errors && Object.keys(error.response.data.errors)) {
          let key = Object.keys(error.response.data.errors)[0]
          let msg = `${key} ${error.response.data.errors[key]}`
          error.response.data.error = msg;
        }
        dispatch({type: "RECEIVE_USER_ERROR", payload: (error.response && error.response.data), app_id: app_id, logged_in: false, error: true})
      })
  }
}

export function setAutoPlay(val) {
  return function(dispatch) {
    setLocalStorage(`${configuration.app_id}-autoplay`, val);
    dispatch({type: SET_AUTO_PLAY, payload: val})
  }
}

export function getUserProfile(user) {
  return function(dispatch) {
    if(!user || !user.profiles || user.profiles.length === 0) return;
    const id = user.profiles[0].identifier || "";
    axios.get(`${APIS.USER_PROFILE_API}/${id}` , getTvodApiHeaders())
      .then(data => {
        const user_profile = (data.data && data.data.app_user) || {}
        dispatch({type: GET_USER_PROFILE_DATA, payload: user_profile})
      })
  }
}
