import { FEED_POLICY_RESPONSE, ONE_FEED_PENDING, ONE_FEED_REJECTED, ONE_FEED_RESOLVED, PREVIEW_SESSION_EXPIRED, PREVIEW_LOCALES_RESPONSE, SET_CC_OPTION, SET_LOCAL_SUBTITLE_SETTING, PREVIEW_LOCALES_CHANGE, FEED_POLICY_REJECTED } from "../constants/actionTypes";
import configuration from "../constants/config";

const defaultState = {
  feeds: {
    text: 1
  }, 
  app_id: 0, 
  background: '',
  oneFeedLoading: false,
  showSubSetting: false,
  showSubtitle: false,
  policyData: {},
  localesData: null,
  previewSessionExpired: false,
  feedPolicyRejected: false
}

export default function reducer(state = defaultState, action) {

  switch (action.type) {
    case "RECEIVE_FEEDS": {
      var sections = action.payload.sections
      if(sections){
        var defaultSection = sections[0]
        for(var i=0; i<sections.length; i++){
          if(sections[i].isDefault){
            defaultSection = sections[i];
            break;
          }
        }
        return {
          ...state, feeds: action.payload, app_id: action.app_id, background: defaultSection.background,
        }
      } else {
        return {
          ...state, feeds: action.payload, app_id: action.app_id, background: sections,
        }
      }
    }

    case ONE_FEED_PENDING: {
      return {
        ...state,
        oneFeedLoading: true
      }
    }
    case ONE_FEED_RESOLVED: {
      return {
        ...state,
        app_id: configuration.app_id,
        feeds: action.payload,
        oneFeedLoading: false
      }
    }

    case ONE_FEED_REJECTED: {
      return {
        ...state,
        oneFeedLoading: false
      }
    }

    case SET_CC_OPTION: {
      return {
        ...state,
        showSubSetting: action.payload.showSetting,
        showSubtitle: action.payload.localSub
      }
    }

    case SET_LOCAL_SUBTITLE_SETTING: {
      return {
        ...state,
        showSubtitle: action.payload
      }
    }
    case FEED_POLICY_RESPONSE: {
      return {
        ...state,
        policyData: action.payload
      }
    }

    case FEED_POLICY_REJECTED: {
      return {
        ...state,
        feedPolicyRejected: true
      }
    }

    case PREVIEW_LOCALES_CHANGE: {
      return {
        ...state,
        policyData: action.payload,
        feeds: {
          text: 1
        }, 
      }
    }
    case PREVIEW_LOCALES_RESPONSE: {
      return {
        ...state,
        policyData: action.payload.policyData,
        localesData: action.payload.localesData
      }
    }
    case PREVIEW_SESSION_EXPIRED: {
      return {
        ...state,
        previewSessionExpired: true
      }
    }
  }

  return state
}