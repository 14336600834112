import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { userEngaged } from '../actions/userActions';
import { isSmartTv } from '../helperfunctions/common';

const AudioMutedMessage = props => {
    const { _this, videoId } = props
    const dispatch = useDispatch()
    const [showMessage, setShowMessage] = useState(false)
    const player = useRef(null)
    player.current = _this.theoPlayerReference || document.getElementById(videoId)
    const isVideoMuted = player.current && player.current.muted
    let showMessageTimer = 0

    useEffect(() => {
        return () => {
            handleClick()
        }
    }, [])

    const toggleMutedMessage = () => {
        setShowMessage(true)
        showMessageTimer = setTimeout(() => {
            setShowMessage(false)
        }, 7_500);
    }

    useEffect(() => {
        if (isVideoMuted) {
            toggleMutedMessage()
        }
    }, [isVideoMuted])

    useEffect(() => {
        if (!showMessage && showMessageTimer) {
            clearTimeout(showMessageTimer)
        }
    }, [showMessage, showMessageTimer])

    const handleClick = () => {
        if (player.current) {
            try {
                player.current.muted = false
            } catch (error) {
                console.log("Unable to Perform Muted Action")
            }
        }
        setShowMessage(false)
    }

    const handleUserKeyPress = useCallback(event => {
        const { keyCode } = event;
        if (keyCode) {
            dispatch(userEngaged())
        }
    }, []);

    useEffect(() => {
        if (isSmartTv()) window.addEventListener("keydown", handleUserKeyPress);

        return () => {
            if (isSmartTv()) window.removeEventListener("keydown", handleUserKeyPress);
        };
    }, [handleUserKeyPress]);

    return (
        <div className="muted-video-overlay" onClick={handleClick} >
            {showMessage ? <span className="muted-video-message"><p> <b>{isSmartTv() ? "Press any key on remote to unmute audio" : "Click anywhere on the screen to unmute"}</b></p></span> : <></>}
        </div>
    )
}

export default AudioMutedMessage;