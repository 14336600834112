import React, { useEffect, useState } from 'react';

import { formatHexCode } from '../constants/common';
import { isPreviewBlocked, previewContent, supportingToRTL } from '../helperfunctions';
import { isSmartTv } from '../helperfunctions/common';
import PreviewVideo from './PreviewVideo';

const getGradientStyle = (background, feeds) => {
  if(!background || !background.gradient || !background.gradient.topColor || !background.gradient.bottomColor) return {};
  const tempGradTop = formatHexCode(background.gradient.topColor, true);
  const tempGradBot = formatHexCode(background.gradient.bottomColor, true);
  return {
    backgroundImage: supportingToRTL(feeds) ? `linear-gradient(to left, ${tempGradTop}, ${tempGradBot})` : `linear-gradient(${tempGradTop}, ${tempGradBot})`
  }
}

export default function TvodWatchmanBackground(props) {
  const { section, feeds, openedWithPayment, runningOnMobile } = props;
  const [ showImage, setShowImage] = useState(true);
  const [ videoEnded, setVideoEnded] = useState(false);
  let switchTimer = null;
  let fadeTimer = null;
  let fadeInTimer = null;
  const { video_content } = Boolean(section.previewInfo)? previewContent({item: {}, previewType: "previewVideo", previewInfo: section.previewInfo, section}) : {};
  const previewBlocked = isPreviewBlocked(feeds);
  const playingPreview = Boolean(!openedWithPayment && !previewBlocked && video_content && video_content.contentUrl);
  const gradientStyle = getGradientStyle(section.background, feeds);

  useEffect(() => {
    clearTimer();
    setVideoEnded(false);
    setShowImage(true);
    if(playingPreview) {
      switchTimer = setTimeout(() => {
        const el = document.getElementById("preview-background");
        if(el) el.classList.add("fade-out");
        fadeTimer = setTimeout(() => {
          setShowImage(false);
        }, 1000)
      }, 2000);
    }

    return () => clearTimer();
  }, [section]);

  useEffect(() => {
    if(showImage && videoEnded) {
      const el = document.getElementById("preview-background");
      if(el) {
        el.classList.replace("fade-out", "fade-in");
      }
    }
  }, [showImage])


  let background = section.background || {};
  const colorStyle = background && background.color ? {backgroundColor: background.color.replace('#FF', '#')} : {};

  const clearTimer = () => {
    if(switchTimer) {
      clearTimeout(switchTimer);
    }
    if(fadeTimer) {
      clearTimeout(fadeTimer);
    }
    if(fadeInTimer) {
      clearTimeout(fadeInTimer);
    }
  }

  const getObjectFitStyle = () => {
    const { extras } = feeds;
    let imgFit = {};
    if(runningOnMobile || isSmartTv()) return { imgFit, videoFit: ""};

    let videoFit = "contain-fit";
    if(extras && extras.customUI && extras.customUI.tv) {
      const { webInterstitialImageFit, webInterstitialVideoFit } = extras.customUI.tv;
      if(webInterstitialImageFit) {
        imgFit = { "objectFit": webInterstitialImageFit }
      }
      if(["cover", "contain", "fill" ].includes(webInterstitialVideoFit)) {
        videoFit = `${webInterstitialVideoFit}-fit`;
      }
    }
    return { imgFit, videoFit };
  }

  const handleFade = () => {
    const el = document.querySelector(".tvod-interstitial .base #previewVideo");
    if(el) {
      el.classList.replace("fade-in", "fade-out");
    }
    setVideoEnded(true);
    fadeInTimer = setTimeout(() => {
      setShowImage(true);
    }, 1000)
  }

  const renderVideoPreview = (video_content, isBackground) => {
    const { videoFit } = getObjectFitStyle();
    return (
      <PreviewVideo
        video_content={video_content}
        gradientStyle={gradientStyle}
        onEnded={() => handleFade()}
        isTvodInt={true}
        isBackground={isBackground}
        videoFit={ videoFit }
      />
    )
  }

  const renderPreview = () => {
    let imgSrc = null;
    let videoSrc = null;
    let grad = {...gradientStyle};
    if(playingPreview && !videoEnded && video_content.cover_url) {
      imgSrc = video_content.cover_url;
    } else if (background.imageUrl) {
      imgSrc = background.imageUrl;
    } else if (!previewBlocked && !(video_content && video_content.contentUrl) && background.videoUrl && !videoEnded ) {
      videoSrc = background.videoUrl
    } else if (section.previewImage && section.previewImage.url) {
      imgSrc = section.previewImage.url;
    }
    
    if(imgSrc) {
      const { imgFit } = getObjectFitStyle();
      return (
        <>
          <img
            id="preview-background"
            style={{visibility:"hidden", ...colorStyle, ...imgFit}}
            onLoad={(e) => {e.target.style.visibility='visible'}}
            alt="" 
            src={imgSrc}
          />
          <div id="previewShadow" style={grad}></div>
        </>
      )
    } else if(videoSrc) {
      return renderVideoPreview({contentUrl: videoSrc, muted: true}, true)
    } else {
      return <div id="previewShadow" style={grad}></div>
    }
  }

  return (
    <div className="base" style={colorStyle}>
      {(!showImage && playingPreview)
        ? renderVideoPreview(video_content)
        : renderPreview()
      }
    </div>
  )
}