import { useState, useCallback, memo, useEffect, useRef } from "react";
import { MoveFocusInside } from "react-focus-lock";
import { getTabIndex } from "../../helperfunctions/common";
import { getBackBtnKeyCodes } from "../../helperfunctions/ctvHelper";
import SelectedIcon from "../icons/SelectedIcon";

const ContentModal = memo(
  ({ options, getter = null, setter, parentNode, closeModal = () => {} }) => {
    const [openNode, setOpenNode] = useState(null);
    const selectedRef = useRef(null);
    const backButtonRef = useRef();
    const parentNodeRef = useRef();

    const handleBack = useCallback(() => {
      setter && setter(null);
    }, [setter]);

    useEffect(() => {
      return () =>
        parentNode?.current &&
        document.getElementById(parentNode.current) &&
        document.getElementById(parentNode.current).focus();
    }, []);

    useEffect(() => {
      if (selectedRef.current) {
        selectedRef.current.focus();
      }
    }, [selectedRef]);

    const handleSelectNode = useCallback(
      (event, node) => {
        if (node.children) {
          parentNodeRef.current = event.target.id;
          setOpenNode(node);
        } else {
          node.onSelect && node.onSelect();
          handleBack();
        }
      },
      [handleBack]
    );

    const handleUserKeyPress = useCallback((event) => {
      const { keyCode } = event;
      const back_key_codes = getBackBtnKeyCodes();
      if (back_key_codes[keyCode]) {
        if (backButtonRef && backButtonRef.current)
          backButtonRef.current.click();
      }
    }, []);

    useEffect(() => {
      window.addEventListener("keydown", handleUserKeyPress);
      return () => {
        window.removeEventListener("keydown", handleUserKeyPress);
      };
    }, [handleUserKeyPress]);

    const checkIfSelectedValue = ({ option, getter }) => {
      return (
        option?.label === (getter?.selectedValue && getter.selectedValue())
      );
    };

    return (
      <div className={`content-modal-container`}>
        {!openNode && (
          <>
            <span className="header">{getter ? getter.label : "Settings"}</span>
            <button
              onClick={getter ? handleBack : closeModal}
              style={{ display: "none" }}
              tabIndex={getTabIndex()}
              ref={backButtonRef}
            />
            <MoveFocusInside>
              <div className="options-container">
                {options
                  .filter((option) => !option.hidden)
                  .map((option, idx) => (
                    <div
                      key={`${option.label.replace(/ /g, "")}-${idx}`}
                      id={`${option.label.replace(/ /g, "")}-${idx}`}
                      className="titleHolder option focusable"
                      tabIndex={getTabIndex()}
                      onClick={(event) => handleSelectNode(event, option)}
                      ref={
                        checkIfSelectedValue({ option, getter })
                          ? selectedRef
                          : null
                      }
                    >
                      {option.label}
                      <div className={"content-modal-option-select"}>
                        {Boolean(option.selectedValue) && <nobr>{option.selectedValue()}</nobr>}
                        {Boolean(option.children) && <span>&gt;</span>}
                      </div>
                      
                      {!option.children &&
                        checkIfSelectedValue({ option, getter }) && (
                          <SelectedIcon />
                        )}
                    </div>
                  ))}
              </div>
            </MoveFocusInside>
          </>
        )}

        {openNode && (
          <ContentModal
            options={openNode.children}
            getter={openNode}
            setter={setOpenNode}
            parentNode={parentNodeRef}
          />
        )}
      </div>
    );
  }
);

export default ContentModal;