import React from 'react'
import ThumbnailVideo from './ThumbnailVideo'
import ThumbnailImage from './ThumbnailImage'
import configuration from '../constants/config';

const Thumbnail = (props) => {
  if(props.content.type === "vid" || (props.content.type === "live" && configuration.isAppTvodType)){
    return(
      <ThumbnailVideo background={props.background} feeds={props.feeds} locked={props.locked} show_ad={props.show_ad} primary={props.primary} secondary={props.secondary} content={props.content} head={props.head} detail={props.detail} all_contents={props.all_contents} layout = {props.layout} index={props.index} total={props.total} col={props.col}/>
    )
  } else if(props.content.type === "image" || props.content.type === 'link'){
    return(
      <ThumbnailImage background={props.background} feeds={props.feeds} locked={props.locked} primary={props.primary} secondary={props.secondary} content={props.content} head={props.head} detail={props.detail} all_contents={props.all_contents} layout = {props.layout} index={props.index} total={props.total} col={props.col} />
    )
  } else {
    return(null);
  }
};

export default Thumbnail;
