import React from "react";
import { connect } from "react-redux";
import { isMobile } from "mobile-device-detect";
import WebFont from "webfontloader";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from "react-router-dom";
import queryString from "query-string";

import NavBar from "./NavBar";
import PoweredByScreen from "../components/PoweredByScreen";
import SponsorScreen from "../components/SponsorScreen";
import Modal from "../components/Modal";
import Toast from "../components/toast";
import Pay from "./Pay";
import Reset from "../components/ResetPassword";
import OnBoarding from "../components/OnBoarding";
import PreviewMode from "../components/PreviewMode";
import FeedError from "../components/FeedError";
// import GeofenceConfirm from "../components/GeofenceConfirm";
import DeviceCode from "../components/DeviceCode";

import { getFeeds, channgeLocalCaption } from "../actions/feedsActions";
import {
  setUserCookie,
  getUserSubscriptionDetails,
  getUserMeteringDetails,
  userEngaged,
  getUserIp,
  getUserDeviceID,
  getDeviceModel,
  loginThroughMazTv,
  getLocationFromIP,
  setGeolocation,
  checkVoucherCodeValidity,
  checkIfUserOnline,
  setOnboardingCompleted,
  getUserProfile,
  setAutoPlay
} from "../actions/userActions";
import { getAllRememberSpot } from "../actions/rememberSpotActions";
import {
  getSavedItems,
  getUSerFeedHistoryOrProgress,
  getTvodSavedItems
} from "../actions/itemActions";
import {
  generateFontStyleTagAndFontNames,
  getPrimaryAndSecondaryFontsFromFeed,
  checkFeedIntegrity,
  isUsingTheoPlayer,
  is2by3PosterImage,
  supportingToRTL
} from "../helperfunctions";
import configuration from "../constants/config";
import {
  getCurrentPlatformTvod,
  getGeoFromLocal,
  isSmartTv,
  setGeoFenceInLocal
} from "../helperfunctions/common";
import { checkCorrectPlatform } from "../helperfunctions/common";
import {
  DEFAULT_GEO_LOCALTION,
  MAZ_REPORTING_INTERVAL,
  PLATFORM_HTML_SIMULATOR,
  PLATFORM_LG,
  PLATFORM_SAMSUNG,
  PLATFORM_VIZIO,
  PLATFORM_WEB,
  PLATFORM_XBOX,
  PLATFORM_ZEASN,
  PLATFORM_WEB_PREVIEW,
  K_TOTAL_WATCHED_TIME_BEACONING
} from "../constants/common";
import { setPageViewFixedSections, setEventForWatchedVideo } from "../constants/analytics";
import {
  sendActiveUserEvent,
  sendAppUsageMinutes
} from "../helperfunctions/mazAnalytics";
import { getTvodStreams } from "../actions/streamActions";
import {
  getLocalStorage,
  getUserCookie,
  removeItemLocalStorage,
  setLocalStorage
} from "../helperfunctions/storage";
import { getCleengCredentials } from "../helperfunctions/authHelper";
import { loadMediaMelonScript, resetInstance } from "../helperfunctions/mediaMelon";
import { loadJsScript } from "../helperfunctions/ctvHelper";
import PolicyError from "../components/PolicyError";
import { checkIfVizioAccountSupported } from "../actions/vizioAccountActions";
import { getParentalLockStatus } from "../actions/parentalLockAction";
import { analytics_ga4, setActiveUsersData } from "../constants/analytics";

class Layout extends React.Component {
  constructor(props) {
    super(props);
    const openGeoModal = this.checkGeoPermissionInLocal();
    this.state = {
      launchScreen: true,
      openGeofenceModal: openGeoModal,
      showLaunchScreen: true,
      show_toast: false
    };
    this.beekenInterval = null;
    this.beekenCount = -1;
    this.mazBeekenInterval = null;
    this.mazBeakenCount = 0;
    this.mazBeakenStartTime = new Date().toISOString();
    this.userClickEvent = this.userClickEvent.bind(this);
    this.loadDeviceConfigs = this.loadDeviceConfigs.bind(this);
  }

  componentDidMount() {
    this.getAppData();
    document.body.addEventListener("click", this.userClickEvent, true);
    this.props.getUserIp();
    this.loadScriptsAndConfigs();
    const onMessageReceive = this.onMessageReceive;
    window.addEventListener("message", onMessageReceive);
    this.handleAnalytics();
    if (true) {
      this.online_status_interval = setInterval(() => {
        this.props.checkIfUserOnline();
      }, 4500);
    }
    this.checkOnboradCompletion();
    if (checkCorrectPlatform(PLATFORM_SAMSUNG)) {
      setTimeout(() => {
        this.setState({ showLaunchScreen: false });
      }, 10000);
    }
    if(checkCorrectPlatform([PLATFORM_SAMSUNG]) || checkCorrectPlatform([PLATFORM_XBOX]) ){
      this.removeUniqueSessionId()
    }
    setActiveUsersData();
  }

  componentWillUnmount() {
    document.body.removeEventListener("click", this.userClickEvent, true);
    window.removeEventListener("message", this.onMessageReceive);
    this.handleAppMinuteUsage(true);
    if (this.beekenInterval) {
      clearInterval(this.beekenInterval);
      this.beekenInterval = null;
    }
    if (this.mazBeekenInterval) {
      clearInterval(this.mazBeekenInterval);
      this.mazBeekenInterval = null;
    }
    if (isSmartTv()) {
      if (this.online_status_interval) {
        clearInterval(this.online_status_interval);
        this.online_status_interval = null;
      }

      if (analytics_ga4 || configuration.kEnableGAUserBeaconing) {
        document.removeEventListener(
          "webkitvisibilitychange",
          this.pageVisibilityHandler
        );
      }
    }
    if (checkCorrectPlatform([PLATFORM_VIZIO])) {
      document.removeEventListener(
        "VIZIO_LIBRARY_DID_LOAD",
        this.loadDeviceConfigs
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (isSmartTv() && prevProps.isUserOnline != this.props.isUserOnline) {
      this.setState({ show_toast: true });
    }

    // When feeds load for the first time. Get primary and secondary font and update the state.
    if (
      prevProps.feeds.text === 1 &&
      this.props.feeds &&
      this.props.feeds.fonts &&
      this.props.feeds.fonts.length
    ) {
      const fonts = getPrimaryAndSecondaryFontsFromFeed(this.props.feeds);
      this.loadFonts(fonts);
    }
    if (prevProps.geoLoading && !this.props.geoLoading) {
      this.setState({
        openGeofenceModal: false
      });
    }
    if (
      prevProps.logged_in !== this.props.logged_in &&
      this.props.feeds.onboardingMetadata
    ) {
      if (this.props.logged_in) {
        this.onboardingCompleted();
      } else {
        this.revokeOnboradingCompletion();
      }
    }
    if (
      prevProps.feeds.text === 1 &&
      this.props.feeds &&
      this.props.feeds.text !== 1
    ) {
      this.setAppPlayer();
      this.sendTotalWatchedTimeData();
    }
    if(prevProps.logged_in !== this.props.logged_in) {
      resetInstance(this.props.logged_in);
    }
  }

  sendTotalWatchedTimeData(){
    if(this.props.feeds.kTotalWatchedTimeBeaconing){
      const data = getLocalStorage(K_TOTAL_WATCHED_TIME_BEACONING)

      if(data){
        const { contentUrl, path, videoItem, duration, videoPlayCount } = data;
        setEventForWatchedVideo(contentUrl, this.props.feeds, path, videoItem, duration, videoPlayCount);
        removeItemLocalStorage(K_TOTAL_WATCHED_TIME_BEACONING)
      }
    }
  }

  getAppData() {
    // Polling is happening inside getFeeds Action.
    if (checkCorrectPlatform([PLATFORM_WEB_PREVIEW])) {
      const { token } = queryString.parse(window.location.search);
      if (token) {
        setLocalStorage("preview_token", token);
      }
    }
    this.hideSponsorScreen()
    this.props.getFeeds(configuration.app_id);
    this.authenticateCookie();
    this.props.checkVoucherCodeValidity(null, configuration.app_id);
    this.getAutoplaySetting();
  }

  hideSponsorScreen = () => {
    if(!isSmartTv()){
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      });
      if(params.hidesponsor){
        this.setState({ launchScreen: false });
      }
    }
  }

  loadScriptsAndConfigs() {
    if (checkCorrectPlatform([PLATFORM_VIZIO])) {
      document.addEventListener("VIZIO_LIBRARY_DID_LOAD", () => {
        window.VIZIO &&
          window.VIZIO.setClosedCaptionHandler((isCCEnabled) => {
            if (isCCEnabled) {
              // CC is on
              this.props.channgeLocalCaption(true);
            } else {
              // CC is of
              this.props.channgeLocalCaption(false);
            }
          });
        this.loadDeviceConfigs();
      });
    } else if (checkCorrectPlatform([PLATFORM_ZEASN])) {
      loadJsScript({
        url: "https://cache.zeasn.tv/webstatic/homepage_web/deviceinfo/zeasn_deviceInfo_sdk.js",
        location: document.body,
        type_to_set: "async",
        onLoadMethod: () => {
          this.loadDeviceConfigs();
        }
      });
    } else {
      this.loadDeviceConfigs();
    }
  }

  loadDeviceConfigs() {
    this.props.getUserDeviceID();
    this.props.getDeviceModel();
    this.props.checkIfVizioAccountSupported()
  }

  pageVisibilityHandler() {
    if (document.webkitHidden) {
      // reset counter for CTV
      this.beekenCount = -1;
    }
  }

  onMessageReceive = (event) => {
    const _self = this;
    const data = event.data;
    if (data.request_from === "maz-tv" && data.action === "login") {
      const { user_id, auth_token, app_id } = data;
      if (user_id && auth_token && app_id && app_id == configuration.app_id) {
        _self.props.loginThroughMazTv(auth_token, user_id);
      }
    }
  };

  handleAnalytics() {
    if (analytics_ga4 || configuration.kEnableGAUserBeaconing) {
      this.beekenInterval = setInterval(() => {
        if (document.hidden) {
          this.beekenCount = -1;
        } else {
          this.beekenCount++;
        }
        if (this.beekenCount === 0 || this.beekenCount === 240) {
          // Send first event / every 4 mins
          this.beekenCount = 0;
          setPageViewFixedSections("User Activity Beacon");
        }
      }, 1000);

      if (isSmartTv()) {
        document.addEventListener(
          "webkitvisibilitychange",
          this.pageVisibilityHandler
        );
      }
    }
    sendActiveUserEvent();
    this.mazBeekenInterval = setInterval(() => {
      this.handleAppMinuteUsage();
    }, 1000);
  }

  authenticateCookie = () => {
    let user_cookie = getUserCookie();
    const isCleengAuthenticated = getCleengCredentials().jwtToken;
    const isCleengPayment = configuration.payment_provider === "cleeng";
    const isCookiePresent = isCleengPayment
      ? user_cookie && isCleengAuthenticated
      : user_cookie;

    if (isCookiePresent) {
      if (configuration.isAppTvodType) {
        this.props.getTvodStreams();
        this.props.getTvodSavedItems();
        this.props.getParentalLockStatus();
        this.props.getUserProfile(user_cookie);
      } else {
        this.getUserMetering(user_cookie.auth_token, user_cookie.user_id);
        this.getUserMazFeedHistoryAndProgress(
          user_cookie.auth_token,
          user_cookie.user_id
        );
        this.props.getSavedItems(
          user_cookie.auth_token,
          "100",
          "0",
          user_cookie.user_id
        );
        if (!this.props.rem_fetched_after_login)
          this.props.getAllRememberSpot(
            user_cookie.auth_token,
            user_cookie.user_id
          );
      }
      this.getUserSubscription(user_cookie.auth_token, user_cookie.user_id);
      this.props.setUserCookie(user_cookie, configuration.app_id);
    }
  };

  setAppPlayer() {  
    configuration.kUseTheoPlayer = isUsingTheoPlayer(this.props.feeds);
    // load media melon
    loadMediaMelonScript(this.props.logged_in)
  }

  getAutoplaySetting() {
    const auto = getLocalStorage(`${configuration.app_id}-autoplay`);
    let val = true;
    if (auto !== null && auto !== undefined) {
      val = auto;
    }
    this.props.setAutoPlay(val);
  }

  checkOnboradCompletion() {
    if (getLocalStorage(`${configuration.app_id}-onboarding`) === "done") {
      this.props.setOnboardingCompleted(true);
    }
  }

  revokeOnboradingCompletion() {
    if (this.props.feeds.onboardingMetadata.onboardingWall) {
      removeItemLocalStorage(`${configuration.app_id}-onboarding`);
      this.props.setOnboardingCompleted(false);
    }
  }

  removeUniqueSessionId() {
      removeItemLocalStorage('uniqueSessionId');
      removeItemLocalStorage('gaUniqueSessionTimestamp');
  }

  onboardingCompleted() {
    if (!this.props.onBoardingCompleted) {
      setLocalStorage(`${configuration.app_id}-onboarding`, "done");
      this.props.setOnboardingCompleted(true);
    }
  }

  handleAppMinuteUsage(unmounting) {
    if (!document.hidden) {
      this.mazBeakenCount++;
    }
    if (unmounting || this.mazBeakenCount === MAZ_REPORTING_INTERVAL) {
      //  every configure  mins / unmounting
      sendAppUsageMinutes(this.mazBeakenCount, this.mazBeakenStartTime);
      this.mazBeakenCount = 0;
      this.mazBeakenStartTime = new Date().toISOString();
    }
  }

  checkGeoPermissionInLocal = () => {
    const geoObj = getGeoFromLocal();
    let openGeoModal = true;
    if (geoObj && geoObj.isGeoPermissionSet) {
      openGeoModal = false;
      if (geoObj && geoObj.isGeoFenceOn && geoObj.geoFenceCountry) {
        this.props.setGeolocation(geoObj.geoFenceCountry);
      }
    }
    return openGeoModal;
  };

  checkToOpenGeofenceModal() {
    return true;
  }

  onSponsorTimeout() {
    this.setState({ launchScreen: false });
  }

  //Get user MAZ feed details. (history and progress)
  getUserMazFeedHistoryAndProgress = (token, userId) => {
    const params = {
      auth_token: token,
      user_id: userId,
      combo_app_id: configuration.app_id
    };
    this.props.getUSerFeedHistoryOrProgress({
      ...params,
      filterType: "history"
    });
    this.props.getUSerFeedHistoryOrProgress({
      ...params,
      filterType: "progress"
    });
  };

  //Get user subscription when page refreshes (first time get is in userAcess.js)
  getUserSubscription = (token, userId) => {
    const params = {
      auth_token: token,
      user_id: userId,
      combo_app_id: configuration.app_id
    };
    if (
      !(
        configuration.is_simulator &&
        checkCorrectPlatform([PLATFORM_HTML_SIMULATOR])
      )
    )
      this.props.getUserSubscriptionDetails(params);
  };
  //Get user metering when page refreshes (first time get is in userAcess.js)
  getUserMetering = (token, userId) => {
    const params = {
      auth_token: token,
      user_id: userId
    };
    if (
      !(
        configuration.is_simulator &&
        checkCorrectPlatform([PLATFORM_HTML_SIMULATOR])
      )
    )
      this.props.getUserMeteringDetails(params);
  };
  /**
   * Adding Primary and Secondary Fonts (If present) that are coming from feed into HTML head and load it using webfont.
   */
  loadFonts = (fonts) => {
    const styleTag = generateFontStyleTagAndFontNames(fonts);
    document.getElementsByTagName("head")[0].appendChild(styleTag.style);
    WebFont.load({
      custom: {
        families: [...styleTag.fontNames]
      }
    });
  };

  userClickEvent() {
    this.props.userEngaged();
  }

  getBorderClassName() {
    let pClasses = "";
    const extras = this.props.feeds.extras;
    if (
      extras &&
      !extras.shouldShowCornerRadius &&
      extras.shouldShowCornerRadius !== undefined
    ) {
      pClasses = " " + "hide-corner-radius";
    }
    return pClasses;
  }

  getRTLClass(feeds) {
    if(supportingToRTL(feeds)) return "right-to-left";
    return "";
  }

  getParentClassNameUsingPlatform() {
    if (
      configuration.is_simulator &&
      checkCorrectPlatform([PLATFORM_HTML_SIMULATOR])
    ) {
      return "simulator-route-container";
    }
    if (checkCorrectPlatform([PLATFORM_WEB, PLATFORM_WEB_PREVIEW])) {
      if (isMobile) {
        return "web-route-container web-route-container-mobile";
      }
      if (checkCorrectPlatform([PLATFORM_WEB_PREVIEW])) {
        return "web-route-container web-preview";
      }
      return "web-route-container";
    }
    if (checkCorrectPlatform([PLATFORM_LG]))
      return "simulator-route-container ctv-container lg-container";
    if (checkCorrectPlatform([PLATFORM_SAMSUNG]))
      return "simulator-route-container ctv-container samsung-container";
    if (checkCorrectPlatform([PLATFORM_VIZIO]))
      return "simulator-route-container ctv-container vizio-container";
    if (checkCorrectPlatform([PLATFORM_XBOX]))
      return "simulator-route-container ctv-container xbox-container";
    if (checkCorrectPlatform([PLATFORM_ZEASN]))
      return "simulator-route-container ctv-container zeasn-container"; // FOR now using LG css
  }

  onGeofenceButtonClick = (getLocation) => {
    let defaultCountry =
      (this.props.feeds.defaultCountry &&
        this.props.feeds.defaultCountry.toUpperCase()) ||
      DEFAULT_GEO_LOCALTION.toUpperCase();
    if (!getLocation) {
      this.setState({
        openGeofenceModal: false
      });
      setGeoFenceInLocal(true, defaultCountry);
      this.props.setGeolocation(defaultCountry);
      //Save in localstorage
    } else {
      this.props.getLocationFromIP(defaultCountry);
    }
  };

  renderToast() {
    if (this.props.isUserOnline) {
      setTimeout(() => {
        this.setState({ show_toast: false });
      }, 3000);

      return <Toast type={"success"} msg={"Internet Connected"} />;
    } else {
      return <Toast type={"error"} msg={"Internet Disconnected!!"} />;
    }
  }

  renderSponsorScreen() {
    return (
      this.props.feeds.text != 1 &&
      this.props.feeds.sponsor &&
      this.state.launchScreen && (
        <SponsorScreen
          sponsor={this.props.feeds.sponsor}
          onTimeout={() => this.onSponsorTimeout()}
          runningOnMobile={isMobile}
        />
      )
    );
  }

  renderSessionExpired() {
    return (
      <div className="preview-session-expired">
        <p>Your session has expired</p>
      </div>
    );
  }

  renderPoweredByScreen() {
    return <PoweredByScreen runningOnMobile={isMobile} />;
  }

  defaultRenderComp = () => {
    const {
      feeds,
      geoLoading,
      onBoardingCompleted,
      oneFeedLoading,
      app_id,
      userCountry,
      isGeofencingOn,
      background,
      feedPolicyRejected
    } = this.props;

    if(feedPolicyRejected){
      return <PolicyError />
    }

    if (
      feeds.text == 1
    ) {
      return this.renderPoweredByScreen();
    }

    if (!checkFeedIntegrity(feeds)) {
      return <FeedError />;
    }

    if (this.state.openGeofenceModal && feeds.isGeoFence) {
      this.onGeofenceButtonClick(true)
      return
      // return (
      //   <GeofenceConfirm
      //     onButtonPress={this.onGeofenceButtonClick}
      //     isLoading={geoLoading}
      //   />
      // );
    } else if (feeds.onboardingMetadata && !onBoardingCompleted) {
      const classNames =
        this.getParentClassNameUsingPlatform() + "" + this.getBorderClassName();
      return (
        <div className={classNames}>
          <OnBoarding
            onboardingMetadata={feeds.onboardingMetadata}
            feeds={feeds}
            onboardingCompleted={() => this.onboardingCompleted()}
            runningOnMobile={isMobile}
          />
        </div>
      );
    } else {
      const show2by3poster = is2by3PosterImage(feeds)
      const twoByThreePosterClass = show2by3poster ? "two-by-three-poster" : "";
      const classNames =
        `${this.getParentClassNameUsingPlatform()} ${this.getBorderClassName()} ${twoByThreePosterClass} ${this.getRTLClass(feeds)}`;
      return (
        <Router>
          <div className={classNames}>
            {isSmartTv() && this.state.show_toast ? this.renderToast() : null}
            {checkCorrectPlatform([PLATFORM_WEB_PREVIEW]) && <PreviewMode />}
            <Modal
              oneFeedLoading={oneFeedLoading}
              feeds={feeds}
              app_id={app_id}
              runningOnMobile={isMobile}
            />
            <NavBar
              oneFeedLoading={oneFeedLoading}
              feeds={feeds}
              app_id={app_id}
              background={background}
              runningOnMobile={isMobile}
              isGeofencingOn={isGeofencingOn}
              userCountry={userCountry}
            />
          </div>
        </Router>
      );
    }
  };

  renderDeviceComp = () => {
    if (this.props.feeds.text == 1) {
      return this.renderPoweredByScreen();
    }
    const classNames = this.getParentClassNameUsingPlatform() + "" + this.getBorderClassName();
    return (
      <div className={classNames + " " + "device-code"}>
        <DeviceCode feeds={this.props.feeds} runningOnMobile={isMobile}/>
      </div>
    )
  };

  render() {
    const DefaultRender = this.defaultRenderComp;
    // Check if got redirected from error page on S3
    if (window.location.search.includes("?paths=")) {
      const redirectTo = window.location.search.replace("?paths=", "");
      return <Redirect to={redirectTo} />;
    }

    if (
      checkCorrectPlatform([PLATFORM_WEB_PREVIEW]) &&
      this.props.previewSessionExpired
    ) {
      return this.renderSessionExpired();
    }

    return (
      <>
        {this.renderSponsorScreen()}
        <Switch>
          <Route exact path={"/pay/*"} component={Pay} />
          <Route exact path={"/password/reset"} component={Reset} />
          <Route exact path={"/device"} component={this.renderDeviceComp} />
          <Route component={DefaultRender} />
        </Switch>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  feeds: state.feeds.feeds,
  app_id: state.feeds.app_id,
  background: state.feeds.background,
  rem_fetched_after_login: state.remember.fetched_after_login,
  oneFeedLoading: state.feeds.oneFeedLoading,
  previewSessionExpired: state.feeds.previewSessionExpired,
  feedPolicyRejected: state.feeds.feedPolicyRejected,
  geoLoading: state.user.isGeoLoading,
  isGeofencingOn: state.user.isGeofencingOn,
  userCountry: state.user.userCountry,
  isUserOnline: state.user.isUserOnline,
  logged_in: state.user.logged_in,
  onBoardingCompleted: state.user.onBoardingCompleted
});

const mapDispatchToProps = {
  getFeeds: getFeeds,
  setUserCookie: setUserCookie,
  getAllRememberSpot: getAllRememberSpot,
  getSavedItems: getSavedItems,
  userEngaged: userEngaged,
  getUserSubscriptionDetails,
  getUserMeteringDetails,
  getUSerFeedHistoryOrProgress,
  getUserIp,
  getUserDeviceID,
  getDeviceModel,
  loginThroughMazTv,
  getLocationFromIP,
  setGeolocation,
  checkVoucherCodeValidity,
  getTvodStreams,
  getTvodSavedItems,
  checkIfUserOnline,
  channgeLocalCaption,
  setOnboardingCompleted,
  setAutoPlay,
  checkIfVizioAccountSupported,
  getParentalLockStatus,
  getUserProfile
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
