import 'videojs-contrib-quality-levels'

export const videojsQualityHelper = (player) => {
    // Below Function is for disabling the audio playlist of videos.
    // Note: Audio Playlist has undefined height
    try {
        let qualityLevels = player.qualityLevels();

        qualityLevels.on('addqualitylevel', function (event) {
            let qualityLevel = event.qualityLevel;
            if (qualityLevel.height) {
                qualityLevel.enabled = true;
            } else {
                qualityLevel.enabled = false;
            }
        });

    } catch (error) {
        console.log("An Error Occurred!")
    }
}

/**
 * Function to add text tracks event. When new text track get added, event will get called and text track will set to player.
 */
export const addTextTracksEventVideoJs = (player) => {
    if (!player) return;

    player.textTracks().on("addtrack", (e) => {
        const allTracks = player.textTracks();
        let textTrack;
        for (let i = 0; i < allTracks.length; i++) {
            let track = allTracks[i];
            if (["subtitles", "captions"].includes(track.kind) && track.language) {
                if (!textTrack || track.mode == "showing") textTrack = track;
            }
            track.mode = "disabled"; //Hiding all first, because sometime multiple text tracks were visible
        }
        setTimeout(() => {
            if (textTrack) textTrack.mode = "showing";
        }, 0)
    });
};

/**
 * Function to enable and disable text track based on mode
 */
export const updateTextTrack = (player, mode="showing") => {
    if (!player) return;

    const allTracks = player.textTracks();
    let textTrack;
    for (let i = 0; i < allTracks.length; i++) {
        let track = allTracks[i];
        track.mode = "hidden"; //Hiding all track first.
        if (track.kind == "metadata") continue;
        if (track.src) textTrack = track; //If track added through code, priortize that first.
        if (["subtitles", "captions"].includes(track.kind) && track.language && !textTrack) {
            textTrack = track;
        }
    }
    setTimeout(() => {
        if (textTrack && mode == "showing") textTrack.mode = "showing";
    }, 0)
}