import React, { useRef } from "react"
import { withRouter } from "react-router-dom";
import config from "../../constants/config";

function StripePage(props) {
  const sessionId = props.match.params.sessionId;
  let intervalObj = useRef(null);

  React.useEffect(() => {
    intervalObj.current = setInterval(() => {
      if(window.Stripe && sessionId) {
        stripeCheckout(sessionId);
        clearInterval(intervalObj.current);
        intervalObj.current = null;
      }
    }, 1000)

    return () => {
      intervalObj.current && clearInterval(intervalObj.current);
    }
  }, []);

  function stripeCheckout(sessionId) {
    try {
      const stripeObj = getStripeObject();
      stripeObj.redirectToCheckout({
          sessionId: sessionId
      }).then(function (result) {
          console.log(result);
      })
    }
    catch(e) {
      props.history.push("/pay/failure");
    }
  }

  function getStripeObject() {
    const Stripe = window.Stripe;
    let stripeObj = null;
    if (Stripe && config.stripe_public_key && config.stripe_connect_id) {
        stripeObj = Stripe(config.stripe_public_key,
            {stripeAccount: config.stripe_connect_id});
    }
    return stripeObj;
  }

  return (
    <></>
  );
}

export default withRouter(StripePage);
