import React, { useCallback, useEffect } from "react";
import { useRef } from "react";
import ReactFocusLock from "react-focus-lock";
import { useSelector, useDispatch } from 'react-redux'
import { clearPendingPurchase } from "../actions/subscriptionAction";
import { createUserSubscription } from "../actions/userActions";
import { clearVizioPurchase, resetVizioError, startVizioTvAccountLink, startVizioTvPurchase, vizioPaymentModalClose, vizioPaymentModalOpen } from "../actions/vizioAccountActions";
import configuration from "../constants/config";
import '../css/vizioAccountContainer.css'
import { getAllConstants } from "../helperfunctions/regionalization";
import Loader from "./Loader";

const VizioAccountContainer = (props) => {
    const { planCode } = props
    const dispatch = useDispatch()
    const { vizioAccount, subscription, feeds } = useSelector(({ vizioAccount, subscription, feeds }) => {
        return {
            vizioAccount: vizioAccount,
            subscription: subscription,
            feeds: feeds
        }
    })
    const { isVizioTvLinked, plans, isVizioPurchaseSuccess, isLoading, hasError, purchasePlan } = vizioAccount
    const { purchase } = subscription
    const { policyData } = feeds
    const confirmRef = useRef()
    const ALL_C = getAllConstants()

    useEffect(() => {
        //Triggering the Purchase Function if TV is linked and there is no error on
        if (isVizioTvLinked && plans && !hasError) {
            startPurchase({ planCode })
        }
    }, [isVizioTvLinked, plans])

    useEffect(() => {
        //Focusing confirm button on Component Mounting
        setTimeout(() => {
            confirmRef.current && confirmRef.current.focus()
        }, 0)
        
        dispatch(vizioPaymentModalOpen())
        return () => {
            //Clearing Vizio Account State on Component unmounting.
            if (hasError) dispatch(resetVizioError())
            if (isVizioPurchaseSuccess) dispatch(clearVizioPurchase())
            dispatch(vizioPaymentModalClose())
            dispatch(clearPendingPurchase())
        }
    }, [])


    const startPurchase = ({ planCode }) => {
        //Dispatching Vizio Purchase Action on button Click
        dispatch(startVizioTvPurchase({ planCode }))
    }

    const getPostDataDetails = ({ purchasePlan }) => {
        //This Object will be used to Create Entitlement
        return {
            partner_id: configuration.vizio_partner_id,
            platform: "vizio",
            transaction_id: purchasePlan.subscriptionId,
            customer_id: purchasePlan.customerId,
            external_id: planCode, // Invoke puchase plan
            cid: purchase.details.cid,
            bundle_token: purchase.details.bundleToken,
            product_id: purchase.details.product_id, //identifieer will get freed
            language: policyData.language,
            locale_id: policyData.locale_id,
        }
    }

    useEffect(() => {
        if (isVizioPurchaseSuccess) {
            dispatch(createUserSubscription(getPostDataDetails({ purchasePlan })))
            handleClose()
        }
    }, [isVizioPurchaseSuccess])

    const handleConfirm = () => {
        if (isVizioTvLinked) {
            startPurchase({ planCode })
        } else {
            dispatch(startVizioTvAccountLink())
        }
    }

    const handleClose = () => {
        props.onClose()
    }

    const handleUserKeyPress = useCallback(event => {
        const { keyCode } = event;
        if(keyCode === 8 && !isLoading){
            dispatch(vizioPaymentModalClose())
        }
    }, [isLoading]);

    useEffect(() => {
        if(isLoading) window.removeEventListener("keydown", handleUserKeyPress);
        else window.addEventListener("keydown", handleUserKeyPress);
        return () => {
            window.removeEventListener("keydown", handleUserKeyPress);
        };
    }, [handleUserKeyPress, isLoading]);

    const handleClick = e => {
        e.preventDefault()
        if (e.target.type !== 'submit') return
        const value = e.target && e.target.value
        switch (value) {
            case 'cancel':
                handleClose()
                break;
            case 'confirm':
                !hasError ? handleConfirm() : props.onVizioSkip()
                break;
            case 'later':
                hasError ? handleConfirm() : props.onVizioSkip()
                break;
            default:
                break;
        }
    }

    return <ReactFocusLock>
        <div id='vizio-account-container'>
            {isLoading || isVizioPurchaseSuccess
                ?
                <>
                    <div className="vizio-account-container" id="vizio-account-container-loader">
                        <Loader loaderText={ALL_C.kCtvPaymentTextDoNotBackPress} />
                    </div>
                </>
                :
                <div className="vizio-account-container" id="vizio-account-container-modal">
                    <p className="title">{!hasError ? ALL_C.kVizioSetup : ALL_C.kVizioNA}</p>
                    <p className="subtitle" id="vizio-modal-subtitle" aria-live="polite" aria-atomic="true">{!hasError ? ALL_C.kVizioSetupDesc : ALL_C.kVizioNADesc}</p>
                    <div className="btn-container" onClick={handleClick} aria-describedby="vizio-modal-subtitle">
                        <button className="focusable" value={'cancel'} aria-label={ALL_C.kCancel} role="button">{ALL_C.kCancel}</button>
                        <button className="focusable" value={'later'} aria-label={ALL_C.kNotNow} role="button">{ALL_C.kNotNow}</button>
                        <button className="focusable" autoFocus data-autofocus value={'confirm'} aria-label={ALL_C.kConfirm} role="button" ref={confirmRef}>{ALL_C.kConfirm}</button>
                    </div>
                </div>
            }
        </div>
    </ReactFocusLock>
}

export default VizioAccountContainer;