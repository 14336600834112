import React from 'react';
import { withRouter } from 'react-router-dom'
import {Link} from 'react-router-dom'
import Swiper from 'react-id-swiper'
import { connect } from 'react-redux'

import { openModal } from "../actions/modalActions"
import { httpsUrl } from "../constants/common"
import isAvailable from '../helperfunctions/is_available'
import Metering from './Metering';
import { backButtonObj } from '../helperfunctions';
import backMobile from "../images/icons/back-mobile.png";

function BackButton(feeds, section_id){
  return backButtonObj(feeds, section_id).pathLocation;
}


function NextImage(all_contents, index){
  var content;
  var nextIndex;
  for(var i=index+1; i<all_contents.length; i++){
    if(all_contents[i].type === "image"){
      content = all_contents[i]
      nextIndex = i
      break;
    }
  }
  return [content, nextIndex]
}

function ChangeRoute(identifier){
  var currentLocation = window.location.pathname.split('/')
  currentLocation = currentLocation.slice(0,-1)
  currentLocation.push(identifier)
  currentLocation = currentLocation.join("/")
  return currentLocation
}

function FindImage(identifier, all_contents){
  var imageIndex = 0;
  for(var i=0; i<all_contents.length; i++){
    if(identifier === all_contents[i].identifier){
      break;
    }
    if (all_contents[i].type === "image")
      imageIndex++;
  }
  return imageIndex;
}

function ParentSection(feeds,section_id){
  var sections = feeds.sections
  var content;
  var pathLocation = section_id.split("/")
  for(var i=0; i<pathLocation.length - 1; i++){
    for(var j=0; j<sections.length; j++){
      if(sections[j].identifier === pathLocation[i]){
        content = sections[j]
        if(sections[j].contents) {
          sections = sections[j].contents;
        }
      }
    }
  }
  return content
}


class FullImage extends React.Component {
  constructor(props){
    super(props)
    let imageIndex = FindImage(props.section.identifier, props.all_contents)
    let nextImageData = NextImage(props.all_contents, imageIndex)
    this.state = {
      all_contents: props.all_contents,
      index: imageIndex,
      section: props.section,
      next_section: nextImageData[0],
      next_index: nextImageData[1],
      next_image: false,
      currentView: '',
      parent_section: ParentSection(props.feeds, props.section_id)
    }
  }

  onLinkCLick(route){
    document.body.classList.remove("noNav")
    this.setState({ currentView: route })
    this.props.callbackParent(route);
  }

  componentDidMount(){
    document.body.className = "image-scroll noNav";
  }

  componentDidUpdate(){
    document.body.className = "image-scroll noNav";
  }

  setNextSlide(){
    window.history.replaceState("new slide", {}, ChangeRoute(document.getElementsByClassName('image-full swiper-slide-active')[0].getAttribute("data-identifier")))
  }

  onFreeContentOver() {
    //
  }

  render(){
    const params = {
      initialSlide: FindImage(this.props.section.identifier, this.props.all_contents),
      nextButton: '.swiper-button-next',
      prevButton: '.swiper-button-prev',
      keyboardControl: true
    }

    const contents = []
    this.state.all_contents.map(function(content, i){
      if (content.type === "image"){
        contents.push(
          <div key={i} className='swiper-slide image-full' style={{backgroundImage: 'url(' + httpsUrl(content.contentUrl) + ')', backgroundSize: 'cover'}} data-identifier={content.identifier}></div>
        )
      }
    })

    const runningOnMobile = this.props.runningOnMobile;

    return (
      <div className="image-view">
        <span className="icon-hollow_arrow">
          <Link className={(runningOnMobile ? "backBtn-mobile" : "backBtn")} style={{zIndex: 100}} to={"/apps/" + this.props.app_id + "/" + BackButton(this.props.feeds, this.props.section_id)} onClick={(route) => this.onLinkCLick(this.state.section.identifier)}>
            {runningOnMobile && <img src={backMobile} alt="Back"/>}
          </Link>
        </span>
        <Swiper initialSlide={params.initialSlide} onSlideChangeEnd={() => this.setNextSlide()} nextButton={params.nextButton} prevButton={params.prevButton} keyboardControl={params.keyboardControl}>{contents}</Swiper>
        {((!isAvailable(this.state.parent_section.identifier,this.state.parent_section.special,this.state.parent_section.registerWall) && 
            this.state.parent_section.locked)) && 
          <Metering
            content_id={this.props.section.identifier}
            parent_id={this.state.parent_section.identifier}
            contentAccess={this.state.parent_section.access}
            feeds={this.props.feeds}
            onFreeContentOver={() => this.onFreeContentOver()}
            playing_Ad={false}
            showMetering={true}
            content={this.state.parent_section}
            modalHead={this.props.history.action && this.props.history.action}
          />
        }
      </div>
    )
  }
}

const mapDispatchToProps = {
  openModal: openModal
}

export default withRouter(connect(null, mapDispatchToProps)(FullImage));