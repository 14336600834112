import React from 'react';
import { connect } from "react-redux";
import axios from "axios";
import { withRouter } from 'react-router-dom';
import { elementScrollIntoView } from "seamless-scroll-polyfill";
import queryString from 'query-string';

import store from "../store"
import { getTvodApiHeaders, getTvodAPIKey } from '../constants/initializer';
import * as APIS from "../constants/api";
import { openModal, closeModal } from '../actions/modalActions';
import { canPlayVideoCheckFailed, resetTvodMaxStream } from '../actions/streamActions';
import { addTvodAPIRegionalisation, checkCorrectPlatform, checkLoginUIMetaData, formatItemDuration, getCurrentPlatform, getCurrentPlatformTvod, getDateTimeForTvodInterstitial, getDateWithoutHyphen, getPlaylistVideoCounter, getTabIndex, isSmartTv } from '../helperfunctions/common';
import { backButtonObj, getCustomUIColors, getLogoFromLoginOrFeed, getTvodInterstitalMetaData, getFontStyleFromFeed, getParentFeed, getExtrasParentTitle, checkIfShowingContentOnSectionLevel, maturityCheckFailed } from '../helperfunctions';
import FocusLock from 'react-focus-lock';
import { logoutUserActions } from '../actions/userActions';
import configuration from '../constants/config';
import OverlayVideo from './OverlayVideo';
import FakeLive from './FakeLive';
import LiveSetup from './LiveSetup';
import ContentSectionVideo from './ContentSectionVideo';
import { addRemoveAllNavigation, scrollToTop } from '../helperfunctions/ctvHelper';
import { DEEPLINK, EPG_THEME, EXTRA_CATALOG, hexToRgba, PLATFORM_HTML_SIMULATOR, PLATFORM_LG, PLATFORM_SAMSUNG, PLATFORM_VIZIO, PLATFORM_WEB, PLATFORM_WEB_PREVIEW, PLATFORM_XBOX, PLATFORM_ZEASN, TRAILER_CATALOG } from '../constants/common';
import JsSpatialNavigation from '../ctvnavigation/js-spatial-navigation';
import SaveIcon from "./SaveIcon"
import backMobile from "../images/icons/back-mobile.png";
import LoginFlow from '../components/LoginFlow';
import { startPurchase } from '../actions/subscriptionAction';
import { simulatedTvodPurchase } from '../actions/userActions';
import ErrorDisplay from './ErrorDisplay';
import PreviewVideo from './PreviewVideo';
import { getAllConstants } from '../helperfunctions/regionalization';
import ContentBadges from './ContentBadges';
import ReactFocusLock from 'react-focus-lock';
import TvodInterstitalExtras from './TvodInterstitalExtras';
import Trailer from './icons/Trailer';
import TvodWatchmanBackground from './TvodWatchmanBackground';
import TvodSlickContext from './contexts/TvodSlickContext';
import Loader from './Loader';
import SetParentalLock from './parentalLock/SetParentalLock';
import LinkSection from './LinkSection';

let ALL_C = {};

const COUNTER = 10;
const PAGES = {
  main: "main",
  morePurchase: "morePurchase",
  moreWays: "moreWays",
  details: "details",
  login: "login",
  message: "message",
  ctvPolicy: "ctvPolicy",
  innerInterstitial: "innerInterstitial",
  linkSection: "linkSection",
  pinCode: "pinCode",
  unAuthExternalMessage: "unAuthExternalMessage",
  contentUnavailable: "contentUnavailable"
}

class TvodWatchman extends React.Component {
  constructor(props) {
    super(props);
    this.getAllInterstitialData();
    const prevData = this.getPrevPurchaseData();
    this.state = {
      canPlayVideo: false,
      streamCounterUpdate: null,
      emailSelected: false,
      saveAfterLogin: false,
      playAfterLogin: false,
      forceUpdateKey: new Date().getTime(),
      playAfterStreamFetched: false,
      autoPlayInAction: null,
      modifiedContentUrl: null,
      pages: prevData ? prevData.prev_pages : [PAGES.main],
      detailsSub: prevData && prevData.subDetails ? prevData.subDetails : null,
      contentUnavailableMessage: null,
      currentSection: props.section
    }
    this.primaryColor = props.feeds.primaryColor ? props.feeds.primaryColor.replace('#FF', '#') : "#000";
    this.secondaryColor = props.feeds.secondaryColor ? props.feeds.secondaryColor.replace('#FF', '#') : "#fff";
    this.streamCounter = 0;
    this.streamInterval = null;
    this.navTimer = null;
    this.slickFocusedItem = 0;
    this.lastFocusedItem = null;
    this.canShowInstructionText = false;
    this.sectionAutoStreamed = false;
    this.autoPlayedWithInterstitialKey = false;
    this.interstitialOff = !props.section.showInterstitial;
    this.showInterstitialOnBack = props.section.showInterstitial && props.feeds.showInterstitialOnBack;
    this.isDirectlyPlayable = this.checkIfDirectlyPlayable();
    this.isSectionLevel = checkIfShowingContentOnSectionLevel(props.section , props.feeds);
    this.handleRemoteClick = this.handleRemoteClick.bind(this);
    ALL_C = getAllConstants();
  }

  componentDidMount() {
    if(isSmartTv() && configuration.use_light_sliders) {
      this.sn_id = addRemoveAllNavigation({ selector: '.tvod-interstitial .focusable' });
      JsSpatialNavigation.focus(this.sn_id);
    }

    if(this.isDirectlyPlayable) {
      this.checkIfCanPlay();
    }
    this.handleCasesOnMount()
    if(isSmartTv()) {
      document.addEventListener('keydown', this.handleRemoteClick);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.logged_in !== this.props.logged_in && !this.props.logged_in) {
      this.addNewView(PAGES.login);
      this.setState({canPlayVideo: false})
    }
    if(prevProps.logged_in !== this.props.logged_in && this.props.logged_in) {
      // Logged in successfully
      this.goBack({afterLogin: true});
    }
    if(prevProps.streamingAllowed !== this.props.streamingAllowed && !this.props.streamingAllowed) {
      if(this.streamInterval) {
        clearInterval(this.streamInterval);
        this.streamInterval = null;
      }
      window.history.replaceState("new video", {}, `${window.location.pathname}?mode=details`)
    }
    if(prevState.canPlayVideo !== this.state.canPlayVideo && this.state.canPlayVideo) {
      this.streamInterval = setInterval(() => this.watchStreamCounter(), 1000);
      if(this.ownLockedContent && this.ownLockedContent.endsAt) {
        // Any type of purchase : Check if user doesn't play beyond end date
        this.playerChecktimer = setInterval(() => {
          this.handlePlayerChecktimer(this.ownLockedContent.endsAt);
        }, 5000) // every 5 seconds
      }
    }
    if(prevProps.tvod_purchased && this.props.tvod_purchased && Object.keys(prevProps.tvod_purchased).length !== Object.keys(this.props.tvod_purchased).length) {
      this.getAllInterstitialData(true);
    }

    // focus on first focusable element in interstitial screen
    // "sn_id" set when component mount
    if (prevState.pages !== this.state.pages) {
      const pressingBack = prevState.pages.length > this.state.pages.length;
      this.handleFocusOnPageChange(pressingBack);
    }
  }

  componentWillUnmount() {
    this.handlePlayingClass();
    if(this.streamInterval) {
      clearInterval(this.streamInterval);
      this.streamInterval = null;
    }
    if(isSmartTv() && configuration.use_light_sliders) {
      addRemoveAllNavigation({ id: this.sn_id });
    }
    if(this.playerChecktimer) {
      clearInterval(this.playerChecktimer);
      this.playerChecktimer = null;
    }
    if(this.eventWatchTimer) {
      clearInterval(this.eventWatchTimer);
      this.eventWatchTimer = null;
    }
    if(this.navTimer) {
      clearTimeout(this.navTimer);
      this.navTimer = null;
    }
    if(isSmartTv()) {
      document.removeEventListener('keydown', this.handleRemoteClick);
    }
    const previewBar = document.getElementById("preview-bar");
    previewBar && previewBar.classList.remove("preview-z-index")
  }

  isFromPurchase() {
    try {
      const query = queryString.parse(window.location.search);
      if(query && query.justpurchased) return true;
      return false;
    } catch {
      return false
    }
  }

  canLiveEventPlay(section){
    if(section && section.access && section.access.endsAt && section.access.startsAt){
      if(getDateWithoutHyphen(section.access.startsAt) > new Date()) return false
      if(getDateWithoutHyphen(section.access.endsAt) < new Date()) return false
    }
    return true
  }

  checkIfDirectlyPlayable() {
    const { modal, section } = this.props;
    if(section && section.catalog === TRAILER_CATALOG) return true; // Trailer
    if(section.type === "live" && !this.canLiveEventPlay(section)) return false //Live Event Check
    if(modal && (modal.head === EPG_THEME || modal.firstEpisodePlay)) return true; // EPG & Series first play
    if(!this.lockedContentData) { // Item is available
      if(section.sectionAutoStream || (modal && modal.autoPlayingSectionPlaylist)) {  // Stream on entry
        this.canShowInstructionText = true;
        this.sectionAutoStreamed = true;
        return true
      }
      if((this.interstitialOff || this.showInterstitialOnBack) && !this.isFromPurchase()) { // interstitial is off 
        this.autoPlayedWithInterstitialKey = true
        return true
      }
    }
    return false;
  }

  handlePlayingClass(add) {
    const el = document.getElementById("tvod-overlay");
    if(!el || !this.isSectionLevel) return;

    if(add) {
      el.classList.add("playing");
    } else {
      el.classList.remove("playing");
    }
  }

  handleCasesOnMount() {
    const startPlayer = (window.location.search === "?mode=watch");
    this.changeWindowUrl(startPlayer);
    if(this.ownLockedContent) {
      const isEvent = this.ownLockedContent.type === "events";
      let startTimer = isEvent && new Date(this.ownLockedContent.startsAt) > new Date();
      if(startTimer) {
        //commenting for now. will need this timer again
        // this.eventWatchTimer = setInterval(() => {this.handleEventWatchTimer()}, 1000); // every seconds
      }
      const isEventEnded = isEvent && new Date(this.ownLockedContent.endsAt) < new Date();
      //play if mode=watch 
      if(startPlayer && !startTimer && !isEventEnded && !this.isDirectlyPlayable) {
        this.checkIfCanPlay();
      }
    }
  }

  handleFocusOnPageChange(pressingBack) {
    if(!(isSmartTv() && configuration.use_light_sliders)) return;

    if(pressingBack && this.lastFocusedItem && this.lastFocusedItem.id) {
      // setTimeout(() => {
        this.slickFocusedItem = this.lastFocusedItem.index;
        setTimeout(() => {
          const el = document.querySelector(`.tvod-interstitial .item-${this.lastFocusedItem.id} .focusable`);
          if(el) {
            // el.focus();
            JsSpatialNavigation.focus(el);

            elementScrollIntoView(el, { behavior: 'auto', block: 'center' });
            this.lastFocusedItem = null;
          }
        }, 1000)
      // })
    } else if(this.sn_id) {
      JsSpatialNavigation.focus(this.sn_id);
    }
  }

  getPrevPurchaseData() {
    // in case of purchase fails/back button show exact page from where purchase triggered
    try {
      const query = queryString.parse(this.props.location.search);
      if(query.prev_purchase && this.lockedContentData) {
        const prev_pages = query.prev_purchase.split(",");
        let subDetails = null
        if(query.sub_id && this.lockedContentData.subscriptions && this.lockedContentData.subscriptions.length > 0) {
          //find sub to show details
          for(let i=0; i < this.lockedContentData.subscriptions.length; i++) {
            if(this.lockedContentData.subscriptions[i].identifier === query.sub_id) {
              subDetails = this.lockedContentData.subscriptions[i];
              break;
            }
          }
        }
        return { prev_pages, subDetails}
      }
      return null;
    } catch {
      return null;
    }
  }

  getAllInterstitialData(reset) {
    const { lockedContentData, ownLockedContent, moreWays } = getTvodInterstitalMetaData(this.props, this.getPlatform())
    this.moreWays = moreWays;
    this.ownLockedContent = ownLockedContent;
    this.lockedContentData = lockedContentData;
    const lastPage = this.state && this.state.pages[this.state.pages.length - 1];
    if(reset && lastPage !== PAGES.pinCode) {
      this.setState({forceUpdateKey: new Date().getTime()})
      if(this.ownLockedContent) {
        this.addNewView(PAGES.main, true)
      }
    }
  }

  handlePlayerChecktimer(endDate) {
    if(this.state.playingTrailer || this.state.innerSection) return;

    if(new Date() > new Date(endDate)) {
      // get intertital data again
      this.getAllInterstitialData();
      if(!this.ownLockedContent || (
        this.ownLockedContent.type === "events" && new Date(this.ownLockedContent.endsAt) < new Date()
      )) {
        // Not free now or event has ended
        this.setState({canPlayVideo: false})
        if(this.playerChecktimer) {
          clearInterval(this.playerChecktimer);
          this.playerChecktimer = null;
        }
        if(this.streamInterval) {
          clearInterval(this.streamInterval);
          this.streamInterval = null;
        }
      }
    }
  }

  handleEventWatchTimer() {
    const start = this.ownLockedContent.startsAt;
    if(new Date() >= new Date(start)) {
      this.checkIfCanPlay();
      if(this.eventWatchTimer) {
        clearInterval(this.eventWatchTimer);
        this.eventWatchTimer = null;
      }
    }
  }

  validateNextVideo(newItem) {
    const props = {...this.props, section: newItem.newSection};
    const { lockedContentData, ownLockedContent, moreWays } = getTvodInterstitalMetaData(props, this.getPlatform())
    this.setState({currentSection: newItem.newSection});
    this.lockedContentData = lockedContentData;
    this.ownLockedContent = ownLockedContent;
    this.moreWays = moreWays;
    this.interstitialOff = !newItem.newSection.showInterstitial;
    this.showInterstitialOnBack = newItem.newSection.showInterstitial && this.props.feeds.showInterstitialOnBack;
    if(!ownLockedContent) {
      this.autoPlayedWithInterstitialKey = false;
      this.setState({canPlayVideo: false});
      let currentLocation = window.location.pathname.split('/');
      currentLocation = currentLocation.slice(0,-1);
      currentLocation.push(newItem.newSection.identifier);
      currentLocation = currentLocation.join("/");
      window.history.replaceState("new video", {}, `${currentLocation}?mode=details`)
    } else {
      this.checkIfCanPlay(newItem);
    }
  }

  getPlatform() {
    let platform = getCurrentPlatform();
    if(platform === PLATFORM_WEB) platform = "web";
    if(platform === PLATFORM_XBOX) platform = "microsoft";
    return platform;
  }

  getDurationText(duration) {
    if(duration == 31) return `1 ${ALL_C.kISMonth}`;
    else if(duration == 93) return `3 ${ALL_C.kISMonths}`;
    else if(duration == 186) return `6 ${ALL_C.kISMonths}`;
    else if(duration == 366) return ALL_C.kISYear;
    else return null
  }

  getFreeDurationText(duration) {
    if(duration == 7) return `1 ${ALL_C.kISWeeks}`;
    else if(duration == 31 || duration == 30) return `1 ${ALL_C.kISMonth}`;
    else if(duration == 62 || duration == 60) return `2 ${ALL_C.kISMonths}`;
    else if(duration == 93 || duration == 90) return `3 ${ALL_C.kISMonths}`;
    else return null
  }

  showCTVPolicyModal = (type, url) => {
    this.setState({ctvPolicy: {url, type}});
    this.addNewView(PAGES.ctvPolicy);
  }

  livePlayedFromMenu = () => {
    return ((this.props.section.type === 'live' || this.props.type === "fake") && !this.props.modal.openLiveModalDirectly)
  }

  updateEmailSelected() {
    this.setState({emailSelected: true})
  }

  watchStreamCounter() {
    this.streamCounter = this.streamCounter + 1;
    if(this.streamCounter === COUNTER) {
      this.setState({streamCounterUpdate: new Date().getTime()})
      this.streamCounter = 0;
    }
  }

  changeWindowUrl(startPlayer) { 
    if(this.props.modal.deepLink === DEEPLINK || this.livePlayedFromMenu()) {
      if(!startPlayer || !this.ownLockedContent) {
        window.history.replaceState("new video", {}, `${window.location.pathname}?mode=details`)
      }
    } else {
      let url = null;
      const path = window.location.pathname.split("/");
      const {head, identifier} = this.props.modal;
      if(path[path.length - 1] == head) {
        url = `${window.location.pathname}/${identifier}`
      } else {
        url = `${window.location.pathname}/${head}/${identifier}`
      }
      if(!startPlayer || url !== window.location.pathname) {
        window.history.pushState("new video", {}, `${url}?mode=details`)
      }
    }
  }

  dissmissAll() {
    this.props.resetTvodMaxStream();
    if(this.isSectionLevel) return; // NOT for section level content
    if(this.props.isVizioPaymentModalOpen) return; //If Vizio Payment Modal is Open, Do not Go Back

    this.props.closeModal();

    if (this.props.modal.deepLink === DEEPLINK || (this.props.type !== "video" && this.props.modal.head !== "search")) {
      const backUrlObj = backButtonObj(this.props.feeds, window.location.pathname.split('/').slice(3).join('/'));
      this.props.history.replace(backUrlObj.backPath);
      if(this.props.type === "video") {
        this.props.modal.callbackParent(this.props.section.identifier);
      }
      if(this.props.type === "fake" && isSmartTv() && document.getElementById("nav-bar")) {
        document.getElementById("nav-bar").style.display = '';
      }
    } else {
      window.history.replaceState("view", {}, this.props.modal.pathname)
    }
  }

  checkIfCanPlay(newItem, trailer, afterValidation) {
    this.setState({canPlayVideoChecking: true})
    const { allStreamObject, feeds, parentalLock, logged_in, policyData } = this.props;
    const section = this.state.currentSection;
    let sectionCId = section.cid;
    let newItemState = {};
    let sectionToCheck = {...section};
    if(trailer) {
      sectionCId = trailer.cid;
      newItemState = {playingTrailer: trailer};
      sectionToCheck = {...trailer};
    } else if(this.state.innerSection) {
      sectionCId = this.state.innerSection.cid;
      sectionToCheck = {...this.state.innerSection};
    } else if(newItem && newItem.newSection) {
      this.canShowInstructionText = false;
      sectionCId = newItem.newSection.cid;
      newItemState = {autoPlayInAction: newItem}
      sectionToCheck = {...newItem.newSection};
    } else if(section.type === "fake") {
      const index = getPlaylistVideoCounter("fake", section.identifier);
      sectionCId = section.contents[index].cid;
      sectionToCheck = {...section.contents[index]};
    }
    if(!afterValidation && maturityCheckFailed(sectionToCheck, feeds, parentalLock, logged_in, policyData)) {
      const pageToOpen = logged_in ? PAGES.pinCode : PAGES.login;
      const loginStateChange = logged_in ? {} : { pinCodeFlowAfterLogin : true};
      this.addNewView(pageToOpen);
      this.setState({canPlayVideo: false, canPlayVideoChecking: false, pinCodeFlowCallbackParam: {newItem, trailer}, ...loginStateChange});
      return;
    }
    const progress = (this.props.type === "live") ? 100 : ((allStreamObject[sectionCId] && allStreamObject[sectionCId].progress) || 0)
    const data = {"cid": sectionCId, "progress": progress, platform: getCurrentPlatformTvod(), first_play: true};
    let url = APIS.TVOD_STREAMS_API;
    if(!this.props.logged_in) {
      data.key = getTvodAPIKey();
      data.app_id = configuration.app_id;
      url = APIS.TVOD_STREAMS_ANONYMOUS_API;
    }
    axios.post(url, addTvodAPIRegionalisation(data, null, policyData), getTvodApiHeaders())
    .then(result => {
      let modifiedContentUrl  = null;
      let data = result && result.data

      if(data && data.url) {
        modifiedContentUrl  = data.url;
      }
      if(data && data.error) {
        this.setState({canPlayVideo: false, canPlayVideoChecking: false, contentUnavailableMessage: data.error})
        return this.addNewView(PAGES.contentUnavailable)
      }
      this.setState({canPlayVideo: true, canPlayVideoChecking: false, cmsData: data, modifiedContentUrl, ...newItemState })
    }).catch(err => {
      const code = err.response && err.response.status;
      if(code == 401) {
        this.props.logoutUserActions(configuration.app_id)
      } else if(code === 403) {
        const max = err.response.data && err.response.data.max;
        this.props.canPlayVideoCheckFailed(max)
      }
      this.setState({canPlayVideo: false, canPlayVideoChecking: false})
    })
  }

  getPlayerData() {
    let section = {};
    let all_contents = [];
    let keepInterstitialOpen = true;
    let playingExtra = true;
    if(this.state.playingTrailer) {
      section = {...this.state.playingTrailer}
    } else if(this.state.innerSection) {
      section = {...this.state.innerSection}
    } else {
      const { modal } = this.props;
      if(!this.isSectionLevel) {
        keepInterstitialOpen = false;
      }
      if(!this.props.hasUserEngaged){
        //For Streaming on Entry Autoplay
        keepInterstitialOpen = true;
      }
      const noInter = modal && (modal.head === EPG_THEME || modal.firstEpisodePlay)
      if(this.showInterstitialOnBack && this.state.currentSection.catalog !== TRAILER_CATALOG && !noInter) {
        keepInterstitialOpen = true;
      }
      playingExtra = false;
      section = this.state.autoPlayInAction ? {...this.props.section} : {...this.state.currentSection};
      if(modal && modal.all_contents) {
        all_contents = [...modal.all_contents];
      }
    }

    if(this.state.modifiedContentUrl) {
      if(section.type === "fake") {
        const index = this.state.autoPlayInAction ? this.state.autoPlayInAction.newIndex : getPlaylistVideoCounter("fake", section.identifier);
        section.contents[index].contentUrl = this.state.modifiedContentUrl;
        section.contents[index].cmsData = this.state.cmsData;
      } else if(this.state.autoPlayInAction && all_contents.length > 0) {
        all_contents[this.state.autoPlayInAction.newIndex].contentUrl = this.state.modifiedContentUrl;
        all_contents[this.state.autoPlayInAction.newIndex].cmsData = this.state.cmsData;
      } else {
        section.contentUrl = this.state.modifiedContentUrl;
        section.cmsData = this.state.cmsData;
      }
    }
    return { section, all_contents, keepInterstitialOpen, playingExtra };
  }

  handleRemoteClick(e) {
    this.setNavbarTimeout();
  }

  setNavbarTimeout() {
    if(this.navTimer) {
      clearTimeout(this.navTimer);
    }
    this.navTimer = setTimeout(() => {
      const el = document.getElementById("tvod-overlay");
      el && el.classList.remove("zero-z-index");
      const previewBar = document.getElementById("preview-bar");
      previewBar && previewBar.classList.remove("preview-z-index")
    }, 3000)
  }

  handleKeepInterstitialOpen() {
    const wasPlayingTrailer = this.state.playingTrailer;
    if(this.isSectionLevel && !this.showInterstitialOnBack && !wasPlayingTrailer && !this.state.innerSection && !this.sectionAutoStreamed) {
      //playing section level content & interstitial should not be visible at back press
      // show navbar
      const el = document.getElementById("tvod-overlay");
      const previewBar = document.getElementById("preview-bar");
      if(el) {
        el.classList.add("zero-z-index");
        previewBar && previewBar.classList.add("preview-z-index")

        const navBarElement = document.querySelector('#nav-bar .active.focusable:first-child');
        if(navBarElement && document.activeElement != navBarElement && configuration.show_live_controls && this.props.type === "live") navBarElement.focus()
        this.setNavbarTimeout();
      }
      return;
    }
    this.canShowInstructionText = false;
    window.history.replaceState("new video", {}, `${window.location.pathname}?mode=details`)
    this.setState({canPlayVideo: false, playingTrailer: null, autoPlayInAction: null});
    if(wasPlayingTrailer) {
      this.handleFocusOnPageChange(true);
    }
    if(this.state.innerSection && !this.showInterstitialOnBack) {
      this.goBack();
    }
  }

  passToTvodWatchman() {
    this.addNewView(PAGES.login);
    this.setState({saveAfterLogin: true})
  }

  addNewView(view, reset) {
    let pages = [...this.state.pages];
    if(reset) {
      pages = [view];
    } else {
      pages.push(view);
    }
    this.setState({pages: pages})
  }

  goBack(extra) {
    if(this.state.pages.length === 1) { // last page
      this.dissmissAll();
    } else {
      let pages = [...this.state.pages];
      const popped = pages.pop();
      const innerIntState = popped === PAGES.innerInterstitial ? { innerSection: null } : {};
      if(extra && extra.afterLogin && this.state.pinCodeFlowAfterLogin) {
        pages.push(PAGES.pinCode);
      }
      const pinCodeState = (popped === PAGES.login && this.state.pinCodeFlowAfterLogin) ? {pinCodeFlowAfterLogin: null} : {};
      this.setState({pages: pages, emailSelected: false, playAfterLogin: false, ctvPolicy: null, ...pinCodeState, ...innerIntState})
      if(!extra || !extra.afterLogin) {
        this.setState({saveAfterLogin: false})
      }
    }
  }

  manageClick(key, tier, selectedSub) {
    if(key === "subscriptions" && !selectedSub) {
      this.setState({detailsSub: tier})
      this.addNewView(PAGES.details);
    } else {
      if(!this.props.logged_in) {
        this.addNewView(PAGES.login);
        return;
      }
      if(this.props.logged_in && key === "externals"){
        this.addNewView(PAGES.unAuthExternalMessage);
        return
      }
      if(key === "externals") {
        return;
      }
      // do purchase
      const platform = this.getPlatform()
      let detail;
      if(key === "subscriptions") {
        detail = {...tier, ...selectedSub}
      } else {
        detail = tier
      }
      if(checkCorrectPlatform([PLATFORM_HTML_SIMULATOR, PLATFORM_WEB_PREVIEW])) {
        // simulated purchase
        let nDate = new Date();
        this.props.simulatedTvodPurchase({
          bundleToken: detail.bundleToken,
          endsAt: new Date(nDate.setDate(nDate.getDate() + 2)).toString(), // 48 hours
          identifier: detail.identifier,
          isActive: true,
          platform: (platform === "microsoft") ? PLATFORM_XBOX : platform,
          productId: detail.iap[platform].identifier,
          purchaseDate: new Date().toString(),
          startsAt: new Date().toString(),
          type: detail.type
        })
        this.addNewView(PAGES.message);
      } else {
        // real purchase
        this.props.startPurchase({
          tier_id: detail.identifier,
          price: detail.iap[platform].price,
          product_id: detail.iap[platform].identifier,
          title: detail.title,
          type: detail.type,
          bundleToken: detail.bundleToken,
          cid: this.state.currentSection.cid,
          platform: (platform === "microsoft") ? PLATFORM_XBOX : platform,
          itemDetails: detail
        });
        this.props.openModal({ type: "payment", tvodWatchmanModal: {...this.props.modal}, goBack: isSmartTv() ? "tvodInterstitial" : false, prev_purchase: this.state.pages.join(","), sub_id: (this.state.detailsSub && this.state.detailsSub.identifier) || null});
      }
    }
  }

  getPurchaseTitle(key) {
    switch(key) {
      case "subscriptions": return ALL_C.kISSubscribe;
      case "rentals": return ALL_C.kISRent;
      case "events": return ALL_C.kISAttend;
      case "purchases": return ALL_C.kISBuy;
      default: return "";
    }
  }

  handleExtrasClick(video, i) {
    this.lastFocusedItem = {id: video.identifier, index: i};
    if(video.catalog === TRAILER_CATALOG) {
      this.checkIfCanPlay(null, video);
    }else if(video.catalog === "Link") {
      this.addNewView(PAGES.linkSection);
      this.setState({innerSection: video});
    }else {
      // open inner interstital
      this.addNewView(PAGES.innerInterstitial);
      this.setState({innerSection: video});
      if(this.ownLockedContent && (this.interstitialOff || this.showInterstitialOnBack)) {
        this.checkIfCanPlay(null, video);
      }
    }
  }

  renderBackground(lastPage) {
    if(lastPage !== PAGES.main && lastPage !== PAGES.innerInterstitial && this.props.runningOnMobile) return null;
    
    const section = this.state.innerSection  ? this.state.innerSection : this.state.currentSection;
    return <TvodWatchmanBackground section={section} feeds={this.props.feeds} openedWithPayment={this.props.openedWithPayment} runningOnMobile={this.props.runningOnMobile}/>
  }

  renderTitles(section) {
    const fontStyle = getFontStyleFromFeed(this.props.feeds);
    return (
      <div className="titles">
        <span id='watchman-title' className="title" style={fontStyle.primaryFont}>{section.title}</span>
        {section.subtitle && <span id="watchman-subtitle" className="subtitle">{section.subtitle}</span>}
        {this.ownLockedContent && this.ownLockedContent.badge && <span className="badge">{this.ownLockedContent.badge}</span>}
      </div>
    )
  }

  renderMetaData(section) {
    const { duration, rating, copyright, role, genre } = section;
    return (
      <div className="meta-data">
        <div id="watchman-metadata">
          {copyright && <span>{copyright}</span>}
          {duration && <span>{formatItemDuration(duration)}</span>}
          {rating && <span className='upperCase'>{rating}</span>}
          {genre && <span>{genre}</span>}
        </div>
        {role && role.actor && <p>{`${ALL_C.kISStarring}: ${role.actor}`}</p>}
        {role && role.director && <p>{`${role.director.split(",").length > 1 ? ALL_C.kISDirectors : ALL_C.kISDirector}: ${role.director}`}</p>}
      </div>
    )
  }

  renderSaveIcon(secColor, section, priColor) {
    if(this.props.runningOnMobile) return null;
    if(!this.props.feeds.hasSavedSection) return null;
    return (
      <div className="save-icon">
        <SaveIcon 
          title={section.title || ""}   // title of content to read by TTS
          no_color={true} 
          locked={section.locked} 
          store={store} 
          content={section} 
          type={"content"} 
          secondary={secColor} 
          tvodPrimaryColor={priColor}
          feeds={this.props.feeds} 
          layout={'detail'}
          index={1}
          total={1}
          passToTvodWatchman={() => this.passToTvodWatchman()}
          saveAfterLogin={this.state.saveAfterLogin}
        />
      </div>
    )
  }

  renderTrailerButton(section, secButtonStyle) {
    if(!section.trailer) return null;

    if(this.props.runningOnMobile) {
      return (
        <div className='trailer' onClick={() => this.checkIfCanPlay(null, section.trailer)}>
          <Trailer />
          <span className="mt-10">{ALL_C.kTrailerText}</span>
        </div>
      )
    }
    return (
      <span className="focusable" tabIndex="-1" onClick={() => this.checkIfCanPlay(null, section.trailer)} style={secButtonStyle} { ...this.getAriaLabels('play-btn') } onFocus={e => scrollToTop(e) }>
        <span id="play-btn">{ALL_C.kTrailer}</span>
      </span>
    )
  }

  renderButtons(isInnerInt, section) {
    const { priButtonStyle, secButtonStyle, secColor, priTextcolor, priColor } = getCustomUIColors(this.props.feeds);
    let platform = this.getPlatform();
    if(this.lockedContentData) {
      const {rentals, subscriptions, events, purchases, externals} = this.lockedContentData;
      let pastLockedEvent = false;
      let elements = null;
      let moreOption = Object.keys(this.lockedContentData).length > 1
      if(isInnerInt || section.catalog === EXTRA_CATALOG) { // extra from interstitial / from theme
        const title = isInnerInt ? this.state.currentSection.title : getExtrasParentTitle(this.props.feeds, section);
        elements = (
          <span className="focusable" tabIndex="-1" { ...this.getAriaLabels('msg-btn') } onFocus={e => scrollToTop(e) }>
            <span id="msg-btn">{`${ALL_C.kISExtrasLocked} ${title}`}</span>
          </span>
        )
        moreOption = false;
      } else if(externals && externals.length > 0) {
        const defaultExt = externals[0];
        const unauthorizedExternalButtonText = this.props.logged_in ? this.props.feeds?.loginUIMetadata?.tvodLogin?.strings?.unauthorizedExternalButtonText : ""
        elements = (
          <span className="focusable" tabIndex="-1" onClick={() => this.manageClick("externals")} style={priButtonStyle} { ...this.getAriaLabels('watch-with-btn') } onFocus={e => scrollToTop(e) }>
            <span id="watch-with-btn">{unauthorizedExternalButtonText || `${defaultExt.title}`}</span>
          </span>
        )
        moreOption = false;
      } else if(subscriptions && subscriptions.length > 0) {
        const defaultSubs = subscriptions.filter(sub => {
          return sub.isDefault;
        })
        const defaultSub = (defaultSubs && defaultSubs[0]) || subscriptions[0];
        elements = (
          <span className="focusable" tabIndex="-1" onClick={() => this.manageClick("subscriptions", defaultSub)} style={priButtonStyle} { ...this.getAriaLabels('watch-with-btn') } onFocus={e => scrollToTop(e) }>
            <span id="watch-with-btn">{`${ALL_C.kISWatchWith} ${defaultSub.title}`}</span>
          </span>
        )
        moreOption = moreOption || subscriptions.length > 1;
      } else if(purchases && purchases.length > 0) {
        const defaultP = purchases[0];
        elements = (
          <span className="focusable" tabIndex="-1" onClick={() => this.manageClick("purchases", defaultP)} style={priButtonStyle} { ...this.getAriaLabels('watch-with-btn') } onFocus={e => scrollToTop(e) }>
            <span id="watch-with-btn">{`${defaultP.title} $${defaultP.iap[platform].price}`}</span>
          </span>
        )
        moreOption = moreOption || purchases.length > 1;
      } else if(rentals && rentals.length > 0) {
        const defaultR = rentals[0];
        let msg = ""
        if (defaultR.rentalDurationText) {
          msg = defaultR.rentalDurationText
        } else {
          msg = ALL_C.kISExpiresText;
          msg = msg.replace("%s", (defaultR.start ? Math.floor(defaultR.start / 3600) : ""))
          msg = msg.replace("%t", (defaultR.finish ? Math.floor(defaultR.finish / 3600) : ""))
        }
        elements = (
          <span className="focusable" tabIndex="-1" onClick={() => this.manageClick("rentals", defaultR)} style={priButtonStyle} { ...this.getAriaLabels('rental-title rental-expires-in') } onFocus={e => scrollToTop(e) }>
            <span id="rental-title">{`${defaultR.title} $${defaultR.iap[platform].price}`}</span>
            <sub id="rental-expires-in">{msg}</sub>
          </span>
        )
        moreOption = moreOption || rentals.length > 1;
      } else if(events && events.length > 0) {
        const defaultE = events[0];
        pastLockedEvent = (new Date(defaultE.endsAt) < new Date());
        elements = pastLockedEvent ? (
          <span className="focusable" tabIndex="-1" { ...this.getAriaLabels('event-ended') } onFocus={e => scrollToTop(e) }>
            <span id="event-ended">{ALL_C.kISEventEnded}</span>
          </span>
        ) : (
          <span className="focusable" tabIndex="-1" onClick={() => this.manageClick("events", defaultE)} style={priButtonStyle} { ...this.getAriaLabels('event-starts-title event-starts') } onFocus={e => scrollToTop(e) }>
            <span id="event-starts-title">{`${defaultE.title} $${defaultE.iap[platform].price}`}</span>
            <sub id="event-starts">{`${ALL_C.kISEventStarts} ${defaultE.startsAt && getDateTimeForTvodInterstitial(defaultE.startsAt)}`}</sub>
          </span>
        )
        moreOption = moreOption || events.length > 1;
      } else {
        elements = (
          <span className="focusable" tabIndex="-1" onClick={() => this.addNewView(PAGES.moreWays)} style={priButtonStyle} { ...this.getAriaLabels('how-to-watch-btn') } onFocus={e => scrollToTop(e) }>
            <span id="how-to-watch-btn">{ALL_C.kISHowToWatch}</span>
          </span>
        )
        moreOption = false;
      }
      return (
        <div className="item-play">
          {elements}
          {moreOption &&
            <span className="focusable" tabIndex="-1" onClick={() => this.addNewView(PAGES.morePurchase)} style={secButtonStyle} onFocus={e => scrollToTop(e) }>
              <span>{ALL_C.kISMorePurchase} </span>
          </span>
          }
          {!pastLockedEvent && this.renderTrailerButton(section, secButtonStyle)}
          {!pastLockedEvent && this.renderSaveIcon(secColor, section, priColor)}
        </div>
      )
    } else {
      const isEvent = this.ownLockedContent && this.ownLockedContent.type === "events";
      let msg = null;
      if(isEvent && !isInnerInt) {
        if(new Date(this.ownLockedContent.startsAt) > new Date()) {
          msg = `${ALL_C.kISEventStarts}  ${getDateTimeForTvodInterstitial(this.ownLockedContent.startsAt)}`
        } else if(new Date(this.ownLockedContent.endsAt) < new Date()) {
          msg = ALL_C.kISEventEnded;
        }
      }
      return (
        <div className="item-play free">
          {msg 
            ? (
              <span className="focusable" tabIndex="-1" { ...this.getAriaLabels('msg-btn') } onFocus={e => scrollToTop(e) }>
                <span id="msg-btn">{msg}</span>
              </span>
            )
            : (
              <span className="focusable" tabIndex="-1" onClick={() => this.checkIfCanPlay()} style={priButtonStyle} { ...this.getAriaLabels('play-btn') } onFocus={e => scrollToTop(e) }>
                <span id="play-btn">{ALL_C.kISPlay}</span><span className="arrow-right" style={{borderLeftColor: priTextcolor}}></span>
              </span>
            )
          }
          {!msg && this.renderTrailerButton(section, secButtonStyle)}
          {!msg && this.renderSaveIcon(secColor, section, priColor)}
        </div>
      )
    }
  }

  renderBackButton(lastPage) {
    let style = {}
    if(isSmartTv()) {
      style.display = 'none';
    }
    if(this.isSectionLevel && lastPage === PAGES.main) {
      return null;
    }

    return (
    <span className={"icon-hollow_arrow backBtnSpan " + (this.props.runningOnMobile ? "backBtnSpan-mobile" : "")}>
      <a id="modal_back_btn" href="" onClick={(e) => {
          e.preventDefault();
          if(this.autoPlayedWithInterstitialKey) {
            this.dissmissAll();
          } else {
            this.goBack();
          }
        }} 
        className={(this.props.runningOnMobile ? "backBtn-mobile" : "backBtn")}
        style={{zIndex: 100, ...style}}>
          {this.props.runningOnMobile && <img src={backMobile} alt="Back"/>}
      </a>
    </span>)
  }

  renderTier(key, tier, priButtonStyle) {
    const title = this.getPurchaseTitle(key);
    let platform = this.getPlatform();
    return (
      <div>
        <span>{title}</span>
        {tier.map(tierElement => {
          const price = (key === "subscriptions") ? tierElement.metadata[0].iap[platform].price : tierElement.iap[platform].price;
          let msg = ""
          if (tierElement.rentalDurationText) {
            msg = tierElement.rentalDurationText
          } else {
            msg = ALL_C.kISExpiresText;
            msg = msg.replace("%s", (tierElement.start ? Math.floor(tierElement.start / 3600) : ""))
            msg = msg.replace("%t", (tierElement.finish ? Math.floor(tierElement.finish / 3600) : ""))
          }
          return (
            <div key={tierElement.title} 
              className="purchase-button focusable" 
              tabIndex="-1" 
              onClick={() => this.manageClick(key, tierElement)}
              style={priButtonStyle}
            >
              <span>{`${tierElement.title} $${price}`}</span>
              {key === "rentals" &&
                <sub>{msg}</sub>
              }
              {key === "events" &&
                <sub>{`${ALL_C.kISEventStarts} ${tierElement.startsAt && getDateTimeForTvodInterstitial(tierElement.startsAt)}`}</sub>
              }
            </div>
          )
        })}
      </div>
    )
  }

  renderMorePurchases() {
    if(!this.lockedContentData) return null;
    const {rentals, subscriptions, events, purchases} = this.lockedContentData;
    const { priButtonStyle, secButtonStyle } = getCustomUIColors(this.props.feeds);
    return (
      <div className="more-sub-wrapper">
        <div className="more-purchases">
          {subscriptions && this.renderTier("subscriptions", subscriptions, priButtonStyle)}
          {purchases && this.renderTier("purchases", purchases, priButtonStyle)}
          {rentals && this.renderTier("rentals", rentals, priButtonStyle)}
          {events && this.renderTier("events", events, priButtonStyle)}
          {this.moreWays.showMoreWays && <div className="purchase-button extra focusable" tabIndex="-1" onClick={() => this.addNewView(PAGES.moreWays)} style={secButtonStyle}>
            <span>{ALL_C.kISMoreWays}</span>
          </div>}
          {!this.props.logged_in && <div className="purchase-button extra focusable" tabIndex="-1" onClick={() => this.addNewView(PAGES.login)} style={secButtonStyle}>
            <span>{ALL_C.kISSignIn}</span>
          </div>}
        </div>
        { (purchases || rentals || events) &&
          <div className="policy-wrapper">
            {this.diclaimerDiv()}
            {this.termsAndPrivacyPolicyDiv()}
          </div>
        }
      </div>
    )
  }

  diclaimerDiv = () => {
    let configSubText = ALL_C.subscription_terms_text ? ALL_C.subscription_terms_text : configuration.subscription_terms_text;
    let subText = ALL_C.kDisclaimerText;
    return (
      // disclaimer doesn't have focus. so, TTS do not read it
      // "area-live" and "aria-atomic" use so that when content is ready TTS can read disclaimer
      <div className="disclaimer" aria-live="polite" aria-atomic="true">
        {configSubText? configSubText: subText}
      </div>
    )
  }

  termsAndPrivacyPolicyDiv = () => {
    if (isSmartTv()) {
      return (
        <div className="termsOfUse">
          <ul className="mob_info">
            <li><a onClick={() => this.showCTVPolicyModal(ALL_C.kTermsOfServiceText,this.props.feeds.loginUIMetadata.urls.terms)} style={{ padding: '0 10px' }} className="text-white focusable" tabIndex={getTabIndex()}>{ALL_C.kTermsOfServiceText}</a></li>
            <li className="termsVertical">|</li>
            <li><a onClick={() => this.showCTVPolicyModal(ALL_C.kSettingsPrivacyPolicy,this.props.feeds.loginUIMetadata.urls.policy)} style={{ padding: '0 10px' }} className="text-white focusable" tabIndex={getTabIndex()}>{ALL_C.kSettingsPrivacyPolicy}</a></li></ul>
        </div>
      )
    } else {
      return (
        <div className="termsOfUse">
            <ul className="mob_info">
            <li><a href={this.props.feeds.loginUIMetadata.urls.terms} target = "_blank" className="text-white" >{ALL_C.kTermsOfServiceText}</a></li>
            <li className="termsVertical">|</li>
            <li><a href={this.props.feeds.loginUIMetadata.urls.policy} target = "_blank" className="text-white">{ALL_C.kSettingsPrivacyPolicy}</a></li></ul>
        </div>
      )
    }
  }

  renderCTVPolicy() {
    return (
      <div className="ctv-poilcy" role="dialog" aria-labelledby="ctv-policy-heading" aria-describedby="ctv-policy-description">
        <h3 id='ctv-policy-heading'>{ this.state.ctvPolicy.type }</h3>
        <p id='ctv-policy-description'>
          &nbsp; {ALL_C.kISReadVisit} &nbsp; {this.state.ctvPolicy.url }
        </p>
        <ReactFocusLock>
            <div className='center-align' style={{marginTop: '5em'}}>
              <div id="terms-privacy-back-btn" className="focusable" tabIndex="-1" onClick={() => document.getElementById("modal_back_btn").click()} aria-label={'Go back'} aria-describedby="ctv-policy-heading ctv-policy-description">
                Go back
              </div>
            </div>
          </ReactFocusLock>
      </div>
    )
  }

  renderSubDetails() {
    if(!this.state.detailsSub) return null;
    const detailsSub = this.state.detailsSub;
    const { priButtonStyle, secButtonStyle } = getCustomUIColors(this.props.feeds);
    let platform = this.getPlatform();
    return (
      <div className="sub-details">
        <span>{detailsSub.title}</span>
        {detailsSub.metadata.map(meta => {
          const freeText = this.getFreeDurationText(meta.freeTrial);
          return (
            <div key={meta.duration} 
              className="purchase-button focusable" 
              tabIndex="-1" 
              onClick={() => this.manageClick("subscriptions", detailsSub, meta)}
              style={priButtonStyle}
              role="button"
              aria-label={`$${meta.iap[platform].price} per ${this.getDurationText(meta.duration)} | ${freeText ? `Start with a ${freeText} free trial` : ""}`}
            >
              <span>{`$${meta.iap[platform].price} / ${this.getDurationText(meta.duration)}`}</span>
              {freeText && <sub>{ALL_C.kISStartsWith.replace("%s", freeText)}</sub>}
            </div>
          )
        })}
        {!this.props.logged_in && <div className="purchase-button extra focusable" tabIndex="-1" onClick={() => this.addNewView(PAGES.login)} style={secButtonStyle}>
          <span>{ALL_C.kISSubscriberSignIn}</span>
        </div>}
      </div>
    )
  }

  renderMoreWays() {
    return (
      <div className="more-ways">
        <span>{this.props.logged_in ? this.moreWays.signedInMoreWays : this.moreWays.signedOutMoreWays}</span>
      </div>
    )
  }

  renderContentBadge(section) {
    return (
      <div className='tvod-content-badges'>
        <ContentBadges content={section} isInterstitial={true}/>
      </div>
    )
  }

  renderExtras() {
    return (
      <TvodSlickContext.Provider value={this.slickFocusedItem}>
        <TvodInterstitalExtras 
          feeds={this.props.feeds}
          primary={this.primaryColor}
          secondary={this.secondaryColor}
          handleExtrasClick={(video, i) => this.handleExtrasClick(video, i)}
          section={this.state.currentSection}
          runningOnMobile={this.props.runningOnMobile}
        />
      </TvodSlickContext.Provider>
    )
  }

  renderDescription(lastPage) {
    const isInnerInt = lastPage === PAGES.innerInterstitial;
    const section = isInnerInt ? this.state.innerSection : this.state.currentSection;
    return (
      <div className="description" key={lastPage}>
        {
          this.props.runningOnMobile 
          ? <div className="description-wrapper">
              {this.renderTitles(section)}
              {this.renderButtons(isInnerInt, section)}
              {this.renderContentBadge(section)}
              <div id="watchman-summary" className="tvod-summary">
                <span>{section.summary}</span>
              </div>
              {this.renderMetaData(section)}
            </div>
          : <div className="description-wrapper">
              {this.renderTitles(section)}
              {this.renderMetaData(section)}
              {this.renderContentBadge(section)}
              <div id="watchman-summary" className="tvod-summary">
                <span>{section.summary}</span>
              </div>
              {this.renderButtons(isInnerInt, section)}
            </div>
        }
        {!isInnerInt && this.renderExtras()}
      </div>
    )
  }

  renderLogin() {
    const headerLogo = getLogoFromLoginOrFeed(this.props.feeds , "registrationWall");
    const allProps = {
      feeds: checkLoginUIMetaData(this.props.feeds),
      app_id: configuration.app_id,
      background: (this.props.feeds.loginUIMetadata.colors && this.props.feeds.loginUIMetadata.colors.background) || null,
      screen: this.props.modal.screen,
      titleMsg: this.state.saveAfterLogin ? null : ALL_C.kLoginToContinueWatching,
      directLogin: this.props.modal.directLogin
    }
    return (
      <div className="scroll-auto-h-100 login-page">
        <LoginFlow
          emailSelected={this.state.emailSelected}
          headerLogo={headerLogo}
          selectEmail={() => this.updateEmailSelected()}
          selectBack={this.goBack}
          allProps={allProps}
          runningOnMobile={this.props.runningOnMobile}
          pushToTvodWatchman={(type, url) => this.showCTVPolicyModal(type, url)}
        />
      </div>
    )
  }

  renderExternalMessage(){
    const unAuthorisedMessageHeading = this.props.feeds?.loginUIMetadata?.tvodLogin?.strings?.unauthorizedExternalTitle 
    const dismissButtonText = this.props.feeds?.loginUIMetadata?.tvodLogin?.strings?.unauthorizedExternalDismissText 
    const unAuthorisedMessage = this.props.feeds?.loginUIMetadata?.tvodLogin?.strings?.unauthorizedExternalMessage || ALL_C.kUnauthorizedMessage;
    return (
      <ErrorDisplay
        dismiss={() => {
          this.addNewView(PAGES.main, true)//reset
        }} 
        errorMsg={unAuthorisedMessage} 
        errorHeading={unAuthorisedMessageHeading}
        dismissButtonText={dismissButtonText}
    />
    )
  }

  renderLinkSection(){
    return <LinkSection 
      item={this.state.innerSection}
      onClose={() => {
        this.getAllInterstitialData();
        this.addNewView(PAGES.main, true)//reset
      }}
      hideBackButton 
    />
  }

  renderSimulatedMessage(){
    return (
      <ErrorDisplay 
        dismiss={() => {
          this.getAllInterstitialData();
          this.addNewView(PAGES.main, true)//reset
        }} 
        errorMsg={"This content will be unlocked."} 
        errorHeading={"This is a simulated purchase."} 
    />
    )
  }

  renderContentUnavailable(){

    return (
      <div class="content-unavailibity">
        <ErrorDisplay 
          dismiss={() => {
            if(this.state.innerSection) {
              this.goBack();
            } else {
              this.addNewView(PAGES.main, true)
            }
          }} 
          errorMsg={this.state.contentUnavailableMessage || ALL_C.kContentUnavailableMessage} 
        />
      </div>
    )
  }

  renderBlockUI() {
    return (
      <FocusLock>
        <div className="stream-block-modal">
          <p className="stream-modal-title">{ALL_C.kISMaxStreamTitle}</p>
          <span>{ALL_C.kISMaxStreamMessage.replace("%s", this.props.maxStreamCount)}</span>
          {this.props.runningOnMobile
            ? <div>
                <div className="line"></div>
                <div className="stream-modal-dismiss focusable" tabIndex={getTabIndex()} onClick={() => this.dissmissAll()}>{ALL_C.kOk}</div>
            </div>
            : <button onClick={() => this.dissmissAll()} className="stream-modal-dismiss focusable " tabIndex={getTabIndex()} autoFocus={true}>{ALL_C.kOk}</button>
          }
        </div>
      </FocusLock>
    )
  }

  renderPlayer() {
    const { section, all_contents, keepInterstitialOpen, playingExtra } = this.getPlayerData();
    if(!playingExtra) {
      window.history.replaceState("new video", {}, `${window.location.pathname}?mode=watch`)
    }
    this.handlePlayingClass(true);
    const propData = {
      streamCounterUpdate: this.state.streamCounterUpdate,
      modal: this.props.modal,
      section: section,
      app_id: configuration.app_id,
      feeds: this.props.feeds,
      runningOnMobile: this.props.runningOnMobile,
      handleKeepInterstitialOpen: keepInterstitialOpen ? () => this.handleKeepInterstitialOpen() : false,
      playingExtra: playingExtra,
      canShowInstructionText: this.canShowInstructionText
    }
    if (this.props.type === "video") {
      return (
        <OverlayVideo
          {...propData}
          all_contents={all_contents}
          parent_id= {this.props.modal.head} 
          validateNextVideo={(newItem) => this.validateNextVideo(newItem)}
          autoPlayInAction={this.state.autoPlayInAction ? this.state.autoPlayInAction.newIndex : null}
          autoPlayingSectionPlaylist={this.props.modal.autoPlayingSectionPlaylist}
        />
      )
    } else if (this.props.type === "fake") {
      return (
        <FakeLive 
          key={section.identifier}
          {...propData}
          autoPlayInAction={this.state.autoPlayInAction ? this.state.autoPlayInAction.newIndex : null}
          validateNextVideo={(newItem) => this.checkIfCanPlay(newItem)}
        />
      )
    } else if (this.props.type === "live") {
      return (
        <LiveSetup key={section.identifier} {...propData}/>
      )
    } else if (this.props.type === "svideo") {
      return (
        <ContentSectionVideo {...propData}/>
      )
    }
  }

  renderPinCodeFlow(classes) {
    return (
      <div className={classes}>
        <SetParentalLock
          goBack={() => {
            if(this.autoPlayedWithInterstitialKey) {
              this.dissmissAll();
            } else {
              this.goBack();
            }
          }}
          fromTvod
          onValidation={() => {
            const { newItem, trailer } = this.state.pinCodeFlowCallbackParam || {};
            this.goBack();
            this.checkIfCanPlay(newItem, trailer, "validated");
            this.setState({pinCodeFlowCallbackParam: null})
          }}
          runningOnMobile={this.props.runningOnMobile}
        />
      </div>
    )
  }

  getAriaLabels(label) {
    return {
      'role': 'button',
      'aria-describedby': `watchman-title watchman-subtitle watchman-summary watchman-metadata`
    };
  }

  render() {
    const lastPage = this.state.pages.length > 0 ? this.state.pages[this.state.pages.length - 1] : PAGES.main;
    const fontStyle = getFontStyleFromFeed(this.props.feeds);

    if(lastPage == PAGES.contentUnavailable) return this.renderContentUnavailable()
    if(this.state.canPlayVideo && this.props.streamingAllowed) {
      return this.renderPlayer()
    } else {
      this.handlePlayingClass();
      if(this.state.canPlayVideoChecking){
        return <Loader loadingType="videoLoader"/>
      }
      if(this.props.isUserLoading){
        return <Loader />
      }
      let classes = "tvod-interstitial";
      if(this.isSectionLevel) {
        classes = "tvod-interstitial section-interstitial";
      }
      if(lastPage === PAGES.pinCode) {
        return this.renderPinCodeFlow(classes);
      }
      return (
        <div className={classes} key={this.state.forceUpdateKey} style={fontStyle.secondaryFont}>
          {this.renderBackground(lastPage)}
          {this.renderBackButton(lastPage)}
          {(lastPage === PAGES.main || lastPage === PAGES.innerInterstitial) && this.renderDescription(lastPage)}
          {lastPage === PAGES.morePurchase && this.renderMorePurchases()}
          {lastPage === PAGES.moreWays && this.renderMoreWays()}
          {lastPage === PAGES.details &&
            <div className="more-sub-wrapper">
              {this.renderSubDetails()}
              <div className="policy-wrapper">
                {this.diclaimerDiv()}
                {this.termsAndPrivacyPolicyDiv()}
              </div>
            </div>
          }
          {lastPage === PAGES.login && this.renderLogin()}
          {lastPage === PAGES.message && this.renderSimulatedMessage()}
          {lastPage === PAGES.ctvPolicy && this.renderCTVPolicy()}
          {lastPage === PAGES.unAuthExternalMessage && this.renderExternalMessage()}
          {lastPage === PAGES.linkSection && this.renderLinkSection()}
          {!this.props.streamingAllowed && this.renderBlockUI()}
        </div>
      )
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  logged_in: state.user.logged_in,
  streamingAllowed: state.streams.streamingAllowed,
  allStreamObject: state.streams.allStreamObject,
  maxStreamCount: state.streams.maxStreamCount,
  fetchingStreams: state.streams.fetchingStreams,
  shouldPlayAfterPurchase: state.subscription.shouldPlayAfterPurchase,
  tvod_purchased: state.user.tvod_purchased,
  policyData: state.feeds.policyData,
  parentalLock: state.parentalLock,
  isVizioPaymentModalOpen: state.vizioAccount.modalOpen,
  hasUserEngaged: state.user.userEngaged,
  isUserLoading: state.user.isLoading,
})
  
const mapDispatchToProps = {
  openModal: openModal,
  resetTvodMaxStream: resetTvodMaxStream,
  logoutUserActions: logoutUserActions,
  canPlayVideoCheckFailed: canPlayVideoCheckFailed,
  closeModal: closeModal,
  startPurchase: startPurchase,
  simulatedTvodPurchase: simulatedTvodPurchase
}
  
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TvodWatchman));