import axios from 'axios';
import { START_PURCHASE, GET_SESSION_ID_PENDING, GET_SESSION_ID_RESOLVE, GET_SESSION_ID_REJECT, CLEAR_PENDING_PURCHASE,
    CLEAR_ERROR_MESSAGE, CANCEL_SUBSCRIPTION_RESOLVE, CANCEL_SUBSCRIPTION_PENDING, CANCEL_SUBSCRIPTION_REJECT,
    RENEW_SUBSCRIPTION_PENDING, RENEW_SUBSCRIPTION_REJECT, RENEW_SUBSCRIPTION_RESOLVE, SUB_POLLING_START, SUB_POLLING_STOP, PLAY_AFTER_PURCHASE, TVOD_SUB_DETAILS_RESOLVE, TVOD_SUB_DETAILS_PENDING, TVOD_SUB_DETAILS_REJECT, CLEAR_SUBSCRIPTION_FLAG } from "../constants/actionTypes";
import * as API_URL from "../constants/api"
import { getMazCoreEnvUrl, getTvodApiHeaders } from '../constants/initializer';
import config from '../constants/config'
import { addTvodAPIRegionalisation } from '../helperfunctions/common';
import { getUserSubscriptionDetails } from './userActions';

export function startPurchase(itemDetails){
    return function(dispatch) {
        dispatch({ 
            type: START_PURCHASE,
            payload: {
                isPending: true,
                details: itemDetails
            }
        })
    }
}

export function clearPendingPurchase(){
    return function(dispatch) {
        dispatch({ 
            type: CLEAR_PENDING_PURCHASE,
        })
    }
}

export function getPurchaseSessionId(apiParams) {
    return function(dispatch, getState) {
        dispatch({type: GET_SESSION_ID_PENDING});
        axios.post(API_URL.CREATE_PURCHASE_SESSION_ID_API, addTvodAPIRegionalisation(apiParams, getState), getTvodApiHeaders())
        .then(data => {
            const sessionId = data.data.session_id;
            const paymentUrl = config.isAppTvodType ? data.data.url : `${getMazCoreEnvUrl()}/${data.data.url}`;
            const subPollingUrl = config.isAppTvodType ? data.data.polling_url : `${getMazCoreEnvUrl()}/${data.data.polling_url}`;
            dispatch({type: GET_SESSION_ID_RESOLVE,
                payload: {
                    isLoading: false,
                    sessionId,
                    paymentUrl,
                    subPollingUrl
                }
            })
        })
        .catch(err => {
            const  msg = "Some error occured."
            dispatch({type: GET_SESSION_ID_REJECT,
                payload: {
                    isLoading: false,
                    errorMsg: msg
                }
            })
        })
    }
}

export function cancelSubscription(apiParams) {
    return function(dispatch) {
        dispatch({type: CANCEL_SUBSCRIPTION_PENDING})
        axios.get(API_URL.CANCEL_SUBSCRIPTION_API, { params: apiParams })
        .then(data => {
            dispatch({type: CANCEL_SUBSCRIPTION_RESOLVE})
        })
        .catch(err => {
            const msg= "Some error occured.";
            dispatch({type: CANCEL_SUBSCRIPTION_REJECT, payload: { errorMsg: msg}})
        })
    }
}

export function renewSubscription(apiParams) {
    return function(dispatch) {
        dispatch({type: RENEW_SUBSCRIPTION_PENDING})
        axios.get(API_URL.RENEW_SUBSCRIPTION_API, { params: apiParams })
        .then(data => {
            dispatch({type: RENEW_SUBSCRIPTION_RESOLVE})
        })
        .catch(err => {
            const msg= "Some error occured.";
            dispatch({type: RENEW_SUBSCRIPTION_REJECT, payload: { errorMsg: msg}})
        })
    }
}

export function clearErrorMessage() {
    return function(dispatch) {
        dispatch({ 
            type: CLEAR_ERROR_MESSAGE,
        })
    }
}

export function managePlayAfterPurchase(value) {
    return function(dispatch) {
        dispatch({type: PLAY_AFTER_PURCHASE, payload: value});
    }
}

export function startSubPolling(url) {
    const api = url;
    return function(dispatch) {
        dispatch({type: SUB_POLLING_START});
        axios.get(api, getTvodApiHeaders())
        .then(data => {
            if (data.data.success) {
                dispatch({type: SUB_POLLING_STOP, payload: data.data});
                dispatch(managePlayAfterPurchase(true));
            }
        })
        .catch(err => {
            if (err.response.status === 401) {
                dispatch({type: SUB_POLLING_STOP, payload: {isPollingError: true}});
            }
        })
    }
}


export function getTvodSubscriptionDetails() {
    return function(dispatch) {
        dispatch({type: TVOD_SUB_DETAILS_PENDING});
        axios.get(API_URL.TVOD_SUBSCRIPTION_LIST, getTvodApiHeaders())
        .then(data => {
            dispatch({type: TVOD_SUB_DETAILS_RESOLVE, payload: data?.data?.data || []});
        })
        .catch(err => {
            dispatch({type: TVOD_SUB_DETAILS_REJECT});
        })
    }
}

export function tvodCancelSubscription(id) {
    const api = `${API_URL.TVOD_STRIPE_API}/${id}/cancel`
    return function(dispatch) {
        dispatch({type: CANCEL_SUBSCRIPTION_PENDING})
        axios.post(api, {}, getTvodApiHeaders())
        .then(data => {
            dispatch(getUserSubscriptionDetails())
            dispatch({type: CANCEL_SUBSCRIPTION_RESOLVE})
        })
        .catch(err => {
            const msg= "Unable to cancel subscription";
            dispatch({type: CANCEL_SUBSCRIPTION_REJECT, payload: { errorMsg: msg}})
        })
    }
}

export function tvodRenewSubscription(id) {
    const api = `${API_URL.TVOD_STRIPE_API}/${id}/renew`
    return function(dispatch) {
        dispatch({type: RENEW_SUBSCRIPTION_PENDING})
        axios.post(api, {}, getTvodApiHeaders())
        .then(data => {
            dispatch(getUserSubscriptionDetails())
            dispatch({type: RENEW_SUBSCRIPTION_RESOLVE})
        })
        .catch(err => {
            const msg= "Unable to renew subscription";
            dispatch({type: RENEW_SUBSCRIPTION_REJECT, payload: { errorMsg: msg}})
        })
    }
}

export function clearSubscriptionFlags() {
    return function(dispatch) {
        dispatch({type: CLEAR_SUBSCRIPTION_FLAG})
    }
}
