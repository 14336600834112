import React from 'react';
import Swiper from 'react-id-swiper';
import { connect } from "react-redux";
import Cta from "../components/Cta";
import { HERO_SLIDER_THEME, VIDEO_GRID_THEME_THEME } from '../constants/common';
import { getFontStyleFromFeed, getSwiperParamsByTheme, getThumnailViewClass } from "../helperfunctions";
import { checkIfEmptyMazIdFeedsInContents } from '../helperfunctions/common';

class VideoGridContent extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      currentView: ''
    }
    this.swiper = null
  }


  render() {
    const _self = this;
    const fontStyle = getFontStyleFromFeed(this.props.feeds);
    const { layout, content, index, isHero } = _self.props;
    const paramContent = isHero ? {...content, isHero: isHero, index: index}  : content;
    let params = getSwiperParamsByTheme(_self, paramContent, VIDEO_GRID_THEME_THEME);
    
    if(checkIfEmptyMazIdFeedsInContents(this.props.content.contents))
      return null;

    const thumbClass = getThumnailViewClass(this.props.content, this.props?.currentSection);
    return (
      <div className={"video-grid-content" + " " + thumbClass}>
        {(!(isHero && index === 0) && this.props.title !== "saved_items" && !this.props.content.locked) && <h2 style={fontStyle.primaryFont}>{this.props.content.feedTitle || this.props.title || this.props.content.title}</h2>}
        {!(isHero && index === 0) && this.props.content.locked && <Cta layout={VIDEO_GRID_THEME_THEME}time={Date.now()} content={this.props.content} feeds={this.props.feeds} />}
        <Swiper {...params}>{this.props.contents}</Swiper>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  modal_progress: state.modal.progress,
})

export default connect(mapStateToProps, null)(VideoGridContent);
