import { connect } from "react-redux"
import React from 'react';


import store from './../store.js';
import configuration from "../constants/config.js";
import { getDateTimeForTvodInterstitial, getDateWithoutHyphen } from "./common.js";
import { getAllConstants } from "./regionalization.js";

function isAvailable(props,special=false, register_wall=false, content=null){
    // locked content is normally locked sub
    // if it is special then it will locked by only iap or registerwall
    var newState = store.getState();
    if(configuration.isAppTvodType) {
      return isAvailableForTvod(newState.user.tvod_purchased, content)
    }
    let iap_lock = special
    let sub_lock = true
    let responses = []

    if (content){
      iap_lock = (content.access && content.access.iap) || false
      special = content.special
      register_wall =  (content.registerWall && register_wall)
    }
    if(special){
      sub_lock = false
    }
    if(newState.user && newState.user.validVoucherCode) {
      // Got voucher code to unlock everything
      return true;
    }

    if (!register_wall && !iap_lock && !sub_lock){
      return true
    }
    if(register_wall){
      responses.push(newState.user.logged_in)
    }
    if (iap_lock){
      responses.push(newState.user.items_purchased.includes(props))
    }
    if (sub_lock) {
      responses.push(newState.user.subscription || newState.user.items_purchased.includes(props))
    }
    let response = responses.filter(function(value) {return value})
    return response[0] || false
}

function isAvailableForTvod(tvod_purchased, content) {
  if(!content || !content.access) return true;
  const { startsAt, endsAt, bundles } = content.access;
  const isEvent = endsAt && startsAt;

  if(bundles && bundles.length > 0) {
    let list = {};
    for(let i=0; i < bundles.length; i++) {
      const bundle = bundles[i];
      const purchased = tvod_purchased[bundle.bundleToken];
      if(purchased && purchased.isActive) {
        let val;
        if(bundle.type === "purchases") {
          val = true;
        } else if(bundle.type === "events" && isEvent) {
          val = endsAt;
        } else if((bundle.type === "subscriptions" || bundle.type === "rentals" || bundle.type === "externals") && (getDateWithoutHyphen(purchased.endsAt) > new Date())) {
          val = getDateWithoutHyphen(purchased.endsAt);
        }
        if(val) {
          if(isEvent) {
            list["events"] = getDateWithoutHyphen(endsAt);
            list["badgeType"] = bundle.type;
          } else {
            list[bundle.type] = val;
          }
        }
      }
    }
    const { kPurchaseBadge, kSubBadge, kRentalBadge, kEventBadge } = getAllConstants();
    if(list["purchases"]) {
      return {
        type: "purchases",
        badge: kPurchaseBadge
      }
    } else if(list["subscriptions"]) {
      return {
        type: "subscriptions",
        badge: kSubBadge,
        endsAt: list["subscriptions"]
      }
    } else if(list["rentals"]) {
      return {
        type: "rentals",
        badge: `${kRentalBadge} ${getDateTimeForTvodInterstitial(list["rentals"])}`,
        endsAt: list["rentals"]
      }
    } else if(list["events"]) {
      const badgeTypes = {"events": kEventBadge, "purchases": kPurchaseBadge, "subscriptions": kSubBadge };
      return {
        type: "events",
        badge: (list.badgeType && badgeTypes[list.badgeType]) ? badgeTypes[list.badgeType] : kEventBadge,
        endsAt: list["events"],
        startsAt: getDateWithoutHyphen(startsAt),
      }
    } else if(list["externals"]) {
      return {
        type: "externals",
        badge: kSubBadge,
        endsAt: list["externals"]
      }
    } else {
      return false;
    }
  }
  if(endsAt && startsAt) {
    // Free event , no badge
    return {
      type: "events",
      endsAt: getDateWithoutHyphen(endsAt),
      startsAt: getDateWithoutHyphen(startsAt),
    }
  }
  return true;
}

store.subscribe(isAvailable)



export default isAvailable;

/**
 * Function will check in all subscription that user is subscribed or not.
 * @param {*} allSubscription
 */
export function isUserSubscribed(allSubscription) {
  let isSubscribed = false;
  let sub_platform = "web_as_output";
  allSubscription.forEach(subs => {
    if (subs.type == 'subscription') {
      const currDate = new Date();
      //Adding 1 minute in currDate, So don't show wrong data in subscription page. when subscription get cancelled.
      currDate.setMinutes(currDate.getMinutes() + 1);
      const subEndDate = new Date(subs.end_date);
      if (subEndDate > currDate) {
        isSubscribed = true;
        sub_platform = subs.platform
      }
    }
  });
  return [isSubscribed,sub_platform];
}
