import React from "react";
import Swiper from "react-id-swiper";
import { connect } from "react-redux";

import Content from "../components/Content";
import Preview from "../components/Preview";
import { DynamicList } from "./DynamicList";

import { setPreviewVideo, setLockedContent } from "../actions/modalActions";
import isAvailable from "../helperfunctions/is_available";
import { checkSingleMenu, getFontStyleFromFeed } from "../helperfunctions";
import { PLATFORM_WEB, PLATFORM_WEB_PREVIEW } from "../constants/common";
import { checkCorrectPlatform, isSmartTv } from "../helperfunctions/common";
import JsSpatialNavigation from "../ctvnavigation/js-spatial-navigation";
import { attachObserver } from "../helperfunctions/ctvHelper";
import configuration from "../constants/config";
import { getItemForPreview } from "../helperfunctions/feedHelper";


class FullScreenSection extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			muted: !this.props.section.previewAudio,
			rows_shown: 3
		};
	}

  onMouseEventHandler = (event) => {
    if (event === "in") {
      this.mutePreviewVIdeo("out");
    }
  }

  mutePreviewVIdeo = (event) => {
    if (event === "in") {
      this.setState({ muted: true });
    } else if (event === "out") {
      this.setState({ muted: !this.props.section.previewAudio });
    }
  };

  handleRemoteClick = (e) => {
    if (
      this.navbar_element && this.fulllscreen_parent &&
      this.navbar_element.contains(document.activeElement)
    ) {
      this.fulllscreen_parent.scrollTop = 0;
    }
  };

  observerCallback = (entries, observer) => {
		if(!this.observer) {
			this.observer = observer;
		}
    if(entries[0].isIntersecting) {
      const { rows_shown } = this.state;
      if(rows_shown < this.props.section.contents.length) {
        this.setState({ rows_shown: rows_shown+3})
      } else {
        observer.unobserve(this.last_element);
      }
    }
  }

  componentDidMount() {
    this.setPreview();
    if (checkCorrectPlatform([PLATFORM_WEB, PLATFORM_WEB_PREVIEW])) {
      this.setWindowHover();
    }
    if (isSmartTv()) {
      this.fulllscreen_parent = document.querySelector('#fullscreen-grid');
      this.navbar_element = document.getElementById('nav-bar');

      document.addEventListener("keyup", this.handleRemoteClick);
      JsSpatialNavigation.set("navbar", {
        leaveFor: {
          "down": "#fullscreen .focusable"
        }
      });

      this.last_element = document.getElementById('last_elm');
      this.last_element && attachObserver(this.observerCallback, this.last_element);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.section.contents !== this.props.section.contents) {
      this.setPreview();
    }
  }

  setPreview(dContents) {
    const allContents = dContents || this.props.section.contents;
    if (!allContents) {
      this.props.dispatch(setPreviewVideo({}));
      return;
    };
    let _content = getItemForPreview(allContents, this.props.dynamicLists);
    _content["locked"] =
      allContents[0].locked &&
      !isAvailable(
        this.props.section.identifier,
        this.props.section.special,
        this.props.section.registerWall
      );
    this.props.dispatch(setPreviewVideo(_content));
    this.props.dispatch(
      setLockedContent(
        _content.locked &&
          !isAvailable(
            this.props.section.identifier,
            this.props.section.special,
            this.props.section.registerWall
          )
      )
    );
  }

  /**
   * Peek Functionality - (https://app.asana.com/0/1199123221292070/1199113311301949) When user hover on bottom 200px, The first row of fullscreen will fully visible.
   */
  setWindowHover = () => {
    const _self = this;
    window.addEventListener("mousemove", _self.onWindowMouseMove);
  };

  getFirstItemRowHeight = () => {
    if (
      document.querySelector(
        "#first-row-fullscreen .swiper-wrapper a.thumbLink"
      )
    ) {
      return document.querySelector(
        "#first-row-fullscreen .swiper-wrapper a.thumbLink"
      ).offsetHeight;
    } else if (document.querySelector(".swiper-wrapper a.thumbLink")) {
      return document.querySelector(".swiper-wrapper a.thumbLink").offsetHeight;
    } else {
      return 200;
    }
  };

  onWindowMouseMove = (e) => {
    const firstRowEleHeight = this.getFirstItemRowHeight() + 25;
    const pos = document.getElementById("fullscreen-grid")
      ? document.getElementById("fullscreen-grid").scrollTop
      : undefined;
    if (pos >= 0 && pos <= firstRowEleHeight) {
      if (e.clientY > window.innerHeight - 200) {
        document.getElementById("fullscreen-grid").scrollTop = firstRowEleHeight
      } else {
        if (e.clientY < (window.innerHeight - firstRowEleHeight - 75)) {
          document.getElementById("fullscreen-grid").scrollBy(0, -firstRowEleHeight)
        }
      }
    }
  };

  contentComponent = (content, i) => {
    if(isSmartTv() && this.state.rows_shown <= i) {
      return null;
    }

    const classId = this.props.section.contents ? this.props.section.contents[0].identifier : this.props.section.identifier;
    const propData = {
      app_id: this.props.app_id,
      feeds: this.props.feeds,
      layout: this.props.layout,
      content: content,
      key: i,
      index: i,
      section: this.props.section,
      pathLocation: this.props.pathLocation,
      callbackParent: this.props.callbackParent,
      sourceClass: "image-video-" + classId,
      hover: true,
      background: this.props.background
    };
    const isDynamicList = content.isDynamicList;
    if (i === 0) {
      const isSection = content.identifier === this.props.section.identifier;
      return (
        <span
          id="first-row-fullscreen"
          className="fullscreen-parent-row"
          onMouseEnter={() => this.onMouseEventHandler("in")}
          onMouseLeave={() => this.onMouseEventHandler("out")}
          onMouseMove={() => this.onMouseEventHandler("move")}
          key={i}
        >
          {isDynamicList
            ? <DynamicList key={`dynamic-${propData.key}`} propData={propData} content={content} isSection={isSection} onListLoad={(dContents) => this.setPreview(dContents)}/>
            : <Content {...propData} />
          }
        </span>
      );
    } else {
      return (
        <span
          key={i}
          onMouseEnter={() => this.mutePreviewVIdeo("in")}
          className="fullscreen-parent-row"
        >
          {isDynamicList
            ? <DynamicList key={`dynamic-${propData.key}`} propData={propData} content={content}/>
            : <Content {...propData} />
          }
        </span>
      );
    }
  };

  componentWillUnmount() {
    clearInterval(this.interval);
    window.removeEventListener("mousemove", this.onWindowMouseMove);
    if (isSmartTv()) {
      JsSpatialNavigation.set("navbar", {
        leaveFor: {
          "down": ".content-box .focusable"
        }
      });
      document.removeEventListener("keyup", this.handleRemoteClick);
			if(this.observer) {
				this.observer.disconnect();
			}
    }
  }

  render() {
    const fontStyle = getFontStyleFromFeed(this.props.feeds);
    let contents;
    if (this.props.section.isDynamicList || checkSingleMenu(this.props.section)) {
      contents = [this.props.section].map(this.contentComponent);
    } else {
      contents = this.props.section.contents.map(this.contentComponent);
    }
    return (
      <div id="fullscreen">
        <Preview
          muted={this.state.muted}
          previewType={this.props.section.previewType}
          section={this.props.section}
          previewInfo={this.props.section.previewInfo}
          fontStyle={fontStyle}
        />
        <div id="fullscreen-grid">
          <div className="fullscreen-web-wrapper">
            {contents}
            {isSmartTv() ? <div style={{ height: '1em' }} id="last_elm"></div> : null }
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  subscription: state.user.subscription,
  dynamicLists: state.items.dynamicLists
});

export default connect(mapStateToProps)(FullScreenSection);
