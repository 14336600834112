import React from "react";
import { connect } from "react-redux";
import Slider from "react-slick";

import Content from "../components/Content";
import Preview from "../components/Preview";
import { DynamicList } from "./DynamicList";

import { setPreviewVideo, setLockedContent } from "../actions/modalActions";
import isAvailable from "../helperfunctions/is_available";
import { checkSingleMenu, getFontStyleFromFeed } from "../helperfunctions";
import { isSmartTv } from "../helperfunctions/common";
import configuration from "../constants/config";
import { attachObserver, getVerticalSliderSettings } from "../helperfunctions/ctvHelper";
import { getItemForPreview } from "../helperfunctions/feedHelper";


class SpotlightSection extends React.Component {
  constructor(props) {
    super(props);
    this.swiper = null;
    this.state = {
      rows_shown: 3
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.section.contents !== this.props.section.contents) {
      this.getPreviewData();
    }
  }

  getPreviewData(dContents) {
    const allContents = dContents || this.props.section.contents;
    if (!allContents) {
      this.props.dispatch(setPreviewVideo({}));
      return;
    };
    let _content = getItemForPreview(allContents, this.props.dynamicLists);
    _content["locked"] =
      allContents[0].locked &&
      !isAvailable(
        this.props.section.identifier,
        this.props.section.special,
        this.props.section.registerWall
      );
    this.props.dispatch(setPreviewVideo(_content));
    this.props.dispatch(
      setLockedContent(
        _content.locked &&
          !isAvailable(
            this.props.section.identifier,
            this.props.section.special,
            this.props.section.registerWall
          )
      )
    );
  }

  observerCallback = (entries, observer) => {
    if(!this.observer) {
			this.observer = observer;
		}
    if(entries[0].isIntersecting) {
      const { rows_shown } = this.state;
      if(rows_shown < this.props.section.contents.length) {
        this.setState({ rows_shown: rows_shown+3})
      } else {
        observer.unobserve(this.last_element);
      }
    }
  }

  componentDidMount() {
    this.getPreviewData();
    const _self = this;
    this.swiper =
      document.querySelector("#spotlight-grid > .swiper-container") &&
      document.querySelector("#spotlight-grid > .swiper-container").swiper;
    if (isSmartTv()) {
      document.addEventListener("keyup", this.handleRemoteClick);

      this.last_element = document.getElementById('last_elm');
      this.last_element && attachObserver(this.observerCallback, this.last_element);
    }
    if (this.swiper) {
      this.updateSwiperAfterSetTime(100);
    }
    if (
      document.getElementById("web-nav-bar") &&
      document.getElementById("nav-with-modal-id")
    ) {
      document.getElementById("web-nav-bar").style.background =
        "linear-gradient(black, transparent)";
      document.getElementById("nav-with-modal-id").style.background = "black";
    }
  }

  updateSwiperAfterSetTime = (timeInms) => {
    const _self = this;
    setTimeout(function () {
      _self.swiper.update();
    }, timeInms);
  };

  componentWillUnmount() {
    document.removeEventListener("keyup", this.handleRemoteClick);
    if (
      document.getElementById("web-nav-bar") &&
      document.getElementById("nav-with-modal-id")
    ) {
      document.getElementById("web-nav-bar").style.background = "black";
      document.getElementById("nav-with-modal-id").style.background = "unset";
    }
    if(isSmartTv() && this.observer) {
			this.observer.disconnect();
		}
  }

  handleRemoteClick = (e) => {
    let spotlightParent = document.querySelector("#spotlight-grid");
    if (
      !configuration.enableCtvVirtualSlides &&
      (e.keyCode === 37 ||
        e.keyCode === 39 ||
        e.keyCode === 38 ||
        e.keyCode === 40)
    ) {
      if (spotlightParent && spotlightParent.contains(document.activeElement)) {
        const nearestContainer =
          document.activeElement.closest(".swiper-container");
        let currIndex;
        nearestContainer &&
          nearestContainer
            .querySelectorAll(".swiper-wrapper > div")
            .forEach((ele, index) => {
              if (ele.contains(document.activeElement)) {
                currIndex = index;
                nearestContainer.swiper &&
                  nearestContainer.swiper.slideTo(currIndex);
              }
            });
      }
    }
  };

  contentComponent = (content, i) => {
    if(isSmartTv() && this.state.rows_shown <= i) {
      return null;
    }

    const classId = this.props.section.contents ? this.props.section.contents[0].identifier : this.props.section.identifier;
    const propData = {
      app_id: this.props.app_id,
      feeds: this.props.feeds,
      layout: this.props.layout,
      content: content,
      key: i,
      pathLocation: this.props.pathLocation,
      callbackParent: this.props.callbackParent,
      sourceClass: "image-video-" + classId,
      hover: true,
      background: this.props.background,
      col: i,
      section: this.props.content,
    }

    const isSection = content.identifier === this.props.section.identifier;
    return (
      <span key={i}>
        {
          content.isDynamicList
          ? <DynamicList key={`dynamic-${propData.key}`} propData={propData} content={content} isSection={isSection} onListLoad={(dContents) => this.getPreviewData(dContents)}/>
          : <Content {...propData}/>
        }
      </span>
    );
  };

  render() {
    const fontStyle = getFontStyleFromFeed(this.props.feeds);
    let contents = [];
    if (this.props.section.isDynamicList) {
      contents = [this.props.section].map(this.contentComponent);
    } else if(this.props.section.contents) {
      contents = this.props.section.contents.map(this.contentComponent);
    }

    if (
      this.props.section.contents &&
      checkSingleMenu(this.props.section)
    ) {
      return (
        <div id="spotlight-section" className="h-screen">
          <Preview
            muted={!this.props.section.previewAudio}
            previewType={this.props.section.previewType}
            section={this.props.section}
            previewInfo={this.props.section.previewInfo}
            fontStyle={fontStyle}
          />
          <div id="spotlight-grid">
            <Content
              app_id={this.props.app_id}
              feeds={this.props.feeds}
              content={this.props.section}
              callbackParent={this.props.callbackParent}
              layout={this.props.layout}
              pathLocation={this.props.pathLocation}
            />
          </div>
        </div>
      );
    }
    return (
      <div id="spotlight-section" style={{ height: "100%" }}>
        <Preview
          muted={!this.props.section.previewAudio}
          previewType={this.props.section.previewType}
          section={this.props.section}
          previewInfo={this.props.section.previewInfo}
          fontStyle={fontStyle}
        />
        <div id="spotlight-grid">
          {isSmartTv() ? (
            // <Slider {...getVerticalSliderSettings()}>{contents}</Slider>
            <>
              {contents}
              <div style={{ height:"2em"}} id="last_elm"></div>
            </>
          ) : (
            contents
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  subscription: state.user.subscription,
  dynamicLists: state.items.dynamicLists
});

export default connect(mapStateToProps)(SpotlightSection);
