import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom'
import { openModal } from '../../actions/modalActions';
import { clearSubscriptionFlags, tvodCancelSubscription, tvodRenewSubscription } from '../../actions/subscriptionAction';
import BackButton from '../../components/BackButton';
import ConfirmModal from '../../components/ConfirmModal';
import Loader from '../../components/Loader';
import { getDateWithoutHyphen, getTabIndex, isSmartTv } from '../../helperfunctions/common';
import JsSpatialNavigation from '../../ctvnavigation/js-spatial-navigation';
import { addRemoveAllNavigation, getBackBtnKeyCodes } from '../../helperfunctions/ctvHelper';
import configuration from '../../constants/config';
import { throttle } from '../../constants/common';

function TvodSubcriptionItem(props) {
  const history = props.history;
  const params = props.match.params;
  const path = props.match.path
  const subscriptionId = params.id
  const listUrl = `${path.split("subscriptions")[0]}subscriptions`
  const isLoading = props.isLoading || props.isRenewPending

  const {feeds, tvodSubscriptionList, runningOnMobile} = props
  const subDetails = tvodSubscriptionList.find(i => i.identifier === subscriptionId)

  const [openConfirm, setOpenConfirm] = useState(false);
  const [showPopup, setShowPopup] = useState("")

  let id;
  useEffect(() => {
    //didmount
    if(isSmartTv() && configuration.use_light_sliders) {
      id = addRemoveAllNavigation({ selector: '.list-container .focusable' });
      JsSpatialNavigation.focus(id);
    }
    return () => {
      //will unmount
      if(isSmartTv() && configuration.use_light_sliders) {
          addRemoveAllNavigation({ id });
      }
    }
  });

  //If wrong subscription id, go to list page
  if (!subDetails) {
    history.push(listUrl);
    return;
  }

  const closeAllPopup = () => {
    setOpenConfirm(false);
    onDismiss()
  }

  const onCtvBackClick = (e) => {
    if (isSmartTv) {
      e.preventDefault();
      if (openConfirm || showPopup) {
        closeAllPopup()
      } else {
        history.push(listUrl)
      }
    }
  }

  const getDate = (date) => {
    if (!date) return "";
    return getDateWithoutHyphen(date)?.toDateString()
  }

  const modifySubscription = () => {
    setOpenConfirm(false);
    if (subDetails.cancelledAt) {
      props.tvodRenewSubscription(subDetails.identifier)
    } else {
      props.tvodCancelSubscription(subDetails.identifier)
    }
  }

  const mayBeOpenConfirmModal = (e) => {
    e.preventDefault();
    if (!subDetails.cancelledAt) {
      setOpenConfirm(true)
    } else {
      modifySubscription()
    }
  }

  const onDismiss = () => {
    props.clearSubscriptionFlags();
    setShowPopup("");
  }

  useEffect(() => {
    if (props.isSubscriptionRenewed) setShowPopup("Your subscription has been renewed.")
  }, [props.isSubscriptionRenewed])

  useEffect(() => {
    if (props.isSubscriptionCancelled) setShowPopup("Your subscription has been cancelled.")
  }, [props.isSubscriptionCancelled])

  useEffect(() => {
    if (props.errorMsg) setShowPopup(props.errorMsg)
  }, [props.errorMsg])
  
  const {trialEndsAt, endsAt, cancelledAt, platform, paymentPlatform} = subDetails;
  const buttonText = subDetails.cancelledAt ? "Renew Subscription" : "Cancel Subscription"
  const showTrialDate = !cancelledAt && trialEndsAt
  const isStripePayment = paymentPlatform == "stripe"
  const renewDate = showTrialDate ? trialEndsAt : endsAt

  return (
    <>
      <BackButton 
        backUrl={listUrl}
        runningOnMobile={runningOnMobile}
        onCTVBackClick={onCtvBackClick}
      />
      <div className='list-container sub-item-details'>
        {isLoading && <Loader />}
        <h3>Subscription</h3>
        <div>You have an active subscription</div>
        { showTrialDate && 
          <span>
            <b>Trial end date:</b> {getDate(trialEndsAt)}
          </span>
        }
        { !cancelledAt && Boolean(endsAt) &&
          <span>
            <b>Auto renew date:</b> {getDate(renewDate)}
          </span>
        }
        { Boolean(cancelledAt) && 
          <span>
            <b>Cancel date:</b> {getDate(cancelledAt)}
          </span>
        }
        { Boolean(cancelledAt) && 
          <span className="cancel-note">
            You have already cancelled your subscription, It will end on the Cancel date.
          </span>
        }
        { isStripePayment && 
          <button className={"submit-button focusable"} tabIndex={getTabIndex()} onClick={(e) => mayBeOpenConfirmModal(e)}>
            {buttonText}
          </button> 
        }
        { !isStripePayment && platform && <span>Purchased on: {platform}</span> }

        {openConfirm && <ConfirmModal
          header={"Cancel Subscription"}
          message={"Your Subscription will be discontinued after the cancel date. If there is no free trial, the amount will not be refunded."}
          onConfirm={() => modifySubscription()}
          onDeny={() => setOpenConfirm(false)}
          confirmText={"Confirm"}
          denyText={"Cancel"}
        />}
        {showPopup && <ConfirmModal
          message={showPopup}
          onConfirm={onDismiss}
          confirmText={"Dismiss"}
          hideDeny
          popupStyle={{width: 700}}
        />}
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  isLoading: state.subscription.isLoading,
  isRenewPending: state.subscription.isRenewPending,
  isSubscriptionRenewed: state.subscription.isSubscriptionRenewed,
  isSubscriptionCancelled: state.subscription.isSubscriptionCancelled,
  errorMsg: state.subscription.errorMsg,
});

const connectDispatchToProps = {
  tvodCancelSubscription,
  tvodRenewSubscription,
  openModal,
  clearSubscriptionFlags
};

export default withRouter(connect(mapStateToProps, connectDispatchToProps)(TvodSubcriptionItem))