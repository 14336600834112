import React from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';

import Cta from "../components/Cta"
import { httpsUrl, getSpotxIframe, removeSpotxIframe, getSpotXId, PLATFORM_HTML_SIMULATOR, MAZ_REPORTING_INTERVAL, PLATFORM_LG, PLATFORM_VIZIO, PLATFORM_SAMSUNG, PLATFORM_ZEASN, K_TOTAL_WATCHED_TIME_BEACONING } from "../constants/common"
import { closeModal,openModal } from '../actions/modalActions'
import { environment } from "../constants/initializer"
import { connect } from 'react-redux';
import isAvailable from '../helperfunctions/is_available'
import Metering from './Metering';
import { getMeteringTypes } from '../constants/common';
import { backButtonObj, checkIfSectionLevelContent, getParentFeed } from '../helperfunctions'
import * as analytics  from '../constants/analytics';
import playButton from "../images/icons/play-button.png";
import { updateVideoCount } from "../actions/adsActions";
import { runAdInVideo, constructSpotxParams, showAdsAsPerFrequency } from '../helperfunctions/adHelper';
import backMobile from "../images/icons/back-mobile.png";
import configuration from "../constants/config";
import { addRemoveAllNavigation, checkIfHideNavbarOnLive, keyMappings, toggleNavbarVisibility, toggleSubtitlesVisibility } from '../helperfunctions/ctvHelper';
import { sendVideoMinutes } from '../helperfunctions/mazAnalytics';
import 'videojs-contrib-ads';
import 'videojs-ima';
import { onVideoError, isSmartTv, getTabIndex, onCTVVideoError, checkCorrectPlatform, checkIfSafari, getVttCaption, videoType, fullscreen, modifiedPlaybackUrl, handleUnmutedPlayback, getDateWithoutHyphen } from '../helperfunctions/common';
import PlayerIcon from './icons/PlayerIcon';
import PlayerProgressBar from './icons/PlayerProgressBar';
import ContinueWatching from './ContinueWatching';
import TheoPlayer from './TheoPlayer';
import { getMMVideoAssetInfo, resettingVideoSource, startSendingMMEvents } from '../helperfunctions/mediaMelon';
import videojs from 'video.js';
import { isIOS, isMobile } from 'mobile-device-detect';
import { addTextTracksEventVideoJs, updateTextTrack } from '../helperfunctions/videojsHelper';
import { InstructionText } from './InstructionText';
import AudioMutedMessage from './AudioMutedMessage';
import { setDefaultValuesInVideoJs } from './player/PlayerSettingsMenu';
import { setLocalStorage, removeItemLocalStorage } from '../helperfunctions/storage';

// only for web
// not supported on ios
if (!isSmartTv() && !isIOS) {
  // replace default fullscreen toggle button
  // this button makes viewport body fullscreen
  videojs.registerPlugin("fullscreen", () => {
    const button = fullscreen().button;
    document.getElementsByClassName('vjs-control-bar')[0].appendChild(button);
  })
}

let current_player,current_src,current_type;

function showVideoAd(props){
  var adToShow = props.ads.spotxid.length > 0
  adToShow = adToShow && window.SpotX && props.section.showVideoAd
  var showAd = false
  if(adToShow){ 
    if(props.ads.requestAdEverytimeForLive) {
      showAd = true;
      // always show ads(if enabled) in live irrespective of frequency
    } else {
      showAd = showAdsAsPerFrequency(props.ads, 0);
    }
  }
  return showAd
}

function playAd(props) {
  if(configuration.kUseTheoPlayer) return;
  if(document.getElementById("loader")) document.getElementById("loader").style.display = "none";
  let videoSlot = document.getElementById('live-video');
  let adContainer = document.getElementsByClassName('live-video-ad')[0];
  if (document.getElementById('thumb-video_html5_api')) {
    videoSlot = document.getElementById("thumb-video_html5_api");
    adContainer = document.getElementById("live-video");
  }
  const cust_params = constructSpotxParams(props.feeds, props.section);
  const directAdOS = new window.SpotX.DirectAdOS({
    channel_id: getSpotXId(props.ads),
    slot: adContainer,
    video_slot: videoSlot,
    hide_skin: false,
    autoplay: true,
    custom: {...cust_params}
  });
  directAdOS.loadAd();
}

function playVideo(videourl, props, state, _this) {
  if(configuration.kUseTheoPlayer) return;
  let videoParams = { 
      muted: false,
      html5: {
        vhs: {
          overrideNative: !isSmartTv(),
        },
        nativeTextTracks: false
      },
      controlBar: {
        // enable default fullscreen toggle button if on ios
        fullscreenToggle: isIOS,
        pictureInPictureToggle: false,
        subsCapsButton: false,
        audioTrackButton: false
      },
      liveui: true,
    };
  if(props.runningOnMobile) {
    videoParams.controls = configuration.show_live_controls || false;
  }
  if(isSmartTv()) {
    videoParams.loadingSpinner = true;
    videoParams.controls = false;
  }
  const player = videojs('live-video', videoParams);
  if(!player) return;

  // for web only
  // not supported on ios
  // register fullscreen plugin which make viewport body in fullscreen instead of video
  if(!isSmartTv() && !isIOS) {
    player.fullscreen();
  }
  
  const url = httpsUrl(videourl)
  const type = videoType(videourl)
  current_player = player;
  current_src = url;
  current_type = type;
  player.on('error', function(e) {
    if(isSmartTv()) {
      onCTVVideoError(e, player, 'live');
    } else {
      onVideoError(type, player);
    }
  });
  player.on('pause', () => {
    setTimeout(() => {
      const vid = document.getElementById("live-video_html5_api");
      if(_this && _this.state && !_this.state.playing_Ad && vid && vid.paused) {
        prepareMazEventData(_this, "video_paused");
        _this.videoPausedByUser = true;
      }
      // pause event trigger for everything even if video ended so handling it by delaying method call
    }, 1000)
  })
  player.on("play", () => {
    if(_this.videoPausedByUser) {
      resetMazEventData(_this);
      _this.videoPausedByUser = false;
    }
  })
  player.ready(() => {
    let playbackUrl = modifiedPlaybackUrl(props, url, props.section, player);
    player.src({src: playbackUrl, type: type, hls: {withCredentials: true}, ...getMMVideoAssetInfo(props.section)})
    const { showVideoAd } = (configuration.isAppTvodType && getParentFeed(props.feeds, props.section.lineage)) || {}
    const isAdTrue = props.section.showVideoAd || showVideoAd;

    runAdInVideo(player, props, state, _this, isAdTrue, "live-video_html5_api", props.section);
    startSendingMMEvents(player, playbackUrl, props.section, true)
    const promise = player.play();
    configuration.isAppTvodType && handleUnmutedPlayback(promise, player, "live-video_html5_api", _this.props.userEngaged, _this.showBigPlayButton);
    var newVideo = document.getElementById("live-video_html5_api")
    newVideo.setAttribute("type", type);
    newVideo.muted = false;
    newVideo.addEventListener("contextmenu", (event) => {
      event.preventDefault();
    });
    if(document.getElementById("loader")) document.getElementById("loader").style.display = "none";
    newVideo.addEventListener("timeupdate", () => handleVideoTimeUpdate(_this), false);
    if(isSmartTv()) {
      newVideo.addEventListener("play", () => _this.hideNavbar())
      newVideo.addEventListener("pause", () => _this.hideNavbar(true))
    }
    const vttCaption = getVttCaption(props.section);
    vttCaption && player.addRemoteTextTrack({src: vttCaption, default: _this.props.showSubtitle}, false);
    if (_this.props.showSubtitle && !vttCaption) {
      addTextTracksEventVideoJs(player)
    }
    isSmartTv() && setDefaultValuesInVideoJs({player})
  })
}

function handleVideoTimeUpdate(_this) {
  if(document.getElementById("live-video_html5_api")){
    var video = document.getElementById("live-video_html5_api");
  } else {
    var video = document.getElementById("live-video");
  }
  if(video) {
    handleVideoMinutes(video, _this)
  }
}

function handleVideoMinutes(videoEl, _this) {
  const time = Math.floor(videoEl.currentTime);
  const tier = videoEl.videoHeight;
  if(time !== _this.streamedVideoTime) {
    _this.streamedVideoTime = time;
    _this.streamedVideoSeconds++;
  }
  if(tier && _this.streamedVideoTier && tier !== _this.streamedVideoTier) {
    prepareMazEventData(_this, "tier_changed");
  } else if(_this.streamedVideoSeconds === MAZ_REPORTING_INTERVAL) {
    prepareMazEventData(_this, "beacon");
  }
  if(tier) {
    _this.streamedVideoTier = tier;
  }
}

function prepareMazEventData(_this, type) {
  const {streamedVideoSeconds, streamedVideoTier, videoStartTime, props, state, videoPathname} = _this || {};
  if(streamedVideoSeconds == null || !streamedVideoTier ) {
    return;
  }
  const data = {
    eventValue: streamedVideoSeconds.toString(),
    eventStartTime: videoStartTime,
    tierStreamed: `${streamedVideoTier}px`,
    contentId: props.section.identifier,
    contentUrl: state.contentUrl,
    eventAction: type
  }

  resetMazEventData(_this);
  sendVideoMinutes(data, props.feeds, videoPathname, "")
}

function resetMazEventData(_this) {
  if(_this) {
    _this.videoStartTime = new Date().toISOString();
    _this.streamedVideoSeconds = 0;
  }
}

class LiveSetup extends React.Component {
  constructor(props){
    super(props)
    const jWPlayerConfig = this.getJWPlayerConfig();
    this.jWPlayerConfig = jWPlayerConfig;
    this.state = {
      metering_type: null,
      freeContentOver: false,
      adWrapperExist: false,
      videoInQueue: undefined,
      playing_Ad: false,
      showPlayButton: configuration.isAppTvodType ? false : !this.props.userEngaged,
      video_start: false,
      endVideoAfterAd: false,
      contentUrl: jWPlayerConfig ? null : props.section.contentUrl,
      afterMount: false
    }
    this.backButton = this.backButton.bind(this)
    this.METERING_TYPE = getMeteringTypes();
    this.onAdComplete = this.onAdComplete.bind(this)
    this.onAdStart = this.onAdStart.bind(this)
    this.onAdReady = this.onAdReady.bind(this)
    this.onAllAdComplete = this.onAllAdComplete.bind(this)
    this.showBigPlayButton = this.showBigPlayButton.bind(this)
    this.simulatorInterval = null;
    this.simulatorCount = 0;
    this.videoStartedAt = new Date();
    this.streamedVideoTier = null;
    this.streamedVideoSeconds = 0;
    this.streamedVideoTime = 0;
    this.videoStartTime = new Date().toISOString();
    this.videoPausedByUser= false;
    this.navbar_timeout = null;
    this.videoPathname = '';
    this.totalWatchedTimeInterval = null;
  }
  componentDidMount(){
    this.setState({afterMount: true});
    this.videoPathname = window.location.pathname;
    analytics.setEventForContent(this.props.feeds, this.videoPathname, 'Video','Live Play', '', this.state.contentUrl);
    if(!this.state.showPlayButton) {
      this.startVideo()
    } else {
      if(document.getElementById("loader")) document.getElementById("loader").style.display = "none";
    }
    if(isSmartTv()) {
      document.addEventListener('keyup', this.handleRemoteClick);

      if(document.getElementById('live-video_html5_api')) {
        document.getElementById('live-video_html5_api').addEventListener('loadedmetadata',(e) => {
          this.setState({ video_start: true });
        },{once: true});  
      }

      if(configuration.use_light_sliders) {
        this.id = addRemoveAllNavigation({ selector: '.videoFull .focusable' });
      }
    }

    if(configuration.is_simulator && checkCorrectPlatform(PLATFORM_HTML_SIMULATOR) && !checkIfSafari()) {
      // For Bug simulator Bug https://github.com/MagAppZine/MagAppZine-MagControl/issues/8267
      this.simulatorInterval = setInterval(() => {
        this.checkVideoStatusForSimulator()
      },1000)
    }
    this.totalWatchTimeCalculator()
  }

  totalWatchTimeCalculator() {
    if(this.props.feeds.kTotalWatchedTimeBeaconing){
      this.totalWatchedTimeInterval = setInterval(() => {
        let key = K_TOTAL_WATCHED_TIME_BEACONING
        let currentWatchedTime = Math.floor((new Date() - this.videoStartedAt) / 1000)
        let data = {
          contentUrl: this.state.contentUrl,
          path: this.videoPathname,
          duration: currentWatchedTime
        }
        setLocalStorage(key, {...data})
      }, 60 * 1000);
    }
  }

  getJWPlayerConfig() {
    const section = this.props.section;
    if(section.liveStreamProvider && section.liveStreamProvider.type === "jw_player" && section.liveStreamProvider.url) {
      return section.liveStreamProvider;
    }
    return null;
  }

  hideNavbar = (clear = false, hideOnly = false) => {
    const navbar = document.getElementById('nav-bar');
    if(navbar) {
      let timer = 2_000;
      if(hideOnly) timer = 0;
      this.navbar_timeout && clearTimeout(this.navbar_timeout);
      navbar.style.display = 'flex';
      if(clear) {
        return;
      }      
      this.navbar_timeout = setTimeout(() => {
        navbar.style.display = 'none';
      }, timer);
    }
  }

  clearSimulatorInterval() {
    if(this.simulatorInterval) {
      clearInterval(this.simulatorInterval);
      this.simulatorInterval = null;
    }
  }


  clearTotalWatchedTimeInterval() {
    if(this.totalWatchedTimeInterval) {
      clearInterval(this.totalWatchedTimeInterval)
      this.totalWatchedTimeInterval = null
      removeItemLocalStorage(K_TOTAL_WATCHED_TIME_BEACONING)
    }
  }

  sendWatchedVideoAnalytics() {
    const time = Math.floor((new Date() - this.videoStartedAt) / 1000);
    analytics.setEventForWatchedVideo(this.state.contentUrl, this.props.feeds, this.videoPathname, null, time);
  }
 
  checkVideoStatusForSimulator() {
    if(!this.state.playing_Ad && !this.state.showPlayButton) {
      this.simulatorCount++;
      if(this.simulatorCount > 5) {
        const player = videojs('live-video');
        if(player && !player.paused() && player.readyState() == 0) {
          player.trigger("error");
          player.pause();
          if(document.getElementById('live-video')) document.getElementById('live-video').classList.add("vjs-error")
        }
      }
    }
  }

  handleJWPlayerInterval() {
    axios.get(this.jWPlayerConfig.url)
      .then(result => {
        if(result && result.data && result.data.status === "active" && result.data.current_event && this.props.section.contentUrl) {
          const contentUrl = this.props.section.contentUrl.replace('channelId', result.data.current_event);
          this.setState({showLiveIdleImage: false})
          if(this.state.contentUrl !== contentUrl) {// do nothing if getting same url again
            this.setState({contentUrl: contentUrl});
            if(this.idleInterval) {
              clearInterval(this.idleInterval);
              this.idleInterval = null;
            }
            if(!this.activeInterval) {// set only once
              this.activeInterval = setInterval(() => {
                this.handleJWPlayerInterval()
              }, this.jWPlayerConfig.activeRefreshTime * 60000)// convert min into ms
            }
          }
        } else {
          this.setState({showLiveIdleImage: true})
          if(this.state.contentUrl) {
            // In case if url was active but became idle later
            this.setState({contentUrl: null});
            if(current_player && current_player.pause) {
              current_player.pause();
            }
            if(this.activeInterval) {
              clearInterval(this.activeInterval);
              this.activeInterval = null;
            }
            if(!this.idleInterval) {// set only once
              this.idleInterval = setInterval(() => {
                this.handleJWPlayerInterval()
              }, this.jWPlayerConfig.idleRefreshTime * 60000)// convert min into ms
            }
          }
        }
      })
      .catch(error => {
        this.setState({showLiveIdleImage: true})
      })
  }

  startVideo() {
    if(this.jWPlayerConfig && !this.state.contentUrl) {
      this.setState({showPlayButton: false});
      this.handleJWPlayerInterval();
      this.idleInterval = setInterval(() => {
        this.handleJWPlayerInterval();
      }, this.jWPlayerConfig.idleRefreshTime * 60000)// convert min into ms
      return;
    }

    let showAd = false
    if(this.props.ads.spotxid !== undefined){
      showAd = showVideoAd(this.props)
    }
    if(showAd && !isSmartTv()){
      playAd(this.props);
    } else {
      playVideo(this.state.contentUrl, this.props, this.state, this);
    }
    this.setState({
      playing_Ad: showAd,
      showPlayButton: false
    })
    this.videoStartTime = new Date().toISOString();
  }

  shouldComponentUpdate(nextProps, nextState){
    if(!isAvailable(this.props.section.identifier,this.props.section.special,this.props.section.registerWall) && nextProps.subscription){
      if(document.getElementById('fake-video-overlay')) document.getElementById('fake-video-overlay').style.display = 'none';
      return false;
    } else {
      return true;
    }
  }

  componentWillUnmount(){
    this.sendWatchedVideoAnalytics();
    prepareMazEventData(this, "video_exited");
    if(!this.props.ads.requestAdEverytimeForLive) {
      this.props.updateVideoCount(1);
    }
    const video = document.getElementById("live-video");
    if (video)
      videojs(video).dispose();
    if(isSmartTv()) {
      document.removeEventListener('keyup', this.handleRemoteClick);      
      this.hideNavbar(true);

      if(configuration.use_light_sliders) {
        addRemoveAllNavigation({ id: this.id });
      }
    }
    this.clearSimulatorInterval();
    if(this.activeInterval) {
      clearInterval(this.activeInterval);
      this.activeInterval = null;
    }
    if(this.idleInterval) {
      clearInterval(this.idleInterval);
      this.idleInterval = null;
    }
    this.clearTotalWatchedTimeInterval()
  }

  componentDidUpdate(prevProps, prevState){
    if(isSmartTv() && (prevProps.isUserOnline != this.props.isUserOnline) && !configuration.kUseTheoPlayer) {
      if(this.props.isUserOnline && current_player) {
        // restore video
        // current_player.reset();
        let playbackUrl = modifiedPlaybackUrl(this.props, current_src, this.props.section, current_player);
        resettingVideoSource();
        current_player.src({ src: playbackUrl, type: current_type, hls: {withCredentials: true} });
        current_player.ready(() => {
          current_player.play();
          var newVideo = document.getElementById("live-video_html5_api") || document.getElementById("live-video_Html5_api");
          newVideo.muted = false;
        });
      }
    }

    if(prevProps.isPlayerSettingsModalOpen !== this.props.isPlayerSettingsModalOpen) this.hideNavbar(null,true);

    if(prevState.freeContentOver !== this.state.freeContentOver && this.state.freeContentOver) {
      const video = document.getElementById("live-video");
      const spotxIframe = getSpotxIframe();
      if (video) videojs(video).dispose();
      if (spotxIframe) removeSpotxIframe();
      if(this.theoPlayerReference) {
        this.theoPlayerReference.stop();
      }
    }
    if(prevState.playing_Ad !== this.state.playing_Ad && !this.state.playing_Ad) {
      resetMazEventData(this);
    }
    if(isSmartTv() && prevProps.showSubtitle != this.props.showSubtitle) {
      if(this.theoPlayerReference) {
        toggleSubtitlesVisibility(this.theoPlayerReference, this.props.showSubtitle);
      } else if(current_player) {
        toggleSubtitlesVisibility(current_player, this.props.showSubtitle);
      }
    }

    if(this.jWPlayerConfig && this.state.contentUrl !== prevState.contentUrl && this.state.contentUrl) {
      this.startVideo();
    }
  }

  handleRemoteClick = (e) => {
    if(parseInt(e.keyCode) != 10009 && document.getElementById("video-inlineMenu") && !document.getElementById('video-inlineMenu').classList.contains('closedMenu')) {
      return;
    }
    if(this.props.isPlayerSettingsModalOpen) return;

    if(document.getElementById("continue-watching")) {// Continue watching modal is up
      return;
    }

    if(document.activeElement?.className?.includes("headerLinks navbar")) {
      return;
    }

    const isFocusOnControls = document.querySelector('#custom-control-bar') && document.querySelector('#custom-control-bar').contains(document.activeElement);
    const player = document.getElementById("live-video_html5_api") || document.getElementById("live-video_Html5_api");
    if(!player) return;

    if(!player.paused && checkIfHideNavbarOnLive(e.keyCode)) {
      this.hideNavbar();
    }

    if(this.state.playing_Ad) return;
    
    let current_time;

    const focus_on_nav = document.getElementById("nav-bar") ? document.getElementById("nav-bar").contains(document.activeElement) : false;
    if(!(configuration.show_live_controls || false) || focus_on_nav || this.state.playing_Ad) return;
    if(configuration.kUseTheoPlayer) return;

    switch(keyMappings(e.keyCode)) {
      case 'OK':
        if (isFocusOnControls) {break;}
        if(player && player.paused) {
          player.play();
        } else {
          player && player.pause();
        }
      break;
      
      case 'PLAY': //media play
        if(player && player.paused) {
          player.play();
        }
      break;

      case 'PAUSE': //media pause
        if(player && !player.paused) {
          player.pause();
        }
      break;

      case 'PLAY-PAUSE': //media playpause
        if(player && player.paused) {
          player.play();
        } else {
          player && player.pause();
        }
      break;

      case 'STOP': // stop video
        if(player) {
          if(checkCorrectPlatform([PLATFORM_ZEASN, PLATFORM_LG, PLATFORM_SAMSUNG]) && !checkIfSectionLevelContent() && document.getElementById("overlay_video_back_btn")) {
            setTimeout(() => {
              document.getElementById("overlay_video_back_btn").click();
            }, 500);
          } else {
            player.pause();
            player.currentTime = 0;
          }
        }
      break;

      case 'LEFT': // left
        if (isFocusOnControls) {break;}
      case 'REWIND': // rewind
        current_time = player.currentTime;
        player.currentTime = current_time - 5;
      break;
      
      case 'RIGHT': // right
        if (isFocusOnControls) {break;}
      case 'FORWARD': //forward
        current_time = player.currentTime;
        player.currentTime = current_time + 5;
      break;

    }
  }

  onAdReady() {
    const adCont = document.getElementById("live-video_ima-ad-container");
    if(adCont) {
      if(!adCont.classList.contains("bumpable-ima-ad-container")) {
        this.setState({adWrapperExist: true})
      }
    }
  }

  onAdStart(ev) {
    if(ev) this.current_ad = ev.getAd();
    this.setState({playing_Ad: true})
    if(isSmartTv() && checkIfSectionLevelContent()) {
      // hide navbar on ad play
      toggleNavbarVisibility('none');
    }
  }

  onAdError() {
    this.setState({
      adWrapperExist: false,
      playing_Ad: false
    })
    if(isSmartTv() && checkIfSectionLevelContent()) {
      toggleNavbarVisibility('block');
    }
  }

  updateSrcInSmartTv(_type) {
    if(configuration.kUseTheoPlayer) return;
    let _this = this;
    let ad_url = this.props.ads.adURL;
    let isVapidAd = ad_url.includes('vpaid');
    if((_type === 'ad_com' && isVapidAd) || (_type === 'ad_all_com' && !isVapidAd)) return;

    let adPod = this.current_ad.getAdPodInfo()
    let podInfo = {
      podPosition: adPod.getAdPosition(),
      podLength: adPod.getTotalAds()
    }

    if (podInfo.podPosition == podInfo.podLength) { 
      // console.log("played manually",current_src);
      if(!checkCorrectPlatform([PLATFORM_VIZIO])) {
        current_player.reset();
      }
    
      let playbackUrl = modifiedPlaybackUrl(_this.props, current_src, _this.props.section, current_player);
      resettingVideoSource();
      current_player.src({ src: playbackUrl, type: current_type, hls: {withCredentials: true} });
      current_player.ready(() => {
        current_player.play();
        var newVideo = document.getElementById("live-video_html5_api") || document.getElementById("live-video_Html5_api");
        newVideo.muted = false;
        newVideo.addEventListener('play', () => {
          // set the subtitle if exist
          if (_this.props.showSubtitle) updateTextTrack(current_player, "showing")
          _this.hideNavbar();
        });
        newVideo.addEventListener("pause", () => _this.hideNavbar(true));
      });
      const vttCaption = getVttCaption(_this.props.section);
      vttCaption && current_player.addRemoteTextTrack({src: vttCaption, default: _this.props.showSubtitle});
      if (_this.props.showSubtitle && !vttCaption) {
        addTextTracksEventVideoJs(current_player)
      }
      isSmartTv() && setDefaultValuesInVideoJs({player: current_player})
    }
  }

  onAdComplete() {
    if(isSmartTv()) {
      // this.updateSrcInSmartTv();      
      setTimeout(() => {
        this.updateSrcInSmartTv('ad_com');
      },0);

      if(checkIfSectionLevelContent()) {
        toggleNavbarVisibility('block');
      }
    }
    this.setState({playing_Ad: false})
    var newVideo = document.getElementById("live-video_html5_api")
    // live doesn't start playing after ad, so force it.
    if(newVideo && newVideo.paused && !isSmartTv()) {
      newVideo.play();
      newVideo.muted = false;
    }
  }

  onAllAdComplete() {
    if(isSmartTv()) {
      this.updateSrcInSmartTv('ad_all_com');
    }
    this.setState({adWrapperExist: false})
    if(this.state.endVideoAfterAd) {
      this.backButton();
    }
  }

  backButton(e){
    if(e) e.preventDefault()
    
    // exit fullscreen
    // not supported on ios
    if (!isSmartTv() && !isIOS && !fullscreen().isNormalScreen()) fullscreen().contract();
    if(this.props.handleKeepInterstitialOpen) {
      this.props.handleKeepInterstitialOpen();
      return;
    }
    this.props.closeModal();
    const { head, pathname } = this.props.modal || {};
    if(head === "search" && pathname) {
      this.props.history.replace(pathname);
    } else {
      const backUrlObj = backButtonObj(this.props.feeds, this.videoPathname.split('/').slice(3).join('/'));
      this.props.history.replace(backUrlObj.backPath);
    }
  }

  onFreeContentOver() {
    this.setState({freeContentOver: true})
    this.clearSimulatorInterval();
  }

  shouldClosePlayer() {
    if(!configuration.isAppTvodType || this.theoPlayerReference) return true;

    const video = document.getElementById("live-video_html5_api");
    if(!video || !video.currentTime) return true;

    const { duration, access } = this.props.section || {};
    if(duration && duration - video.currentTime < 1) return true // recorded video as live

    if(access && access.endsAt && new Date(getDateWithoutHyphen(access.endsAt)) > new Date()) {// never ending live video
      return false;
    }

    return true;
  }

  // This will get called when ad is over.
  setNextVideo = () => {
    if(document.getElementById("loader")) document.getElementById("loader").style.display = "block";
    const self = this;
    const spotxIframe = getSpotxIframe();
    if (spotxIframe && !isSmartTv()) {
      this.setState({
        playing_Ad: false,
      })
      setTimeout(() => {
        removeSpotxIframe();
        playVideo(self.state.contentUrl, this.props, this.state, this);
      }, 100)
    } else if(configuration.isAppTvodType && document.getElementById("overlay_video_back_btn")) {
      if(this.state.adWrapperExist) {
        this.setState({endVideoAfterAd: true})
      } else {
        if(this.shouldClosePlayer()) {
          this.backButton();
        } else {
          if(document.getElementById("loader")) document.getElementById("loader").style.display = "none";
          setTimeout(() => {
            const video = document.getElementById("live-video_html5_api");
            if(video && video.paused && video.play) {
              video.play();
            }
          }, 500)
        }
      }
    }
  }

  getCTVVideoId() {
    if(document.getElementById("live-video_html5_api")) {
      return "live-video_html5_api";
    } else if(document.getElementById("live-video_Html5_api")) {
      return "live-video_Html5_api";
    } else {
      return null;
    }
  }

  theoPlayerUpdateTimeHandler(currentTime, totalTime) {
    handleVideoMinutes(this.theoPlayerReference, this)
  }

  onTheoPause() {
    prepareMazEventData(this, "video_paused");
    this.videoPausedByUser = true;
  }

  onTheoPlay() {
    if(isSmartTv() && !this.state.video_start) {
      this.setState({video_start: true})
    }
    if(this.videoPausedByUser) {
      resetMazEventData(this);
      this.videoPausedByUser = false;
    }
  }

  handleTheoAdState(state) {
    switch(state) {
      case "adbegin": this.onAdStart()
        break;
      case "adend": 
        this.onAllAdComplete();
        this.onAdComplete();
        break;
      case "aderror": this.onAdError()
        break;
      default:
        break;
    }
  }

  renderInstructionText() {
    if(!this.props.canShowInstructionText) return null;
    return (
      <InstructionText 
        feeds={this.props.feeds}
        runningOnMobile={this.props.runningOnMobile}
        playing_Ad={this.state.playing_Ad}
      />
    )
  }

  showBigPlayButton() {
    this.setState({showPlayButton : true})
  }

  renderPlayer() {
    if(configuration.kUseTheoPlayer) {
      if(!this.state.contentUrl) return null;
      const { showVideoAd }  = (configuration.isAppTvodType && getParentFeed(this.props.feeds, this.props.section.lineage)) || {};
      const isAdTrue = this.props.section.showVideoAd || showVideoAd;

      return (
        <TheoPlayer
          section={{...this.props.section, contentUrl: this.state.contentUrl}}
          type={videoType(this.state.contentUrl)}
          feeds={this.props.feeds}
          showPlayButton={this.state.showPlayButton}
          setShowPlayButton={this.showBigPlayButton}
          isAdForFeed={isAdTrue}
          ads={this.props.ads}
          showSubtitle={this.props.showSubtitle}
          theoPlayerUpdateTimeHandler={(currentTime, totalTime) => this.theoPlayerUpdateTimeHandler(currentTime, totalTime)}
          setNextVideo={() => this.setNextVideo()}
          oninit={(player) => this.theoPlayerReference = player}
          onAdUpdateState={(state) => this.handleTheoAdState(state)}
          onTheoPlay={() => this.onTheoPlay()}
          onTheoPause={() => this.onTheoPause()}
          videoType={"live"}
          user_ip={this.props.user_ip}
          user_concent={this.props.userConcent}
          device_uuid={this.props.device_uuid}
          user_coords={this.props.user_coords}
          device_model={this.props.device_model}    
          hideNavbar={this.hideNavbar}
          handleKeepInterstitialOpen={this.props.handleKeepInterstitialOpen}
          isUserOnline={this.props.isUserOnline}
        />
      )
    }
    return (
      <video
        className={"video-js vjs-default-skin"}
        id="live-video" preload="10" 
        onEnded={() => this.setNextVideo()}
        disablePictureInPicture 
      />
    )
  }

  render(){
    let navbar_present = !configuration.isAppTvodType && document.getElementsByClassName("nav-view")[0] || false;
    const isSectionLevel = !configuration.isAppTvodType && checkIfSectionLevelContent();
    const runningOnMobile = this.props.runningOnMobile;
    let ctv_style = {}
    if(isSmartTv()) {
      ctv_style.display = 'none';
    }
    const showLiveIdleImage = this.state.showLiveIdleImage;
    const showControlBar = ( !isMobile && this.state.playing_Ad ) || configuration.show_live_controls || this.props.playingExtra;
    const canShowCustomControls = isSmartTv() && this.getCTVVideoId() && !showLiveIdleImage && !this.state.playing_Ad && configuration.show_live_controls && !this.state.showPlayButton && this.state.afterMount;
    
    return(
      <div className={"videoFull " + ( showControlBar ? "show_live_controls" : "")} style={{top: 0, left: 0, position: "absolute"}}>
        {!showLiveIdleImage && <div id="loader" className="loader" style={{zIndex: 101}}><img src={"https://s3.amazonaws.com/resources.magappzine.com/assets/core/" + environment() + "/loader.gif"}/></div>}
        <div className="live-video-ad" style={{height: '100%'}} data-sn-left="" data-sn-right="">
          {this.renderPlayer()}
          {!navbar_present && !isSectionLevel && 
            <span className="icon-hollow_arrow">
              <a id="overlay_video_back_btn" href="" onClick={(event) => this.backButton(event)} className={(runningOnMobile ? "backBtn-mobile" : "backBtn")} style={{zIndex: 100, ...ctv_style}}>
                {runningOnMobile && <img src={backMobile} alt="Back"/>}
              </a>
            </span>
          }
          {this.state.showPlayButton && <div className="video-play-extra-button">
            <img className="focusable" tabIndex={getTabIndex()} alt="Play" src={playButton} onClick={() => this.startVideo()}/>
          </div>}
          {showLiveIdleImage && <img className="idle-live-image" alt="Coming Soon" src={this.props.section.liveFeedImage && this.props.section.liveFeedImage.url} />}
        </div>
        {this.renderInstructionText()}
        {configuration.isAppTvodType && !this.props.userEngaged && <AudioMutedMessage _this={this} videoId={"live-video_html5_api"}/>}
        {!showLiveIdleImage && !configuration.isAppTvodType && !this.state.showPlayButton && this.props.section.locked && !isAvailable(this.props.section.identifier,this.props.section.special,this.props.section.registerWall) &&
          <Metering
            content_id={this.props.section.identifier}
            parent_id={this.props.section.identifier} 
            contentAccess={this.props.section.access}
            feeds={this.props.feeds}
            onFreeContentOver={() => this.onFreeContentOver()}
            playing_Ad={this.state.playing_Ad}
            showMetering={true}
            content={this.props.section}
            isLive={true}
            interstitialModalDetails={this.props.modal && this.props.modal.interstitialProps}
            video_start={this.state.video_start}
          />
        }
        {canShowCustomControls && !this.props.isPlayerSettingsModalOpen && <PlayerIcon videoId={this.getCTVVideoId()}/>}
        {canShowCustomControls && <PlayerProgressBar videoId={this.getCTVVideoId()} isLive/>}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  subscription: state.user.subscription,
  ads: state.ads,
  userEngaged: state.user.userEngaged,
  user_ip: state.user.ip,
  user_concent: state.user.userConcent,
  device_uuid: state.user.device_uuid,
  user_coords: state.user.user_coords,
  device_model: state.user.device_model,
  showSubtitle: state.feeds.showSubtitle,
  isUserOnline: state.user.isUserOnline,
  isPlayerSettingsModalOpen: state.user.playerSettingsModalOpen,
})
const mapDispatchToProps = {
  closeModal: closeModal,
  openModal: openModal,
  updateVideoCount: updateVideoCount
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LiveSetup));
