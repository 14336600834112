import { START_PURCHASE, GET_SESSION_ID_PENDING, GET_SESSION_ID_RESOLVE, GET_SESSION_ID_REJECT, CLEAR_PENDING_PURCHASE,
    CLEAR_ERROR_MESSAGE, CANCEL_SUBSCRIPTION_PENDING, CANCEL_SUBSCRIPTION_REJECT, CANCEL_SUBSCRIPTION_RESOLVE,
    RENEW_SUBSCRIPTION_RESOLVE, RENEW_SUBSCRIPTION_PENDING, RENEW_SUBSCRIPTION_REJECT, SUB_POLLING_START, SUB_POLLING_STOP, PLAY_AFTER_PURCHASE, TVOD_SUB_DETAILS_RESOLVE, TVOD_SUB_DETAILS_PENDING, TVOD_SUB_DETAILS_REJECT, CLEAR_SUBSCRIPTION_FLAG
} from "../constants/actionTypes";

const defaultState = {
    purchase: {
        isPending: false,
        details: {
            itemDetails: {}
        },
    },
    sessionId: null,
    paymentUrl: null,
    subPollingUrl: null,
    isLoading: false,
    errorMsg: "",
    isError: false,
    isSubscriptionCancelled: false,
    isRenewPending: false,
    isSubPolling: false,
    shouldPlayAfterPurchase: false,
    subPollData: null,
    tvodSubscriptionList: [],
    tvodSubscriptionListLoading: false,
    isSubscriptionRenewed: false
};
export default function reducer ( state = { ...defaultState }, action ) {
    switch (action.type) {
        case START_PURCHASE: {
            return {
                ...state,
                purchase: action.payload
            }
        }
        case CLEAR_PENDING_PURCHASE: {
            return {
                ...state,
                purchase: {
                    isPending: false,
                    details: {
                        itemDetails: {}
                    }
                },
                sessionId: null
            }
        }
        case CLEAR_ERROR_MESSAGE: {
            return {
                ...state,
                errorMsg: ""
            }
        }
        case GET_SESSION_ID_PENDING: {
            return {
                ...state,
                isLoading: true,
                errorMsg: "",
                sessionId: null,
                paymentUrl: null,
                subPollingUrl: null
            }
        }
        case GET_SESSION_ID_RESOLVE: {
            return {
                ...state,
                ...action.payload
            }
        }
        case GET_SESSION_ID_REJECT: {
            return {
                ...state,
                ...action.payload
            }
        }
        case CANCEL_SUBSCRIPTION_PENDING: {
            return {
                ...state,
                isLoading: true,
                isSubscriptionCancelled: false,
                errorMsg: ""
            }
        }
        case CANCEL_SUBSCRIPTION_RESOLVE: {
            return {
                ...state,
                isLoading: false,
                isSubscriptionCancelled: true
            }
        }
        case CANCEL_SUBSCRIPTION_REJECT: {
            return {
                ...state,
                isLoading: false,
                ...action.payload
            }
        }
        case RENEW_SUBSCRIPTION_PENDING: {
            return {
                ...state,
                isRenewPending: true,
                isSubscriptionRenewed: false,
                errorMsg: ""
            }
        }
        case RENEW_SUBSCRIPTION_RESOLVE: {
            return {
                ...state,
                isRenewPending: false,
                isSubscriptionRenewed: true
            }
        }
        case RENEW_SUBSCRIPTION_REJECT: {
            return {
                ...state,
                isRenewPending: false,
                errorMsg: action.payload.errorMsg,
                isSubscriptionRenewed: false
            }
        }
        case SUB_POLLING_START: {
            return {
                ...state,
                isSubPolling: true
            }
        }
        case SUB_POLLING_STOP: {
            return {
                ...state,
                isSubPolling: false,
                subPollData: action.payload
            }
        }
        case PLAY_AFTER_PURCHASE: {
            return {
                ...state,
                shouldPlayAfterPurchase: action.payload
            }
        }
        case TVOD_SUB_DETAILS_RESOLVE: {
            return {
                ...state,
                tvodSubscriptionList: action.payload,
                tvodSubscriptionListLoading: false,
            }
        }
        case TVOD_SUB_DETAILS_PENDING: {
            return {
                ...state,
                tvodSubscriptionList: [],
                tvodSubscriptionListLoading: true,
            }
        }
        case TVOD_SUB_DETAILS_REJECT: {
            return {
                ...state,
                tvodSubscriptionList: action.payload,
                tvodSubscriptionList: [],
                tvodSubscriptionListLoading: false,
            }
        }
        case CLEAR_SUBSCRIPTION_FLAG: {
            return {
                ...state,
                isSubscriptionRenewed: false,
                isSubscriptionCancelled: false,
                errorMsg: ""
            }
        }
    }
    return state;
}