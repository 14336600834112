import axios from "axios"
import { CLEAR_VIZIO_PURCHASE_ERROR, CLEAR_VIZIO_TV_PURCHASE, GET_VIZIO_TV_PLANS_PENDING, GET_VIZIO_TV_PLANS_REJECTED, GET_VIZIO_TV_PLANS_RESOLVED, VIZIO_PAYMENT_MODAL_CLOSE, VIZIO_PAYMENT_MODAL_OPEN, VIZIO_REQUEST_PENDING, VIZIO_TV_ACCOUNT_SUPPORTED_REJECTED, VIZIO_TV_ACCOUNT_SUPPORTED_RESOLVED, VIZIO_TV_LINKED_REJECTED, VIZIO_TV_LINKED_RESOLVED, VIZIO_TV_LINK_PENDING, VIZIO_TV_LINK_REJECTED, VIZIO_TV_LINK_RESOLVED, VIZIO_TV_PURCHASE_PENDING, VIZIO_TV_PURCHASE_REJECTED, VIZIO_TV_PURCHASE_RESOLVED, VIZIO_TV_REGISTER_PENDING, VIZIO_TV_REGISTER_REJECTED, VIZIO_TV_REGISTER_RESOLVED } from "../constants/actionTypes"
import { VIZIO_SIGNATURE_API } from "../constants/api"
import configuration from "../constants/config"

export const resetVizioError = () => {
    return dispatch => {
        dispatch({ type: CLEAR_VIZIO_PURCHASE_ERROR })
    }
} 

export const clearVizioPurchase = () => {
    return dispatch => {
        dispatch({ type: CLEAR_VIZIO_TV_PURCHASE })
    }
} 

export const vizioPaymentModalOpen = () => {
    return dispatch => {
        dispatch({ type: VIZIO_PAYMENT_MODAL_OPEN })
    }
}

export const vizioPaymentModalClose = () => {
    return dispatch => {
        dispatch({ type: VIZIO_PAYMENT_MODAL_CLOSE })
    }
}

export const checkIfVizioAccountSupported = () => {
    return async (dispatch) => {
        dispatch({ type: VIZIO_REQUEST_PENDING })
        try {
            const isPuchaseCapable = await window.VIZIO._deviceInfoStore.isPurchaseCapable
            if(!isPuchaseCapable) throw new Error('Vizio Account Not Supported')
            dispatch({ type: VIZIO_TV_ACCOUNT_SUPPORTED_RESOLVED })
            dispatch(registerVizioPartnerSignature())
        } catch (error) {
            dispatch({
                type: VIZIO_TV_ACCOUNT_SUPPORTED_REJECTED, 
                payload: {
                    isVizioTvAccountSupported: false,
                    error: error,
                    errorMsg: "Vizio TV Purchase Capability Check Failed."
                }
            })
        }
    }
}

export const registerVizioPartnerSignature = () => {
    const vizioKeyId = configuration.vizio_app_id
    const apiKey = configuration.api_key
    async function postData(url = '', data = {}) {
        //Returning Signature for Vizio Backend Services
        const response = await axios.post(url, data)
        return response.data.signature;
      }
    return async (dispatch) => {   
        dispatch({ type: VIZIO_TV_REGISTER_PENDING })
        try {
            const backendService = {
                backendUrl: VIZIO_SIGNATURE_API,
                signChallenge: async function (challenge) {
                    return await postData(`${this.backendUrl}/signature`, {"challenge_token": challenge, "key": apiKey})
                }
            }
            await window.VIZIO.Account.registerPartner(backendService.signChallenge.bind(backendService), vizioKeyId)
            dispatch({ type: VIZIO_TV_REGISTER_RESOLVED })
            dispatch(checkIfVizioTVAccountLinked())
        } catch (error) {
            dispatch({ type: VIZIO_TV_REGISTER_REJECTED , payload: error})
        }
    }
}

export const checkIfVizioTVAccountLinked = () => {
    return async (dispatch) => {
        dispatch({ type: VIZIO_REQUEST_PENDING })
        try {
            const isTvLinked = await window.VIZIO.Account.getLinkStatus();
            if(!isTvLinked) throw new Error('Vizio TV Account Not Linked')
            dispatch({ type: VIZIO_TV_LINKED_RESOLVED })
            dispatch(getVizioTvPlans())
        } catch (error) {
            dispatch({
                type: VIZIO_TV_LINKED_REJECTED, 
                payload: {
                    isVizioTvLinked: false,
                    error: error,
                    errorMsg: "Vizio TV Account Not Linked"
                }
            })
        }
    }
}

export const startVizioTvAccountLink = () => {
    return async (dispatch) => {
        dispatch({ type: VIZIO_TV_LINK_PENDING })
        try {
            const linkResult = await window.VIZIO.Account.startTVLink();
            if(!linkResult) throw new Error("Vizio TV Linking Failed")
            dispatch({ type: VIZIO_TV_LINK_RESOLVED })
            dispatch(checkIfVizioTVAccountLinked())
        } catch (error) {
            dispatch({
                type: VIZIO_TV_LINK_REJECTED, 
                payload: {
                    error: error,
                    errorMsg: "Vizio TV Linking Failed"
                }
            })
        }
    }
}

export const getVizioTvPlans = () => {
    return async (dispatch) => {
        dispatch({ type: GET_VIZIO_TV_PLANS_PENDING })
        try {
            const { data } = await window.VIZIO.Account.getPlans();
            dispatch({
                type: GET_VIZIO_TV_PLANS_RESOLVED, 
                payload: data
            })
        } catch (error) {
            dispatch({
                type: GET_VIZIO_TV_PLANS_REJECTED, 
                payload: {
                    error: error,
                    errorMsg: "Vizio TV Plans fetch failed"
                }
            })
        }
    }
}

export const startVizioTvPurchase = ({ planCode }) => {
    const vizioSourceId = configuration.vizio_source_key
    return async (dispatch) => {
        dispatch({ type: VIZIO_TV_PURCHASE_PENDING })
        try {
            const { data } = await window.VIZIO.Account.startSVODPurchase(planCode, vizioSourceId)
            dispatch({
                type: VIZIO_TV_PURCHASE_RESOLVED, 
                payload: data
            })
        } catch (error) {
            dispatch({
                type: VIZIO_TV_PURCHASE_REJECTED, 
                payload: {
                    error: error,
                    errorMsg: "Vizio TV Purchase failed"
                }
            })
        }
    }
}