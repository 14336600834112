import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";

import { changePreviewLocales } from '../actions/feedsActions';

import '../css/previewmode.css';


// Select Option Custom
export const Select = ({ optionsList, defaultText, callback }) => {
  const [showOptionList, setShowOptionList] = useState(false);

	useEffect(() => {
		document.addEventListener("click", handleClickOutside);
		return () => {
			document.removeEventListener("click", handleClickOutside);
		}
	}, [])

	const handleClickOutside = e => {
		if (
			!e.target.classList.contains("custom-select-option") &&
			!e.target.classList.contains("selected-text")
		) {
			setShowOptionList(false);
		}
	};

	const handleListDisplay = () => {
		setShowOptionList(!showOptionList);
	};

	const handleOptionClick = (option) => {
		setShowOptionList(false);
		callback(option)
	};

	return (
		<div className="custom-select-container">
			<div
				className={showOptionList ? "selected-text active" : "selected-text"}
				onClick={handleListDisplay}
			>
				{defaultText}
			</div>
			{showOptionList && (
				<ul className="select-options">
					{optionsList.map(option => {
						return (
							<li
								className="custom-select-option"
								// data-name={option.name}
								key={option.identifier}
								onClick={() => handleOptionClick(option)}
							>
								{option.name}
							</li>
						);
					})}
				</ul>
			)}
		</div>
	);
}

class PreviewMode extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			defaultLanguage: "",
			defaultLocale: "",
			langList: [],
			localeList: []
		}
	}

	componentDidMount() {
		this.setDefaultData();
	}

	componentDidUpdate(prevProps, prevState) {
		if(JSON.stringify(prevState.localeList) !== JSON.stringify(this.props.localesData)) {
			this.setDefaultData();
		}

		if(prevState.defaultLanguage && prevState.defaultLocale && 
			(prevState.defaultLanguage !== this.state.defaultLanguage || prevState.defaultLocale !== this.state.defaultLocale)
		) {
			const data = {
				locale_id: this.state.locale_id,
				language: this.state.defaultLanguage,
				localeName: this.state.defaultLocale,
			}
			this.props.changePreviewLocales(data);
		}
	}

	setDefaultData() {
		const {localesData, policyData} = this.props;
		if(!localesData || !policyData) return;

		let allData = {};
		localesData.forEach(element => {
			allData[element.identifier] = element;
		});
		this.allData = allData;
		this.setLanguageList(policyData.locale_id, policyData.language);
		this.setState({
			defaultLocale: policyData.localeName,
			localeList: localesData,
			locale_id: policyData.locale_id
		})
	}

	setLanguageList(identifier, language) {
		let selectedLocale = this.allData[identifier];
		if(!selectedLocale || !selectedLocale.languages || selectedLocale.languages.length === 0) return;
		let langList = [];
		selectedLocale.languages.forEach((lng, index) => {
			langList.push({
				identifier: `${index}-${lng}`,
				name: lng
			})
		})
		this.setState({
			defaultLanguage: language || langList[0].name,
			langList: langList
		})
	}

	handleLocaleChange(newValue) {
		this.setState({
			defaultLocale: newValue.name,
			locale_id: newValue.identifier
		})
		this.setLanguageList(newValue.identifier)
	}

	handleLanguageChange(newValue) {
		this.setState({defaultLanguage: newValue.name})
	}

	isShowingSelectors() {
		const { defaultLanguage, defaultLocale, localeList, langList} = this.state;
		if(!defaultLanguage || !defaultLocale || !localeList || !langList) return false;

		if(localeList.length > 1 || langList.length > 1) {
			return true
		}
		return false;
	}
 
	render() {
		return (
			<div className='preview-mode' id="preview-bar">
				<label className='title'>
					Preview Mode
				</label>
				{this.isShowingSelectors() && 
					<div className='right-content' >
						<label>Region :  </label>
						<Select
							defaultText={this.state.defaultLocale}
							optionsList={this.state.localeList}
							callback={(newValue) => this.handleLocaleChange(newValue)}
						/>
						<label>Language : </label>
						<Select
							defaultText={this.state.defaultLanguage}
							optionsList={this.state.langList}
							callback={(newValue) => this.handleLanguageChange(newValue)}
							/>
					</div>
				}
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	policyData: state.feeds.policyData,
	localesData: state.feeds.localesData,
})
  
const mapDispatchToProps = {
  changePreviewLocales: changePreviewLocales,
}
export default connect(mapStateToProps, mapDispatchToProps)(PreviewMode)
