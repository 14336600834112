import React from 'react';
import { httpsUrl } from "../constants/common"
import { isSmartTv } from '../helperfunctions/common';

class ContentSectionImage extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      section: {}
    }
  }

  componentDidMount(){
    if(isSmartTv()) {
      document.getElementById('nav-bar').style.zIndex = '100';
    } else {
      document.body.classList.remove("noNav");
    }
  }

  render(){
    return(
      <div className="imageFull">
          <img src={httpsUrl(this.props.section.contentUrl)} />
      </div>
    )
  }
}

export default ContentSectionImage;