export default function reducer(
  state={modal: {}, progress: {}, content: {}, locked: false}, action) {

  switch (action.type) {
    case "OPEN_MODAL": {
      return {
        ...state, modal: action.payload
      }
    }
    case "MODAL_PROGRESS": {
      return {
        ...state, progress: action.payload
      }
    }
    case "CLOSE_MODAL": {
      return {
        ...state, modal: {}, progress: {}
      }
    }

    case "SET_PREVIEW_VIDEO": {
      return {
        ...state, content: action.data
      }
    }

    case "SET_LOCKED_CONTENT":{
      return {
        ...state, locked: action.data
      }
    }

  }

  return state
}