import React from 'react';
import qs from 'query-string';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { resetPassword } from '../actions/userActions';
import { API_STATE } from '../constants/common';
import Loader from './Loader';

function ResetPassword(props) {
    const { reset_password_token: resetToken } = qs.parse(props.location.search);

    const [password, setPassword] = React.useState("");
    const [repeatPassword, setRepeatPassword] = React.useState("");
    const [error, setError] = React.useState(null);

    React.useEffect(() => {
        setError(null);
    }, [password, repeatPassword])

    React.useEffect(() => {
        if (props.reset.state === API_STATE.rejected) {
            if (props.reset.state.errors && props.reset.state.errors.reset_password_token)
                setError("Invalid token");
            else setError("Some error occurred");
        }
    }, [props.reset])

    const validate = () => {
        let validate = true;
        if (!password || !repeatPassword) {
            validate = false;
            setError("Password cannot be empty.")
        }

        if (password !== repeatPassword) {
            validate = false;
            setError("Passwords must be same.")
        }
        return validate;
    }

    const onSubmit = (e) => {
        e.preventDefault();

        if (!validate()) return null;

        props.resetPassword({app_user: {
            password: password,
            password_confirmation: repeatPassword,
            reset_password_token: resetToken
        }})
    }

    if (!resetToken) props.history.push('/');

    if (props.reset.state === API_STATE.loading)
        return <Loader />

    return (
        <div className="reset-page">
            <div>
            </div>
            <form onSubmit={onSubmit}>
                <div className="reset-content">
                    {props.reset.state === API_STATE.resolved
                    ? <>
                        <div className="message">
                            Password changed successfully, Please login again to continue.
                        </div>
                        <a target="_self" href="/">Homepage</a>
                    </>
                    : <>
                        <h4>Change your password</h4>
                        {error && <div className="error-div">{error}</div>}
                        <div className="password-div">
                            <Input
                                type="password"
                                onChange={setPassword}
                                value={password}
                                placeHolder={"New password"}
                                className="password-input"
                            />
                            <hr style={{
                                width: '100%',
                                borderBottom: '1px solid black'
                            }}/>
                            <Input
                                type="password"
                                onChange={setRepeatPassword}
                                value={repeatPassword}
                                placeHolder={"Confirm password"}
                                className="password-input"
                            />
                        </div>
                        <Input
                            type="submit"
                            value={"Reset Password"}
                            className="password-submit"
                        />
                    </>}
                </div>
            </form>
            <div className="reset-footer">
                <a target="_blank" href="https://www.mazsystems.com/outputs-terms">Terms of use</a>
                <a target="_blank" href="https://www.mazsystems.com/outputs-privacy">Privacy Policy</a>
            </div>
        </div>
    )
}

function Input({ onChange, ...props}) {
    return (
        <input
            type="text"
            onChange={ (e) => {
                e.preventDefault();
                onChange(e.target.value)
            }}
            {...props}
        />
    )
}

const mapStateToProps = (state) => ({
    reset: state.user.resetPassword
})

const mapDispatchToProps = {
    resetPassword
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPassword));
