import 'react-app-polyfill/stable';
import React from "react"
import { createRoot } from 'react-dom/client';
import App from './App';
import { checkCorrectPlatform, detectPlatform, isSmartTv } from './helperfunctions/common';
import { elementScrollIntoViewPolyfill } from "seamless-scroll-polyfill";
import configuration from './constants/config';
import { keyMappings, loadJsScript } from './helperfunctions/ctvHelper';
import { PLATFORM_ZEASN, throttle } from './constants/common';

elementScrollIntoViewPolyfill();
const app = document.getElementById('root')
const root = createRoot(app);
root.render(<App tab="home" />);

function inputEvent(e) {
    // if(checkCorrectPlatform([PLATFORM_ZEASN])) return;
    try {
        if(keyMappings(e.keyCode) == 'OK') {
            document.activeElement.click();
            if(configuration.platform !== 'xbox') {
                e.preventDefault();
            }
            if(checkCorrectPlatform([PLATFORM_ZEASN]) && document.activeElement.id != 'log_in_out_btn') {
                e.stopPropagation();
            }
        }
    } catch(er) {
        console.log(er);
    }
}

window.onload = ()  => {
    document.addEventListener('keydown', inputEvent, true);

    if(configuration.platform == 'xbox') {
        window.navigator.gamepadInputEmulation = "keyboard";
    } else if(configuration.platform == 'zeasn') {
        document.getElementById('root').classList.add('remove-scrollbar');
    }

    const paths = [
        "https://js.spotx.tv/directsdk/v1/85394.js",
        "https://imasdk.googleapis.com/js/sdkloader/ima3.js"
    ];
    if(!isSmartTv()){
        paths.push("https://www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1");
        paths.push("https://js.stripe.com/v3/");
    }
    for(let i=0;i<paths.length;i++) {
        loadJsScript({
            url: paths[i],
            location: document.body,
            type_to_set: 'async'
        });
    }

    if(isSmartTv() && window.tizen) {
        // for samsung TV
        const supported_keys = [
            'MediaPlayPause',
            'MediaPlay',
            'MediaPause',
            'MediaStop',
            'MediaFastForward',
            'MediaRewind',
            '0',
            '1',
            '2',
            '3',
            '4',
            '5',
            '6',
            '7',
            '8',
            '9'
        ];
        window.tizen.tvinputdevice.registerKeyBatch(supported_keys);
    }
};

window.onunload = () => {
    document.removeEventListener('keydown', inputEvent);
}


  /*
Vizio, binding the event handlers
*/
    // primary event handler
    document.addEventListener('VIZIO_LIBRARY_DID_LOAD', function(e) {
        // IFA - ID for Advertisement
        window.VIZIO.setAdvertiserIDListener(function(AdvertiserID) {
            window.VIZIO.ad_id = AdvertiserID;
        });

        /**
        * Device Language Handler
        *
        * ex: {code: "en", name: "English"}
        */
        window.VIZIO.setDeviceLanguageHandler(function(deviceLanguage){
            console.log("setDeviceLanguageHandler language: "+ deviceLanguage)
        });
        
        window.VIZIO.getDevicePlaybackQualities(function(qualities){
            // gives an array of playback qualities supported per device
            console.log("Device Playback Qualities: " + qualities)
        });
        
        window.VIZIO.getDeviceHDRCapabilities(function(capabilities){
            // example: ["DolbyVision","HDR10"]
            console.log("Device HDR Capabilities: " + capabilities)
        });

    }, false);