import React from 'react';
import Background from '../components/Background';
import Logo from '../components/Logo';
import { connect } from "react-redux";
import { pollingSession } from '../actions/userActions'
import { closeModal } from "../actions/modalActions"
import Loader from '../components/Loader'
import * as analytics  from '../constants/analytics';
import { isSmartTv } from '../helperfunctions/common';
import configuration from '../constants/config';
import { getTvodAPIKey } from '../constants/initializer';


const isTvod = configuration.isAppTvodType
class WebsiteLogin extends React.Component {
    constructor(props) {
        super(props);
        this.intervalTimer = null;
        this.loginPopup = null;
        this.state = {
            loading: true,
            text: "",
            code: ""
        }
    }

    componentDidMount() {
        //console.log('inside website login')
        const _self = this;
        let pollingUrl = _self.props.data.polling_url;
        const isAppTvodType = configuration.isAppTvodType;

        if(isAppTvodType) {
          pollingUrl = `${pollingUrl}${pollingUrl.includes("?") ? "&" : "?"}key=${getTvodAPIKey()}`
        }

        _self.props.pollingSession(pollingUrl)
        this.intervalTimer = setInterval(() => {
            _self.props.pollingSession(pollingUrl)
            if(this.loginPopup?.closed){
                this.props.stopLoading()
            }
        }, 5000)

        if(isSmartTv()) {
            this.setState({ text: this.props.data.sign_in_url, code: this.props.data.code });
        } else {
            this.loginPopup = this.popupWindow(this.props.data.sign_in_url, "", window, 450, 600)// window.open(this.props.data.sign_in_url, '_blank');
            if (!this.loginPopup) {
                this.setState({
                    text: "Please allow popup to open login page."
                })
            }
        }
    }

    popupWindow = (url, windowName, win, w, h) => {
        const y = win.top.outerHeight / 2 + win.top.screenY - ( h / 2);
        const x = win.top.outerWidth / 2 + win.top.screenX - ( w / 2);
        return win.open(url, windowName, `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`);
    }

    componentDidUpdate(prevProps) {
        if (!this.props.isPolling) {
            this.removeInterval();
            this.closeLoginWindow();
        }
        //Close modal, if error comes while polling.
        if (!prevProps.isPollingError && this.props.isPollingError)
            this.props.closeModal();
    }

    closeLoginWindow = () => {
        if (this.loginPopup) {
            this.loginPopup.close();
        }
        //analytics.setEvent('Registration', 'Login', 'Website')
    }

    removeInterval = () => {
        if (this.intervalTimer) {
            clearInterval(this.intervalTimer);
        }
    }

    componentWillUnmount() {
        this.removeInterval();
        this.closeLoginWindow();
    }

    onBack = () => {
        if (isSmartTv()) {
            this.props.stopLoading()
        }
    }

    render() {
        // const logo = this.props.feeds.logo && this.props.feeds.logo.url;
        // const background = this.props.feeds.loginUIMetadata.colors && this.props.feeds.loginUIMetadata.colors.background || null;
        if (this.state.loading) {
            return (
            <>
                {isSmartTv() && <button id="website_back_button_ctv" onClick={this.onBack} style={{display: "none"}}>back</button>}
                {isSmartTv() && this.state.text
                    ?   (<Loader
                            key={this.state.text}
                            loaderText={this.state.text}
                            loadingType="websiteScreen"
                            feeds={this.props.feeds}
                            code={isTvod ? this.state.code : null}
                        />)
                    : (<Loader key={this.state.text} loaderText={this.state.text}/>)
                }
            </>
            );
        }
    }
}

const mapStateToProps = (state) => ({
    isPolling: state.user.isPolling,
    isPollingError: state.user.isPollingError
})

const mapDispatchToProps = {
    pollingSession,
    closeModal
}

export default connect(mapStateToProps, mapDispatchToProps)(WebsiteLogin);
