import React from 'react'
import { connect } from "react-redux"
import { forgotPassword } from "../actions/userActions"
import { getTabIndex } from '../helperfunctions/common';
import { getAllConstants } from '../helperfunctions/regionalization';
import ErrorDisplay from "./ErrorDisplay"
import { environment } from '../constants/initializer';

let ALL_C = {};
class ForgotPassword extends React.Component{
    constructor(props){
        super(props);
        this.resetPassword = this.resetPassword.bind(this)
        this.dismissAction = this.dismissAction.bind(this)
        this.state = {error: false, errorHeading: "", errorMsg: ""}
        ALL_C = getAllConstants();
    }

    componentDidUpdate(prevProps) {
        if(prevProps !== this.props) {
            if (this.props.user_error && !this.props.user.success){
                this.setState({error: true, errorHeading: "", errorMsg: this.props.user.error})
            } else {
                this.setState({error: true, errorHeading: "Email Sent", errorMsg: "Please check your email for instructions on how to reset your password."})
            }
            this.setState({showSpinner: false});
        }
    }

    dismissAction(e){
        this.props.dismiss()
        if(e) {
            e.preventDefault();
        }
    }
    resetPassword(e){
        e.preventDefault();
        this.setState({showSpinner: true});
        this.props.forgotPassword(this.props.email, this.props.app_id)
    }
  
    render(){
        document.body.className = "Reset"
        document.getElementsByClassName('logoHolder')[0].style.display = "none";
        if(this.state.error){
            return <ErrorDisplay dismiss={this.dismissAction} errorMsg={this.state.errorMsg} errorHeading={this.state.errorHeading} />
        } else {
            return (
                <div className="rePassword">
                    <div className="reContent">
                        <p id="reset_pswd_text">{ALL_C.kDoYouWantResetPassword}</p>
                        <a aria-describedby='reset_pswd_text' href="#" onClick={this.resetPassword} className="btn-reset focusable" tabIndex={getTabIndex()}>{ALL_C.kISActionReset}</a><br/>
                        <a aria-describedby='reset_pswd_text' href="#" onClick={this.props.dismiss} className="focusable" tabIndex={getTabIndex()}>{ALL_C.kCancel}</a>
                    </div>
                    {this.state.showSpinner && <div className="spinner">
                        <img src={"https://s3.amazonaws.com/resources.magappzine.com/assets/core/" + environment() + "/loader.gif"}/>
                    </div>}
                </div>
            )
        }
    }
}


const mapStateToProps = (state) => ({
    user_error: state.user.error,
    user: state.user.user
})

const mapDispatchToProps = {
    forgotPassword: forgotPassword
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)