import React from 'react';
import { connect } from "react-redux"
import { isIOS, isMobile } from 'mobile-device-detect';
import { withRouter } from 'react-router-dom'

import { localRememberSpotBatch } from '../actions/rememberSpotActions'
import { closeModal, modalProgress,openModal } from '../actions/modalActions'
import { httpsUrl, getSpotxIframe, removeSpotxIframe, getSpotXId, DEEPLINK, INLINE_MENU_IDLE_TIMEOUT, EPG_THEME, PLATFORM_HTML_SIMULATOR, MAZ_REPORTING_INTERVAL, PLATFORM_LG, PLATFORM_VIZIO, PLATFORM_SAMSUNG, PLATFORM_XBOX, PLATFORM_ZEASN, TRAILER_CATALOG, K_TOTAL_WATCHED_TIME_BEACONING } from "../constants/common"
import { updateVideoCount } from "../actions/adsActions"
import { environment } from "../constants/initializer"
import isAvailable from '../helperfunctions/is_available'
import InlineMenu from "./InlineMenu";
import * as analytics  from '../constants/analytics';
import Metering from './Metering';
import { getMeteringTypes } from '../constants/common';
import { checkIfSafari, onVideoError, getTabIndex, onCTVVideoError, getItemCuePoints, checkCorrectPlatform, getSecondsFromDuration, getVttCaption, videoType, fullscreen, modifiedPlaybackUrl, setPlaylistVideoCounter, handleUnmutedPlayback } from '../helperfunctions/common';
import { backButtonObj, getParentFeed } from '../helperfunctions'
import { isSmartTv } from '../helperfunctions/common';
import { showAdsAsPerFrequency, runAdInVideo, constructSpotxParams, getModifiedAdUrl } from '../helperfunctions/adHelper';
import backMobile from "../images/icons/back-mobile.png";
import configuration from '../constants/config';
import { sendVideoMinutes } from '../helperfunctions/mazAnalytics';
import playButton from "../images/icons/play-button.png";
import { pushTvodStreamData } from '../actions/streamActions';
import PlayerIcon from './icons/PlayerIcon';
import { InstructionText } from './InstructionText';

import 'videojs-contrib-ads';
import 'videojs-ima';
import { addRemoveAllNavigation, keyMappings, retainFocusOnBackPress, toggleSubtitlesVisibility } from '../helperfunctions/ctvHelper';
import PlayerProgressBar from './icons/PlayerProgressBar';
import ContinueWatching from './ContinueWatching';
import TheoPlayer from './TheoPlayer';
import Image from './Image';
import JsSpatialNavigation from '../ctvnavigation/js-spatial-navigation';
import { getMMVideoAssetInfo, resettingVideoSource, startSendingMMEvents } from '../helperfunctions/mediaMelon';

import videojs from 'video.js';
import { addTextTracksEventVideoJs, updateTextTrack, videojsQualityHelper } from '../helperfunctions/videojsHelper';
import AudioMutedMessage from './AudioMutedMessage';
import { setDefaultValuesInVideoJs } from './player/PlayerSettingsMenu';
import { setLocalStorage, removeItemLocalStorage } from '../helperfunctions/storage';

// for web only
// not supported on ios
if (!isSmartTv() && !isIOS) {
  // replace default fullscreen toggle button
  // this button makes viewport body fullscreen
  videojs.registerPlugin("fullscreen", () => {
    const button = fullscreen().button;
    document.getElementsByClassName('vjs-control-bar')[0].appendChild(button);
  })
}

let hideMenuTimeout = null;
let current_player,current_src,current_type;

function NextVideoThumbnail(currentTime, totalTime){
  var spotxIframe = getSpotxIframe();
  if(!spotxIframe){
    if(document.getElementById("thumb-video_html5_api")){
      var video = document.getElementById("thumb-video_html5_api");
    } else {
      var video = document.getElementById("thumb-video");
    }
    let current;
    let total;
    if(configuration.kUseTheoPlayer) {
      current = currentTime;
      total = totalTime
    } else if(video) {
      current = video.currentTime;
      total = video.duration;
    }
    if(current && total && (Math.ceil(total - current) < 11)){
      if (document.getElementById("next-video")) {
        document.getElementById("next-video").style.display = "inline"
        // upnext and title does not appear together, when upNext ui is coming
        if (document.querySelector(".prevThumbnail.video-title")) document.querySelector(".prevThumbnail.video-title").style.display = "none";
        if (document.getElementById("MeteringCounter")) document.getElementById("MeteringCounter").style.display = "none";
      }
      if (document.getElementById("video-inlineMenu")) {document.getElementById("video-inlineMenu").style.display = "none"}    
      if (document.getElementsByClassName("nextVideoSeconds")[0]) document.getElementsByClassName("nextVideoSeconds")[0].innerHTML = Math.ceil(total - current)
    } else {
      if (document.getElementById("next-video") && document.getElementById("next-video").style.display === "inline" ){
        document.getElementById("next-video").style.display = "none";
        if (document.querySelector(".prevThumbnail.video-title")) document.querySelector(".prevThumbnail.video-title").style.display = "block";
        if (document.getElementById("MeteringCounter")) document.getElementById("MeteringCounter").style.display = "block";
        if (document.getElementById("video-inlineMenu")) {
          document.getElementById("video-inlineMenu").style.display = "inherit";
          hideMenuTimeout = setTimeout(() => {
            if (document.getElementById("video-inlineMenu")){
              document.getElementById("video-inlineMenu").style.display = "none" ;
            }
          }, INLINE_MENU_IDLE_TIMEOUT)
        }    
      }
    }
  }
}

function get_parent_section(section_id,contents){
  var sections = this.props.feeds.sections
  var content;
  var pathLocation = section_id.split("/")
  if(isNaN(pathLocation[0])){return "settings"}
  for(var i=0; i<pathLocation.length - 1; i++){
    for(var j=0; j<sections.length; j++){
      if(sections[j].identifier === pathLocation[i]){
        content = sections[j]
        sections = sections[j].contents
      }
    }
  }
  if(content.regularLayout === "detail" || pathLocation.length < 3){
    pathLocation = pathLocation.slice(0,-1)
  } else {
    pathLocation = pathLocation.slice(0,-2)
  }
  pathLocation = pathLocation.join("/")
  return pathLocation
}

function showVideoAd(state, props){
  var adToShow = props.ads.spotxid.length > 0;
  const feedAd = isAdForFeed(props.modal, state.section);
  adToShow = adToShow && feedAd && window.SpotX
  var showAd = false
  if(adToShow){
    showAd = showAdsAsPerFrequency(props.ads, state.video_count);
  }

  if(showAd){
    analytics.setEvent('Ads', 'Video Ad Play', 'SpotX')
  }
  // return false;
  return showAd
}

function isAdForFeed(modal, section) {
  if(section.mazContentType === "saved") return false; // saved section in mazId feed
  if(modal.head === "saved_items") return false; // saved section
  if(section.catalog === TRAILER_CATALOG) return false; // trailer
  return (modal.show_ad || section.showAd);
}

function PlayVideo(url, type, next_section, props, state, showAd, _this){
  const isEpg = state.isEPG;
  if(configuration.kUseTheoPlayer) return;
  if(showAd && !isSmartTv()){
    if (document.getElementById("video-inlineMenu")) {document.getElementById("video-inlineMenu").style.display = "none"}
    if (document.getElementById("next-video")) document.getElementById("next-video").style.display = "none"
    var adContainer = document.getElementsByClassName('videoFull')[0];
    const cust_params = constructSpotxParams(props.feeds, state.section);
    if(document.getElementById("thumb-video_html5_api")){
      adContainer = document.getElementById("thumb-video");
      var directAdOS = new window.SpotX.DirectAdOS({
        channel_id: getSpotXId(props.ads),
        slot: adContainer,
        video_slot: document.getElementById("thumb-video_html5_api"),
        hide_skin: false,
        autoplay: true,
        custom: {...cust_params}
      });
      directAdOS.loadAd();
    } else {
      var directAdOS = new window.SpotX.DirectAdOS({
        channel_id: getSpotXId(props.ads),
        slot: adContainer,
        video_slot: document.getElementById("thumb-video"),
        hide_skin: false,
        autoplay: true,
        custom: {...cust_params}
      });
      directAdOS.loadAd();
    }
  } else {
    if (document.getElementById("video-inlineMenu")) {document.getElementById("video-inlineMenu").style.display = "inherit"}    
    if (document.getElementById("next-video")) document.getElementById("next-video").style.display = "none"
    const vTime = props.remember[state.section.identifier];
    let videoParams = { 
      loadingSpinner: false,
      html5: {
        vhs: {
          overrideNative: !isSmartTv(),
        },
        nativeTextTracks: false
      },
      controlBar: {
        // enable default fullscreen toggle button if on ios
        fullscreenToggle: isIOS,
        pictureInPictureToggle: false,
        subsCapsButton: false,
        audioTrackButton: false,
        seekToLive: false
      },
      textTrackSettings: true
    };
    if(props.runningOnMobile && !isEpg) {
      videoParams.controls = true; //this line necessary for mobile to show videojs control bar on screen click 
    }
    if(isSmartTv() && !checkCorrectPlatform([PLATFORM_XBOX])) {
      videoParams.loadingSpinner = true;
      videoParams.controls = false;
    } else if(checkCorrectPlatform([PLATFORM_XBOX])) {
      videoParams.loadingSpinner = true;
      videoParams.controls = true;
    }

    const player = videojs('thumb-video', videoParams);
    videojsQualityHelper(player)

    // for web only
    // not supported on ios
    // register fullscreen plugin which make viewport body in fullscreen instead of video
    if(!isSmartTv() && !isIOS) {
      player.fullscreen();
    }

    current_player = player;
    current_src = url;
    current_type = type;
    player.on('error', function(e) {
      _this.setState({ isShowCountineWatching: false })
      if(isSmartTv()) {
        onCTVVideoError(e, player);
      } else {
        onVideoError(type, player);
      }
    });
    player.on('pause', () => {
      setTimeout(() => {
        const vid = document.getElementById("thumb-video_html5_api");
        if(_this && _this.state && !_this.state.playing_Ad && vid && vid.paused) {
          prepareMazEventData(_this, "video_paused");
          _this.videoPausedByUser = true;
        }
        // pause event trigger for everything even if video ended so handling it by delaying method call
      }, 1000)
    })
    player.on("play", () => {
      if(_this.videoPausedByUser) {
        resetMazEventData(_this);
        _this.videoPausedByUser = false;
      }
    })
    player.on('ended', () => {
      // <Video> onEnded event not triggering for mobile devices so handling that here
      if(props.runningOnMobile && !_this.state.adWrapperExist && _this.videoTotalDuration && player && player.currentTime() && (_this.videoTotalDuration - player.currentTime() < 1)) {
        _this.setNextVideo(state.next_index, true)
      }
    });
    const streamProgress = (configuration.isAppTvodType && props.allStreamObject[state.section.cid] && props.allStreamObject[state.section.cid].progress) || null;
    player.ready(() => {
      let playbackUrl = modifiedPlaybackUrl(props, url, state.section, player);
      player.src({src: playbackUrl, type: type, ...getMMVideoAssetInfo(state.section)})
      const feedAd = isAdForFeed(props.modal, state.section);
      runAdInVideo(player, props, state, _this, feedAd, "thumb-video_html5_api", state.section);
      startSendingMMEvents(player, playbackUrl, state.section, false)
      const promise = player.play();
      configuration.isAppTvodType && handleUnmutedPlayback(promise, player, "thumb-video_html5_api", _this.props.userEngaged, _this.showBigPlayButton);
      if(isIOS && player.playsinline) {
        player.playsinline(true);
      }
      var newVideo = document.getElementById("thumb-video_html5_api")
      if(isSmartTv()) {
        newVideo.removeAttribute('tabIndex');
      }
      newVideo.muted = false
      newVideo.setAttribute('type', type)
      newVideo.controlsList = "nodownload"

      newVideo.addEventListener("contextmenu", (event) => {
        event.preventDefault();
      });

      if(checkCorrectPlatform([PLATFORM_ZEASN])) {
        newVideo.addEventListener('play', function() {
          let setTime = setRememberSpot.call(this, streamProgress, vTime, newVideo, state);     
        }, { once: true });
      } else {
        newVideo.addEventListener("loadedmetadata", function() { 
          let setTime = setRememberSpot.call(this, streamProgress, vTime, newVideo, state);
          if(props.runningOnMobile && state.playing_Ad) { // playing ad
            _this.setRememberSpotAfterAd = setTime
          }
        }, {once: true})
      }
      
      const showVideoAd = props.ads && feedAd && props.ads.videoAdtype && props.ads.videoAdtype !== "spotX" && showAdsAsPerFrequency(props.ads, state.video_count)
      const nonVmapAdBreak = (showVideoAd && (props.ads.nonVmapAdBreakFrequency || props.ads.nonVmapCuePointKey));
      const adBreakData =  nonVmapAdBreak ? {
        state,
        _this, 
        player, 
        nonVmapAdBreakFrequency: props.ads.nonVmapAdBreakFrequency,
        nonVmapCuePointKey: props.ads.nonVmapCuePointKey
      } : null;
      newVideo.addEventListener("timeupdate", () => handleVideoTimeUpdate(next_section, adBreakData, _this), false)
      const vttCaption = getVttCaption(state.section);
      vttCaption && player.addRemoteTextTrack({src: vttCaption, default: _this.props.showSubtitle}, false);
      if (_this.props.showSubtitle && !vttCaption) {
        addTextTracksEventVideoJs(player)
      }
      isSmartTv() && setDefaultValuesInVideoJs({player})
    })
    
  }
}

function setRememberSpot(streamProgress, vTime, newVideo, state) {
  if(document.getElementById("loader")) {
    document.getElementById("loader").style.display = "none";
  }

  let setTime;

  if(state.isEPG) {
    // Do nothing, Not forwarding for EPG - AC- 503
  } else if(streamProgress) {
    const totalTime = newVideo.duration || getSecondsFromDuration(state.section.duration);
    if(streamProgress !== 100 && totalTime) {//to restart the video
      const timetoSeek =  Math.floor((streamProgress * totalTime) / 100);
      this.currentTime = timetoSeek;
      setTime = timetoSeek;
    }
  } else if (vTime && (vTime["p"] != vTime["t"])) {
    setTime = vTime["p"];
    this.currentTime = vTime["p"];
  }

  return setTime;
}

function handleVideoTimeUpdate(next_section, adBreakData, _this) {
  if(next_section) {
    NextVideoThumbnail()
  }

  handlefocusOfNextUIforCTV(_this);

  if(document.getElementById("thumb-video_html5_api")){
    var video = document.getElementById("thumb-video_html5_api");
  } else {
    var video = document.getElementById("thumb-video");
  }

  if(video) {
    handleVideoMinutes(video, _this);
    _this.handlePercentAnalytics();
    if(!_this.state.playing_Ad && video.duration && !_this.videoTotalDuration) {
      _this.videoTotalDuration = video.duration;
    }
  }

  if(adBreakData && video && !_this.state.playing_Ad) {
    const currentTime = Math.floor(video.currentTime);
    const {state, _this, player, nonVmapAdBreakFrequency, nonVmapCuePointKey} = adBreakData;
    let timeMatched = false;
    if(nonVmapAdBreakFrequency && (currentTime % (nonVmapAdBreakFrequency * 60) === 0)) {
      timeMatched = true;
    } else if(nonVmapCuePointKey && state.section && state.section.cuePoints) {
      const cuePoints = getItemCuePoints(state.section.cuePoints);
      if(cuePoints.indexOf(currentTime) > -1) {
        timeMatched = true;
      }
    }
    if(currentTime !== 0 && currentTime !== _this.state.setTimeAfterAdBreak && timeMatched) {
      if(!(player && player.ima && typeof player.ima === "object")) return;
      _this.pushTvodStreams();
      _this.saveVideoTimeInRemeberSpot(video);
      player.ima.initializeAdDisplayContainer();
      player.ima.setContentWithAdTag(null, getModifiedAdUrl(_this.props, state.section, player));
      player.ima.requestAds();
      player.ima.playAdBreak();
      _this.setState({setTimeAfterAdBreak: currentTime});
    }
  }

  if(_this.state && _this.state.isEPG && _this.state.section && !_this.state.playing_Ad) {
    const { epgEndTimeSec, epgStartTimeSec, epgContainerEndTimeSec, epgContainerStartTimeSec} = _this.state.section;
    const nowTime = getSecondsFromDuration(new Date().toLocaleTimeString('en-GB'));
    if(nowTime > (epgEndTimeSec % 86400) || nowTime > (epgContainerEndTimeSec % 86400)) {
      // Time ended start next video
      if(_this.state.adWrapperExist) _this.setState({adWrapperExist: false});
      _this.setNextVideo(_this.state.next_index);
    } else if(nowTime < (epgStartTimeSec % 86400) || nowTime < (epgContainerStartTimeSec % 86400)) {
      // Stop player
      _this.setNextVideo();
    }
  }
}

function handlefocusOfNextUIforCTV(_this) {
  if(isSmartTv()) {
    if(document.getElementById("next-video") && document.getElementById("next-video").style.display == 'inline') {
      if(_this.state.ctv_focus_on != '#next-video') {
        _this.setState({ ctv_focus_on: '#next-video' })
      }
    } else {
      if(_this.state.ctv_focus_on != '#inline_arrow') {
        _this.setState({ ctv_focus_on: '#inline_arrow' })
      }
    }
  }
}

function handleVideoMinutes(videoEl, _this) {
  const time = Math.floor(videoEl.currentTime);
  const tier = videoEl.videoHeight;
  if(time !== _this.streamedVideoTime) {
    _this.streamedVideoTime = time;
    _this.streamedVideoSeconds++;
  }
  if(tier && _this.streamedVideoTier && tier !== _this.streamedVideoTier) {
    prepareMazEventData(_this, "tier_changed");
  } else if(_this.streamedVideoSeconds === MAZ_REPORTING_INTERVAL) {
    prepareMazEventData(_this, "beacon");
  }

  if(tier) {
    _this.streamedVideoTier = tier;
  }
}

function prepareMazEventData(_this, type) {
  const {streamedVideoSeconds, streamedVideoTier, videoStartTime, state, props} = _this;
  if(streamedVideoSeconds == null || !streamedVideoTier || !state) {
    return;
  }
  const {section: { identifier, contentUrl }, video_path, video_title} = state;
  const data = {
    eventValue: streamedVideoSeconds.toString(),
    eventStartTime: videoStartTime,
    tierStreamed: `${streamedVideoTier}px`,
    contentId: identifier,
    contentUrl: contentUrl,
    eventAction: type
  }

  resetMazEventData(_this);
  sendVideoMinutes(data, props.feeds, video_path, video_title)
}

function resetMazEventData(_this) {
  if(_this) {
    _this.videoStartTime = new Date().toISOString();
    _this.streamedVideoSeconds = 0;
  }
}

function NextVideo(all_contents, index){
  var content;
  var nextIndex;
  for(var i=index+1; i<all_contents.length; i++){
    if(all_contents[i].type === "vid") {
      content = all_contents[i]
      nextIndex = i
      break;
    }
  }
  return [content, nextIndex]
}

function ChangeRoute(identifier, attachMode){
  var currentLocation = window.location.pathname.split('/')
  currentLocation = currentLocation.slice(0,-1)
  currentLocation.push(identifier)
  currentLocation = currentLocation.join("/")
  if(configuration.isAppTvodType && attachMode) {
    return `${currentLocation}?mode=watch`
  }
  return currentLocation
}

function FindVideo(identifier, all_contents){
  var videoIndex = 0;
  for(var i=0; i<all_contents.length; i++){
    if(identifier === all_contents[i].identifier){
      videoIndex = i;
      break;
    }
  }
  return videoIndex;
}

//Function to toggle fullscreen on dbl click (Safari only, Not implemented yet), Just call this function on video double click
function toggleFullscreen(video) {
  if (checkIfSafari()) {
    if (document.webkitIsFullScreen) {
      document.webkitExitFullscreen();
    } else {
      video.webkitRequestFullscreen && video.webkitRequestFullscreen();
    }
  }
}

function onVideoPauseCheck() {
  const videoObj = document.getElementsByTagName('video')[0];
  if (videoObj && !videoObj.controls) {
    if(videoObj.play) videoObj.play();
    videoObj.removeEventListener('pause', onVideoPauseCheck)
  }
}
class OverlayVideo extends React.Component {
  constructor(props){
    super(props)
    var videoIndex = FindVideo(props.section.identifier, props.all_contents);
    let section = props.section;
    if(props.modal.head === "search"){
      section = this.addParentFeedDataForSearch(section);
    }
    if (section.mazContentType && section.lineage) {
      section = this.getSectionParentFromLineage(section)
    }
    var type = videoType(section.contentUrl)
    var nextVideoData = NextVideo(props.all_contents, videoIndex)
    this.state = {
      all_contents: props.all_contents,
      index: videoIndex,
      type: type,
      section: section,
      next_section: nextVideoData[0],
      next_index: nextVideoData[1],
      video_path: props.modal.firstpath,
      next_video: false,
      currentView: '',
      rememberSpotBatch: {},
      setTimeAfterAdBreak: null,
      lineage: "",
      video_count: 0,
      video_title: section.title,
      purchased: false,
      playing_Ad: false,
      adWrapperExist: false,
      videoInQueue: undefined,
      showTitle: false,
      showMetering: false,
      isEPG: props.modal.head === EPG_THEME,
      showPlayButton: configuration.isAppTvodType ? false : !this.props.userEngaged,
      video_start: false,
      afterMount: false,
      ctv_focus_on: '#inline_arrow',
      isShowCountineWatching:true
    }
    this.maintainTitle = this.maintainTitle.bind(this)
    this.updateState = this.updateState.bind(this)
    this.onAdComplete = this.onAdComplete.bind(this)
    this.onAdStart = this.onAdStart.bind(this)
    this.onAdReady = this.onAdReady.bind(this)
    this.onAllAdComplete = this.onAllAdComplete.bind(this)
    this.showBigPlayButton = this.showBigPlayButton.bind(this)
    this.simulatorInterval = null;
    this.simulatorCount = 0;
    this.videoStartedAt = new Date();
    this.streamedVideoTier = null;
    this.streamedVideoSeconds = 0;
    this.streamedVideoTime = 0;
    this.videoStartTime = new Date().toISOString();
    this.videoPausedByUser= false;
    this.video_stream_buffered = 0;
    this.eventSentForVideo = [];
    this.totalWatchedTimeInterval = null;
  }

  totalWatchTimeCalculator() {

    if(this.props.feeds.kTotalWatchedTimeBeaconing){
      const { section, video_path } = this.state;

      this.totalWatchedTimeInterval = setInterval(() => {
        let key = K_TOTAL_WATCHED_TIME_BEACONING
        let currentWatchedTime = Math.floor((new Date() - this.videoStartedAt) / 1000)
        let data = {
          contentUrl: section.contentUrl,
          path: video_path,
          videoItem: section,
          duration: currentWatchedTime
        }
        setLocalStorage(key, {...data})
      }, 60 * 1000);
    }
  }

  updateState(){
    if (this.props.modal.deepLink !== DEEPLINK && !isSmartTv()) {
      const sectionItem = document.getElementsByClassName("item-" + this.state.section.identifier)[0]
      sectionItem && sectionItem.classList.add("slideHover")
    }
    document.body.classList.remove("noNav")
    // var video = document.getElementById("thumb-video");

    // var spotxIframe = getSpotxIframe();

    // if(spotxIframe) {
    //   removeSpotxIframe();
    // } else {
    //   this.saveVideoTimeInRemeberSpot(video);
    //   if(video) videojs(video).dispose();
    // }
  }

  saveVideoTimeInRemeberSpot = (videoEle) => {
    if(configuration.isAppTvodType) return;
    if(!configuration.kUseTheoPlayer &&  !videoEle) return;
    if(configuration.kUseTheoPlayer && !this.theoPlayerReference) return;
    var rememberSpotBatch = {...this.state.rememberSpotBatch}
      rememberSpotBatch[this.state.section.identifier] = {
        p: this.theoPlayerReference ? this.theoPlayerReference.currentTime : videojs(videoEle).currentTime(),
        t: this.theoPlayerReference ? this.theoPlayerReference.duration : videojs(videoEle).duration(),
        saved: false,
        type: this.state.section.type
      }
      const {t, p} = rememberSpotBatch[this.state.section.identifier];
      if (!isNaN(t) && !isNaN(p)) {
        this.props.rememberSpot(this.props.app_id, rememberSpotBatch, this.props.logged_in, this.props.user.auth_token,
          this.props.user.user_id)
      }
  }

  onLinkCLick(e) {
    e && e.preventDefault()
    this.updateState();
    if(this.props.handleKeepInterstitialOpen) {
      this.props.handleKeepInterstitialOpen();
      return;
    }
    this.props.closeModal()
    this.props.updateVideoCount(this.state.video_count + 1)

    if (this.props.modal.deepLink === DEEPLINK) {
      const backUrlObj = backButtonObj(this.props.feeds, window.location.pathname.split('/').slice(3).join('/'));
      this.props.history.replace(backUrlObj.backPath);
      this.props.modal.callbackParent(this.state.section.identifier);
    } else {
      window.history.replaceState("view", {}, this.props.modal.pathname)
    }
  }

  handleRemoteClick = (e) => {
    if(parseInt(e.keyCode) != 10009 && document.getElementById("video-inlineMenu") && !document.getElementById('video-inlineMenu').classList.contains('closedMenu')) {
      return;
    }
    if(document.getElementById("continue-watching")) {// Continue watching modal is up
      return;
    }
    if(document.querySelector('.player-setting-modal')) return; //Player Setting Modal is UP;

    if(document.activeElement?.className?.includes("headerLinks navbar")) {
      return;
    }

    const isFocusOnControls = document.querySelector('#custom-control-bar') && document.querySelector('#custom-control-bar').contains(document.activeElement);
    const player = document.getElementById("thumb-video_html5_api");
    let current_time;
    if(this.state.playing_Ad || this.state.isEPG || !player || configuration.kUseTheoPlayer) return;
    
    switch(keyMappings(e.keyCode)) {
      case 'OK':
        if (isFocusOnControls) break;
        if(player && player.paused) {
          player.play();
        } else {
          player && player.pause();
        }
      break;
      
      case 'PLAY': //media play
        if(player && player.paused) {
          player.play();
        }
      break;

      case 'PAUSE': //media pause
        if(player && !player.paused) {
          player.pause();
        }
      break;

      case 'PLAY-PAUSE': //media playpause
        if(player && player.paused) {
          player.play();
        } else {
          player && player.pause();
        }
      break;

      case 'LEFT': // left
        if (isFocusOnControls) break;
        current_time = player.currentTime;
        player.currentTime = current_time - 10;
      break;
      
      case 'RIGHT': // right
        if (isFocusOnControls) break;
        current_time = player.currentTime;
        player.currentTime = current_time + 10;
      break;

      case 'FORWARD': //forward
        current_time = player.currentTime;
        player.currentTime = current_time + 10;
      break;

      case 'REWIND': // backward
        current_time = player.currentTime;
        player.currentTime = current_time - 10;
      break;

      case 'STOP': // stop video
        if(checkCorrectPlatform([PLATFORM_LG, PLATFORM_ZEASN, PLATFORM_SAMSUNG]) && document.getElementById("overlay_video_back_btn")) {
          setTimeout(() =>{
            document.getElementById("overlay_video_back_btn").click();
            retainFocusOnBackPress(window.last_focus, null, window.location.pathname);  
          }, 500)    
        } else {
          if(player && !player.paused) {
            player.pause();
            player.currentTime = 0;
          }
        }
      break;
    }
  }

  componentDidMount(){
    document.body.classList.add("noNav")
    this.setState({afterMount: true})
    this.setVideoAnalytics();
    this.totalWatchTimeCalculator()
    if(!configuration.isAppTvodType) {
      window.history.pushState("new video", {}, this.props.modal.firstpath)
    }
    this.props.modalProgress({head: this.props.modal.head, index: this.state.index, item: this.state.section.identifier, first: true})
    if(!this.state.showPlayButton) {
      this.startVideo()
    } 
    document.body.addEventListener("mousemove", this.cancelHideMenuTimeout, true);

    if(document.getElementById("thumb-video")) {
      document.getElementById("thumb-video").focus();
    }
    if(isSmartTv()) {
      if(document.getElementById('video-inlineMenu')) {
        document.getElementById('video-inlineMenu').focus();
      }

      if(document.getElementById('thumb-video_html5_api')) {
        document.getElementById('thumb-video_html5_api').focus();

        document.getElementById('thumb-video_html5_api').addEventListener('loadedmetadata',(e) => {
          this.setState({ video_start: true });
        },{once: true});
      }
      document.addEventListener('keydown', this.handleRemoteClick);
      
      if(configuration.use_light_sliders) {
        this.video_id = addRemoveAllNavigation({ selector: '.videoFull .focusable' });
      }
    }

    if(configuration.is_simulator && checkCorrectPlatform(PLATFORM_HTML_SIMULATOR) && !checkIfSafari()) {
      // For Bug simulator Bug https://github.com/MagAppZine/MagAppZine-MagControl/issues/8267
      this.simulatorInterval = setInterval(() => {
        this.checkVideoStatusForSimulator()
      },1000)
    }

    if(this.state.showPlayButton) {
      JsSpatialNavigation.focus(this.video_id);
    }
  }

  startVideo() {
    const video = document.getElementById("thumb-video");
    if(video || configuration.kUseTheoPlayer) {
      let showAd = false
      if(this.props.ads.spotxid !== undefined){
        showAd = showVideoAd(this.state, this.props)
      }
      PlayVideo(httpsUrl(this.state.section.contentUrl), this.state.type, this.state.next_section, this.props, this.state, showAd, this)
      if(!showAd) {
        this.maintainTitle()
      }
      this.setState({
        playing_Ad: showAd,
        showPlayButton: false
      })
    }
  }

  clearSimulatorInterval() {
    if(this.simulatorInterval) {
      clearInterval(this.simulatorInterval);
      this.simulatorInterval = null;
    }
  }

  clearTotalWatchedTimeInterval() {
    if(this.totalWatchedTimeInterval) {
      clearInterval(this.totalWatchedTimeInterval)
      this.totalWatchedTimeInterval = null
      removeItemLocalStorage(K_TOTAL_WATCHED_TIME_BEACONING)
    }
  }
 
 
  checkVideoStatusForSimulator() {
    if(!this.state.playing_Ad) {
      this.simulatorCount++;
      if(this.simulatorCount > 5) {
        const player = videojs('thumb-video');
        if(player && !player.paused() && player.readyState() == 0) {
          player.trigger("error");
          player.pause();
          if(document.getElementById('thumb-video')) document.getElementById('thumb-video').classList.add("vjs-error")
        }
      }
    }
  }

  onAdReady() {
    const adCont = document.getElementById("thumb-video_ima-ad-container");
    if(adCont) {
      if(!adCont.classList.contains("bumpable-ima-ad-container")) {
        this.setState({adWrapperExist: true});
      }
    }

    const newVideo = document.getElementById("thumb-video_html5_api");
    if(checkCorrectPlatform([PLATFORM_ZEASN]) && newVideo) {
      newVideo.setAttribute("type", videoType(newVideo.src));
    }
  }

  onAdStart(ev) {
    if(ev) this.current_ad = ev.getAd();
    this.setState({playing_Ad: true})
  }

  onAdError() {
    this.setState({
      adWrapperExist: false,
      playing_Ad: false
    })
    this.setTimeAfterAdBreak();
  }

  updateSrcInSmartTv(_type) {
    if(configuration.kUseTheoPlayer) return;
    let ad_url = this.props.ads.adURL;
    let playbackToken = this.props.feeds.playbackUrlToken;
    const playbackUrlToken = playbackToken && this.props.feeds.playbackUrlToken.token;
    let isVapidAd = ad_url.includes('vpaid');
    if((_type === 'ad_com' && isVapidAd) || (_type === 'ad_all_com' && !isVapidAd)) return;

    let adPod = this.current_ad.getAdPodInfo()
    let podInfo = {
      podPosition: adPod.getAdPosition(),
      podLength: adPod.getTotalAds()
    }
    const _this = this;
    if (podInfo.podPosition == podInfo.podLength) { 
      let playbackUrl = modifiedPlaybackUrl(_this.props, current_src, _this.state.section, current_player);
      resettingVideoSource();
      current_player.src({ src: playbackUrl, type: current_type });
      current_player.ready(() => {
        const promise = current_player.play()
        configuration.isAppTvodType && handleUnmutedPlayback(promise, current_player, "thumb-video_html5_api", _this.props.userEngaged, _this.showBigPlayButton);
        var newVideo = document.getElementById("thumb-video_html5_api")
        newVideo.setAttribute("type", videoType(current_src))
        newVideo.addEventListener('play', () => {
          // set the video playback time
          const vTime = _this.props.remember[_this.state.section.identifier];
          const streamProgress = (configuration.isAppTvodType && _this.props.allStreamObject[_this.state.section.cid] && _this.props.allStreamObject[_this.state.section.cid].progress) || null;
          if (_this.props.showSubtitle) updateTextTrack(current_player, "showing");
          if(_this.state.isEPG) {
            // Do nothing
          } else if(streamProgress) {
            const totalTime = newVideo.duration || getSecondsFromDuration(_this.state.section.duration);
            if(totalTime) {
              current_player.currentTime(Math.floor((streamProgress * totalTime) / 100));
            }
          } else if (vTime && (vTime["p"] != vTime["t"])) {
            current_player.currentTime(vTime["p"]);
          }
        });
        const vttCaption = getVttCaption(_this.state.section);
        vttCaption && current_player.addRemoteTextTrack({src: vttCaption, default: _this.props.showSubtitle});
        if (_this.props.showSubtitle && !vttCaption) {
          addTextTracksEventVideoJs(current_player)
        }
        isSmartTv() && setDefaultValuesInVideoJs({player: current_player})
      });
    }
  }

  onAdComplete() {
    if(isSmartTv()) {
      setTimeout(() => {
        this.updateSrcInSmartTv('ad_com');
      },0);
    } else {
      this.setTimeAfterAdBreak();
    }
    this.setState({playing_Ad: false})
    this.maintainTitle();
  }
  
  onAllAdComplete() {    
    if(isSmartTv()) {
      this.updateSrcInSmartTv('ad_all_com');
    }
    this.setState({adWrapperExist: false});
    if(this.state.videoInQueue >= 0) {
      this.setNextVideo(this.state.videoInQueue);
    }
  }

  setTimeAfterAdBreak() {
    var video = document.getElementById("thumb-video_html5_api");
    if(!video) return;
    try {
      if(this.state.setTimeAfterAdBreak) {
        video.currentTime = this.state.setTimeAfterAdBreak;
      }
      if(video.paused && video.play) {
        video.play();
      }
    } catch {
      console.log("Error: Can't autoplay")
    }
  }

  cancelHideMenuTimeout() {
    if (hideMenuTimeout) {
      clearTimeout(hideMenuTimeout);
      hideMenuTimeout = null;
    }
  }

  getSectionParentFromLineage = (section) => {
    if(configuration.isAppTvodType) return section;
    
    const url = `${section.lineage}/${section.identifier}`;
    let parent = getParentFeed(this.props.feeds, url)
    const {locked, access, special, displayTitle, registerWall, identifier, showVideoAd} = parent;
    return {
      ...section,
      locked: locked,
      access: access,
      special: special,
      displayTitle: displayTitle || null,
      registerWall: registerWall,
      parent_id: identifier,
      showAd: showVideoAd
    }
  }


  maintainTitle(){
    let displayTitle = this.props.modal.content.displayTitle;
    if (["saved_items", "search"].includes(this.props.modal.head)){
      displayTitle = this.props.feeds.displayTitle;
    }
    let metering_type = null;
    const METERING_TYPE = getMeteringTypes();
    if ((!isAvailable(this.state.section.parent_id,this.state.section.special,this.state.section.registerWall) && 
    this.state.section.locked)) {
      if (this.props.feeds.ctaMetadata && this.props.feeds.ctaMetadata.metering) {
        metering_type = METERING_TYPE.VIEWS
      } else if (this.state.section.access && this.state.section.access.timed !== undefined) {
        metering_type = METERING_TYPE.TIME
      }
    }

    this.handleMetering(false);
    if (displayTitle && displayTitle.type === "duration"){
      this.handleTitle(true)
      var dTime = displayTitle.value*1000
      setTimeout(() => {
        this.handleTitle(false);
        this.handleMetering(true);
        if (metering_type === METERING_TYPE.VIEWS) {
          setTimeout(() => {
            this.handleMetering(false);
          }, dTime)
        }
      }, dTime)
    } else if(displayTitle && displayTitle.type == "full"){
      this.handleTitle(true)
      if(metering_type) {
        // if metering hide title after 5 seconds
        setTimeout(() => {
          this.handleTitle(false);
          this.handleMetering(true);
          if (metering_type === METERING_TYPE.VIEWS) {
            // if view metering hide metering after 5 seconds
            setTimeout(() => {
              this.handleMetering(false);
              this.handleTitle(true);
            }, 5000)
          }
        }, 5000)
      }
    } else {
      this.handleTitle(false)
      this.handleMetering(true);
      if (metering_type === METERING_TYPE.VIEWS) {
        setTimeout(() => {
          this.handleMetering(false);
        }, 5000)
      }
    }
  }

  handleMetering(show) {
   this.setState({showMetering: show})
  }

  handleTitle(show){
    this.setState({showTitle: show})
  }

  setVideoAnalytics(videoAutoplay, section=null, videoTitle=null, path=null){
    if (section === null) {
      section = this.state.section
    }
    if (!videoTitle) {
      videoTitle = this.state.video_title || analytics.getVideoTitleForEvents(section);
    }
    if (path === null) {
      path = this.props.modal.firstpath
    }
    const contentUrl =  section.contentUrl;
    const playText = videoAutoplay ? 'Autoplay' : 'Play';
    if(this.props.modal.head === "search") {
      analytics.setEvent('Video', `Video ${playText} (search - ${section.locked ? "locked" : "unlocked"})`, videoTitle, contentUrl);
    } else if (this.props.modal.head === "saved_items") {
      analytics.setEvent('Video', `Video ${playText} (saved)`, videoTitle, contentUrl);
    }
    analytics.setEventForContent(this.props.feeds, path, 'Video', `Video ${playText}`, videoTitle, contentUrl)
  }

  sendWatchedVideoAnalytics() {
    const { section, video_title, video_path } = this.state;
    const time = Math.floor((new Date() - this.videoStartedAt) / 1000);
    analytics.setEventForWatchedVideo(section.contentUrl, this.props.feeds, video_path, section, time);
  }

  componentDidUpdate(prevProps, prevState){
    if(isSmartTv() && (prevProps.isUserOnline != this.props.isUserOnline) && !configuration.kUseTheoPlayer) {
      if(this.props.isUserOnline) {
        // restore video
        let playbackUrl = modifiedPlaybackUrl(this.props, current_src, this.state.section, current_player);
        resettingVideoSource();
        current_player.src({ src: playbackUrl, type: current_type });
        current_player.ready(() => {
          if(!document.getElementById('continue-watching')) {
            current_player.play()
          }

          const newVideo = document.getElementById("thumb-video_html5_api")
          if(newVideo) {
            newVideo.addEventListener('play', () => {
              if(this.video_stream_buffered) {
                current_player.currentTime(this.video_stream_buffered);
              }
            }, { once: true });

            const vttCaption = getVttCaption(this.state.section);
            vttCaption && current_player.addRemoteTextTrack({src: vttCaption, default: this.props.showSubtitle});
          }
        });
      } else {
        this.video_stream_buffered = current_player.bufferedEnd();
      }
    }

    //analytics.setEventForContent(this.props.feeds, window.location.pathname, 'Video', 'Video Play')
    this.props.modalProgress({head: this.props.modal.head, index: this.state.index, item: this.state.section.identifier, first: false})
    const video = document.getElementById("thumb-video");
    if(prevState.index !== this.state.index && (video || configuration.kUseTheoPlayer)) {
      // checking if video changed and video tag hasn't been disposed
      //analytics.setEventForContent(this.props.feeds, window.location.pathname, 'Video', 'Video Play')
      //this.setVideoAnalytics();
      let showAd = false
      if(this.props.ads.spotxid !== undefined){
        showAd = showVideoAd(this.state, this.props)
      }
      PlayVideo(httpsUrl(this.state.section.contentUrl), this.state.type, this.state.next_section, this.props, this.state, showAd, this)
      if(!showAd) {
        this.maintainTitle()
      } else {
        this.setState({playing_Ad: true, showTitle: false, showMetering: false})
      }
      this.simulatorCount = 0;
      this.videoStartedAt = new Date();
    }
    if(prevState.playing_Ad !== this.state.playing_Ad) {
      if(this.state.playing_Ad) {
        prepareMazEventData(this, "ad_started");
      } else {
        resetMazEventData(this);
        if(this.props.runningOnMobile && this.setRememberSpotAfterAd && current_player) {
          current_player.currentTime(this.setRememberSpotAfterAd);
          this.setRememberSpotAfterAd = null;
        }
      }
    }
    if(prevProps.streamCounterUpdate !== this.props.streamCounterUpdate && this.props.streamCounterUpdate) {
      this.pushTvodStreams();
    }
    if(isSmartTv() && prevProps.showSubtitle != this.props.showSubtitle) {
      if(this.theoPlayerReference) {
        toggleSubtitlesVisibility(this.theoPlayerReference, this.props.showSubtitle);
      } else if(current_player) {
        toggleSubtitlesVisibility(current_player, this.props.showSubtitle);
      }
    }
    if(prevProps.autoPlayInAction !== this.props.autoPlayInAction && this.props.autoPlayInAction !== null && this.state.autoPlayInAction) {
      const {index, prevVideoEnded} = this.state.autoPlayInAction;
      if(document.getElementById("loader")) document.getElementById("loader").style.zIndex = "unset";
      this.setState({autoPlayInAction: null});
      this.setNextVideoData(index, prevVideoEnded);
    }
  }

  playOriginalVideo(){
    const isEpg = this.state.isEPG
    this.setState({playing_Ad: false})
    this.maintainTitle()
    if (document.getElementById("video-inlineMenu")) {document.getElementById("video-inlineMenu").style.display = "inherit"}    
    if (document.getElementById("next-video")) document.getElementById("next-video").style.display = "none"
    var rememberSpotId = this.props.remember[this.state.section.identifier]
    var next_section = this.state.section
    var url = httpsUrl(this.state.section.contentUrl)
    let videoParams = { 
      loadingSpinner: false,
      html5: {
        vhs: {
          overrideNative: !isSmartTv(),
        },
        nativeTextTracks: false
      },
      controlBar: {
        // enable default fullscreen toggle button if on ios
        fullscreenToggle: isIOS,
        pictureInPictureToggle: false,
        subsCapsButton: false,
        audioTrackButton: false,
        seekToLive: false
      }
    };
    if(this.props.runningOnMobile && !isEpg) {
      videoParams.controls = true;
      videoParams.muted = false;
    }
    if(isSmartTv() && !checkCorrectPlatform([PLATFORM_XBOX])) {
      videoParams.loadingSpinner = true;
      videoParams.controls = false;
    } else if(checkCorrectPlatform([PLATFORM_XBOX])) {
      // videoParams.html5 = {
      //   nativeAudioTracks: true,
      //   nativeVideoTracks: true,
      //   nativeTextTracks: false,
      //   hls: {
      //     overrideNative: false
      //   }
      // };
      videoParams.loadingSpinner = true;
      videoParams.controls = true;
    }
    this.video = videojs("thumb-video", videoParams);
    videojsQualityHelper(this.video)
    // for web only
    // not supported on ios
    // register fullscreen plugin which make viewport body in fullscreen instead of video
    if(!isSmartTv() && !isIOS) {
      this.video.fullscreen();
    }

    const _video = this.video;
    const _videoType = videoType(this.state.section.contentUrl);
    _video.on('error', function(e) {
      this.setState({ isShowCountineWatching: false })
      if(isSmartTv()) {
        onCTVVideoError(e, _video);
      } else {
        onVideoError(_videoType, _video);
      }
    });
    _video.on('pause', () => {
      setTimeout(() => {
        const vid = document.getElementById("thumb-video_html5_api");
        if(this && this.state && !this.state.playing_Ad && vid && vid.paused) {
          prepareMazEventData(this, "video_paused");
          this.videoPausedByUser = true;
        }
        // pause event trigger for everything even if video ended so handling it by delaying method call
      }, 1000)
    })
    _video.on("play", () => {
      if(this.videoPausedByUser) {
        resetMazEventData(this);
        this.videoPausedByUser = false;
      }
    })
    _video.on('ended', () => {
      // <Video> onEnded event not triggering for mobile devices so handling that here
      if(this.props.runningOnMobile) {
        this.setNextVideo(this.state.next_index, true)
      }
    });
    const streamProgress = (configuration.isAppTvodType && this.props.allStreamObject[this.state.section.cid] && this.props.allStreamObject[this.state.section.cid].progress) || null;
    this.video.ready(() => {
      let playbackUrl = modifiedPlaybackUrl(this.props, url, this.state.section, this.video);
      this.video.src({src: playbackUrl, type: videoType(this.state.section.contentUrl), ...getMMVideoAssetInfo(this.state.section)});
      startSendingMMEvents(this.video, playbackUrl, this.state.section, false)
      const promise = this.video.play()
      configuration.isAppTvodType && handleUnmutedPlayback(promise, this.video, "thumb-video_html5_api", this.props.userEngaged, this.showBigPlayButton);
      if(isIOS && this.video.playsinline) {
        this.video.playsinline(true);
      }
      var newVideo = document.getElementById("thumb-video_html5_api")
      newVideo.muted = false
      if(!this.props.runningOnMobile && !isEpg) {
      //   // Below line is for firefox bug (https://github.com/MagAppZine/MagAppZine-MagControl/issues/7107)
      //   // Video is getting paused and controls off, because of spotx ADOS control the video player.
        newVideo.addEventListener('pause', onVideoPauseCheck)
      }
      const _this = this;
      newVideo.addEventListener("contextmenu", (event) => {
        event.preventDefault();
      });
      if(checkCorrectPlatform([PLATFORM_ZEASN])) {
        newVideo.addEventListener('play',function() {
          setRememberSpot.call(this, streamProgress, rememberSpotId, newVideo, _this.state);
        }, { once: true });
      } else {
        newVideo.addEventListener('loadedmetadata', function() {
          setRememberSpot.call(this, streamProgress, rememberSpotId, newVideo, _this.state);
        }, { once: true });
      }
      newVideo.addEventListener("timeupdate", () => handleVideoTimeUpdate(next_section, null, this), false)
      const vttCaption = getVttCaption(this.state.section);
      vttCaption && _video.addRemoteTextTrack({src: vttCaption, default: this.props.showSubtitle}, false);
    });
  }

  componentWillUnmount(){
    this.pushTvodStreams();
    prepareMazEventData(this, "video_exited");
    this.sendWatchedVideoAnalytics();
    document.body.removeEventListener("mousemove", this.cancelHideMenuTimeout, true);
    var video = document.getElementById("thumb-video");
    var spotxIframe = getSpotxIframe();
    if(spotxIframe) {
      removeSpotxIframe();
    } else {
      this.saveVideoTimeInRemeberSpot(video);
      if(video) videojs(video).dispose();
    }

    if(isSmartTv()) {
      document.removeEventListener('keydown', this.handleRemoteClick);

      if(configuration.use_light_sliders) {
        addRemoveAllNavigation({ id: this.video_id });
      }
    }
    this.clearSimulatorInterval();
    this.clearTotalWatchedTimeInterval()
  }

  addParentFeedDataForSearch(section) {
    const {locked, access, special, displayTitle, registerWall, identifier} = section.parentMenu || {};
    let newSection = {
      ...section,
      locked: locked,
      special: special,
      displayTitle: displayTitle || null,
      registerWall: registerWall,
      parent_id: identifier,
      inlineMenu: this.props.feeds.inlineMenu || "" // inline menu in search as set at app level
    }
    if(!configuration.isAppTvodType) {
      newSection.access = access;
    }
    let moreData = getParentFeed(this.props.feeds, (section.lineage + "/" + section.identifier));
    newSection.showAd = moreData && moreData.showVideoAd;
    return newSection;
  }

  setNextVideo(index, prevVideoEnded, keepAutoplay){
    if(this.state.adWrapperExist && prevVideoEnded && isSmartTv()) return;

    if(configuration.kUseTheoPlayer) {
      NextVideoThumbnail(0, 0);
    }
    if(document.getElementById("loader")) document.getElementById("loader").style.display = "block";
    if(prevVideoEnded && this.state.adWrapperExist) {
      //ad will play, don't run next video for now
      this.setState({videoInQueue: index});
      return;
    }
    var spotxIframe = getSpotxIframe();
    if(spotxIframe) {
      setTimeout(() => {
        removeSpotxIframe();
        if(document.getElementById("thumb-video")) {
          this.playOriginalVideo()
        };
      }, 100)
    } else {
      if (index >= 0 && (this.props.autoPlay || keepAutoplay)) {
        let newSection = this.props.all_contents[index];
        //for tvod check if next video is locked
        if(configuration.isAppTvodType && this.props.validateNextVideo) {
          this.props.validateNextVideo({newSection: newSection, newIndex: index});
          this.setState({autoPlayInAction: {index, prevVideoEnded}})
          if(document.getElementById("loader")) document.getElementById("loader").style.zIndex = "99";
        } else {
          this.setNextVideoData(index, prevVideoEnded)
        }
      } else {
        prepareMazEventData(this, "video_ended");
        this.onLinkCLick()
      }
    }
  }

  setNextVideoData(index, prevVideoEnded) {
    let newSection = this.props.all_contents[index];
    this.setState({ isShowCountineWatching: true })
    this.pushTvodStreams();
    prepareMazEventData(this, prevVideoEnded ? "video_ended" : "video_exited");
    this.sendWatchedVideoAnalytics(); // Send analytics of last video seen before any state update
    this.video = document.getElementById("thumb-video");
    var rememberSpotBatch = {...this.state.rememberSpotBatch}
    rememberSpotBatch[this.state.section.identifier] = {
      p: this.theoPlayerReference ? this.theoPlayerReference.currentTime : videojs(this.video).currentTime(),
      t: this.theoPlayerReference ? this.theoPlayerReference.duration : videojs(this.video).duration(),
      saved: false,
      type: this.state.section.type
    }
    // dispose previous one before playing next
    var video = document.getElementById("thumb-video");
    if(video && !configuration.kUseTheoPlayer) {
      videojs(video).dispose();
    }
    this.setState({rememberSpotBatch: rememberSpotBatch, adWrapperExist: false, videoInQueue: undefined})
    this.setState({all_contents: this.props.all_contents})
    if(this.props.modal.head === "search"){
      newSection = this.addParentFeedDataForSearch(newSection);
    }
    if (newSection.mazContentType && newSection.lineage) {
      newSection = this.getSectionParentFromLineage(newSection)
    }
    var type = videoType(newSection.contentUrl)
    var nextVideoData = NextVideo(this.props.all_contents, index)
    this.setState({
      type: type,
      section: newSection,
      index: index,
      lineage: newSection.lineage,
      next_section: nextVideoData[0],
      next_index: nextVideoData[1],
      next_video: true,
      video_path: ChangeRoute(newSection.identifier),
      video_title: newSection.title,
      setTimeAfterAdBreak: null,
      video_count: this.state.video_count + 1
    })
    this.videoTotalDuration = null;
    this.eventSentForVideo = [];
    if(prevVideoEnded){
      this.setVideoAnalytics(true, newSection, newSection.title, ChangeRoute(newSection.identifier));
    }else{
      this.setVideoAnalytics(false, newSection, newSection.title, ChangeRoute(newSection.identifier));
    }
    if(!configuration.isAppTvodType) {
      this.props.rememberSpot(this.props.app_id, rememberSpotBatch, this.props.logged_in, this.props.user.auth_token,
        this.props.user.user_id)
    }
  }

  pushTvodStreams() {
    if(this.state.section && this.state.section.catalog === TRAILER_CATALOG) return;
    if(this.state.playing_Ad) return;

    const video = this.theoPlayerReference ? this.theoPlayerReference : document.getElementById("thumb-video_html5_api");
    if(video && configuration.isAppTvodType && video.duration) {
      let progress = Math.floor((video.currentTime / video.duration) * 100) || 0;
      if(video.duration - video.currentTime < 1) { // On video end, for some videos difference in both time is in ms
        progress = 100;
      }
      this.props.pushTvodStreamData({"cid": this.state.section.cid, "progress": progress}, this.state.section)
      if(this.props.autoPlayingSectionPlaylist && !this.props.playingExtra) {
        setPlaylistVideoCounter("playlist", this.props.autoPlayingSectionPlaylist, this.state.index);
      }
    }
  }

  onFreeContentOver() {
    try {
      if(this.theoPlayerReference) {
        this.theoPlayerReference.stop();
      }
      const video = document.getElementById("thumb-video");
      var spotxIframe = getSpotxIframe();
      if (isSmartTv()) {
        this.saveVideoTimeInRemeberSpot(video);
      }
      if(video) videojs(video).dispose();
      if(spotxIframe) {
        removeSpotxIframe();
      }
      this.handleMetering(false)
      this.handleTitle(false)
      if (document.getElementById("next-video")) {(document.getElementById("next-video").style.display = "none")}
      if (document.getElementById("video-inlineMenu")) {document.getElementById("video-inlineMenu").style.display = "none"}  
      this.clearSimulatorInterval();
    } catch(err) {
      console.log("error occured in free content over!");
    }
  }

  handlePercentAnalytics() {
    const video = this.theoPlayerReference ? this.theoPlayerReference : document.getElementById("thumb-video_html5_api");

    if(!video || !video.currentTime || !video.duration || !this.state.section) return;
    const percentage = (video.currentTime / video.duration) * 100;
    let eventName;
    if(percentage > 99) {
      eventName = "Video Played to Completion";
    } else if(percentage > 75) {
      eventName = "Video Played > 75%";
    } else if(percentage > 50) {
      eventName = "Video Played > 50%";
    } else if(percentage > 25) {
      eventName = "Video Played > 25%";
    }

    if(eventName && !this.eventSentForVideo.includes(eventName)) {
      const { title, contentUrl } = this.state.section || {};
      analytics.setEvent('Video', eventName, title, contentUrl);
      this.eventSentForVideo.push(eventName);
    }
  }

  theoPlayerUpdateTimeHandler(currentTime, totalTime) {
    if(this.state.next_section) {
      NextVideoThumbnail(currentTime, totalTime)
    }
    handlefocusOfNextUIforCTV(this);
    handleVideoMinutes(this.theoPlayerReference, this)
    this.handlePercentAnalytics();
  }

  handleTheoAdState(state) {
    switch(state) {
      case "adbegin": this.onAdStart()
        break;
      case "adend": 
        this.onAllAdComplete();
        this.onAdComplete();
        break;
      case "aderror": this.onAdError()
        break;
      default:
        break;
    }
  }

  onTheoPause() {
    prepareMazEventData(this, "video_paused");
    this.videoPausedByUser = true;
  }

  onTheoPlay() {
    if(isSmartTv() && !this.state.video_start) {
      this.setState({video_start: true})
    }
    if(this.videoPausedByUser) {
      resetMazEventData(this);
      this.videoPausedByUser = false;
    }
  }

  getPlayerKey() {
    if(configuration.kUseTheoPlayer && !checkCorrectPlatform([PLATFORM_VIZIO])) {
      return "overlayVideo"
    }
    return `${this.state.section.identifier}`;
  }

  renderInstructionText() {
    if(!this.props.canShowInstructionText) return null;
    return (
      <InstructionText
        feeds={this.props.feeds}
        runningOnMobile={this.props.runningOnMobile}
        playing_Ad={this.state.playing_Ad}
      />
    )
  }

  renderContinueWatching() {
    const extras = this.props.feeds.extras;
    if(!extras || !extras.continueWatching || !this.state.isShowCountineWatching) return null;
    return (
      <ContinueWatching
        playerId={"thumb-video_html5_api"}
        extras={extras}
        theoPlayerReference={this.theoPlayerReference}
      />
    )
  }

  showBigPlayButton() {
    this.setState({showPlayButton : true})
  }
 
  renderInlineMenu() {
    let val = this.state.section.inlineMenu || "";
    if(val && this.state.showPlayButton) {
      val = "limited";
    }
    return (
      <InlineMenu
        all_contents={this.props.all_contents}
        feeds={this.props.feeds}
        next={(index) => this.setNextVideo(index, false, true)}
        playing={this.state.index}
        inlineMenu={val}
        policyData={this.props.policyData}
      />
    )
  }

  renderPlayer() {
    if(configuration.kUseTheoPlayer) {
      const streamProgress = (configuration.isAppTvodType && this.props.allStreamObject[this.state.section.cid] && this.props.allStreamObject[this.state.section.cid].progress) || null;
      return (
        <TheoPlayer
          section={this.state.section}
          type={this.state.type}
          feeds={this.props.feeds}
          showPlayButton={this.state.showPlayButton}
          setShowPlayButton={this.showBigPlayButton}
          isAdForFeed={isAdForFeed(this.props.modal, this.state.section)}
          videoCount={this.state.video_count}
          ads={this.props.ads}
          rememberSpot={this.props.remember[this.state.section.identifier] || {}}
          streamProgress={streamProgress}
          isEPG={this.state.isEPG}
          ctv_focus_on={this.state.ctv_focus_on}
          showSubtitle={this.props.showSubtitle}
          theoPlayerUpdateTimeHandler={(currentTime, totalTime) => this.theoPlayerUpdateTimeHandler(currentTime, totalTime)}
          setNextVideo={() => this.setNextVideo(this.state.next_index, true)}
          oninit={(player) => this.theoPlayerReference = player}
          onAdUpdateState={(state) => this.handleTheoAdState(state)}
          onTheoPlay={() => this.onTheoPlay()}
          onTheoPause={() => this.onTheoPause()}
          videoType={"video"}
          user_ip={this.props.user_ip}
          user_concent={this.props.userConcent}
          device_uuid={this.props.device_uuid}
          user_coords={this.props.user_coords}
          device_model={this.props.device_model}
          isUserOnline={this.props.isUserOnline}
        />
      )
    }
    return (
      <video
        className={"video-js vjs-default-skin video-content-" + this.state.section.identifier} 
        id="thumb-video" 
        preload="10" 
        onEnded={(index) => this.setNextVideo(this.state.next_index, true)} // 2nd argument for post roll ad handling
        disablePictureInPicture
      /> 
    )
  }

  render(){
    const runningOnMobile = this.props.runningOnMobile;
    if(this.props.section.identifier != this.state.section.identifier){
      window.history.replaceState("new video", {}, ChangeRoute(this.state.section.identifier, true))
    }

    const tv_style = {};
    if(isSmartTv()) {
      tv_style.display = "none";
    }
    const epgClassName = this.state.isEPG ? "epg-video" : "";

    const handleBackButtonClick = (event) => {
      this.onLinkCLick(event);
      // exit fullscreen
      // not supported on ios
      if (!isSmartTv() && !isIOS && !fullscreen().isNormalScreen()) fullscreen().contract();
    }
    const hideControlBar = ( isMobile || isSmartTv() ) && this.state.playing_Ad; //For Hiding Control Bar when ads are playing on Mobile and CTV Devices for theo Player
    const canShowCustomControls = isSmartTv() && !checkCorrectPlatform([PLATFORM_XBOX]) && document.getElementById('thumb-video_html5_api') && !this.state.playing_Ad && !this.state.showPlayButton  && this.state.afterMount && !this.state.isEpg;

    return (
      <div className={`videoFull ${epgClassName} ${hideControlBar ? "theo-controls-div-hide" : ""}`} style={{background: "#000000"}} id="overlay-video">
        <div key={this.getPlayerKey()}>
          {/* different key for each video to dispose previous because of ads wrapper, else next click won't work*/}
          <div id="loader" className="loader"><img src={"https://s3.amazonaws.com/resources.magappzine.com/assets/core/" + environment() + "/loader.gif"}/></div>
          <span className="icon-hollow_arrow">
            <a id="overlay_video_back_btn" href="" onClick={handleBackButtonClick} className={(runningOnMobile ? "backBtn-mobile" : "backBtn")} style={{zIndex: 100, ...tv_style}}>
              {runningOnMobile && <img src={backMobile} alt="Back"/>}
            </a>
          </span>
          {this.renderPlayer()}
          {this.state.showPlayButton && <div id="video-play-extra-button" className="video-play-extra-button">
            <img className="focusable" tabIndex={getTabIndex()} alt="Play" src={playButton} onClick={() => this.startVideo()}/>
          </div>}
          {
            (!configuration.isAppTvodType && !this.state.showPlayButton && (!isAvailable(this.state.section.parent_id,this.state.section.special,this.state.section.registerWall) &&
              this.state.section.locked)) &&
              <Metering
                content_id={this.state.section.identifier}
                parent_id={this.state.section.parent_id}
                title={this.state.section.title}
                contentAccess={this.state.section.access}
                feeds={this.props.feeds}
                onFreeContentOver={() => this.onFreeContentOver()}
                playing_Ad={this.state.playing_Ad}
                showMetering={this.state.showMetering}
                content={this.state.section}
                modalHead={this.props.modal.deepLink === DEEPLINK ? DEEPLINK : this.props.modal.head}
                interstitialModalDetails={this.props.modal.interstitialProps}
                video_start={this.state.video_start}
              />
          }
          {!runningOnMobile && !this.state.playing_Ad && this.state.showTitle && this.state.video_title && <div className="prevThumbnail video-title ellipsis" >
            <p className=""> <b>{this.state.video_title}</b></p>
          </div>}
          {this.renderInstructionText()}
          {configuration.isAppTvodType && !this.props.userEngaged && <AudioMutedMessage _this={this} videoId={"thumb-video_html5_api"}/>}
          {/* To check if it is last video do not show up next. (this.state.next_index will be undefined for last video) */}
          {this.props.autoPlay && !runningOnMobile && !this.state.playing_Ad && !this.state.isEPG && this.state.next_index && <div className="prevThumbnail focusable" tabIndex={getTabIndex()} id="next-video" style={{display: "none", backgroundColor: (this.props.feeds.primaryColor && this.props.feeds.primaryColor.replace('#FF','#'))}} onClick={(index) => this.setNextVideo(this.state.next_index)}>
            <p>Up Next in <span className="nextVideoSeconds"></span> Seconds</p>
            {this.state.next_section && this.state.next_section.cover && <Image cover={this.state.next_section.cover} customSize={384}/>}
            <p className="prevText">{this.state.next_section && this.state.next_section.title}</p>
          </div>}
        </div>
        {!runningOnMobile && !this.state.playing_Ad && !this.state.isEPG && !this.props.isPlayerSettingsModalOpen && this.renderInlineMenu()}
        {canShowCustomControls && !this.props.isPlayerSettingsModalOpen && <PlayerIcon videoId={'thumb-video_html5_api'} ctv_focus_on={this.state.ctv_focus_on} />}
        {canShowCustomControls && <PlayerProgressBar videoId={'thumb-video_html5_api'} key={this.state.index} ctv_focus_on={this.state.ctv_focus_on}/>}
        {!this.state.showPlayButton && this.renderContinueWatching()}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  remember: state.remember.remember,
  logged_in: state.user.logged_in,
  autoPlay: state.user.autoPlay,
  user: state.user.user,
  ads: state.ads,
  items_purchased: state.user.items_purchased,
  subscription: state.user.subscription,
  userEngaged: state.user.userEngaged,
  modal: state.modal.modal,
  user_ip: state.user.ip,
  user_concent: state.user.userConcent,
  device_uuid: state.user.device_uuid,
  user_coords: state.user.user_coords,
  device_model: state.user.device_model,
  showSubtitle: state.feeds.showSubtitle,
  isUserOnline: state.user.isUserOnline,
  allStreamObject: state.streams.allStreamObject,
  policyData: state.feeds.policyData,
  isPlayerSettingsModalOpen: state.user.playerSettingsModalOpen,
})

const mapDispatchToProps = {
  rememberSpot: localRememberSpotBatch,
  closeModal: closeModal,
  openModal: openModal,
  modalProgress: modalProgress,
  updateVideoCount: updateVideoCount,
  pushTvodStreamData: pushTvodStreamData
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OverlayVideo));
