import React from 'react';
import ReactFocusLock from 'react-focus-lock';
import Swiper from 'react-id-swiper';

import { formatHexCode, PLATFORM_VIZIO, PLATFORM_XBOX } from '../constants/common';
import configuration from '../constants/config';
import JsSpatialNavigation from '../ctvnavigation/js-spatial-navigation';
import { getLogoFromLoginOrFeed } from '../helperfunctions';
import { checkCorrectPlatform, checkLoginUIMetaData, getTabIndex, isSmartTv } from '../helperfunctions/common';
import { addRemoveAllNavigation, callExitCtvMethod } from '../helperfunctions/ctvHelper';
import { getAllConstants } from '../helperfunctions/regionalization';
import Image from './Image';
import LoginFlow from './LoginFlow';
import { keyMappings } from '../helperfunctions/ctvHelper';

let ALL_C = {};

class OnBoarding extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      showLogin: false,
      emailSelected: false,
      ctvPolicy: null,
      exit_ctv: false
    }
    this.swiperRef = React.createRef();
    this.handleRemoteClick = this.handleRemoteClick.bind(this)
    ALL_C = getAllConstants();
  }
  componentDidMount() {
    if (isSmartTv()) {
      document.addEventListener('keyup', this.handleRemoteClick);
      this.id = addRemoveAllNavigation({ selector: '.onboarding-screen .focusable' });
    }
  }
  componentDidUpdate() {
    if (isSmartTv()) {
      JsSpatialNavigation.focus(this.id);
    }
  }
  componentWillUnmount() {
    if (isSmartTv()) {
      document.removeEventListener('keyup', this.handleRemoteClick);
      addRemoveAllNavigation({ id: this.id });
    }
  }
  handleNextClick(isLastScreen) {
    if(isLastScreen) {
      const action = this.props.onboardingMetadata.action;
      if(action && action.toLocaleLowerCase() === 'register') {
        this.setState({showLogin: true});
      } else {
        this.onboardingCompleted()
      }
    } else if(this.swiperRef && this.swiperRef.current && this.swiperRef.current.swiper) {
      this.swiperRef.current.swiper.slideNext();
    }
  }
  onboardingCompleted() {
    this.props.onboardingCompleted();
  }
  updateEmailSelected(_bool = true) {
    this.setState({emailSelected: _bool})
  }

  showCTVPolicyModal = (type, url) => {
    this.setState({ctvPolicy: {url, type}});
  }

  handleRemoteClick(e) {
    const back_key_codes = {
      '10009': true, // lg
      '461': true,    // samsung
      '8': checkCorrectPlatform([PLATFORM_VIZIO]) // vizio
    };
    if(checkCorrectPlatform([PLATFORM_XBOX])) {
      back_key_codes['198'] = true;
      back_key_codes['196'] = true;
    }
    if (back_key_codes[e.keyCode]) {
      if(this.state.ctvPolicy) {
        this.setState({ctvPolicy: null});
      } else if (document.getElementById('virtual_keyboard') &&  document.getElementById("virtual_keyboard").style.display != 'none') {
        return;
      } else if(document.getElementById('website_back_button_ctv')) {
        document.getElementById('website_back_button_ctv').click();
        JsSpatialNavigation.focus();
      } else if(this.state.emailSelected) {
        this.setState({ emailSelected: false });
      } else {
        this.setState({ exit_ctv: !this.state.exit_ctv });
      }
    } else if(this.swiperRef && this.swiperRef.current && this.swiperRef.current.swiper){
      if(keyMappings(e.keyCode) === 'LEFT') this.swiperRef.current.swiper.slidePrev();
      if(keyMappings(e.keyCode) === 'RIGHT') this.swiperRef.current.swiper.slideNext();
    }
  }

  setBulletsColors() {
    const colors = this.props.onboardingMetadata.colors;
    let activeColor = "#e02929";
    let inActiveColor = "#80ffffff";
    if(colors && colors.pageControlSelectedColor) {
      activeColor = colors.pageControlSelectedColor;
    }
    if(colors && colors.pageControlUnselectedColor) {
      inActiveColor = colors.pageControlUnselectedColor;
    }
    activeColor = formatHexCode(activeColor, true);
    inActiveColor = formatHexCode(inActiveColor, true);
    let all = document.getElementsByClassName('swiper-pagination-bullet');
    for (var i = 0; i < all.length; i++) {
      all[i].style.background = inActiveColor;
    }
    let allActive = document.getElementsByClassName('swiper-pagination-bullet-active');
    for (var i = 0; i < allActive.length; i++) {
      allActive[i].style.background = activeColor;
    }
  }

  hideSingleBullet(){
    let swiper = this.swiperRef?.current?.swiper
    if(swiper.slides.length <= 1){
      swiper.pagination.el.style.display = 'none';
    }
  }

  exitCtvApp(_bool) {
    if(_bool) {
      callExitCtvMethod();
    } else {
      this.setState({ exit_ctv: false })
    }
  }

  renderExitCtv() {
    return (
      <ReactFocusLock>
        <div id="overlay" className="onboarding-screen user-access exit-popup" style={{ background: 'black',zIndex: 250 }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 'inherit', flexDirection: 'column' }}>
            <div role="heading" id="exit_app_text">
              {ALL_C.kCtvExitMsg}
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '13em', marginTop: '4em' }}>
              <button aria-label={`${ALL_C.kCtvExitMsg}, ${ALL_C.kNo}`} onClick={e => this.exitCtvApp(false)} className="focusable" tabIndex={getTabIndex()} style={{ background: '#99bdff', padding: '1em 4em', borderRadius: '1em', fontSize: '1em' }}>{ALL_C.kNo}</button>
              <button aria-label={`${ALL_C.kCtvExitMsg}, ${ALL_C.kYes}`} onClick={e => this.exitCtvApp(true)} className="focusable" tabIndex={getTabIndex()} style={{ background: '#f5f5f5', padding: '1em 4em', borderRadius: '1em', marginTop: '2em', fontSize: '1em' }}>{ALL_C.kYes}</button>
            </div>
          </div>
        </div>
      </ReactFocusLock>
    );
  }

  renderLogin() {
    const headerLogo = getLogoFromLoginOrFeed(this.props.feeds, "registrationWall");
    const allProps = {
      feeds: checkLoginUIMetaData(this.props.feeds),
      app_id: configuration.app_id,
      background: (this.props.feeds.loginUIMetadata.colors && this.props.feeds.loginUIMetadata.colors.background) || null,
      titleMsg: this.props.feeds?.loginUIMetadata?.tvodLogin?.strings?.header || ALL_C.kLoginToContinue,
      directLogin: true
    }
    return (
      <div className={(this.props.runningOnMobile ? "web-route-container web-route-container-mobile" : "")}>
        <div className="scroll-auto-h-100 onboarding-screen login-page">
          {this.state.ctvPolicy
            ? <div className="ctv-container">
                <div className="ctv-poilcy"  role="dialog" aria-labelledby="ctv-policy-heading" aria-describedby="ctv-policy-description">
                  <h3 id='ctv-policy-heading'>{ this.state.ctvPolicy.type }</h3>
                  <p id='ctv-policy-description'>
                    &nbsp; {ALL_C.kISReadVisit} &nbsp; {this.state.ctvPolicy.url }
                  </p>
                  <ReactFocusLock>
                    <div className='center-align' style={{marginTop: '5em'}}>
                      <div id="terms-privacy-back-btn" className="focusable" tabIndex="-1" onClick={() => this.setState({ctvPolicy: null})} aria-label={'Go back'} aria-describedby="ctv-policy-heading ctv-policy-description">
                        {ALL_C.kGoBack}
                      </div>
                    </div>
                  </ReactFocusLock>
                </div>
              </div>
            : <LoginFlow
                emailSelected={this.state.emailSelected}
                headerLogo={headerLogo}
                selectEmail={() => this.updateEmailSelected()}
                selectBack={this.goBack}
                allProps={allProps}
                runningOnMobile={this.props.runningOnMobile}
                pushToOnboardingScreen={(type, url) => this.showCTVPolicyModal(type, url)}
              />
          }
        </div>
      </div>
    )
  }

  render () {
    const params = {
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      },
      spaceBetween: 0,
      on: {
        slideChange: () => {
          if(this.swiperRef && this.swiperRef.current && this.swiperRef.current.swiper) {
            this.setState({activeIndex: this.swiperRef.current.swiper.activeIndex});
          }
          this.setBulletsColors();
        },
        init: () => {
          // pagination bullets renders after init so taking timeout
          setTimeout(() => {
            this.setBulletsColors()
            this.hideSingleBullet()
          })
        }
      }
    }
    const { screens =[], allowSkip, buttonTitle, background, colors } = this.props.onboardingMetadata;
    if(this.state.exit_ctv && isSmartTv()) {
      return this.renderExitCtv();
    }

    if(screens.length === 0 || this.state.showLogin) {
      return this.renderLogin();
    }
    const isMobile = this.props.runningOnMobile;
    const buttonStyle = {color: colors.btnTitleColor || "white", backgroundColor: colors.btnColor || "#e02929"};
    const isLastScreen = this.state.activeIndex + 1 === screens.length;
    return(
      <div className={"onboarding-screen" + (this.props.runningOnMobile ? " mobile-view" : "")}>
        <div className="first-layer">
          {background && background.imageUrl && <img className="big-image" src={background.imageUrl}/>}
        </div>
        <div className="second-layer">
          <Swiper {...params} ref={this.swiperRef}>
            {screens.map((screen, i) => {
              return (
                <div key={`${screen.title}-${i}`}>
                  <div className="screen-item">
                    {screen.image && screen.image.url && <Image classNames="small-image" cover={screen.image}/>}
                    <p id={`title-${i}`} className="title" style={{color: (isMobile && colors.titleColor) || "white"}}>{screen.title}</p>
                    <p id={`summary-${i}`} className="summary" style={{color: (isMobile && colors.descriptionColor) || "white"}}>{screen.summary}</p>
                  </div>
                </div>
              )
            })}
          </Swiper>
        </div>
        <span role="button" aria-describedby={`title-${this.state.activeIndex} summary-${this.state.activeIndex}`} className="next-btn focusable" style={buttonStyle} tabIndex={getTabIndex()} onClick={() => this.handleNextClick(isLastScreen)}>{isLastScreen ? buttonTitle : ALL_C.kNext}</span>
        {allowSkip && <span role="button" className="skip-btn focusable" tabIndex={getTabIndex()} onClick={() => this.onboardingCompleted()}>{ALL_C.kISSkip}</span>}
      </div>
    )
  }
}

export default OnBoarding;