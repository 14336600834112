import React from 'react';
import { HERO_SLIDER_THEME } from '../constants/common';

import Background from './Background';
import Content from './Content';
import { DynamicList } from './DynamicList';

export default (props) => {
  const { app_id, feeds, layout, section, callbackParent, pathLocation, runningOnMobile, background, isSingleMenu} = props;
  const mainContents = isSingleMenu ? [section] : section.contents;
  return (
    <div className={"content-box hero-slider" + ( runningOnMobile ? " video-grid-theme" : "")} id="content-box-1">
        <Background background={background} />
        {mainContents.map((content, i) => {
          const propData = {
            background: background,
            key: content.identifier,
            index: i,
            app_id: app_id,
            feeds: feeds,
            layout: HERO_SLIDER_THEME,
            content: content,
            runningOnMobile: runningOnMobile,
            callbackParent: (route) => callbackParent(route),
            pathLocation: pathLocation,
            currentSection: section
          }
          if(content.isDynamicList) {
            return <DynamicList key={`dynamic-${propData.key}`} propData={propData} content={content} isSection={isSingleMenu}/>
          }
          return <Content {...propData}/>
        })}
    </div>
  )
}