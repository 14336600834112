import React from "react";

function TickIcon(props) {
    const {fill = "currentColor"} = props
    return (
        <div className={`tick-icon ${props.className || ""}`} style={{"margin": "-10px 0 0 0"}}>
            <svg width="70" height="70" viewBox="0 0 22 12" fill={fill} xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M21.0316 0.970943C21.3238 1.26454 21.3227 1.73941 21.0291 2.0316L7.96656 15.0316C7.67396 15.3228 7.20104 15.3228 6.90844 15.0316L0.970943 9.1225C0.677348 8.83031 0.67621 8.35544 0.9684 8.06184C1.26059 7.76825 1.73546 7.76711 2.02906 8.0593L7.4375 13.4419L19.9709 0.9684C20.2645 0.67621 20.7394 0.677348 21.0316 0.970943Z"/>
            </svg>
        </div>
    );
}

export default TickIcon;
