import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux"
import { localRememberSpot } from '../actions/rememberSpotActions'
import { updateVideoCount } from "../actions/adsActions"

import Cta from "../components/Cta"
import { closeModal,openModal } from '../actions/modalActions'
import { httpsUrl, getSpotxIframe, removeSpotxIframe, getSpotXId, PLATFORM_HTML_SIMULATOR, MAZ_REPORTING_INTERVAL, PLATFORM_SAMSUNG, PLATFORM_ZEASN, K_TOTAL_WATCHED_TIME_BEACONING } from "../constants/common"
import isAvailable from '../helperfunctions/is_available'
import InlineMenu from "./InlineMenu";
import Metering from './Metering';
import { getMeteringTypes } from '../constants/common';
import { backButtonObj, checkIfSectionLevelContent } from '../helperfunctions';
import * as analytics  from '../constants/analytics';
import playButton from "../images/icons/play-button.png";
import TvFocusable from '../components/TvFocusable';
import { showAdsAsPerFrequency, runAdInVideo, constructSpotxParams, getModifiedAdUrl } from '../helperfunctions/adHelper';
import backMobile from "../images/icons/back-mobile.png";
import configuration from '../constants/config';
import { addRemoveAllNavigation, checkIfHideNavbarOnLive, toggleNavbarVisibility, toggleSubtitlesVisibility } from '../helperfunctions/ctvHelper';
import { sendVideoMinutes } from '../helperfunctions/mazAnalytics';
import ContinueWatching from './ContinueWatching';
import TheoPlayer from './TheoPlayer';
import { InstructionText } from './InstructionText';
import { isIOS, isMobile } from 'mobile-device-detect';
import { getPlaylistVideoCounter, handleUnmutedPlayback, modifiedPlaybackUrl, setPlaylistVideoCounter } from '../helperfunctions/common';

import 'videojs-contrib-ads';
import 'videojs-ima';
import { onVideoError, isSmartTv, onCTVVideoError, getItemCuePoints, checkCorrectPlatform, checkIfSafari, getTabIndex, getVttCaption, videoType, fullscreen } from '../helperfunctions/common';
import PlayerIcon from './icons/PlayerIcon';
import { getMMVideoAssetInfo, resettingVideoSource, startSendingMMEvents } from '../helperfunctions/mediaMelon';

import videojs from 'video.js';
import { addTextTracksEventVideoJs, updateTextTrack, videojsQualityHelper } from '../helperfunctions/videojsHelper';
import AudioMutedMessage from './AudioMutedMessage';
import { setDefaultValuesInVideoJs } from './player/PlayerSettingsMenu';
import { setLocalStorage, removeItemLocalStorage } from '../helperfunctions/storage';

// only for web
// not supported on ios
if (!isSmartTv() && !isIOS) {
  // replace default fullscreen toggle button
  // this button makes viewport body fullscreen
  videojs.registerPlugin("fullscreen", () => {
    const button = fullscreen().button;
    document.getElementsByClassName('vjs-control-bar')[0].appendChild(button);
  })
}

let current_player,current_src,current_type;

function FindVideo(props){
  if(configuration.isAppTvodType) {
    return getPlaylistVideoCounter("fake", props.section.identifier);
  }
  var videoIndex = 0;
  if(props.remember[props.section.identifier]){
    var all_contents = props.section.contents
    var contentId = props.remember[props.section.identifier]["contentId"]
    for(var i=0; i<all_contents.length; i++){
      if(contentId === all_contents[i].identifier){
        videoIndex = i;
        break;
      }
    }
  }
  return videoIndex;
}

function showVideoAd(state, props){
  var adToShow = props.ads.spotxid.length > 0
  adToShow = adToShow && window.SpotX && props.section.showVideoAd
  var showAd = false
  if(adToShow){
    showAd = showAdsAsPerFrequency(props.ads, state.video_count);
  }
  return showAd
}

function setRememberSpot(streamProgress, rememberSpot, rememberSpotId, newVideo) {

  let setTime;

  if(streamProgress && streamProgress !== 100 && newVideo.duration) {
    const timetoSeek =  Math.floor((streamProgress * newVideo.duration) / 100);
    setTime = timetoSeek;
    this.currentTime = timetoSeek;
  } else if(rememberSpot && rememberSpotId["p"]) {
    setTime = rememberSpotId["p"];
    this.currentTime = rememberSpotId["p"];
  }

  return setTime;
}

function playVideo(url, type, props, showAd, new_video, _this, state){
  if(!document.getElementById('fake-video')) return; // if video tag doesn't exist
  if(configuration.kUseTheoPlayer) return;

  if(showAd && !isSmartTv()) {
    if (document.getElementById("video-inlineMenu")) {document.getElementById("video-inlineMenu").style.display = "none"}
    var adContainer = document.getElementsByClassName('fake-video-ad')[0];
    const cust_params = constructSpotxParams(props.feeds, state.contents[state.index]);
    if(document.getElementById('fake-video_html5_api')){
      adContainer = document.getElementById('fake-video');
      var directAdOS = new window.SpotX.DirectAdOS({
        channel_id: getSpotXId(props.ads),
        slot: adContainer,
        video_slot: document.getElementById('fake-video_html5_api'),
        hide_skin: false,
        autoplay: true,
        custom: {...cust_params}
      });
      directAdOS.loadAd();
    } else {
      var directAdOS = new window.SpotX.DirectAdOS({
        channel_id: getSpotXId(props.ads),
        slot: adContainer,
        video_slot: document.getElementById('fake-video'),
        hide_skin: false,
        autoplay: true,
        custom: {...cust_params}
      });
      directAdOS.loadAd();
    }
  } else {

    //Increase Video Count
    if (document.getElementById("video-inlineMenu")) {document.getElementById("video-inlineMenu").style.display = "inherit"}
    const player = videojs('fake-video', {
      controls: false,
      loadingSpinner: isSmartTv(),
      html5: {
        vhs: {
          overrideNative: !isSmartTv(),
        },
        nativeTextTracks: false
      },
      controlBar: {
        // enable default fullscreen toggle button if on ios
        fullscreenToggle: isIOS,
        pictureInPictureToggle: false,
        subsCapsButton: false,
        audioTrackButton: false
      }
    });

    videojsQualityHelper(player)

    // for web only
    // not supported on ios
    // register fullscreen plugin which make viewport body in fullscreen instead of video
    if(!isSmartTv() && !isIOS) {
      player.fullscreen();
    }

    const _videoType = videoType(url);
    current_player = player;
    current_src = url;
    current_type = _videoType;
    player.on('error', function(e) {
      _this.setState({ isShowCountineWatching: false })
      if(isSmartTv()) {
        onCTVVideoError(e, player, 'live');
      } else {
        onVideoError(_videoType, player);
      }      
    });
    player.on('ended', () => {
      // <Video> onEnded event not triggering for mobile devices so handling that here
      if(props.runningOnMobile && !_this.state.adWrapperExist &&  _this.videoTotalDuration && player && player.currentTime() && (_this.videoTotalDuration - player.currentTime() < 1)) {
        _this.setNextFakeVideo(state.index, true)
      }
    });
    const streamProgress = (configuration.isAppTvodType && props.allStreamObject[state.contents[state.index].cid] && props.allStreamObject[state.contents[state.index].cid].progress) || null;
    player.ready(() => {
      let playbackUrl = modifiedPlaybackUrl(props, url, state.contents[state.index], player);
      player.src({src: playbackUrl, type: videoType(url), hls: {withCredentials: true}, ...getMMVideoAssetInfo(state.contents[state.index])})
      runAdInVideo(player, props, state, _this, props.section.showVideoAd, "fake-video_html5_api", state.contents[state.index]);
      startSendingMMEvents(player, playbackUrl, state.contents[state.index], false)
      const promise = player.play();
      configuration.isAppTvodType && handleUnmutedPlayback(promise, player, "fake-video_html5_api", _this.props.userEngaged, _this.showBigPlayButton);  //This funtion will only execute if Video in CTV is not autoplayed.
      var newVideo = document.getElementById("fake-video_html5_api");
      newVideo.setAttribute("type", _videoType);
      newVideo.muted = false;
      const video_id = state.contents[state.index].identifier
      const rememberSpotId = props && props.remember[props.section.identifier]
      const rememberSpot = rememberSpotId && (rememberSpotId.contentId === video_id)
      newVideo.addEventListener("contextmenu", (event) => {
        event.preventDefault();
      });
      if(checkCorrectPlatform([PLATFORM_ZEASN])) {
        newVideo.addEventListener("play", function(){
          let setTime = setRememberSpot.call(this, streamProgress, rememberSpot, rememberSpotId, newVideo);
        }, {once: true})
      } else {
        newVideo.addEventListener("loadedmetadata", function(){
          let setTime = setRememberSpot.call(this, streamProgress, rememberSpot, rememberSpotId, newVideo);
          if(props.runningOnMobile && state.playing_Ad) { // playing ad
            _this.setRememberSpotAfterAd = setTime
          }
        }, {once: true})
      }

      if(isSmartTv()) {
        newVideo.addEventListener("play", () => _this.hideNavbar())
        newVideo.addEventListener("pause", () => _this.hideNavbar(true))
      }
      // ads breaks
      const showVideoAd = props.ads && props.section.showVideoAd && props.ads.videoAdtype && props.ads.videoAdtype !== "spotX" && showAdsAsPerFrequency(props.ads, state.video_count)
      const nonVmapAdBreak = (showVideoAd && (props.ads.nonVmapAdBreakFrequency || props.ads.nonVmapCuePointKey));
      const adBreakData =  nonVmapAdBreak ? {
        state,
        _this, 
        player, 
        nonVmapAdBreakFrequency: props.ads.nonVmapAdBreakFrequency,
        nonVmapCuePointKey: props.ads.nonVmapCuePointKey,
        videoItem: state.contents[state.index] || null
      } : null;
      newVideo.addEventListener("timeupdate", () => handleVideoTimeUpdate(adBreakData, _this), false)
      const vttCaption = getVttCaption(state.contents && state.contents[state.index]);
      vttCaption && player.addRemoteTextTrack({src: vttCaption, default: _this.props.showSubtitle}, false);
      if (_this.props.showSubtitle && !vttCaption) {
        addTextTracksEventVideoJs(player)
      }
      isSmartTv() && setDefaultValuesInVideoJs({player})
    })
  }
}

function handleVideoTimeUpdate(adBreakData, _this) {
  if(document.getElementById("fake-video_html5_api")){
    var video = document.getElementById("fake-video_html5_api");
  } else {
    var video = document.getElementById("fake-video");
  }

  if(video) {
    handleVideoMinutes(video, _this)
    if(!_this.state.playing_Ad && video.duration && !_this.videoTotalDuration) {
      _this.videoTotalDuration = video.duration;
    }
  }

  if(adBreakData && video && !_this.state.playing_Ad) {
    const currentTime = Math.floor(video.currentTime);
    const {state, _this, player, nonVmapAdBreakFrequency, nonVmapCuePointKey, videoItem} = adBreakData;
    let timeMatched = false;
    if(nonVmapAdBreakFrequency && (currentTime % (nonVmapAdBreakFrequency * 60) === 0)) {
      timeMatched = true;
    } else if(nonVmapCuePointKey && videoItem && videoItem.cuePoints) {
      const cuePoints = getItemCuePoints(videoItem.cuePoints);
      if(cuePoints.indexOf(currentTime) > -1) {
        timeMatched = true;
      }
    }
    if(currentTime !== 0 && currentTime !== _this.state.setTimeAfterAdBreak && timeMatched) {
      _this.saveVideoTimeInRemeberSpot(video);
      player.ima.initializeAdDisplayContainer();
      player.ima.setContentWithAdTag(null, getModifiedAdUrl(_this.props, videoItem, player));
      player.ima.requestAds();
      player.ima.playAdBreak();
      _this.setState({setTimeAfterAdBreak: currentTime});
    }
  }
}

function handleVideoMinutes(videoEl, _this) {
  const time = Math.floor(videoEl.currentTime);
  const tier = videoEl.videoHeight;
  if(time !== _this.streamedVideoTime) {
    _this.streamedVideoTime = time;
    _this.streamedVideoSeconds++;
  }
  if(tier && _this.streamedVideoTier && tier !== _this.streamedVideoTier) {
    prepareMazEventData(_this, "tier_changed");
  } else if(_this.streamedVideoSeconds === MAZ_REPORTING_INTERVAL) {
    prepareMazEventData(_this, "beacon");
  }
  if(tier) {
    _this.streamedVideoTier = tier;
  }
}

function prepareMazEventData(_this, type) {
  const {streamedVideoSeconds, streamedVideoTier, videoStartTime, state, props, videoCount} = _this || {};
  if(streamedVideoSeconds == null || !streamedVideoTier || !state) {
    return;
  }
  const { index, contents } = state;
  const data = {
    eventValue: streamedVideoSeconds.toString(),
    eventStartTime: videoStartTime,
    tierStreamed: `${streamedVideoTier}px`,
    contentId: contents[index].identifier,
    contentUrl: contents[index].contentUrl,
    eventAction: type
  }

  resetMazEventData(_this);
  sendVideoMinutes(data, props.feeds, _this.videoPathname, contents[index].title, videoCount)
}

function resetMazEventData(_this) {
  if(_this) {
    _this.videoStartTime = new Date().toISOString();
    _this.streamedVideoSeconds = 0;
  }
}

class FakeLive extends React.Component {
  constructor(props){
    super(props)
    var index = FindVideo(props);
    this.state = {
      contents: props.section.contents,
      index: index,
      original_index: index,
      type: videoType(httpsUrl(props.section.contents[0].contentUrl)),
      video_count: 0,
      metering_type: null,
      setTimeAfterAdBreak: null,
      playing_Ad: false,
      video_title: "",
      showTitle: false,
      showMetering: false,
      adWrapperExist: false,
      videoInQueue: undefined,
      freeContentOver: false,
      showPlayButton: configuration.isAppTvodType ? false : !this.props.userEngaged,
      video_start: false,
      afterMount: false
    }
    this.backButton = this.backButton.bind(this)
    this.maintainTitle = this.maintainTitle.bind(this)
    this.onAdComplete = this.onAdComplete.bind(this)
    this.onAdStart = this.onAdStart.bind(this)
    this.onAdReady = this.onAdReady.bind(this)
    this.onAllAdComplete = this.onAllAdComplete.bind(this)
    this.showBigPlayButton = this.showBigPlayButton.bind(this)
    this.METERING_TYPE = getMeteringTypes();
    this.videoCount=0;
    this.simulatorInterval = null;
    this.simulatorCount = 0;
    this.videoStartedAt = new Date();
    this.streamedVideoTier = null;
    this.streamedVideoSeconds = 0;
    this.streamedVideoTime = 0;
    this.videoStartTime = new Date().toISOString();
    this.video_stream_buffered = 0;
    this.navbar_timeout = null;
    this.videoPathname = "";
    this.totalWatchedTimeInterval = null;
  }

  totalWatchTimeCalculator() {
    if(this.props.feeds.kTotalWatchedTimeBeaconing){
      const { index, contents } = this.state;

      this.totalWatchedTimeInterval = setInterval(() => {
        let key = K_TOTAL_WATCHED_TIME_BEACONING
        let currentWatchedTime = Math.floor((new Date() - this.videoStartedAt) / 1000)
        let data = {
          contentUrl: contents[index].contentUrl,
          path: this.videoPathname,
          videoItem: contents[index],
          duration: currentWatchedTime,
          videoPlayCount: this.videoCount
        }
        setLocalStorage(key, {...data})
      }, 60 * 1000);
    }
  }

  shouldComponentUpdate(nextProps, nextState){
    if(!isAvailable(this.props.section.identifier,this.props.section.special,this.props.section.registerWall) && nextProps.subscription){
      if(document.getElementById('fake-video-overlay')) document.getElementById('fake-video-overlay').style.display = 'none';
      return false;
    } else {
      return true;
    }
  }

  hideNavbar = (clear = false) => {
    const navbar = document.getElementById('nav-bar');
    if(navbar) {
      this.navbar_timeout && clearTimeout(this.navbar_timeout);
      navbar.style.display = 'flex';
      if(clear) {
        return;
      }
      this.navbar_timeout = setTimeout(() => {
        navbar.style.display = 'none';
      }, 2000);
    }
  }
  
  onAdReady() {
    const adCont = document.getElementById("fake-video_ima-ad-container");
    if(adCont) {
      if(!adCont.classList.contains("bumpable-ima-ad-container")) {
        this.setState({adWrapperExist: true})
      }
    }
  }

  saveVideoTimeInRemeberSpot(video) {
    if(configuration.isAppTvodType) return;
    this.props.rememberSpot(this.props.app_id, this.props.section.identifier,
      parseInt(videojs(video).currentTime()), parseInt(videojs(video).duration()), false, this.props.section.type,
      this.props.logged_in, this.props.user.auth_token, this.props.user.user_id, this.state.contents[this.state.index].identifier);
  }

  onAdStart(ev) {
    if(ev) this.current_ad = ev.getAd();
    this.setState({playing_Ad: true})
    if(isSmartTv() && checkIfSectionLevelContent()) {
      // hide navbar on ad play
      toggleNavbarVisibility('none');
    }
  }

  onAdError() {
    this.setState({
      adWrapperExist: false,
      playing_Ad: false
    })
    this.setTimeAfterAdBreak();
    if(isSmartTv() && checkIfSectionLevelContent()) {
      toggleNavbarVisibility('block');
    }
  }

  updateSrcInSmartTv(_type) {
    if(configuration.kUseTheoPlayer) return;
    let ad_url = this.props.ads.adURL;
    let isVapidAd = ad_url.includes('vpaid');
    if((_type === 'ad_com' && isVapidAd) || (_type === 'ad_all_com' && !isVapidAd)) return;

    let adPod = this.current_ad.getAdPodInfo()
    let podInfo = {
      podPosition: adPod.getAdPosition(),
      podLength: adPod.getTotalAds()
    }

    if (podInfo.podPosition == podInfo.podLength) { 
      // console.log("played manually");
      // current_player.reset();
      let playbackUrl =  modifiedPlaybackUrl(this.props, current_src, this.state.contents[this.state.index], current_player);
      resettingVideoSource();
      current_player.src({ src: playbackUrl, type: current_type, hls: {withCredentials: true} });
      const _this = this;
      current_player.ready(() => {
        const promise = current_player.play();
        configuration.isAppTvodType && handleUnmutedPlayback(promise, current_player, "fake-video_html5_api", _this.props.userEngaged, _this.showBigPlayButton);
        var newVideo = document.getElementById("fake-video_html5_api");
        const video_id = _this.state.contents[_this.state.index].identifier
        const rememberSpotId = _this.props && _this.props.remember[_this.props.section.identifier]
        const rememberSpot = rememberSpotId && (rememberSpotId.contentId === video_id)
        const video_cid = configuration.isAppTvodType && _this.state.contents[_this.state.index].cid
        const streamProgress = (video_cid && _this.props.allStreamObject[video_cid] && _this.props.allStreamObject[video_cid].progress) || null;
        if(checkCorrectPlatform([PLATFORM_ZEASN])) {
          newVideo.addEventListener("play", function(){
            setRememberSpot.call(this, streamProgress, rememberSpot, rememberSpotId, newVideo);
            // set the subtitle if exist
            if (_this.props.showSubtitle) updateTextTrack(current_player, "showing");
          }, {once: true})
        } else {
          newVideo.addEventListener("loadedmetadata", function(){
            setRememberSpot.call(this, streamProgress, rememberSpot, rememberSpotId, newVideo);
            // set the subtitle if exist
            if (_this.props.showSubtitle) updateTextTrack(current_player, "showing");
          }, {once: true})
        }
        newVideo.addEventListener("contextmenu", (event) => {
          event.preventDefault();
        });

        const vttCaption = getVttCaption(_this.state.contents && _this.state.contents[_this.state.index]);
        vttCaption && current_player.addRemoteTextTrack({src: vttCaption, default: _this.props.showSubtitle}, false);
        if (_this.props.showSubtitle && !vttCaption) {
          addTextTracksEventVideoJs(current_player)
        }
        isSmartTv() && setDefaultValuesInVideoJs({player: current_player})
      })
    }
  }

  onAdComplete() {
    if(isSmartTv()) {
      setTimeout(() => {
        this.updateSrcInSmartTv('ad_com');
      },0);

      if(checkIfSectionLevelContent()) {
        toggleNavbarVisibility('block');
      }
    } else {
      this.setTimeAfterAdBreak();
    }
    this.setState({playing_Ad: false})
    this.maintainTitle();
  }

  
  onAllAdComplete() {
    if(isSmartTv()) {
      this.updateSrcInSmartTv('ad_all_com');
    }
    this.setState({adWrapperExist: false})
    if(this.state.videoInQueue >= 0) {
      this.setNextFakeVideo(this.state.videoInQueue);
    }
  }

  setTimeAfterAdBreak() {
    var video = document.getElementById("fake-video_html5_api");
    if(!video) return;
    try {
      if(this.state.setTimeAfterAdBreak) {
        video.currentTime = this.state.setTimeAfterAdBreak;
      }
      if(video.paused && video.play) {
        video.play();
      }
    } catch {
      console.log("Error: Can't autoplay")
    }
  }

  startVideo() {
    let showAd = false
    if(this.props.ads.spotxid !== undefined){
      showAd = showVideoAd(this.state, this.props)
    }
    this.setState({video_title: this.state.contents[this.state.index].title})
    if(!showAd){
      this.videoCount = this.videoCount+1;
    }
    playVideo(httpsUrl(this.state.contents[this.state.index].contentUrl), this.state.type, this.props, showAd, true, this, this.state)
    if(!showAd){
      this.setState({video_title: this.state.contents[this.state.index].title})
      this.maintainTitle();
    }
    this.videoStartTime = new Date().toISOString();
    this.setState({
      playing_Ad: showAd,
      showPlayButton: false
    })
  }

  playOriginalVideo(){
    this.setState({playing_Ad: false})
    this.maintainTitle()
    const _self = this;
    if (document.getElementById("video-inlineMenu")) {document.getElementById("video-inlineMenu").style.display = "inherit"}
    var video_id = this.state.contents[this.state.index].identifier
    var rememberSpotId = this.props.remember[this.props.section.identifier]
    var rememberSpot = rememberSpotId && (rememberSpotId.contentId === video_id)
    var original_index = this.state.original_index
    var index = this.state.index
    var video = videojs('fake-video', {
      html5: {
        vhs: {
          overrideNative: !isSmartTv(),
        },
        nativeTextTracks: false
      },
      controlBar: {
        // enable default fullscreen toggle button if on ios
        fullscreenToggle: isIOS,
        pictureInPictureToggle: false,
        subsCapsButton: false,
        audioTrackButton: false
      }
    });
    videojsQualityHelper(video)

    // for web only
    // not supported on ios
    // register fullscreen plugin which make viewport body in fullscreen instead of video
    if(!isSmartTv() && !isIOS) {
      video.fullscreen();
    }

    const _videoType = videoType(this.state.contents[this.state.index].contentUrl);
    video.on('error', function(e) {
      this.setState({ isShowCountineWatching: false })
      if(isSmartTv()) {
        onCTVVideoError(e, video, 'live');
      } else {
        onVideoError(_videoType, video);
      } 
    });
    video.on('ended', () => {
      // <Video> onEnded event not triggering for mobile devices so handling that here
      if(this.props.runningOnMobile) {
        this.setNextFakeVideo(this.state.index, true)
      }
    });
    var url = httpsUrl(this.state.contents[this.state.index].contentUrl)
    let playbackUrl =  modifiedPlaybackUrl(this.props, url, this.state.contents[this.state.index], video);
    video.ready(function () {
      video.src({
        src: playbackUrl,
        type: videoType(_self.state.contents[_self.state.index].contentUrl),
        hls: {
          withCredentials: true
        },
        ...getMMVideoAssetInfo(_self.state.contents[_self.state.index])
      });
      startSendingMMEvents(video, playbackUrl, _self.state.contents[_self.state.index], false)
      if(video.play){
        const promise = video.play()
        configuration.isAppTvodType && handleUnmutedPlayback(promise, video, "fake-video_html5_api", _self.props.userEngaged && _self.showBigPlayButton);
        _self.videoCount = _self.videoCount+1;
      }
      var newVideo = document.getElementById("fake-video_html5_api")
      newVideo.muted = false;
      const video_cid = configuration.isAppTvodType && _self.state.contents[_self.state.index].cid;
      const streamProgress = (video_cid && _self.props.allStreamObject[video_cid] && _self.props.allStreamObject[video_cid].progress) || null;
      if(checkCorrectPlatform([PLATFORM_ZEASN])) {
        newVideo.addEventListener("play", function(){
          if(streamProgress && newVideo.duration) {
            this.currentTime = Math.floor((streamProgress * newVideo.duration) / 100);
          } else if((original_index === index) && rememberSpot && rememberSpotId["p"] &&
            (rememberSpotId["p"] != rememberSpotId["t"])){
            newVideo.currentTime = rememberSpotId["p"]
          }
        }, {once: true})
      } else {
        newVideo.addEventListener("loadedmetadata", function(){
          if(streamProgress && newVideo.duration) {
            this.currentTime = Math.floor((streamProgress * newVideo.duration) / 100);
          } else if((original_index === index) && rememberSpot && rememberSpotId["p"] &&
            (rememberSpotId["p"] != rememberSpotId["t"])){
            newVideo.currentTime = rememberSpotId["p"]
          }
        }, {once: true})
      }
      newVideo.addEventListener("contextmenu", (event) => {
        event.preventDefault();
      });
      newVideo.addEventListener("timeupdate", () => handleVideoTimeUpdate(null, _self), false);
      const vttCaption = getVttCaption(_self.state.contents && _self.state.contents[_self.state.index]);
      vttCaption && video.addRemoteTextTrack({src: vttCaption, default: _self.props.showSubtitle}, false);
    }, this);
  }

  setNextFakeVideo(index, prevVideoEnded){
    if(this.state.adWrapperExist && prevVideoEnded && isSmartTv()) return;

    var newIndex = index + 1
    if (newIndex >= this.state.contents.length) {
      newIndex = 0
    }
    if(prevVideoEnded && this.state.adWrapperExist) {
      //ad will play, don't run next video for now
      this.setState({videoInQueue: index});
      return;
    }
    var spotxIframe = getSpotxIframe();
    if(spotxIframe) {
      setTimeout(function(){
        removeSpotxIframe();
        this.playOriginalVideo()
      }.bind(this), 500)
    } else {
      this.pushTvodStreams();
      this.sendWatchedVideoAnalytics();
      prepareMazEventData(this, prevVideoEnded ? "video_ended" : "video_exited");
      analytics.setEventForSimlive(this.props.feeds, this.videoPathname, 'Video','Simulated Live Play', this.state.contents[this.state.index], this.videoCount, this.state.contents[this.state.index].contentUrl)
      //for tvod check for next video
      if(configuration.isAppTvodType && this.props.validateNextVideo) {
        this.props.validateNextVideo({newSection: this.state.contents[newIndex], newIndex: newIndex});
        this.setState({autoPlayInAction: newIndex})
      } else {
        this.setNextFakeVideoData(newIndex)
      }
    }
  }

  componentDidMount() {
    this.videoPathname = window.location.pathname;
    this.totalWatchTimeCalculator()
    if(isSmartTv() && configuration.use_light_sliders) {
      this.id = addRemoveAllNavigation({ selector: '.video-play-extra-button .focusable' });
      this.inline_id = addRemoveAllNavigation({ selector: '#video-inlineMenu .focusable' });
    }
    this.setState({afterMount: true});

    if(!this.state.showPlayButton) {
      this.startVideo()
    } else {
      if(isSmartTv()) {
        if(document.getElementById('fake-video_html5_api')) {
          document.getElementById('fake-video_html5_api').addEventListener('loadedmetadata',(e) => {
            this.setState({ video_start: true });
          },{once: true});  
        }          
      }
      if(document.getElementById("loader")) document.getElementById("loader").style.display = "none";
    }

    if(configuration.is_simulator && checkCorrectPlatform(PLATFORM_HTML_SIMULATOR) && !checkIfSafari()) {
      // For Bug simulator Bug https://github.com/MagAppZine/MagAppZine-MagControl/issues/8267
      this.simulatorInterval = setInterval(() => {
        this.checkVideoStatusForSimulator()
      },1000)
    }
    if(isSmartTv()) {
      document.addEventListener('keyup', this.handleRemoteClick);
      this.hideNavbar();
    }
  }

  setNextFakeVideoData(newIndex) {
    // dispose previous one before playing next
    this.setState({ isShowCountineWatching: true })
    var video = document.getElementById("fake-video");
    if(video && !configuration.kUseTheoPlayer) {
      videojs(video).dispose();
    }
    var url = httpsUrl(this.state.contents[newIndex].contentUrl)
    var type = videoType(url)
    this.setState({video_title: this.state.contents[newIndex].title})
    this.setState({index: newIndex, type: type, setTimeAfterAdBreak: null})
    this.setState({video_count: this.state.video_count + 1})
    this.videoTotalDuration = null;
  }

  handleRemoteClick = (e) => {
    if(checkIfHideNavbarOnLive(e.keyCode)) this.hideNavbar()
  }

  pushTvodStreams() {
    if(this.state.playing_Ad) return;
    if(configuration.isAppTvodType) {
      setPlaylistVideoCounter("fake", this.props.section.identifier, this.state.index);
    }
  }

  clearSimulatorInterval() {
    if(this.simulatorInterval) {
      clearInterval(this.simulatorInterval);
      this.simulatorInterval = null;
    }
  }


  clearTotalWatchedTimeInterval() {
    if(this.totalWatchedTimeInterval) {
      clearInterval(this.totalWatchedTimeInterval)
      this.totalWatchedTimeInterval = null
      removeItemLocalStorage(K_TOTAL_WATCHED_TIME_BEACONING)
    }
  }
 
  checkVideoStatusForSimulator() {
    if(!this.state.playing_Ad && !this.state.showPlayButton) {
      this.simulatorCount++;
      if(this.simulatorCount > 5) {
        const player = videojs('fake-video');
        if(player && !player.paused() && player.readyState() == 0) {
          player.trigger("error");
          player.pause();
          if(document.getElementById('fake-video')) document.getElementById('fake-video').classList.add("vjs-error")
        }
      }
    }
  }

  componentDidUpdate(prevProps, prevState){
    if(isSmartTv() && (prevProps.isUserOnline != this.props.isUserOnline) && !configuration.kUseTheoPlayer) {
      if(this.props.isUserOnline) {
        // restore video
        let playbackUrl = modifiedPlaybackUrl(this.props, current_src, this.state.contents[this.state.index], current_player);
        resettingVideoSource();
        current_player.src({ src: playbackUrl, type: current_type, hls: {withCredentials: true} });
        current_player.ready(() => {
          current_player.play();
        });
        const vttCaption = getVttCaption(this.state.contents && this.state.contents[this.state.index]);
        vttCaption && current_player.addRemoteTextTrack({src: vttCaption, default: this.props.showSubtitle}, false);
      }
    }
    
    const video = document.getElementById("fake-video");

    if(prevState.index !== this.state.index || prevProps.section.identifier !== this.props.section.identifier) {

      let showAd = false
      if(this.props.ads.spotxid !== undefined){
        showAd = showVideoAd(this.state, this.props)
      }
      if(!showAd){
        this.videoCount = this.videoCount+1;
      }
      playVideo(httpsUrl(this.state.contents[this.state.index].contentUrl), this.state.type, this.props, showAd, false, this, this.state)
      if(!showAd) {
        this.setState({video_title: this.state.contents[this.state.index].title})
        this.maintainTitle()
      } else {
        this.setState({playing_Ad: true, showTitle: false, showMetering: false})
      }
      this.simulatorCount = 0;
      this.videoStartedAt = new Date();
    }

    if(prevState.freeContentOver !== this.state.freeContentOver && this.state.freeContentOver) {
      var spotxIframe = getSpotxIframe();
      if(video) videojs(video).dispose();
      if(spotxIframe) {
        removeSpotxIframe();
      }
      if(this.theoPlayerReference) {
        this.theoPlayerReference.stop();
      }
    }

    if(prevState.playing_Ad !== this.state.playing_Ad) {
      if(this.state.playing_Ad) {
        prepareMazEventData(this, "ad_started");
      } else {
        resetMazEventData(this);
        if(this.props.runningOnMobile && this.setRememberSpotAfterAd && current_player) {
          current_player.currentTime(this.setRememberSpotAfterAd);
          this.setRememberSpotAfterAd = null;
        }
      }
    }

    if(isSmartTv() && prevProps.showSubtitle != this.props.showSubtitle) {
      if(this.theoPlayerReference) {
        toggleSubtitlesVisibility(this.theoPlayerReference, this.props.showSubtitle);
      } else if(current_player) {
        toggleSubtitlesVisibility(current_player, this.props.showSubtitle);
      }
    }

    if(prevProps.autoPlayInAction !== this.props.autoPlayInAction && this.props.autoPlayInAction !== null && this.state.autoPlayInAction) {
      this.setNextFakeVideoData(this.state.autoPlayInAction);
      this.setState({autoPlayInAction: null});
    }
  }

  componentWillUnmount(){
    this.pushTvodStreams();
    prepareMazEventData(this, "video_exited");
    this.sendWatchedVideoAnalytics();
    analytics.setEventForSimlive(this.props.feeds, this.videoPathname, 'Video','Simulated Live Play', this.state.contents[this.state.index], this.videoCount, this.state.contents[this.state.index].contentUrl)

    var video = document.getElementById("fake-video")
    if (getSpotxIframe()) {
      removeSpotxIframe();
    } else if((video || this.theoPlayerReference) && !configuration.isAppTvodType) {
      let currentTime = this.theoPlayerReference ? this.theoPlayerReference.currentTime : videojs(video).currentTime();
      let duration = this.theoPlayerReference ? this.theoPlayerReference.duration : videojs(video).duration();
      this.props.rememberSpot(this.props.app_id, this.props.section.identifier,
        parseInt(currentTime), parseInt(duration), false, this.props.section.type,
        this.props.logged_in, this.props.user.auth_token, this.props.user.user_id, this.state.contents[this.state.index].identifier);
    }
    if (video) {
      videojs(video).dispose();
    }
    this.clearSimulatorInterval();
    this.clearTotalWatchedTimeInterval()

    if(isSmartTv()) {
      if(configuration.use_light_sliders) {
        addRemoveAllNavigation({ id: this.id });
        addRemoveAllNavigation({ id: this.inline_id });  
      }

      document.removeEventListener('keyup', this.handleRemoteClick);
      this.hideNavbar(true);
    }
  }

  sendWatchedVideoAnalytics() {
    const { index, contents } = this.state;
    const time = Math.floor((new Date() - this.videoStartedAt) / 1000);
    analytics.setEventForWatchedVideo(contents[index].contentUrl, this.props.feeds, this.videoPathname, contents[index], time, this.videoCount);
  }

  onFreeContentOver() {
    this.setState({freeContentOver: true})
    this.clearSimulatorInterval();
    if (document.getElementById("video-inlineMenu")) {document.getElementById("video-inlineMenu").style.display = "none"}
  }
 
  backButton(e){
    if(e) e.preventDefault();
    // exit fullscreen
    // not supported on ios
    if (!isSmartTv() && !isIOS && !fullscreen().isNormalScreen()) fullscreen().contract();
    this.props.updateVideoCount(this.state.video_count + 1)
    if(this.props.handleKeepInterstitialOpen) {
      this.props.handleKeepInterstitialOpen();
      return;
    }

    this.props.closeModal()
    const backUrlObj = backButtonObj(this.props.feeds, this.videoPathname.split('/').slice(3).join('/'));
    this.props.history.replace(backUrlObj.backPath);
    if(isSmartTv() && document.getElementById("nav-bar")) {
      document.getElementById("nav-bar").style.display = '';
    }
  }


  maintainTitle(){
    let displayTitle = this.props.section.displayTitle;
    let metering_type = null;
    const METERING_TYPE = getMeteringTypes();
    if (this.props.section.locked && !isAvailable(this.props.section.identifier,this.props.section.special,this.props.section.registerWall)) {
      if (this.props.feeds.ctaMetadata && this.props.feeds.ctaMetadata.metering) {
        metering_type = METERING_TYPE.VIEWS
      } else if (this.props.section.access && this.props.section.access.timed !== undefined) {
        metering_type = METERING_TYPE.TIME
      }
    }
    

    this.handleMetering(false);
    if (displayTitle && displayTitle.type === "duration"){
      this.handleTitle(true)
      var dTime = displayTitle.value*1000
      setTimeout(() => {
        this.handleTitle(false);
        this.handleMetering(true);
        if (metering_type === METERING_TYPE.VIEWS) {
          setTimeout(() => {
            this.handleMetering(false);
          }, dTime)
        }
      }, dTime)
    } else if(displayTitle && displayTitle.type == "full"){
      this.handleTitle(true)
      if(metering_type) {
        // if metering hide title after 5 seconds
        setTimeout(() => {
          this.handleTitle(false);
          this.handleMetering(true);
          if (metering_type === METERING_TYPE.VIEWS) {
            // if view metering hide metering after 5 seconds
            setTimeout(() => {
              this.handleMetering(false);
              this.handleTitle(true);
            }, 5000)
          }
        }, 5000)
      }
    } else {
      this.handleTitle(false)
      this.handleMetering(true);
      if (metering_type === METERING_TYPE.VIEWS) {
        setTimeout(() => {
          this.handleMetering(false);
        }, 5000)
      }
    }
  }

  handleMetering(show) {
   this.setState({showMetering: show})
  }

  handleTitle(show){
    this.setState({showTitle: show});
  }

  theoPlayerUpdateTimeHandler(currentTime, totalTime) {
    handleVideoMinutes(this.theoPlayerReference, this)
  }

  onTheoPause() {
    prepareMazEventData(this, "video_paused");
    this.videoPausedByUser = true;
  }

  onTheoPlay() {
    if(isSmartTv() && !this.state.video_start) {
      this.setState({video_start: true})
    }
    if(this.videoPausedByUser) {
      resetMazEventData(this);
      this.videoPausedByUser = false;
    }
  }

  handleTheoAdState(state) {
    switch(state) {
      case "adbegin": this.onAdStart()
        break;
      case "adend": 
        this.onAllAdComplete();
        this.onAdComplete();
        break;
      case "aderror": this.onAdError()
        break;
      default:
        break;
    }
  }

  getPlayerKey() {
    if(configuration.kUseTheoPlayer) {
      return "fakeLive";
    }
    return `${this.state.contents[this.state.index].identifier}`;
  }

  renderInstructionText() {
    if(!this.props.canShowInstructionText) return null;
    return (
      <InstructionText
        feeds={this.props.feeds}
        runningOnMobile={this.props.runningOnMobile}
        playing_Ad={this.state.playing_Ad}
      />
    )
  }

  showBigPlayButton() {
    this.setState({showPlayButton : true})
  }

  renderContinueWatching() {
    const extras = this.props.feeds.extras;
    if(!extras || !extras.continueWatching || !this.state.isShowCountineWatching) return null;
    return (
      <ContinueWatching
        playerId={"fake-video_html5_api"}
        extras={extras}
        theoPlayerReference={this.theoPlayerReference}
      />
    )
  }

  renderPlayer() {
    if(configuration.kUseTheoPlayer) {
      const rememberSpotId = this.props && this.props.remember[this.props.section.identifier]
      const rememberSpot = (rememberSpotId && (rememberSpotId.contentId === this.state.contents[this.state.index].identifier)) ? rememberSpotId : {}
      const streamProgress = (configuration.isAppTvodType && this.props.allStreamObject[this.state.contents[this.state.index].cid] && this.props.allStreamObject[this.state.contents[this.state.index].cid].progress) || null;
      return (
        <TheoPlayer
          section={this.state.contents[this.state.index]}
          type={this.state.type}
          feeds={this.props.feeds}
          showPlayButton={this.state.showPlayButton}
          setShowPlayButton={this.showBigPlayButton}
          isAdForFeed={this.props.section.showVideoAd}
          videoCount={this.state.video_count}
          ads={this.props.ads}
          rememberSpot={rememberSpot}
          streamProgress={streamProgress}
          showSubtitle={this.props.showSubtitle}
          theoPlayerUpdateTimeHandler={(currentTime, totalTime) => this.theoPlayerUpdateTimeHandler(currentTime, totalTime)}
          setNextVideo={() => this.setNextFakeVideo(this.state.index, true)}
          oninit={(player) => this.theoPlayerReference = player}
          onAdUpdateState={(state) => this.handleTheoAdState(state)}
          onTheoPlay={() => this.onTheoPlay()}
          onTheoPause={() => this.onTheoPause()}
          videoType={"fake"}
          user_ip={this.props.user_ip}
          user_concent={this.props.userConcent}
          device_uuid={this.props.device_uuid}
          user_coords={this.props.user_coords}
          device_model={this.props.device_model}
          hideNavbar={this.hideNavbar}
          isUserOnline={this.props.isUserOnline}
        />
      )
    }
    return (
      <video 
        className={"video-js vjs-default-skin video-content-" + this.state.contents[this.state.index].identifier}
        id="fake-video"
        preload="10"
        onEnded={(index) => this.setNextFakeVideo(this.state.index, true)} disablePictureInPicture 
      />
    )
  }
  
  render(){
    let inlineVal = this.props.section.inlineMenu || "";
    if(inlineVal && this.state.showPlayButton) {
      inlineVal = "limited";
    }
    let navbar_present = !configuration.isAppTvodType && document.getElementsByClassName("nav-view")[0] || false;
    const isSectionLevel = !configuration.isAppTvodType && checkIfSectionLevelContent();
    
    // if(isSmartTv() && document.getElementById("nav-bar")) document.getElementById("nav-bar").style.display = 'none';
    let margin_style = {};
    if(isSmartTv() && isSectionLevel) {
      margin_style = {marginTop: '3em'}
    }

    const runningOnMobile = this.props.runningOnMobile;
    const showControlBar = !isMobile && this.state.playing_Ad;
    return (
      <div className={"videoFull " + ( showControlBar ? "show_live_controls" : "")} style={{top: 0, left: 0, position: "absolute"}}>
        <div key={this.getPlayerKey()} className="fake-video-ad">
          {this.renderPlayer()}
          {
            isSmartTv()
            ? (<span className="icon-hollow_arrow" style={{display:'none'}}><a id="overlay_video_back_btn" href="" onClick={(event) => this.backButton(event)} className="backBtn" style={{zIndex: 100}}></a></span>
            )
            : (!navbar_present && !isSectionLevel && 
              <span className="icon-hollow_arrow">
                <a id="overlay_video_back_btn" href="" onClick={(event) => this.backButton(event)} className={(runningOnMobile ? "backBtn-mobile" : "backBtn")} style={{zIndex: 100}}>
                  {runningOnMobile && <img src={backMobile} alt="Back"/>}
                </a>
              </span>
            )
          }
        </div>
        {this.state.showPlayButton && <div id="video-play-extra-button" className="video-play-extra-button">
          <img alt="Play" className="focusable" tabIndex={getTabIndex()} src={playButton} onClick={() => this.startVideo()}/>
        </div>}
        {!configuration.isAppTvodType && !this.state.showPlayButton && this.props.section.locked && !isAvailable(this.props.section.identifier,this.props.section.special,this.props.section.registerWall) &&
          <Metering
            content_id={this.props.section.identifier}
            parent_id={this.props.section.identifier} 
            contentAccess={this.props.section.access}
            feeds={this.props.feeds}
            onFreeContentOver={() => this.onFreeContentOver()}
            playing_Ad={this.state.playing_Ad}
            showMetering={this.state.showMetering}
            content={this.props.section}
            isLive={true}
            interstitialModalDetails={this.props.modal && this.props.modal.interstitialProps}
            video_start={this.state.video_start}
          />

        }
        {!runningOnMobile && this.state.showTitle && this.state.video_title && !this.state.playing_Ad && <div className="prevThumbnail video-title ellipsis" style={ margin_style}>
          <p className=""> <b>{this.state.video_title}</b></p>
        </div>}
        {this.renderInstructionText()}
        {configuration.isAppTvodType && !this.props.userEngaged && <AudioMutedMessage _this={this} videoId={"fake-video_html5_api"}/>}
        {!configuration.isAppTvodType && !runningOnMobile && !this.state.playing_Ad && <InlineMenu
          all_contents={this.props.section.contents}
          feeds={this.props.feeds}
          next={(index) => this.setNextFakeVideo(index - 1)}
          playing={this.state.index}
          inlineMenu={inlineVal}
          fake={true}
          policyData={this.props.policyData}
        />}
        {/* Uncomment below line if want to show play, pause, rewind and forward icon on action */}
        {/* {isSmartTv() && !this.state.showPlayButton  && this.state.afterMount && <PlayerIcon  videoId = {'fake-video_html5_api'}/>} */}
        {!this.state.showPlayButton && this.renderContinueWatching()}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  remember: state.remember.remember,
  logged_in: state.user.logged_in,
  user: state.user.user,
  userEngaged: state.user.userEngaged,
  ads: state.ads,
  subscription: state.user.subscription,
  user_ip: state.user.ip,
  user_concent: state.user.userConcent,
  device_uuid: state.user.device_uuid,
  user_coords: state.user.user_coords,
  device_model: state.user.device_model,
  showSubtitle: state.feeds.showSubtitle,
  isUserOnline: state.user.isUserOnline,
  allStreamObject: state.streams.allStreamObject,
  policyData: state.feeds.policyData  
})

const mapDispatchToProps = {
  rememberSpot: localRememberSpot,
  closeModal: closeModal,
  openModal: openModal,
  updateVideoCount: updateVideoCount
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FakeLive));
