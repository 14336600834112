import React from 'react';
import { connect } from "react-redux"

import { userActions, getUserConcent } from "../actions/userActions"
import LoginScreen from '../components/LoginScreen'
import UserAccess from '../components/UserAccess'
import Background from '../components/Background'
import Logo from '../components/Logo'
import DataAndPrivacy from './DataAndPrivacy';
import { getGdprParamsForApi, checkIfSectionLevelContent } from '../helperfunctions/feedHelper';
import { checkIfSingleOption, getTabIndex, isSmartTv } from '../helperfunctions/common';
import { closeModal, openModal } from '../actions/modalActions';
import { addRemoveAllNavigation, getBackBtnKeyCodes } from '../helperfunctions/ctvHelper';
import config from '../constants/config';
import CleengLogin from './CleengLogin';
import JsSpatialNavigation from '../ctvnavigation/js-spatial-navigation';
import ReactFocusLock from 'react-focus-lock';
import { getAllConstants } from '../helperfunctions/regionalization';

class LoginFlow extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loginRegistrationFlag: false,
            loginData: {},
            openFacebookTab: false,
            isWebsiteLogin: false,
            openWebsiteLogin: false,
            singleLogin: false,
        }
        this.backKeyPressed= false
    }

    componentDidMount() {
        if(isSmartTv() && config.use_light_sliders) {
            this.id = addRemoveAllNavigation({ selector: '.user-access .focusable' });
            if(this.props.modalLogin && !document.body.classList.contains('transparent')) JsSpatialNavigation.focus(this.id);
        }
        if(isSmartTv() && this.props.login_data) {
            this.setState({
                loginRegistrationFlag: true,
                loginData: this.props.login_data
            });
        }
        document.addEventListener('keyup',this.handleRemoteClick);
    }
    handleRemoteClick = (e) => {
        const back_key_codes = getBackBtnKeyCodes();
        if (back_key_codes[e.keyCode]) {
          this.backKeyPressed = true
        }
	}

    componentWillUnmount() {
        if(this.props.updateEmailSelected) {
            this.props.updateEmailSelected(false);
        }
        if(isSmartTv() && config.use_light_sliders) {
            addRemoveAllNavigation({ id: this.id });
        }
        if(isSmartTv()){
            this.backKeyPressed = false;
            document.removeEventListener('keyup',this.handleRemoteClick);
        }
    }

    onAPIDataReady(loginData, fbData, isWebsiteLogin) {
        this.setState({loginRegistrationFlag: true, loginData: loginData, fbData: fbData, isWebsiteLogin: isWebsiteLogin, openWebsiteLogin: false},
            () => {
                //If user already gave concent and login type is website, then do continue action.
                if (this.props.userConcent && isWebsiteLogin) {
                    this.onContinueDataPrivacy();
                }
            })
    }

    cancelDataPrivacy = () => {
        if (isSmartTv()) {
            const { allProps } = this.props;
            const {screen, directLogin, feeds} = allProps;
            if (checkIfSingleOption(this.getScreenType(screen), feeds) && directLogin) {
                this.props.closeModal();
            } else {
                this.setState({loginRegistrationFlag: false});
            }
        }
        // console.log("cancelDataPrivacy")
    }

    getScreenType = (loginscreen=null) => {
        if (config.isAppTvodType) return "tvodLogin"
        let screen = "firstSave"
        if (loginscreen === "registerWall"){
          screen = "registrationWall"
        }
        return screen;
    }

    onContinueDataPrivacy() {
        let changeState = {loginRegistrationFlag: false}
        if(this.state.loginData) {
            const {email, password, app_id, button_clicked} = this.state.loginData;
            this.props.userActions(email, password, app_id, button_clicked, getGdprParamsForApi(this.props.allProps.feeds));
        } else if(this.state.fbData) {
            changeState.openFacebookTab = true
        }
        else {
            changeState.openWebsiteLogin = true
        }
        this.setState({...this.state, ...changeState});
        this.props.getUserConcent();    
    }

    checkUserConcent(feeds) {
        if(this.props.userConcent) return true;// concent taken already
        if(!feeds.gdpr || Object.keys(feeds.gdpr) < 1) return true; // gdpr not in feed
        return false;
    }

    handleTermsPolicyClick = (type, url, is_gdpr = false) => {
        if(this.props.pushToTvodWatchman) {
            this.props.pushToTvodWatchman(type, url);
            return;
        }
        if(this.props.pushToOnboardingScreen) {
            this.props.pushToOnboardingScreen(type, url);
            return;
        }
        const goBack = window.location.pathname.includes('settings') ? 'login' : null;
        let login_data = null;

        if(is_gdpr && isSmartTv()) {
            login_data = this.state.loginData;
        }
        this.props.openModal({ type: 'terms_privacy_screen', message: { type, url, goBack, login_data }, last_modal_data: this.props.modal });
    }

    renderTermsAndPrivacyPolicy = (feeds) => {
        const { kTermsOfServiceText, kSettingsPrivacyPolicy} = getAllConstants();
        if (isSmartTv()) {
            return (
                <div className="termsOfUse">
                    <ul className="mob_info">
                    <li><a data-sn-left="" onClick={() => this.handleTermsPolicyClick(kTermsOfServiceText,feeds.loginUIMetadata.urls.terms)} className="text-white focusable" tabIndex={getTabIndex()}>{kTermsOfServiceText}</a></li>
                    <li className="termsVertical">|</li>
                    <li><a data-sn-right="" onClick={() => this.handleTermsPolicyClick(kSettingsPrivacyPolicy,feeds.loginUIMetadata.urls.policy)} className="text-white focusable" tabIndex={getTabIndex()}>{kSettingsPrivacyPolicy}</a></li></ul>
                </div>
            )
        } else {
            return (
                <div className="termsOfUse">
                    <ul className="mob_info">
                    <li><a href={feeds.loginUIMetadata.urls.terms} target = "_blank" className="text-white focusable" tabIndex={getTabIndex()}>{kTermsOfServiceText}</a></li>
                    <li className="termsVertical">|</li>
                    <li><a href={feeds.loginUIMetadata.urls.policy} target = "_blank" className="text-white focusable" tabIndex={getTabIndex()}>{kSettingsPrivacyPolicy}</a></li></ul>
                </div>
            )
        }
    }

    cancelFbLogin = () => {
        if (isSmartTv) {
            this.setState({openFacebookTab: false})
        }
    }

    singleLogin = () => {
        this.setState({singleLogin: true})
    }

    renderLoginOptions() {
        const { allProps, headerLogo, selectEmail, modalLogin, pushToOnboardingScreen} = this.props;
        const { background, app_id, feeds, isSavedSection, screen, titleMsg, directLogin } = allProps;
        //Cleeng only for tvod
        if (config.payment_provider === 'cleeng')
            return (
                <div id="cleeng-container" className='cleeng-login'>
                    <CleengLogin />
                </div>
            )
        if(this.state.singleLogin){
            if(this.backKeyPressed){
                this.props.closeModal()
            }
            this.props.selectEmail()
        }
        return (
            <>
                <div className={"userLoginCntnt" + (!modalLogin ? " queueLogin" : "")}>
                    {(background || pushToOnboardingScreen) && <Background background={background} />}
                    <Logo logo={headerLogo} />
                    <LoginScreen
                        openFacebookTab={this.state.openFacebookTab}
                        onClick={selectEmail}
                        app_id={app_id}
                        feeds={feeds}
                        isSavedSection={isSavedSection}
                        screen={screen || null}
                        titleMsg={titleMsg || null}
                        userConcent={this.checkUserConcent(feeds)}
                        onAPIDataReady={(fbData, isWebsiteLogin) => this.onAPIDataReady(null, fbData, isWebsiteLogin)}
                        openWebsiteLogin={this.state.openWebsiteLogin}
                        directLogin={directLogin}
                        cancelFbLogin = {this.cancelFbLogin}
                        isSingleLogin={this.singleLogin}
                    />
                </div>
                {this.renderTermsAndPrivacyPolicy(feeds)}
            </>
        )
    }

    renderUserAccess() {
        const { allProps, headerLogo, modalLogin, selectBack, pushToOnboardingScreen} = this.props;
        const { background, app_id, feeds, screen, titleMsg, logged_in } = allProps;
        const {email, password} = this.state.loginData || {};
        return (
            <div className={"userLoginCntnt" + (!modalLogin ? " queueLogin" : "")}>
                {(background || pushToOnboardingScreen)&& <Background background={background} />}
                <Logo logo={headerLogo} />
                <UserAccess 
                    email={email}
                    password={password}
                    logged_in={logged_in}
                    selectBack={selectBack} 
                    app_id={app_id}
                    feeds={feeds}
                    modalLogin={modalLogin} 
                    userConcent={this.checkUserConcent(feeds)}
                    screen={screen || null}
                    titleMsg={titleMsg || null}
                    onAPIDataReady={(loginData) => this.onAPIDataReady(loginData)} 
                />
            </div>
        )
    }

    render() {
        const { emailSelected, allProps, divStyle, modalLogin } = this.props;
        const { feeds } = allProps;
        if(this.state.loginRegistrationFlag && !this.props.userConcent && feeds.gdpr) {
            return <DataAndPrivacy 
                onContinue={() => this.onContinueDataPrivacy()} 
                onCancel={() => this.cancelDataPrivacy()} 
                feeds={feeds} 
                runningOnMobile={this.props.runningOnMobile} 
                handleTermsPolicyClick={this.handleTermsPolicyClick}
            />
        } else if(emailSelected) {
            if(modalLogin) {
                return this.renderUserAccess();
            } else {
                return (
                    <div className="user-access overflow-scroll-auto" style={divStyle}>
                        {this.renderUserAccess()}
                    </div>
                )
            }
        } else {
            if(modalLogin) {
                if(isSmartTv() && !document.body.classList.contains('transparent')) {
                    return (
                        <ReactFocusLock>
                            {this.renderLoginOptions()}
                        </ReactFocusLock>
                    )
                } else {
                    return (
                        <>
                            {this.renderLoginOptions()}
                        </>
                    )
                }
            } else {
                return (
                    <div className="user-access overflow-scroll-auto" style={divStyle}>
                        <>
                            {this.renderLoginOptions()}
                        </>
                    </div>
                )
            }
            
        }
    }
}

const mapStateToProps = (state) => ({
    userConcent: state.user.userConcent,
    modal: state.modal.modal
})

const mapDispatchToProps = {
    userActions: userActions,
    getUserConcent: getUserConcent,
    closeModal,
    openModal
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginFlow)