import React, {useEffect} from 'react';
import { getTabIndex, isSmartTv } from '../helperfunctions/common';
import Loader from './Loader';
import { addRemoveAllNavigation } from '../helperfunctions/ctvHelper';
import configuration from '../constants/config';
import ReactFocusLock from 'react-focus-lock';

function ConfirmModal({ header, message, onConfirm, onDeny, confirmText, denyText, hideDeny, isLoading, popupStyle = {} }) {
    let id;
    useEffect(() => {
        //didmount
        if(isSmartTv() && configuration.use_light_sliders) {
            id = addRemoveAllNavigation({ selector: '#confirmation-modal .focusable' });
        }
      
        return () => {
            //will unmount
            if(isSmartTv() && configuration.use_light_sliders) {
                addRemoveAllNavigation({ id });
            }
        }
    });

    return (
        <ReactFocusLock>
            {isLoading && (<Loader />)}
            <div id="confirmation-modal" className="confirm-modal">
                <div style={{...popupStyle}} onClick={(e) => {
                    e.stopPropagation();
                }}>
                    {header && <h3>{header}</h3>}
                    {message && <p>{message}</p>}
                    <div className="button-div">
                        <button  className="focusable" tabIndex={getTabIndex()} onClick={onConfirm}>{confirmText || "Allow"}</button>
                        {!hideDeny && <button className="focusable" tabIndex={getTabIndex()} onClick={onDeny}>{denyText || "Deny"}</button>}
                    </div>
                </div>
            </div>
        </ReactFocusLock>
    )
}

export default ConfirmModal;