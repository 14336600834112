import { DETAIL_THEME, GRID_THEME, HERO_SLIDER_THEME, INLINE_MENU, JUMBO_THEME, SERIES_THEME, SPOTLIGHT_THEME, VIDEO_GRID_THEME_THEME } from "../constants/common"
import configuration from "../constants/config"
import { elemOrientation, getThumnailViewClass, supportingToRTL } from ".";

function commonParams(_self) {
    return {
        mousewheelControl: false,
        observer: true,
        spaceBetween: 20,
        mousewheelForceToAxis: true,
        mousewheelInvert: false,
        preloadImages: false,
        slidesOffsetAfter: 30,
        lazyLoading: true,
        freeMode: true,
        onInit: (swiper) => {
            _self.swiper = swiper
        },
        mousewheel: {
            forceToAxis: true,
            invert: false,
        }
    }
}
function commonPortrait(_self) {
    return {
        ...commonParams(_self),
        slidesPerView: 8.1,
        breakpoints: {
          1920: {
            slidesPerView: 7.3
          },
          1366: {
            spaceBetween: 15
          }
        }
      }
}

function commonLandscape(_self) {
    return {
        ...commonParams(_self),
        slidesPerView: 6.5,
        breakpoints: {
            1920: {
                slidesPerView: 6.1,
            },
            1366: {
                spaceBetween: 15
            }
        }
      }
}
export function getWebSwiperParamsByTheme(_self, section, themeType, nextSlideCB) {
    if (themeType === GRID_THEME) {
        return getWebGridThemeParams(_self, section)
    }
    if (themeType === SPOTLIGHT_THEME) {
        //Passing props in case of spotlight content, instead of section.content
        const props = section;
        let gridClassName = getSpotlightGridClassNames(props);
        const isSquare = gridClassName.includes("square-view");
        let params;
        if (props.section && props.section.regularLayout == 'fullscreen') {
            params = getWebFullscreenParams(_self, props)
        } else {
            params = getWebSpotlightParams(_self, isSquare)
        }
        return { gridClassName: gridClassName, params: params }
    }
    if (themeType === HERO_SLIDER_THEME) {
      return getHeroCommonFactorParams(_self, section, 15)
    }
    if (themeType === SERIES_THEME) {
      const extraSlides = section.thumbOrientation === "square" ? 2 : 2;
      return getCommonFactorParams(_self, 0, extraSlides);
    }
    if (themeType === JUMBO_THEME) {
      return getWebJumboThemeParams(_self, section)
    }
    if (themeType === DETAIL_THEME) {
      return getWebDetailThemeParams(_self, section, nextSlideCB)
    }
    if (themeType === INLINE_MENU) {
      return getWebInlineParam(_self)
    }
    if (themeType === VIDEO_GRID_THEME_THEME) {
      return getVideoGridThemeParams(_self, section)
    }
}

export function getWebBannerSwiperParams(initialSlide, _self) {
    return {
        initialSlide: initialSlide,
        slidesPerView: 1.8,
        ...getRTLParams(_self),
        loop: true,
        spaceBetween: 20,
        autoplay: {
          delay: 2000
        },
        loopedSlides: 3,
        mousewheelControl: true,
        mousewheelInvert: false,
        centeredSlides: true,
        mousewheelForceToAxis: true,
        breakpoints: {
          768: {
            spaceBetween: 15
          }
        },
        mousewheel: {
         forceToAxis: true,
         invert: false
       }
      }
}

export function getMobileBannerSwiperParams(initialSlide, _self) {
  return {
    initialSlide: initialSlide,
    ...getRTLParams(_self),
    slidesPerView: 1,
    loop: true,
    autoplay: {
      delay: 2000
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
    },
    mousewheelControl: true,
    mousewheelInvert: true,
    centeredSlides: true,
    mousewheelForceToAxis: true,
    mousewheel: {
      forceToAxis: true,
      invert: true
    }
  }
}

function getGridThemeNumbers(factor, extra_slides = 0) {
  const width = window && window.screen.width;
  if(width > (1400 * factor)) {
    return 6.1 + extra_slides;
  } else if(width > (1100 * factor)) {
    return 5.1 + extra_slides;
  } else if(width > (800 * factor)) {
    return 4.1 + extra_slides;
  } else {
    return 3.1 + extra_slides;
  }
}

function getHeroGridThemeNumbers(factor, index) {
  const width = window && window.screen.width;
  if(width > (1400 * factor)) {
    return index === 0 ? 4.5 : 6.1;
  } else if(width > (1100 * factor)) {
    return index === 0 ? 3.5 : 5.1;
  } else if(width > (800 * factor)) {
    return index === 0 ? 2.5 : 4.1;
  } else {
    return index === 0 ? 1.5 : 3.1;
  }
}

function getVideoGridThemeParams(_self, section) {
  if(section.index == 0 && section.isHero) {
   return {
        ...commonParams(_self),
        ...getRTLParams(_self),
        slidesPerView: 1,
        breakpoints: {
        1400: {
            slidesPerView: 2,
            spaceBetween: 30
        },
        1100: {
            slidesPerView: 2,
            spaceBetween: 30
        },
        768: {
          slidesPerView: 1.6,
          spaceBetween: 30
        },
        520: {
          slidesPerView: 1,
          spaceBetween: 30
        }
      }
    }
  }
  else { 
    return {
      ...commonParams(_self),
      ...getRTLParams(_self),
      slidesPerView:'auto',
    }
  }
}

function getRTLParams(_self) {
  let rtl = {};
  if(_self && _self.props && _self.props.feeds && supportingToRTL(_self.props.feeds)) {
    rtl = {rtl: elemOrientation(_self.props.feeds)}
  }
  return rtl;
}

function getCommonFactorParams(_self, extraSpace = 0, extra_slides = 0) {
  const factor = configuration.standard_theme_scaling_factor || 1;
  
  return {
    ...commonParams(_self),
    ...getRTLParams(_self),
    slidesPerView: getGridThemeNumbers(factor, extra_slides), // Using method for first time load
    spaceBetween: (20 + extraSpace),
    breakpoints: {
      9999: {
        slidesPerView: 6.1 + extra_slides
      },
      [1400 * factor]: {
        slidesPerView: 5.1 + extra_slides
      },
      [1100 * factor]: {
        slidesPerView: 4.1 + extra_slides
      },
      [800 * factor]: {
        slidesPerView: 3.1 + extra_slides
      },
      1366: {
        spaceBetween: (15 + extraSpace)
      }
    }
  }
}


function getHeroCommonFactorParams(_self, section, extraSpace = 0) {
  const factor = configuration.standard_theme_scaling_factor || 1;

  return {
    ...commonParams(_self),
    ...getRTLParams(_self),
    slidesPerView: getHeroGridThemeNumbers(factor, section.index), // Using method for first time load
    spaceBetween: (20 + extraSpace),
    breakpoints: {
      9999: {
        slidesPerView: section.index === 0 ? 4.5 : 6.1,
      },
      [1400 * factor]: {
        slidesPerView: section.index === 0 ? 3.5 : 5.1
      },
      [1100 * factor]: {
        slidesPerView: section.index === 0 ? 2.5 : 4.1,
        spaceBetween:40
      },
      [800 * factor]: {
        slidesPerView: section.index === 0 ? 1.5 : 3.1
      },
      1366: {
        spaceBetween: (15 + extraSpace)
      }
    }
  }
}

function getWebGridThemeParams(_self, section) {
  return getCommonFactorParams(_self, 0);
}

function getSpotlightGridClassNames(props) {
  const { content, section, nosaveicon } = props
  const thumbClass = getThumnailViewClass(section);
  const noSave = nosaveicon ? " nothumbBox " : " ";
  return `spotlight${noSave}slider-box grid-view ${thumbClass} content-${content.identifier}`;
}

export const getWebSpotlightParams = (self, isSquare) => {
  return getCommonFactorParams(self, 5, (isSquare ? 2 : 0));
}

function getWebFullscreenParams(self, props) {
  if (props.index === 0) {
    return getCommonFactorParams(self, 5, 2);
  }
  return getCommonFactorParams(self, 5);
}

function getWebJumboThemeParams(self, section) {
  const factor = configuration.standard_theme_scaling_factor;
  const {showingSavedSection, feeds} = self.props

  if(factor === 1 && showingSavedSection && feeds && feeds.extras && feeds.extras.wrapSavedSectionItems) {
    return getCommonFactorParams(self, -1)
  }
  else return getCommonFactorParams(self, 5);
}

function getWebDetailThemeParams(_self, content, nextSlideCB) {
 
  const commonParams = {
    direction: 'vertical',
    resistance: false,
    keyboard: true,
    slidesPerView: 'auto',
    preloadImages: false,
    freeModeMomentum: true,
    touchReleaseOnEdges: true,
    spaceBetween: 24,
    observer: true,
    lazyLoadingOnTransitionStart: true,
    slidesOffsetAfter: 300,
    mousewheelControl: false,
    freeMode: true,
    on:{
      init: () => {
        _self.swiper =  document.querySelector('.swiper-container-vertical').swiper
      },
      slideChange: () => {
        nextSlideCB();
      }
    },
    mousewheel: {
      forceToAxis: true,
      invert: false,
    },
    height: window.innerHeight,
    ...getRTLParams(_self),
  }
  if(content.thumbOrientation === "portrait"){
    return {
      ...commonParams,
      breakpoints: {
        1600: {
          slidesOffsetAfter: 150,
        },
        1400: {
          spaceBetween:16,
          slidesOffsetAfter: 120,
        },
        1366: {
          slidesOffsetAfter: 50,
        },
        1280: {
          slidesOffsetAfter: 0,
        }
      }
    }
  } else {
    return {
      ...commonParams,
      breakpoints: {
        1600: {
          slidesOffsetAfter: 200,
        },
        1400: {
          spaceBetween:16,
          slidesOffsetAfter: 190,
        },
        1366: {
          slidesOffsetAfter: 100,
        },
        1280: {
          slidesOffsetAfter: 50,
        }
      }
    }
  }
}

function getWebInlineParam(_self) {

  return {
    mousewheelControl: true,
    slidesPerView: 'auto',
    spaceBetween: 20,
    mousewheelForceToAxis: true,
    mousewheelInvert: true,
    preloadImages: false,
    lazyLoading: true,
    freeMode: true,
    ...getRTLParams(_self),
    onInit: (swiper) => {
      _self.swiper = swiper
    },
    mousewheel: {
        forceToAxis: true,
        invert: false,
    }
  }
}