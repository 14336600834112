import React from "react";
import { Login, Register, PasswordReset, Auth, store, Capture } from "@cleeng/mediastore-sdk";
import * as UserActions from '../actions/userActions';
import { connect } from "react-redux";
import { getLocalStorage } from "../helperfunctions/storage";
import configuration from "../constants/config";
import ConfirmModal from "./ConfirmModal";
import { getCleengCredentials } from "../helperfunctions/authHelper";

function CleengLogin(props) {
  const [tab, setTab] = React.useState("register");
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [capture, setCapture] = React.useState(false);
  const [isPasswordReset, setIsPasswordReset] = React.useState(false);

  React.useEffect(() => {
    if(isLoggedIn) {
      const cleengTokens = getCleengCredentials();
      const params = {
        ...cleengTokens,
        app_id: configuration.app_id
      }
      props.cleengLogin(params)
    }
  }, [isLoggedIn])

  if(capture) {
    return (
      <Capture onSuccess={() => setIsLoggedIn(Auth.isAuthenticated)}/>
    )
  }

  if (tab === "login")
    return (
      <Login
        onSuccess={() => setCapture(true)}
        onRegisterClick={() => setTab("register")}
        onPasswordResetClick={() =>
          setTab("resetPassword")
        }
      />
    );

  if (tab === "register") 
    return <Register 
      onSuccess={() => setCapture(true)}
      onHaveAccountClick={() => setTab("login")}
    />;

  if (tab === "resetPassword") return (
    <>
      <PasswordReset
        onSuccess={(val) => setIsPasswordReset(true)}
      />
      {isPasswordReset && <ConfirmModal 
        message="Reset password link has been sent to your email."
        onConfirm={() => {setTab("login"); setIsPasswordReset(false)}}
        confirmText={"Done"}
        hideDeny
      />}
    </>)
}

const mapStateToProps = (state) => ({
  userConcent: state.user.logged_in
})

const mapDispatchToProps = {
  cleengLogin: UserActions.cleengLogin,
}
export default connect(null, mapDispatchToProps)(CleengLogin);

