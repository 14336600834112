import React from 'react'

const StopIcon = (props) => {
    return (
        <div className={`stop-icon ${props.className}`}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="#ffffff" width="800px" height="800px" viewBox="0 0 612.00 612.00" stroke="#ffffff">
                <g id="SVGRepo_bgCarrier" stroke-width="0" />
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" />
                <g id="SVGRepo_iconCarrier">
                    <rect width="360" height="360" fill-rule="evenodd" transform="translate(128 128)" />
                </g>
            </svg>
        </div>
    )
}

export default StopIcon