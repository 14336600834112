import React, { useEffect, useState, useCallback } from 'react';
import OtpInput from 'react-otp-input';
import JsSpatialNavigation from '../../ctvnavigation/js-spatial-navigation';
import { elemOrientation } from '../../helperfunctions';
import { getTabIndex } from '../../helperfunctions/common';
import { addRemoveAllNavigation } from '../../helperfunctions/ctvHelper';
import { getAllConstants } from '../../helperfunctions/regionalization';

function CodeInput(props) {
  const { onExit, onSubmit, formStrings, type, OnSetupFailed, runningOnMobile, feeds } = props;
  const STRINGS = getAllConstants();
  const [otp, setOtp] = useState("");
  const [codeArray, setCodeArray] = useState([]);
  const [title, setTitle] = useState(formStrings.title)
  const [msg, setMsg] = useState(formStrings.msg)
  const [error, setError] = useState(formStrings.error)

  useEffect(() => {
    const focusId = addRemoveAllNavigation({ selector: '.keyboard-wrapper .focusable' });
    const el = document.getElementById("keyboard-1");
    if(el) {
      JsSpatialNavigation.focus(el);
    }
    window && window.addEventListener("keydown", handleUserKeyPress);
    
    return () => {
      addRemoveAllNavigation({ id: focusId });
      window && window.removeEventListener("keydown", handleUserKeyPress);
    }
  }, [])

  useEffect(() => {
    if(otp.length === 4) {
      handleOnSubmit(otp);
    }
  }, [otp])

  const handleUserKeyPress = useCallback(event => {
    const { key } = event;
    const int = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];
    if(int.includes(parseInt(key))) {
      setOtp(prevOtp => `${prevOtp}${key}`);
      const el = document.getElementById(`keyboard-${key}`);
      if(el) {
        JsSpatialNavigation.focus(el);
      }
    }
  }, []);

  const handleOnSubmit = () => {
    if(type === "validate") {
      onSubmit(otp);
      return;
    }
    // user need to enter code twice
    if(codeArray.length === 0) {
      setCodeArray([otp]);
      setOtp("");
      setMsg(STRINGS.sPleaseEnterCodeAgain)
    } else {
      if(codeArray[0] === otp) {
        onSubmit(otp);
        return;
      } else {
        if (codeArray.length === 1) {
          const newCodeArray = [codeArray[0], otp];
          setCodeArray(newCodeArray);
          setOtp("");
          setMsg(STRINGS.sPleaseTryAgain)
          setError(STRINGS.sCodeNotMatched);
        } else {
          OnSetupFailed();
        }
      }
    }

  }

  
  const handleButtonClick = (val) => {
    const int = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];
    if(int.includes(val)) {
      if(otp.length === 4) return;
      const newVal = otp.toString() + val;
      setOtp(newVal)
    } else if (val === "X") {
      if(otp.length === 0) return;
      const newVal = otp.substring(0, otp.length - 1);
      setOtp(newVal)
    } else if (val === "Exit") {
      onExit();
    } else if (val === "Done") {
      if(otp.length === 4) {
        handleOnSubmit();
      }
    }
  }


  //=====================

  const renderKeyboard = () => {
    const buttons = [1, 2, 3, "Exit", 4, 5, 6, "X", 7, 8, 9, "Done", 0]
    return (
      <div className='keyboard-wrapper'>
        {buttons.map(btn => {
          return (
            <div 
              className='key-button focusable' 
              key={btn}
              role="button"
              aria-label={btn.toString().toLowerCase()}
              tabIndex={getTabIndex()}
              onClick={() => handleButtonClick(btn)}
              id={`keyboard-${btn}`}
            >
              {btn}
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <div className='code-input-container' dir={elemOrientation(feeds)}>
      <p aria-label={title.toLowerCase()}>{title}</p>
      <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={4}
          separator={<span>&nbsp;&nbsp;</span>}
          inputStyle={{
            padding: 5,
            fontSize: "40px",
            fontWeigth: 'bold',
            borderRadius: "5px",
            color: "#c6c5c5",
            border: "1px solid #7c7c7c",
            background: "black",
            width: runningOnMobile ? "40px" : "60px",
            height: runningOnMobile ? "40px" : "60px"
          }}
          isInputNum
          isInputSecure
        />
        {error && <span className='error' aria-label={error.toLowerCase()}>{error}</span>}
        {msg && <span className='msg' aria-label={msg.toLowerCase()}>{msg}</span>}
        {renderKeyboard()}
    </div>
  )
}

export default CodeInput;