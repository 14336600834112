import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";

import backMobile from "../images/icons/back-mobile.png";
import Background from '../components/Background';
import { userActions, logoutUserActions, logoutMazTv, setAutoPlay } from "../actions/userActions";
import { openModal } from "../actions/modalActions";
import { clearSavedItems } from "../actions/itemActions"
import { getTabIndex, isAppRunningOnLowEndPlatform, isSmartTv } from '../helperfunctions/common';
import { addRemoveAllNavigation, getBackBtnKeyCodes } from '../helperfunctions/ctvHelper';
import { getFontStyleFromFeed, parentalLockExist } from "../helperfunctions";
import JsSpatialNavigation from '../ctvnavigation/js-spatial-navigation';
import { setPageViewFixedSections } from '../constants/analytics';
import configuration from '../constants/config';
import packageJson from '../../package.json';
import SwitchSlider from '../components/SwitchSlider';
import SetParentalLock from '../components/parentalLock/SetParentalLock';
import ResetParentalLock from '../components/parentalLock/ResetParentalLock';

import '../css/moreSection.css';
import LinkSection from '../components/LinkSection';

const MoreSettings = (props) => {
  const { feeds, runningOnMobile, section, autoPlay, policyData } = props;
  const fontStyle = getFontStyleFromFeed(feeds);
  const { contents } = section;
  const [currentPage, updatePage] = useState(contents);
  const [allPages, setAllPages] = useState([contents]);
  const [showLink, setShowLink] = useState(null);
  const [newCodeFlow, setNewCodeFlow] = useState(false);
  const [resetCodeFlow, setResetCodeFlow] = useState(false);

  useEffect(() => {
    setPageViewFixedSections('More'); 
    if(isSmartTv()) {
      JsSpatialNavigation.focus('navbar');
      let focusId = addRemoveAllNavigation({ selector: '.more-section .focusable' });
      JsSpatialNavigation.set('navbar', {
        leaveFor: {
          'down': `@${focusId}`
        }
      });   
      return () => {
        addRemoveAllNavigation({ id: focusId });
        JsSpatialNavigation.set('navbar', {
          leaveFor: {
            'down': '.content-box .focusable'
          }
        }); 
      }
    }
  }, [])

  useEffect(() => {
    // focus on first item
    if (isSmartTv()) {
      let focusId = addRemoveAllNavigation({ selector: '.more-section .focusable' });
    }
  }, [currentPage]);

  const handleMenuClick = (item) => {
    if(!item || !item.contents) return;

    const newPage = item.contents;
    const pages = [...allPages];
    pages.push(newPage)
    updatePage(newPage);
    setAllPages(pages)
  }

  const handleGoBack = () => {
    if(showLink) {
      setShowLink(null);
      return;
    }
    const pages = [...allPages];
    pages.pop();
    if(pages && pages.length) {
      const newPage = pages[pages.length - 1];
      updatePage(newPage);
      setAllPages(pages)
    } else {
      updatePage(contents);
      setAllPages([contents])
    }
  }

  const openLink = (item) => {
    if(!item || !item.contentUrl) return;
    setShowLink(item)
  }

  const handleSettingsClick = (item) => {
    switch(item.action) {
      case "authenticate":
        if(props.logged_in) {
          if (props.mazTVLogin) {
            props.logoutMazTv();
          }
          props.logoutUserActions();
          props.clearSavedItems();
        } else {
          props.openModal({type: "login", directLogin: true})
        }
        break;
      case "app_help":
        openLink(item);
        break;
      case "setParentalCode":
        setNewCodeFlow(true);
        break;
      case "resetParentalCode":
        setResetCodeFlow(true);
        break;
      default:
        break;
    }
  }

  const removeTransition = () => {
    if(isAppRunningOnLowEndPlatform()) {
      return 'remove-transition';
    }
    return '';
  }

  const getStyles = (type) => {
    const colors = feeds.standardMenuUI;
    if(type === "background") {
      if(colors && colors.backgroundColor) {
        return {color: colors.backgroundColor};
      }
      return undefined;
    }

    let style = {};
    if(!colors) return {};
    if (type === "focus") {
      if(colors.selectedItemFontColor) {
        style.color = colors.selectedItemFontColor.replace('#FF', '#');
      }
      if(colors.selectedItemBackgroundColor) {
        style.backgroundColor = colors.selectedItemBackgroundColor.replace('#FF', '#');
      }
    } else if(type === "header") {
      if(colors.detailFontColor) {
        style.color = colors.detailFontColor.replace('#FF', '#');
      }
    } else {
      if(colors.itemFontColor) {
        style.color = colors.itemFontColor.replace('#FF', '#');
      }
    }
    
    return style;
  }

  useEffect(() => {
    let active = document.querySelector(".more-section .more-section-wrapper .item:hover");
    if(!active) return;
    
    const focusStyle = getStyles("focus");
    if(!focusStyle) return;
    
    if (!active.classList.contains('header')) {
      active.style.color = focusStyle.color;
    }
    active.style.backgroundColor = focusStyle.backgroundColor;
  }, [currentPage])

  const handleFocus = (e, type) => {
    const focusStyle = getStyles("focus");
    if(!focusStyle) return;

    const activeIcon = e && e.currentTarget;
    if(!activeIcon) return;
    
    if (type !== 'header') {
      activeIcon.style.color = focusStyle.color;
    }
    activeIcon.style.backgroundColor = focusStyle.backgroundColor;
  }

  const handleBlur = (e, type) => {
    const style = getStyles(type);
    if(!style) return;

    const allItems = document.querySelectorAll(".more-section .item");
    allItems.forEach(el => {
      if (type !== 'header' && !el.classList.contains('header')) {
        el.style.color = style.color;
      }
      el.style.backgroundColor = "unset";
    })
  }

  const getTitleName = (item) => {
    if(!item || !item.title) return "";
    let title = item.title;
    const placeHolder = item.placeholder;
    if(!placeHolder || !title.includes(placeHolder)) return title;

    if(placeHolder === "[APP_NAME]") {
      title = title.replace(placeHolder, ` ${configuration.title}`);
    } else if(placeHolder === "[APP_VERSION]") {
      const version = packageJson.version;
      title = title.replace(placeHolder, ` ${version}`);
    } else if(placeHolder === "[LANGUAGE_NAME]" || placeHolder === "[LANGUAGE_CODE]") {
      title = title.replace(placeHolder, ` ${policyData.language || ""}`);
    } else if(placeHolder === "[LOCATION_NAME]" || placeHolder === "[LOCATION_CODE]") {
      title = title.replace(placeHolder, ` ${policyData.locale_id || ""}`);
    } else if(item.placeholderValue) {
      title = title.replace(placeHolder, item.placeholderValue);
    }

    return title;
  }

  const handleAutoplay = (val) => {
    props.setAutoPlay(val);
  }

  // -----------Renders----------
  const renderLogo = () => {
    if(runningOnMobile) return null;

    return (
      <div className="left-wrapper">
        {feeds.logo && <img src={feeds.logo.url}/>}
      </div>
    )
  }

  const renderMenuItem = (item, key) => {
    const styles  = getStyles(item.type);
    return (
      <div 
        key={key}
        className={`item focusable ${removeTransition()}`} tabIndex={getTabIndex()}
        role="menuitem"
        aria-title={item.title.toLowerCase()}
        onClick={() => handleMenuClick(item)}
        style={styles}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onMouseEnter={handleFocus}
        onMouseLeave={handleBlur}
      >
        <span>{item.title}</span>
        {item.icon && <img src={item.icon}/>}
      </div>
    )
  }

  const renderAutoPlay = (item, key, styles) => {
    return (
      <div className='item cc-div' style={styles} key={key}
        role="checkbox"
        aria-label={item.title.toLowerCase()}
        aria-checked={autoPlay}
        onFocus={isSmartTv() ? handleFocus : undefined}
        onBlur={isSmartTv() ? handleBlur : undefined}
        onMouseEnter={handleFocus}
        onMouseLeave={handleBlur}
      >
        <span>{item.title}</span>
        <SwitchSlider 
          isChecked={autoPlay}
          onSliderChange={(val) => handleAutoplay(val)}
          section="more"
        />
      </div>
    )
  }

  const renderSettingsItem = (item, key) => {
    const actionNotSupported = ["restore", "location", "language", "notifications"];
    if(actionNotSupported.includes(item.action)) return null;
    if((item.action === "setParentalCode" || item.action === "resetParentalCode") &&
      !parentalLockExist(feeds)
    ) {
      return null;
    }

    const styles  = getStyles(item.type);
    
    if(item.action === "autoplay") {
      return renderAutoPlay(item, key, styles);
    } 
    const resetDisabled = (item.action === "resetParentalCode" && (!props.logged_in || !props.codeConfigured));

    return (
      <div 
        key={key}
        className={`item focusable ${removeTransition()}`} tabIndex={getTabIndex()}
        role={props.logged_in && item.action === "authenticate" ? "button" : "link"}
        aria-label={props.logged_in && item.action === "authenticate" ? "Log Out" : item.title.toLowerCase()}
        onClick={() => {
          if(resetDisabled) return;
          handleSettingsClick(item)
        }}
        style={styles}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onMouseEnter={handleFocus}
        onMouseLeave={handleBlur}
        disabled={resetDisabled}
      >
        <span>{props.logged_in && item.action === "authenticate" ? "Log Out" : item.title}</span>
        {item.icon && <img src={item.icon}/>}
      </div>
    )
  }

  const renderLinkItem = (item, key) => {
    const styles  = getStyles(item.type);
    return (
      <div key={key} 
        className={`item focusable ${removeTransition()}`}
        role="link"
        aria-title={item.title.toLowerCase()}
        tabIndex={getTabIndex()} 
        onClick={() => openLink(item)}
        style={styles}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onMouseEnter={handleFocus}
        onMouseLeave={handleBlur}
      >
        <span>{item.title}</span>
        {item.icon && <img src={item.icon}/>}
      </div>
    )
  }

  const renderHeaderItem = (item, key) => {
    const styles  = getStyles(item.type);
    return (
      <div key={key} 
        className={`item header ${removeTransition()}`}
        role="heading"
        aria-label={item.title.toLowerCase()}
        tabIndex={getTabIndex()}
        style={styles}
      >
        {item.icon && <img src={item.icon}/>}
        {item.title}
      </div>
    )
  }

  const renderInfoItem = (item, key) => {
    const styles  = getStyles(item.type);
    return (
      <div key={key} 
        className={`item focusable ${removeTransition()}`}
        role="contentinfo"
        aria-label={getTitleName(item).toLowerCase()}
        tabIndex={getTabIndex()}
        style={styles}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onMouseEnter={handleFocus}
        onMouseLeave={handleBlur}
      >
        <span>{getTitleName(item)}</span>
        {item.icon && <img src={item.icon}/>}
      </div>
    )
  }

  const renderLinkInfo = () => {
    return (
      <LinkSection item={showLink} onClose={() => {}} hideBackButton fullScreen/>
    )
  }

  const renderBackButton = () => {
    if(allPages.length < 2 && !showLink) return null;

    let style = {}
    if(isSmartTv()) {
      style.display = 'none';
    }
    return (
      <span className={"icon-hollow_arrow backBtnSpan " + (runningOnMobile ? "backBtnSpan-mobile" : "")}>
        <a id="more-back-button" href="" onClick={(e) => {
            e.preventDefault();
            handleGoBack();
          }} 
          className={(runningOnMobile ? "backBtn-mobile" : "backBtn")}
          style={{zIndex: 100, ...style}}>
            {runningOnMobile && <img src={backMobile} alt="Back"/>}
        </a>
      </span>
    )
  }

  if(newCodeFlow) {
    return (
      <SetParentalLock
        goBack={() => setNewCodeFlow(false)}
        runningOnMobile={runningOnMobile}
      />
    )
  }
  if(resetCodeFlow) {
    return (
      <ResetParentalLock
        goBack={() => setResetCodeFlow(false)}
      />
    )
  }

 
  return (
    <div className="more-section">
      <Background background={getStyles("background")}/>
      {renderBackButton()}
      <div className='more-section-wrapper'>
        {renderLogo()}
        <div className="right-wrapper" role="menu" style={fontStyle.secondaryFont}>
          {currentPage && currentPage.map((item, index) => {
            const itemMain = {...item};
            const key = `${item.title}-${index}`
            if(item.type === "menu") {
              return renderMenuItem(itemMain, key);
            } else if(item.type === "settings") {
              return renderSettingsItem(itemMain, key);
            } else if (item.type === "link") {
              return renderLinkItem(itemMain, key);
            } else if (item.type === "header") {
              return renderHeaderItem(itemMain, key);
            } else if (item.type === "info") {
              return renderInfoItem(itemMain, key);
            } else {
              return null;
            }
          })}
        </div>
      </div>
      {showLink && renderLinkInfo()}
    </div>
  )

}

const mapStateToProps = (state) => ({
  user: state.user.user,
  logged_in: state.user.logged_in,
  autoPlay: state.user.autoPlay,
  policyData: state.feeds.policyData,
  mazTVLogin: state.user.mazTVLogin,
  codeConfigured: state.parentalLock.codeConfigured
})

const mapDispatchToProps = {
  openModal: openModal,
  userActions: userActions,
  logoutUserActions: logoutUserActions,
  clearSavedItems: clearSavedItems,
  logoutMazTv,
  setAutoPlay
}

export default connect(mapStateToProps, mapDispatchToProps)(MoreSettings);