import { applyMiddleware, createStore } from "redux"
import {composeWithDevTools} from 'redux-devtools-extension/developmentOnly';

// import logger from 'redux-logger'
import thunk from "redux-thunk"
import reducer from "./reducers"

// const middleware = applyMiddleware(thunk, logger)
const middleware = applyMiddleware(thunk)

const composeEnhancers = composeWithDevTools({
  trace: true,
  traceLimit: 25,
})

const store = createStore(reducer, composeEnhancers(middleware))

export default store