import React from 'react';
import { connect } from 'react-redux'
import Swiper from 'react-id-swiper'
import { closeModal, modalProgress, openModal } from "../actions/modalActions"
import { httpsUrl } from "../constants/common"
import { localRememberSpotBatch } from "../actions/rememberSpotActions"
import { environment } from "../constants/initializer"
import * as analytics  from '../constants/analytics';
import isAvailable from '../helperfunctions/is_available'
import Metering from './Metering';
import { isSmartTv } from '../helperfunctions/common';
import backMobile from "../images/icons/back-mobile.png";

function NextImage(all_contents, index){
  var content;
  var nextIndex;
  for(var i=index+1; i<all_contents.length; i++){
    if((all_contents[i].type === "image") && (all_contents[i].parentMenu === undefined || (all_contents[i].parentMenu && !all_contents[i].parentMenu.locked))){
      content = all_contents[i]
      nextIndex = i
      break;
    }
  }
  return [content, nextIndex]
}

function ChangeRoute(identifier){
  var currentLocation = window.location.pathname.split('/')
  currentLocation = currentLocation.slice(0,-1)
  currentLocation.push(identifier)
  currentLocation = currentLocation.join("/")
  return currentLocation
}

function FindImage(identifier, all_contents){
  var imageIndex = 0;
  for(var i=0; i<all_contents.length; i++){
    if(identifier === all_contents[i].identifier){
      break;
    }
    if (all_contents[i].type === "image")
      imageIndex++;
  }
  return imageIndex;
}


class OverlayImage extends React.Component {
  constructor(props){
    super(props)
    let imageIndex = FindImage(props.section.identifier, props.all_contents)
    let section = props.section;
    if(props.modal.head === "search"){
      section = this.addParentFeedDataForSearch(section);
    }
    var nextImageData = NextImage(props.all_contents, imageIndex)

    this.state = {
      all_contents: props.all_contents,
      index: imageIndex,
      section: section,
      next_section: nextImageData[0],
      next_index: nextImageData[1],
      next_image: false,
      currentView: '',
      rememberSpotBatch: {},
    }
  }

  onLinkCLick(e){
    var rememberSpotBatch = this.state.rememberSpotBatch
    rememberSpotBatch[this.props.all_contents[this.state.index].identifier] = {
      p: 1,
      t: 1,
      saved: false,
      type: "image"
    }
    this.props.rememberSpot(this.props.app_id, rememberSpotBatch, this.props.logged_in, this.props.user.auth_token,
      this.props.user.user_id)
    var item = document.getElementsByClassName('image-full swiper-slide-active')[0].getAttribute("data-identifier")
    if(!isSmartTv()) document.getElementsByClassName("item-" + item)[0].classList.add("slideHover")
    document.body.classList.remove("noNav")
    window.history.replaceState("view", {}, this.props.modal.pathname)
    this.props.closeModal()
    if(e) e.preventDefault()
  }

  componentWillUnmount() {
    document.body.classList.remove("noNav");
  }

  setImageAnalytics(){
    const contentUrl = this.state.section.contentUrl
    if(this.props.modal.firstpath.includes("search") || this.props.modal.firstpath.includes("saved_items")){
      if(this.props.modal.head === "search"){
        if(this.state.section.locked){
          analytics.setEvent('Image', 'Image View (search - locked)', this.state.section.title, contentUrl)   
        }else{
          analytics.setEvent('Image', 'Image View (search - unlocked)', this.state.section.title, contentUrl)   
        }
      }
      else if(this.props.modal.head === "saved_items"){
        if(this.state.section.locked){
          analytics.setEvent('Image', 'Image View (saved - locked)', this.state.section.title, contentUrl)   
        }else{
          analytics.setEvent('Image', 'Image View (saved - unlocked)', this.state.section.title, contentUrl)   
        }
      }
    }
    else{
      analytics.setEventForContent(this.props.feeds, this.props.modal.firstpath, 'Image', 'Image View', this.state.section.title, contentUrl)
    }
  }


  componentDidMount(){
    document.body.classList.add("noNav")
    analytics.setPageviewContent(this.props.feeds, this.props.modal.firstpath)
    this.setImageAnalytics();
    window.history.replaceState("new video", {}, this.props.modal.firstpath)
  }

  addParentFeedDataForSearch(section) {
    const {locked, access, special, registerWall, identifier} = section.parentMenu;
    let newSection = {
      ...section,
      locked: locked,
      access: access,
      special: special,
      registerWall: registerWall,
      parent_id: identifier,
    }
    return newSection;
  }

  setNextSlide(){
    var item = document.getElementsByClassName('image-full swiper-slide-active')[0].getAttribute("data-identifier")
    var newIndex = FindImage(item, this.props.all_contents)
    var rememberSpotBatch = this.state.rememberSpotBatch
    rememberSpotBatch[this.props.all_contents[newIndex].identifier] = {
      p: 1,
      t: 1,
      saved: false,
      type: "image"
    }
    this.props.rememberSpot(this.props.app_id, rememberSpotBatch, this.props.logged_in, this.props.user.auth_token,
      this.props.user.user_id)
    this.props.modalProgress({head: this.props.modal.head, item: item, index: newIndex})
    window.history.replaceState("new slide", {}, ChangeRoute(item));
  }

  onFreeContentOver() {
    //
  }

  render(){
    const params = {
      initialSlide: FindImage(this.props.section.identifier, this.state.all_contents),
      nextButton: '.swiper-button-next',
      prevButton: '.swiper-button-prev',
      keyboardControl: true
    }

    const contents = []
    this.state.all_contents.map(function(content, i){
      if (content.type === "image") {
        contents.push(
          <div key={i} className='swiper-slide image-full'
               style={{backgroundImage: 'url(' + httpsUrl(content.contentUrl) + ')'}}
               data-identifier={content.identifier}></div>
        )
      }
    })

    let tv_style = {};
    if(isSmartTv()) {
      tv_style.display = "none";
    }
    const runningOnMobile = this.props.runningOnMobile;

    return (
      <div className="image-view" style={{background: "#000000"}}>
        <div id="loader" className="loader"><img src={"https://s3.amazonaws.com/resources.magappzine.com/assets/core/" + environment() + "/loader.gif"}/></div>
        <span className="icon-hollow_arrow">
          <a id="overlay_video_back_btn" href="" onClick={(event) => this.onLinkCLick(event)} className={(runningOnMobile ? "backBtn-mobile" : "backBtn")} style={{zIndex: 100, ...tv_style}}>
            {runningOnMobile && <img src={backMobile} alt="Back"/>}
          </a>
        </span>
        <Swiper 
          initialSlide={params.initialSlide} 
          onSlideChangeEnd={() => this.setNextSlide()} 
          nextButton={params.nextButton} 
          prevButton={params.prevButton} 
          keyboardControl={params.keyboardControl}
        >
          {contents}
        </Swiper>
        {((!isAvailable(this.state.section.parent_id,this.state.section.special,this.state.section.registerWall) && 
            this.state.section.locked)) && 
          <Metering
            content_id={this.state.section.identifier}
            title={this.state.section.title}
            parent_id={this.state.section.parent_id}
            contentAccess={this.state.section.access}
            feeds={this.props.feeds}
            onFreeContentOver={() => this.onFreeContentOver()}
            playing_Ad={false}
            showMetering={true}
            content={this.state.section}
            modalHead={this.props.modal.head}
            interstitialModalDetails={this.props.modal.interstitialProps}
            video_start={true}
          />
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  remember: state.remember.remember,
  logged_in: state.user.logged_in,
  user: state.user.user,
})

const mapDispatchToProps = {
  openModal: openModal,
  closeModal: closeModal,
  modalProgress: modalProgress,
  rememberSpot: localRememberSpotBatch,
}

export default connect(mapStateToProps, mapDispatchToProps)(OverlayImage);