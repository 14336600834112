import React from "react";
import { Redirect, Route, Switch } from 'react-router-dom'
import success from "../images/success.png";
import failure from "../images/failure.png";
import StripePage from "./Pay/StripePage";

export default function Pay(props) {
  return (
    <div
      style={{
        color: "black",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        boxSizing: "border-box",
      }}>
      <Switch>
        <Route exact path={`/pay/success`}><Success /></Route>
        <Route exact path={`/pay/failure`}><Failure /></Route>
        <Route exact path={`/pay/:sessionId`}><StripePage /></Route>
        <Redirect to={"/"} />
      </Switch>
    </div>
  );
}

const Success = () => {
  return (
    <>
      <Image src={success} alt="Success" />
      <h1>Payment successful</h1>
      <h2 style={{ marginTop: 0 }}>Your purchase will auto update in the app. If not, please try restarting the app.</h2>
    </>
  );
}

const Failure = () => {
  return (
    <>
      <Image src={failure} alt="Failure" />
      <h1>Payment failed</h1>
    </>
  );
}

const Image = ({ src, alt }) => {
  return (
    <img src={src} alt={alt} width={100} height="auto" />
  );
}
