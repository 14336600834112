import React from 'react';
import configuration from '../constants/config';
import { getTabIndex, isSmartTv } from '../helperfunctions/common';

import privacy_header from "../images/icons/privacy_header.png";
import privacy_header_mobile from "../images/icons/privacy_header_mobile.png";
import SwitchSlider from './SwitchSlider';
import { addRemoveAllNavigation } from '../helperfunctions/ctvHelper';
import JsSpatialNavigation from '../ctvnavigation/js-spatial-navigation';
import { getAllConstants } from '../helperfunctions/regionalization';

class DataAndPrivacy extends React.Component {
    constructor(props) {
        super(props);
        this.primaryColor = (props.feeds && props.feeds.primaryColor && props.feeds.primaryColor.replace("#FF","#")) || "#2196F3";
        // only two keys in state for now, if need to add more then also change disableContinue() logic
        this.state = this.getInitialState(props); 
    }

    componentDidMount() {
        if(this.props.runningOnMobile) {
            let els = document.getElementsByClassName("pri-links");
            if(els && els.length > 0) {
                for(let i =0; i < els.length ; i++) {
                    els[i].style.color = this.primaryColor 
                }
            }
        }
        if (isSmartTv()) {
            if (document.getElementById('nav-bar')) document.getElementById('nav-bar').style.display = 'none';
            if(configuration.use_light_sliders) {
                this.id = addRemoveAllNavigation({ selector: '.data-privacy .focusable' });
                JsSpatialNavigation.focus(this.id);
            }
        }
    }

    componentWillUnmount() {
        if (isSmartTv()) {
            if (document.getElementById('nav-bar')) document.getElementById('nav-bar').style.display = 'block';
            if(configuration.use_light_sliders) {
                addRemoveAllNavigation({ id: this.id });
            }
        }
    }

    getInitialState(props) {
        let state = {};
        const {privacy: {partner, third_party}} = props.feeds.gdpr;
        if(partner) {
            state["partner"] = false
        }
        if(third_party && Object.keys(third_party).length > 0) {
            state["third_party"] = false
        }
        return state;
    }

    disableContinue() {
        for(let key in this.state) {
            if(!this.state[key]) return true;
        }
        return false;
    }

    onButtonClick = () => {
        this.props.onContinue();
    }

    onCancelClick = () => {
        this.props.onCancel();
    }

    onSliderChange(key, val) {
        this.setState({
            [key]: val
        })
    }

    renderPrivacy(data, key) {
        const {label, text, url} = data;
        if(!text) return null;

        return (
            <>
                <div className="privacy-item" key={key}>
                    <SwitchSlider 
                        primaryColor={this.primaryColor}
                        onSliderChange={(val) => this.onSliderChange(key, val)}
                        ariaId={`data-privacy-text-${key}`}
                    />
                    {(this.props.runningOnMobile)
                        ? (
                            <span dangerouslySetInnerHTML={{ __html: text.replace("^", `<a class="pri-links" target="_blank" href=${url}>${label}</a>`) }}/>
                        )
                        : (<>
                            <p id={`data-privacy-text-${key}`}>{text.replace("^", label)}</p>
                        {   isSmartTv()
                            ?   <a onClick={() => this.props.handleTermsPolicyClick && this.props.handleTermsPolicyClick(label, url, true)} className="focusable" tabIndex={getTabIndex()} style={{backgroundColor: this.primaryColor}}>{label}</a>
                            :   <a target="_blank" href={url} style={{backgroundColor: this.primaryColor}}>{label}</a>
                        }
                        </>
                        )
                    }
                </div>
                <hr />
            </>
        )
    }

    render() {
        const {header_text, submit_text, privacy: {partner, third_party}} = this.props.feeds.gdpr;
        const { kBackButtonText } = getAllConstants();
        return (
            <div className="data-privacy">
                {isSmartTv() && <button id="gdpr_back_button" onClick={this.props.onCancel} style={{display: "none"}}>{kBackButtonText}</button>}
                <div className="privacy-header">
                    <img alt="Privacy" src={(this.props.runningOnMobile) ? privacy_header_mobile : privacy_header}/>
                    <span>{header_text}</span>
                </div>
                <hr/>
                {partner && this.renderPrivacy(partner, "partner")}
                {third_party && this.renderPrivacy(third_party, "third_party")}
                <div className="btn-wrapper">
                    <button className="contine-button focusable" tabIndex={getTabIndex()} style={{backgroundColor: this.primaryColor}} onClick={this.onButtonClick} disabled={this.disableContinue()}>{submit_text}</button>
                </div>
            </div>
        )
    }
}

export default DataAndPrivacy;