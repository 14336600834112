import React from 'react';
import { connect } from 'react-redux';
import configuration from '../constants/config';
import isAvailable from '../helperfunctions/is_available';
import LockIcon from './icons/Lock';


const ItemLockOverlay = ({ feeds, content = {}, isLoading }) => {

    const { primaryColor, secondaryColor } = feeds;
    let ownLockedContent = false
    if(!configuration.isAppTvodType) {
        let identifier = content.parent_id || content.identifier
        ownLockedContent = !content.locked || isAvailable(identifier,content.special,content.registerWall, content);
    } else {
        ownLockedContent =  isAvailable(null, null, null, content)
    }
    if (ownLockedContent || isLoading) return null;

    var primary = (primaryColor && primaryColor !== "" && primaryColor !== undefined) ? primaryColor.replace('#FF', '#') : "#000000"
    var secondary = (secondaryColor && secondaryColor !== "" && secondaryColor !== undefined) ? secondaryColor.replace('#FF', '#') : "#FFFFFF"
    return (
        <div className="img-grid-overlay-lock" >
            <LockIcon
                primary={primary}
                secondary={secondary}
            />
        </div>
    )
}

const mapStateToProps = (state) =>({
    isLoading : state.user.isLoading
})


export default connect(mapStateToProps)(ItemLockOverlay);