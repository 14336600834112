import axios from "axios";
import * as ACTION_TYPES from '../constants/actionTypes';
import * as API from "../constants/api"
import { getTvodApiHeaders } from "../constants/initializer";


export function getParentalLockStatus() {
  return function(dispatch) {
    dispatch({type: ACTION_TYPES.PARENTAL_LOCK_DATA_FTECHING});
    axios.get(API.TVOD_PARENTAL_LOCK_CODE, getTvodApiHeaders())
      .then((data => {
        const configured = data.data && data.data.configured;
        dispatch({type: ACTION_TYPES.PARENTAL_LOCK_DATA_FTECH_SUCCESS, payload: configured });
      }))
      .catch(error => {
        dispatch({type: ACTION_TYPES.PARENTAL_LOCK_DATA_FTECH_FAIL});
      })
  }
}

export function setParentalLock(code) {
  return function(dispatch) {
    dispatch({type: ACTION_TYPES.PARENTAL_LOCK_CODE_SETUP});
    axios.post(API.TVOD_PARENTAL_LOCK_CODE, {"access_code": code} ,getTvodApiHeaders())
      .then((data => {
        dispatch({type: ACTION_TYPES.PARENTAL_LOCK_CODE_SETUP_SUCCESS });
      }))
      .catch(error => {
        dispatch({type: ACTION_TYPES.PARENTAL_LOCK_CODE_SETUP_FAIL});
      })
  }
}

export function validateParentalLock(code) {
  return function(dispatch) {
    dispatch({type: ACTION_TYPES.PARENTAL_LOCK_CODE_VALIDATE});
    axios.post(API.TVOD_PARENTAL_LOCK_CODE_VALIDATE, {"access_code": code} ,getTvodApiHeaders())
      .then((data => {
        dispatch({type: ACTION_TYPES.PARENTAL_LOCK_CODE_VALIDATE_SUCCESS });
      }))
      .catch(error => {
        const er = (error && error.response && error.response.data && error.response.data.error) || "Please try again";
        dispatch({type: ACTION_TYPES.PARENTAL_LOCK_CODE_VALIDATE_FAIL, payload: er});
      })
  }
}

export function resetParentalLock() {
  return function(dispatch) {
    axios.post(API.TVOD_PARENTAL_LOCK_CODE_RESET, {} ,getTvodApiHeaders())
      .then((data => {
        dispatch({type: ACTION_TYPES.PARENTAL_LOCK_CODE_RESET });
      }))
      .catch(error => {
      })
  }
}

export function removeParentalLock() {
  return function(dispatch) {
    axios.post(API.TVOD_PARENTAL_LOCK_CODE, {"access_code": null} ,getTvodApiHeaders())
      .then((data => {
        dispatch({type: ACTION_TYPES.PARENTAL_LOCK_CODE_REMOVED });
      }))
      .catch(error => {
      })
  }
}

export function resetValidationData() {
  return function(dispatch) {
    dispatch({type: ACTION_TYPES.PARENTAL_LOCK_CODE_VALID_FAIL_RESET });
  }
}

export function removeParentalLockFromLocal() {
  return function(dispatch) {
    dispatch({type: ACTION_TYPES.PARENTAL_LOCK_CODE_REMOVED });
  }
}
