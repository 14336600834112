import React from 'react';
import { connect } from 'react-redux'
import DetailTitle from './DetailTitle';
import ThumbnailVideo from './ThumbnailVideo';
import { openModal } from '../actions/modalActions'
import { getTabIndex } from '../helperfunctions/common';
import { SHOW_INTERSTITIAL_AFTER_BACK_LOGIN_SAVE } from '../constants/common';
import Background from '../components/Background'
import { getFontStyleFromFeed, getParentFeed } from '../helperfunctions';
import InterstitialMobile from './InterstitialMobile';
import Image from './Image';

class InterstitialScreen extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    componentDidUpdate() {

    }

    componentWillUnmount() {

    }

    checkIfMazIdFeeds(feeds, content) {
        if (content.mazContentType && content.lineage && ["progress", "history"].includes(content.mazContentType)) {
            const url = `${content.lineage}/${content.identifier}`;
            let parent = getParentFeed(feeds, url)
            const {locked, access, special, displayTitle, registerWall, identifier, showVideoAd} = parent;
            return {
                ...content,
                locked: locked,
                access: access,
                special: special,
                displayTitle: displayTitle || null,
                registerWall: registerWall,
                parent_id: identifier,
                showAd: showVideoAd
            }
        } else if (this.props.modalProps && this.props.modalProps.head === "search" && content.parentMenu) {
            return {
                ...content,
                parent_id: content.parentMenu.identifier,
                registerWall: content.parentMenu.registerWall,
                special: content.parentMenu.special
            }
        }
        return content;
    }

    openModal = (e) => {
        e.preventDefault();
        const currProps = SHOW_INTERSTITIAL_AFTER_BACK_LOGIN_SAVE ? this.getCurrInterstitialProps() : null;
        this.props.openModal({...this.props.modalProps, interstitialProps: currProps});
    }

    getCurrInterstitialProps = () => {
        const currProps = {...this.props};
        delete currProps.remember;
        delete currProps.haveSavedMazIDFeed;
        delete currProps.openModal;
        delete currProps.runningOnMobile;
        delete currProps.feeds;
        return currProps;
    }

    render () {
        const feeds = this.props.feeds;
        const content = this.checkIfMazIdFeeds(feeds, this.props.modalProps.content);
        const primary = feeds.primaryColor || "#FFFFFF";
        const secondary = feeds.secondaryColor || "#FFFFFF";
        const fontStyle = getFontStyleFromFeed(feeds);
        const summary = {
            title: content.title,
            subtitle: content.subtitle,
            description: content.summary
        }
        const thumbOrientation = content.thumbOrientation;

        const divSecodaryBack = {backgroundColor: content.cover ? 'transparent' :secondary.replace('#FF', '#')}
        const divPrimaryBack = {backgroundColor: content.cover ? 'transparent' :primary.replace('#FF', '#')}
        const imgPrimaryBack = {backgroundColor: primary.replace('#FF', '#')}
        const imgSecodaryBack = {backgroundColor: secondary.replace('#FF', '#')}
        const thumbClass = thumbOrientation == "portrait" ? 'portrait-view' : '';
        if (this.props.runningOnMobile) {
            return <InterstitialMobile
                feeds={this.props.feeds}
                content = {content}
                haveSavedMazIDFeed={this.props.haveSavedMazIDFeed}
                parent_content={{...content, identifier: content.parent_id}} 
                summary={summary}
                interstitial={true}
                interstitialProps={SHOW_INTERSTITIAL_AFTER_BACK_LOGIN_SAVE ? this.getCurrInterstitialProps(): null}
                type={content.type}
                onPlay={this.openModal}
            />
        }
        return (
            <>
                <Background background={this.props.thumbnailProps.background}/>
                <div className="detail-view-cls" id="detail-view-content">
                    <div className={`detail-view ${thumbClass}`}>
                        <div className="swiper-container swiper-container-vertical">
                            <div className="swiper-wrapper">
                                <div className="swiper-slide detail-swiper">
                                    <a aria-labelledby='interstitial_title' href="" className="thumbLink thumbHover focusable" tabIndex={getTabIndex()} onClick={(event) => this.openModal(event)}
                                        aria-describedby={`content-head-${content.parent_id} summaryDescription-${content.identifier}`}
                                    >
                                        <div style={divSecodaryBack} className="img-video"
                                            data-title={content.title} data-subtitle={content.subtitle}
                                            data-summary={content.summary}
                                            // onMouseEnter={(hover, sourceClass) => this.onMouseEventHandler(true, "image-content-" + content.identifier, content)}
                                            // onMouseLeave={(hover, sourceClass) => this.onMouseEventHandler(false, "image-content-" + content.identifier,content)}
                                        >
                                            {/* {this.props.remember[content.identifier] && <span className="remSpot" style={style}></span>} */}
                                            {content.cover && <Image style={imgSecodaryBack} classNames={"img-thumb swiper-lazy image-content-" + content.identifier} cover={content.cover}/>}
                                        </div>
                                    </a>
                                    <div className="titleHolder">
                                    <p id="interstitial_title" className="title" style={{
                                        marginRight: '10px',
                                        display: "-webkit-box",
                                        WebkitBoxOrient: "vertical",
                                        ...fontStyle.primaryFont
                                        }}>{content.title}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="detailContent">
                                <DetailTitle 
                                    haveSavedMazIDFeed={this.props.haveSavedMazIDFeed} 
                                    secondary={secondary} 
                                    summary={summary} 
                                    type={content.type} 
                                    feeds={feeds} 
                                    content={content} 
                                    parent_content={{...content, identifier: content.parent_id}} 
                                    interstitial={true}
                                    interstitialProps={SHOW_INTERSTITIAL_AFTER_BACK_LOGIN_SAVE ? this.getCurrInterstitialProps(): null}
                                />
                            </div>
                        </div>
                    </div>
                    
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    remember: state.remember.remember,
    haveSavedMazIDFeed: state.items.haveSavedMazIDFeed
  })

const mapDispatchToProps = {
    openModal
}

export default connect(mapStateToProps, mapDispatchToProps)(InterstitialScreen);