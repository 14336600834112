import React from 'react';
import configuration from "../constants/config";

class FeedError extends React.Component {
    render() {
        const divClassName = configuration.is_simulator ? "simulator-feed-err" : "web-feed-err";
        return (
            <div className={`feed-error ${divClassName}`}>
                <h1>Please add content in your app, or activate any existing section.</h1>
            </div>
        )
    }
}

export default FeedError