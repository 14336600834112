import React from 'react';
import { isIOS } from 'mobile-device-detect';

import * as analytics  from '../constants/analytics';
import { getSpotXId, getTheoPlayerVariables, PLATFORM_LG, PLATFORM_SAMSUNG, PLATFORM_VIZIO, PLATFORM_XBOX, PLATFORM_ZEASN, PLAYER_EVENTS } from '../constants/common';
import { constructSpotxParams, getModifiedAdUrl, showAdsAsPerFrequency } from '../helperfunctions/adHelper';
import { checkCorrectPlatform, getItemCuePoints, getVttCaption, isSmartTv, onCTVVideoError, onVideoError, toHHMMSS, fullscreen, isAppRunningOnLowEndPlatform } from '../helperfunctions/common';
import { checkIfHideNavbarOnLive, keyMappings, loadJsScript, retainFocusOnBackPress } from '../helperfunctions/ctvHelper';
import PauseIcon from './icons/PauseIcon';
import PlayIcon from './icons/PlayIcon';
import configuration from '../constants/config';
import Rewind from './icons/Rewind';
import Forward from './icons/Forward';
import PlayerProgressBar from './icons/PlayerProgressBar';
import { checkIfSectionLevelContent } from '../helperfunctions';
import { modifiedPlaybackUrl } from '../helperfunctions/common';
import StopIcon from './icons/StopIcon';
import { connect } from 'react-redux';
import { getMMVideoAssetInfo, startSendingMMEventsTheo } from '../helperfunctions/mediaMelon';

class TheoPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPause: false,
      showStop: false,
      currTime: toHHMMSS(0),
      muted: false,
      volume: this.getVolumeInPercentage(),
      isPaused: false,
      metaDataLoadedAt: null,
      showPlay: false,
      showRewind: false,
      showForward: false,
    }
    this._el = React.createRef();
    this._player = null;
    this.handleRemoteClick = this.handleRemoteClick.bind(this);
    this.afterPlayerLoaded = this.afterPlayerLoaded.bind(this);
    this.lastAdbreakCheckAt = 0;
    this.customVideoEndTimer = null;
    this.THEO_VARIABLES = getTheoPlayerVariables();
  }

  componentDidMount() {
    if(!window.THEOplayer) {
      loadJsScript({
        url: this.THEO_VARIABLES.cdn,
        location: document.body,
        type_to_set: 'async',
        onLoadMethod: () => {
          this.afterPlayerLoaded();
        }
      });
    } else {
      this.afterPlayerLoaded();
    }
  }

  afterPlayerLoaded() {
    this.initPlayer();

    if(!this.props.showPlayButton) {
      this.startPlaying();
    }
    if(isSmartTv()) {
      document.addEventListener('keyup', this.handleRemoteClick);
    }

    // for web only
    // not supported on ios
    if (!isSmartTv() && !isIOS) {
      // replace default fullscreen toggle button
      // this button makes viewport body fullscreen
      const button = fullscreen().button;
      let controlBar = document.getElementsByClassName('vjs-control-bar');
      controlBar[controlBar.length - 1].appendChild(button);
    }    
  }

  componentDidUpdate(prevProps) {
    if( prevProps.showPlayButton != this.props.showPlayButton && !this.props.showPlayButton) {
      this.startPlaying();
    }

    this.handleVideoPlayAfterNetworkDisconnect(prevProps)

    if(prevProps.section && 
      prevProps.section.identifier !== this.props.section.identifier && 
      this.props.section.contentUrl && !this.props.showPlayButton
    ) {
      // play next video
      this.startPlaying();
      this.clearCustomVideoEndTimer();
      this.prerollPassed = false;
    }
  }

  componentWillUnmount() {
    this.clearCustomVideoEndTimer();
    if(!this._player) return;
    this._player.destroy();
    if(isSmartTv()) {
      document.removeEventListener('keyup', this.handleRemoteClick);
    }
  }

  handleVideoPlayAfterNetworkDisconnect(prevProps){
    if(!this._player || this.props.type !== "video/mp4") return
    const isSamsungTv = configuration.platform == PLATFORM_SAMSUNG

    if(!this.props.isUserOnline && prevProps.isUserOnline && isSamsungTv) {
      !this._player.paused && this._player.pause()
      return
    }
    
    else if(this.props.isUserOnline && !prevProps.isUserOnline && isSamsungTv) {
      this._player.paused && this._player.play()
      return
    }

    if(this.props.isUserOnline && !prevProps.isUserOnline) {
      // This is to Update the Video Source after Network Reconnect for MP4 Videos: https://app.asana.com/0/1151170181640649/1203092366731045/f
      this.startPlaying();
      if(this.timeOnError){
        this._player.currentTime = this.timeOnError
      }
    }
  }

  initPlayer() {
    if(!this._el.current) return;
    this._player = new window.THEOplayer.Player(this._el.current, {
      libraryLocation: this.THEO_VARIABLES.library,
      license: this.THEO_VARIABLES.license,
      ui: {
        controlBar: {
          // enable default fullscreen toggle button if on ios
          fullscreenToggle: isIOS
        }
      },
      mutedAutoplay: configuration.isAppTvodType ? 'all' : 'none',
      allowNativeFullscreen: isIOS,
    });
    if(this.props.oninit) this.props.oninit(this._player);
  }

  startPlaying() {
    if(!this._player) return;
    const { section, type, feeds, videoType } = this.props;
    let url = modifiedPlaybackUrl(this.props, section.contentUrl, section, this._player);
    this._player.source = {
      sources: [{
        "src":  url,
        "type": type
      }],
      textTracks : [this.getSubtitles()],
      ads: [this.getAdsDetails()],
    };
    this._player.autoplay = true;
    startSendingMMEventsTheo(this._player, videoType === "live", section)

    this.addEvents();
  }

  addEvents() {
    if(!this._player) return;
    this._player.addEventListener(PLAYER_EVENTS, (event) => {
      if(this.playFailureFallbackTimer) clearTimeout(this.playFailureFallbackTimer)
      switch(event.type) {
        case "timeupdate":
          if(!this._player.ads.playing) {
            this.props.theoPlayerUpdateTimeHandler(this._player.currentTime, this._player.duration);
            this.handleAdbreaks();
            this.handleCustomTimeUpdate();
            this.handleCustomVideoEnd();
          }
          break;
        case "loadeddata":
          if(document.getElementById("loader")) document.getElementById("loader").style.display = "none";
          const {p, t} = this.props.rememberSpot || {};
          if(this.props.isEPG || this.vizioSourceUpdateTimeout) {
            // Do nothing. AC - 503
          } else if(this.props.streamProgress && this.props.streamProgress !== 100 && this._player.duration) {
            this._player.currentTime = Math.floor((this.props.streamProgress * this._player.duration) / 100);
          } else if (!this._player.ads.playing && p && p !== t) {
            this._player.currentTime = Math.floor(p);
          }
          this.handleCustomMetaDataLoad();
          break;
        case "ended":
          if(this.props.setNextVideo) this.props.setNextVideo();
          break;
        case "pause":
          setTimeout(() => {
            if(!this._player.ads.playing) this.props.onTheoPause() 
          }, 1000)
          this.handleCustomPause();
          if(isSmartTv() && (this.props.videoType == 'live' || this.props.videoType == 'fake')) {
            this.props.hideNavbar(true);
          }
          break;
        case "play":
          this.props.onTheoPlay();
          this.handleCustomPlay();
          if(isSmartTv() && (this.props.videoType == 'live' || this.props.videoType == 'fake')) {
            this.props.hideNavbar();
          }
          break;
        case "error":
          if(event.errorObject && event.errorObject.message && event.errorObject.message.includes("Something went wrong during native playback.")){
            this.timeOnError = this._player.currentTime
          }
          if (isSmartTv()) {
            onCTVVideoError(event, this._player, (this.props.videoType === "live" ? "live" : ""))
          } else {
            onVideoError(this.props.type, this._player);
          }
          break;
        case "volumechange":
          this.handleCustomVolumeChange();
          break;
        case "loadstart":
          if (configuration.isAppTvodType) {
            this.playFailureFallbackTimer = setTimeout(() => {
              if(!this.props.hasUserEngaged){
                document.getElementById("overlay_video_back_btn") && document.getElementById("overlay_video_back_btn").click();
              } else if(!isSmartTv()) {
                this.props.setShowPlayButton()
              }
            }, 3000)
          }
          break;
        default:
          break;
      }
    })
    this._player.ads.addEventListener(['aderror', "adbegin" ,"adend"], (event) => {
      if(event.type === "adbegin") {
				analytics.setEvent('Ads', 'Video Ad Play', 'AdTag');
      }
      if(this.props.onAdUpdateState) this.props.onAdUpdateState(event.type);
    });
    this._player.textTracks.addEventListener('addtrack', addTrackEvent => {
      const haveCustomCC = this.getSubtitles();
      if(haveCustomCC && haveCustomCC.src) {
        return; // showing subtitle from vtt file
      }
      const track = addTrackEvent.track;
      if(!track) return;
      if(checkCorrectPlatform(PLATFORM_LG)){
        if(track.language != "" && track.label != "" && track.src != this.vttCaption) {
          track.mode = this.props.showSubtitle ? "showing" : "disabled";
        }
      } else {
        setTimeout(() => {
          if(track.src != this.vttCaption){
            track.mode = this.props.showSubtitle ? "showing" : "disabled";
          }
        }, 1000);
      }
    });
  }

  handleCustomVideoEnd() {
    if(!this._player || !this._player.currentTime || !this._player.duration) return;
    if(this.props.videoType === "live" && !this.props.handleKeepInterstitialOpen) return;
    
    if(this._player.duration - this._player.currentTime < 1) {
      this.clearCustomVideoEndTimer();
      const currentId = this.props.section.identifier;
      this.customVideoEndTimer = setTimeout(() => {
        try {
          if(!this.props.section || currentId !== this.props.section.identifier) return; //Video Ended
          if(!this._player || !this._player.currentTime || !this._player.duration) return;

          if(this._player.duration - this._player.currentTime < 1) { // To avoid video end after video seek
            this._player.currentTime = this._player.duration;
          }
        } catch(e) {
          console.log("Not able to end the video")
        }
      }, 2000)
    }
  }

  clearCustomVideoEndTimer() {
    if(!this.customVideoEndTimer) return;
    clearTimeout(this.customVideoEndTimer);
    this.customVideoEndTimer = null;
  }

  getSubtitles() {
    this.vttCaption = getVttCaption(this.props.section);
    if(!this.vttCaption) return {};
    return {
      default: this.props.showSubtitle,
      kind : 'subtitles', //optional - find other values at https://support.theoplayer.com/hc/en-us/articles/214350425#TextTrackDescription
      label: 'English subs', //optional - this will appear in your UI
      src : this.vttCaption,
      srclang : 'en',
    }
  }

  getAdsDetails() {
    const { ads, isAdForFeed, videoCount, videoType, feeds, section } = this.props || {};
    if(!(isAdForFeed && (showAdsAsPerFrequency(ads, videoCount) || (videoType === "live" && ads.requestAdEverytimeForLive)))) return {}

    let adObj = {};
    if(ads.videoAdtype === "spotX" && !isSmartTv()) {
      adObj = {
        "id": getSpotXId(this.props.ads),
        "integration": "spotx",
        "custom": constructSpotxParams(feeds, section)
      }
    } else {
      adObj = {
        "sources": getModifiedAdUrl(this.props, section, this._player),
        // "sources": ads.adURL,
        "integration": "google-ima"
      }
    }
    return adObj;
  }

  handleAdbreaks() {
    const { nonVmapAdBreakFrequency, nonVmapCuePointKey, videoAdtype, videoType } = this.props.ads || {};
    if(videoAdtype === "spotX" || this.props.videoType === "live" || (!nonVmapAdBreakFrequency && !nonVmapCuePointKey)) return;
    const currentTime = Math.floor(this._player.currentTime);
    if(currentTime === 0 || this.lastAdbreakCheckAt === currentTime) return;

    let timeMatched = false;
    const section = this.props.section;
    if(nonVmapAdBreakFrequency && (currentTime % (nonVmapAdBreakFrequency * 60) === 0)) {
      timeMatched = true;
    } else if(nonVmapCuePointKey && section && section.cuePoints) {
      const cuePoints = getItemCuePoints(section.cuePoints);
      if(cuePoints.indexOf(currentTime) > -1) {
        timeMatched = true;
      }
    }
    if(timeMatched) {
      this.lastAdbreakCheckAt = currentTime
      this._player.ads.schedule(this.getAdsDetails());
    }
  }

  handleCustomTimeUpdate() {
    let currTime = this._player.currentTime.toFixed();
    this.setState({currTime: toHHMMSS(currTime)});
    let percentage = (this._player.currentTime / this._player.duration ) * 100;
    if (document.querySelector("#custom-seekbar span.seek")) {
      document.querySelector("#custom-seekbar span.seek").style.width = percentage+"%";
    }
  }

  handleCustomVolumeChange() {
    this.setState({muted: this._player.muted});
    this.setState({volume: this.getVolumeInPercentage()})
  }

  getVolumeInPercentage() {
    if(!this._player) return 100;
    return Math.floor(this._player.volume * 10)*10;
  }

  handleCustomPause() {
    this.setState({isPaused: true});
    this.toggleIconsState("showPause", true);
  }

  handleCustomPlay() {
    this.setState({isPaused: false});
    this.toggleIconsState("showPlay", true);
  }

  handleCustomMetaDataLoad() {
    this.setState({metaDataLoadedAt: new Date().toString()})
  }

  isShowingControls() {
    if(this.props.isEPG) return false;
    if(this.props.videoType === "video" || (this.props.videoType === "live" && configuration.show_live_controls)) return true;
    return false;
  }

  isShowingCustomControls() {
    const enableControl = this.isShowingControls();
    if(enableControl && isSmartTv() && !checkCorrectPlatform([PLATFORM_XBOX])) {
      return true;
    }
    return false;
  }

  toggleIconsState(key, value) {
    this.setState({[key]: value});
    setTimeout(() => {
      this.setState({[key]: !value});
    }, 500)
  }

  togglePlay() {
    if(!this.state.metaDataLoadedAt) return;
    if(!this._player || !this._player.play || !this._player.pause) return;

    if (this._player.paused) {
      this._player.play();
    } else {
      this._player.pause();
    }
  }

  isSamsungAndUserOffline(){
    if(!this.props.isUserOnline && configuration.platform === PLATFORM_SAMSUNG && this.props.type === "video/mp4"){
      return true
    }
    return false
  }

  handleRemoteClick(e) {
    e.preventDefault();
    if(!this._player) return;
    if(this._player.ads && this._player.ads.playing) return;
    if(this.props.isPlayerSettingsModalOpen) return;
    if(parseInt(e.keyCode) != 10009 && document.getElementById("video-inlineMenu") && !document.getElementById('video-inlineMenu').classList.contains('closedMenu')) {
      return;
    }
    if((this.props.videoType == 'live' || this.props.videoType == 'fake') && !this._player.paused && checkIfHideNavbarOnLive(e.keyCode)) {
      this.props.hideNavbar();
    }
    if(!this.isShowingControls()) return;
    const keyCode = keyMappings(e.keyCode);
    const focus_on_nav = document.getElementById("nav-bar") ? document.getElementById("nav-bar").contains(document.activeElement) : false;
    const isFocusOnControls = document.querySelector('#custom-control-bar') && document.querySelector('#custom-control-bar').contains(document.activeElement);
    if(focus_on_nav) return;

    if(this.isSamsungAndUserOffline()){
      switch (keyCode) {
        case "OK":
        case 'PLAY-PAUSE':
        case 'PLAY':
          return
      }
    }

    switch(keyCode) {
      case 'OK': //media playpause
        if(isFocusOnControls) break;
      case 'PLAY-PAUSE': //media playpause
        this.togglePlay();
        break;
      case 'LEFT':
        if(isFocusOnControls) break;
        if(checkCorrectPlatform([PLATFORM_VIZIO])) this.toggleIconsState("showRewind", true);
      case 'REWIND':
        this._player.currentTime -= 10; //Subtracts 10 seconds
        break;
      case 'RIGHT':
        if(isFocusOnControls) break;
        if(checkCorrectPlatform([PLATFORM_VIZIO])) this.toggleIconsState("showForward", true)
      case 'FORWARD':
        this._player.currentTime += 10; //Adds 10 seconds
        break;
      case 'PLAY': //media play
        this._player.play();
        break;
      case 'PAUSE': //media pause
        this._player.pause();
        break;
      case 'STOP': // stop video
        this.toggleIconsState("showStop", true);
        if(checkCorrectPlatform([PLATFORM_ZEASN, PLATFORM_LG, PLATFORM_SAMSUNG]) && document.getElementById("overlay_video_back_btn")) {
          setTimeout(() =>{
            document.getElementById("overlay_video_back_btn").click();
            retainFocusOnBackPress(window.last_focus, null, window.location.pathname);  
          }, 500)    
        } else {
          this._player.currentTime = 0;
          this._player.pause();
        }
        break;
      default:
        break;
    }
  }

  renderCustomControls() {
    if(!this.isShowingCustomControls()) return null;
    if(this.props.showPlayButton || !this._player || (this._player.ads && this._player.ads.playing)) return null;

    const { isPaused, volume, muted, currTime, metaDataLoadedAt} = this.state;
    return (
      <>
        <div className="theo custom-controls">
          {this.state.showPlay && <PlayIcon />}
          {this.state.showPause && <PauseIcon />}
          {this.state.showStop && <StopIcon />}
          {this.state.showForward && <Forward />}
          {this.state.showRewind && <Rewind />}
        </div>
        <PlayerProgressBar
          theoPlayerRef = {this._player}
          theoPlayerIsPaused = {isPaused}
          theoPlayerVolume = {volume}
          theoPlayerMuted = {muted}
          theoPlayerCurrTime = {currTime}
          ctv_focus_on={this.props.ctv_focus_on}
          theoMetaDataLoadedAt = {metaDataLoadedAt}
          isLive = {this.props.videoType === "live"}
          isSamsungAndUserOffline={this.isSamsungAndUserOffline()}
        />
      </>
    )
  }

  render() {
    const showNativeControl = this.isShowingControls() && !this.isShowingCustomControls();
    return (
      <div id="theo-player" className={ showNativeControl ? "showingControls" : ""}>
        <div ref={this._el} className="theoplayer-container video-js theoplayer-skin THEOplayer"></div>
        {this.renderCustomControls()}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  hasUserEngaged: state.user.userEngaged,
  isPlayerSettingsModalOpen: state.user.playerSettingsModalOpen,
})

export default connect(mapStateToProps, null)(TheoPlayer);