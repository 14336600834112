import React, {Component} from "react";
import videojs from 'video.js';
import { connect } from 'react-redux';
import axios from "axios";
import { isIOS } from 'mobile-device-detect';

import { addTvodAPIRegionalisation, getCurrentPlatformTvod, getImageUrlBaseOnSize, isSmartTv, videoType } from '../helperfunctions/common';
import Image from "./Image";
import configuration from "../constants/config";
import * as APIS from "../constants/api";
import { getTvodApiHeaders, getTvodAPIKey } from "../constants/initializer";
import { logoutUserActions } from '../actions/userActions';
import { isPreviewBlocked, isUsingTheoPlayer } from "../helperfunctions";
import { videojsQualityHelper } from "../helperfunctions/videojsHelper";
import { loadJsScript } from "../helperfunctions/ctvHelper";
import { THEO_VARIABLES } from "../constants/common";


class PreviewVideo extends Component {
  constructor(props) {
    super(props);
    this.ele_id = props.isTvodInt ? "spotlight-preview-tvod-interstitial" : "spotlight-preview";
    this.previewBlocked = isPreviewBlocked(props.feeds);
    this.isUsingTheo = isUsingTheoPlayer(props.feeds)
    this.timer = null;
    this._el = React.createRef();
    this._player = null;
  }

  componentDidMount() {
    if (!this.props.video_content) return;
    if(this.previewBlocked) return;

    if(this.isUsingTheo) {
      this.loadTheoPlayer();
    } else {
      this.setAxiosCancelRequest();
      this.handlePreviewPlay();
    }
  }

  loadTheoPlayer() {
    if(!window.THEOplayer) {
      loadJsScript({
        url: THEO_VARIABLES.cdn,
        location: document.body,
        type_to_set: 'async',
        onLoadMethod: () => {
          this.afterPlayerLoaded();
        }
      });
    } else {
      this.afterPlayerLoaded();
    }
  }

  afterPlayerLoaded() {
    if(!this._el.current) return;
    this._player = new window.THEOplayer.Player(this._el.current, {
      libraryLocation: THEO_VARIABLES.library,
      license: THEO_VARIABLES.license,
      ui: {
        controlBar: {
          enable: false
        }
      },
      mutedAutoplay: 'all'
    });
    this.setAxiosCancelRequest();
    this.handlePreviewPlay();
  }

  handlePreviewPlay() {
    if(configuration.isAppTvodType && !this.props.isBackground) {
      this.getSignedUrl();
    } else {
      this.playPreview();
    }
  }

  getSignedUrl() {
    let video_content = {...this.props.video_content};
    const data = {"cid": video_content.cid, "progress": 0, platform: getCurrentPlatformTvod(), first_play: true};
    let url = APIS.TVOD_STREAMS_API;
    if(!this.props.logged_in) {
      data.key = getTvodAPIKey();
      data.app_id = configuration.app_id;
      url = APIS.TVOD_STREAMS_ANONYMOUS_API;
    }
    this.timer = setTimeout(() => {
      axios.post(url, addTvodAPIRegionalisation(data, null, this.props.policyData), getTvodApiHeaders(), { cancelToken: this.axios_source.token })
        .then(result => {
          let data = result?.data
          if(data?.error) return
          if(data?.url) video_content.contentUrl = data.url;
          this.playPreview(video_content);
        }).catch(err => {
          const code = err.response && err.response.status;
          if (code == 401) {
            this.props.logoutUserActions(configuration.app_id)
          }
        })
    }, 0);
  }

  setAxiosCancelRequest() {
    if(this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }

    this.axios_source && this.axios_source.cancel("Request cancelled by the user!");
    const CancelToken = axios.CancelToken;
    this.axios_source = CancelToken.source();
  }

  playPreview(video_content) {
    let video = video_content || this.props.video_content;
    if(!video.contentUrl) return;

    if(this.props.isTvodInt) video.muted = false
    if(isIOS) video.muted = true

    if(this.isUsingTheo) {
      this.playWithTheo(video);
    } else {
      this.playWithVideoJs(video);
    }
  }

  playWithVideoJs(video){
    const videoParams = {
      preload: 'auto',
      muted: video.muted,
      html5: {
        vhs: {
          overrideNative: !isSmartTv(),
        },
        nativeTextTracks: false
      },
    }
    this.player = videojs(this.ele_id,{errorDisplay: false, loadingSpinner: false, ...videoParams});
    videojsQualityHelper(this.player);
    this.player.src(video.contentUrl);
    this.player && this.player.muted(video.muted)
    /**
     * Playing video when it is ready, If preview not play then mute the video and try again.
     */
    videojs(this.ele_id) && this.player.ready(() => {
      if (!video.locked) {
        const promise = this.player.play();
        if(isIOS && this.player.playsinline) {
          this.player.playsinline(true);
        }
        promise && promise
        .catch((err) => {
          const requestError = err.message.includes("request was interrupted") ||  err.message.includes("The operation was aborted")
          try {
            if (this.player && this.player.muted && this.player.play && videojs(this.ele_id)) {
              requestError ? this.player.muted(video.muted) : this.player.muted(true);
              this.player.play()
              if(isIOS && this.player.playsinline) {
                this.player.playsinline(true);
              }
            }
          }
          catch(err) {
          }
        })
      }
    });
  }

  componentWillUnmount() {
    try {
      this.setAxiosCancelRequest()
      if (this.player) {
        this.player.dispose()
      }
      if(this._player) {
        this._player.destroy();
      }
    }
    catch(err) {
    }
  }

  playWithTheo(video) {
    if(!this._player) return;
    this._player.source = {
      sources: [{
        "src":  video.contentUrl,
        "type": videoType(video.contentUrl),
        "lowLatency": false
      }]
    };

    this._player.autoplay = true;
    this._player.muted = video.muted;
    this._player.addEventListener(['ended', 'canplay'], (event) => {
      var imageEle = document.getElementById("theo-player-preview-image")
      
      if(event.type === "ended") {
        this.handleOnEnded();
        if(imageEle) imageEle.style.visibility = "visible";
      }
      if(event.type === 'canplay'){
          if(imageEle) imageEle.style.visibility = "hidden";
      } 
    })
  }

  handleOnEnded() {
    if(this.props.onEnded) {
     this.props.onEnded();
    }
  }

  renderVideoElement() {
    if(this.isUsingTheo) {
      return <div id={this.ele_id} ref={this._el} className="theoplayer-container video-js theoplayer-skin THEOplayer">
         {this.props.video_content && this.props.video_content.cover_url && <Image classNames="object-cover h-full w-full" cover={{url: this.props.video_content.cover_url}} customSize={600} id={"theo-player-preview-image"}/>}
      </div>
    } else {
      const {cover_url, contentUrl, muted} = this.props.video_content
      return (
        <video
          id={this.ele_id}
          className="object-cover h-full w-full"
          poster = {cover_url && getImageUrlBaseOnSize(cover_url, 900)}
          onEnded={() => this.handleOnEnded()}
        >
          <source src={contentUrl}/>
        </video>
      )
    }
  }

  render(){
    if (!this.props.video_content){
      return null
    };

    const {cover_url, contentUrl, muted} = this.props.video_content
    return (
      <div id = "previewVideo" className={`fade-in ${this.props.videoFit || ''}`}>
        {
          this.previewBlocked ?
          <Image classNames="object-cover h-full w-full" cover={{url: cover_url}} customSize={600} id={this.ele_id}/> :
          this.renderVideoElement()
        }
        <div
          id="previewShadow"
          style={this.props.gradientStyle || {}}
        >
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  logged_in: state.user.logged_in,
  policyData: state.feeds.policyData,
  feeds: state.feeds.feeds,
})
  
const mapDispatchToProps = {
  logoutUserActions: logoutUserActions
}
  

export default connect(mapStateToProps, mapDispatchToProps)(PreviewVideo); 