import { useEffect } from "react";
import ReactFocusLock from "react-focus-lock";
import "./captionStyling.css";
import { useDispatch } from "react-redux";
import {
  PLAYERS_SETTING_MODAL_CLOSE,
  PLAYERS_SETTING_MODAL_OPEN
} from "../../constants/actionTypes";
import { getTabIndex } from "../../helperfunctions/common";
import backButton from "../../images/icons/back-mobile.png";
import PlayerSettingsMenu from "./PlayerSettingsMenu";

const PlayerSettingsModal = ({ onCancel, player }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: PLAYERS_SETTING_MODAL_OPEN });
    return () => {
      setTimeout(() => {
        dispatch({ type: PLAYERS_SETTING_MODAL_CLOSE });
      }, 500);
    };
  }, []);

  return (
    <ReactFocusLock returnFocus={true}>
      <div className="player-setting-modal">
        <div className="player-setting-modal-content" id="modal-content">
          <div
            className="back-btn-div backBtnSpan focusable"
            onClick={onCancel}
            tabIndex={getTabIndex()}
          >
            <img src={backButton} alt="Back" />
          </div>
          <PlayerSettingsMenu player={player} closeModal={onCancel} />
        </div>
      </div>
    </ReactFocusLock>
  );
};

export default PlayerSettingsModal;