import React, { useState } from 'react';

import { getCustomUIColors, getFontStyleFromFeed } from '../helperfunctions';
import GridContent from './GridContent';
import StandardTitle from './StandardTitle';
import ThumbnailVideo from './ThumbnailVideo';
import Image from './Image';
import ContentBadges from './ContentBadges';
import { getTabIndex } from '../helperfunctions/common';
import { TRAILER_CATALOG } from '../constants/common';

export default function TvodInterstitalExtras(props) {
  const {feeds, primary, secondary, handleExtrasClick, section, runningOnMobile } = props;
  const [extraIndex, setExtraIndex] = useState(0);

  const ALL_DATA = section ? section.extras : [];
  if(!ALL_DATA || ALL_DATA.length < 1) return null;

  
  const renderOTT = () => {
    const contents = ALL_DATA[extraIndex].contents ;
    if(!contents) return null;

    const thumnails = contents.map((video, i) => {
      const prop = {
        content: video,
        feeds: feeds,
        primary: primary,
        secondary: secondary
      }
      const lockStateSection = video.catalog === TRAILER_CATALOG ? video : section;
      return (
        <div key={video.identifier} className={"detail-swiper item-" + video.identifier}>
          <ThumbnailVideo {...prop} extraClickHandler={() => handleExtrasClick(video, i)} tvodSection={lockStateSection}/>
          <StandardTitle no_color={true} {...prop} isTvodExtras={true}/>
        </div>
      )
    })
    return <GridContent key={extraIndex} nosaveicon={true} content={section} contents={thumnails} feeds={feeds} isTvodExtras={true}/>;
  }

  const renderMobile = () => {
    const contents = ALL_DATA[extraIndex].contents;
    if(!contents) return null;

    return (
      <div>
        {contents.map(video => {
          const cover = video.cover;
          return (
            <div key={video.identifier}  className="extra" onClick={() => handleExtrasClick(video)}>
              <div className="">
                <div className="img-video">
                  <Image 
                    classNames="img-thumb"
                    cover={cover}
                  />
                  <ContentBadges content={video}/>
                </div>
              </div>
              <div className="extra-des">
                <p className="title">{video.title}</p>
                {video.subTitle && <p className="sub-title">{video.subTitle}</p>}
                {video.summary && <p className="summary">{video.summary}</p>}
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  const fontStyle = getFontStyleFromFeed(feeds);
  const { priColor } = getCustomUIColors(feeds);

  return (
    <div className='tvod-interstital-extras'>
      <div className='extra-title'>
        {ALL_DATA.map((extra, index) => {
          const borderStyle = index === extraIndex ? {borderBottom: `1px solid ${priColor}`} : {};
          return (
            <div
              key={extra.identifier}
              onClick={() => setExtraIndex(index)}
              className='focusable'
              tabIndex={getTabIndex()}
              aria-label={extra.title ? extra.title.toLowerCase() : ""}
              style={{margin: 1}}
            >
              <span style={{...borderStyle, ...fontStyle.primaryFont}}>
                {extra.title || ""}
              </span>
            </div>
          )
        })}
      </div>
      {runningOnMobile
        ? renderMobile()
        : renderOTT()
      }
    </div>
  )
}