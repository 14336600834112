import configuration from './config';
import { getUserCookie } from '../helperfunctions/storage';


export function getEnvironmentUrl() {
    var env = environment()
    if (env === "production"){
        return "https://dashboard.mazsystems.com"
    } else if (env === "staging"){
        return "https://staging1dash.mazsystems.com"
    } else if (env === "staging2"){
        return "https://staging2.mazsystems.com"
    } else if (env === "staging3"){
        return "http://maz.maz-staging3.apps.speedyrails.net"
    } else if (env === "development") {
        return "http://localhost:3000"
    }
}

export function getMazCoreEnvUrl(){
    var env = environment()
    if (env === "production"){
        return "https://api.mazdigital.com"
    } else if (env === "staging"){
        return "https://corestaging1.mazdigital.com"
    } else if (env === "staging2"){
        return "https://staging2core.mazsystems.com"
    } else if (env === "staging3"){
        return "http://maz.maz-staging3.apps.speedyrails.net"
    } else if (env === "development") {
        return "http://localhost:5000"
    }
}

export function getTvodEndpoints(){
    var env = environment()
    if (env === "production"){
        return "https://api.maz.tv"
    } else if (env === "staging"){
        return "https://api.staging.maz.tv"
    } else if (env === "development") {
        return "http://localhost:3003"
    }
    return "http://localhost:3003"
}

export function getTvodPreviewFeedEndpoints(){
    var env = environment()
    if (env === "production"){
        return "https://catalog.maz.tv"
    } else if (env === "staging"){
        return "https://catalog.staging.maz.tv"
    }
}

export function getTvodFeedEndpoints(encrypted){
    var env = environment();
    if(encrypted) {
        if (env === "production"){
            return "https://resources.maz.tv"
        } else if (env === "staging"){
            return "https://resources.staging.maz.tv"
        } else {
            return "http://assets.development.maz.tv"
        }
    } else {
        if (env === "production"){
            return "https://cloud.maz.tv"
        } else if (env === "staging"){
            return "https://cloud.staging.maz.tv"
        } else {
            return "http://assets.development.maz.tv"
        }
    }
}

export function getTvodApiHeaders(noAuth){
    if(configuration.isAppTvodType) {
        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
        if(!noAuth) {
            let user_cookie = getUserCookie();
            if(user_cookie && user_cookie.token) {
                headers.Authorization = user_cookie.token
            }
        }
        return {headers};
    } else {
        return {}
    }
}

export function getTvodAPIKey() {
    if(configuration.kTVODApiKey) {
        return configuration.kTVODApiKey;
    } else if(configuration.api_key) {
        return configuration.api_key;
    }
    return "";
}

export function getApiToken(type){
    var env = environment()
    if (type === "search"){
      if (env === "production"){
        return "2e18e904-d9cd-4911-b30c-1817b1e0b04b"
      } else {
        return "4d43463a-fb2f-44e8-b14e-812875bfeefb"
      }
    }
}

export function getMazAnalyticsApi() {
    var env = environment()
    if (env === "production"){
        return "https://1sr7xk7iu2.execute-api.us-east-1.amazonaws.com/v1/analytics-data";
    } else {
        return "https://1sr7xk7iu2.execute-api.us-east-1.amazonaws.com/v1/staging/analytics-data";
    }
}

export function getMazAnalyticsApiToken() {
    return "4zkREYDvgx504YrB5O8Sm4p3IwYSGg3v9FQ1fb43";
}

export function environment(){
  return configuration.env
  if (process.env && process.env.REACT_APP_ENV){
    return process.env.REACT_APP_ENV
  }
  else if (document.getElementById("root") && document.getElementById("root").getAttribute("data-env")) {
    return document.getElementById("root").getAttribute("data-env")
  }
    return "production"
}

export const siteUrl = (app_id) =>
    getEnvironmentUrl() + "/apps/" + app_id


export function getOneFeedAPIToken() {
    let env = environment()
    if (env === "production"){
        return "2e18e904-d9cd-4911-b30c-1817b1e0b04b"
    } else if (env === "staging"){
        return "4d43463a-fb2f-44e8-b14e-812875bfeefb"
    } else if (env === "staging2"){
        return "93737f1b-0c0e-4a2a-877f-1b891402bdc2"
    } else if (env === "staging3"){
        return "b577aacf-295a-404d-9c59-9a23401f6c64"
    } else if (env === "development") {
        return "4d43463a-fb2f-44e8-b14e-812875bfeefb"
    }    
}