import { getSecondsFromDuration } from "./common";
import { getAllConstants } from "./regionalization";

export function getDateWithoutTimeZoneIfAny(dateStr) {
    if (!dateStr) return null;
    const lastIndexOfTime = dateStr.lastIndexOf(":") + 3;
    const newdate = dateStr.slice(0, lastIndexOfTime);
    return newdate;
}

export function getSecondsFromEpgTime(epgTime) {
    const timeArr = epgTime.split(':');
    if (timeArr.length > 2) {
        let additionalSec = 0;
        const newTime = getDateWithoutTimeZoneIfAny(epgTime)
        const dateDiff = getDifferenceFromEpgMaxEndDate(newTime);
        if (dateDiff > 0) {
            additionalSec = 86400 * dateDiff
        }
        const epgdateTime = new Date(newTime);
        let hr = epgdateTime.getHours();
        let min = epgdateTime.getMinutes();
        let sec = epgdateTime.getSeconds();
        return hr*3600 + min*60 + sec + additionalSec;
    } else {
        return getSecondsFromDuration(epgTime, true);
    }
}

export function getEpgTimeFromSeconds(sec) {
    let secondsInDay = sec % 86400;
    let intDuration = parseInt(secondsInDay);
    let hr = Math.floor(intDuration / 3600);
    intDuration = intDuration % 3600;

    let min = Math.floor(intDuration / 60);
    if (hr < 10) { hr = `0${hr}` }
    if (min < 10) { min = `0${min}` }
    return `${hr}:${min}`
}

function generateNoAvailableItem(startTimeSec, endTimeSec) {
    const { kNoProgramDataAvailable } = getAllConstants();
    return {
        epgEndTime: getEpgTimeFromSeconds(endTimeSec),
        epgEndTimeSec: endTimeSec,
        epgStartTime: getEpgTimeFromSeconds(startTimeSec),
        epgStartTimeSec: startTimeSec,
        itemDurationInSec: (endTimeSec-startTimeSec),
        haveNoData: true,
        epgName: kNoProgramDataAvailable,
        identifier: `${startTimeSec}`,
        videos: [{}]
    }
}

export function makeGroupsFromChannelItems(items, epgMaxEndDate) {
    let groupObj = {};
    let channelGroups = [];
    let maxEndTimeSec = 0;
    if (!items && !items.length) return [];
    for(let i=0; i<items.length; i++) {
        let item = items[i];
        if (item.epgId) {
            if (groupObj[item.epgId]) { groupObj[item.epgId].push(item) }
            else { groupObj[item.epgId] = [item] }
        } else {
            channelGroups.push({
                epgEndTime: item.epgEndTime,
                epgEndTimeSec: item.epgEndTimeSec,
                epgStartTime: item.epgStartTime,
                epgStartTimeSec: item.epgStartTimeSec,
                itemDurationInSec: item.epgEndTimeSec - item.epgStartTimeSec,
                title: item.title,
                epgName: item.title,
                subtitle: item.subtitle,
                identifier: item.identifier,
                haveNoData: false,
                videos: [{...item}],
                isLastItem: false
            })
        }
    }
    let allKeys = Object.keys(groupObj);
    allKeys.length && allKeys.forEach(groupId => {
        channelGroups.push(combineGroupVideos(groupObj[groupId], groupId))
    });
    const sortedChannelItems = channelGroups.sort((item1, item2) => {
        return item1.epgStartTimeSec - item2.epgStartTimeSec
    })
    sortedChannelItems.length && (sortedChannelItems[sortedChannelItems.length - 1].isLastItem = true);
    channelGroups = addNoDataAvailable(sortedChannelItems, epgMaxEndDate);
    return channelGroups;
}

function addNoDataAvailable(items, epgMaxEndDate) {
    let dateDiff = getDifferenceFromEpgMaxEndDate(epgMaxEndDate);
    let maxEndTimeSec = 0;
    const channelGroups = [];
    for(let i=0; i<items.length; i++) {
        let item = items[i];
        if ((item.epgStartTimeSec - maxEndTimeSec) > 100) {
            channelGroups.push(generateNoAvailableItem(maxEndTimeSec, item.epgStartTimeSec))
        }
        maxEndTimeSec = maxEndTimeSec > item.epgEndTimeSec ? maxEndTimeSec : item.epgEndTimeSec;
        channelGroups.push(item)
    }
    const maxSeconds = 86400 + (dateDiff * 86400);
    if ((maxSeconds - maxEndTimeSec) > 100 ) {
        channelGroups.push(generateNoAvailableItem(maxEndTimeSec, maxSeconds))
    }
    return channelGroups;
}

function combineGroupVideos(groupArr, groupId) {
    const startTimeSec = groupArr[0].epgStartTimeSec;
    const sortedItems = groupArr.sort((item1, item2) => {
        return item1.epgStartTimeSec - item2.epgStartTimeSec
    })
    const groupObj = {
        epgStartTime: groupArr[0].epgStartTime,
        epgStartTimeSec: startTimeSec,
        videos: sortedItems,
        isGroup: true,
        identifier: `${groupId}-${groupArr[0].identifier}`,
        haveNoData: false,
        epgName: groupArr[0].title
    }
    let duration = 0;
    for(let i=0; i<groupArr.length; i++) {
        let item = groupArr[i];
        const itemDuration = item.epgEndTimeSec - item.epgStartTimeSec;
        duration += itemDuration
        if (item.epgName) {
            groupObj.epgName = item.epgName;
        }
    }
    let endTimeSec = startTimeSec + duration;
    groupObj.itemDurationInSec = duration;
    groupObj.epgEndTime = getEpgTimeFromSeconds(endTimeSec);
    groupObj.epgEndTimeSec = endTimeSec;
    return groupObj;
}

export function getStartingMinuts(timeInterval) {
    const width = window.screen.width;
    const pxPerMin = 360/timeInterval;
    const min = width/pxPerMin;
    const minimumMinutes = 1440 - min;
    const minimumStarting = Math.floor(minimumMinutes/timeInterval) * timeInterval;
    const h = new Date().getHours();
    const currMin = new Date().getMinutes();
    const currTotalMin = h*60 + currMin;
    const startTimeMin = Math.floor(currTotalMin/timeInterval) * timeInterval;
    if (minimumStarting < startTimeMin)
        return minimumStarting;
    return startTimeMin;
}

export function calculateTimeBarWidth(timeInterval, epgMaxEndTime) {
    const dateDiff = getDifferenceFromEpgMaxEndDate(epgMaxEndTime);
    const startMin= getStartingMinuts(timeInterval);
    const pxPerMin = 360/timeInterval;
    const totalMin = (1440) - startMin + (dateDiff*1440);
    return totalMin*pxPerMin;
}

export function calculateEpgHeaderWidth(timeInterval, epgMaxEndTime) {
    const timeBarWidth = calculateTimeBarWidth(timeInterval, epgMaxEndTime);
    const currentTimeWidth = 260+50;    //hardcoded
    return timeBarWidth+currentTimeWidth;
}

export function calculateEpgProgrammesWidth(timeInterval, epgMaxEndTime) {
    const timeBarWidth = calculateTimeBarWidth(timeInterval, epgMaxEndTime);
    const channelMenuWidth = 300;   //hardcoded
    return timeBarWidth + channelMenuWidth;
}

export function calculateWidthFromSec(sec, timeInterval) {
    const pxPerSec = 360/timeInterval/60;
    return sec*pxPerSec;
}

export function getPlayingObject(channel) {
    const {identifier, showVideoAd, locked, access, special, displayTitle, registerWall, inlineMenu} = channel;
    const parentObj = {
        locked: locked,
        access: access,
        special: special,
        displayTitle: displayTitle || null,
        registerWall: registerWall,
        parent_id: identifier,
        inlineMenu: inlineMenu || "",
        showVideoAd
    }
    const currentSeconds = (new Date().getHours() * 3600) + (new Date().getMinutes() * 60) + new Date().getSeconds();
    let playingItem = null;
    let encounterNoData = false;
    let allContents = []
    channel.channelItems.forEach(item => {
        if ((item.epgStartTimeSec <= currentSeconds) && (item.epgEndTimeSec >= currentSeconds)) {
            playingItem = item;
            item.videos.map(vid => {
                if(!item.haveNoData) {
                    vid.epgContainerStartTimeSec = item.epgStartTimeSec;
                    vid.epgContainerEndTimeSec = item.epgEndTimeSec;
                }
                return vid;
            })
            allContents = allContents.concat(item.videos)
        }
        else if (playingItem && !item.haveNoData && !encounterNoData) {
            item.videos.map(vid => {
                vid.epgContainerEndTimeSec = item.epgEndTimeSec;
                vid.epgContainerStartTimeSec = item.epgStartTimeSec;
                return vid;
            })
            allContents = allContents.concat(item.videos)
        }
        if (playingItem && item.haveNoData) {
            encounterNoData = true;
        }
    })

    const playingVideoObj = getPlayingVideo(playingItem, currentSeconds, parentObj);
    allContents = allContents.map(item => ({...item, ...parentObj}))
    return {
        allContents,
        ...playingVideoObj,
        currPlayingItem: playingItem
    }
}

function getPlayingVideo(playingItem, currentSeconds, parentObj) {
    let playingVideo={};
    if (playingItem && playingItem.videos && playingItem.videos.length == 1) {
        playingVideo = playingItem.videos[0]
    } else {
        playingItem && playingItem.videos && playingItem.videos.forEach(item => {
            if ((item.epgStartTimeSec <= currentSeconds) && (item.epgEndTimeSec >= currentSeconds)) {
                playingVideo = item;
            }
        })
    }
    let forwardDuration = currentSeconds - playingVideo.epgStartTimeSec;
    return { identifier: playingVideo.identifier,
        forwardDuration,
        item: {...playingVideo, ...parentObj}  
    }
}  

export function checkIfTodayOrFutureTime(epgTime, maxEndTime) {
    const timeArr = epgTime.split(':');
    if (timeArr.length > 2) {
        const maxEndDate = new Date(maxEndTime)
        const epgdateTime = new Date(getDateWithoutTimeZoneIfAny(epgTime));
        const currDate = new Date()
        return ((epgdateTime >= currDate) && (epgdateTime <= maxEndDate));
    }
    else {
        return true;
    }
}

export function compareAndReturnBigDate(date1, date2) {
    const d1 = date1 ? new Date(date1) : new Date();
    const d2 = date2 ? new Date(date2) : new Date();
    if (d1 > d2)
        return d1;
    else 
        return d2;
}

export function getDifferenceFromEpgMaxEndDate(epgMaxEndDate) {
    const maxString = new Date(epgMaxEndDate).toDateString();
    const timeString = new Date().toDateString();
    const diffTime = Math.abs(new Date(maxString) - new Date(timeString));
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
    return diffDays;
}

export function getAllTimesForTimeline(durationInterval, epgMaxEndDate) {
    let timeDifference = parseInt(durationInterval); //This should be in minutes
    // let h = new Date().getHours();
    // let currMin = new Date().getMinutes();
    // let currTotalMin = h*60 + currMin;
    // let startTimeMin = Math.floor(currTotalMin/timeDifference) * timeDifference;
    let m = getStartingMinuts(timeDifference);
    let times = generateTimelineArray(m, timeDifference);
    let dateDiff = getDifferenceFromEpgMaxEndDate(epgMaxEndDate)
    if (dateDiff > 0) {
        for(let i=0; i<dateDiff; i++) {
            times = times.concat(generateTimelineArray(0, durationInterval, i+1))
        }
    }
    return times;
}

export function generateTimelineArray(startingMin, durationInterval, i="") {
    let m = startingMin;
    let times = [];
    

    while (m < 1440) {
        let min = m;
        let format = "AM";
        let hoursObj = calculateHoursFromMin(min);
        let hours = hoursObj.hours
        if(parseInt(hours) >= 12) {
            hours = parseInt(hours) - 12;
            format = "PM";
        }
        if(parseInt(hours) == 0) {
            hours = 12
        }
        if (hours == "12" && i && format == "AM") {
            let date = new Date();
            date.setDate(date.getDate() + i);
            let dateStr = date.toDateString().substring(4,10);
            hours = `${dateStr},  ${hours}`
        }
        times.push(`${hours}:${hoursObj.minutes} ${format}`);
        m+=durationInterval;
    }
    return times;
}

function calculateHoursFromMin(min){
    let hours = Math.floor(min/60);
    let minutes = min%60;
    if (minutes < 10)
        minutes = `0${minutes}`
    if (hours < 10)
        hours = `0${hours}`
    
    return {
      hours,
      minutes
    }
}

export function checkFutureItemAndGenerateArr(item, epgMaxEndDate) {
    let dateDiff = getDifferenceFromEpgMaxEndDate(epgMaxEndDate);
    let startTime = item.epgStartTime;
    let futureItems = [];
    if ((startTime.split(':').length == 2) && (dateDiff > 0)) {
        for(let i=0; i<dateDiff; i++) {
            let newIndex = i+1;
            let newItem = {...item};
            newItem.epgEndTimeSec = newItem.epgEndTimeSec + 86400*newIndex;
            newItem.epgStartTimeSec = newItem.epgStartTimeSec + 86400*newIndex;
            newItem.identifier = newItem.identifier + newIndex;
            if (newItem.epgId)
                newItem.epgId = `${newIndex}${newItem.epgId}`
            futureItems.push(newItem);
        }
    }
    return futureItems;
}

export const getTimeIn12HoursFormat = (hr,min) => {
    if(hr === 0 || hr === '00') return `12:${min} AM`;
    else if(hr > 12) return `${hr - 12}:${min} PM`
    else return `${hr}:${min} AM`
}

export function formatEpgTime(epgTime) {
    const timeArr = epgTime.split(':');
    if (timeArr.length > 2) {
        const newTime = getDateWithoutTimeZoneIfAny(epgTime);
        const epgdateTime = new Date(newTime);
        let hr = epgdateTime.getHours();
        let min = epgdateTime.getMinutes();
        if (hr < 10) {hr = `0${hr}`}
        if (min < 10) {min = `0${min}`}
        return getTimeIn12HoursFormat(hr,min)
    } else {
        return epgTime;
    }
}

export function supportTimezoneFormatting(supportTimezone, time) {
    //replacing - with /, fot date time support in safari
    let slashTime = time && time.replace(/-/g, "/");
    if(
        !time ||
        !supportTimezone ||
        slashTime.split(':').length <= 2 ||
        new Date(slashTime) == "Invalid Date"
    ) {
        return slashTime
    }
    return new Date(slashTime).toString();
}
