import { getTheoPlayerVariables, MEDIA_MELON_THEO, MEDIA_MELON_VIDEOJS, PLATFORM_HTML_SIMULATOR, PLATFORM_LG, PLATFORM_SAMSUNG, PLATFORM_VIZIO, PLATFORM_WEB, PLATFORM_WEB_PREVIEW, PLATFORM_XBOX, PLATFORM_ZEASN } from "../constants/common";
import configuration from "../constants/config";
import { loadJsScript } from "./ctvHelper";
import { getUserCookie } from "./storage";
import packageJson from '../../package.json';

let mediaMelonInstane = null;
let customerId = null;
let user_logged_in = false;


function initMMVideoJsInstance() {
  if(window && window.VideoJSMMSSIntgr) {
    mediaMelonInstane = new window.VideoJSMMSSIntgr();
    if(!mediaMelonInstane) return;

    if(mediaMelonInstane.getRegistrationStatus() === false) {
      mediaMelonInstane.registerMMSmartStreaming(getPlayerName(), customerId, getSubscriberId(), configuration.title, getSubscriberType(), "NA");
      mediaMelonInstane.reportPlayerInfo("VideoJS", "VideoJS", '7.21.0');
    }
    const version = configuration.app_version || packageJson.version;
    mediaMelonInstane.reportAppInfo(configuration.title, version);
  }
}

function getPlayerName() {
  const platform = configuration.platform;
  const name = configuration.kUseTheoPlayer ? "TheoPlayer" : "VideoJS"
	if (platform === PLATFORM_WEB)
		return `${name}_Web`;
	if (platform === PLATFORM_WEB_PREVIEW)
		return `${name}_Preview`;
	if (platform === PLATFORM_LG)
		return `${name}_WebOS`;
	if (platform === PLATFORM_SAMSUNG)
		return `${name}_Tizen`;
	if (platform === PLATFORM_HTML_SIMULATOR)
		return `${name}_Simulator`;
	if (platform === PLATFORM_VIZIO)
    return `${name}_Vizio`;
	if (platform === PLATFORM_XBOX)
    return `${name}_Xbox`;
	if (platform === PLATFORM_ZEASN)
    return `${name}_Zeasn`;

  return name;
}

function getSubscriberId() {
  let id = "NA"
	const user = getUserCookie();
	if(configuration.isAppTvodType) {
    if(user && user.profiles && user.profiles.length > 0) {
			id = user.profiles[0].identifier || "NA";
		}
  } else {
    id = user?.user_id || "NA";
  }

  return id;
}

function getSubscriberType() {
  if(user_logged_in) return "Authenticated";
  return "Not Authenticated";
}

function initMMTheoInstance() {
  if(window && window.mmTheoJSAdapter) {
    mediaMelonInstane = new window.mmTheoJSAdapter();
    if(!mediaMelonInstane) return;

    if (mediaMelonInstane.getRegistrationStatus() === false) {
      mediaMelonInstane.registerMMSmartStreaming(getPlayerName(), customerId, getSubscriberId(), configuration.title, getSubscriberType(), "NA");
      mediaMelonInstane.reportPlayerInfo("TheoPlayer", "TheoPlayer", "5.6");
    }

    const version = configuration.app_version || packageJson.version;
    mediaMelonInstane.reportAppInfo(configuration.title, version);
  }
}

function loadScript() {
  const scriptUrl = configuration.kUseTheoPlayer ? MEDIA_MELON_THEO : MEDIA_MELON_VIDEOJS;
  loadJsScript({
    url: scriptUrl,
    location: document.body,
    type_to_set: "async",
    onLoadMethod: initMediaMelon
  }); 
}

export function initMediaMelon() {
  if(configuration.tv && configuration.tv.kMediaMelonCustomerId) {
    customerId = configuration.tv.kMediaMelonCustomerId;
  } else {
    return;
  }

  if(!configuration.kUseTheoPlayer) {
    initMMVideoJsInstance();
  } else {
    initMMTheoInstance();
  }
}

export function getMMVideoAssetInfo(videoItem) {
  if(!mediaMelonInstane || !videoItem) return {};

  const { drm, video_id, episode_number } = videoItem.cmsData || {}
  const mmVideoAssetInfo = {
    "assetName": videoItem.title,
    "assetId": videoItem.identifier,
    "videoId": video_id || "NA",
    "contentType": videoItem.catalog || "NA",
    "genre" : videoItem.genre || "NA",
    "episodeNumber": episode_number || "NA"
  };
  if(drm && Object.keys(drm).length > 0) {
    mmVideoAssetInfo.drmProtection = Object.keys(drm).join(", ")
  }

  return {mmVideoAssetInfo: mmVideoAssetInfo};
}

export function startSendingMMEvents(player, mediaURL, videoItem, isLive = false) {
  if(!mediaMelonInstane || !player) return {};
  
  const { mmVideoAssetInfo = {} } = getMMVideoAssetInfo(videoItem);
  mediaMelonInstane.initialize(player, mediaURL, mmVideoAssetInfo, isLive);    
}

export function startSendingMMEventsTheo(player, isLive, videoItem) {
  if(!mediaMelonInstane || !player) return {};

  const { mmVideoAssetInfo = {} } = getMMVideoAssetInfo(videoItem);
  mediaMelonInstane.initialize(player, mmVideoAssetInfo, isLive);        
}

export function loadMediaMelonScript(logged_in) {
  if(configuration.tv && configuration.tv.kMediaMelonCustomerId) {
    customerId = configuration.tv.kMediaMelonCustomerId;
  } else {
    return;
  }

  user_logged_in = logged_in;

  if(configuration.kUseTheoPlayer) {
    const THEO_VARIABLES = getTheoPlayerVariables();
    loadJsScript({
      url: THEO_VARIABLES.cdn,
      location: document.body,
      type_to_set: "async",
      onLoadMethod: loadScript
    });
  } else {
    loadScript();
  }
}

export function resetInstance(logged_in) {
  user_logged_in = logged_in;
  initMediaMelon();
}

export function resettingVideoSource() {
  if(!mediaMelonInstane) return;

  try {
    mediaMelonInstane.reportContentSwitch();
  } catch {
    console.log("reset event not sent")
  }
}