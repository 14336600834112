import React from "react";
import { connect } from "react-redux";
import { Route, Link, Switch, withRouter } from "react-router-dom";
import { Redirect } from "react-router";

import MyQueue from "./MyQueue";
import Settings from "./Settings";
import Search from "./Search";
import PaymentMsg from "../components/PaymentMsg";
import configuration from "../constants/config";
import WebNavBar from "../components/WebNavBar";
import {
  backButtonObj,
  checkIfSectionExist,
  checkIfSectionLevelContent,
  getOnStartDefaultSection,
  isSection,
  showSearchSection,
  showSettingSection
} from "../helperfunctions";
import {
  checkCorrectPlatform,
  checkLoginUIMetaData,
  getCurrentPlatformTvod,
  getTabIndex,
  isMenuBlockGeofence,
  isSmartTv
} from "../helperfunctions/common";
import {
  deepLinkUrl,
  hexToRgba,
  PLATFORM_LG,
  PLATFORM_SAMSUNG,
  PLATFORM_VIZIO
} from "../constants/common";
import backMobile from "../images/icons/back-mobile.png";
import { closeModal } from "../actions/modalActions";
import {
  snFocusEventHandler,
  alignToTop,
  getParameterByName
} from "../helperfunctions/ctvHelper";
import { updateSearchRouteEntry } from "../actions/searchActions";
import ErrorBoundary from "../components/ErrorBoundary";
import { getAllConstants } from '../helperfunctions/regionalization';
import TvodSubscriptions from "./TvodSubscriptions/";
import { elemOrientation } from '../helperfunctions/feedHelper';

let Section;
if(checkCorrectPlatform([PLATFORM_SAMSUNG, PLATFORM_LG])) {
  Section = require('./Section').default;
} else {
  Section = React.lazy(() => import('./Section'));
}

class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.resizeTimeout = null;
    this.hideNavTimeout = null;
    this.onBackButtonEvent = this.onBackButtonEvent.bind(this);
    this.state = { currentView: "", mountdone: false };
  }

  componentDidMount() {
    window.addEventListener("popstate", () =>
      this.onBackButtonEvent(window.location.pathname)
    );
    if (configuration.is_simulator) {
      this.hideNavbar();
      this.onBodyMouseMoveHideShowNavbar();
    }
    this.onViewChange();
    if (checkCorrectPlatform([PLATFORM_VIZIO]) && window.VIZIO) {
      window.VIZIO.setContentChangeHandler((contentURL) => {
        let actionUrl;
        if (contentURL) {
          actionUrl = getParameterByName("item", contentURL);
        }

        if (actionUrl) {
          let deeplink = deepLinkUrl(
            actionUrl,
            this.props.app_id,
            this.props.feeds
          );
          deeplink && this.props.history.push(deeplink);
        } else {
          this.props.history.push("/");
        }
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentView !== this.state.currentView) {
      this.onViewChange();
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.handleRemoteClick);
  }

  handleRemoteClick = (e) => {
    if (e.keyCode == "10009" || e.keyCode == "461") {
      if (document.getElementById("overlay_video_back_btn")) {
        document.getElementById("overlay_video_back_btn").click();
      } else if (document.getElementById("main_back_id")) {
        document.getElementById("main_back_id").click();
      }
      e.preventDefault();
    }
  };

  navigateToRoute = (route) => {
    const prev = this.props.location && this.props.location.pathname
    const interstitialPresent = document.querySelector(".interstitial") || 
                                (configuration.isAppTvodType && (document.querySelector(".tvod-interstitial") || 
                                document.querySelector(".videoFull")))

    if (this.state.currentView !== route) {
      this.setState({ previousRoute: prev, currentView: route })
      this.handleSearchRoute(route)
      if(this.state.currentView && interstitialPresent) this.props.closeModal() //On route change, if interstitial is open. Close it
    }
  };

  handleSearchRoute(route) {
    // handle routing from search in case of menu click
    const searchRouteEntry = this.props.searchRouteEntry;
    if (!searchRouteEntry || searchRouteEntry.length === 0) return;
    let routes = [...searchRouteEntry];
    const lastRoute = searchRouteEntry[searchRouteEntry.length - 1];
    const path = window.location.pathname;
    if (isSection(this.props.feeds, route)) {
      routes = [];
    } else if (path == lastRoute) {
      routes.push(`${path}/${route}`);
    } else {
      routes.pop();
    }
    this.props.updateSearchRouteEntry(routes);
  }

  handleHeaderLinks(className, add) {
    const headerLinks = document.getElementsByClassName(className);
    for (let element of headerLinks) {
      if (element) {
        if (add) {
          element.classList.add("disable-header-link");
        } else if (element.classList) {
          element.classList.remove("disable-header-link");
        }
      }
    }
  }

  onBackButtonEvent(route) {
    this.setState({ currentView: route });
  }

  getNavShadowBar() {
    const { tabBarCustomisation } = this.props.feeds.extras || {};
    if (
      !configuration.isAppTvodType ||
      !tabBarCustomisation ||
      !tabBarCustomisation.color
    )
      return {};
    const platform = getCurrentPlatformTvod();
    if (
      tabBarCustomisation[platform] &&
      tabBarCustomisation[platform].shadowOpacity
    ) {
      const rgba = hexToRgba(
        tabBarCustomisation.color.replace("#FF", "#"),
        tabBarCustomisation[platform].shadowOpacity / 100
      );
      return { boxShadow: `0px 10px 20px ${rgba}` };
    }
    return {};
  }

  onViewChange() {
    this.handleHeaderLinks("headerLinks", true); //disable
    setTimeout(() => {
      this.handleHeaderLinks("headerLinks"); //enable all
      this.handleHeaderLinks("headerLinks active", true); // disable active one
    }, 1200);
  }

  onBodyMouseMoveHideShowNavbar = () => {
    document.body.onmousemove = () => {
      if (document.getElementById("nav-bar")) {
        if (this.hideNavTimeout) {
          clearTimeout(this.hideNavTimeout);
        }
        this.showNavbar();
        this.hideNavbar();
      }
    };
  };

  hideNavbar = () => {
    return;
    this.hideNavTimeout = setTimeout(function () {
      if (document.getElementById("nav-bar")) {
        document.getElementById("nav-bar").style.top = "-140px";
        document.getElementById("nav-bar").style.transitionDuration = ".7s";
      }
    }, 3000);
  };

  showNavbar = () => {
    if (document.getElementById("nav-bar")) {
      document.getElementById("nav-bar").style.top = "0";
      document.getElementById("nav-bar").style.transitionDuration = ".4s";
    }
  };

  backButton(section_id) {
    if (window.location.pathname.includes("/saved_items")) {
      return {
        pathLocation: window.location.pathname.split("/saved_items")[0]
      };
    }
    return backButtonObj(
      this.props.feeds,
      section_id,
      this.props.runningOnMobile
    );
  }

  //If section id have saved_items return to save section
  getSectionId = (sectionId) => {
    if (sectionId && sectionId.includes("/saved_items")) {
      this.props.history.replace(
        `/apps/${configuration.app_id}/${sectionId.split("/saved_items")[0]}`
      );
      return sectionId.split("/saved_items")[0];
    }
    return sectionId;
  };

  renderBackButton(section_id) {
    let { pathLocation, style } = this.backButton(section_id);
    const lastPage = this.props.history.location?.state?.from
    let parent_section_id = pathLocation;
    window.scrollTo(0, 0);
    const tv_style = {};
    if (isSmartTv()) {
      tv_style.display = "none";
    }
    if (
      this.props.searchRouteEntry &&
      this.props.searchRouteEntry.length === 1
    ) {
      parent_section_id = "search";
    }
    if (this.props.runningOnMobile) {
      return (
        <span className="icon-hollow_arrow backBtnSpan backBtnSpan-mobile">
          <Link
            id="main_mobile_back_button"
            className="backBtn-mobile"
            style={{ zIndex: 100 }}
            to={lastPage? lastPage : "/apps/" + this.props.app_id + "/" + parent_section_id}
            onClick={() => this.navigateToRoute(parent_section_id)}
          >
            <img src={backMobile} alt="Back" />
          </Link>
        </span>
      );
    }
    return (
      <span
        className="icon-hollow_arrow backBtnSpan"
        style={{ ...style, ...tv_style }}
      >
        <Link
          id="main_back_id"
          className="backBtn"
          style={{ zIndex: 100 }}
          to={lastPage? lastPage : "/apps/" + this.props.app_id + "/" + parent_section_id}
          onClick={() => this.navigateToRoute(parent_section_id)}
        />
      </span>
    );
  }

  isBlockedGeofence = (content) => {
    return isMenuBlockGeofence(
      this.props.isGeofencingOn,
      content,
      this.props.userCountry
    );
  };

  applySuspense(_comp) {
    if(checkCorrectPlatform([PLATFORM_SAMSUNG, PLATFORM_LG])) {
      return _comp;
    }

    return (
      <React.Suspense fallback={<div className="text-center">Loading . . .</div>}>
        <ErrorBoundary>
          { _comp }
        </ErrorBoundary>
      </React.Suspense>
    );    
  }
  
  renderNavBar(section_id) {
    var extra_sections = ["search", "settings"];
    var all_extra_sections = extra_sections + ["save", "subscribe"];
    const { feeds, app_id } = this.props;
    const navShadowStyle = this.getNavShadowBar();
    if (configuration.is_simulator) {
      //similator
      if (
        !section_id ||
        checkIfSectionExist(section_id, feeds) ||
        all_extra_sections.includes(section_id)
      ) {
        // if sectionid is null or is number or a string from all_extra section
        let navStyle = { backgroundColor: "#7a7a7a" };
        if (feeds.ottTabBarColor) {
          navStyle.backgroundColor = feeds.ottTabBarColor.replace("#FF", "#");
        }
        if (checkCorrectPlatform([PLATFORM_SAMSUNG, PLATFORM_LG])) {
          // navStyle.zIndex = 1;
        }
        const { kSettingText, kSearchText } = getAllConstants();
        let renderingDynamicSearch = false;
        return (
          <div
            id="nav-bar"
            className="slider-box nav-view"
            style={{ ...navStyle, ...navShadowStyle }}
            onFocus={(e) => alignToTop(e)}
            dir={elemOrientation(feeds)}
          >
            {feeds &&
              feeds.sections &&
              feeds.sections.map(function (section, i) {
                // only when feeds.sections exist
                if (this.isBlockedGeofence(section)) return null;
                if (!section || !section.type) return null;
                if (section.type === "search") {
                  if (!showSearchSection(feeds, true))
                    return null;
                  renderingDynamicSearch = true;
                  return (
                    <div key={i}>
                      <Link
                        to={"/apps/" + app_id + "/search"}
                        className="headerLinks navbar-search focusable"
                        tabIndex={getTabIndex()}
                        onClick={() => this.navigateToRoute("search")}
                      >
                        {section.title}
                      </Link>
                    </div>
                  );
                }
                return (
                  <div key={i}>
                    <Link
                      id={`${
                        section.title &&
                        section.title.toLowerCase().replace(" ", "_")
                      }`}
                      to={"/apps/" + app_id + "/" + section.identifier}
                      className={
                        "focusable headerLinks navbar-" + section.identifier
                      }
                      data-sn-left={!i ? "" : null}
                      tabIndex={getTabIndex()}
                      onClick={() => this.navigateToRoute(section.identifier)}
                    >
                      {section.title}
                    </Link>
                  </div>
                );
              }, this)}
            {extra_sections.map(function (section, i) {
              if (section === "search") {
                if (renderingDynamicSearch || !showSearchSection(feeds)) {
                  return null;
                }
                return (
                  <div key={i}>
                    <Link
                      to={"/apps/" + app_id + "/search"}
                      className="headerLinks navbar-search focusable"
                      tabIndex={getTabIndex()}
                      onClick={() => this.navigateToRoute("search")}
                    >
                      {kSearchText}
                    </Link>
                  </div>
                );
              } else if (section === "settings" && showSettingSection(feeds)) {
                return (
                  <div key={i}>
                    <Link
                      to={"/apps/" + app_id + "/settings"}
                      className="headerLinks navbar-settings focusable"
                      tabIndex={getTabIndex()}
                      data-sn-right=""
                      onClick={() => this.navigateToRoute("settings")}
                    >
                      {kSettingText}
                    </Link>
                  </div>
                );
              }
            }, this)}
          </div>
        );
      } else {
        return this.renderBackButton(section_id);
      }
    } else {
      // for Web
      return (
        <>
          <WebNavBar
            feeds={feeds}
            navigateToRoute={(route) => this.navigateToRoute(route)}
            app_id={app_id}
            currentView={this.state.currentView}
            runningOnMobile={this.props.runningOnMobile}
            isMenuBlockGeofence={this.isBlockedGeofence}
            navShadowStyle={navShadowStyle}
          />
          {section_id &&
            !(
              checkIfSectionExist(section_id, feeds) ||
              all_extra_sections.includes(section_id)
            ) &&
            this.renderBackButton(section_id)}
        </>
      );
    }
  }

  checkIfPymentConfirmationPage = () => {
    if (window.location.pathname.includes("/payment/confirmation")) return true;
    return false;
  };

  render() {
    const sectionClassName = checkIfSectionLevelContent()
      ? "section-content"
      : "non-section-content";
    let section_id = null;
    if (this.props.feeds.sections) {
      section_id = window.location.pathname.replace(
        "/apps/" + this.props.app_id,
        ""
      );
      section_id = section_id.replace(/^\/+|"+$/g, "");

      if (
        isSmartTv() &&
        !window.location.pathname.includes("apps") &&
        !window.location.pathname.includes("payment") &&
        !window.location.pathname.includes("saved_items")
      ) {
        section_id = "";
      } else if (isSmartTv() && window.location.pathname.includes(".html")) {
        section_id = "";
      }

      if (section_id.includes("/trailer")) {
        section_id = section_id.split("/trailer")[0];
        return (
          <Redirect to={"/apps/" + this.props.app_id + "/" + section_id} />
        );
      }

      if (section_id === "") {
        section_id = getOnStartDefaultSection(this.props.feeds);
        return (
          <Redirect to={"/apps/" + this.props.app_id + "/" + section_id} />
        );
      }
    }

    return (
      <div
        id="nav-with-modal-id"
        className={
          "with-modal " +
          (section_id === "subscription" || this.checkIfPymentConfirmationPage()
            ? "subscription-section "
            : " ") +
          sectionClassName
        }
      >
        {this.renderNavBar(section_id)}
        {this.applySuspense(
          <Switch>
            <Route exact path={"/payment/confirmation"}>
              <PaymentMsg feeds={this.props.feeds} />
            </Route>
            {"hasSearch" in this.props.feeds &&
            !showSearchSection(this.props.feeds) ? null : (
              <Route path={"/apps/" + this.props.app_id + "/search"}>
                <Search
                  app_id={this.props.app_id}
                  background={this.props.background}
                  feeds={this.props.feeds}
                  runningOnMobile={this.props.runningOnMobile}
                />
              </Route>
            )}
            <Route path={"/apps/" + this.props.app_id + "/settings"}>
              <Settings
                background={this.props.background}
                feeds={this.props.feeds}
                logo={this.props.feeds.logo}
                app_id={this.props.app_id}
                runningOnMobile={this.props.runningOnMobile}
                parentCallback={(route) => this.navigateToRoute(route)}
                has_subs={this.props.feeds.subscriptions != undefined}
              />
            </Route>
            <Route path={"/apps/" + this.props.app_id + "/subscriptions"}>
              <TvodSubscriptions
                feeds={this.props.feeds}
                runningOnMobile={this.props.runningOnMobile}
              />
            </Route>
            <Route exact path={"/apps/" + this.props.app_id}>
              {section_id ? (
                <Section
                  oneFeedLoading={this.props.oneFeedLoading}
                  feeds={this.props.feeds}
                  app_id={this.props.app_id}
                  parentCallback={(route) => this.navigateToRoute(route)}
                  runningOnMobile={this.props.runningOnMobile}
                  isBlockedGeofence={this.isBlockedGeofence}
                />
              ) : (
                <MyQueue
                  feeds={checkLoginUIMetaData(this.props.feeds)}
                  app_id={this.props.app_id}
                  background={this.props.feeds.savedUIMetadata.background}
                  item_id={0}
                  runningOnMobile={this.props.runningOnMobile}
                />
              )}
            </Route>
            {section_id && (
              <Route
                path={"/apps/" + this.props.app_id + "/:section_id*"}
                render={(props) => (
                  <Section
                    oneFeedLoading={this.props.oneFeedLoading}
                    key={this.getSectionId(props.match.params.section_id)}
                    feeds={this.props.feeds}
                    previousRoute={this.state.previousRoute}
                    section_id={this.getSectionId(
                      props.match.params.section_id
                    )}
                    app_id={this.props.app_id}
                    parentCallback={(route) => this.navigateToRoute(route)}
                    runningOnMobile={this.props.runningOnMobile}
                    isBlockedGeofence={this.isBlockedGeofence}
                  />
                )}
              />
            )}
            <Redirect to="/" />
          </Switch>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  searchRouteEntry: state.search.searchRouteEntry
});

const connectDispatchToProps = {
  closeModal,
  updateSearchRouteEntry: updateSearchRouteEntry
};

export default withRouter(
  connect(mapStateToProps, connectDispatchToProps)(NavBar)
);
