import React from 'react';
import Swiper from 'react-id-swiper';
import downArrow from "../images/icons/down-arrow.png";
import upArrow from "../images/icons/up-arrow.png";
import flatArrow from "../images/icons/flat.png";
import { checkIfSectionLevelContent, getFontStyleFromFeed } from "../helperfunctions";
import { INLINE_MENU_IDLE_TIMEOUT, INLINE_MENU } from "../constants/common";
import { getTabIndex, isFeedAvailable, isSmartTv } from '../helperfunctions/common';
import config from '../constants/config';
import { getWebBannerSwiperParams, getWebSwiperParamsByTheme } from '../helperfunctions/webSwiperParams';
import FocusLock from 'react-focus-lock';
import JsSpatialNavigation from '../ctvnavigation/js-spatial-navigation';
import Image from './Image';
import { getBackBtnKeyCodes, keyMappings } from '../helperfunctions/ctvHelper';

class InlineMenu extends React.Component {
    constructor(props) {
      super(props);
      this.curr_focus = 0;
      this.timerCtv=null;
      this.state = {
        showInlineMenu: false,
        isOpening: false,
        isClosing: false,
        swiper: null
      }
  }

    componentDidMount() {
      document.body.addEventListener("mousemove", this.onBodyMouseMoveHideShowMenu, true);
      document.body.addEventListener("click", this.onBodyMouseMoveHideShowMenu, true);
      this.onBodyMouseMoveHideShowMenu();
      document.addEventListener('keyup', this.handleRemoteClick); 
    }

    componentDidUpdate(prevProps, prevState) {
      // slide to focused slide
      if(this.state.showInlineMenu && prevState.showInlineMenu !== this.state.showInlineMenu) {
        const swiperEl = document.querySelector('#video-inlineMenu .swiper-container');
        if(!isSmartTv() && !isNaN(this.props.playing) && swiperEl && swiperEl.swiper) {
            swiperEl.swiper.slideTo(this.props.playing)
        }
      }
    }

    handleRemoteClick = (e) => {
      if(document.getElementById("next-video") && document.getElementById("next-video").style.display === 'inline')
        return;

      const keyCode = keyMappings(e.keyCode);
      const back_key_codes = getBackBtnKeyCodes();

      if(keyCode == 'LEFT' || keyCode == 'RIGHT' || keyCode == 'UP' || keyCode == 'DOWN') {
        this.onBodyMouseMoveHideShowMenu();
      }
      if(keyCode == 'UP') {
        if(this.state.showInlineMenu) return;
        this.onBodyMouseMoveHideShowMenu();
        this.setState({
          showInlineMenu: true,
          isOpening: true,
          isClosing: false
        })
        this.timerCtv && clearTimeout(this.timerCtv);
        this.timerCtv = setTimeout(() => {
          this.setState({
            showInlineMenu: true,
            isOpening: false,
            isClosing: false
          },() => {
            this.focusPlayingElement(this.props.playing);
            // if(document.getElementById('menu_vid_0')) {
            //   document.getElementById('menu_vid_0').focus();
            // }
          })
        },500)
      } else if(keyCode == 'DOWN' || back_key_codes[e.keyCode]) {
        if(!this.state.showInlineMenu) return;
        this.onBodyMouseMoveHideShowMenu();
        this.setState({
          showInlineMenu: false,
          isOpening: false,
          isClosing: true
        })  
        this.timerCtv && clearTimeout(this.timerCtv);
        this.timerCtv = setTimeout(() => {
          this.setState({
            showInlineMenu: false,
            isOpening: false,
            isClosing: false
          })
        },500)
        if(checkIfSectionLevelContent()) {
          const nav_elm = document.getElementById('nav-bar');
          if(nav_elm && nav_elm.style.display != 'none') {
            JsSpatialNavigation.focus('navbar');
          }
        }
      } else if(e.keyCode == '37' || e.keyCode == '39' || e.keyCode == '38' || e.keyCode == '40') {
        this.onBodyMouseMoveHideShowMenu();
      }
    }

    focusPlayingElement = (index) => {
      if (!(index >= 0)) index = 0;
      if (isSmartTv) {
        const elementId = `menu_vid_${index}`
        if(document.getElementById(elementId)) {
          document.getElementById(elementId).focus();
        }
      }
    }

    componentWillUnmount() {
      document.body.removeEventListener("mousemove", this.onBodyMouseMoveHideShowMenu, true);
      document.body.removeEventListener("click", this.onBodyMouseMoveHideShowMenu, true);
      if (this.hideMenuTimeout) {
        clearTimeout(this.hideMenuTimeout);
      }
      document.removeEventListener('keyup', this.handleRemoteClick);    
    }

    onBodyMouseMoveHideShowMenu = () => {
      if (document.getElementById("video-inlineMenu")) {
        if (this.hideMenuTimeout) {
          clearTimeout(this.hideMenuTimeout);
        }
        this.showMenu();
        this.hideMenu();
      }
    }
  
    hideMenu = () => {
      this.hideMenuTimeout = setTimeout(() => {
        if (document.getElementById("video-inlineMenu")){
          document.getElementById("video-inlineMenu").style.display = "none" ;
          if(this.state.showInlineMenu) {
            this.handleInlineMenu();
          }   
        }
      }, INLINE_MENU_IDLE_TIMEOUT)

      // if(document.getElementById('thumb-video_html5_api')) {
      //   document.getElementById('thumb-video_html5_api').focus();
      // }
    }
  
    showMenu = () => {
      if (document.getElementById("video-inlineMenu") && !(
        document.getElementById("next-video") && document.getElementById("next-video").style.display === "inline"
      )) {
        document.getElementById("video-inlineMenu").style.display = "inherit";
      }
    }

    handleInlineMenu() {
      const isOpened = this.state.showInlineMenu;
      this.setState({
        showInlineMenu: true,
        isOpening: !isOpened,
        isClosing: isOpened
      })
      this.timerCtv = setTimeout(() => {
        this.setState({
          showInlineMenu: !isOpened,
          isOpening: false,
          isClosing: false
        })
      },500)
    }

    playVideo(index) {
      if(this.props.playing == index) {
        this.handleInlineMenu();
        return;
      }
      this.props.next(index);
    }

    renderMenu() {
      let params;
      if (!config.is_simulator) {
        params = getWebSwiperParamsByTheme(this, null, INLINE_MENU)
      } else {
        params = {
            mousewheelControl: true,
            slidesPerView: 'auto',
            spaceBetween: 30,
            mousewheelForceToAxis: true,
            mousewheelInvert: true,
            preloadImages: false,
            lazyLoading: true,
            freeMode: true,
            onInit: (swiper) => {
                this.swiper = swiper
            },
            mousewheel: {
                forceToAxis: true,
                invert: false,
            }
        }
      }

        const primary = this.props.feeds.primaryColor || "#FFFFFF";
        const fontStyle = getFontStyleFromFeed(this.props.feeds);
        const img_video_class = (isSmartTv() && config.use_light_sliders) ? 'mar' : 'img-video';
        const { countryGroups } = this.props.feeds;
        const country = this.props.policyData ? this.props.policyData.country : null;

        const _slides = this.props.all_contents.map((video, index) => {
          if (!video || !isFeedAvailable(country, countryGroups, video.countryGroupId)) return null;
          const {cover, identifier, title, type} = video;
          if(type !== "vid") return null;
          return (
            <div key={identifier} className={`video-inlineMenuItem ${img_video_class}`}>
              {this.props.inlineMenu === "full"
                ? (
                <span id={`menu_vid_${index}`} tabIndex={getTabIndex()} className={this.props.playing === index ? "selected focusable" : "focusable"} 
                  style={{backgroundColor: primary.replace('#FF','#')}}
                  onClick={() => this.playVideo(index) }
                  data-sn-down=""
                  aria-label={title}
                >
                  <Image
                    cover={cover}
                    customSize={384}
                  />
                </span>)
                : 
                (
                  <span id={`menu_vid_${index}`} tabIndex={getTabIndex()} className={"limited " + (this.props.playing === index ? "selected focusable" : "focusable")} 
                    style={{backgroundColor: primary.replace('#FF','#')}}
                    data-sn-down=""
                    aria-label={title}
                  >
                    <Image
                      cover={cover}
                      customSize={384}
                    />
                  </span>)
              }
              <div style={{...fontStyle.primaryFont}} className="title">
                <p>{title}</p>
              </div>
            </div>
          )
        });

        return (
          isSmartTv() && config.use_light_sliders ?
            <div className="d-flex">
              { _slides }
            </div> :
            <Swiper { ...params }>
              { _slides.filter((slide) => slide !== null) }
            </Swiper>
        )
    }

    render() {
        if (!this.props.inlineMenu || this.props.inlineMenu === "" || this.props.inlineMenu === "none") return null;
        if(!this.props.all_contents || this.props.all_contents.length < 1) return null;
        const fontStyle = getFontStyleFromFeed(this.props.feeds);
        let parentClasses = "video-inlineMenu";
        let menu = 'menu';
        if(!this.state.showInlineMenu) {
          parentClasses = parentClasses + " closedMenu";
        }
        if(this.state.isClosing) {
          parentClasses = parentClasses + " closingNow";
        }
        if(isSmartTv() && config.use_light_sliders) {
          menu = 'slick_inline_menu';
        }

        return (
          <FocusLock>
            <div id="video-inlineMenu" style={fontStyle.primaryFont} className={parentClasses}>
                <div className="button-toggle">
                  { (this.state.isClosing || this.state.isOpening)
                    ? <img alt="Menu" src={this.state.isClosing ? downArrow : upArrow}/>
                    : <img alt="Menu" src={this.state.showInlineMenu ? downArrow : upArrow} onClick={() => this.handleInlineMenu()}/>
                  }
                </div>
                {this.state.showInlineMenu
                    ? <div className={menu}>
                        {this.renderMenu()}
                        </div>
                    : <div className="menu not-visible" style={{ width: 'max-content'}}></div>
                }
            </div>
          </FocusLock>
        )
    }
}

export default InlineMenu;