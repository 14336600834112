import React from 'react'

function PlayIcon(props) {
    return (
        <div className={`play-icon ${props.className || ""}`}>
            <svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="2 2 20 20" width="30" fill="white">
                <path d="M0 0h24v24H0z" fill="none"/><path d="M8 5v14l11-7z"/>
            </svg>
        </div>
    )
}

export default PlayIcon
