import React from 'react'
import { Link } from 'react-router-dom'
import StandardTitle from "./StandardTitle";
import { connect } from "react-redux"
import { openModal } from "../actions/modalActions"
import { correctPath, httpsUrl } from "../constants/common"
import isAvailable from '../helperfunctions/is_available'
import * as analytics  from '../constants/analytics';
import configuration from '../constants/config'
import { getTabIndex, isAppRunningOnLowEndPlatform } from '../helperfunctions/common';
import { getParentFeed } from '../helperfunctions';
import ItemLockOverlay from './ItemLockOverlay';
import Image from './Image';
import ContentBadges from './ContentBadges';
import { updateSearchRouteEntry } from '../actions/searchActions';

class ThumbnailSearch extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      hover: false,
      sourceClass: '',
    }
  }

  onMouseEventHandler(hover, sourceClass) {
    var elem = document.getElementsByClassName("searchContent hovered")[0]
    if(elem){
      elem.classList.remove("hovered")
    }
    if(hover){
      this.setState({
        hover: hover,
        sourceClass: sourceClass,
      });
    } else {
      this.setState({
        hover: hover,
        sourceClass: sourceClass
      })
    }
  }

  appendingParentContent = (content, parentDetails) => {
    return {
      ...content,
      locked: parentDetails.locked,
      access: parentDetails.access,
      special: parentDetails.special
    }
  }

  openModal(e){
    let content = this.props.content;
    const commonModalParams = {head: "search", identifier: this.props.content.identifier, content: content,
    all_contents: this.props.all_contents, pathname: window.location.pathname, firstpath: "/apps/"+ configuration.app_id +"/search/" + this.props.content.identifier};
    let modalParams;
    if (this.props.content.type === "vid") {
      modalParams = {...commonModalParams, type: "video"}
    } else if(this.props.content.type === "image"){
      modalParams = {...commonModalParams, type: "image"}
    } else if((this.props.content.type === "live" || this.props.content.type === "fake") && configuration.isAppTvodType) {
      modalParams = {...commonModalParams, type: this.props.content.type, feeds: this.props.feeds, section: this.props.content, openLiveModalDirectly: true}
    }
    const url = `${content.lineage}/${content.identifier}`;
    const parentDetails = getParentFeed(this.props.feeds, url)
    e.preventDefault();
    if(content.type === "menu") {
      this.props.updateSearchRouteEntry([`/apps/${configuration.app_id}/${url}`])
      this.props.onMenuClick(url);
      return;
    }
    if (!configuration.isAppTvodType && parentDetails && parentDetails.showInterstitial) {
      this.props.openModal({
        type: 'interstitialScreen',
        modalProps: {
          ...modalParams,
          content: this.appendingParentContent(content, parentDetails)
        },
        thumbnailProps: {
          background: parentDetails.background,
        }
      })
    } else {
      this.props.openModal(modalParams);
    }
  }

  itemLockIconOverlay = () => {
    return (
      <ItemLockOverlay
        feeds={this.props.feeds}
        content={this.props.content}
      />
    )
  }

  renderContentBadge() {
    return <ContentBadges content={this.props.content}/>
  }

  renderImage() {
    const { cover, previewImage, identifier } = this.props.content;
    const image = previewImage || cover;
    if (!image) return null;
    return (
      <Image
        classNames={"img-thumb image-content-" + identifier}
        cover={image}
      />
    )
  }

  removeTransition() {
    if(isAppRunningOnLowEndPlatform()) {
      return 'remove-transition';
    }
    return '';
  }
  
  playVideoOnHover() {
    if(configuration.isAppTvodType || isAppRunningOnLowEndPlatform()) {
      return null
    }
    return (
      <video className={"video-thumb video-content-" + this.props.content.identifier} preload="10" autoPlay muted loop >
        <source src={httpsUrl(this.props.content.contentUrl)} type="video/mp4"></source>
      </video>
    );    
  }

  render(){
    if(this.props.remember[this.props.content.identifier]){
      var played = this.props.remember[this.props.content.identifier]["p"]
      var total = this.props.remember[this.props.content.identifier]["t"]
      var style = {
        width: played*100/total + "%",
        backgroundColor: this.props.secondary ? this.props.secondary.replace('#FF','#') : "#fff"
      }
    }
    if(this.props.content.type === "live") {
      style = {};
    }
    if(this.state.hover){
      return(
        <div className={"searchContent item-" + this.props.content.identifier}>
          <a tabIndex={getTabIndex()} href="" onClick={(event) => this.openModal(event)} className={`thumbLink thumbHover focusable ${this.removeTransition()}`} aria-label={this.props.content.title}>
            <div style={{backgroundColor: (this.props.secondary ? this.props.secondary.replace('#FF','#') : "#fff")}} className="img-video" onMouseEnter={(hover, sourceClass) => this.onMouseEventHandler(true, "image-content-" + this.props.content.identifier)} onMouseLeave={(hover, sourceClass) => this.onMouseEventHandler(false, "image-content-" + this.props.content.identifier)}>
              {this.props.remember[this.props.content.identifier] && <span className="remSpot" style={style}></span>}
              {this.renderImage()}
              {this.playVideoOnHover()}
              {this.renderContentBadge()}
              {this.itemLockIconOverlay()}
            </div>
          </a>
          <StandardTitle no_color={true} locked={this.props.locked && !isAvailable(this.props.content.identifier, this.props.content.special)} secondary={this.props.secondary} content={this.props.content} type={"search"} feeds={this.props.feeds} />
        </div>
      )
    } else {
      return (
        <div className={"searchContent item-" + this.props.content.identifier}>
          <a tabIndex={getTabIndex()} href="" onClick={(event) => this.openModal(event)} className={`thumbLink focusable ${this.removeTransition()}`}
            onFocus={(hover, sourceClass) => this.onMouseEventHandler(true, "image-content-" + this.props.content.identifier)}
            onBlur={(hover, sourceClass) => this.onMouseEventHandler(false, "image-content-" + this.props.content.identifier)}          
            aria-label={this.props.content.title}            
          >
            <div style={{backgroundColor: this.props.primary ? this.props.primary.replace('#FF','#') : "#fff"}} className="img-video"
              onMouseEnter={(hover, sourceClass) => this.onMouseEventHandler(true, "image-content-" + this.props.content.identifier)}
              onMouseLeave={(hover, sourceClass) => this.onMouseEventHandler(false, "image-content-" + this.props.content.identifier)}
            >
              {this.props.remember[this.props.content.identifier] && <span className="remSpot" style={style}></span>}
              {this.renderImage()}
              {this.renderContentBadge()}
              {this.itemLockIconOverlay()}
            </div>
            
          </a>
          <StandardTitle no_color={true} locked={this.props.locked && !isAvailable(this.props.content.identifier,this.props.content.special)} secondary={this.props.secondary} content={this.props.content} type={"search"} feeds={this.props.feeds} />
        </div>
      )
    }
  }
}

const mapStateToProps = (state) => ({
  remember: state.remember.remember,
  modal: state.modal.modal,
  subscription: state.user.subscription
})

const mapDispatchToProps = {
  openModal: openModal,
  updateSearchRouteEntry: updateSearchRouteEntry
}

export default connect(mapStateToProps, mapDispatchToProps)(ThumbnailSearch);
