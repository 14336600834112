import React from 'react'

function PauseIcon(props) {
    return (
        <div className={`pause-icon ${props.className}`}>
            <svg xmlns="http://www.w3.org/2000/svg" height="200px" viewBox="0 0 24 24" width="200px" fill="#000000">
                <path d="M0 0h24v24H0V0z" fill="none"/><path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z"/>
            </svg>
        </div>
    )
}

export default PauseIcon
