import ReactGA from 'react-ga';

import configuration from './config';
import * as common  from './common';
import { getCurrentPlatform, isSmartTv } from '../helperfunctions/common';
import { getLocalStorage, getUserCookie, setLocalStorage } from '../helperfunctions/storage';
import { getSessionStorage, setSessionStorage } from '../helperfunctions/storage';
import packageJson from '../../package.json';

export const analytics_ga4 = configuration.kFirebaseAnalyticsEnabled && configuration.kGA4ApiKey && configuration.kFirebaseMeasurementID;

function getDimesion1ForAnalytics() {
	const platform = configuration.platform;
	if (platform === common.PLATFORM_WEB)
		return 'Web';
	if (platform === common.PLATFORM_WEB_PREVIEW)
		return 'Preview';
	if (platform === common.PLATFORM_LG)
		return 'lg_tv';
	if (platform === common.PLATFORM_SAMSUNG)
		return 'samsung_tv';
	if (platform === common.PLATFORM_HTML_SIMULATOR)
		return 'simulator';
	if (platform === common.PLATFORM_VIZIO)
		return 'vizio_tv';
	if (platform === common.PLATFORM_XBOX)
		return 'xbox';
	if (platform === common.PLATFORM_ZEASN)
		return 'zeasn';
}

export function initializeGoogleAnalytics() {
	if (isSmartTv()) {
		ReactGA.initialize(configuration.ga_tracking_code, { titleCase: false});
		window.ga('create', configuration.ga_tracking_code, 'auto');
		window.ga('set', 'checkProtocolTask', null); 
		window.ga('set', 'checkStorageTask', null); 
		window.ga('set', 'historyImportTask', null); 
	} else {
		ReactGA.initialize(configuration.ga_tracking_code, { titleCase: false});
	}
}

export function setActiveUsersData(){
	if(analytics_ga4) {
		const params = getGA4CommonParams();
		params.Label = "App Launched";
		logEvent("App_Launched", params);
	}
	if (isSmartTv()) {
		window.ga("create", configuration.ga_active_user_tracking, {name: "user_tracker"});
		window.ga('user_tracker.set', 'checkProtocolTask', null); 
		window.ga('user_tracker.set', 'checkStorageTask', null); 
		window.ga('user_tracker.set', 'historyImportTask', null); 
		window.ga("user_tracker.set", {dimension1: getDimesion1ForAnalytics()});
		window.ga("user_tracker.send", {
			hitType: "event",
			eventCategory: "MAZ Internal",
			eventAction: "App Launched",
			eventLabel: "App Launched"
		})
	} else {
		ReactGA.initialize([
				{
				trackingId: configuration.ga_active_user_tracking,
				gaOptions: { name: 'user_tracker' },
				}
		]);
		ReactGA.set({ dimension1: getDimesion1ForAnalytics() }, ['user_tracker']);
		ReactGA.event({
			category: "MAZ Internal",
				action: "App Launched",
			label: "App Launched"
		}, ['user_tracker']);
	}
}

export function setPageviewContent(feeds, path) {
	var responseArray = getTitleArrayForContent(feeds, path);
	var screenName;
	if(responseArray[1]){
		screenName = 'Locked | '+ responseArray[0].join(' | ');
	}else{
		screenName = responseArray[0].join(' | ');
	}
	if(analytics_ga4) {
		const params = getGA4CommonParams();
		params.Label = screenName
    logEvent("Screen_View", params);
	}
	initializeGoogleAnalytics();
	ReactGA.set({ dimension1: getDimesion1ForAnalytics() }); 
	ReactGA.set({ dimension2: null });
	ReactGA.pageview(screenName);
}


export function setPageViewSections(feeds, path) {
	var responseArray = getTitleArrayForMenus(feeds, path);
	var screenName;
	if(responseArray[1]){
		screenName = 'Locked | '+ responseArray[0].join(' | ');
	}else{
		screenName = responseArray[0].join(' | ');
	}
	if(analytics_ga4) {
		const params = getGA4CommonParams();
		params.Label = screenName
    logEvent("Screen_View", params);
	}
	initializeGoogleAnalytics();
	ReactGA.set({ dimension1: getDimesion1ForAnalytics() }); 
	ReactGA.set({ dimension2: null }); 
	ReactGA.pageview(screenName);
}

export function setPageViewFixedSections(fixedSectionName){
	if(analytics_ga4) {
		let params = getGA4CommonParams();
		const name = "Screen_View";
		params.Label = fixedSectionName;

		if(fixedSectionName === "User Activity Beacon"){
			params = { ...params, engagement_time_msec : 240 * 1000 }
		}
    	logEvent(name, params);
	}
	initializeGoogleAnalytics();
	ReactGA.set({ dimension1: getDimesion1ForAnalytics() }); 
	ReactGA.set({ dimension2: null }); 
	ReactGA.pageview(fixedSectionName);
}


export function setEvent(eventCategory, eventAction, eventLabel, contentUrl = null) {
	if(analytics_ga4) {
		const params = getGA4CommonParams();
		params.Category = eventCategory;
		params.Label = eventLabel;
		if(contentUrl && contentUrl.length < 100) {
			params.ContentUrl = contentUrl;
		}
    logEvent(getActionNormalise(eventAction), params);
	}
	initializeGoogleAnalytics();
	ReactGA.set({ dimension1: getDimesion1ForAnalytics() });
	ReactGA.set({ dimension2: contentUrl }); 
	ReactGA.event({
		category: eventCategory,
			action: eventAction,
			label: eventLabel
	});
}

export function setEventForContent(feeds, path, eventCategory, eventAction, videoTitle, contentUrl = null){
	var responseArray = getTitleArrayForMenus(feeds, path);
    var screenName = responseArray[0].join(' | ');
	if(responseArray[1]){
		eventAction = eventAction + ' (locked)';
	}else{
		eventAction = eventAction + ' (unlocked)';
	}
	const label = responseArray[2] ? screenName + ' | ' + videoTitle : screenName
	if(analytics_ga4) {
		const params = getGA4CommonParams();
		params.Category = eventCategory;
		params.Label = label;
		if(contentUrl && contentUrl.length < 100) {
			params.ContentUrl = contentUrl;
		}
    logEvent(getActionNormalise(eventAction), params);
	}
	initializeGoogleAnalytics();
	ReactGA.set({ dimension1: getDimesion1ForAnalytics() }); 
	ReactGA.set({ dimension2: contentUrl }); 
	ReactGA.event({
	category: eventCategory,
		action: eventAction,
		label: label
	});
}

export function setEventForWatchedVideo(contentUrl, feeds, path, videoItem, duration, videoPlayCount = null) {
	let title = "";
	const videoTitle = getVideoTitleForEvents(videoItem);
	if(path.includes("search")) {
		title = "search | " + videoTitle;
	} else if (path.includes("saved_items")) {
		title = "saved | " + videoTitle;
	} else {
		let responseArray = getTitleArrayForMenus(feeds, path);
		let screenName = responseArray[0].join(' | ');
		title = screenName;
		if(videoPlayCount !== null) {
			title = videoPlayCount + ' | ' + screenName + ' | ' + videoTitle;
		} else if(responseArray[2]) {
			title = screenName + ' | ' + videoTitle;
		}
	}
	if(analytics_ga4) {
		const params = getGA4CommonParams();
		params.Category = "Video";
		params.Label = title;
		params.value = duration;
		if(contentUrl && contentUrl.length < 100) {
			params.ContentUrl = contentUrl;
		}
    logEvent(getActionNormalise("Total Watched Time (in seconds)"), params);
	}
	initializeGoogleAnalytics();
	ReactGA.set({ dimension1: getDimesion1ForAnalytics() }); 
	ReactGA.set({ dimension2: contentUrl });
	ReactGA.event({
		category: "Video",
		action: "Total Watched Time (in seconds)",
		label: title,
		value: duration
	});
}


export function setEventForSimlive(feeds, path, eventCategory, eventAction, videoItem, videoPlayCount, contentUrl = null){
	var responseArray = getTitleArrayForMenus(feeds, path);
  var screenName = videoPlayCount + ' | '+responseArray[0].join(' | ') + ' | ' + getVideoTitleForEvents(videoItem);
	if(responseArray[1]){
		eventAction = eventAction + ' (locked)';
	}else{
		eventAction = eventAction + ' (unlocked)';
	}
	if(analytics_ga4) {
		const params = getGA4CommonParams();
		params.Category = eventCategory;
		params.Label = screenName;
		if(contentUrl && contentUrl.length < 100) {
			params.ContentUrl = contentUrl;
		}
    logEvent(getActionNormalise(eventAction), params);
	}
	initializeGoogleAnalytics();
	ReactGA.set({ dimension1: getDimesion1ForAnalytics() }); 
	ReactGA.set({ dimension2: contentUrl }); 
	ReactGA.event({
		category: eventCategory,
			action: eventAction,
			label: screenName
	});
}


export function getTitleArrayForContent(feeds, path){
	var identifierArray = path.split('/');
	var titleHierarchy = [];
	var sections = feeds.sections;
	var locked;;
 	for(var i=3; i<identifierArray.length; i++){
 		for(var j=0 ; j < sections.length; j++ ){
 			if(identifierArray[i] === sections[j].identifier ){
 				titleHierarchy.push(getVideoTitleForEvents(sections[j]))
 				if(i === identifierArray.length-2 && sections[j].locked){
 					locked = true;
				}
				if(i === identifierArray.length-1 && sections[j].locked){
					locked = true;
				}
 				
 				if(sections[j].contents) {
					sections = sections[j].contents;
				}
				break;
 			}
 		}
  	}
  	return [titleHierarchy, locked];
}

export function getTitleArrayForMenus(feeds, path){
	var identifierArray = path.split('/');
	var titleHierarchy = [];
	var sections = feeds.sections;
	var locked;
	var mazId=false;

	for(var i=2; i<identifierArray.length; i++){
 		for(var j=0 ; j < sections.length; j++ ){
 			if(identifierArray[i] === sections[j].identifier ){
 				titleHierarchy.push(getVideoTitleForEvents(sections[j]))
 				if(i === identifierArray.length-2 && sections[j].locked){
 					locked = true;
				}
				if(i === identifierArray.length-1 && sections[j].locked){
					locked = true;
			   	}
				if(i===identifierArray.length-2 && sections[j].mazIDSectionType){
 					mazId = true;
 				}
				if(sections[j].contents) {
					sections = sections[j].contents;
				}
				break;
 			}
 		}
  	}
  	return [titleHierarchy, locked, mazId];
}

function getGA4CommonParams() {
	const user = getUserCookie();
	let profileParam = {};
	if(configuration.isAppTvodType) {
		let pId = "";
		if(user && user.profiles && user.profiles.length > 0) {
			pId = user.profiles[0].identifier || "";
		}
		profileParam = { Profile_Id: pId }
	} else {
		const uId = (user && user.user_id) ? user.user_id : "";
		profileParam = { User_Id: uId }
	}
	return {
		App_Id: configuration.app_id,
		Platform_Type: getCurrentPlatform(),
		App_Name: configuration.title,
		...profileParam
	}
}

function convertToTitlecase (title) {
	if(!title) return "";

	try {
		const regex = /(\b[a-z](?!\s))/g;
		title = title.replace(regex, function(letter) {
			return letter.toUpperCase();
		})
		return title;
	} catch {
		return title;
	}
}

function getActionNormalise(action) {
	if(!action) return '';
	action = action.replace(/>/g, "greater than");
	action = convertToTitlecase(action);
	action = action.replace(/ - /g, "_");
	action = action.replace(/ /g, "_");
	action = action.replace(/[^a-zA-Z0-9_]/g,'');
	return action;
}

function logEvent(view, params) {
	const measurement_id = configuration.kFirebaseMeasurementID;
	const api_secret = configuration.kGA4ApiKey;
	const app_name = configuration.title
    const platform_name = getDimesion1ForAnalytics();
	const session_id = gaUniqueSessionTimestamp(); 
	const app_version = configuration.app_version || packageJson.version;

	params = {...params, session_id: session_id, App_Version: app_version};

	fetch(`https://www.google-analytics.com/mp/collect?measurement_id=${measurement_id}&api_secret=${api_secret}`, {
		method: "POST",
		body: JSON.stringify({
			client_id: createGA4ClientId(),
			user_id: userId(),
			events: [{
				name: view,
				params: params,
			}],
			user_properties: { "app_name": { "value": `${app_name}` }, "platform_name": { "value": `${platform_name}` }, "app_version": { "value": `${app_version}` } }
		})
	})
	.then(res => {})
	.catch(err => {
		console.log("error occured!");
	})
}

function gaUniqueSessionTimestamp(){
	const currentDate = new Date();
	let sessionId = getSessionStorage('gaUniqueSessionTimestamp')
    
	if(!sessionId){
		sessionId = Math.floor(currentDate.getTime() / 1000);
		setSessionStorage('gaUniqueSessionTimestamp', `${sessionId}`);
	}

	return sessionId;
}

function userId() {
	const user = getUserCookie();
	let user_id  = "";

	if(configuration.isAppTvodType) {
		if(user && user.profiles && user.profiles.length > 0) {
			user_id = user.profiles[0].identifier || "";
		}
	} else {
		user_id = (user && user.user_id) ? user.user_id : "";
	}

	user_id = user_id || guestId();

	return user_id;
}

function guestId(){
	return "Guest" + createGA4ClientId().slice(-10); 
}

function createGA4ClientId() {
	const key = `ga4_client_id_${configuration.app_id}`
	let client_id = getLocalStorage(key);
	if(client_id) return client_id;

	client_id = '';
	for(let i=0; i < 20; i++) {
		client_id = `${client_id}${Math.floor(Math.random() * 10)}`;
		if(i == 9) {
			client_id = client_id + ".";
		}
	}
	setLocalStorage(key, client_id);
	return client_id;
}
/*

export function gtagSetter(){
    const gtagID = configuration.kFirebaseMeasurementID
    const app_name = configuration.title
    const platform_name = getDimesion1ForAnalytics()

	function gtagExecuter() {
		window.dataLayer = window.dataLayer || [];
		function gtag(){dataLayer.push(arguments);}
		gtag('js', new Date());
		gtag('config', `${gtagID}`);
		gtag('set', 'user_properties', { 'app_name': `${app_name}`, 'platform_name': `${platform_name}` });
	}

	loadJsScript({
		url: `https://www.googletagmanager.com/gtag/js?id=${gtagID}`,
		location: document.head,
		type_to_set: 'async',
		onLoadMethod: gtagExecuter
	});
}

*/

export function getVideoTitleForEvents(videoItem) {
	if(!videoItem) return "";

	const { title, subtitle, summary } = videoItem;
	if(title) {
		return title;
	} else if(subtitle) {
		return subtitle;
	} else if(summary) {
		return summary;
	} else {
		return "*hidden*";
	}
}