import React, {Component} from "react";
import { connect } from 'react-redux'
import videojsPlaylistPlugin from 'videojs-playlist';
import videojs from 'video.js';

videojs.registerPlugin('playlist', videojsPlaylistPlugin);


class PreviewVideo extends Component {

  componentDidMount(){
    this.player = videojs("spotlight-preview",{errorDisplay: false, loadingSpinner: false, preload: 'auto',
      muted: this.props.preview_feed.muted})
    this.player.playlist(this.props.preview_feed.video_list);
    this.player.playlist.autoadvance(0);
    /**
       * Playing video when it is ready, If preview not play then mute the video and try again.
       */
    videojs("spotlight-preview") && this.player.ready(() => {
      const promise = this.player.play();
      promise && promise
      .catch((err) => {
        try {
          if (this.player && this.player.muted && this.player.play && videojs("spotlight-preview")) {
            this.player.muted(true);
            this.player.play()
          }
        }
        catch(err) {
        }
      })
    });
  }

  componentWillUnmount() {
    if (this.player) {
      try {
        this.player.dispose()
      }
      catch(err) {
      }
    }
  }

  render(){
    return (
      <div id = "previewVideo">
        <video
          id="spotlight-preview"
          className="object-cover h-full w-full"
        >
        </video>
        <div
          id="previewShadow"
        >
        </div>
      </div>
    );
  }
}

export default PreviewVideo; 