import React from 'react';
import Swiper from 'react-id-swiper'
import { connect } from "react-redux"
import { getSavedItems, deleteItem } from '../actions/itemActions'
import Thumbnail from './Thumbnail'
import GridContent from '../components/GridContent'
import { openModal } from '../actions/modalActions'
import StandardTitle from "./StandardTitle";
import { environment } from "../constants/initializer"
import JumboContent from '../components/JumboContent'
import DetailContent from '../components/DetailContent'
import { getParentFeed } from '../helperfunctions';
import { checkIfOverlayVideoIsPresent, isFeedAvailable, isSmartTv } from '../helperfunctions/common';
import configuration from '../constants/config';
import JsSpatialNavigation from '../ctvnavigation/js-spatial-navigation';


const Loader = (props) => <div className="loader"><img src={"https://s3.amazonaws.com/resources.magappzine.com/assets/core/" + props.environment + "/loader.gif"}/></div>

class SavedVideos extends React.Component {
    openModal(e){
        if(this.props.items.content[0].type === "vid"){
          this.props.openModal({head: "saved_items", type: "video", identifier: this.props.items.content[0].identifier,
            content: this.props.items.content[0], all_contents: this.props.items.content,
            pathname: window.location.pathname, firstpath: window.location.pathname + "/saved_items/" + this.props.items.content[0].identifier})
        } else if(this.props.items.content[0].type === "image"){
          this.props.openModal({head: "saved_items", type: "image", identifier: this.props.items.content[0].identifier,
            content: this.props.items.content[0], all_contents: this.props.items.content,
            pathname: window.location.pathname, firstpath: window.location.pathname + "/saved_items/" + this.props.items.content[0].identifier})
        }
      e.preventDefault()
    }

    componentDidMount() {
        if(isSmartTv()) {
            JsSpatialNavigation.set('navbar', {
                leaveFor: {}
            });
        }
    }

    componentDidUpdate() {
        //If overlay video is present, add noNav class, only for simulator
        if(document.querySelector('.simulator-route-container') && checkIfOverlayVideoIsPresent()) {
            document.body.classList.add("noNav");
        }
        if(isSmartTv()) {
            if(JsSpatialNavigation.sectionExist(`section_${this.props.section.identifier}`)) {
                setTimeout(() => {
                    JsSpatialNavigation.focus(`section_${this.props.section.identifier}`);
                } , 0);
            } else {
                setTimeout(() => {
                    JsSpatialNavigation.focus();
                }, 0);
            }
        }
    }

    render() {
        const params = {
            mousewheelControl: true,
            slidesPerView: 5,
            spaceBetween: 40,
            mousewheelForceToAxis: true,
            freeMode: true,
            breakpoints: {
                1400: {
                    slidesPerView: 3.5,
                    spaceBetween: 28
                }
            }
        }
        var contents = this.props.items.content;
        let wrapSavedSectionItems = this.props.feeds.extras && this.props.feeds.extras.wrapSavedSectionItems;
        const content_array = []
        if(this.props.loader){
            return (<Loader environment={environment()} />)
        } else {
            if(this.props.items.content.length > 0){
                contents.map(function(content, i) {
                    if(!isFeedAvailable(this.props.policyData.country, this.props.feeds.countryGroups, content.countryGroupId)) return;
                    const parent = getParentFeed(this.props.feeds, `${content.lineage}/${content.identifier}`);
                    if(parent && parent.type === "menu") {
                        content["locked"] = parent.locked
                        if(!configuration.isAppTvodType) {
                            content["access"]= parent.access
                        }
                        content["special"]= parent.special
                        content["displayTitle"]= parent.displayTitle
                        content["registerWall"]= parent.registerWall
                        content["parent_id"]= parent.identifier
                        content["thumbOrientation"]= this.props.section.thumbOrientation
                        content["showInterstitial"] = this.props.section.showInterstitial || content.showInterstitial;
                    } 
                    if(configuration.isAppTvodType && content.parentMenu ) {
                        content["showInterstitial"] = content.parentMenu.showInterstitial;
                    }
                    content["inlineMenu"]= this.props.feeds.inlineMenu || ""; // inline menu in saved as set at app level
                    content_array.push(
                        <div key={content.identifier} className={"detail-swiper item-" + content.identifier }>
                            <Thumbnail 
                                feeds={this.props.feeds} 
                                all_contents={contents} 
                                primary={this.props.feeds.primaryColor} 
                                secondary={this.props.feeds.secondaryColor} 
                                content={content} 
                                head={"saved_items"} 
                                detail={this.props.section.regularLayout === "detail"} 
                                layout={this.props.section.regularLayout}
                            />
                            <StandardTitle no_color={this.props.section.regularLayout !== "jumbo"} content={content} type={"saved_item"} secondary={this.props.feeds.secondaryColor} feeds={this.props.feeds} />
                        </div>
                    )
                }, this)
                let content = this.props.section
                content.contents = contents
                if (this.props.section.regularLayout === "jumbo"){
                return (
                    <div className={ wrapSavedSectionItems ? `saved-jumboitems-wrapper` : ''}>
                        <JumboContent showingSavedSection={true} key={`saved-section-jumbo-${this.props.section.identifier}`} title={this.props.section.title} contents={content_array} content={this.props.section} feeds={this.props.feeds}/>
                    </div>
                )}if(this.props.section.regularLayout === "detail"){
                  return(
                    <div>
                      <DetailContent showingSavedSection={true} key={`saved-section-detail-${this.props.section.identifier}`} secondary={this.props.feeds.secondaryColor || "#FFFFFF"} content={content}  title={this.props.section.title} contents={content_array} feeds={this.props.feeds} detail_content={contents} />
                    </div>
                  )}else{
                  return <div className={ wrapSavedSectionItems ? `saved-items-wrapper` : ''}>
                  <GridContent showingSavedSection={true} key={`saved-section-grid-${this.props.section.identifier}`} title={this.props.section.title} contents={content_array} content={this.props.section} feeds={this.props.feeds}/>
                  </div>
                }
            } else {
                return (
                    <div>
                        <div className="invalidScreen">
                            <div className="invalidContent noItem">
                                <p className="inv-head" style={{color: this.props.feeds.savedUIMetadata.textColor}}>{this.props.feeds.savedUIMetadata.emptyText}</p>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    }
}


const mapStateToProps = (state) => ({
    items: state.items.items,
    loader: state.items.loader,
    user_id: state.items.user_id,
    policyData: state.feeds.policyData
})

const mapDispatchToProps = {
    getSavedItems: getSavedItems,
    deleteItem: deleteItem,
    openModal: openModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(SavedVideos)
