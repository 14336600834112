import axios from "axios";
import { getEnvironmentUrl, getMazCoreEnvUrl, getTvodEndpoints, getTvodFeedEndpoints, getTvodPreviewFeedEndpoints } from '../constants/initializer';
import { checkCorrectPlatform } from "../helperfunctions/common";
import { getLocalStorage } from "../helperfunctions/storage";
import { PLATFORM_WEB_PREVIEW } from "./common";
import configuration from "./config";

const TVOD_TYPE = configuration.isAppTvodType || false;

export const GET_USER_SUBSCRIPTION_API = TVOD_TYPE ? `${getTvodEndpoints()}/v1/entitlements` : `${getMazCoreEnvUrl()}/payment_connect/get_purchases`;
export const CREATE_PURCHASE_SESSION_ID_API = TVOD_TYPE ? `${getTvodEndpoints()}/v1/checkout_sessions` : `${getMazCoreEnvUrl()}/payment_connect/create_session`;
export const CANCEL_SUBSCRIPTION_API = `${getMazCoreEnvUrl()}/payment_connect/cancel_subscription`;
export const RENEW_SUBSCRIPTION_API = `${getMazCoreEnvUrl()}/payment_connect/update_subscription`;

export const USER_INFO_API = `${getMazCoreEnvUrl()}/user_sync/api/v1/app_users/`;

export const USER_SIGN_API =  TVOD_TYPE ? `${getTvodEndpoints()}/app_users/sign_in` : `${getMazCoreEnvUrl()}/user_sync/api/v2/app_users/sign_in`;
export const USER_PROFILE_API =  `${getTvodEndpoints()}/v1/profiles`;
export const USER_SIGNUP_API = TVOD_TYPE ? `${getTvodEndpoints()}/app_users` : `${getMazCoreEnvUrl()}/user_sync/api/v2/app_users/`;
export const USER_LOGOUT_API = `${getTvodEndpoints()}/app_users/logout`;
export const USER_FORGOT_PASSWORD_API = TVOD_TYPE ? `${getTvodEndpoints()}/app_users/password` : `${getMazCoreEnvUrl()}/user_sync/api/v2/app_users/password`;
export const FB_LOGIN_SIGNUP = `${getMazCoreEnvUrl()}/user_sync/api/v2/app_users`
export const WEBSITE_LOGIN = TVOD_TYPE ? `${getTvodEndpoints()}/device_codes` : `${getMazCoreEnvUrl()}/user_sync/api/v2/app_users/create_token`;

export const FB_GET_CODE = `https://graph.facebook.com/v8.0/device/login`;
export const FB_POLLING_URL = `https://graph.facebook.com/v8.0/device/login_status`;
export const FB_GET_USER_DATA = `https://graph.facebook.com/v8.0/me`;

export const USER_IP = 'https://ipv4.jsonip.com/' // third party service
export const USER_GEO = ''; // get user location
export const LOCATION_FROM_IP = `${getEnvironmentUrl()}/api/v2/geolocation`;

export const TVOD_STREAMS_API = `${getTvodEndpoints()}/v1/streams`;
export const TVOD_STREAMS_ANONYMOUS_API = `${getTvodEndpoints()}/v1/streams/anonymous`;
export const TVOD_SAVE_API = `${getTvodEndpoints()}/v1/saved_items`;
export const TVOD_SEARCH_API = `${getTvodEndpoints()}/v1/search`;
export const TVOD_POLICY_API = `${getTvodEndpoints()}/policy`;
export const getTvodPreviewLocales = (app_id, token) => `${getTvodPreviewFeedEndpoints()}/${app_id}/feeds/v1/locales?key=${token}`;
export const getTvodAppFeedUrl = (app_id) => `${getTvodFeedEndpoints()}/${app_id}/feeds/v1/tv_app_feed`;
export const getTvodOneFeedUrl = (app_id, locale_id, language) => `${getTvodFeedEndpoints()}/${app_id}/${locale_id}/${language}/feeds/v1/tv_one_feed`;
export const getTvodEncryptedAppFeedUrl = (app_id, locale_id, language) => {
  if(checkCorrectPlatform([PLATFORM_WEB_PREVIEW])) {
    const token = getLocalStorage("preview_token");
    return `${getTvodPreviewFeedEndpoints()}/${app_id}/${locale_id}/${language}/feeds/v1/tv_app_feed_encrypted?key=${token}&preview=true`;
  }
  return `${getTvodFeedEndpoints(true)}/${app_id}/${locale_id}/${language}/feeds/v1/tv_app_feed_encrypted`;
}
export const getTvodEncryptedOneFeedUrl = (app_id, locale_id, language) => {
  if(checkCorrectPlatform([PLATFORM_WEB_PREVIEW])) {
    const token = getLocalStorage("preview_token");
    return `${getTvodPreviewFeedEndpoints()}/${app_id}/${locale_id}/${language}/feeds/v1/tv_one_feed_encrypted?key=${token}&preview=true`;
  }
  return `${getTvodFeedEndpoints(true)}/${app_id}/${locale_id}/${language}/feeds/v1/tv_one_feed_encrypted`;
}

export const VALIDATE_TOKEN_API = `${getTvodEndpoints()}/device_codes/validate`;
export const AUTHENTICATE_DEVICE_API = `${getTvodEndpoints()}/device_codes/sign_in`;
export const CLEENG_SIGN_IN = `${getTvodEndpoints()}/app_users/cleeng`;
export const TVOD_DYNAMIC_LIST = `${getTvodEndpoints()}/v1/collections`;
export const TVOD_STRIPE_API = `${getTvodEndpoints()}/v1/stripe`;
export const TVOD_SUBSCRIPTION_LIST = `${getTvodEndpoints()}/v1/stripe/subscriptions`

export const VIZIO_SIGNATURE_API = TVOD_TYPE ? `${getTvodEndpoints()}/v1/vizio` : `${getMazCoreEnvUrl()}/payment_connect/get_purchases`;

export const ADOBE_TVE_API = 'https://api.auth.adobe.com'
export const ADOBE_TVE_REGISTRATION_API = ADOBE_TVE_API + '/o/client/register'
export const ADOBE_TVE_ACCESS_TOKEN_API = ADOBE_TVE_API + '/o/client/token'

export const TVOD_PARENTAL_LOCK_CODE = `${getTvodEndpoints()}/v1/parental_access`;
export const TVOD_PARENTAL_LOCK_CODE_VALIDATE = `${getTvodEndpoints()}/v1/parental_access/validate`;
export const TVOD_PARENTAL_LOCK_CODE_RESET = `${getTvodEndpoints()}/v1/parental_access/reset_email`;
