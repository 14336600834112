import React, { useEffect, useState } from 'react';

import configuration from '../constants/config';
import { isSmartTv } from '../helperfunctions/common';

let timer;
export function InstructionText(props) {
  const { feeds: { streamOnEntryTextColor, streamOnEntryInstructionsText }, runningOnMobile, playing_Ad } = props;
  const [showText, setShowText] = useState(true);

  useEffect(() => {
    if(timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      setShowText(false);
    }, 5000)

    return () => {
      if(timer) {
        clearTimeout(timer);
      }
    }
  }, [])

  useEffect(() => {
    if(timer) {
      clearTimeout(timer);
    }
    if(showText && !playing_Ad) {
      timer = setTimeout(() => {
        setShowText(false);
      }, 5000)
    }
  }, [playing_Ad])

  if(!configuration.isAppTvodType) return null;
  if(!showText || playing_Ad) return null;

  const customStyle = runningOnMobile ? {
    maxWidth: "90%",
    right: "0px",
    left: "0px",
    margin: "auto",
    top: "50px",
    color: streamOnEntryTextColor ? streamOnEntryTextColor.replace('#FF', '#') : "#fff"
  } : {
    top: isSmartTv() ? "130px" : "120px",
    maxWidth: "600px",
    width: "max-content",
    color: streamOnEntryTextColor ? streamOnEntryTextColor.replace('#FF', '#') : "#fff"
  }
  return streamOnEntryInstructionsText ? (
      <div className="prevThumbnail video-title ellipsis streamOnEntryText" style={customStyle}>
        <p className="" style={{color: customStyle.color}}> <b>{streamOnEntryInstructionsText}</b></p>
      </div>
  ) : <></>
}