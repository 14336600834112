import React, {Component} from "react";
import {isSmartTv } from '../helperfunctions/common';
import Image from "./Image";

class PreviewImage extends Component {

  render(){
    if(!this.props.image_content){
      return null
    }
    const cover_url = this.props.image_content.image_url
    return (
      <div id = "previewImage" >
        <Image id={"image-preview"} cover={{url: cover_url}} customSize={isSmartTv() ? 600 : 900}/>
        <div id = "previewShadow"></div>
      </div>
    );
  }
}

export default PreviewImage; 