import React from "react";
import { connect } from "react-redux";
import OtpInput from "react-otp-input";
import { openModal } from "../actions/modalActions";
import { validateDeviceOTP, deviceLogin, clearDeviceLogin } from "../actions/userActions"
import { withRouter } from "react-router";
import qs from "query-string";
import Loader from "./Loader";
import { getTvodAPIKey } from "../constants/initializer";
import { getAllConstants } from '../helperfunctions/regionalization';
import LoginFlow from "./LoginFlow";
import { checkLoginUIMetaData } from "../helperfunctions/common";
import { getLogoFromLoginOrFeed } from "../helperfunctions";
import configuration from "../constants/config";


function DeviceCode(props) {
  const ALL_C = getAllConstants();
  const {feeds={}, location, runningOnMobile} = props;
  const {logo={}, primaryColor, secondaryColor} = feeds;
  const primary = primaryColor ? primaryColor.replace("#FF", "#") : "#FFFFFF";
  const secondary = secondaryColor ? secondaryColor.replace("#FF", "#") : "#FFFFFF";
  const query = qs.parse(location.search);

  const [otp, setOtp] = React.useState(query.code || "");
  const [msg, setMsg] = React.useState(null);
  const [showLogIn, setShowLogIn] = React.useState(false);
  const [emailSelected, setEmailSelected] = React.useState(false);
  const [deviceOtpErr, setDeviceOtpErr] = React.useState(null);

  const navBarVisibility = (isShow) => {
    const displayVal = isShow ? "flex": "none"
    const navbar = document.querySelector('#web-nav-bar') || document.querySelector('#web-nav-bar-mobile-wrapper');
    const backBtn = document.querySelector('a#main_back_id') || document.querySelector('a.backBtn-mobile');
    navbar && (navbar.style.display = displayVal)
    backBtn && (backBtn.style.display = displayVal)
  }

  React.useEffect(() => {
    navBarVisibility(false)
    props.clearDeviceLogin()
    return () => {
      navBarVisibility(true)
    }
  }, [])

  React.useEffect(() => {
    if (props.deviceOtpApi.success && !props.isLoggedIn) {
      setShowLogIn(true);
      props.openModal({type: "login", text:ALL_C.kLoginToWatch, directLogin: true})
    }
    if (props.deviceOtpApi.success && props.isLoggedIn) {
      authorizeUser()
    }
    if (props.deviceOtpApi.error) {
      setDeviceOtpErr(ALL_C.kEnterCorrectCode)
    }
  }, [props.deviceOtpApi])

  React.useEffect(() => {
    if (props.deviceAuthenticationApi.success) {
      setMsg(ALL_C.kYouCanWatchOnDevice)
    }
    if (props.deviceAuthenticationApi.error) {
      setMsg(ALL_C.kDeviceLoginFailed)
    }
  }, [props.deviceAuthenticationApi])

  React.useEffect(() => {
    if (props.deviceOtpApi.success && props.isLoggedIn) {
      setShowLogIn(false);
      setEmailSelected(false);
      authorizeUser()
    }
  }, [props.isLoggedIn])

  React.useEffect(() => {
    setDeviceOtpErr(null);
    if (otp.length === 6) verifyCode()
  }, [otp])

  const verifyCode = () => {
    const params = { code: otp, key: getTvodAPIKey() }
    props.validateDeviceOTP(params)
  }

  const authorizeUser = () => {
    const params = {
      code: otp
    }
    props.deviceLogin(params)
  }

  const isLoading = props.deviceAuthenticationApi.pending || props.deviceOtpApi.pending;
  const showInput = msg ? false: true;


  if(showLogIn) {
    const headerLogo = getLogoFromLoginOrFeed("registrationWall");
    const allProps = {
      feeds: checkLoginUIMetaData(feeds),
      app_id: configuration.app_id,
      background: (feeds.loginUIMetadata.colors && feeds.loginUIMetadata.colors.background) || null,
      titleMsg: ALL_C.kLoginToContinue,
      directLogin: true
    }

    return (
      <LoginFlow
        emailSelected={emailSelected}
        headerLogo={headerLogo}
        selectEmail={() => setEmailSelected(true)}
        allProps={allProps}
        runningOnMobile={runningOnMobile}
      />
    )
  }

  return (
    <div className="otp-input-parent">
      {isLoading ? <Loader /> : null}
      
      <img src={logo.url} height="50px" style={{marginBottom: "40px"}}/>

      {showInput && <>
        <h2>{ALL_C.kEnterCodeHere}</h2>
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          separator={<span>&nbsp;&nbsp;</span>}
          shouldAutoFocus
          inputStyle={{
            padding: 5,
            fontSize: "min(7vmin, 48px)",
            fontWeigth: 'bold'
          }}
          focusStyle={{
            outline: `2px solid ${secondary}`,
          }}
          isInputNum
        />
        { deviceOtpErr ? <div className="error-div">{deviceOtpErr}</div>: null }
        <button 
          className="submit-btn" 
          onClick={verifyCode} 
          style={{
            backgroundColor: `${primary}`,
          }}
          disabled={otp.length !== 6}
        >{ALL_C.kSubmit}</button>  
      </>}

      { msg ? <h2>{msg}</h2> : null }
    </div>
  );
}

const maspStateToProps = (state) => ({
  isLoggedIn: state.user.logged_in,
  deviceOtpApi: state.user.deviceOtp,
  deviceAuthenticationApi: state.user.deviceAuthentication
})

const mapDispatchToProps ={
  openModal,
  validateDeviceOTP,
  deviceLogin,
  clearDeviceLogin
}

export default connect(maspStateToProps, mapDispatchToProps)(withRouter(DeviceCode));
