import React from 'react';
import { connect } from "react-redux"
import { openModal, closeModal } from "../actions/modalActions"
import configuration from '../constants/config';
import { checkIfSectionLevelContent, getParentFeed } from '../helperfunctions';

class SectionVideoSetup extends React.Component {
  componentDidMount(){
    let modalProps;
    if(this.props.section.type === "fake"){
      modalProps = {type: "fake", feeds: this.props.feeds, section: this.props.section};
      // this.props.openModal({type: "fake", feeds: this.props.feeds, section: this.props.section})
    } else if(this.props.section.type === "live"){
      modalProps = {type: "live", feeds: this.props.feeds, section: {...this.props.section}};
      if(configuration.isAppTvodType) {
        const parentObj =  getParentFeed(this.props.feeds, this.props.section.lineage) || {};
        if("showInterstitial" in parentObj) modalProps.section.showInterstitial = parentObj.showInterstitial;
      }
      // this.props.openModal({type: "live", feeds: this.props.feeds, section: this.props.section})
    } else if(this.props.section.type === "svideo"){
      modalProps = {type: "svideo", feeds: this.props.feeds, section: this.props.section};
      // this.props.openModal({type: "svideo", feeds: this.props.feeds, section: this.props.section})
    }

    if (!configuration.isAppTvodType && !(checkIfSectionLevelContent() || this.props.section.regularLayout == "detail") && (this.props.section.showInterstitial)) {
      this.props.openModal({
        type: 'interstitialScreen',
        goBack: true,
        modalProps: {...modalProps, content: this.props.section},
        thumbnailProps: {
          background: this.props.background,
        }
      })
    } else {
      this.props.openModal(modalProps)
    }
  }

  componentWillUnmount(){
    this.props.closeModal()
    if(this.props.modal.type !== "live" && window.hls){
      window.hls.destroy();
    }
  }

  render(){
    return (
      null
    )
  }
}

const mapStateToProps = (state) => ({
  modal: state.modal.modal,
})

const mapDispatchToProps = {
  openModal: openModal,
  closeModal: closeModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(SectionVideoSetup);