export function openModal(modalData) {
  return function(dispatch){
    dispatch({type: "OPEN_MODAL", payload: modalData})
  }
}

export function modalProgress(modalData) {
  return function(dispatch){
    dispatch({type: "MODAL_PROGRESS", payload: modalData})
  }
}

export function closeModal(modalData) {
  return function(dispatch){
    dispatch({type: "CLOSE_MODAL"})
  }
}

export function setPreviewVideo(content){
	return function(dispatch){
		dispatch({type: "SET_PREVIEW_VIDEO", data: content})
	}
}

export function setLockedContent(locked){
  return function(dispatch){
    dispatch({type: "SET_LOCKED_CONTENT", data: locked})
  }
}