import React from 'react';
import { httpsUrl } from "../constants/common"
import { isSmartTv } from "../helperfunctions/common";

class Logo extends React.Component {
  componentDidMount(){
    if(this.props.logo){
      var myImage = document.getElementById('logoHolder');
      myImage.addEventListener("load", function(){
        var i = new Image();
        i.src = this.src
        var aspect = i.width/i.height
        if(aspect < 4.0){
          myImage.parentNode.classList.add('smallImg')
        }
      }, {once: true})
    }
  }

  render() {
    let marginTop = '0';
    if(isSmartTv()) {
      // marginTop = '-3em'
    }

    return (
      <div className="logoHolder" style={{ marginTop }}>
        {this.props.logo && <img id="logoHolder" src={httpsUrl(this.props.logo.url || this.props.logo)} />}
      </div>
    )
  }
}

export default Logo;
