import React from 'react';
import { connect } from "react-redux";
import Image from './Image';

const BADGE_MAP = {
  0: "details",
  1: "topLeft",
  2: "topRight",
  3: "bottomRight",
  4: "bottomLeft",
  5: "topCenter",
  6: "rightCenter",
  7: "bottomCenter",
  8: "leftCenter",
  9: "center"
}

const getPositionClass = (badge) => {
  const pos = BADGE_MAP[badge.position];
  if(!pos) return null;

  return `badge-${pos}`;
}

const getAllbadges = (content, feeds) => {
  let badges = [];
  feeds.badges.forEach(badge => {
    if(content.badgeIds.includes(badge.identifier)) {
      badges.push(badge);
    }
  });
  return badges;
}

const ContentBadges = (props) => {
  const { content, feeds, isInterstitial } = props;

  if(!content || !content.badgeIds || !feeds.badges) return null;
  const badges = getAllbadges(content, feeds);

  return (
    <>
      {badges.map(badge => {
        if(!badge.cover || !badge.cover.url) return null;

        const posClass = getPositionClass(badge);
        if(!posClass) return null;
        if(isInterstitial && posClass !== "badge-details") return null;
        if(!isInterstitial && posClass === "badge-details") return null;
        return (
          <div key={`${content.identifier}-${badge.identifier}`}>
            <Image cover={badge.cover}  classNames={`content-badge ${posClass}`} customSize={"60"}/>
          </div>
        )
      })}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    feeds: state.feeds.feeds,
  }
}

export default connect( mapStateToProps , null)(ContentBadges);
