import React, { useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import configuration from '../../constants/config';
import JsSpatialNavigation from '../../ctvnavigation/js-spatial-navigation';
import { getTabIndex, isSmartTv } from '../../helperfunctions/common';
import { addRemoveAllNavigation } from '../../helperfunctions/ctvHelper';
import { getAllConstants } from '../../helperfunctions/regionalization';

function TvodSubscriptionsList({feeds, history, ...props}) {
  const subs = props.tvodSubscriptionList;
  const path = props.match.path;
  const ALL_C = getAllConstants();

  let id;
  useEffect(() => {
    //didmount
    if(isSmartTv() && configuration.use_light_sliders) {
      id = addRemoveAllNavigation({ selector: '.list-container .focusable' });
      JsSpatialNavigation.focus(id);
    }
  
    return () => {
      //will unmount
      if(isSmartTv() && configuration.use_light_sliders) {
          addRemoveAllNavigation({ id });
      }
    }
  });

  const subscriptionRowRender = (sub) => {
    const subUrl = `${path}/${sub.identifier}`
    return (
      <Link
        className={"sub-list-item focusable"}
        to={subUrl}
        tabIndex={getTabIndex()}
      >
        {sub.name}
      </Link>
    )
  }

  if (subs && subs.length == 0) {
    return (
      <div className='list-container'>
        <h2>{ALL_C.kNoActiveSubs}</h2>
      </div>
    )
  }
  
  return (
    <div className='list-container'>
      <h3>{ALL_C.kSubscriptionsSettingsText}</h3>
      <div className='tvod-sub-list hide-scrollbar'>
        { subs.map(sub => subscriptionRowRender(sub)) }
      </div>
    </div>
  )
}

export default withRouter(TvodSubscriptionsList)