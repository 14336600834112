import configuration from '../constants/config.js';
import store from './../store.js';


const STATIC_CONSTATNTS = {
  kSearchText: "Search",
  kCcpaMessage: "CCPA",
  kCcpaMailId: "Do not sell my personal data",
  kDoNotSellData: "Please write to %s to request that we not sell your personal data.",
  kEnterValidEmailPassword: "Enter valid Email and Password",
  kDoYouWantResetPassword: "Do you want to reset your password?",
  kGenricSigninMessage: "Please sign in to access your content. If you are not already a member, please join and return to app.",
  kMoreWaysToWatchMsg: "Additional purchase options for this content are not available on this device.",
  kTrailer: "Watch Trailer",
  kISMaxStreamTitle: "Streaming Blocked",
  kISMaxStreamMessage: "You are already streaming on %s devices. Please stop streaming on a device and try again.",
  kLogOutText: "Log Out",
  kLogIn: "Log In",
  kISCloseCaptioning: "Closed Captioning",
  kVoucherCode: "Voucher Code",
  kValidating: "Validating...",
  kCodeApplied: "Valid voucher code applied.",
  kCodeRejected: "Invalid code.",
  kConfirm: "Confirm",
  kClose: "Close",
  kSetCountry: "Set country",
  kEnterCountryCode: "Enter country code",
  kSettingText: "Settings",
  kSearchPlaceholder: "Search...",
  kSearchMsg: "Your search results will appear here.",
  kSearchEmptyText: "No result found!",
  kEmailPlaceholder: "Email",
  kPasswordPlaceholder: "Password",
  kForgotPassword: "Forgot Password ?",
  kInvalidDetails: "Invalid Details",
  kAccountLocked: "You've reached the maximum login attempts, your account is temporarily locked",
  kInvalidEmail: "Invalid email",
  kEnterValidEmail: "Please input a valid email address.",
  kInvalidPassword: "Invalid password",
  kInvalidPasswordText: "Password can't be blank.",
  kTermsOfServiceText: "Terms of Service",
  kSettingsPrivacyPolicy: "Privacy Policy",
  kCancel: "Cancel",
  kISActionReset: "Reset",
  kEmailSent: "Email Sent",
  kEmailSentText: "Please check your email for instructions on how to reset your password.",
  kISSubscribe: "Subscribe",
  kISRent: "Rent",
  kISAttend: "Attend",
  kISBuy: "Buy",
  kISWatchWith: "Watch with",
  kISHowToWatch: "How to watch",
  kISMorePurchase: "More Purchase Options",
  kISEventStarts: "Event starts",
  kISEventEnded: "The Event has ended",
  kISPlay: "Play",
  kISMoreWays: "More Ways to Watch",
  kISSignIn: "Sign In",
  kISSubscriberSignIn: "Already A Subscriber? Sign In",
  kDisclaimerText: "Payment will be charged to the Account at confirmation of purchase. Subscription automatically renews unless auto-renew is turned off at least 24 hours before the end of the current period for the same price as the first period. Any unused portion of a free period, if offered, will be forfeited when the user purchases a subscription. You may manage your subscriptions and auto-renew may be turned off by going to Subscription in Settings after purchase and cancel the subscription. No cancellation of the current subscription is allowed during an active subscription period. “Free Trial” and “FREE” represent a period of free access that will be automatically followed by an auto-renewing subscription at the standard subscription price unless auto-renew is turned off at least 24 hours before the end of the current period.",
  kISReadVisit: "To read, please visit:",
  kISStartsWith: "Start with a %s free trial",
  kOk: "OK",
  kISExpiresText: "Expires in %s hrs, or %t hrs after you start watching",
  kISStarring: "Starring",
  kISDirector: "Director",
  kISDirectors: "Directors",
  kISMonths: "Months",
  kISWeeks: "Week",
  kISYear: "Year",
  kISMonth: "Month",
  kISHr: "hr ",
  kISMin: "min",
  kISSec: "sec ",
  kContinueWatching: "Continue Watching?",
  kResume: "RESUME",
  kStartOver: "START OVER",
  kISSkip: "Skip",
  kLoginToContinue: "PLEASE LOGIN TO CONTINUE",
  kUnableToPurchase: "Unable to do purchase.",
  kPurchaseNotPossible: "Purchase is not possible.",
  kInorderToPurchase: "IN ORDER TO PURCHASE YOU NEED TO REGISTER",
  kPaymentFailed: "Payment failed",
  kPaymentRedirect: "You will be redirecting back in 3 seconds.",
  kPaymentClick: "Click here to redirect",
  kPaymentSuccess: "Payment successful",
  kPaymentDuration: "Duration",
  kPaymentTitle: "Title",
  kPaymentPrice: "Price",
  kPurchaseBadge:"You own this item",
  kSubBadge: "Included with your subscription",
  kRentalBadge: "Your rental expires",
  kEventBadge: "You have a ticket to this event",
  kNoProgramDataAvailable: 'No Program Available',
  kBackButtonText: "Back",
  kEnterCodeHere: "Please enter the code here",
  kSubmit: "Submit",
  kLoginToWatch: "Login to watch on your device",
  kEnterCorrectCode: "Please enter correct code",
  kYouCanWatchOnDevice :"You can now watch content on the device",
  kDeviceLoginFailed: "Device login failed",
  kGoBack: "Go Back",
  kPermission: "Permission",
  kDismiss: "Dismiss",
  kCtvWebLoginGoToMsg: "1. On your phone or computer, go to",
  kCtvWebRegisterMsg: "2. Create an account or login on that device.",
  kCtvWebEnterDeviceCode: "2. Enter the device code and login.",
  kCtvPaymentTextMakePayment: "On your phone or computer, open the link or Scan the QR code",
  kCtvPaymentTextMakePayment: "To make the payment on that device.",
  kCtvPaymentTextDoNotBackPress: "Note - Please do not press back, after the payment, page will auto redirect.",
  kMorePleaseVisit: "Please Visit",
  kTrailerText: "Trailer",
  kISExtrasLocked: "This content is included with your purchase of",
  kCtvExitMsg: "Are you sure you want to exit ?",
  kYes: "Yes",
  kNo: "No",
  kAllow: "Allow",
  kDeny: "Deny",
  kLoginToContinueWatching: "PLEASE LOGIN TO CONTINUE WATCHING",
  kISGenres: "Genres",
  kISGenre: "Genre",
  KStreamOnEntryInstructionsText: "Press the back button to return to the home screen",
  kVizioSetup: "Set up VIZIO Account",
  kVizioNA: "Service not available",
  kVizioSetupDesc: "VIZIO Account has not yet been set up. Would you like to do that now?",
  kVizioNADesc: "VIZIO Account is currently unavailable. Would you like to continue using a different payment method?",
  kNotNow: "Not Now",
  kUnauthorised: "Unauthorised",
  kUnauthorizedMessage: "You are not authorized to access this content.",
  kAutoPlay: "Auto Play",
  kContentUnavailableMessage: "This content is currently unavailable. Please try again later.",

  // parental locak strings
  sParentalControl: "Parental Controls",
  sResetCode: "Reset Code",
  sSetNewCode: "Set New Code",
  sPleaseEnterCodeAgain: "Please enter your code again.",
  sCodeNotMatched: "The code that you entered does not match the previously entered code.",
  sPleaseTryAgain: "Please try again.",
  sSetParentalAccessCode: "Set Parental Access Code",
  sSetupParentalCodeMessage: "You have not yet set a parental access code. Once added, this code will be required before viewing mature content. Would you like to add a code now?",
  sCancel: "Cancel",
  sYes: "Yes",
  sRemoveOrChangeCodeMessage: "A parental access code is currently set. Would you like to change or remove the code now?",
  sRemoveCode: "Remove Code",
  sChangeCode: "Change Code",
  sPinRemovedMsg: "Your PIN code has been removed.",
  sOk: "OK",
  sEnterAccessCode: "Enter Parental Access Code",
  sPleaseEnterAccessCode: "Please enter your parental access code.",
  sPleaseEnterCodeAgain: "Please enter your code again.",
  sIncorrectCodeWarning: "Incorrect Parental Access Code",
  sCodeNotMatched: "The code that you entered does not match the previously entered code.",
  sCodeWrongTryAgainMessage: "The code that you entered is incorrect. Try again?",
  sParentalAccessCodeSetSuccess: "Parental Access Code Set",
  sParentalAccessCodeSetSuccessMsg: "Your parental access code has been set. This code will now be required prior to watching mature content.",
  kNo: "No",
  sResetAccessCode: "Reset Access Code",
  sResetAccessCodeMsg: "This will reset your parental access code. To receive your new code, you will need access to:",
  sAreYouSure: "Are you sure?",
  sConfirm: "Confirm",
  sResetInstructionMsg: "An email with reset instructions has been sent to your registered email address. If you do not see this email soon, please check your spam folder.",
  sTooManyAttempts: "Too many invalid attempts. Please reset your access code",
  sTooManyFixString: "Too many invalid attempts. Please reset your access code",
  sPleaseScanQR: "Please scan the QR code to set your access code.",
  kPVCNoItems: "You have no Items.",
  kISDefaultEmptyText: "You have no Items.",
  kNext: "Next",
  kMyAccount: "My Account",
  kVisitCtvLinkHeader: "To visit this web link, type the following URL into your browser:",
  kVisitWebLinkHeader: "Please click on the link provided below to access the content.",
  kLinkScanQRText: "Or scan the QR code below:",
  kSubscriptions: "Subscriptions",
  kNoActiveSubs: "There is no active subscriptions.",
  kSubscriptionsSettingsText: "Activate Subscription"
}

function getTVConstants(language) {
  if(!language) return {};

  if(configuration.tv && configuration.tv[language]) {
    return configuration.tv[language];
  }
  return {}
}

function getSettingsConstants(language) {
  if(!language) return {};

  if(configuration.settings && configuration.settings[language]) {
    return configuration.settings[language];
  }
  return {}
}

function getRootLevelConstants(language) {
  if(!language) return {};

  if(configuration[language]) {
    return configuration[language];
  }
  return {}
}

function getBadgeTexts(language) {
  if(!language) return {};

  var newState = store.getState();

  const badgeTexts = newState?.feeds?.feeds?.extras?.badgeText;
  if(badgeTexts && badgeTexts[language]) {
    const allStrings = badgeTexts[language];
    let badges = {};
    if(allStrings.subscription) {
      badges.kSubBadge = allStrings.subscription;
    }
    if(allStrings.rental) {
      badges.kRentalBadge = allStrings.rental;
    }
    if(allStrings.event) {
      badges.kEventBadge = allStrings.event;
    }
    if(allStrings.purchase) {
      badges.kPurchaseBadge = allStrings.purchase;
    }
    return badges;
  }
  return {};
}

function getRegionalizationConstants(language) {
  if(!language) return {};

  var newState = store.getState();
  const allStrings = newState?.feeds?.feeds?.extras?.regionalization
  
  if(allStrings && allStrings[language]) return allStrings[language];
  return {};
}

function getParentalLockStrings() {
  var newState = store.getState();
  if(newState && newState.feeds && newState.feeds.feeds && newState.feeds.feeds.parentalControls && 
    newState.feeds.feeds.parentalControls.localisedMetadata
  ) {
    return newState.feeds.feeds.parentalControls.localisedMetadata;
  }
  return {};
}

export function getLanguage() {
  var newState = store.getState();
  if(newState && newState.feeds && newState.feeds.policyData && newState.feeds.policyData.language) {
    return newState.feeds.policyData.language;
  }
  return "en";
}

export function getAllConstants() {

  if(!configuration.isAppTvodType) {
    return STATIC_CONSTATNTS;
  }

  const language = getLanguage();
  return {
    ...STATIC_CONSTATNTS,
    ...getTVConstants(language),
    ...getSettingsConstants(language),
    ...getBadgeTexts(language),
    ...getRegionalizationConstants(language),
    ...getRootLevelConstants(language),
    ...getParentalLockStrings()
  }
}