import configuration from "../../constants/config";
import {
  DefaultTheoColors,
  getColorOptions,
  getEdgeStyleOptions,
  getFontFamilyOptions,
  getFontSizeOptions,
  getOpacityOptions,
  getOpacityValue,
  getRGBValue,
  getUpdatedColor,
  getUpdatedColorOpacity,
  THEOPLAYER_DEFAULT_STATE,
  VIDEOJS_DEFAULT_STATE
} from "../../helperfunctions/playerHelpers";
import {
  getLocalStorage,
  setLocalStorage
} from "../../helperfunctions/storage";
import ContentModal from "./ContentModal";

const PlayerSettingsMenu = ({ player, closeModal }) => {
  const { kUseTheoPlayer } = configuration;

  const handleVideoSettings = ({ value, type }) => {
    var settings = player.textTrackSettings;
    settings.setValues({
      [type]: value
    });
    settings.updateDisplay();
    settings.saveSettings();
    setLocalStorage(`videoJsPlayer.textTrackSettings.${type}`, value);
  };

  const handleOpacityChange = ({ value, type }) => {
    if (!kUseTheoPlayer) {
      handleVideoSettings({ value, type });
      return;
    }

    if (type === "windowOpacity") {
      player.textTrackStyle.windowColor = getUpdatedColorOpacity(
        player.textTrackStyle.windowColor || "rgb(0, 0, 0)",
        value
      );
    } else if (type === "textOpacity") {
      player.textTrackStyle.fontColor = getUpdatedColorOpacity(
        player.textTrackStyle.fontColor || "rgb(255, 255, 255)",
        value
      );
    } else if (type === "backgroundOpacity") {
      player.textTrackStyle.backgroundColor = getUpdatedColorOpacity(
        player.textTrackStyle.backgroundColor || "rgb(0, 0, 0)",
        value
      );
    }
  };

  const handleColorChange = ({ value, type }) => {
    if (!kUseTheoPlayer) {
      handleVideoSettings({ value, type });
      return;
    }
    player.textTrackStyle[type] = getUpdatedColor(
      player.textTrackStyle[type] || DefaultTheoColors[type],
      value
    );
    if (!value && type === "backgroundColor")
      player.textTrackStyle[type] = "rgba(0, 0, 1, 0.8)"; //TheoPlayer Default Background is Black with Opacity of 0.8
  };

  const handleStyleChange = ({ value, type }) => {
    if (!kUseTheoPlayer) {
      handleVideoSettings({ value, type });
      return;
    }
    player.textTrackStyle[type] = value;
  };

  const getSeletedValue = (key, options) => {
    if (kUseTheoPlayer) {
      var textTrackStyleValue;
      switch (key) {
        case "fontColor":
        case "backgroundColor":
        case "windowColor":
          textTrackStyleValue = getRGBValue(player.textTrackStyle[key]);
          break;
        case "textOpacity":
          textTrackStyleValue = getOpacityValue(
            player.textTrackStyle.fontColor
          );
          break;
        case "windowOpacity":
          textTrackStyleValue = getOpacityValue(
            player.textTrackStyle.windowColor
          );
          break;
        case "backgroundOpacity":
          textTrackStyleValue = getOpacityValue(
            player.textTrackStyle.backgroundColor
          );
          break;
        default:
          textTrackStyleValue = player.textTrackStyle[key];
          break;
      }
      return (
        options.find((option) => option.value === textTrackStyleValue)?.label ||
        "Default"
      );
    } else {
      const textTrackStyleValues = player.textTrackSettings.getValues();
      return (
        options.find((option) => option.value === textTrackStyleValues[key])
          ?.label || "Default"
      );
    }
  };

  const resetSettings = () => {
    if (kUseTheoPlayer) {
      THEOPLAYER_DEFAULT_STATE.map(([key, value]) => {
        player.textTrackStyle[key] = value;
      });
    } else {
      VIDEOJS_DEFAULT_STATE.map(([type, value]) => {
        handleVideoSettings({ type, value });
      });
    }
  };
  const fontColorOptions = getColorOptions({
    type: kUseTheoPlayer ? "fontColor" : "color",
    handler: handleColorChange,
    kUseTheoPlayer
  });
  const backgroundColorOptions = getColorOptions({
    type: "backgroundColor",
    handler: handleColorChange,
    kUseTheoPlayer
  });
  const windowColorOptions = getColorOptions({
    type: "windowColor",
    handler: handleColorChange,
    kUseTheoPlayer
  });
  const fontFamilyOptions = getFontFamilyOptions({
    type: "fontFamily",
    handler: handleStyleChange,
    kUseTheoPlayer
  });
  const fontSizeOptions = getFontSizeOptions({
    type: kUseTheoPlayer ? "fontSize" : "fontPercent",
    handler: handleStyleChange,
    kUseTheoPlayer
  });
  const backgroundOpacityOptions = getOpacityOptions({
    type: "backgroundOpacity",
    handler: handleOpacityChange,
    kUseTheoPlayer
  });
  const textOpacityOptions = getOpacityOptions({
    type: "textOpacity",
    handler: handleOpacityChange,
    kUseTheoPlayer
  });
  const windowOpacityOptions = getOpacityOptions({
    type: "windowOpacity",
    handler: handleOpacityChange,
    kUseTheoPlayer
  });
  const edgeStyleOptions = getEdgeStyleOptions({
    type: "edgeStyle",
    handler: handleStyleChange,
    kUseTheoPlayer
  });
  
  const captionStylingMap = [
    {
      label: "Font Family",
      children: fontFamilyOptions,
      selectedValue: () => getSeletedValue("fontFamily", fontFamilyOptions)
    },
    {
      label: "Font Color",
      children: fontColorOptions,
      selectedValue: () =>
        getSeletedValue(
          kUseTheoPlayer ? "fontColor" : "color",
          fontColorOptions
        )
    },
    {
      label: "Font Size",
      children: fontSizeOptions,
      selectedValue: () =>
        getSeletedValue(
          kUseTheoPlayer ? "fontSize" : "fontPercent",
          fontSizeOptions
        )
    },
    {
      label: "Text Opacity",
      children: textOpacityOptions,
      selectedValue: () => getSeletedValue("textOpacity", textOpacityOptions)
    },
    {
      label: "Background Color",
      children: backgroundColorOptions,
      selectedValue: () =>
        getSeletedValue("backgroundColor", backgroundColorOptions)
    },
    {
      label: "Background Opacity",
      children: backgroundOpacityOptions,
      selectedValue: () =>
        getSeletedValue("backgroundOpacity", backgroundOpacityOptions)
    },
    {
      label: "Window Color",
      children: windowColorOptions,
      selectedValue: () => getSeletedValue("windowColor", windowColorOptions)
    },
    {
      label: "Window Opacity",
      children: windowOpacityOptions,
      selectedValue: () =>
        getSeletedValue("windowOpacity", windowOpacityOptions)
    },
    {
      label: "Edge Styles",
      children: edgeStyleOptions,
      selectedValue: () => getSeletedValue("edgeStyle", edgeStyleOptions)
    },
    {
      label: "Reset All Settings",
      onSelect: resetSettings
    }
  ];

  const playerSettingMap = [
    {
      label: "Closed Caption Settings",
      children: captionStylingMap
    }
  ];

  return (
    <div className="select-container">
      <ContentModal options={playerSettingMap} closeModal={closeModal} />
    </div>
  );
};

export default PlayerSettingsMenu;

export const setDefaultValuesInVideoJs = ({ player }) => {
  const textTrackSettings = player.textTrackSettings;
  const currentValues = [
    ...Object.entries(textTrackSettings.getValues()),
    ["fontPercent", ""]
  ];
  currentValues.map(([key, value]) => {
    textTrackSettings.setValues({
      [key]: getLocalStorage(`videoJsPlayer.textTrackSettings.${key}`) || null
    });
    textTrackSettings.updateDisplay();
    textTrackSettings.saveSettings();
  });
};