import React from 'react'
import { connect } from "react-redux"
import { saveItem, saveTvodItem, getSavedItems, deleteItem, deleteTvodItem } from "../actions/itemActions"
import { openModal } from "../actions/modalActions"
import isAvailable from '../helperfunctions/is_available'
import * as analytics  from '../constants/analytics';
import { getParentFeed } from '../helperfunctions'
import { getTabIndex, isSmartTv } from '../helperfunctions/common'
import { getSpatialDataAttribute, scrollToTop } from '../helperfunctions/ctvHelper'
import configuration from '../constants/config'
import JsSpatialNavigation from '../ctvnavigation/js-spatial-navigation';
import { EXTRA_CATALOG, TRAILER_CATALOG } from '../constants/common'
import TickIcon from './icons/TickIcon'

function isItemSaved(content, item_hash, content_type) {
    var flag = 0;
    for(var i=0; i< item_hash.length; i++) {
      if((configuration.isAppTvodType && content.cid === item_hash[i].cid) ||
        (!configuration.isAppTvodType && content.identifier === item_hash[i].identifier)
      ) {
        flag = 1;
        break;
      }
    }
    if(flag === 1){
        return true;
    } else{
        return false;
    }
}

function createBigIcon(url) {
    return (url.split(".png")[0] + "@2x" + ".png")
}

function isItemLocked(locked, content) {
  const contentData = configuration.isAppTvodType ? content : null;
  const isLock =  (locked && !isAvailable(content.parent_id, content.special, content.registerWall, contentData));
  if(isLock) {
    if(configuration.isAppTvodType) return null; // Let user save locked content for tvod
    if(content.special) return "You must purchase this content in order to save it.";
    else if(content.access.iap) return "You must unlock this content in order to save it.";
    else return "You must subscribe in order to save this content.";
  } else return null;
}

function isViewMetering(feeds) {
  if (feeds && feeds.ctaMetadata && feeds.ctaMetadata.metering) return true;
  return false;
}

class SaveIcon extends React.Component {
    constructor(props){
        super(props);
        this.saveItem = this.saveItem.bind(this)
        this.removeItem = this.removeItem.bind(this)
    }

    componentDidMount() {
      if(!this.showingIcon()) return;

      if(this.props.saveAfterLogin && this.props.logged_in) {
        this.saveItem();
      }
    }

    showingIcon() {
      if(!this.props.content ||
        this.props.content.catalog === TRAILER_CATALOG ||
        this.props.content.catalog === EXTRA_CATALOG
      ) {
        return false;
      }  
      return true;
    }

    saveItem(ev){
      let { locked, feeds, content, metering_views_seen } = this.props;
      if (content.mazContentType && content.lineage) {
        const parentFeed = getParentFeed(feeds, `${content.lineage}/${content.identifier}`);
        locked = parentFeed.locked;
        content = {
          ...content,
          locked: parentFeed.locked,
          special: parentFeed.special,
          displayTitle: parentFeed.displayTitle || null,
          registerWall: parentFeed.registerWall,
          parent_id: parentFeed.identifier,
          showAd: parentFeed.showVideoAd
        }
        if(!configuration.isAppTvodType) {
          content.access = parentFeed.access
        }
      }
      if(configuration.isAppTvodType) {
        // Not checking key "locked" for now
        locked = content.access && content.access.bundles && content.access.bundles.length > 0;
      } 
      const lockedMsg = isItemLocked(locked, content);
      const viewMetering = isViewMetering(feeds);
      if(!this.props.logged_in){
        if(this.props.passToTvodWatchman) {
          this.props.passToTvodWatchman();
        } else {
          this.props.openModal({type: "login", saved_item: this.props.content, locked: locked, viewMetering: viewMetering, interstitialModalDetails: this.props.interstitialProps})
        }
      } else {
        let viewRemaining = 0;
        if(viewMetering) {
          viewRemaining = feeds.ctaMetadata.metering.freeViews - metering_views_seen.length;
        }
        if(lockedMsg && (!viewMetering || (!metering_views_seen.includes(content.identifier) && viewRemaining <= 0)) ) {
          // if locked with time metering or views has expired
          this.props.openModal({type: "error", message: lockedMsg, goBack: this.props.passToTvodWatchman})
        } else {
          analytics.setEvent('Save', 'Save', this.props.content.title)
          if(configuration.isAppTvodType) {
            this.props.saveTvodItem(this.props.content, {saveAfterLogin: this.props.saveAfterLogin})
          } else {
            let paths = window.location.pathname.split("/").splice(3);
            paths.push(this.props.content.parent_id);
            let content = {...this.props.content, lineage: paths.join("/")}
            this.props.saveItem(this.props.user.auth_token, content.identifier, this.props.user.user_id, content)
          }
        }
      }
      if(ev) ev.preventDefault();
      if(isSmartTv() && ev && ev.currentTarget.children.length) {
        JsSpatialNavigation.focus(ev.currentTarget.children[0]);
      }
    }
    removeItem(ev){
      const isSavedSection = this.props.type === 'saved_item'
      const itemContentlength = this.props.item_content && this.props.item_content.length

      let isLastElem = false

      if(isSmartTv() && isSavedSection && itemContentlength > 1){
        if(this.props.item_content[itemContentlength - 1].identifier == this.props.content.identifier){
          isLastElem = true
        }
      }
        analytics.setEvent('Save', 'Archive from Saved/Queue', this.props.content.title)
        if(configuration.isAppTvodType) {
          this.props.deleteTvodItem(this.props.content);
        } else {
          this.props.deleteItem(this.props.user.auth_token, this.props.content.identifier, this.props.user.user_id, this.props.content)
        }
        ev.preventDefault()
        if(isSmartTv() && ev.currentTarget.children.length) {
          JsSpatialNavigation.focus(ev.currentTarget.children[0]);
        }
        if(isLastElem){
          var el = document.querySelector(`.item-${this.props.item_content[itemContentlength - 2].identifier} .icon.focusable`)
          el && JsSpatialNavigation.focus(el);
        }
    }

    getAriaLabels(title, type) {
      return {
        'role': 'button',
        'aria-label': `${type} item | ${title}`
      };
    }

  render(){
    if(!this.showingIcon()) return null;
    // return(<></>);
    if(this.props.no_color){
      var style = {
        color: "#ffffff"
      }
    }
    let buttonStyle = {marginLeft: '0px'}
    if(this.props.tvodPrimaryColor) {
      buttonStyle.backgroundColor = this.props.tvodPrimaryColor;
    }

    let title = this.props.title ? this.props.title.toLowerCase() : "";

    const isSavedSection = this.props.type === 'saved_item'
    const isSavedItem = isItemSaved(this.props.content, this.props.item_content, this.props.type)
    const handleClick = isSavedItem ? this.removeItem : this.saveItem;
    const spatialDataAttributes = getSpatialDataAttribute(this.props.index, this.props.total, this.props.layout)
    const ariaLabel = isSavedItem ? 'remove' : 'save';
    const ariaLabels = this.getAriaLabels(title, ariaLabel)

    if(configuration.isAppTvodType && isSavedSection && isSavedItem){
      return <a href="#" onClick={handleClick}><div style={buttonStyle} className="focusable" tabIndex={getTabIndex()} {...spatialDataAttributes} {...ariaLabels} onFocus={e => scrollToTop(e)} ><TickIcon fill={this.props?.feeds?.secondaryColor?.replace("#FF", "#")} /></div></a>
    }

    if (this.props.no_color) {
      if(!(this.props.feeds.savedIconUrl && this.props.feeds.savedFillIconUrl)){
        if (!isSavedItem) {
          return (
            <a href="#" onClick={handleClick}><span className="icon-star focusable" tabIndex={getTabIndex()} style={style} { ...spatialDataAttributes } {...ariaLabels} onFocus={e => scrollToTop(e) }></span></a>
          )
        } else {
          return (
            <a href="#" onClick={handleClick}><span className="icon-starfill focusable"  tabIndex={getTabIndex()} style={style} { ...spatialDataAttributes }  {...ariaLabels} onFocus={e => scrollToTop(e) }></span></a>
          )
        }
      } else {
        if (!isSavedItem) {
          return (
            <a href="#" onClick={handleClick}><img style={buttonStyle} className="icon focusable"  tabIndex={getTabIndex()} src={createBigIcon(this.props.feeds.savedIconUrl)} { ...spatialDataAttributes }  {...ariaLabels} onFocus={e => scrollToTop(e) }/></a>
          )
        } else {
          return (
            <a href="#" onClick={handleClick}><img style={buttonStyle} className="icon focusable"  tabIndex={getTabIndex()} src={createBigIcon(this.props.feeds.savedFillIconUrl)} { ...spatialDataAttributes }  {...ariaLabels} onFocus={e => scrollToTop(e) }/></a>
          )
        }
      }
    } else {
      if(!(this.props.feeds.savedColorIconUrl && this.props.feeds.savedColorFillIconUrl)){
        if (!isSavedItem) {
          return (
            <a href="#" onClick={handleClick}><span className="icon-star focusable" tabIndex={getTabIndex()} style={style} {...spatialDataAttributes}  {...ariaLabels} onFocus={e => scrollToTop(e)}></span></a>
          )
        } else {
          return (
            <a href="#" onClick={handleClick}><span className="icon-starfill focusable" tabIndex={getTabIndex()} style={style} {...spatialDataAttributes}  {...ariaLabels} onFocus={e => scrollToTop(e)}></span></a>
          )
        }
      } else {
        if (!isSavedItem) {
          return (
            <a href="#" onClick={handleClick}><img style={{ marginLeft: '0px' }} className="icon focusable" tabIndex={getTabIndex()} src={createBigIcon(this.props.feeds.savedColorIconUrl)} {...spatialDataAttributes}  {...this.getAriaLabels(title, 'save')} onFocus={e => scrollToTop(e)} /></a>
          )
        } else {
          return (
            <a href="#" onClick={handleClick}><img style={{ marginLeft: '0px' }} className="icon focusable" tabIndex={getTabIndex()} src={createBigIcon(this.props.feeds.savedColorFillIconUrl)} {...spatialDataAttributes}  {...this.getAriaLabels(title, 'remove')} onFocus={e => scrollToTop(e)} /></a>
          )
        }
      }
    }
  }
}

const mapStateToProps = (state) => ({
    item_content: state.items.items.content,
    user_id: state.items.user_id,
    logged_in: state.user.logged_in,
    user: state.user.user,
    metering_views_seen: state.user.metering_views_seen,
    subscription: state.user.subscription,
})

const mapDispatchToProps = {
    getSavedItems: getSavedItems,
    saveItem: saveItem,
    saveTvodItem: saveTvodItem,
    deleteItem: deleteItem,
    deleteTvodItem: deleteTvodItem,
    openModal: openModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(SaveIcon)
