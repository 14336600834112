function httpsUrl(url){
    let newUrl = url;
    if(url.startsWith("http:")){
        newUrl = url.replace("http:", "https:")
    }
    return newUrl;
}

/**
 * Make an object of primary and secondary font from the feed object
 * @param {object} feeds Feeds Object (One feed), checks for fonts key in the object
 */
function getPrimaryAndSecondaryFontsFromFeed(feeds) {
    const fonts = {
      primary: "", secondary: ""
    };

    if (!feeds || !feeds.fonts || !feeds.fonts.length)
        return fonts;

    feeds.fonts.forEach(fontObj => {
        if (fontObj.type === "primary" && fontObj.url) {
            fonts.primary = fontObj;
            fonts.primary.url = httpsUrl(fonts.primary.url);
        }

        else if (fontObj.type === "secondary" && fontObj.url) {
            fonts.secondary = fontObj;
            fonts.secondary.url = httpsUrl(fonts.secondary.url);
        }
    });
    return fonts;
  }

/**
 * Function to generate Font markup using font url and font name, Such that font can be loaded in the application
 * @param {String} fontUrl url of the font
 * @param {String} fontName name of the font
 */
function generateFontMarkup(fontUrl, fontName) {
    return [
        '@font-face {\n',
        '\tfont-family: \'', fontName, '\';\n',
        '\tsrc: url(\'', fontUrl, '\');\n',
        '}\n'
    ].join('');
}

/**
 * Generate style tag, such that it can be put into HTML head to load the fonts.
 * @param {*} fontObj Details of primary and secondary font (If present)
 */
function generateFontStyleTagAndFontNames(fontObj) {
    let style =  document.createElement('style');
    style.setAttribute('type', 'text/css');
    const fontNames = [];
    if (fontObj.primary && fontObj.primary.url) {
        style.appendChild(document.createTextNode(generateFontMarkup(fontObj.primary.url, fontObj.primary.name)));
        fontNames.push(fontObj.primary.name);
    }

    if (fontObj.secondary && fontObj.secondary.url) {
        style.appendChild(document.createTextNode(generateFontMarkup(fontObj.secondary.url, fontObj.secondary.name)));
        fontNames.push(fontObj.secondary.name);
    }
    return {
        style,
        fontNames
    };
}

/**
 * Check for primary and secondary font in the object and then generate style object such that primary and secondary font can be applied to html tags.
 * @param {*} feeds One feed data object
 */
function getFontStyleFromFeed(feeds) {
    const fontObj = getPrimaryAndSecondaryFontsFromFeed(feeds);
    const styles = {
        primaryFont : {},
        secondaryFont: {}
    }

    if (fontObj.primary && fontObj.primary.url) {
        styles.primaryFont = {
            "fontFamily": fontObj.primary.name
        }
    }

    if (fontObj.secondary && fontObj.secondary.url) {
        styles.secondaryFont = {
            "fontFamily": fontObj.secondary.name
        }
    }
    return styles;
}

export {
    generateFontStyleTagAndFontNames,
    getPrimaryAndSecondaryFontsFromFeed,
    getFontStyleFromFeed
}