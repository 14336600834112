import React from 'react'
import { connect } from "react-redux"
import { getPrimaryAndSecondaryFontsFromFeed } from "../helperfunctions";
import { openModal } from "../actions/modalActions"
import { simulatedSubPurchase,getMeteringDisplay } from "../actions/userActions"
import Subscribe from '../containers/Subscribe'
import isAvailable from '../helperfunctions/is_available'
import { getFontStyleFromFeed } from "../helperfunctions";
import { getMeteringTypes, HERO_SLIDER_THEME } from '../constants/common';
import lock_mini from "../images/icons/lock_mini.png";
import { VIDEO_GRID_THEME_THEME } from '../constants/common';
import configuration from '../constants/config';

function FindDefaultSubString(subscriptions){
  var duration = subscriptions[0].duration
  for(var i=0; i < subscriptions.length; i++){
    if(subscriptions[i].isDefault){
      duration = subscriptions[i].duration
      break;
    }
  }
  duration = Math.ceil(duration/31);
  if(duration === 12){
    return "year";
  } else if(duration === 1){
    return "month";
  } else {
    return (duration + " months")
  }
}

function findDateString(startDate, days) {
  if(!startDate || days == undefined) return "";
  // change date format from dd/mm/yy to mm/dd/yy
  let formattedDate = startDate.split("/");
  formattedDate = `${formattedDate[1]}/${formattedDate[0]}/${formattedDate[2]}`;

  let nDate = new Date(formattedDate);
  nDate.setDate(nDate.getDate() + days);
  let dateString = new Date(nDate).toString();
  return dateString.substring(4, 15)
}

class Cta extends React.Component {
  constructor(props){
    super(props)
    this.subscribe = this.subscribe.bind(this)
    this.purchase = this.purchase.bind(this)
    this.METERING_TYPE = getMeteringTypes();
  }

  subscribe(ev){
    this.props.simulatedSubPurchase()
    if(!this.props.fake){
      this.props.openModal({
        type: "subscribe",
        heading: "This is a simulated purchase. All content will be unlocked.",
        message: "You can cancel this purchase from settings."})
    }
    ev.preventDefault()
  }

  // feeds={this.props.feeds} subscriptions={this.props.feeds.subscriptions} background={"#808080"} item={this.props.modal.content} parent_id={this.props.parent_id}

  purchase(ev){
    if (!isAvailable(this.props.head, this.props.content.special, false, this.props.content)){
      this.props.openModal(
        {
              type: "subscriptions",
              feeds: this.props.feeds,
              content: this.props.content,
              parent_id: this.props.content.identifier,
              goBack: ["live", "fake"].includes(this.props.content.type) && this.props.interstitial ? true : false,
              interstitialModalDetails: this.props.interstitialProps
        }
      )
    }else{
      this.props.openModal(
        {
          type: "login",
          screen: "registerWall",
          interstitialModalDetails: this.props.interstitialProps
        }
      )
    }
    ev.preventDefault()
  }

  render(){
    if(configuration.isAppTvodType) return null;
    const fontStyle = getFontStyleFromFeed(this.props.feeds);
    let style = {
      color: (this.props.feeds.primaryColor && this.props.feeds.primaryColor.replace('#FF','#'))
    }
    let subDuration = this.props.feeds.subscriptions ? FindDefaultSubString(this.props.feeds.subscriptions) : "month" // fail safe
    let sub_price = this.props.feeds.subscriptions ? this.props.feeds.subscriptions[0].iap.price : 1
    let iap_price = this.props.content.access.iap ? this.props.content.access.iap.price : 1
    let cta_text = "";
    let display_time = 0
    let type = ""
    let total_available = 0
    if (this.props.feeds.ctaMetadata && this.props.feeds.ctaMetadata.metering && this.props.feeds.ctaMetadata.metering.freeViews){
      type = this.METERING_TYPE.VIEWS
      display_time =  this.props.metering_views_seen.length
      total_available = this.props.feeds.ctaMetadata.metering.freeViews
      display_time = total_available - display_time
    }else {
      type = this.METERING_TYPE.TIME
      total_available = this.props.content.access.timed
      display_time = (this.props.metering_details[this.props.content.identifier] || this.props.content.access.timed )
   }
   if (display_time <=0){
      cta_text = this.props.feeds.ctaMetadata && this.props.feeds.ctaMetadata.completedText
      if (display_time<=0 && type === this.METERING_TYPE.VIEWS){ 
        const resetDays = this.props.feeds.ctaMetadata && this.props.feeds.ctaMetadata.metering && this.props.feeds.ctaMetadata.metering.resetAfter;
        display_time = findDateString(this.props.meterStartDate, resetDays)
      }
   }else if (total_available === display_time) {
     cta_text = this.props.feeds.ctaMetadata && this.props.feeds.ctaMetadata.fullText
   } else{
     cta_text = this.props.feeds.ctaMetadata && this.props.feeds.ctaMetadata.remainingText
   }
    if (total_available === 0){cta_text="Locked"}
    cta_text = cta_text.replace("^", (type == this.METERING_TYPE.TIME ? getMeteringDisplay(display_time,"cta"):display_time ));
    let title = ""
    if (["standard","spotlight", VIDEO_GRID_THEME_THEME, HERO_SLIDER_THEME].includes(this.props.layout)){
      title = <p style={{display: "inline-block"}}><b style={{...fontStyle.primaryFont}}>{this.props.content.title} &nbsp;</b></p>
    }
    if(!isAvailable(this.props.content.identifier,this.props.content.special,this.props.content.registerWall,this.props.content)) {
      return <div className="ctaHolder" id={`content-head-${this.props.content.identifier}`}>
          {title}
          {this.props.layout === VIDEO_GRID_THEME_THEME
            ? <img className="lock-icon" src={lock_mini} onClick={this.purchase}/>
            : <img className="lock-icon" src={this.props.feeds.ctaMetadata && this.props.feeds.ctaMetadata.lockIconUrl} onClick={this.purchase}/>
          }
           <p style={{display: "inline-block"}} onClick={this.purchase}>&nbsp;{cta_text}</p>
           </div>
        } else {
          const noTitleClass = !title ? 'noTitle': "";
          return <div className={`ctaHolder ${noTitleClass}`} >{title}</div>
        }
  }
}

const mapDispatchToProps = {
  openModal: openModal,
  simulatedSubPurchase: simulatedSubPurchase,
}

const mapStateToProps = (state) => ({
  subscription: state.user.subscription,
  metering_details: state.user.metering_details,
  metering_views_seen: state.user.metering_views_seen,
  meterStartDate: state.user.meterStartDate,
  items_purchased: state.user.items_purchased,
  logged_in: state.user.logged_in,
})

export default connect(mapStateToProps, mapDispatchToProps)(Cta)
