import React from 'react';
import {BrowserRouter as Router, Route, Link, Switch, withRouter } from 'react-router-dom'
import { connect } from "react-redux"
import { userActions, logoutUserActions, removeSimulatedSubPurchase, logoutMazTv, setGeolocation, checkVoucherCodeValidity } from "../actions/userActions"
import Background from '../components/Background'
import { clearSavedItems } from "../actions/itemActions"
import { httpsUrl, PLATFORM_HTML_SIMULATOR, PLATFORM_LG, PLATFORM_SAMSUNG, PLATFORM_XBOX } from "../constants/common"
import { openModal } from "../actions/modalActions"
import { setAutoPlay } from '../actions/userActions';
import config from "../constants/config"
import { clearErrorMessage } from "../actions/subscriptionAction";
import * as analytics  from '../constants/analytics';
import configuration from '../constants/config';
import { checkCorrectPlatform, getTabIndex, isAppRunningOnLowEndPlatform, isSmartTv, setGeoFenceInLocal } from '../helperfunctions/common';
import { addRemoveAllNavigation, getBackBtnKeyCodes, getHref, handleNavItemClick, keyMappings } from '../helperfunctions/ctvHelper';
import FocusLock from 'react-focus-lock';
import SwitchSlider from '../components/SwitchSlider';
import { channgeLocalCaption } from '../actions/feedsActions';
import JsSpatialNavigation from '../ctvnavigation/js-spatial-navigation';
import { getAllConstants } from '../helperfunctions/regionalization';
import ParentalLock from '../components/parentalLock';
import { elemOrientation, parentalLockExist } from '../helperfunctions';

const divStyle = {
  height: '100%'
}

let ALL_C = {};
class Settings extends React.Component {
  constructor(){
    super();
    this.logout = this.logout.bind(this);
    this.removeSimulatedPurchase = this.removeSimulatedPurchase.bind(this);
    this.state = {
      currentView: '',
      showVoucherCodeModal: false,
      voucherCode: '',
      codeCheckedOnce: false,
      geoValue: ""
    }
    this.errTimer = null;
    this.keyDownTimer = null;
    ALL_C = getAllConstants();
  }
  componentDidMount(){
    analytics.setPageViewFixedSections('Settings'); 
    handleNavItemClick(this.props.feeds, "navbar-settings")
    if(this.props.logo && !this.props.runningOnMobile){
      var myImage = document.getElementById('settings-logoHolder');
      myImage.addEventListener("load", function(){
        var i = new Image();
        i.src = this.src
        var aspect = i.width/i.height
        if(aspect < 4.0){
          myImage.parentNode.classList.add('smallImg')
        }
      }, {once: true})
    }

    if(isSmartTv() && config.use_light_sliders) {
      this.id_settings = addRemoveAllNavigation({ selector: '.SettingScreen .focusable' });
      this.id_invalid = addRemoveAllNavigation({ selector: '.invalidScreen .focusable' });
      this.id_voucher = addRemoveAllNavigation({ selector: '.voucher-modal .focusable' });
      JsSpatialNavigation.focus('navbar');
    }
  }
  componentWillUnmount() {
    if(isSmartTv() && config.use_light_sliders) {
      addRemoveAllNavigation({ id: this.id_settings });
      addRemoveAllNavigation({ id: this.id_invalid });
      addRemoveAllNavigation({ id: this.id_voucher });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if(prevProps.validVoucherCode !== this.props.validVoucherCode && this.props.validVoucherCode) {
      // valid code applied, close modal
      setTimeout(() => {
        this.closeVoucherCodeModal()
      }, 1000)
    }

    if (isSmartTv() && this.state.showVoucherCodeModal && !prevState.showVoucherCodeModal) {
      document.addEventListener("keyup", this.handleRemoteClick);
    } else if(isSmartTv() && prevState.showVoucherCodeModal && !this.state.showVoucherCodeModal){
      document.removeEventListener("keyup", this.handleRemoteClick);
      JsSpatialNavigation.resume();
    }
  }

  handleRemoteClick = (e) => {
    e.preventDefault()

    const keycode = keyMappings(e.keyCode)
    const back_key_codes = getBackBtnKeyCodes();
    const isbackKeyPressed = back_key_codes[e.keyCode]

    if(isbackKeyPressed){
      this.closeVoucherCodeModal()
    }
    if(document.activeElement === e.target){
      JsSpatialNavigation.pause();
    } else {
      JsSpatialNavigation.resume();
    }

    if(keycode === 'UP' || keycode === 'DOWN'){
      JsSpatialNavigation.resume();
    }

  };

  handleKeyUp(e, callback) {
    if(keyMappings(e.keyCode) == 'OK' && this.keyDownTimer) {
      clearTimeout(this.keyDownTimer)
      this.keyDownTimer = null;
      callback();
    }
  }
  handleKeyDown(e) {
    if(keyMappings(e.keyCode) == 'OK' && !this.keyDownTimer && !this.state.showVoucherCodeModal) {
      this.keyDownTimer = setTimeout(() => {
        this.setState({showVoucherCodeModal: true})
        this.keyDownTimer = null;
      }, 3000)
    }
  }
  confirmVoucherCode(e) {
    e.preventDefault();
    if(this.state.voucherCode != null && this.state.voucherCode != '') {
      this.setState({codeCheckedOnce: true})
      this.props.checkVoucherCodeValidity(this.state.voucherCode, config.app_id)
    }
  }
  handleVoucherCodeChange(voucherCode) {
    this.setState({voucherCode: voucherCode})
  }
  closeVoucherCodeModal() {
    this.setState({showVoucherCodeModal: false, voucherCode: '', codeCheckedOnce: false})
  }
  logout(e){
    if(e){
      e.preventDefault();
    }
    if (this.props.mazTVLogin) {
      this.props.logoutMazTv();
    }
    this.props.logoutUserActions();
    this.props.clearSavedItems();
  }

  removeSimulatedPurchase(e){
    if(e){
      e.preventDefault();
    }
    this.props.removeSimulatedSubPurchase()
    this.props.openModal({type: "subscribe", message: "The simulated purchase has been cancelled."})
  }

  navigateToRoute(route) {
    this.setState({ currentView: route })
    this.props.parentCallback(route);
  }

  onSubscriptionClick = (e) => {
    e.preventDefault();
    this.props.history.push("/apps/" + this.props.app_id + "/subscription");
  }

  logIn = () => {
    this.props.openModal({type: "login", directLogin: true})
  }

  clickEnter = (e) => {
    // console.log('Click enter',e);
    // document.activeElement.click();
    e.srcElement.childNodes[0].click();
  }
  
  onCCPAClick = () => {

    var message = "";
    if(this.props.feeds.ccpa && this.props.feeds.ccpa.ccpaMessage){
      message = this.props.feeds.ccpa.ccpaMessage;
    }else if(this.props.feeds.ccpa && this.props.feeds.ccpa.ccpaMailId){
      message = ALL_C.kDoNotSellData ? ALL_C.kDoNotSellData.replace("%s", this.props.feeds.ccpa.ccpaMailId) : ""
    }
    this.props.openModal({type: "error", message : message})
  }

  onGeoInputPress = (e) => {
    let value = e.target.value;
    this.setState({geoValue: value})
  }

  setCountry = (e) => {
    e.preventDefault();
    const country = this.state.geoValue.toUpperCase()
    this.props.setGeolocation(country)
    setGeoFenceInLocal(true, country)
  }

  onParentalClick = (e) => {
    e.preventDefault();
    this.setState({showParentalOptions: true})
  }

  showGeoInput = () => {
    return (
      <div className="geo-input">
        <input type="text" className="focusable" tabIndex={getTabIndex()} value={this.state.geoValue} onChange={this.onGeoInputPress} placeholder={ALL_C.kEnterCountryCode} dir={elemOrientation(this.props.feeds)}/>
        <button className="focusable" tabIndex={getTabIndex()} onClick={this.setCountry}> {ALL_C.kSetCountry} </button>
      </div>
    )
  }

  removeTransition() {
    if(isAppRunningOnLowEndPlatform()) {
      return 'remove-transition';
    }
    return '';
  }

  renderVoucherCodeModal() {
    let infoText = null;
    let infoClass = null;
    if(this.props.checkingValidVoucherCode) {
      infoText = ALL_C.kValidating;
      infoClass = "green";
    } else if (this.props.validVoucherCode) {
      infoClass = "green";
      infoText = ALL_C.kCodeApplied
    } else if(!this.props.validVoucherCode && this.state.codeCheckedOnce) {
      infoClass = "red";
      infoText = ALL_C.kCodeRejected
    }

    return (
      <FocusLock>
        <div className="voucher-modal">
          <div className="voucher-modal-content">
            <div id="voucher-title" className="title">{config.title}</div>
            <form>
              <input id='voucher-title-input' className="focusable" tabIndex={getTabIndex()} placeholder={ALL_C.kVoucherCode} type="text" onChange={(e) => this.handleVoucherCodeChange(e.target.value)} autoFocus={true} aria-describedby='voucher-title' dir={elemOrientation(this.props.feeds)}/>
              {infoText && <p className={infoClass} aria-live="polite" aria-atomic="true">{infoText}</p>}
              <div className="footer">
                <button className="focusable" tabIndex={getTabIndex()} type="submit" onClick={(e) => this.confirmVoucherCode(e)}>{ALL_C.kConfirm}</button>
                <button className="focusable" tabIndex={getTabIndex()} type="button" onClick={() => this.closeVoucherCodeModal()}>{ALL_C.kClose}</button>
              </div>
            </form>
          </div>
        </div>
      </FocusLock>      
    )
  }

  changeSubtitleSetting = (showSubtitle) => {
    this.props.channgeLocalCaption(showSubtitle);
  }

  toggleAutoplay = (autoplay) => {
    this.props.setAutoPlay(autoplay);
  }

  handleCleengAccount = (type) => {
    this.props.openModal({type: "cleengAccount", cleengComponent: type});
  }

  canShowTvodSubscription = () => {
    const isSubscriptionInFeeds = this.props.feeds?.tvod?.tiers?.subscriptions;
    const isStripePaymentProvider = config.payment_provider == "stripe";
    return this.props.logged_in && isSubscriptionInFeeds && config.isAppTvodType && isStripePaymentProvider
  }

  moveToTvodSubscriptionPage = (e) => {
    e.preventDefault();
    this.props.history.push("/apps/" + this.props.app_id + "/subscriptions");
  }

  renderParentalControlOptions() {
    return (
      <ParentalLock
        goBack={() => this.setState({showParentalOptions: false})}
        runningOnMobile={this.props.runningOnMobile}
      />
    )
  }

  renderParentalControlLink() {
    if(!parentalLockExist(this.props.feeds)) return null;

    return (
      <li>
        <a className={`btn-LogIn focusable ${this.removeTransition()}`} tabIndex={getTabIndex()} { ...getHref('#') } onClick={(e) => this.onParentalClick(e)}>
          {ALL_C.sParentalControl}
        </a>
      </li>
    )
  }

  renderSettingList() {
    var purchase_option =  "";
     purchase_option = <li>
        <a className={`btn-LogIn focusable ${this.removeTransition()}`} tabIndex={getTabIndex()} { ...getHref('#') } onClick={this.onSubscriptionClick}>Subscription</a>
     </li>;
     if ((config.is_simulator && checkCorrectPlatform([PLATFORM_HTML_SIMULATOR])) && this.props.subscription) {
      purchase_option = <li><a className={`btn-LogIn focusable ${this.removeTransition()}`} tabIndex={getTabIndex()} { ...getHref('#') } onClick={this.removeSimulatedPurchase}>Cancel Purchase</a></li>;
    }

    var ccpa = "";
    if(this.props.feeds.ccpa && this.props.feeds.ccpa.ccpaMessage){
      ccpa = <li><a className={`btn-LogIn focusable ${this.removeTransition()}`} tabIndex={getTabIndex()} { ...getHref('#') } onClick={this.onCCPAClick}>{ALL_C.kCcpaMessage}</a></li>;
    }else if(this.props.feeds.ccpa && this.props.feeds.ccpa.ccpaMailId){
      ccpa = <li><a className={`btn-LogIn focusable ${this.removeTransition()}`} tabIndex={getTabIndex()} { ...getHref('#') } onClick={this.onCCPAClick}>{ALL_C.kCcpaMailId}</a></li>;
    }

    const tvodSusbcription = <li>
      <a className={`btn-LogIn focusable ${this.removeTransition()}`} tabIndex={getTabIndex()} { ...getHref('#') } onClick={this.moveToTvodSubscriptionPage}>{ALL_C.kSubscriptions}</a>
    </li>;

    return (
      <>
        {(this.props.logged_in)
          ? <li><a id="log_in_out_btn" className={`btn-LogIn focusable ${this.removeTransition()}`} tabIndex={getTabIndex()} { ...getHref('#') } onKeyDown={(e) => this.handleKeyDown(e)} onKeyUp={(e) => this.handleKeyUp(e, this.logout)} onMouseDown={() => this.handleKeyDown({keyCode: '13'})} onMouseUp={() => this.handleKeyUp({keyCode: '13'}, this.logout)}>{ALL_C.kLogOutText}</a></li>
          : <li><a id="log_in_out_btn" className={`btn-LogIn focusable ${this.removeTransition()}`} tabIndex={getTabIndex()} onKeyDown={(e) => this.handleKeyDown(e)} onKeyUp={(e) => this.handleKeyUp(e, this.logIn)} onMouseDown={() => this.handleKeyDown({keyCode: '13'})} onMouseUp={() => this.handleKeyUp({keyCode: '13'}, this.logIn)}>{ALL_C.kLogIn}</a></li>
        }
        {config.payment_provider === 'cleeng' && this.props.logged_in && (
          <>
            <li><a className={`btn-LogIn focusable ${this.removeTransition()}`} tabIndex={getTabIndex()} { ...getHref('#') } onClick={() => this.handleCleengAccount("account")}>{ALL_C.kMyAccount}</a></li>
            {/* <li><a className={`btn-LogIn focusable ${this.removeTransition()}`} tabIndex={getTabIndex()} { ...getHref('#') } onClick={() => this.handleCleengAccount("subscriptions")}>Subscriptions</a></li> */}
          </>
        )}
        {this.props.has_subs && purchase_option}
        {this.canShowTvodSubscription() && tvodSusbcription }
        {this.props.feeds.ccpa && ccpa}
        {this.props.showSubSetting && <div className='cc-div'>
          <span id="cc-text">{ALL_C.kISCloseCaptioning}</span>
          <SwitchSlider 
            isChecked={this.props.showSubtitle}
            onSliderChange={this.changeSubtitleSetting}
            ariaId={"cc-text"}
          />
        </div>
        }
        <div className='cc-div'>
          <span id="cc-text-auto">{ALL_C.kAutoPlay}</span>
          <SwitchSlider 
            isChecked={this.props.autoPlay}
            onSliderChange={(val) => this.toggleAutoplay(val) }
            ariaId={"cc-text-auto"}
          />
        </div>
        {config.env !== 'production' && this.props.feeds.isGeoFence && this.showGeoInput()}
        {this.renderParentalControlLink()}
      </>
    )
  }

  render(){
    document.body.className = "settings";
    if(this.state.showParentalOptions) {
      return this.renderParentalControlOptions()
    }
    return (
      <div style={divStyle} className={this.props.runningOnMobile ? "mobile-view" : ""}>
          <Background />
          <div style={divStyle}>
              <div className="logIn-container">
                {this.props.logo && !this.props.runningOnMobile && <div id="settings-logoHolder" className="settings-logoHolder"><img src={httpsUrl(this.props.logo.url)} /></div>}
                <div className="SettingScreen" dir={elemOrientation(this.props.feeds)}>
                  <ul>
                    {this.renderSettingList()}
                  </ul>
                </div>
              </div>
          </div>
          {this.state.showVoucherCodeModal && this.renderVoucherCodeModal()}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  logged_in: state.user.logged_in,
  subscription: state.user.subscription,
  allSubscription: state.subscription,
  mazTVLogin: state.user.mazTVLogin,
  checkingValidVoucherCode: state.user.checkingValidVoucherCode,
  validVoucherCode: state.user.validVoucherCode,
  showSubSetting: state.feeds.showSubSetting,
  showSubtitle: state.feeds.showSubtitle,
  autoPlay: state.user.autoPlay,
})

const mapDispatchToProps = {
  openModal: openModal,
  setAutoPlay: setAutoPlay,
  removeSimulatedSubPurchase: removeSimulatedSubPurchase,
  userActions: userActions,
  logoutUserActions: logoutUserActions,
  clearSavedItems: clearSavedItems,
  clearErrorMessage,
  logoutMazTv,
  setGeolocation,
  checkVoucherCodeValidity,
  channgeLocalCaption
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Settings))
