import axios from "axios";
import { getMazCoreEnvUrl } from '../constants/initializer'
import { getUSerFeedHistoryOrProgress } from './itemActions'

export function localRememberSpot(app_id, identifier, played, total, saved, type, logged_in, auth_token, user_id, contentId) {
  var rememberSpot = {}
  if(contentId) {
    rememberSpot[identifier] = {p: played, t: total, saved: saved, type: type, contentId: contentId}
  } else {
    rememberSpot[identifier] = {p: played, t: total, saved: saved, type: type}
  }
  return function(dispatch) {
    dispatch({type: "LOCAL_REMEMBER_SPOT",payload: rememberSpot, app_id: app_id})
    if(logged_in){
      var url = getMazCoreEnvUrl() + '/items/remember_all'
      var req_data = 'auth_token=' + auth_token + '&user_id='+ user_id + '&items=' + JSON.stringify(rememberSpot) + '&web=true'
      axios.post(url, encodeURI(req_data), {headers:{'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'}})
        .then((response) => {
          if (response.data.success) {
            dispatch({type: "SAVE_REMEMBER_SPOT_BATCH", payload: response.data, user_id: user_id})
            dispatch({type: "GET_REMEMBER_SPOT_SYNC", payload: response.data.items, user_id: user_id})
          }
      })
    }
  }
}

export function localRememberSpotBatch(app_id, data, logged_in, auth_token, user_id) {
  var rememberSpot = data
  return function(dispatch) {
    dispatch({type: "LOCAL_REMEMBER_SPOT",payload: rememberSpot, app_id: app_id})
    if(logged_in){
      const apiParams = {
        auth_token: auth_token,
        user_id: user_id,
        combo_app_id: app_id
      }
      var url = getMazCoreEnvUrl() + '/items/remember_all'
      var req_data = 'auth_token=' + auth_token + '&user_id='+ user_id + '&items=' + JSON.stringify(rememberSpot) + '&web=true'
      axios.post(url, encodeURI(req_data), {headers:{'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'}})
        .then((response) => {
          if (response.data.success) {
            dispatch({type: "SAVE_REMEMBER_SPOT_BATCH", payload: response.data, user_id: user_id})
            dispatch({type: "GET_REMEMBER_SPOT_SYNC", payload: response.data.items, user_id: user_id})
            dispatch(getUSerFeedHistoryOrProgress({...apiParams, filterType: 'history'}));
            dispatch(getUSerFeedHistoryOrProgress({...apiParams, filterType: 'progress'}));
          }
        }).catch(err => {
          console.log("error occured!!");
        })
    }
  }
}

export function saveRememberSpotBatch(auth_token, user_id, rememberItems){
  var url = getMazCoreEnvUrl() + '/items/remember_all'
  var req_data = 'auth_token=' + auth_token + '&user_id='+ user_id + '&items=' + rememberItems + '&web=true'
  return function(dispatch) {
    axios.post(url, encodeURI(req_data), {headers:{'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'}})
      .then((response) => {
        if (response.data.success) {
          dispatch({type: "SAVE_REMEMBER_SPOT_BATCH", payload: response.data, user_id: user_id})
          dispatch({type: "GET_REMEMBER_SPOT_SYNC", payload: response.data.items, user_id: user_id})
        }
      })
  }
}

export function getAllRememberSpot(auth_token, user_id){
  var url = getMazCoreEnvUrl() + '/items/remembered?auth_token=' + auth_token + '&user_id=' + user_id
  return function(dispatch) {
    axios.get(url)
      .then((response) => {
        if (response.data && response.status === 200) {
          dispatch({type: "GET_REMEMBER_SPOT_SYNC", payload: response.data, user_id: user_id})
        }
      })
  }
}

export function checkAllRememberSpot(params){
  return function(dispatch, getState) {
    try {
      const state = getState();
      const {remember, fetched_from_server} = state.remember
      if(Object.keys(remember).length > 0){
        var notSaved = 0
        var keys = Object.keys(remember)
        for(var i=0; i < keys.length; i++){
          if(!remember[keys[i]].saved){
            notSaved = notSaved + 1
            break;
          }
        }
        if(notSaved){
          dispatch(saveRememberSpotBatch(params.auth_token, params.user_id, JSON.stringify(remember)))
        }
      } else if(!fetched_from_server){
        dispatch(getAllRememberSpot(params.auth_token, params.user_id))
      }
    } catch(error) {
      dispatch({type: "REMEMBER_SPOT_ERROR", payload: error})
    }
  }
}