import React from 'react';
import FacebookLogin from 'react-facebook-login';
import ErrorDisplay from "./ErrorDisplay";
import WebsiteLogin from './WebsiteLogin';
import { connect, useSelector } from "react-redux"

import { fbUserActions, generateWebsiteLoginToken, generateTVELoginToken } from "../actions/userActions"
import configuration from '../constants/config';
import * as analytics  from '../constants/analytics';
import Loader from '../components/Loader';
import { AUTH_ON_SCREEN, AUTH_WEBSITE, PLATFORM_HTML_SIMULATOR } from '../constants/common';
import { checkCorrectPlatform, getTabIndex, isSmartTv } from '../helperfunctions/common';
import { getGdprParamsForApi } from '../helperfunctions/feedHelper';
import { checkIfSingleOption, checkIfOnlyOneLoginIsPresent } from "../helperfunctions/common"
import FbDeviceLogin from './FbDeviceLogin';


const FbIconImage = () => <img src={require('../images/icons/fb-white.png')} className="fb-icon"/>
const isTvod = configuration.isAppTvodType;
const hasWebsiteLogin = configuration.tv.kHasWebsiteLogin === false ? false : true

class LoginScreen extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            error: false,
            errorHeading: '', 
            errorMsg: '',
            showWebsiteLogin: false,
            showWebsiteForTvodCtv: null,
            showFbLogin: null
        }
        this.responseFacebook = this.responseFacebook.bind(this);
        this.tveLoginMode = this.props.feeds.tve && this.props.feeds.tve.data && this.props.feeds.tve.mode;
    }

    componentDidMount() {
        if (document.getElementsByTagName('body')[0].classList.contains('detail')) {
            document.getElementsByTagName('body')[0].classList.add('overflow-scroll-auto');
        }
        if (this.props.openWebsiteLogin && this.props.userConcent) {
            this.props.generateWebsiteLoginToken(configuration.app_id, getGdprParamsForApi(this.props.feeds))
        }

        if(!this.props.logged_in)
            analytics.setPageViewFixedSections('Registration Screen');

        if(this.props.openFacebookTab && this.props.userConcent && !isSmartTv()) {
            setTimeout(() => {
                // open facebook fage
                if(document.querySelector('.logInScreen .btn-fbIcon')) {
                    document.querySelector('.logInScreen .btn-fbIcon').click();
                }
            },1000)
        }

        /* Render login screen directly if only one login way is present */
        this.handleSingleLoginRender();

        //Directly do OAuth if direct login is true and No other login type is present, instead of Oauth
        //Not to do OAuth if Api is already get called.
        if (!(this.props.openWebsiteLogin && this.props.userConcent) && checkIfSingleOption(this.getScreenType(), this.props.feeds) && this.props.directLogin && !this.props.logged_in && !this.tveLoginMode) {
            this.onWebsiteAuthClick();
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.user_error && (this.props.user_error || (this.props.user.error && !this.props.logged_in))) {
            this.setState({error: true, errorHeading: "Request error", errorMsg: this.props.user.error})
        }
        if (prevProps.getWebsiteLoginLoading && !this.props.getWebsiteLoginLoading && this.props.websiteLoginData && this.props.websiteLoginData.success) {
            this.setState({showWebsiteLogin: true})
        }
        if (!prevProps.openWebsiteLogin && this.props.openWebsiteLogin && this.props.userConcent) {
            this.props.generateWebsiteLoginToken(configuration.app_id, getGdprParamsForApi(this.props.feeds))
        }
    }

    componentWillUnmount() {
        if (document.getElementsByTagName('body')[0].classList.contains('detail')) {
            document.getElementsByTagName('body')[0].classList.remove('overflow-scroll-auto');
        }
    }

    dismissAction = (e) => {
        if(e){
          e.preventDefault();
        }
        this.setState({error: false})
    }
    responseFacebook = (response) =>{
        if (response.accessToken && !(Object.keys(response).length === 0 && response.constructor === Object && !this.props.logged_in)){
            const gdprParams = getGdprParamsForApi(this.props.feeds);
            this.props.fbUserActions(response.email, response.accessToken, response.name, this.props.app_id, gdprParams)
        }
    }
    handleSubscriptionRestore(event) {
        event.preventDefault();
        if (!configuration.is_simulator || !checkCorrectPlatform([PLATFORM_HTML_SIMULATOR]))
            this.props.onClick(event);
    }

    handleFbSelect = (e) => {
        e.preventDefault();
        this.props.onAPIDataReady(e)
    }

    handleSingleLoginRender = () => {
        const screen = this.getScreenType();
        
        const showWebsiteForTvodCtv = isTvod && hasWebsiteLogin && this.props.feeds.loginUIMetadata[screen].authType === AUTH_ON_SCREEN && !checkCorrectPlatform(["web_as_output","preview"]);
        const showFbLogin  = configuration.fb_app_id && this.props.feeds.loginUIMetadata[screen].fb && (isTvod ? checkCorrectPlatform(["web_as_output"]) : true);
        
        const { subscriberHeader } = this.props.feeds.loginUIMetadata[screen].strings;
        const showSubscriberHeader = configuration.is_simulator && checkCorrectPlatform([PLATFORM_HTML_SIMULATOR]) && subscriberHeader;
        
        const { oneLoginPresent, screenToBeRender } = checkIfOnlyOneLoginIsPresent(screen, this.props.feeds, Boolean(this.tveLoginMode), showWebsiteForTvodCtv, showFbLogin, Boolean(showSubscriberHeader));

        this.setState({showFbLogin: showFbLogin, showWebsiteForTvodCtv: showWebsiteForTvodCtv})

        if(oneLoginPresent && screenToBeRender == "authOnScreen") this.props.isSingleLogin();
    }

    renderLogin() {
        if (isSmartTv()) {
            return <FbDeviceLogin 
                onGdprClick={this.handleFbSelect}
                isUserConcent={this.props.userConcent}
                callback={this.responseFacebook}
                isGdpr={this.props.feeds.gdpr}
                openFbTab={this.props.openFacebookTab}
                cancelFbLogin={this.props.cancelFbLogin}
            />
        }
        if(this.props.openFacebookTab || this.props.userConcent) {
            return (
                <FacebookLogin
                    appId={configuration.fb_app_id}
                    fields="name,email"
                    callback={this.responseFacebook}
                    cssClass="btn-fbIcon"
                    textButton="Log In with Facebook"
                    icon={<FbIconImage/>}
                    disableMobileRedirect={true} // to work in mobile devices
                />
            )
        } else {
            return <button className="btn-fbIcon" onClick={(e) => this.handleFbSelect(e)}><FbIconImage/> Log In with Facebook</button>
        }
    }

    onWebsiteAuthClick = (e, hasTVELogin) => {
        e && e.preventDefault();
        if (this.props.feeds.gdpr)
            this.props.onAPIDataReady(null, true)
        else if(hasTVELogin){
            const software_statement = this.props.feeds.tve.data.software_statement
            const requestor = this.props.feeds.tve.data.requestor
            const generateTokenData = [configuration.app_id, getGdprParamsForApi(this.props.feeds)]
            const tve_provider = this.props.feeds.tve.provider
            this.props.generateTVELoginToken({software_statement, requestor, tve_provider, generateTokenData})
        }
        else
            this.props.generateWebsiteLoginToken(configuration.app_id, getGdprParamsForApi(this.props.feeds));
    }

    /**
     * Function to get login screen type. (firstSave or registrationWall)
     */
    getScreenType = () => {
        if (isTvod) return "tvodLogin"
        let screen = "firstSave"
        if (this.props.screen === "registerWall"){
          screen = "registrationWall"
        }
        return screen;
    }

    stopWebsiteLoading = () => {
        this.setState({
            showWebsiteLogin: false
        })
    }
    handleTVELoginClick = (e) => {
        this.onWebsiteAuthClick(e,true)
    }

    render() {
        const screen = this.getScreenType();
        const { showWebsiteForTvodCtv, showFbLogin } = this.state;
        const {subscriberHeader, restore} = this.props.feeds.loginUIMetadata[screen].strings;
        const {bgButton, buttonLabel} = this.props.feeds.loginUIMetadata.colors;
        if((document.getElementsByClassName("user-access")[0] !== undefined) && (document.getElementsByClassName("user-access")[0].getElementsByClassName('logoHolder')[0] !== undefined))
            document.getElementsByClassName("user-access")[0].getElementsByClassName('logoHolder')[0].style.display = "block";
        if(this.state.error){
            return <ErrorDisplay dismiss={this.dismissAction} errorMsg={this.state.errorMsg} errorHeading={this.state.errorHeading} />
        }
        if (this.props.fbLoginLoading || this.props.getWebsiteLoginLoading || this.props.logged_in) {
            return (<Loader />)
        }
        if (this.state.showWebsiteLogin && this.props.websiteLoginData && this.props.websiteLoginData.success) {
            return (<WebsiteLogin 
                data={this.props.websiteLoginData}
                feeds={this.props.feeds}
                stopLoading={this.stopWebsiteLoading}
            />)
        }

        let margin_tv = null;
        if(isSmartTv()) {
            // margin_tv = 0;
        }

        const loginButtonstyle={color: this.props.feeds.loginUIMetadata.colors.buttonLabel, backgroundColor: this.props.feeds.loginUIMetadata.colors.bgButton,margin: margin_tv}
        const titleMessageStyle={color: this.props.feeds.loginUIMetadata.colors.headerText || "#FFD626FF"}

        const renderPrimeTime = () => (
          <PrimetimeSignIn
            loginButtonstyle={loginButtonstyle}
            titleMessageStyle={titleMessageStyle}
            onClick={this.handleTVELoginClick}
            mode={this.tveLoginMode}
          />
        );
      
        if (this.tveLoginMode === "standalone") {
          return (
            <div className="logInScreen">
                <p id="title_msg" style={titleMessageStyle}>
                {this.props.titleMsg || this.props.feeds.loginUIMetadata[screen].strings.header}
                </p>
              {renderPrimeTime()}
            </div>
          );
        }

        return (
            <div className="logInScreen">
                <p id="title_msg" style={titleMessageStyle}>
                {this.props.titleMsg || this.props.feeds.loginUIMetadata[screen].strings.header}
                </p>
                {this.props.feeds.loginUIMetadata[screen].authType === AUTH_ON_SCREEN && this.props.feeds.loginUIMetadata[screen].em &&
                    <a aria-label={`${this.props.titleMsg || this.props.feeds.loginUIMetadata[screen].strings.header}. ${this.props.feeds.loginUIMetadata[screen].strings.emailButton}`} href="#" onClick={this.props.onClick} className="btn-email continue-btn focusable" tabIndex={getTabIndex()} style={{color: this.props.feeds.loginUIMetadata.colors.buttonLabel, backgroundColor: this.props.feeds.loginUIMetadata.colors.bgButton}}>
                        <span className="mail-icon"></span> {this.props.feeds.loginUIMetadata[screen].strings.emailButton}</a>
                }
                <br/>
                {this.props.feeds.loginUIMetadata[screen].authType === AUTH_WEBSITE &&
                    <a aria-describedby='title_msg' id="web_login_id" href="#" onClick={this.onWebsiteAuthClick} className="btn-email continue-btn focusable" tabIndex={getTabIndex()} style={loginButtonstyle}>
                        <span className="website-icon"></span> {this.props.feeds.loginUIMetadata[screen].strings.websiteButton}</a>
                }
                <br/>
                {showWebsiteForTvodCtv &&
                    <>
                        <a id="web_login_id" href="#" onClick={this.onWebsiteAuthClick} className="btn-email continue-btn focusable" tabIndex={getTabIndex()} style={loginButtonstyle}>
                            <span className="website-icon"></span> {this.props.feeds.loginUIMetadata[screen].strings.websiteButton}
                        </a>
                        <br/>
                    </>
                }
                
                {this.tveLoginMode && renderPrimeTime()}

                {/* ---------facebook register/login------ */}
                {showFbLogin &&
                    (this.renderLogin())
                }
                {configuration.is_simulator && checkCorrectPlatform([PLATFORM_HTML_SIMULATOR]) && subscriberHeader && 
                    (<div className="subscriberHeader">
                        <p>{subscriberHeader}</p>
                            <a className="focusable" tabIndex={getTabIndex()} onClick={(e) => this.handleSubscriptionRestore(e)} style={{color: buttonLabel, backgroundColor: bgButton}} >{restore}</a>
                    </div>)
                }
            </div>
            )
    }

}

const mapStateToProps = (state) => ({
    user: state.user.user,
    logged_in: state.user.logged_in,
    user_error: state.user.error,
    fbLoginLoading: state.user.fbLoginLoading,
    getWebsiteLoginLoading: state.user.getWebsiteLoginLoading,
    websiteLoginData: state.user.websiteLoginData,
    modal: state.modal.modal
})

const mapDispatchToProps = {
    fbUserActions: fbUserActions,
    generateWebsiteLoginToken,
    generateTVELoginToken,
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen)


export const PrimetimeSignIn = ({
  onClick,
  mode,
  loginButtonstyle,
  titleMessageStyle
}) => {
  const {
    feeds: { tve }
  } = useSelector(({ feeds }) => feeds);

  const { localisedMetadata } = tve;
  const { kAccessThrough, kIMSub, kOr, kSign } = localisedMetadata; // kAccessThrough: "Access through a TV provider"; kIMSub: "I'm a TV SUBSCRIBER"; kOr: "OR"; kSign: "Sign In";
  return (
    <>
      {mode !== "standalone" && (
        <label className="TVE-login-separator" style={titleMessageStyle}>
          {kOr}
        </label>
      )}
      <p className={`TVE-login-signin-label`} style={titleMessageStyle}>
        {kSign}
      </p>
      <p className="TVE-access-p">
        <label className={"TVE-access-label"} style={titleMessageStyle}>
          {kAccessThrough}
        </label>
      </p>
      <a
        id="web_login_id"
        href="#"
        className="btn-email continue-btn focusable"
        tabIndex={getTabIndex()}
        style={loginButtonstyle}
        onClick={onClick}
        aria-label={kAccessThrough + "." + kIMSub}
      >
        {kIMSub}
      </a>
    </>
  );
};