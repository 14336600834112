import React from 'react'
import { isSmartTv } from '../helperfunctions/common';
import backMobile from "../images/icons/back-mobile.png";
import { Link } from 'react-router-dom'

function BackButton({backUrl, runningOnMobile, onCTVBackClick=()=>{}}) {
  const backButtonVisibility = (isShow) => {
    const displayVal = isShow ? "flex": "none"
    const backBtn = document.querySelector('a#main_back_id') || document.querySelector('a#main_mobile_back_button');
    backBtn && (backBtn.style.display = displayVal)
  }

  React.useEffect(() => {
    backButtonVisibility(false)
    return () => {
      backButtonVisibility(true)
    }
  }, [])

  const tv_style = {};
  if (isSmartTv()) {
    tv_style.display = "none";
  }
  if (runningOnMobile) {
    return (
      <span className="icon-hollow_arrow backBtnSpan backBtnSpan-mobile">
        <Link
          className="backBtn-mobile"
          style={{ zIndex: 101 }}
          to={backUrl}
        >
          <img src={backMobile} alt="Back" />
        </Link>
      </span>
    );
  }
  return (
    <span
      className="icon-hollow_arrow backBtnSpan"
      style={{ ...tv_style }}
    >
      <Link
        id="main-tv-back-button"
        className="backBtn"
        style={{ zIndex: 101 }}
        to={backUrl}
        onClick={onCTVBackClick}
      />
    </span>
  );
}

export default BackButton