import React from "react";

const color_code = {
  success: '#2cbc0e',
  error: '#e74c3c'
};

const getSvg = (type) => {
  if(type == 'success') {
    return(
      <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 22" width="24px" fill="white">
        <path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8z"/>
      </svg>
    );
  } else {
    return(
      <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 22" width="24px" fill="white">
        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"/>
      </svg>
    );
  }
}

const Toast = (props) => {
  const { type, msg } = props;

  return(
    <>
      <div className="custom-toast" style={{ background: color_code[type] }}>
        { getSvg(type) }
        { msg }
      </div>
    </>
  );
}

export default Toast;