import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import { getTvodSubscriptionDetails } from '../../actions/subscriptionAction';
import Background from '../../components/Background';
import Loader from '../../components/Loader';
import List from './List';
import TvodSubcriptionItem from './TvodSubcriptionItem';

function TvodSubscriptions(props) {
  const backgroundColor = "#22272e";
  const path = props.match.path;
  const {feeds, tvodSubscriptionList, runningOnMobile} = props
  const commonProps = {feeds, tvodSubscriptionList, runningOnMobile}
  const settingsPath = `${path.split("/subscriptions")[0]}/settings`

  useEffect(() => {
    props.getTvodSubscriptionDetails()
  }, [])

  if (!props.logged_in) {
    props.history.push(settingsPath)
  }

  if (props.tvodSubscriptionListLoading) {
    return <Loader />
  }
  
  return (
    <div className='tvod-sub-container display-flex'>
      <Background background = {{color: backgroundColor}}/>
      <Switch>
        <Route exact path={`${path}/:id`}>
          <TvodSubcriptionItem {...commonProps}/>
        </Route>
        <Route>
          <List {...commonProps}/>
        </Route>
      </Switch>
    </div>
  )
}

const mapStateToProps = (state) => ({
  tvodSubscriptionList: state.subscription.tvodSubscriptionList,
  tvodSubscriptionListLoading: state.subscription.tvodSubscriptionListLoading,
  logged_in: state.user.logged_in
});

const connectDispatchToProps = {
  getTvodSubscriptionDetails
};

export default withRouter(connect(mapStateToProps, connectDispatchToProps)(TvodSubscriptions))