import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getVirtualListData, refreshFavoriteDynamicList } from '../actions/itemActions';
import { environment } from '../constants/initializer';
import { getDynamicListKey } from '../helperfunctions';
import { getAllConstants } from '../helperfunctions/regionalization';
import Content from './Content';


export function DynamicList(props) {
  const { propData, content, isSection } = props;
  const logged_in = useSelector((state) => state.user.logged_in);
  const dynamicList = useSelector((state) => {
    const key = getDynamicListKey(content);
    return state.items.dynamicLists[key]
  });
  const refreshFavDynamicList = useSelector((state) => state.items.refreshFavDynamicList);
  const dispatch = useDispatch()

  useEffect(() => {
    if(!dynamicList || !dynamicList.apiCallMade) {
      const key = getDynamicListKey(content);
      const uniqueKey = content.cid === key ? null : key;
      dispatch(getVirtualListData(content.cid, 1, uniqueKey))
    }
  }, [logged_in])

  useEffect(() => {
    if(refreshFavDynamicList && content.catalog === "favorite") {
      dispatch(getVirtualListData(content.cid, 1, "favorite"));
      dispatch(refreshFavoriteDynamicList(false))
    }
  }, [refreshFavDynamicList])


  if(!dynamicList) return null;
  // dynamic list loading on section level
  if(isSection && dynamicList.pending) {
    return (
      <div id="loader" className="loader">
        <img src={"https://s3.amazonaws.com/resources.magappzine.com/assets/core/" + environment() + "/loader.gif"}/>
      </div>
    )
  }
  if(!dynamicList.content || dynamicList.content.length === 0) {
    if(!isSection) return null;
    const { kPVCNoItems } = getAllConstants();
    return (
      <div className="dynamic-list-no-item">{kPVCNoItems}</div>
    )
  };

  if(isSection && props.onListLoad) {
    props.onListLoad(dynamicList.content);
  }

  // render dynamic list
  const newContent = {
    ...content,
    contents: dynamicList.content
  }
  return (
    <Content
      {...propData}
      content={newContent}
      isDynamicList={true}
    />
  )
}
