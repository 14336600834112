import { useState } from 'react';
import { ArrowKeyStepper, Grid, AutoSizer } from 'react-virtualized';

const VirtualGrid = ({ contents, render, columnCount = 3, rtl }) => {
  const [mode] = useState('cells');
  const [isClickable, setIsClickable] = useState(true);
  const [scrollToColumn, setScrollToColumn] = useState(0);
  const [scrollToRow, setScrollToRow] = useState(0);

  const getColumnWidth = () => {
    return (window.innerWidth / columnCount) * 0.9;
  };

  const getRowHeight = () => {
    return (window.innerWidth / columnCount) * 0.8;
  };

  const cellRenderer = ({
    columnIndex,
    key,
    rowIndex,
    scrollToColumn,
    scrollToRow,
    style,
  }) => {
    const content = contents[rowIndex * columnCount + columnIndex];
    if(!content) return null
    return (
      <span
        role="none"
        className={`Cell ${
          columnIndex === scrollToColumn && rowIndex === scrollToRow
            ? 'FocusedCells'
            : ''
        }`}
        key={key}
        onClick={
          isClickable &&
          (() =>
            selectCell({
              scrollToColumn: columnIndex,
              scrollToRow: rowIndex,
            }))
        }
        style={{ ...style }}
      >
        {render({ content })}
      </span>
    );
  };

  const selectCell = ({ scrollToColumn, scrollToRow }) => {
    setScrollToColumn(scrollToColumn);
    setScrollToRow(scrollToRow);
  };

  const onClickableChange = (event) => {
    if (event.target instanceof HTMLInputElement) {
      setIsClickable(event.target.checked);
      setScrollToColumn(0);
      setScrollToRow(0);
    }
  };

  return (
    <ArrowKeyStepper
      columnCount={columnCount}
      isControlled={true}
      onScrollToChange={true ? selectCell : undefined}
      mode={mode}
      rowCount={Math.ceil(contents.length / columnCount)}
      scrollToColumn={scrollToColumn}
      scrollToRow={scrollToRow}
    >
      {({ onSectionRendered, scrollToColumn, scrollToRow }) => (
        <div>
          <AutoSizer disableHeight>
            {({ width }) => (
              <Grid
                className="Grid"
                columnWidth={getColumnWidth()}
                columnCount={columnCount}
                height={window.innerHeight * 0.7}
                onSectionRendered={onSectionRendered}
                cellRenderer={cellRenderer}
                rowHeight={getRowHeight()}
                rowCount={Math.ceil(contents.length / columnCount)}
                scrollToColumn={scrollToColumn}
                scrollToRow={scrollToRow}
                width={width}
                // containerStyle={{direction: rtl}}
              />
            )}
          </AutoSizer>
        </div>
      )}
    </ArrowKeyStepper>
  );
};

export default VirtualGrid;