import { v4 as uuidv4 } from 'uuid';
import { checkCorrectPlatform } from './common';
import { PLATFORM_LG, PLATFORM_SAMSUNG, PLATFORM_VIZIO, PLATFORM_XBOX, PLATFORM_ZEASN } from '../constants/common';
import { getSessionStorage, setSessionStorage } from './storage';
import store from '../store';

export function getUniqueSessionId(key = "uniqueSessionId") {
    let sessionId = getSessionStorage(key)
    
	if(!sessionId){
		sessionId = uuidv4();
		setSessionStorage(key, sessionId);
		return sessionId;
	} 

	return sessionId;
}

export function getSamsungAdInfoDetails(){
	let ifa, ifa_type, lmt
	try {
		ifa = window.webapis.adinfo.getTIFA()
		lmt = window.webapis.adinfo.isLATEnabled()
		ifa_type = "tifa"
	} catch (error) {
		ifa = getUniqueSessionId();
		lmt = 0;
		ifa_type = "sessionid"
	}
	return { ifa, ifa_type, lmt };
}

export function ifaMacrosFun() {
	let appState = store.getState();
    const sessionId = getUniqueSessionId();
	const current_device_uuid = appState.user.device_uuid;

    let ifaMacros = {
		SESSION_ID: sessionId ? sessionId : "",
		IFA: "",
		IFA_TYPE: "",
		LMT: 0
	}

	if(!sessionId) return ifaMacros;

    if(checkCorrectPlatform([PLATFORM_SAMSUNG]) && window.tizen){
		const { ifa, ifa_type, lmt} = getSamsungAdInfoDetails()
		ifaMacros.IFA = ifa;
		ifaMacros.IFA_TYPE = ifa_type;
		ifaMacros.LMT = lmt;
	} else if(checkCorrectPlatform([PLATFORM_LG]) && window.webOS) {
		ifaMacros.IFA = current_device_uuid ? current_device_uuid : sessionId;
		ifaMacros.IFA_TYPE = current_device_uuid ? "dpid" : "sessionid";
		ifaMacros.LMT = 0
	} else if(checkCorrectPlatform([PLATFORM_VIZIO]) && window.VIZIO) {
		ifaMacros.IFA = window.VIZIO.ad_id.LMT ? sessionId :  window.VIZIO.ad_id.IFA;
		ifaMacros.IFA_TYPE =  window.VIZIO.ad_id.LMT ? "sessionid" : window.VIZIO.ad_id.IFA_TYPE;
		ifaMacros.LMT = window.VIZIO.ad_id.LMT ? 1 : 0;
    } else if(checkCorrectPlatform([PLATFORM_ZEASN] && window.onDeviceInfoReady)) {
        ifaMacros.IFA = current_device_uuid ? current_device_uuid : sessionId;
        ifaMacros.IFA_TYPE = current_device_uuid ? "dpid" : "sessionid";
        ifaMacros.LMT = 0
	} else if(checkCorrectPlatform([PLATFORM_XBOX])) {
		let xboxIFA = window.Windows.System.UserProfile.AdvertisingManager.advertisingId;
		ifaMacros.IFA = xboxIFA || sessionId;
        ifaMacros.IFA_TYPE = xboxIFA ? "msai" : "sessionid";
        ifaMacros.LMT = 0
	} else {
		ifaMacros.IFA = sessionId
        ifaMacros.IFA_TYPE = "sessionid"
        ifaMacros.LMT = 0
	}

	return ifaMacros;
}

export function ifaMacrosAddition(url) {
	try { 
		let ifaMacros = ifaMacrosFun();
		let adURL = url;

		for(let key in ifaMacros) {
			adURL = adURL.replace(`[[${key}]]`, encodeURIComponent(ifaMacros[key]))
		}

		return adURL;
	}
	catch (err) {
		return url;
	}
}

