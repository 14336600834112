import React from 'react';
import { connect } from "react-redux";
import Cta from "../components/Cta";
import { JUMBO_THEME } from '../constants/common';
import configuration from '../constants/config';
import config from '../constants/config';
import { getFontStyleFromFeed, getSwiperParamsByTheme, getThumnailViewClass, isContentPortrait, is2by3PosterImage } from "../helperfunctions";
import { checkIfEmptyMazIdFeedsInContents, getTabIndex, focusVideoItemWhichWerePlayed, isSmartTv } from '../helperfunctions/common';
import { makeContentFocusableSection, removeContentFromFocusableSection, convertSwiperParamsToSlick } from '../helperfunctions/ctvHelper';
import CustomSlider from './CustomSlider';

class JumboContent extends React.Component {
  constructor(props){
    super(props);
    this.swiper = null
    this.state = {
      currentView: '',
    }
    this.swiperParams = null;
    this.generateSwiperParam();
  }

  componentDidMount() {
    const swiperCont = document.querySelector(`.content-${this.props.content.identifier} .swiper-container`);
    this.swiper = swiperCont && swiperCont.swiper;
    if(isSmartTv() && config.use_light_sliders) {
      makeContentFocusableSection({
        disableDefaultSection: true,
        content_identifier: this.props.content.identifier
      }).then(val => this.sn_id = val)
      .catch(err => console.log("error",err))
    }
  }
  
  componentWillUnmount() {
    if(isSmartTv() && config.use_light_sliders) {
      removeContentFromFocusableSection({
        enableDefaultSection: true,
        content_identifier: this.props.content.identifier,
        id: this.sn_id
      });
    }    
  }

  componentDidUpdate(){
    if(Object.keys(this.props.modal_progress).length > 0 && this.props.modal_progress.head === this.props.content.identifier){
      // this.swiper.slideTo(this.props.modal_progress.index)
      focusVideoItemWhichWerePlayed(this.props.content.identifier, this.props.modal_progress.item)
    }
  }

  nextSlide = () => {
    const _self = this;
    _self.swiper && _self.swiper.slideNext();
  }

  prevSlide = () => {
    const _self = this;
    _self.swiper && _self.swiper.slidePrev();
  }

  generateSwiperParam = () => {
    const _self = this;
    _self.isPortrait = isContentPortrait(this.props.content, this.props?.currentSection);
    _self.is2by3PosterImage = is2by3PosterImage(this.props.feeds)
    this.swiperParams = getSwiperParamsByTheme(_self, this.props.content, JUMBO_THEME, null);
  }


  render(){
    const { feeds, content, currentSection } = this.props;
    const fontStyle = getFontStyleFromFeed(feeds);
    let wrapSavedSectionItems = feeds.extras && feeds.extras.wrapSavedSectionItems;
    const thumbClass = getThumnailViewClass(content, currentSection);
    let jumboClassName = `slider-box jumbo-view ${thumbClass} content-${content.identifier}`;
    // let params = getSwiperParamsByTheme(this, content, JUMBO_THEME)

    let slick_params = null;
    if(isSmartTv() &&  configuration.use_light_sliders) {
      slick_params = convertSwiperParamsToSlick(this.swiperParams, this);
      if(wrapSavedSectionItems) slick_params.lazyLoad = "progressive";
    }
    if(checkIfEmptyMazIdFeedsInContents(content.contents))
      return null;

    return (
      <div className={jumboClassName}>
        {content.locked && <Cta layout={"jumbo"} time={Date.now()} content={content} feeds={feeds}/>}
        {!this.props.showingSavedSection && <h2 id={`content-head-${content.identifier}`} style={fontStyle.primaryFont}>{content.feedTitle || this.props.title}</h2>}
        {
          isSmartTv() && config.enableCtvVirtualSlides && (
          <>
            <div data-sn-right="" className={`swiper-button-next t${content.identifier}-nextEl focusable`} tabIndex={getTabIndex()} onClick={this.nextSlide}></div>
            <div data-sn-left="" className={`swiper-button-prev t${content.identifier}-prevEl focusable`} tabIndex={getTabIndex()} onClick={this.prevSlide}></div>
          </>
          )
        }
        <CustomSlider
          params = { slick_params || this.swiperParams }
          content_identifier = { content.identifier }
        >
          { this.props.contents }
        </CustomSlider>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  modal_progress: state.modal.progress,
})

export default connect(mapStateToProps, null)(JumboContent);
