import React from 'react';
import { httpsUrl, VIDEO_GRID_THEME_THEME } from '../constants/common';
import { getFontStyleFromFeed } from '../helperfunctions';
import { formatItemDuration } from '../helperfunctions/common';
import store from '../store';
import Cta from './Cta';
import Image from './Image';
import SaveIcon from './SaveIcon';

function InterstitialMobile(props) {
    const { feeds, content, parent_content, summary, type, haveSavedMazIDFeed  } = props;
    const { duration, rating, copyright, role, genre } = content;
    const mainContent = (type === "menu") ? content : parent_content;
    const fontStyle = getFontStyleFromFeed(feeds);
    const primary = feeds.primaryColor || "#FFFFFF";
    const secondary = feeds.secondaryColor || "#FFFFFF";
    const divSecodaryBack = {backgroundColor: content.cover ? 'transparent' :secondary.replace('#FF', '#')}
    const divPrimaryBack = {backgroundColor: content.cover ? 'transparent' :primary.replace('#FF', '#')}
    const imgPrimaryBack = {backgroundColor: primary.replace('#FF', '#')}
    const imgSecodaryBack = {backgroundColor: secondary.replace('#FF', '#')}
    let isSpec = true;
    let isStarring = true;
    let isDirector= true;
    let isGenre = true;

    if(!duration && !rating && !copyright) {//spec not available
        isSpec = false;
      }
      if(!role || !role.actor) {//actor not available
        isStarring = false;
      }
      if(!role || !role.director) {//Directors not available
        isDirector = false;
      }
      if(!genre) {//Generes not available
        isGenre = false;
      }

    return (
        <div id="interstitial-mbl">
            <div className="thumbnail">
                {   content.cover &&
                    <Image
                        style={imgSecodaryBack}
                        classNames={"image-content-" + content.identifier}
                        cover={content.cover}
                        customSize={768}
                    />
                }
            </div>
            <div className="ints-content  ">
                {mainContent.locked && 
                    <div className="item-cta">
                        <Cta 
                            interstitial={props.interstitial}
                            interstitialProps={props.interstitialProps}
                            layout={VIDEO_GRID_THEME_THEME} 
                            time={Date.now()} 
                            store={store} 
                            content={mainContent} 
                            feeds={feeds}
                        />
                    </div>
                }
                <div style={fontStyle.primaryFont} className="ints-title">{content.title}</div>
                {isSpec && (<div className="item-details">
                    {duration && <p style={fontStyle.secondaryFont}>{formatItemDuration(duration)}</p>}
                    {copyright && <p style={fontStyle.secondaryFont}>{copyright}</p>}
                    {rating && <p className='upperCase' style={fontStyle.secondaryFont}>{rating}</p>}
                </div>)}
                <div className="item-play">
                    <span style={{...imgPrimaryBack}} onClick={props.onPlay}>
                        <span>Play</span><span className="arrow-right"></span>
                    </span>
                </div>
                <div className="item-desc breakSpaces" style={fontStyle.secondaryFont}>{summary.description}</div>
                {isStarring && <div className="extra-specs">
                    <p style={fontStyle.secondaryFont}>Cast :</p>
                    <p style={fontStyle.secondaryFont}>{role.actor}</p>
                </div>}
                {isDirector && <div className="extra-specs">
                    <p style={fontStyle.secondaryFont}>{role.director.split(",").length > 1 ? "Directors :" : "Director :"}</p>
                    <p style={fontStyle.secondaryFont}>{role.director}</p>
                </div>}
                {isGenre && <div className="extra-specs">
                    <p style={fontStyle.secondaryFont}>{genre.split(",").length > 1 ? "Genres" : "Genre"}</p>
                    <p style={fontStyle.secondaryFont}>{genre}</p>
                </div>}
                {false && (<div className="save-icon">
                    <SaveIcon 
                        locked={mainContent.locked} 
                        store={store} 
                        content={content} 
                        type={"content"} 
                        secondary={secondary} 
                        feeds={feeds} 
                        interstitialProps={props.interstitialProps}
                        
                        no_color={false} 
                    />
                </div>)}
            </div>
        </div>
    )
}

export default InterstitialMobile;