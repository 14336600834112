import axios from "axios";
import * as actionTypes from '../constants/actionTypes';
import * as APIS from "../constants/api";
import configuration from "../constants/config";
import { getEnvironmentUrl, getApiToken, getTvodAPIKey } from '../constants/initializer'
import { addTvodAPIRegionalisation } from "../helperfunctions/common";
import { setEvent } from "../constants/analytics";

export function getSearchResult(app_id, query, params = {}){
    setEvent('Search', 'Search Query', query)
    return function(dispatch, getState) {
        const {locale_id, language} = addTvodAPIRegionalisation({}, getState);
        var url =  configuration.isAppTvodType
            ? `${APIS.TVOD_SEARCH_API}?app_id=${app_id}&query=${query}&key=${getTvodAPIKey()}&device=tv&locale_id=${locale_id}&language=${language}&one_feed=true`
            : getEnvironmentUrl() + '/api/v3/search/'+ app_id +'?q=' + query + '&api_token='+ getApiToken("search") +'&tv=true';
        
        dispatch({type: actionTypes.TEXT_SEARCH, payload: {string: query}})
        axios.get(url, { ...params })
            .then((response) => {
                dispatch({type: actionTypes.RECEIVE_SEARCH_ITEMS, payload: response.data, app_id: app_id})
            }).catch(err => {
                if(axios.isCancel(err)) {
                    // console.log("Request cancel,",query);
                }
                dispatch({ type: actionTypes.RECEIVE_SEARCH_ITEMS_FAILED, app_id, payload: err });
            })
    }
}

export function clearSearch() {
    return function(dispatch) {
        dispatch({type: actionTypes.CLEAR_SEARCH})
    }
}

export function updateSearchRouteEntry(data) {
    return function(dispatch) {
        dispatch({type: actionTypes.UPDATE_SEARCH_ROUTE_ENTRY, payload: data})
    }
}
