import { isMobile } from 'mobile-device-detect';

import { PLATFORM_LG, PLATFORM_SAMSUNG, PLATFORM_VIZIO, PLATFORM_WEB, PLATFORM_WEB_PREVIEW } from '../constants/common';
import configuration from '../constants/config';
import { checkCorrectPlatform, getCurrentPlatformTvod, getDateWithoutHyphen, isSmartTv } from './common';
import isAvailable from './is_available';
import { getAllConstants } from './regionalization';

export function getParentFeed(feeds, sectionUrl) {
  if(!sectionUrl) return {};
    let sections = feeds.sections
    let content;
    let pathLocation = sectionUrl.split("/")
    if(pathLocation[pathLocation.length - 1] === pathLocation[pathLocation.length - 2]) {
      // Sometimes we get item identifier in lineage too, So removing extra one.
      // Bug- https://github.com/MagAppZine/MAZ-Video-Ui/issues/1577
      pathLocation.pop();
    }
    for(let i=0; i<pathLocation.length - 1; i++){
      for(let j=0; j<sections.length; j++){
        if(sections[j].identifier === pathLocation[i]){
          content = sections[j]
          if(sections[j].contents) {
            sections = sections[j].contents;
          }
        }
      }
    }
    return content
}
/**
 * This function return an object which tell when you press back, where the user should go.
 */
export function backButtonObj(feeds, section_id, runningOnMobile){
  let sections = feeds.sections
  let content;
  let pathLocation = section_id.split("/");
  let temp = 1;
  let parentIndex = 1;
  const appId = configuration.app_id;
  if(!checkIfSectionExist(pathLocation[0], feeds)){return {pathLocation: "settings", backPath: `/apps/${appId}/settings`, style: {}}}
  for(let i=0; i<pathLocation.length - 1; i++){
    for(let j=0; j<sections.length; j++){
      if(sections[j].identifier === pathLocation[i]){
        content = sections[j]
        if(sections[j].contents) {
          sections = sections[j].contents
        }
        if (temp === 1)  {
          if (pathLocation.length-1 != i)
            parentIndex = pathLocation.length - i - 1;
          if (content.regularLayout === "detail" && !runningOnMobile) {
            temp = 2;
          } else {
            temp = 3;
          }
        }
        temp--;
      }
    }
  }

  pathLocation = pathLocation.slice(0,-parentIndex).join("/")
  if (!content) {
    return {pathLocation: `apps/${appId}`, backPath: `/apps/${appId}`, style: {}}
  }
  if(content.regularLayout === "spotlight"){
    return {pathLocation: pathLocation, backPath: `/apps/${appId}/${pathLocation}`, style: {position: "fixed"}}
  }
  return {pathLocation: pathLocation, backPath: `/apps/${appId}/${pathLocation}`, style: {}}
}

export function checkIfSectionLevelContent() {
  const urlArr = window.location.pathname.split('/').slice(3);
  if (urlArr.length === 1)
    return true;
  return false;
}


/**
 * Function to check whether proper sections is present, and proper contents in all section. (true for proper feed)
 * @param {*} feed One feed
 */
export function checkFeedIntegrity(feed) {
  const sections = feed.sections;
  if (!sections || !sections.length)
    return false;
  return true;
}


// The function return a pathname for an item
export function getThumbnailType(content, head){
  var contentUrl = window.location.pathname.split("/")
  var lastElement = contentUrl[contentUrl.length - 1]
  if (isSmartTv() && window.location.pathname.includes(content.identifier)) {
    contentUrl = window.location.pathname;
  } else {
    if(head === lastElement || head === content.identifier){
      contentUrl = window.location.pathname + "/" + content.identifier
    } else {
      contentUrl = window.location.pathname + "/" + head + "/" + content.identifier
    }
  }
  return contentUrl
}

export function findDefaultSection(oneFeed) {
  window.feeds = oneFeed
  var feed = 0;
  var contents = oneFeed.sections;
  var defaultSection = contents[0];
  var defaultSectionArray = [defaultSection]
  var pathLocation = [defaultSection.identifier]
  var defaultChanged = 0
  while(feed == 0){
    for(var i=0; i < contents.length; i++){
      if(contents[i].isDefault){
        if(pathLocation.length == 1 && defaultChanged == 0) {
          pathLocation = pathLocation.slice(0,-1)
          defaultSectionArray = defaultSectionArray.slice(0,-1)
          defaultChanged = 1
        }
        defaultSection = contents[i]
        defaultSectionArray.push(defaultSection)
        pathLocation.push(defaultSection.identifier)
        feed = 0
        if(defaultSection.type !== "live" && defaultSection.type !== "cvideo" && defaultSection.type !== "image" && defaultSection.contents){
          contents = defaultSection.contents
        } else {
          feed = 1
          break
        }
        break;
      } else {
        feed = 1
      }
    }
  }
  if(pathLocation.length > 1 && defaultSectionArray[defaultSectionArray.length - 2].regularLayout !== "detail"){
    pathLocation = pathLocation.slice(0,-1)
    return [defaultSectionArray[defaultSectionArray.length - 2], pathLocation]
  } else {
    return [defaultSection, pathLocation];
  }
}

export function getGdprParamsForApi(feeds) {
  const gdprObj = {
    gdpr: {}
  }
  if (!feeds || !feeds.gdpr || !feeds.gdpr.privacy)
    return {};
  if (feeds.gdpr.privacy.partner) {
    gdprObj.gdpr.partner = true;
  }
  if (feeds.gdpr.privacy.third_party) {
    gdprObj.gdpr.third_party = true;
  }
  return gdprObj;
}

export function checkIfSectionIdExist(section_id, feeds) {
  if (section_id.includes('subscription')) {
    return {
      isSectionId: true,
    }
  }
  let sections = feeds.sections
  let content={};
  let pathLocation = section_id.split("/");
  let availablePath = [];
  const appId = configuration.app_id;
  if(!checkIfSectionExist(pathLocation[0], feeds)){
    return {
      isSectionId: false,
      availablePath: ""
    }
  }
  for(let i=0; i<pathLocation.length; i++){
    for(let j=0; j<sections.length; j++){
      if(sections[j].identifier === pathLocation[i]){
        availablePath.push(sections[j].identifier);
        content = sections[j]
        if(sections[j].contents) {
          sections = sections[j].contents
        }
      }
    }
    if (pathLocation[i] !== content.identifier) {
      return {
        isSectionId: false,
        availablePath: availablePath.length ? availablePath[0] : ""
      }
    }
  }
  return {
    isSectionId: true,
  };
} 

export function  findCurrentContentType(feeds, paths) {
  var currentLocation = paths.split("/")
  var contents = feeds.sections
  let type = "";
  for(var i=0; i<currentLocation.length; i++) {
    if(currentLocation[i] === "" || currentLocation[i] === "apps") continue;
    for(var j=0; j<contents.length; j++){
      if(contents[j].identifier == currentLocation[i]) {
        type = contents[j].type;
        if(contents[j].contents) {
          contents = contents[j].contents
        }
        if(!Array.isArray(contents)){
          break;
        }
      }
    }
  }
  return type;
}

export function getTvodInterstitalMetaData(props, platform) {
  if(!props) return {};
  const {section: {locked, access}, feeds} = props;

  let lockedContentData = null;
  let showMoreWays = false;
  let ownLockedContent = isAvailable(null, null, null, props.section);
  if(!ownLockedContent && access && access.bundles && access.bundles.length > 0 && feeds.tvod && feeds.tvod.tiers) { // Not checking key "locked" for now
    const tiers = feeds.tvod.tiers;
    lockedContentData = {};
    access.bundles.forEach(bundle => { // bundle loops
      if(tiers[bundle.type] && tiers[bundle.type].length > 0) {
        tiers[bundle.type].forEach(tierItem => { // tiers loop
          const tier = {...tierItem};
          if(tier.identifier.toString() === bundle.identifier.toString()) {
            let isValid = false;
            if(bundle.type === "subscriptions" && tier.metadata) {
              // check if this subscription tier valid
              const newMeta = tier.metadata.filter(meta => {
                if(meta.iap && Object.keys(meta.iap).length > 0) {
                  if(meta.iap[platform]) return true;
                  showMoreWays = true;
                  return false;
                } else {
                  return false;
                }
              })
              tier.metadata = newMeta;
              isValid = newMeta.length > 0;
            } else if(tier.iap && tier.iap[platform]) {
              // check if this tier valid
              isValid = true
            } else if(bundle.type === "externals") {
              // check if this tier externals
              isValid = true
            }
            if(isValid) {
              // valid for platform
              if(!lockedContentData[bundle.type]) {
                lockedContentData[bundle.type] = [];
              }
              let formattedTier = {...tier, ...bundle};
              if(bundle.type === "events") {
                formattedTier.startsAt = getDateWithoutHyphen(access.startsAt);
                formattedTier.endsAt = getDateWithoutHyphen(access.endsAt);
              }
              lockedContentData[bundle.type].push(formattedTier);
            }
          }
        })// tiers loop ends
      }
    }); // bundle loops ends
  }
  const { kGenricSigninMessage, kMoreWaysToWatchMsg} = getAllConstants();
  let signedInMoreWays = kMoreWaysToWatchMsg;
  let signedOutMoreWays = kGenricSigninMessage;
  if(feeds && feeds.tvod && feeds.tvod.platforms && feeds.tvod.platforms[platform]) {
    if(feeds.tvod.platforms[platform].loggedInUserExternalPurchaseText) signedInMoreWays = feeds.tvod.platforms[platform].loggedInUserExternalPurchaseText
    if(feeds.tvod.platforms[platform].loggedOutUserExternalPurchaseText) signedOutMoreWays = feeds.tvod.platforms[platform].loggedOutUserExternalPurchaseText
  }
  return {
    lockedContentData,
    ownLockedContent,
    moreWays: {
      showMoreWays,
      signedInMoreWays,
      signedOutMoreWays
    }
  };
}

export function getLogoFromLoginOrFeed(feeds, screen) {
  if (configuration.isAppTvodType) {
    screen = "tvodLogin"
  }
  return feeds.loginUIMetadata && feeds.loginUIMetadata[screen] && feeds.loginUIMetadata[screen].headerImage || (feeds.logo && feeds.logo.url);
}

export function getCustomUIColors(feeds, priOver, secOver) {
  const { extras, primaryColor, secondaryColor } = feeds;
  let priColor = priOver || primaryColor;
  let secColor = secOver || secondaryColor;
  let priTextcolor = "#fff";
  let secTextcolor = "#fff";
  if(extras && extras.customUI && extras.customUI.tv) {
    if(extras.customUI.tv.primaryButtonColor) {
      priColor = extras.customUI.tv.primaryButtonColor;
    }
    if(extras.customUI.tv.secondaryButtonColor) {
      secColor = extras.customUI.tv.secondaryButtonColor;
    }
    if(extras.customUI.tv.primaryButtonTextColor) {
      priTextcolor = extras.customUI.tv.primaryButtonTextColor.replace('#FF','#');
    }
    if(extras.customUI.tv.secondaryButtonTextColor) {
      secTextcolor = extras.customUI.tv.secondaryButtonTextColor.replace('#FF','#');
    }
  }
  if(priColor) {
    priColor = priColor.replace('#FF','#');
  }
  if(secColor) {
    secColor = secColor.replace('#FF','#');
  }
  return {
    priButtonStyle: priColor ? {backgroundColor: priColor, color: priTextcolor} : {},
    secButtonStyle: secColor ? {backgroundColor: secColor, color: secTextcolor} : {},
    priTextcolor: priTextcolor,
    secColor: secColor,
    priColor: priColor
  }
}

export function checkIfSectionExist(section_id, feeds) {
  if(!section_id || section_id.includes("/") || !feeds || !feeds.sections) return false;

  for(let index = 0; index < feeds.sections.length; index++) {
    const section = feeds.sections[index];
    if(section.identifier  === section_id) {
      return true;
    }
  }
  return false;
}

export function showSettingSection(feeds) {
  if(!configuration.isAppTvodType) return true;
  if(feeds && (feeds.hasSettingsSection || feeds.hasSettingsSection === undefined)) return true;
  return false;
}

export function showSearchSection(feeds, checkingDynamic) {
  if(!feeds) return false;

  if(configuration.isAppTvodType && 'hasSearchSection' in feeds && feeds.hasSearchSection) {
    return true;
  } else if(!checkingDynamic && 'hasSearch' in feeds && feeds.hasSearch) {
    return true;
  }

  return false;
}

export function isSection(feeds, route) {
  if(!feeds || !feeds.sections || !route) return false;
  if(route === "search" || route === "settings") return true;
  for(let i = 0; i < feeds.sections.length; i++) {
    if(feeds.sections[i].identifier === route) return true;
  }
  return false;
}

export function isPreviewBlocked(feeds) {
  if(!configuration.isAppTvodType) return isSmartTv(); // Gen1
  //Gen2
  const plat = getCurrentPlatformTvod();
  if(feeds && feeds.extras && feeds.extras.blockPreview && (feeds.extras.blockPreview[plat] === true || feeds.extras.blockPreview[plat] === "true") ) {
    return true;
  }
  return false;
}

export function getExtrasParentTitle(feeds, section) {
  if(!section || !feeds) return "";
  const bundleToken = section.access && section.access.bundles && section.access.bundles[0] && section.access.bundles[0].bundleToken;
  const pSection = getParentFeed(feeds, section.lineage);
  if(!bundleToken || !pSection) return "";
  if(pSection.bundleTokens && pSection.bundleTokens.includes(bundleToken)) return pSection.title;

  const grandPSection = getParentFeed(feeds, pSection.lineage);
  if(grandPSection && grandPSection.title) return grandPSection.title;
  
  return "";
}

export function isUsingTheoPlayer(feeds) {
  if(!feeds) return false;

  if(configuration.isAppTvodType) { 
    // TVOD
    const extras = feeds.extras;
    const platform = getCurrentPlatformTvod();
    if(extras && extras.player && extras.player[platform] && extras.player[platform].video === "theo") {
      // Use theo player
      return true;
    }
  } else if(configuration.kUseTheoPlayer) {
    // Use theo player only in case of mobile, LG, Vizio and Samsung
    return isMobile || checkCorrectPlatform([PLATFORM_LG, PLATFORM_VIZIO, PLATFORM_SAMSUNG]);
    // Use theo player only in case of mobile, LG, Vizio and Samsung
  }
  return false;
}

export function getOnStartDefaultSection(feeds) {
  let key = "isDefault";
  if(configuration.isAppTvodType && checkCorrectPlatform([PLATFORM_WEB, PLATFORM_WEB_PREVIEW])) {
    key = "isWebDefault"
  }
  let section_id = feeds.sections.filter(function (value) {
    return value[key];
  });

  if (section_id.length > 0) {
    section_id = section_id[0].identifier;
  } else {
    if(feeds.sections[0].type === "search") {
      section_id = "search"
    } else {
      section_id = feeds.sections[0].identifier;
    }
  }
  return section_id;
}

export function checkIfShowingContentOnSectionLevel(content , feeds) {
  if(!content || !feeds || !feeds.sections) return false;
  for(let i = 0; i < feeds.sections.length; i++) {
    const section = feeds.sections[i];
    if(section && section.identifier === content.identifier) return true;
    if(section?.type == "saved") return false;
    if(section && section.contents && section.contents.length === 1 &&
      section.contents[0].identifier === content.identifier) {// flattening
        return true;
      }
  }
  return false;
}

export function is2by3PosterImage(feeds) {
  return configuration.isAppTvodType && feeds?.extras?.newPosterAspectRatio != false //It should be true, even if feeds?.extras?.newPosterAspectRatio does not exist
}

export function parentalLockExist(feeds) {
  if(!feeds || !feeds.parentalControls || feeds.parentalControls.code === "disabled") return false;
  return true;
}

export function isContentMature(item, feeds, policyData) {
  if(!item) return false;

  if(item.mature) return true;
  if(item.rating && feeds.countryParentalRatings && policyData && policyData.country) {
    if(feeds.countryParentalRatings[policyData.country] &&
      feeds.countryParentalRatings[policyData.country].includes(item.rating)) {
        return true;
      }
    if(feeds.countryParentalRatings[policyData.country.toLowerCase()] &&
      feeds.countryParentalRatings[policyData.country.toLowerCase()].includes(item.rating)) {
        return true;
      }
  }

  return false;
}

export function maturityCheckFailed(section, feeds, parentalLockProp, logged_in, policyData) {
  // return true
  if(!parentalLockExist(feeds)) return false; // No locking for app, So play item

  if(!isContentMature(section, feeds, policyData)) return false; // content not mature, So play item

  if(!logged_in) return true; // item is mature but user is not logged in, So stop playback

  // Check if code is validated or not
  const { maxIdleTime, code, enableInAppSetup } = feeds.parentalControls || {};
  const { codeConfigured, codeValidated } = parentalLockProp || {};
  if(enableInAppSetup && !codeConfigured && code === "optional") return false; // Code not configured and is set to optional, So play item

  if((!maxIdleTime || isNaN(maxIdleTime)) && codeValidated) return false;// Code validated but maxIdleTime is missing, So play item

  if(codeValidated &&  ((new Date() - new Date(codeValidated)) < (maxIdleTime * 60 * 1000))) return false; // code validated with in maxIdleTime, So play item

  return true;
}

export function getDynamicListKey(list) {
  if(!list) return "";

  if(["favorite", "watching", "history"].includes(list.catalog)) {
    return list.catalog;
  }
  return list.cid;
}

export function checkSingleMenu(section) {
  const { contents, catalogType } = section;

  if(configuration.isAppTvodType && catalogType && catalogType.includes("Playlist")) return true;
  var singleMenu = true;
  for(var i=0; i < contents.length; i++){
    if(contents[i].type === "menu" || contents[i].type === "fake" ||
     (contents[i].type === "live" && !configuration.isAppTvodType)
    ){
      singleMenu = false;
      break;
    }
  }
  return singleMenu;
}

export function isContentPortrait(content, parent) {
  return getThumnailViewClass(content, parent) === "portrait-view";
}

export function getThumnailViewClass(content, parent) {
  const { thumbOrientation } = content || {};
  const thumb = thumbOrientation || parent?.thumbOrientation;

  if(thumb === "portrait") {
    return "portrait-view";
  } else if(thumb === "square" ) {
    return "square-view";
  }
  
  return "";
}

export function getItemForPreview(allContents, dynamicLists) {
  let _content = { ...allContents[0] };
  if(_content.type === "menu" && _content.isDynamicList) {
    //loop through row and find first row
    for(let i = 0; i < allContents.length; i++) {
      if(!allContents[i].isDynamicList) {
        _content = allContents[i];
        break;
      } else {
        // dynamic list , check if content is available
        const key = getDynamicListKey(allContents[i]);
        if(dynamicLists[key] && dynamicLists[key].content.length > 0) {
          _content = dynamicLists[key].content[0];
          break;
        }
      }
    }
  }
  if (_content.contents && _content.type === "menu" && _content.contents[0]) {
    _content = { ..._content.contents[0] };
  }
  return _content
}

export function supportingToRTL(feeds) {
  if(checkCorrectPlatform([PLATFORM_WEB, PLATFORM_WEB_PREVIEW])) {
    if(feeds && feeds.rtl) return true;
  }
  return false
}

export function elemOrientation(feeds) {
  if(supportingToRTL(feeds)) return "rtl";
  return "ltr"
}