import React from "react";
import { Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Background from "../components/Background";
import Logo from "../components/Logo";
import Banner from "../components/Banner";
import Content from "../components/Content";
import SpotlightSection from "../components/SpotlightSection";
import FullScreenSection from "../components/FullScreenSection";
import SectionVideoSetup from "../components/SectionVideoSetup";
import Video from "../components/Video";
import FullImage from "../components/FullImage";
import ContentSectionImage from "../components/ContentSectionImage";
import Subscribe from "./Subscribe";
import MyQueue from "./MyQueue";
import EPGSection from "../components/EPGSection";
import Loader from "../components/Loader";
import HeroSliderSection from "../components/HeroSliderSection";
import SeriesSection from "../components/SeriesSection";
import MoreSettings from "./MoreSettings";
import { DynamicList } from "../components/DynamicList";

import { siteUrl } from "../constants/initializer";
import {
  getEmptyFeedMessages,
  VIDEO_GRID_THEME_THEME,
  EPG_THEME,
  HERO_SLIDER_THEME,
  SERIES_THEME,
  MORE_SECTION_THEME
} from "../constants/common";
import {
  closeModal,
  setPreviewVideo,
  openModal
} from "../actions/modalActions";
import * as analytics from "../constants/analytics";
import {
  checkIfSectionIdExist,
  checkIfSectionLevelContent,
  checkSingleMenu,
  findDefaultSection,
  getThumbnailType
} from "../helperfunctions";
import configuration from "../constants/config";
import {
  checkIfOverlayVideoIsPresent,
  checkLoginUIMetaData,
  getPlaylistVideoCounter,
  getRedirectUrlAfterGeofence,
  isFeedAvailable,
  isSmartTv,
  tvFocus
} from "../helperfunctions/common";
import JsSpatialNavigation from "../ctvnavigation/js-spatial-navigation";
import { attachObserver } from "../helperfunctions/ctvHelper";
import LinkSection from "../components/LinkSection";
import { getAllConstants } from "../helperfunctions/regionalization";

function FindDefaultSection(props) {
  return findDefaultSection(props);
}


function ChangeRoute(path_location, section_id) {
  var currentLocation = window.location.pathname.split("/");
  currentLocation = currentLocation.slice(0, 3);
  if (section_id) currentLocation.push(section_id);
  currentLocation = currentLocation.concat(path_location);
  return currentLocation.join("/");
}

function FindSection(props, section_id) {
  var feed = props;
  var currentLocation = section_id.split("/");
  var contents = props.sections;
  var allContents = props.section;
  for (var i = 0; i < currentLocation.length; i++) {
    for (var j = 0; j < contents.length; j++) {
      if (contents[j].identifier == currentLocation[i]) {
        feed = contents[j];
        allContents = contents;
        if (contents[j].contents) {
          contents = contents[j].contents;
        }
        if (!Array.isArray(contents)) {
          break;
        }
      }
    }
  }
  if (
    feed.contents &&
    (feed.contents[0].type == "fake" || feed.contents[0].type == "live") &&
    feed.contents.length < 2
  ) {
    const layout = feed.regularLayout;
    const thumbOrientation = feed.thumbOrientation;
    const showInterstitial = feed.showInterstitial;
    feed = feed.contents[0];
    feed.regularLayout = layout;
    feed.thumbOrientation = thumbOrientation;
    feed.showInterstitial = showInterstitial;
  }
  return [feed, allContents];
}

const getContentForMazFeeds = (mazFeedType, content, itemsReducer) => {
  if (mazFeedType === "saved") {
    const savedContents = itemsReducer.items.content;
    let newContent = { ...content };
    if (savedContents.length)
      newContent.contents = savedContents.map((contentData) => {
        contentData.mazContentType = "saved";
        return contentData;
      });
    newContent.mazContentType = "saved";
    return newContent;
  } else if (mazFeedType === "progress") {
    const progressContents = itemsReducer.progress.contents;
    let newContent = { ...content };
    if (progressContents.length)
      newContent.contents = progressContents.map((contentData) => {
        contentData.mazContentType = "progress";
        return contentData;
      });
    newContent.mazContentType = "progress";
    return newContent;
  } else if (mazFeedType === "history") {
    const historyContents = itemsReducer.history.contents;
    let newContent = { ...content };
    if (historyContents.length)
      newContent.contents = historyContents.map((contentData) => {
        contentData.mazContentType = "history";
        return contentData;
      });
    newContent.mazContentType = "history";
    return newContent;
  }
  return content;
};

const getMazFeedContentForDetailTheme = (content, itemsReducer, feeds) => {
  // const savedSection = itemsReducer.items
  if(configuration.isAppTvodType) return content;
  const self = this;
  let newContent = { ...content };
  if (newContent.mazIDSectionType) {
    newContent = getContentForMazFeeds(
      newContent.mazIDSectionType,
      newContent,
      itemsReducer,
      feeds
    );
  }
  if (newContent.contents && newContent.contents.length) {
    newContent.contents = newContent.contents.map((singleContent) => {
      let newSingleContent = { ...singleContent };
      if (newSingleContent.mazIDSectionType) {
        newSingleContent = getContentForMazFeeds(
          singleContent.mazIDSectionType,
          newSingleContent,
          itemsReducer,
          feeds
        );
      }
      return newSingleContent;
    });
    newContent.contents = newContent.contents.filter((singleContent) => {
      if(singleContent.mazIDSectionType && !singleContent.contents) {
        return false;
      }
      return true;
    });
  }
  return newContent;
};

class Section extends React.Component {
  constructor(props) {
    super(props);
    const stateData = this.getSectionData();
    this.state = {
      banners: {},
      path_location: [],
      all_contents: [],
      currentView: "",
      save_item: {},
      redirect_url: "/",
      show_banners: false,
      banner_id: null,
      ...stateData,
      rows_shown: 3
    };
  }

  navigateToRoute(route) {
    this.setState({ currentView: route });
    this.props.parentCallback(route);
  }

  getSectionData() {
    // When pressed browser back button close all modal.
    if (this.props.history.action === "POP") {
      this.props.closeModal();
      if (
        configuration.isAppTvodType &&
        this.props.userEngaged &&
        window.location.search === "?mode=watch"
      ) {
        window.history.replaceState("view", {}, `${window.location.pathname}`);
      }
    }
    // if (!this.props.pendingSubscription && !this.props.isUserLoading && this.props.isMeteringLoading) {
    // stoping close modal here, creating more problems. Can remove this code after review
    // this.props.closeModal()
    // }
    const stateData = { section_id: this.props.section_id };
    if (this.props.section_id) {
      var sectionData = FindSection(this.props.feeds, this.props.section_id);
      section = sectionData[0];
      section = getMazFeedContentForDetailTheme(section, this.props.items);
      stateData.all_contents = sectionData[1];
      stateData.section = section;
      stateData.logo = section.logo || this.props.feeds.logo;
      stateData.background = section.background;
      var bannerId = section.bannerId;
      if (
        bannerId !== undefined &&
        this.props.feeds.bannerGroups !== undefined &&
        this.props.feeds.bannerGroups[bannerId] !== undefined
      ) {
        stateData.show_banners = true;
        stateData.banner_id = bannerId;
        stateData.banners = this.props.feeds.bannerGroups[bannerId];
      }
    } else {
      var section = FindDefaultSection(this.props.feeds);
      section[0] = getMazFeedContentForDetailTheme(
        section[0],
        this.props.items
      );
      stateData.section = section[0];
      stateData.logo = section[0].logo || this.props.feeds.logo;
      stateData.background = section[0].background;
      stateData.path_location = section[1];
    }
    return stateData;
  }

  loadSectionDataInState = (nextProps) => {
    this.setState({ section_id: this.props.section_id });
    if (this.props.section_id) {
      var sectionData = FindSection(this.props.feeds, this.props.section_id);
      let section = sectionData[0];
      section = getMazFeedContentForDetailTheme(section, nextProps.items);
      this.setState({ all_contents: sectionData[1] });
      this.setState({ section: section, sectionDataChangedAt: new Date() });
      this.setState({ logo: section.logo || this.props.feeds.logo });
      this.setState({ background: section.background });
      var bannerId = section.bannerId;
      if (
        bannerId !== undefined &&
        this.props.feeds.bannerGroups !== undefined &&
        this.props.feeds.bannerGroups[bannerId] !== undefined
      ) {
        this.setState({ show_banners: true, banner_id: bannerId });
        this.setState({ banners: this.props.feeds.bannerGroups[bannerId] });
      }
    } else {
      var section = FindDefaultSection(this.props.feeds);
      section[0] = getMazFeedContentForDetailTheme(section[0], nextProps.items);
      this.setState({ section: section[0], sectionDataChangedAt: new Date() });
      this.setState({ logo: section[0].logo || this.props.feeds.logo });
      this.setState({ background: section[0].background });
      this.setState({ path_location: section[1] });
    }
    this.setState({ currentView: this.props.section_id });
    // this.props.parentCallback(this.props.section_id);
    if (
      !(
        checkIfOverlayVideoIsPresent() ||
        this.props.section_id === nextProps.section_id
      )
    ) {
      this.props.parentCallback(this.props.section_id);
    }
  };

  handleRemoteClick = (e) => {
    let gridParent = document.querySelector("#content-box-1.standard");
    if (
      e.keyCode === 37 ||
      e.keyCode === 39 ||
      e.keyCode === 38 ||
      e.keyCode === 40
    ) {
      if (gridParent && gridParent.contains(document.activeElement)) {
        const nearestContainer =
          document.activeElement.closest(".swiper-container");
        const currSwiper = nearestContainer.swiper;
        let currIndex;
        if (e.keyCode === 37) {
          nearestContainer.swiper.slidePrev();
        }
        if (e.keyCode === 39) {
          nearestContainer.swiper.slideNext();
        }
      }
    }
  };

  observerCallback = (entries, observer) => {
		if(!this.observer) {
			this.observer = observer;
		}

    if(entries[0].isIntersecting) {
      const { rows_shown, section } = this.state;
      if(rows_shown < section.contents.length) {
        this.setState({ rows_shown: rows_shown+3})
      } else {
        observer.unobserve(entries[0].target);
      }
    }
  }

  componentDidMount() {
    if (isSmartTv()) {
      if (!document.getElementById("nav-bar")) {
        setTimeout(() => {
          JsSpatialNavigation.focus();
        }, 0);
      } else {
        if (
          !document.getElementById("nav-bar").contains(document.activeElement)
        )
          JsSpatialNavigation.focus("navbar");
      }
      // document.addEventListener('keyup',this.handleRemoteClick);
      this.updateObserver();
    }

    analytics.setPageViewSections(this.props.feeds, window.location.pathname);
    if (configuration.is_simulator) {
      // to add active class & style in navbar for simulator
      this.addClassToFixNavbar();
      var navbarLinks = document.getElementsByClassName("headerLinks") || [];
      if (navbarLinks.length > 0) {
        const { ottTabBarInactiveColor, ottTabBarActiveColor } =
          this.props.feeds;
        for (var i = 0; i < navbarLinks.length; i++) {
          navbarLinks[i].classList.remove("active");
          if (ottTabBarInactiveColor) {
            navbarLinks[i].style.color = ottTabBarInactiveColor.replace(
              "#FF",
              "#"
            );
          }
        }
        if (this.props.section_id) {
          if (
            document.getElementsByClassName(
              "navbar-" + this.props.section_id.split("/")[0]
            )[0]
          ) {
            document
              .getElementsByClassName(
                "navbar-" + this.props.section_id.split("/")[0]
              )[0]
              .classList.add("active");
          }
          if (
            ottTabBarActiveColor &&
            document.getElementsByClassName(
              "navbar-" + this.props.section_id.split("/")[0]
            )[0]
          ) {
            document.getElementsByClassName(
              "navbar-" + this.props.section_id.split("/")[0]
            )[0].style.color = ottTabBarActiveColor.replace("#FF", "#");
          }
        } else {
          if (
            document.getElementsByClassName(
              "navbar-" + this.state.path_location[0]
            )[0]
          ) {
            document
              .getElementsByClassName(
                "navbar-" + this.state.path_location[0]
              )[0]
              .classList.add("active");
            if (ottTabBarActiveColor) {
              document.getElementsByClassName(
                "navbar-" + this.state.path_location[0]
              )[0].style.color = ottTabBarActiveColor.replace("#FF", "#");
            }
          }
        }
      }
    }

    // this.tvFocus();
    tvFocus(this.state.section.regularLayout);
    this.handlePlaylistAutoStream();
  }

  componentDidUpdate(prevProps, prevState) {
    if (configuration.is_simulator) {
      this.addClassToFixNavbar();
    }
    if (prevProps.oneFeedLoading && !this.props.oneFeedLoading) {
      this.loadSectionDataInState(this.props);
    }
    if(prevProps.feeds && this.props.feeds && prevProps.feeds.modified !== this.props.feeds.modified) {
      this.loadSectionDataInState(this.props);
    }
    if(prevState.rows_shown !== this.state.rows_shown) {
      this.updateObserver();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keyup',this.handleRemoteClick);
		if(isSmartTv() && this.observer) {
			this.observer.disconnect();
		}
  }

  updateObserver() {
    if(!isSmartTv()) return;

    const allNodes = document.querySelectorAll('.slick-list');
    this.last_element = (allNodes && allNodes.length > 0) ? allNodes[allNodes.length - 1] : null;
    this.last_element && attachObserver(this.observerCallback, this.last_element);   
  }

  isLimitedSeriesWithGhostFolder(section) {
    if(!section || !section.catalogType || !section.contents) return false;
    return Boolean(section.catalogType.includes("LimitedSeries") && section.contents.length === 1 && section.contents[0].contents);
  }
  
  handlePlaylistAutoStream() {
    if(!configuration.isAppTvodType) return;
    let section = this.state.section;
    if(!section || !section.contents) return;
    if(section.type !== "menu") return;
    if(!section.sectionAutoStream) return;
    const isPlaylistWithGhostFolder = this.isLimitedSeriesWithGhostFolder(section);
    if(!(isPlaylistWithGhostFolder || checkSingleMenu(section))) return;
    const { policyData, feeds, previousRoute, location } = this.props;
    if(previousRoute && location && location.pathname && previousRoute.includes(location.pathname)) return; // returning from nested subsections

    const all_contents = [];
    const contentList = isPlaylistWithGhostFolder ? section.contents[0].contents : section.contents;
    for(let i = 0; i < contentList.length; i++) {
      const item = contentList[i];
      if(!item || !isFeedAvailable(policyData.country, feeds.countryGroups, item.countryGroupId)) continue;
      if(item.type !== "vid") continue;
      all_contents.push({
        ...item,
        displayTitle: section.displayTitle,
        parent_id: section.parent_id,
        inlineMenu: section.inlineMenu || ""
      })
    }
    if(all_contents.length === 0) return;
    const index = getPlaylistVideoCounter("playlist", section.identifier);
    const itemToPlay = all_contents[index];
    if(!itemToPlay) return;
    this.props.openModal({
      head: section.identifier + isPlaylistWithGhostFolder ? section.contents[0].identifier : "",
      type: "video",
      identifier: itemToPlay.identifier,
      content: itemToPlay,
      show_ad: section.showVideoAd,
      all_contents: all_contents,
      pathname: window.location.pathname,
      firstpath: getThumbnailType(itemToPlay, section.identifier),
      autoPlayingSectionPlaylist: section.identifier
    })
  }

  isCurrentSectionHaveContents = () => {
    let currSection;
    const stateSection = this.state.section;
    if (this.props.section_id) {
      currSection = FindSection(this.props.feeds, this.props.section_id)[0];
    } else {
      currSection = stateSection;
    }
    let section_id = this.props.section_id;
    const isSectionIdExistObject = checkIfSectionIdExist(
      section_id,
      this.props.feeds
    );
    if (section_id && !isSectionIdExistObject.isSectionId) {
      section_id = isSectionIdExistObject.availablePath
        ? isSectionIdExistObject.availablePath
        : "";
      this.props.history.replace(`/apps/${configuration.app_id}/${section_id}`);
      this.props.closeModal();
      return false;
    }
    if (
      (currSection.type === "menu" || currSection.type === "fake") &&
      (!currSection.contents || !currSection.contents.length)
    ) {
      if(currSection.isDynamicList) return true;
      if (!checkIfSectionLevelContent()) {
        let msg =
          "Items are not present at the moment, Please try after some time.";
        if (currSection.mazIDSectionType)
          msg = getEmptyFeedMessages(currSection.mazIDSectionType);
        this.props.openModal({
          type: "error",
          message: msg,
          goBack: true
        });
      }
      return false;
    }
    return true;
  };

  /**
   * Function to add class to fix navbar if layout is jumbo or standard
   */
  addClassToFixNavbar = () => {
    const navbar = document.getElementsByClassName("nav-view")[0];
    if (
      navbar &&
      (this.state.section.regularLayout == "jumbo" ||
        this.state.section.regularLayout == "standard" ||
        this.state.section.regularLayout === null)
    ) {
      navbar.parentNode.classList.add("fix-nav");
    } else navbar && navbar.parentNode.classList.remove("fix-nav");
  };

  renderMoreSetting() {
    return (
      <MoreSettings
        feeds={this.props.feeds}
        app_id={this.props.app_id}
        runningOnMobile={this.props.runningOnMobile}
        section={this.state.section}
      />
    );
  }

  renderContents(propData) {
    return this.state.section.contents.map(function (content, i) {
      if(isSmartTv() && this.state.rows_shown <= i) {
        return null;
      }

      return (
        content.isDynamicList
        ? <DynamicList key={`dynamic-${content.identifier}`} propData={{...propData, key: content.identifier}} content={content}/>
        : <Content key={content.identifier} {...propData} content={content} />
      );
    }, this);
  }

  renderList(listProps, isSingleMenu) {
    const cont = listProps.content;
    if(cont.isDynamicList) {
      return <DynamicList key={`dynamic-${cont.identifier}`} propData={{...listProps}} content={cont} isSection={isSingleMenu}/>
    }
    return <Content {...listProps}/>;
  }

  renderVideoGrid(propData) {
    const { identifier, isDynamicList } = this.state.section;
    const isSingleMenu = isDynamicList || checkSingleMenu(this.state.section);
    return (
      <>
        {this.state.show_banners && (
          <Banner
            oneFeedLoading={this.props.oneFeedLoading}
            banners={this.state.banners}
            app_id={this.props.app_id}
            feeds={this.props.feeds}
            bannerId={this.state.banner_id}
            runningOnMobile={true}
          />
        )}
        <div className="content-box video-grid-theme">
          <Background />
          {isSingleMenu ?
            this.renderList({key: identifier, ...propData, layout: VIDEO_GRID_THEME_THEME, content: this.state.section}, true)
          : (
            this.state.section.contents.map(function (content, i) {
              return this.renderList({key: content.identifier, ...propData, layout: VIDEO_GRID_THEME_THEME, content: content})
            }, this)
          )}
        </div>
      </>
    );
  }

  renderNoContents() {
    return (
      <div>
        <Background background={this.state.background} />
        {this.state.logo ? (
          <Logo logo={this.state.logo} />
        ) : (
          <div className="no-logo-div"></div>
        )}
        {this.state.show_banners && (
          <Banner
            oneFeedLoading={this.props.oneFeedLoading}
            banners={this.state.banners}
            app_id={this.props.app_id}
            feeds={this.props.feeds}
            bannerId={this.state.banner_id}
          />
        )}
      </div>
    );
  }

  renderItemDirectly() {
    const { section, section_id, background, all_contents, currentView } =
      this.state;
    if (["fake", "live", "svideo"].includes(section.type)) {
      if(section.type === "fake" && this.isLimitedSeriesWithGhostFolder(section)) {
        section.contents = section.contents[0].contents;
      }
      document.body.className = "onlyVideo transparent";
      return (
        <div>
          <div className="content-box" id="content-box-1">
            <Background background={background} />
            <SectionVideoSetup
              feeds={this.props.feeds}
              section={section}
              background={background}
            />
          </div>
        </div>
      );
    }
    const params = {
      section: section,
      app_id: this.props.app_id,
      section_id: section_id,
      feeds: this.props.feeds,
      runningOnMobile: this.props.runningOnMobile
    };
    if (section.type === "image" && section_id.split("/").length === 1) {
      document.body.className = "onlyImage transparent";
      return (
        <div className="content-box" id="content-box-1">
          <ContentSectionImage {...params} />
        </div>
      );
    } else if (section.type === "image") {
      return (
        <FullImage
          {...params}
          callbackParent={(route) => this.navigateToRoute(route)}
          pathLocation={currentView}
          all_contents={all_contents}
        />
      );
    } else if (section.type === "vid") {
      return (
        <div className="content-box" id="content-box-1">
          <Video
            {...params}
            callbackParent={(route) => this.navigateToRoute(route)}
            pathLocation={this.state.currentView}
            all_contents={this.state.all_contents}
            isBlockedGeofence={this.props.isBlockedGeofence}
          />
        </div>
      );
    } else {
      return null;
    }
  }

  renderMobileThemes() {
    document.body.className = "video-grid";
    const { regularLayout, isDynamicList } = this.state.section;
    const propData = {
      app_id: this.props.app_id,
      feeds: this.props.feeds,
      layout: regularLayout,
      callbackParent: (route) => this.navigateToRoute(route),
      pathLocation: this.state.currentView,
      runningOnMobile: true
    };
    if (regularLayout === HERO_SLIDER_THEME) {
      const isSingleMenu = isDynamicList || checkSingleMenu(this.state.section);
      return (
        <HeroSliderSection
          {...propData}
          section={this.state.section}
          isSingleMenu={isSingleMenu}
        />
      );
    } else if (regularLayout === SERIES_THEME) {
      return <SeriesSection {...propData} section={this.state.section} />;
    } else if (regularLayout === EPG_THEME) {
      return <EPGSection {...propData} content={this.state.section} />;
    } else {
      return this.renderVideoGrid(propData);
    }
  }

  renderOTTThemes() {
    const { regularLayout, type, contents, isDynamicList } = this.state.section;
    const {
      currentView,
      section,
      background,
      logo,
      show_banners,
      banners,
      sectionDataChangedAt,
      banner_id
    } = this.state;
    if (regularLayout == "jumbo") {
      document.body.className = "jumbo";
    } else if (regularLayout == "detail" && type !== "subscribe") {
      document.body.className = "detail";
    } else {
      document.body.className = "grid";
    }
    const propData = {
      app_id: this.props.app_id,
      feeds: this.props.feeds,
      layout: regularLayout,
      callbackParent: (route) => this.navigateToRoute(route),
      pathLocation: currentView,
      background: background,
      currentSection: section
    };
    const isSingleMenu = isDynamicList || checkSingleMenu(section);
    if (regularLayout === HERO_SLIDER_THEME) {
      return (
        <HeroSliderSection
          {...propData}
          section={section}
          isSingleMenu={isSingleMenu}
        />
      );
    } else if (regularLayout === SERIES_THEME) {
      return <SeriesSection {...propData} section={section} />;
    }
    propData.content = section;
    if (regularLayout === EPG_THEME) {
      return <EPGSection {...propData} sectionDataChangedAt={sectionDataChangedAt}/>;
    } else if (regularLayout === "detail") {
      return (
        <div className="content-box" id="content-box-1">
          <Background background={background} />
          {this.renderList(propData, isSingleMenu)}
        </div>
      );
    } else if (
      regularLayout === "spotlight" ||
      regularLayout === "fullscreen"
    ) {
      return (
        <div className="content-box" id="content-box-1">
          <Background background={background} />
          {regularLayout === "spotlight" ? (
            <SpotlightSection section={section} {...propData} />
          ) : (
            <FullScreenSection section={section} {...propData} />
          )}
        </div>
      );
    } else {
      return (
        <div
          className={`content-box themeLogo ${regularLayout}`}
          id="content-box-1"
        >
          <Background background={background} />
          {logo ? <Logo logo={logo} /> : <div className="no-logo-div"></div>}
          {show_banners && (
            <Banner
              oneFeedLoading={this.props.oneFeedLoading}
              banners={banners}
              app_id={this.props.app_id}
              feeds={this.props.feeds}
              bannerId={banner_id}
            />
          )}
          {isSingleMenu ? this.renderList(propData, true)
           : isSmartTv() ? (
            <>
              {this.renderContents(propData)}
            </>
          ) : (
            this.renderContents(propData)
          )}
        </div>
      );
    }
  }

  render() {
    const {
      section: { type, regularLayout, contents, isDynamicList },
      section_id
    } = this.state;
    if(type === "link"){
      return <LinkSection item={this.state.section} withFocusLock={false} hideBackButton/>
    }

    if (
      (type === "menu" || checkIfSectionLevelContent()) &&
      this.props.isBlockedGeofence(this.state.section)
    ) {
      const redirectUrl = getRedirectUrlAfterGeofence(
        this.props.feeds,
        this.props.isBlockedGeofence
      );
      return <Redirect to={`${redirectUrl}`} />;
    }

    if (this.props.oneFeedLoading && !checkIfSectionLevelContent()) {
      return <Loader />;
    }

    if (!this.isCurrentSectionHaveContents()) {
      const { kISDefaultEmptyText } = getAllConstants();
      return <div className="no-content-div">{kISDefaultEmptyText}</div>;
    }

    if (
      siteUrl(this.props.app_id).replace("https:", "http:") ===
      window.location.href
    ) {
      this.props.history.push(
        ChangeRoute(this.state.path_location, section_id)
      );
    }
    //If overlay video is present, add noNav class, only for simulator
    if (
      document.querySelector(".simulator-route-container") &&
      checkIfOverlayVideoIsPresent() &&
      !checkIfSectionLevelContent()
    ) {
      // props change while video is playing causes body class change
      document.body.classList.add("noNav");
    }

    if (type === "subscribe" || section_id === "subscription") {
      return (
        <div>
          {" "}
          <Subscribe
            feeds={this.props.feeds}
            subscriptions={this.props.feeds.subscriptions}
          />
        </div>
      );
    } else if (type === "saved" || section_id === "queue") {
      return (
        <div className="content-box" id="content-box-1">
          <MyQueue
            isSavedSection={true}
            feeds={checkLoginUIMetaData(this.props.feeds)}
            app_id={this.props.app_id}
            background={
              this.props.feeds.savedUIMetadata &&
              this.props.feeds.savedUIMetadata.background
            }
            item_id={0}
            section={this.state.section}
            runningOnMobile={this.props.runningOnMobile}
          />
        </div>
      );
    }

    if (type !== "menu") {
      // play item
      return this.renderItemDirectly();
    }
    if (regularLayout === MORE_SECTION_THEME) {
      return this.renderMoreSetting();
    } else if (!contents && !isDynamicList) {
      return this.renderNoContents();
    } else if (this.props.runningOnMobile) {
      return this.renderMobileThemes();
    } else {
      return this.renderOTTThemes();
    }
  }
}

const mapStateToProps = (state) => ({
  items: state.items,
  userEngaged: state.user.userEngaged,
  policyData: state.feeds.policyData
});

const mapDispatchToProps = {
  closeModal: closeModal,
  setPreviewVideo: setPreviewVideo,
  openModal
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Section)
);
