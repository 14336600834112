import { getMacros, isSmartTv, getMacrosVal, checkCorrectPlatform, videoType } from './common';
import * as analytics  from '../constants/analytics';
import { ifaMacrosAddition } from './adMacros';
import { PLATFORM_VIZIO, PLATFORM_ZEASN } from '../constants/common';
// return true or false based on ad frequency

export function showAdsAsPerFrequency(ads, video_count = 0) {
	let showAd = false;
	if(ads.firstVideoAdSeen){
		if((ads.frequencyCount + video_count) % ads.videoAdFrequency === 0) {
			showAd = true
		}
	} else {
		if(ads.firstVideoAdIndex === (ads.firstVideoCount + video_count)){
			showAd = true
		} else if((ads.firstVideoCount + video_count - ads.firstVideoAdIndex > 0)  && ((ads.firstVideoCount + video_count - ads.firstVideoAdIndex) % ads.videoAdFrequency === 0)) {
			showAd = true
		}
	}
	return showAd;
}

export function runAdInVideo(player, props, state, _this, feedAd, videoElId, videoItem) {
	const newVideo = document.getElementById(videoElId)
	var adToShow = props.ads.videoAdtype && feedAd;
    if(adToShow && (showAdsAsPerFrequency(props.ads, state.video_count) || (videoElId === "live-video_html5_api" && props.ads.requestAdEverytimeForLive))) {
		const {feeds, ads: {adURL, videoAdtype}} = props;
		if(adURL && window.google && (
			videoAdtype === "googleAd" ||  // google ads
			videoAdtype === "thirdPartyAd" || // third party ads
			videoAdtype === "springServeAd" || // spring serve ads
			(videoAdtype === "spotX" && isSmartTv()) // spotx ads
		)) {
			let newURL = getModifiedAdUrl(props, videoItem, player);
			var imaOptions = {
				adTagUrl: newURL,
				adsRenderingSettings: {
					useStyledNonLinearAds: true
				},
				autoPlayAdBreaks: true
			};
			if(isSmartTv() && props.section && (props.section.type === 'live' || props.section.type === 'fake')) {
				imaOptions.autoPlayAdBreaks = false;
			}
			player.ima(imaOptions);
			player.on("adsready", (ad)=> {
				setTimeout(() => {
					_this.onAdReady();
					if(isSmartTv() && !imaOptions.autoPlayAdBreaks) {
						player.ima.playAdBreak();
					}
					// ad starts a little late
				}, 2000)
				analytics.setEvent('Ads', 'Video Ad Play', 'AdTag')
				if(isSmartTv()) {
					player.ima.addEventListener( window.google.ima.AdEvent.Type.STARTED, _this.onAdStart); // ad start
					player.ima.addEventListener( window.google.ima.AdEvent.Type.COMPLETE, _this.onAdComplete); // ad ended
				} else {
					player.ima.addEventListener( window.google.ima.AdEvent.Type.CONTENT_PAUSE_REQUESTED, _this.onAdStart); // ad start
					player.ima.addEventListener( window.google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED, _this.onAdComplete); // ad ended
				}
				player.ima.addEventListener( window.google.ima.AdEvent.Type.ALL_ADS_COMPLETED, _this.onAllAdComplete); // all ad ended
			})
			player.on('adserror', (err) => {
				_this.onAdError();
			});
		} else {
			if(newVideo.play) newVideo.play();
		}
	} else {
		if(newVideo.play) newVideo.play();
	}
}


function addKVPForGoogleAd(videoAdsKeys, videoAdsKvp, adURL) {
	if(isNull(videoAdsKeys) || isNull(videoAdsKvp)) {
		return adURL;
	}
	try {
		let params = constructParams(videoAdsKeys, videoAdsKvp);
		let newURL = "";
		let startIndex = adURL.indexOf("cust_params=");
		if(startIndex > 0 && false) { // remove "&& false" if want to handle already present cust_params
			//already cust_params present
			let endIndex = adURL.indexOf("&", startIndex);
			if(endIndex < 0) {
				//cust_params is appended at last
				endIndex = adURL.length;
			}
			let cust_params = adURL.substr(startIndex, (endIndex - startIndex));
			params = `${cust_params}%26${params}`;
			newURL = adURL.replace(cust_params, params);
		} else {
			newURL = `${adURL}&cust_params=${params}`;
		}
		return newURL;
	} catch(error) {
		return adURL;
	}
}

export function isNull(data) {
	// doesn't contain any data
	if(
		data === null ||  // null
		data === undefined || // undefined
		(data.constructor === Array && data.length === 0) || // empty array
		(typeof data === "object" && Object.keys(data).length === 0) || // empty object
		(typeof data === "string" && data.length === 0) // empty string
	) {
		return true;
	}
	return false;
}

function constructParams(videoAdsKeys, videoAdsKvp) {
	let params = [];
	if(videoAdsKvp.constructor === Array) {
		videoAdsKvp.forEach(kvp => {
			const key = Object.keys(kvp)[0];
			let value = kvp[key];
			if(key && value && videoAdsKeys.includes(key)) {
				value = trimString(value);
				params.push(`${key}=${value}`);
			}
		});
	} else if(videoAdsKvp.constructor ===  Object) {
		Object.keys(videoAdsKvp).forEach(key => {
			let value = videoAdsKvp[key];
			if(key && value && videoAdsKeys.includes(key)) {
				value = trimString(value);
				params.push(`${key}=${value}`);
			}
		})
	}
	params = params.join("&");
	params = params.replace(/=/g, "%3D");
	params = params.replace(/&/g, "%26");
	params = params.replace(/,/g, "%2C");
	params = params.replace(/ /g, "%20");
	return params;
}

export function constructSpotxParams(feeds, videoItem) {
	let params = {};
	const videoAdsKvp = videoItem.spotxKvp;
	const videoAdsKeys = feeds.spotxKeys;
	if(isNull(videoAdsKeys) || isNull(videoAdsKvp)) {
		return {};
	}

	if(videoAdsKvp.constructor === Array) {
		videoAdsKvp.forEach(kvp => {
			const key = Object.keys(kvp)[0];
			let value = kvp[key];
			if(key && value && videoAdsKeys.includes(key)) {
				value = trimString(value);
				if(value.indexOf(",") > -1) { // multiple values
					value = value.split(",")
				}
				params[key] = value;
			}
		});
	} else if(videoAdsKvp.constructor ===  Object) {
		Object.keys(videoAdsKvp).forEach(key => {
			let value = videoAdsKvp[key];
			if(key && value && videoAdsKeys.includes(key)) {
				value = trimString(value);
				if(value.indexOf(",") > -1) { // multiple values
					value = value.split(",")
				}
				params[key] = value;
			}
		})
	}
	return params;
}

function addKVPForSpringServe(videoAdsKeys, videoAdsKvp, adURL) {
	if(isNull(videoAdsKeys) || isNull(videoAdsKvp)) {
		return null;
	}
	try {
		let params = [];
		if(videoAdsKvp.constructor === Array) {
			videoAdsKvp.forEach(kvp => {
				const key = Object.keys(kvp)[0];
				let value = kvp[key];
				if(key && value && videoAdsKeys.includes(key)) {
					value = trimString(value);
					params.push(`${key}=${value}`);
				}
			});
		} else if(videoAdsKvp.constructor ===  Object) {
			Object.keys(videoAdsKvp).forEach(key => {
				let value = videoAdsKvp[key];
				if(key && value && videoAdsKeys.includes(key)) {
					value = trimString(value);
					params.push(`${key}=${value}`);
				}
			})
		}
		params = params.join("&");
		params = params.replace(/ /g, "_");
		// const foramttedURL = `${adURL}&${params}`;
		const foramttedURL = `&${params}`;
		return foramttedURL;
	} catch(error) {
		return null;
	}
}

function trimString(value) {
	if(typeof value === "string") {
		let trimedValue = value
		if(value.indexOf(",") > -1) {
			//multiple values
			let valArray = value.split(",");
			let valArray2 = [];
			valArray.forEach((val) => {
				valArray2.push(val.trim());
			})
			trimedValue = valArray2.join(",");
		} else {
			trimedValue = value.trim();
		}
		return trimedValue;
	} else return value;
}

export function getModifiedAdUrl(props, videoItem, player) {
	let newURL = "";
	const {feeds, ads: {adURL, videoAdtype, vidParameter}} = props;
	if (videoAdtype === "googleAd") {
		newURL = addKVPForGoogleAd(feeds["googleAdsKeys"], videoItem["googleAdsKvp"], adURL);
		newURL = addVidAdParameter(newURL, vidParameter, videoItem);
		// newURL = `${newURL}&description_url=${videoItem.contentUrl}`;
	} else if(videoAdtype === "thirdPartyAd") {
		if(feeds.videoAds && feeds.videoAds.advanced && feeds.videoAds.advanced.kvpType === "springserve_kvp"){
			newURL = adURL;
			let kvps = addKVPForSpringServe(feeds["videoAdsKeys"], videoItem["videoAdsKvp"], adURL);
			if(kvps) newURL += kvps;
		}
		else{
			newURL = addKVPForGoogleAd(feeds["videoAdsKeys"], videoItem["videoAdsKvp"], adURL);
		}
		let macros_val = getMacrosVal(props, videoItem, player);

		for(let key in macros_val) {
			newURL = newURL.replace(`[[${key}]]`, encodeURIComponent(macros_val[key]))
		}
	} else if(videoAdtype === "springServeAd") {
		newURL = adURL;
		let kvps = addKVPForSpringServe(feeds["videoAdsKeys"], videoItem["videoAdsKvp"], adURL);
		// adding macros for spring serve ads
		let macros = getMacros(props, videoItem, player);

		for(let key in macros) {
			newURL += `&${key}=${encodeURIComponent(macros[key])}`
		}

		if(kvps) newURL += kvps;
	} else {
		newURL = adURL;
	}
	newURL = ifaMacrosAddition(newURL);
	return newURL;
}

function addVidAdParameter(newURL, vidParameter, videoItem) {
	if(!vidParameter || !videoItem || !videoItem.videoAdTag) return newURL;

	if(videoItem.videoAdTag[vidParameter]) {
		return `${newURL}&vid=${videoItem.videoAdTag[vidParameter]}`;
	}
	return newURL;
}