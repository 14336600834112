import React from 'react'
import Swiper from 'react-id-swiper'
import { connect } from "react-redux"
import Cta from "../components/Cta"
import { setLockedContent } from "../actions/modalActions"
import {elemOrientation, getSwiperParamsByTheme, swiperParam} from '../helperfunctions';
import isAvailable from '../helperfunctions/is_available';
import { getFontStyleFromFeed } from "../helperfunctions";
import { SPOTLIGHT_THEME } from '../constants/common'
import { checkIfEmptyMazIdFeedsInContents, getTabIndex, focusVideoItemWhichWerePlayed, isSmartTv } from '../helperfunctions/common';
import config from '../constants/config'
import { convertSwiperParamsToSlick, makeContentFocusableSection, removeContentFromFocusableSection } from '../helperfunctions/ctvHelper';
import CustomSlider from './CustomSlider';
import configuration from '../constants/config'

class SpotlightContent extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      currentView: '',
      virtualData: {
        slides: [],
        offset: 0
      }
    }
    this.swiper = null
    this.slice_params = null;
  }

  componentDidMount() {
    const swiperCont = document.querySelector(`.content-${this.props.content.identifier} .swiper-container`);
    this.swiper = swiperCont && swiperCont.swiper;

    if(isSmartTv() && config.use_light_sliders) {
      makeContentFocusableSection({
        disableDefaultSection: true,
        content_identifier: this.props.content.identifier
      }).then(val => {
        this.sn_id = val;
      }).catch(err => console.log("error"))
    }    
  }

  componentWillUnmount() {
    if(isSmartTv() && config.use_light_sliders) {
      removeContentFromFocusableSection({
        enableDefaultSection: true,
        content_identifier: this.props.content.identifier,
        id: this.sn_id
      });
    }
  }

  componentDidUpdate(){
    if(Object.keys(this.props.modal_progress).length > 0 && this.props.modal_progress.head === this.props.content.identifier){
      // if (this.swiper) {this.swiper.slideTo(this.props.modal_progress.index)};
      focusVideoItemWhichWerePlayed(this.props.content.identifier, this.props.modal_progress.item);
    }
  }

  nextSlide = () => {
    const _self = this;
    _self.swiper && _self.swiper.slideNext();
  }

  prevSlide = () => {
    const _self = this;
    _self.swiper && _self.swiper.slidePrev();
  }

  onDivClick = (e) => {
    e.preventDefault();
    if (isSmartTv()) {
      this.props.dispatch(setLockedContent(this.props.content.locked && !isAvailable(this.props.content.identifier,this.props.content.special,this.props.content.registerWall)))
    }
  }

  render() {
    const fontStyle = getFontStyleFromFeed(this.props.feeds);
    const {params, gridClassName} = getSwiperParamsByTheme(this, this.props, SPOTLIGHT_THEME)
    if(checkIfEmptyMazIdFeedsInContents(this.props.content.contents))
      return null;
    
    if(isSmartTv() && config.use_light_sliders) {
      this.slick_params = convertSwiperParamsToSlick(params, this);
    }
  
    return (
      <div className={gridClassName}
      onMouseEnter = {() => {this.props.dispatch(setLockedContent(this.props.content.locked && !isAvailable(this.props.content.identifier,this.props.content.special,this.props.content.registerWall)))}}
      onClick={this.onDivClick}
      >
        {(this.props.title !== "saved_items" && (!this.props.content.locked || configuration.isAppTvodType)) && <h2 id={`content-head-${this.props.content.identifier}`} style={fontStyle.primaryFont} dir={elemOrientation(this.props.feeds)}>{this.props.content.feedTitle || this.props.title}</h2>}
        {this.props.content.locked && <Cta layout={"spotlight"} time={Date.now()} content={this.props.content} feeds={this.props.feeds} />}
        {
          isSmartTv() && config.enableCtvVirtualSlides && (
          <>
            <div data-sn-right="" className={`swiper-button-next t${this.props.content.identifier}-nextEl focusable`} tabIndex={getTabIndex()} onClick={this.nextSlide}></div>
            <div data-sn-left="" className={`swiper-button-prev t${this.props.content.identifier}-prevEl focusable`} tabIndex={getTabIndex()} onClick={this.prevSlide}></div>
          </>
          )
        }
        <CustomSlider
          params = {this.slick_params || params}
          content_identifier = { this.props.content.identifier}
        >
          {this.props.contents}
        </CustomSlider>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  modal_progress: state.modal.progress,
  subscription: state.user.subscription,
})

export default connect(mapStateToProps, null)(SpotlightContent);
