export const getColorOptions = ({ type, handler, kUseTheoPlayer }) => [
  {
    value: "",
    label: "Default",
    onSelect: function () {
      handler({
        type,
        value: this.value
      });
    }
  },
  {
    value: kUseTheoPlayer ? "rgb(255, 255, 255)" : "#FFF",
    label: "White",
    onSelect: function () {
      handler({
        type,
        value: this.value
      });
    }
  },
  {
    value: kUseTheoPlayer ? "rgb(0, 0, 0)" : "#000",
    label: "Black",
    onSelect: function () {
      handler({
        type,
        value: this.value
      });
    }
  },
  {
    value: kUseTheoPlayer ? "rgb(255, 0, 0)" : "#F00",
    label: "Red",
    onSelect: function () {
      handler({
        type,
        value: this.value
      });
    }
  },
  {
    value: kUseTheoPlayer ? "rgb(0, 255, 0)" : "#0F0",
    label: "Green",
    onSelect: function () {
      handler({
        type,
        value: this.value
      });
    }
  },
  {
    value: kUseTheoPlayer ? "rgb(0, 0, 255)" : "#00F",
    label: "Blue",
    onSelect: function () {
      handler({
        type,
        value: this.value
      });
    }
  },
  {
    value: kUseTheoPlayer ? "rgb(255, 255, 0)" : "#FF0",
    label: "Yellow",
    onSelect: function () {
      handler({
        type,
        value: this.value
      });
    }
  },
  {
    value: kUseTheoPlayer ? "rgb(255, 0, 255)" : "#F0F",
    label: "Magenta",
    onSelect: function () {
      handler({
        type,
        value: this.value
      });
    }
  },
  {
    value: kUseTheoPlayer ? "rgb(0, 255, 255)" : "#0FF",
    label: "Cyan",
    onSelect: function () {
      handler({
        type,
        value: this.value
      });
    }
  }
];

export const getFontFamilyOptions = ({ type, handler, kUseTheoPlayer }) => [
  {
    value: kUseTheoPlayer ? "inherit" : "",
    label: "Default",
    onSelect: function () {
      handler({
        type,
        value: this.value
      });
    }
  },
  {
    value: "proportionalSansSerif",
    label: "Proportional Sans-Serif",
    onSelect: function () {
      handler({
        type,
        value: this.value
      });
    }
  },
  {
    value: "monospaceSansSerif",
    label: "Monospace Sans-Serif",
    onSelect: function () {
      handler({
        type,
        value: this.value
      });
    }
  },
  {
    value: "proportionalSerif",
    label: "Proportional Serif",
    onSelect: function () {
      handler({
        type,
        value: this.value
      });
    }
  },
  {
    value: "monospaceSerif",
    label: "Monospace Serif",
    onSelect: function () {
      handler({
        type,
        value: this.value
      });
    }
  },
  {
    value: "casual",
    label: "Casual",
    onSelect: function () {
      handler({
        type,
        value: this.value
      });
    }
  },
  {
    value: "script",
    label: "Cursive",
    onSelect: function () {
      handler({
        type,
        value: this.value
      });
    }
  },
  {
    value: "small-caps",
    label: "Small Caps",
    onSelect: function () {
      handler({
        type,
        value: this.value
      });
    }
  }
];

export const getFontSizeOptions = ({ type, handler, kUseTheoPlayer }) => [
  {
    value: "",
    label: "Default",
    onSelect: function () {
      handler({
        type,
        value: this.value
      });
    }
  },
  {
    value: kUseTheoPlayer ? "50%" : 0.5,
    label: "50%",
    onSelect: function () {
      handler({
        type,
        value: this.value
      });
    }
  },
  {
    value: kUseTheoPlayer ? "75%" : 0.75,
    label: "75%",
    onSelect: function () {
      handler({
        type,
        value: this.value
      });
    }
  },
  {
    value: kUseTheoPlayer ? "100%" : 1.00,
    label: "100%",
    onSelect: function () {
      handler({
        type,
        value: this.value
      });
    }
  },
  {
    value: kUseTheoPlayer ? "125%" : 1.25,
    label: "125%",
    onSelect: function () {
      handler({
        type,
        value: this.value
      });
    }
  },
  {
    value: kUseTheoPlayer ? "150%" : 1.5,
    label: "150%",
    onSelect: function () {
      handler({
        type,
        value: this.value
      });
    }
  },
  {
    value: kUseTheoPlayer ? "175%" : 1.75,
    label: "175%",
    onSelect: function () {
      handler({
        type,
        value: this.value
      });
    }
  },
  {
    value: kUseTheoPlayer ? "200%" : 2,
    label: "200%",
    onSelect: function () {
      handler({
        type,
        value: this.value
      });
    }
  }
];

export const getOpacityOptions = ({ type, handler, kUseTheoPlayer }) => [
  {
    value: "",
    label: "Default",
    onSelect: function () {
      handler({
        type,
        value: this.value
      });
    }
  },
  {
    value: "0",
    label: "0%",
    hidden: type === "textOpacity",
    onSelect: function () {
      handler({
        type,
        value: this.value
      });
    }
  },
  {
    value: "0.25",
    label: "25%",
    hidden: !kUseTheoPlayer,
    onSelect: function () {
      handler({
        type,
        value: this.value
      });
    }
  },
  {
    value: "0.5",
    label: "50%",
    onSelect: function () {
      handler({
        type,
        value: this.value
      });
    }
  },
  {
    value: "0.75",
    label: "75%",
    hidden: !kUseTheoPlayer,
    onSelect: function () {
      handler({
        type,
        value: this.value
      });
    }
  },
  {
    value: "1",
    label: "100%",
    onSelect: function () {
      handler({
        type,
        value: this.value
      });
    }
  }
];

export const getEdgeStyleOptions = ({ type, handler, kUseTheoPlayer }) => [
  {
    value: "",
    label: "Default",
    onSelect: function () {
      handler({
        type,
        value: this.value
      });
    }
  },
  {
    value: "none",
    label: "None",
    onSelect: function () {
      handler({
        type,
        value: this.value
      });
    }
  },
  {
    value: "raised",
    label: "Raised",
    onSelect: function () {
      handler({
        type,
        value: this.value
      });
    }
  },
  {
    value: "depressed",
    label: "Depressed",
    onSelect: function () {
      handler({
        type,
        value: this.value
      });
    }
  },
  {
    value: "uniform",
    label: "Uniform",
    onSelect: function () {
      handler({
        type,
        value: this.value
      });
    }
  },
  {
    value: "dropshadow",
    label: "Dropshadow",
    onSelect: function () {
      handler({
        type,
        value: this.value
      });
    }
  }
];

export function getUpdatedColor(originalColor, rgbValue) {
  if (!rgbValue) return;
  /*
   * Update RGB value from RGB or RGBA.
   *
   * Args:
   *   originalColor (string): Original RGB or RGBA color string (e.g., "#FF0000" or "rgba(255, 0, 0, 0.5)").
   *   rgbValue (string): New RGB value to use (e.g., "rgb(255, 128, 0)").
   *
   * Returns:
   *   string: Updated RGB or RGBA color string.
   */
  const start = originalColor.indexOf("(");
  const end = originalColor.indexOf(")");
  const values = originalColor.slice(start + 1, end).split(",");

  const updatedValues = rgbValue
    .slice(4, -1)
    .split(",")
    .map((v) => v.trim());

  if (values.length === 4) {
    updatedValues.push(values[3]);
    return `rgba(${updatedValues.join(", ")})`;
  } else {
    return `rgb(${updatedValues.join(", ")})`;
  }
}

export function getRGBValue(color) {
  if (!color) return "";
  // Remove whitespace and convert to lowercase
  color = color.trim().toLowerCase();

  if (color.startsWith("rgba(")) {
    // Extract the RGB values from an RGBA string
    const rgbaValues = color.slice(5, -1).split(",");
    return `rgb(${rgbaValues.slice(0, 3).join(",")})`;
  } else if (color.startsWith("rgb(")) {
    // Return the RGB value as-is if it's already in that format
    return color;
  } else {
    // Invalid input, return null
    return null;
  }
}

export function getOpacityValue(color) {
  if (!color) return "";
  // Remove whitespace and convert to lowercase
  color = color.trim().toLowerCase();

  if (color.startsWith("rgba(")) {
    // Extract the alpha value from an RGBA string
    const rgbaValues = color.slice(5, -1).split(",");
    return rgbaValues[3]?.trim() || "1";
  } else if (color.startsWith("rgb(")) {
    // Return 1 as the alpha value if the input is in RGB format
    return 1;
  } else {
    // Invalid input, return null
    return null;
  }
}

export function getUpdatedColorOpacity(originalColor, alphaValue) {
  /*
   * Update or override alpha value from RGB or RGBA.
   *
   * Args:
   *   originalColor (string): Original RGB or RGBA color string (e.g., "rgb(255, 0, 0)").
   *   alphaValue (string): New value to use for the alpha channel (e.g., "0.5").
   *
   * Returns:
   *   string: Updated RGBA color string.
   */

  // Split the color string into an array of numeric values
  const colorValues = originalColor
    .substring(originalColor.indexOf("(") + 1, originalColor.lastIndexOf(")"))
    .split(",")
    .map((value) => parseInt(value.trim()));

  // Check if the color string is RGBA or RGB
  let alpha = 1;
  if (colorValues.length === 4) {
    alpha = colorValues.pop();
  }

  // Override alpha value if specified
  if (alphaValue !== undefined) {
    alpha = parseFloat(alphaValue);
  }

  // Construct updated color string
  const updatedColorValues = [...colorValues, alpha];
  const updatedColorString = `rgba(${updatedColorValues.join(", ")})`;

  return updatedColorString;
}

export const VIDEOJS_DEFAULT_STATE = [
  ["color", "#FFF"],
  ["textOpacity", "1"],
  ["backgroundColor", "#FFF"],
  ["backgroundOpacity", "0"],
  ["windowColor", "#000"],
  ["windowOpacity", "0"],
  ["fontPercent", 1.25],
  ["edgeStyle", "none"],
  ["fontFamily", "proportionalSansSerif"]
];

export const THEOPLAYER_DEFAULT_STATE = [
  ["backgroundColor", "rgba(0, 0, 1, 0.8)"],
  ["edgeStyle", ""],
  ["fontColor", ""],
  ["fontFamily", "inherit"],
  ["fontSize", ""],
  ["windowColor", ""]
];

export const DefaultTheoColors = {
  "backgroundColor": "rgb(0, 0, 0)",
  "fontColor": "rgb(255, 255, 255)",
  "windowColor": "rgb(0, 0, 0)"
};