import axios from "axios";
import { getTvodApiHeaders, getTvodAPIKey } from '../constants/initializer';
import * as ACTIONS from "../constants/actionTypes";
import * as APIS from "../constants/api";
import { logoutUserActions } from "./userActions";
import configuration from "../constants/config";
import { addTvodAPIRegionalisation, getCurrentPlatformTvod } from "../helperfunctions/common";


export function pushTvodStreamData(params, content) {
  return function(dispatch, getState) {
    let url = APIS.TVOD_STREAMS_API;
    params.platform = getCurrentPlatformTvod();
    if(!getState().user.logged_in) {
      dispatch({type: ACTIONS.TVOD_STREAMING_ALLOWED, payload: {[params.cid]: params }}) // manage remeberspot 
      return; //Do not call tvod stream data for anonymous user.
    }
    if(content) {
      dispatch({type: ACTIONS.UPDATE_WACHING_HISTORY_DYNAMIC_LIST, payload: { content, progress: params.progress}})
    }
    axios.post(url, addTvodAPIRegionalisation(params, getState), getTvodApiHeaders())
    .then(result => {
      const obj = {[params.cid]: params };
      dispatch({type: ACTIONS.TVOD_STREAMING_ALLOWED, payload: obj})
    }).catch(err => {
      const code = err.response && err.response.status;
      if(code == 401) {
        dispatch(logoutUserActions(configuration.app_id))
      } else if(code === 403) {
        const max = err.response.data && err.response.data.max;
        dispatch({type: ACTIONS.TVOD_STREAMING_MAX_OUT, payload: max})
      }
    })
  }
}

export function getTvodStreams(page = 1) {
  return function(dispatch) {
    dispatch({type: ACTIONS.GET_TVOD_STREAMING_PENDING})
    axios.get(APIS.TVOD_STREAMS_API, {
      params: { "page": page },
      ...getTvodApiHeaders()
    })
    .then(result => {
      const resp = (result.data && result.data.data) || [];
      const obj = {};
      resp.forEach(element => {
        obj[element.cid] = element;
      });
      const stillFetching = (result.data && !result.data.last_page && result.data.data.length > 0);
      dispatch({type: ACTIONS.GET_TVOD_STREAMING_RESOLVED, payload: {obj, stillFetching}})
      if(stillFetching) {
        dispatch(getTvodStreams(page + 1));
      }
    }).catch(err => {
      const code = err.response && err.response.status;
      if(code == 401) {
        dispatch(logoutUserActions(configuration.app_id))
      }
      dispatch({type: ACTIONS.GET_TVOD_STREAMING_REJECTED})
    })
  }
}

export function resetTvodMaxStream() {
  return function(dispatch) {
    dispatch({type: ACTIONS.TVOD_STREAMING_ALLOWED})
  }
}

export function canPlayVideoCheckFailed(max) {
  return function(dispatch) {
    dispatch({type: ACTIONS.TVOD_STREAMING_MAX_OUT, payload: max})
  }
}