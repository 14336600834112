import { DETAIL_THEME, GRID_THEME, HERO_SLIDER_THEME, JUMBO_THEME, SERIES_THEME, SPOTLIGHT_THEME } from '../constants/common';
import config from '../constants/config';
import { getThumnailViewClass } from '.';
import * as webSwiperParams from './webSwiperParams'

export function getSwiperParamsByTheme(_self, content, themeType, nextSlideCB, isPortrait=null) {
  if (!config.is_simulator) {
    return webSwiperParams.getWebSwiperParamsByTheme(_self, content, themeType, nextSlideCB)
  }
  if (themeType === GRID_THEME) {
    return getGridThemeParams(_self, content)
  }
  if (themeType === SERIES_THEME) {
    const extraSlides = content.thumbOrientation === "square" ? 2 : 0;
    return getGridThemeParams(_self, content, extraSlides)
  }
  if (themeType === SPOTLIGHT_THEME) {
    //Passing props in case of spotlight content, instead of section.content
    return swiperParam(content, _self)
  }
  if (themeType === HERO_SLIDER_THEME) {
    return getHeroSliderThemeParams(_self, content)
  }
  if (themeType === JUMBO_THEME) {
    return getJumboThemeParams(_self, content)
  }
  if (themeType === DETAIL_THEME) {
    return getDetailThemeParams(_self, content, nextSlideCB)
  }
}

export function getBannerSwiperParams(initialSlide, _self) {
  if (!config.is_simulator) {
    if(_self.runningOnMobile) {
      return webSwiperParams.getMobileBannerSwiperParams(initialSlide, _self)
    }
    return webSwiperParams.getWebBannerSwiperParams(initialSlide, _self)
  }
    return {
      initialSlide: initialSlide,
      slidesPerView: 1.2,
      // loop: true,
      spaceBetween: 40,
      autoplay: {
        delay: 4000
      },
      loopedSlides: 3,
      mousewheelControl: true,
      mousewheelInvert: true,
      centeredSlides: true,
      mousewheelForceToAxis: true,
      breakpoints: {
        1920: {
          slidesPerView: 1.18,
        },
        768: {
          slidesPerView: 1.32,
          spaceBetween: 30
        },
        520: {
          slidesPerView: 1.4,
          spaceBetween: 30
        }
      },
      mousewheel: {
       forceToAxis: true,
       invert: false
     }
    }
}

export const swiperParam = (props, self) => {
  const thumbClass = getThumnailViewClass(props.section);
  const noSave = props.nosaveicon ? " nothumbBox " : " ";
  const gridClassName = `spotlight${noSave}slider-box grid-view ${thumbClass} content-${props.content.identifier}`;
  const extraSlides = gridClassName.includes("square-view") ? 2 : 0;
  if (props.content && props.section && props.section.thumbOrientation === "portrait") {
    var params = {
      mousewheelControl: true,
      observer: true,
      observeParents: true,
      slidesPerView: props.index === 0 ? 6.1 : 5.1,
      spaceBetween: 40,
      mousewheelForceToAxis: true,
      mousewheelInvert: true,
      preloadImages: false,
      lazyLoading: true,
      freeMode: true,
      breakpoints: {
        ...portraitBreakpoints(props.section, props.index)
      },
      onInit: (swiper) => {
        self.swiper = swiper
      },
      mousewheel: {
        forceToAxis: true,
        invert: false
     }
    }
  } else {
    var params = {
      mousewheelControl: true,
      observer: true,
      observeParents: true,
      slidesPerView: props.index === 0 ? 4.5 : 3.6 + extraSlides,
      spaceBetween: 40,
      mousewheelForceToAxis: true,
      freeMode: true,
      mousewheelInvert: true,
      preloadImages: false,
      lazyLoading: true,
      breakpoints: {
        ...landscapeBreakpoints(props.section, props.index, extraSlides)
      },
      onInit: (swiper) => {
        self.swiper = swiper
      },
      mousewheel: {
        invert: false,
        forceToAxis: true,
      }
    }
  }
  
  return {gridClassName: gridClassName, params: params}
}

function landscapeBreakpoints(section, index, extraSlides) {
  if (section && section.regularLayout == 'fullscreen') {
    if (index == 0) {
      /** Breakpoints for fullscreen first Row */
      return {
        // 3840 only if screen is greater than 1920
        3840: {
          slidesPerView: 5.8,
          spaceBetween: 28
        },
        1920: {
          slidesPerView: 5.5,
          spaceBetween: 28
        },
        1600: {
          slidesPerView: 5.1,
          spaceBetween: 28
        },
        1280: {
          slidesPerView: 5.1,
          spaceBetween: 28
        },
        1024: {
          slidesPerView: 4.8,
          spaceBetween: 20
        },
        768: {
          slidesPerView: 4.3,
          spaceBetween: 15
        },
      }
    }
    /**  Breakpoints for fullscreen other RowS*/
    return {
      // 3840 only if screen is greater than 1920
      3840: {
        slidesPerView: 4.3,
        spaceBetween: 28
      },
      1920: {
        slidesPerView: 3.9,
        spaceBetween: 28
      },
      1600: {
        slidesPerView: 3.6,
        spaceBetween: 28
      },
      1280: {
        slidesPerView: 3.6,
        spaceBetween: 28
      },
      1024: {
        slidesPerView: 3.4,
        spaceBetween: 20
      },
      768: {
        slidesPerView: 2.9,
        spaceBetween: 15
      },
    }
  }
  /**  Breakpoints for Spotlight Rows*/
  return {
    1600: {
      slidesPerView: 3.4 + extraSlides,
      spaceBetween: 28
    },
    1280: {
      slidesPerView: 4.1 + extraSlides,
      spaceBetween: 24
    },
    1024: {
      slidesPerView: 2.8 + extraSlides,
      spaceBetween: 20
    },
    768: {
      slidesPerView: 2.3 + extraSlides,
      spaceBetween: 15
    },
  }
}

 
function portraitBreakpoints(section, index) {
  if (section && section.regularLayout == 'fullscreen') {
    if (index == 0) {
      /** Breakpoints for fullscreen first Row */
      return {
        // 3840 only if screen is greater than 1920
        3840: {
          slidesPerView: 6.8,
          spaceBetween: 28
        },
        1920: {
          slidesPerView: 6.5,
          spaceBetween: 28
        },
        1600: {
          slidesPerView: 6.1,
          spaceBetween: 28
        },
        1280: {
          slidesPerView: 6.1,
          spaceBetween: 28
        },
        1024: {
          slidesPerView: 5.8,
          spaceBetween: 20
        },
        768: {
          slidesPerView: 5.3,
          spaceBetween: 15
        },
      }
    }
    /**  Breakpoints for fullscreen other RowS*/
    return {
      // 3840 only if screen is greater than 1920
      3840: {
        slidesPerView: 5.3,
        spaceBetween: 28
      },
      1920: {
        slidesPerView: 4.9,
        spaceBetween: 28
      },
      1600: {
        slidesPerView: 4.6,
        spaceBetween: 28
      },
      1280: {
        slidesPerView: 4.6,
        spaceBetween: 28
      },
      1024: {
        slidesPerView: 4.3,
        spaceBetween: 20
      },
      768: {
        slidesPerView: 3.9,
        spaceBetween: 15
      },
    }
  }
  /** Default breakpoint for spotlight theme (If it has portrait thumb orientation) */
  return {
    1400: {
      slidesPerView: 4.5,
      spaceBetween: 30
    },
  }
};

function getGridThemeParams(_self, section, extraSlides = 0) {
  const commonParams = {
    mousewheelControl: false,
    spaceBetween: 40,
    mousewheelForceToAxis: true,
    mousewheelInvert: true,
    preloadImages: false,
    slidesOffsetAfter: 30,
    lazyLoading: true,
    freeMode: true,
    observer: true,
    observeParents: true,
    onInit: (swiper) => {
      _self.swiper = swiper
    },
    mousewheel: {
     forceToAxis: true,
     invert: false,
    }
  }
  if ((section && section.thumbOrientation === "portrait") || _self.isPortrait) {
    return {
      ...commonParams,
      slidesPerView: 4.5,
      breakpoints: {
        1920: {
          slidesPerView: 4.5
        },
        1366: {
          slidesOffsetAfter: 0
        },
        768: {
          slidesPerView: 3.3,
          spaceBetween: 30
        },
        520: {
          slidesPerView: 2.1,
          spaceBetween: 30
        }
      }
    }
  } else {
    return {
      ...commonParams,
      slidesPerView: 3.1 + extraSlides,
      breakpoints: {
        1920: {
          slidesPerView: 3.1 + extraSlides
        },
        1366: {
          slidesOffsetAfter: 0
        },
        768: {
          slidesPerView: 2.2 + extraSlides,
          spaceBetween: 30
        },
        520: {
          slidesPerView: 1.2 + extraSlides,
          spaceBetween: 30
        }
      }
    }
  }
}

function getHeroSliderThemeParams(_self, section) {
  const { index } = section;
  const commonParams = {
    mousewheelControl: false,
    spaceBetween: 30,
    mousewheelForceToAxis: true,
    mousewheelInvert: true,
    preloadImages: false,
    slidesOffsetAfter: 30,
    lazyLoading: true,
    freeMode: true,
    observer: true,
    observeParents: true,
    onInit: (swiper) => {
      _self.swiper = swiper
    },
    mousewheel: {
      forceToAxis: true,
      invert: false,
    }
  }
  if (section && section.thumbOrientation === "portrait") {
    return {
      ...commonParams,
      slidesPerView: index == 0 ? 3 : 4.5, // 4.5
      breakpoints: {
        1920: {
          slidesPerView: index == 0 ? 3 : 4.5 // 4.5
        },
        1366: {
          slidesOffsetAfter: 0 // 0 
        },
        768: {
          slidesPerView: index == 0 ? 2.1 : 3.3, // 3.3
          spaceBetween: 30
        },
        520: {
          slidesPerView: index == 0 ? 1.2 : 2.1, // 2.1
          spaceBetween: 30
        }
      }
    }
  } else {
    return {
      ...commonParams,
      slidesPerView: index == 0 ? 2 : 3.1, // 3.1
      breakpoints: {
        1920: {
          slidesPerView: index == 0 ? 2 : 3.1 // 3.1
        },
        1366: {
          slidesOffsetAfter: 0 // 0
        },
        768: {
          slidesPerView: index == 0 ? 1.2 : 2.2, // 2.2
          spaceBetween: 30
        },
        520: {
          slidesPerView: index == 0 ? 0.8 : 1.2, // 1.2
          spaceBetween: 30
        }
      }
    }
  }
}



function getJumboThemeParams(_self, section) {
  if((section && section.thumbOrientation === "portrait") || _self.isPortrait){
    const additional_slides = _self.is2by3PosterImage ? 0.7 : 0
    return {
      mousewheelControl: true,
      slidesPerView: 3.2+additional_slides,
      spaceBetween: 40,
      mousewheelForceToAxis: true,
      mousewheelInvert: true,
      preloadImages: false,
      lazyLoading: true,
      freeMode: true,
      observer: true,
      observeParents: true,
      breakpoints: {
        1920: {
          slidesPerView: 3.2+additional_slides,
          spaceBetween: 30
        },
        1600: {
          slidesPerView: 3.01+additional_slides,
        },
        768: {
          slidesPerView: 2.1+additional_slides,
          spaceBetween: 25
        }
      },
      onInit: (swiper) => {
        _self.swiper = swiper
      },
      mousewheel: {
       forceToAxis: true,
       invert: false,
     }
    }
  } else {
    return {
      mousewheelControl: true,
      slidesPerView: 2.01,
      spaceBetween: 40,
      mousewheelForceToAxis: true,
      mousewheelInvert: true,
      preloadImages: false,
      lazyLoading: true,
      freeMode: true,
      observer: true,
      observeParents: true,
      breakpoints: {
        1920: {
          slidesPerView: 2.01,
          spaceBetween: 35
        },
        768: {
          slidesPerView: 1.3,
          spaceBetween: 30
        }
      },
      onInit: (swiper) => {
        _self.swiper = swiper
      },
      mousewheel: {
       forceToAxis: false,
       invert: true,
     }
    }
  }
}

function getDetailThemeParams(_self, content, nextSlideCB) {
  const commonParams = {
    mousewheel: true,
    mousewheelControl: true,
    direction: 'vertical',
    resistance: false,
    keyboard: {
      enabled: true,
      onlyInViewport: false,
    },
    slidesPerView: 'auto',
    preloadImages: false,
    freeModeMomentum: false,
    touchReleaseOnEdges: true,
    observer: true,
    observeParents: true,
    // lazyLoadingOnTransitionStart: true,
    slidesOffsetAfter: 400,
    on:{
      init: () => {
        _self.swiper =  document.querySelector('.swiper-container-vertical').swiper
      },
      transitionEnd: () => {
        nextSlideCB()
      }
    },
    mousewheel: {
      forceToAxis: true,
      invert: false,
    },
    height: window.innerHeight,

  }
  if(content.thumbOrientation === "portrait"){
    return {
      ...commonParams,
      spaceBetween: 48,
      breakpoints: {
        1400: {
          spaceBetween:35
        }
      }
    }
  } else {
    return {
      ...commonParams,
      spaceBetween: 50,
      breakpoints: {
        1400: {
          spaceBetween:30
        }
      }
    }
  }
}

