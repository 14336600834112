import React, { useEffect, useState } from 'react';
import FocusLock from 'react-focus-lock';

import configuration from '../constants/config';
import { getTabIndex } from '../helperfunctions/common';
import { addRemoveAllNavigation, getBackBtnKeyCodes } from '../helperfunctions/ctvHelper';
import { getAllConstants } from '../helperfunctions/regionalization';

export default (props) => {
  const [showModel, setShowModel] = useState(false);
  const { continueWatching } = props.extras || {};
  const { kContinueWatching, kResume, kStartOver} = getAllConstants();
  let myTimer = null;
  let focusableItems = null;

  useEffect(() => {
    if(!continueWatching || !continueWatching.confirmAfter) return;
    onBodyMouseMoveHideShowMenu();
    document.body.addEventListener("click", onBodyMouseMoveHideShowMenu, true);
    document.body.addEventListener("mousemove", onBodyMouseMoveHideShowMenu, true);
    document.addEventListener('keyup', handleRemoteClick, true);
    focusableItems = addRemoveAllNavigation({ selector: '.continue-watching .focusable' });

    return () => {
      document.body.removeEventListener("click", onBodyMouseMoveHideShowMenu, true);
      document.body.removeEventListener("mousemove", onBodyMouseMoveHideShowMenu, true);
      document.body.removeEventListener("keyup", handleRemoteClick, true);
      addRemoveAllNavigation({ id: focusableItems });
    }
  }, [])

  useEffect(() => {
    const player = configuration.kUseTheoPlayer ? props.theoPlayerReference :  document.getElementById(props.playerId);
    if(showModel && player && player.pause) {
      player.pause();
    }
  }, [showModel])

  const onBodyMouseMoveHideShowMenu = (event) => {  
    if(myTimer) {
      clearTimeout(myTimer);
    }

    myTimer = setTimeout(() => {
      setShowModel(true);
    }, parseInt(continueWatching.confirmAfter) * 60000)// mins to ms
  }

  const handleRemoteClick = (event) => {
    if(!document.getElementById("continue-watching")) return;

    const back_key_codes = getBackBtnKeyCodes();
    if (back_key_codes[event.keyCode]) {
      playNow(event);
      onBodyMouseMoveHideShowMenu();
    }
  }

  const playNow = (event, startOver) => {
    if(event) {
      event.preventDefault();
    }
    setTimeout(() => {
      setShowModel(false);
      const player = configuration.kUseTheoPlayer ? props.theoPlayerReference :  document.getElementById(props.playerId);
  
      if(!player || !player.play) return;
  
      player.play();
      if(startOver && player.currentTime) {
        player.currentTime = 0;
      }
    }, 500)
  }

  if(!continueWatching || !continueWatching.confirmAfter) return null;
  if(!showModel) return null;

  const back2 = {backgroundColor: "white", color: "black"};
  return (
    <FocusLock>
      <div id="continue-watching" className="continue-watching">
        <div className="wrapper">
          <p id="cont-watch-text">{kContinueWatching}</p>
          <div className="btns">
            <span role="button" aria-describedby="cont-watch-text" className="focusable" tabIndex={getTabIndex()} onClick={(e) => playNow(e)}>{kResume}</span>
            <span role="button" aria-describedby="cont-watch-text" className="focusable" tabIndex={getTabIndex()} onClick={(e) => playNow(e, true)} style={back2}>{kStartOver}</span>
          </div>
        </div>
      </div>
    </FocusLock>
  )
}