import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {QRCodeSVG} from 'qrcode.react';

import { openModal } from "../../actions/modalActions";
import { setParentalLock, validateParentalLock, removeParentalLock, resetValidationData, getParentalLockStatus } from "../../actions/parentalLockAction";
import JsSpatialNavigation from '../../ctvnavigation/js-spatial-navigation';
import { getCustomUIColors } from '../../helperfunctions';
import { getTabIndex, isSmartTv, removeTransition } from '../../helperfunctions/common';
import { addRemoveAllNavigation } from '../../helperfunctions/ctvHelper';
import { getAllConstants } from '../../helperfunctions/regionalization';
import Background from '../Background';
import Loader from '../Loader';
import backMobile from "../../images/icons/back-mobile.png";
import CodeInput from './CodeInput';

function SetParentalLock(props) {
  const { logged_in, feeds, goBack, isFetching, codeConfigured, codeValidated, fromTvod, codeValidationFailed, validationError, runningOnMobile } = props;
  const STRINGS = getAllConstants();
  const [showInput, setShowInput] = useState(false);
  const [loginForm, setLoginForm] = useState(!logged_in);
  const [loading, setLoading] = useState(logged_in && !codeConfigured)
  const [overlay, setOverlay] = useState(null);
  const [formStrings, setFormStrings] = useState({
    title: STRINGS.sEnterAccessCode,
    msg: STRINGS.sPleaseEnterAccessCode,
    error: null
  })


  useEffect(() => {
    if(logged_in && !codeConfigured) {
      props.getParentalLockStatus();
      setTimeout(() => {
        setLoading(false);
      })
    }
    setRendererData();
    const focusId = addRemoveAllNavigation({ selector: '.lock-overlay .focusable' });
    return () => {
      addRemoveAllNavigation({ id: focusId });
    }
  }, [])

  useEffect(() => {
    if(overlay) {
      const el = document.getElementById("primaryButton");
      if(el) {
        JsSpatialNavigation.focus(el);
      }
    }
  }, [overlay])

  useEffect(() => {
    if(logged_in) {
      setLoginForm(false);
    }
    setRendererData();
  }, [logged_in, codeConfigured, codeValidated, codeValidationFailed, isFetching])

  const setRendererData = () => {
    if(!logged_in) return;
    if(overlay && overlay.title === STRINGS.sRemoveCode) return; // Code removed | will go back on click of OK
    if(loading || isFetching) return;

    const { enableInAppSetup, setupLink, code } = feeds.parentalControls || {};
    if(codeConfigured) {
      //Already Configured
      if(showInput === "set") {
        // code just got set, show set msg
        setOverlay({
          title: STRINGS.sParentalAccessCodeSetSuccess,
          des: STRINGS.sParentalAccessCodeSetSuccessMsg,
          buttons: [ "sOk" ],
          priButton: "sOk",
          btnAction : {
            "sOk": () => handleAfterCodeSet(props)
          }// to modify ok btn behaviour
        })
        setShowInput(false);
      } else if(codeValidationFailed) {
        // validation failed. 
        const needRe = validationError === STRINGS.sTooManyFixString;
        setOverlay({
          title: STRINGS.sIncorrectCodeWarning,
          des: needRe ? STRINGS.sTooManyAttempts : STRINGS.sCodeWrongTryAgainMessage,
          buttons: needRe ? ["sOk"] : [ "kNo", "sYes" ],
          priButton: needRe ? "sOk" : "sYes",
          btnAction : {
            "sYes": () => setShowInput("validate"),
            "sOk": () => exitPrantalCode()
          }// to modify btn behaviour
        })
        setShowInput(false);
      } else if(codeValidated && ((new Date() - new Date(codeValidated)) < (5 * 1000))) {// validate again after 5 sec, idleMaxtime handled at feedhelper
        // Code validated
        if(fromTvod) {
          // Play content
          props.onValidation();
        } else {
          // Show msg to remove or change code
          setOverlay({
            title: STRINGS.sSetParentalAccessCode,
            des: STRINGS.sRemoveOrChangeCodeMessage,
            buttons: [ "sCancel", "sRemoveCode", "sChangeCode" ],
            priButton: "sChangeCode"
          })
          setShowInput(false);
        }
      } else {
        // Show code input for validation from Set New Code
        if(!fromTvod && !enableInAppSetup) {
          startExternalSetup(setupLink);
        } else {
          setShowInput("validate");
          setOverlay(null);
        }
      }
    } else {
      // Not configured Show msg or play if optional
      if(fromTvod && code === "optional") {
        // Play content
        props.onValidation();
      } else {
        setOverlay({
          title: STRINGS.sSetParentalAccessCode,
          des: STRINGS.sSetupParentalCodeMessage,
          buttons: [ "sCancel", "sYes" ],
          priButton: "sYes"
        })
        setShowInput(false);
      }
    }
  }
  // ============ handlers ==============


  const exitPrantalCode = () => {
    props.resetValidationData();
    goBack();
  }

  const handleAfterCodeSet = (props) => {
    if(props.fromTvod) {
      props.onValidation();
    } else {
      exitPrantalCode();
    }
  }

  const startExternalSetup = (setupLink) => {
    const url = (setupLink && setupLink.contentUrl) || "";
    if(isSmartTv()) {
      setOverlay({
        title: STRINGS.sSetParentalAccessCode,
        des: STRINGS.sPleaseScanQR,
        buttons: [ "sCancel" ],
        qrCode: url,
        priButton: "sCancel"
      })
    } else {
      window.open(url);
      exitPrantalCode();
    }
    setShowInput(false);
  }

  const OnSetupFailed = () => {
    setShowInput(false);
    setOverlay({
      title: STRINGS.sIncorrectCodeWarning,
      des: STRINGS.sCodeNotMatched,
      buttons: [ "sOk" ],
      priButton: "sOk"
    })
  }

  const onSubmit = (code) => {
    if(showInput === "validate") {
      props.validateParentalLock(code);
    } else {
      props.setParentalLock(code);
    }
  }

  const handleButtonClick = (btn, btnAction = {}) => {
    setOverlay(null);

    if(btnAction[btn]) {
      btnAction[btn]();
      return;
    }
    const { enableInAppSetup, setupLink } = feeds.parentalControls || {};

    switch(btn) {
      case "sCancel":
        exitPrantalCode();
        break;
      case "sOk":
        exitPrantalCode();
        break;
      case "kNo":
        exitPrantalCode();
        break;
      case "sYes":
        if(!enableInAppSetup) {
          startExternalSetup(setupLink);
        } else {
          setShowInput("set");
        }
        break;
      case "sRemoveCode":
        setOverlay({
          title: STRINGS.sRemoveCode,
          des: STRINGS.sPinRemovedMsg,
          buttons: [ "sOk" ],
          priButton: "sOk"
        });
        props.removeParentalLock();
        break;
      case "sChangeCode":
        setShowInput("set");
        break;
    }
  }

  // ============ UI ==============

  const renderOverlay = () => {
    const { title, des, buttons, btnAction, priButton, qrCode } = overlay;
    const { priButtonStyle, secButtonStyle } = getCustomUIColors(feeds);
    return (
      <div className='lock-overlay'>
        <div className='title' aria-label={title.toLowerCase()}>{title}</div>
        <div className='des' aria-label={des.toLowerCase()}>{des}</div>
        {qrCode && <div className='qr-wrapper'>
          <div
            aria-label={qrCode.toLowerCase()}
          >{qrCode}</div>
          <QRCodeSVG value={qrCode} size={isSmartTv() ? 270 : 128} />
        </div>}
        <div className='buttons'>
          {buttons.map(btn => {
            return (
              <span
                key={btn}
                role="button"
                aria-label={STRINGS[btn].toLowerCase()}
                className={`bttn focusable ${removeTransition()}`}
                tabIndex={getTabIndex()}
                onClick={() => handleButtonClick(btn, btnAction)}
                style={priButton === btn ? priButtonStyle : secButtonStyle}
                id={priButton === btn ? "primaryButton" : ""}
              >
                {STRINGS[btn]}
              </span>
            )
          })}
        </div>
      </div>
    )
  }

  const renderBackButton = () => {
    let style = {}
    if(isSmartTv()) {
      style.display = 'none';
    }
    return (
      <span className={"icon-hollow_arrow backBtnSpan "+ (runningOnMobile ? "backBtnSpan-mobile" : "")}>
        <a id="more-back-button" href="" onClick={(e) => {
            e.preventDefault();
            exitPrantalCode();
          }} 
          className={(runningOnMobile ? "backBtn-mobile" : "backBtn")}
          style={{zIndex: 100, ...style}}>
            {runningOnMobile && <img src={backMobile} alt="Back"/>}
        </a>
      </span>
    )
  }

  // ============ return ==============

  if(loginForm) {
    props.openModal({type: "login", directLogin: true, parentCallback: exitPrantalCode});
    return null;
  }

  return (
    <div className='parental-lock'>
      <Background/>
      {renderBackButton()}
      {(loading || isFetching) && <Loader/>}
      {showInput && (
        <CodeInput
          onExit={exitPrantalCode}
          onSubmit={onSubmit}
          formStrings={formStrings}
          type={showInput}
          OnSetupFailed={OnSetupFailed}
          runningOnMobile={runningOnMobile}
          feeds={feeds}
        />)}
      {overlay && renderOverlay()}
    </div>
  )

}

const mapStateToProps = (state) => ({
  logged_in: state.user.logged_in,
  feeds: state.feeds.feeds,
  isFetching: state.parentalLock.isFetching,
  codeConfigured: state.parentalLock.codeConfigured,
  codeValidated: state.parentalLock.codeValidated,
  codeValidationFailed: state.parentalLock.codeValidationFailed,
  validationError: state.parentalLock.validationError
})

const mapDispatchToProps = {
  openModal: openModal,
  setParentalLock,
  validateParentalLock,
  removeParentalLock,
  getParentalLockStatus,
  resetValidationData
}

export default connect(mapStateToProps, mapDispatchToProps)(SetParentalLock);